.google-preview, .facebook-preview {
    background-color: #fff;
}

.google-preview {
    display: block;
    padding: 12px 16px;
    border-radius: 8px;
    box-shadow: rgb(32 33 36 / 28%) 0 1px 6px;
    max-width: 400px;
    margin-bottom: 20px;

    .google-url {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABs0lEQVR4AWL4//8/RRjO8Iucx+noO0MWUDo16FYABMGP6ZfUcRnWtm27jVPbtm3bttuH2t3eFPcY9pLz7NxiLjCyVd87pKnHyqXyxtCs8APd0rnyxiu4qSeA3QEDrAwBDrT1s1Rc/OrjLZwqVmOSu6+Lamcpp2KKMA9PH1BYXMe1mUP5qotvXTywsOEEYHXxrY+3cqk6TMkYpNr2FeoY3KIr0RPtn9wQ2unlA+GMkRw6+9TFw4YTwDUzx/JVvARj9KaedXRO8P5B1Du2S32smzqUrcKGEyA+uAgQjKX7zf0boWHGfn71jIKj2689gxp7OAGShNcBUmLMPVjZuiKcA2vuWHHDCQxMCz629kXAIU4ApY15QwggAFbfOP9DhgBJ+nWVJ1AZAfICAj1pAlY6hCADZnveQf7bQIwzVONGJonhLIlS9gr5mFg44Xd+4S3XHoGNPdJl1INIwKyEgHckEhgTe1bGiFY9GSFBYUwLh1IkiJUbY407E7syBSFxKTszEoiE/YdrgCEayDmtaJwCI9uu8TKMuZSVfSa4BpGgzvomBR/INhLGzrqDotp01ZR8pn/1L0JN9d9XNyx0AAAAAElFTkSuQmCC');
        background-repeat: no-repeat;
        background-position: 2px 1px;
        font-size: 12px;
        color: rgb(60, 64, 67);
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
        padding-left: 24px;
        margin-bottom: 12px;
    }

    .google-title {
        color: rgb(25, 103, 210);
        font-size: 16px;
        line-height: 20px;
        max-width: 600px;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 12px;

        &:after {
            content: attr(data-sitename);
            display: inline-block;
            margin-left: 5px;
        }
    }

    .google-descr {
        color: rgb(60, 64, 67);
        font-size: 14px;
        line-height: 20px;
        position: relative;
        max-width: 600px;
    }
}

.facebook-preview {
    box-shadow: 0 0 0 1.5px rgb(0 0 0 / 10%) inset, 0 1px 1px rgb(0 0 0 / 5%);
    max-width: 470px;
    font-family: Georgia, 'lucida grande', tahoma, verdana, arial, sans-serif;

    .facebook-image {
        height: 214px;
        background-color: #ccc;
        background-size: cover;
        background-position: center;

        &.facebook-image-empty::after {
            content: "Зображення не вибрано";
            display: block;
            color: #777;
            text-align: center;
            padding: 98px 0;
        }
    }

    .facebook-title {
        padding: 10px 12px 7px;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
    }

    .facebook-descr {
        color: #777;
        max-height: 80px;
        padding-left: 12px;
        padding-right: 12px;
        margin-bottom: 7px;
    }

    .facebook-url {
        padding-left: 12px;
        padding-right: 12px;
        text-transform: uppercase;
        color: #9197a3;
        font-size: 11px;
        line-height: 11px;
        padding-bottom: 10px;
        margin-top: 12px;
    }
}
