@font-face {
  font-family: Material Design Icons;
  src: url("materialdesignicons-webfont-V3IBNJVY-V3IBNJVY.6c5ce79d.eot");
  src: url("materialdesignicons-webfont-V3IBNJVY-V3IBNJVY.6c5ce79d.eot#iefix&v=7.2.96") format("embedded-opentype"), url("materialdesignicons-webfont-NA2Y5B2Y-NA2Y5B2Y.a16632ec.woff2") format("woff2"), url("materialdesignicons-webfont-7R43MHMD-7R43MHMD.7d804c6a.woff") format("woff"), url("materialdesignicons-webfont-HMACA26A-HMACA26A.c0814f9f.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

.mdi:before, .mdi-set {
  font: 24px / 1 Material Design Icons;
  font-size: inherit;
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
}

.mdi-ab-testing:before {
  content: "󰇉";
}

.mdi-abacus:before {
  content: "󱛠";
}

.mdi-abjad-arabic:before {
  content: "󱌨";
}

.mdi-abjad-hebrew:before {
  content: "󱌩";
}

.mdi-abugida-devanagari:before {
  content: "󱌪";
}

.mdi-abugida-thai:before {
  content: "󱌫";
}

.mdi-access-point:before {
  content: "󰀃";
}

.mdi-access-point-check:before {
  content: "󱔸";
}

.mdi-access-point-minus:before {
  content: "󱔹";
}

.mdi-access-point-network:before {
  content: "󰀂";
}

.mdi-access-point-network-off:before {
  content: "󰯡";
}

.mdi-access-point-off:before {
  content: "󱔑";
}

.mdi-access-point-plus:before {
  content: "󱔺";
}

.mdi-access-point-remove:before {
  content: "󱔻";
}

.mdi-account:before {
  content: "󰀄";
}

.mdi-account-alert:before {
  content: "󰀅";
}

.mdi-account-alert-outline:before {
  content: "󰭐";
}

.mdi-account-arrow-down:before {
  content: "󱡨";
}

.mdi-account-arrow-down-outline:before {
  content: "󱡩";
}

.mdi-account-arrow-left:before {
  content: "󰭑";
}

.mdi-account-arrow-left-outline:before {
  content: "󰭒";
}

.mdi-account-arrow-right:before {
  content: "󰭓";
}

.mdi-account-arrow-right-outline:before {
  content: "󰭔";
}

.mdi-account-arrow-up:before {
  content: "󱡧";
}

.mdi-account-arrow-up-outline:before {
  content: "󱡪";
}

.mdi-account-badge:before {
  content: "󱬊";
}

.mdi-account-badge-outline:before {
  content: "󱬋";
}

.mdi-account-box:before {
  content: "󰀆";
}

.mdi-account-box-multiple:before {
  content: "󰤴";
}

.mdi-account-box-multiple-outline:before {
  content: "󱀊";
}

.mdi-account-box-outline:before {
  content: "󰀇";
}

.mdi-account-cancel:before {
  content: "󱋟";
}

.mdi-account-cancel-outline:before {
  content: "󱋠";
}

.mdi-account-card:before {
  content: "󱮤";
}

.mdi-account-card-outline:before {
  content: "󱮥";
}

.mdi-account-cash:before {
  content: "󱂗";
}

.mdi-account-cash-outline:before {
  content: "󱂘";
}

.mdi-account-check:before {
  content: "󰀈";
}

.mdi-account-check-outline:before {
  content: "󰯢";
}

.mdi-account-child:before {
  content: "󰪉";
}

.mdi-account-child-circle:before {
  content: "󰪊";
}

.mdi-account-child-outline:before {
  content: "󱃈";
}

.mdi-account-circle:before {
  content: "󰀉";
}

.mdi-account-circle-outline:before {
  content: "󰭕";
}

.mdi-account-clock:before {
  content: "󰭖";
}

.mdi-account-clock-outline:before {
  content: "󰭗";
}

.mdi-account-cog:before {
  content: "󱍰";
}

.mdi-account-cog-outline:before {
  content: "󱍱";
}

.mdi-account-convert:before {
  content: "󰀊";
}

.mdi-account-convert-outline:before {
  content: "󱌁";
}

.mdi-account-cowboy-hat:before {
  content: "󰺛";
}

.mdi-account-cowboy-hat-outline:before {
  content: "󱟳";
}

.mdi-account-credit-card:before {
  content: "󱮦";
}

.mdi-account-credit-card-outline:before {
  content: "󱮧";
}

.mdi-account-details:before {
  content: "󰘱";
}

.mdi-account-details-outline:before {
  content: "󱍲";
}

.mdi-account-edit:before {
  content: "󰚼";
}

.mdi-account-edit-outline:before {
  content: "󰿻";
}

.mdi-account-eye:before {
  content: "󰐠";
}

.mdi-account-eye-outline:before {
  content: "󱉻";
}

.mdi-account-filter:before {
  content: "󰤶";
}

.mdi-account-filter-outline:before {
  content: "󰾝";
}

.mdi-account-group:before {
  content: "󰡉";
}

.mdi-account-group-outline:before {
  content: "󰭘";
}

.mdi-account-hard-hat:before {
  content: "󰖵";
}

.mdi-account-hard-hat-outline:before {
  content: "󱨟";
}

.mdi-account-heart:before {
  content: "󰢙";
}

.mdi-account-heart-outline:before {
  content: "󰯣";
}

.mdi-account-injury:before {
  content: "󱠕";
}

.mdi-account-injury-outline:before {
  content: "󱠖";
}

.mdi-account-key:before {
  content: "󰀋";
}

.mdi-account-key-outline:before {
  content: "󰯤";
}

.mdi-account-lock:before {
  content: "󱅞";
}

.mdi-account-lock-open:before {
  content: "󱥠";
}

.mdi-account-lock-open-outline:before {
  content: "󱥡";
}

.mdi-account-lock-outline:before {
  content: "󱅟";
}

.mdi-account-minus:before {
  content: "󰀍";
}

.mdi-account-minus-outline:before {
  content: "󰫬";
}

.mdi-account-multiple:before {
  content: "󰀎";
}

.mdi-account-multiple-check:before {
  content: "󰣅";
}

.mdi-account-multiple-check-outline:before {
  content: "󱇾";
}

.mdi-account-multiple-minus:before {
  content: "󰗓";
}

.mdi-account-multiple-minus-outline:before {
  content: "󰯥";
}

.mdi-account-multiple-outline:before {
  content: "󰀏";
}

.mdi-account-multiple-plus:before {
  content: "󰀐";
}

.mdi-account-multiple-plus-outline:before {
  content: "󰠀";
}

.mdi-account-multiple-remove:before {
  content: "󱈊";
}

.mdi-account-multiple-remove-outline:before {
  content: "󱈋";
}

.mdi-account-music:before {
  content: "󰠃";
}

.mdi-account-music-outline:before {
  content: "󰳩";
}

.mdi-account-network:before {
  content: "󰀑";
}

.mdi-account-network-off:before {
  content: "󱫱";
}

.mdi-account-network-off-outline:before {
  content: "󱫲";
}

.mdi-account-network-outline:before {
  content: "󰯦";
}

.mdi-account-off:before {
  content: "󰀒";
}

.mdi-account-off-outline:before {
  content: "󰯧";
}

.mdi-account-outline:before {
  content: "󰀓";
}

.mdi-account-plus:before {
  content: "󰀔";
}

.mdi-account-plus-outline:before {
  content: "󰠁";
}

.mdi-account-question:before {
  content: "󰭙";
}

.mdi-account-question-outline:before {
  content: "󰭚";
}

.mdi-account-reactivate:before {
  content: "󱔫";
}

.mdi-account-reactivate-outline:before {
  content: "󱔬";
}

.mdi-account-remove:before {
  content: "󰀕";
}

.mdi-account-remove-outline:before {
  content: "󰫭";
}

.mdi-account-school:before {
  content: "󱨠";
}

.mdi-account-school-outline:before {
  content: "󱨡";
}

.mdi-account-search:before {
  content: "󰀖";
}

.mdi-account-search-outline:before {
  content: "󰤵";
}

.mdi-account-settings:before {
  content: "󰘰";
}

.mdi-account-settings-outline:before {
  content: "󱃉";
}

.mdi-account-star:before {
  content: "󰀗";
}

.mdi-account-star-outline:before {
  content: "󰯨";
}

.mdi-account-supervisor:before {
  content: "󰪋";
}

.mdi-account-supervisor-circle:before {
  content: "󰪌";
}

.mdi-account-supervisor-circle-outline:before {
  content: "󱓬";
}

.mdi-account-supervisor-outline:before {
  content: "󱄭";
}

.mdi-account-switch:before {
  content: "󰀙";
}

.mdi-account-switch-outline:before {
  content: "󰓋";
}

.mdi-account-sync:before {
  content: "󱤛";
}

.mdi-account-sync-outline:before {
  content: "󱤜";
}

.mdi-account-tag:before {
  content: "󱰛";
}

.mdi-account-tag-outline:before {
  content: "󱰜";
}

.mdi-account-tie:before {
  content: "󰳣";
}

.mdi-account-tie-hat:before {
  content: "󱢘";
}

.mdi-account-tie-hat-outline:before {
  content: "󱢙";
}

.mdi-account-tie-outline:before {
  content: "󱃊";
}

.mdi-account-tie-voice:before {
  content: "󱌈";
}

.mdi-account-tie-voice-off:before {
  content: "󱌊";
}

.mdi-account-tie-voice-off-outline:before {
  content: "󱌋";
}

.mdi-account-tie-voice-outline:before {
  content: "󱌉";
}

.mdi-account-tie-woman:before {
  content: "󱪌";
}

.mdi-account-voice:before {
  content: "󰗋";
}

.mdi-account-voice-off:before {
  content: "󰻔";
}

.mdi-account-wrench:before {
  content: "󱢚";
}

.mdi-account-wrench-outline:before {
  content: "󱢛";
}

.mdi-adjust:before {
  content: "󰀚";
}

.mdi-advertisements:before {
  content: "󱤪";
}

.mdi-advertisements-off:before {
  content: "󱤫";
}

.mdi-air-conditioner:before {
  content: "󰀛";
}

.mdi-air-filter:before {
  content: "󰵃";
}

.mdi-air-horn:before {
  content: "󰶬";
}

.mdi-air-humidifier:before {
  content: "󱂙";
}

.mdi-air-humidifier-off:before {
  content: "󱑦";
}

.mdi-air-purifier:before {
  content: "󰵄";
}

.mdi-air-purifier-off:before {
  content: "󱭗";
}

.mdi-airbag:before {
  content: "󰯩";
}

.mdi-airballoon:before {
  content: "󰀜";
}

.mdi-airballoon-outline:before {
  content: "󱀋";
}

.mdi-airplane:before {
  content: "󰀝";
}

.mdi-airplane-alert:before {
  content: "󱡺";
}

.mdi-airplane-check:before {
  content: "󱡻";
}

.mdi-airplane-clock:before {
  content: "󱡼";
}

.mdi-airplane-cog:before {
  content: "󱡽";
}

.mdi-airplane-edit:before {
  content: "󱡾";
}

.mdi-airplane-landing:before {
  content: "󰗔";
}

.mdi-airplane-marker:before {
  content: "󱡿";
}

.mdi-airplane-minus:before {
  content: "󱢀";
}

.mdi-airplane-off:before {
  content: "󰀞";
}

.mdi-airplane-plus:before {
  content: "󱢁";
}

.mdi-airplane-remove:before {
  content: "󱢂";
}

.mdi-airplane-search:before {
  content: "󱢃";
}

.mdi-airplane-settings:before {
  content: "󱢄";
}

.mdi-airplane-takeoff:before {
  content: "󰗕";
}

.mdi-airport:before {
  content: "󰡋";
}

.mdi-alarm:before {
  content: "󰀠";
}

.mdi-alarm-bell:before {
  content: "󰞎";
}

.mdi-alarm-check:before {
  content: "󰀡";
}

.mdi-alarm-light:before {
  content: "󰞏";
}

.mdi-alarm-light-off:before {
  content: "󱜞";
}

.mdi-alarm-light-off-outline:before {
  content: "󱜟";
}

.mdi-alarm-light-outline:before {
  content: "󰯪";
}

.mdi-alarm-multiple:before {
  content: "󰀢";
}

.mdi-alarm-note:before {
  content: "󰹱";
}

.mdi-alarm-note-off:before {
  content: "󰹲";
}

.mdi-alarm-off:before {
  content: "󰀣";
}

.mdi-alarm-panel:before {
  content: "󱗄";
}

.mdi-alarm-panel-outline:before {
  content: "󱗅";
}

.mdi-alarm-plus:before {
  content: "󰀤";
}

.mdi-alarm-snooze:before {
  content: "󰚎";
}

.mdi-album:before {
  content: "󰀥";
}

.mdi-alert:before {
  content: "󰀦";
}

.mdi-alert-box:before {
  content: "󰀧";
}

.mdi-alert-box-outline:before {
  content: "󰳤";
}

.mdi-alert-circle:before {
  content: "󰀨";
}

.mdi-alert-circle-check:before {
  content: "󱇭";
}

.mdi-alert-circle-check-outline:before {
  content: "󱇮";
}

.mdi-alert-circle-outline:before {
  content: "󰗖";
}

.mdi-alert-decagram:before {
  content: "󰚽";
}

.mdi-alert-decagram-outline:before {
  content: "󰳥";
}

.mdi-alert-minus:before {
  content: "󱒻";
}

.mdi-alert-minus-outline:before {
  content: "󱒾";
}

.mdi-alert-octagon:before {
  content: "󰀩";
}

.mdi-alert-octagon-outline:before {
  content: "󰳦";
}

.mdi-alert-octagram:before {
  content: "󰝧";
}

.mdi-alert-octagram-outline:before {
  content: "󰳧";
}

.mdi-alert-outline:before {
  content: "󰀪";
}

.mdi-alert-plus:before {
  content: "󱒺";
}

.mdi-alert-plus-outline:before {
  content: "󱒽";
}

.mdi-alert-remove:before {
  content: "󱒼";
}

.mdi-alert-remove-outline:before {
  content: "󱒿";
}

.mdi-alert-rhombus:before {
  content: "󱇎";
}

.mdi-alert-rhombus-outline:before {
  content: "󱇏";
}

.mdi-alien:before {
  content: "󰢚";
}

.mdi-alien-outline:before {
  content: "󱃋";
}

.mdi-align-horizontal-center:before {
  content: "󱇃";
}

.mdi-align-horizontal-distribute:before {
  content: "󱥢";
}

.mdi-align-horizontal-left:before {
  content: "󱇂";
}

.mdi-align-horizontal-right:before {
  content: "󱇄";
}

.mdi-align-vertical-bottom:before {
  content: "󱇅";
}

.mdi-align-vertical-center:before {
  content: "󱇆";
}

.mdi-align-vertical-distribute:before {
  content: "󱥣";
}

.mdi-align-vertical-top:before {
  content: "󱇇";
}

.mdi-all-inclusive:before {
  content: "󰚾";
}

.mdi-all-inclusive-box:before {
  content: "󱢍";
}

.mdi-all-inclusive-box-outline:before {
  content: "󱢎";
}

.mdi-allergy:before {
  content: "󱉘";
}

.mdi-alpha:before {
  content: "󰀫";
}

.mdi-alpha-a:before {
  content: "󰫮";
}

.mdi-alpha-a-box:before {
  content: "󰬈";
}

.mdi-alpha-a-box-outline:before {
  content: "󰯫";
}

.mdi-alpha-a-circle:before {
  content: "󰯬";
}

.mdi-alpha-a-circle-outline:before {
  content: "󰯭";
}

.mdi-alpha-b:before {
  content: "󰫯";
}

.mdi-alpha-b-box:before {
  content: "󰬉";
}

.mdi-alpha-b-box-outline:before {
  content: "󰯮";
}

.mdi-alpha-b-circle:before {
  content: "󰯯";
}

.mdi-alpha-b-circle-outline:before {
  content: "󰯰";
}

.mdi-alpha-c:before {
  content: "󰫰";
}

.mdi-alpha-c-box:before {
  content: "󰬊";
}

.mdi-alpha-c-box-outline:before {
  content: "󰯱";
}

.mdi-alpha-c-circle:before {
  content: "󰯲";
}

.mdi-alpha-c-circle-outline:before {
  content: "󰯳";
}

.mdi-alpha-d:before {
  content: "󰫱";
}

.mdi-alpha-d-box:before {
  content: "󰬋";
}

.mdi-alpha-d-box-outline:before {
  content: "󰯴";
}

.mdi-alpha-d-circle:before {
  content: "󰯵";
}

.mdi-alpha-d-circle-outline:before {
  content: "󰯶";
}

.mdi-alpha-e:before {
  content: "󰫲";
}

.mdi-alpha-e-box:before {
  content: "󰬌";
}

.mdi-alpha-e-box-outline:before {
  content: "󰯷";
}

.mdi-alpha-e-circle:before {
  content: "󰯸";
}

.mdi-alpha-e-circle-outline:before {
  content: "󰯹";
}

.mdi-alpha-f:before {
  content: "󰫳";
}

.mdi-alpha-f-box:before {
  content: "󰬍";
}

.mdi-alpha-f-box-outline:before {
  content: "󰯺";
}

.mdi-alpha-f-circle:before {
  content: "󰯻";
}

.mdi-alpha-f-circle-outline:before {
  content: "󰯼";
}

.mdi-alpha-g:before {
  content: "󰫴";
}

.mdi-alpha-g-box:before {
  content: "󰬎";
}

.mdi-alpha-g-box-outline:before {
  content: "󰯽";
}

.mdi-alpha-g-circle:before {
  content: "󰯾";
}

.mdi-alpha-g-circle-outline:before {
  content: "󰯿";
}

.mdi-alpha-h:before {
  content: "󰫵";
}

.mdi-alpha-h-box:before {
  content: "󰬏";
}

.mdi-alpha-h-box-outline:before {
  content: "󰰀";
}

.mdi-alpha-h-circle:before {
  content: "󰰁";
}

.mdi-alpha-h-circle-outline:before {
  content: "󰰂";
}

.mdi-alpha-i:before {
  content: "󰫶";
}

.mdi-alpha-i-box:before {
  content: "󰬐";
}

.mdi-alpha-i-box-outline:before {
  content: "󰰃";
}

.mdi-alpha-i-circle:before {
  content: "󰰄";
}

.mdi-alpha-i-circle-outline:before {
  content: "󰰅";
}

.mdi-alpha-j:before {
  content: "󰫷";
}

.mdi-alpha-j-box:before {
  content: "󰬑";
}

.mdi-alpha-j-box-outline:before {
  content: "󰰆";
}

.mdi-alpha-j-circle:before {
  content: "󰰇";
}

.mdi-alpha-j-circle-outline:before {
  content: "󰰈";
}

.mdi-alpha-k:before {
  content: "󰫸";
}

.mdi-alpha-k-box:before {
  content: "󰬒";
}

.mdi-alpha-k-box-outline:before {
  content: "󰰉";
}

.mdi-alpha-k-circle:before {
  content: "󰰊";
}

.mdi-alpha-k-circle-outline:before {
  content: "󰰋";
}

.mdi-alpha-l:before {
  content: "󰫹";
}

.mdi-alpha-l-box:before {
  content: "󰬓";
}

.mdi-alpha-l-box-outline:before {
  content: "󰰌";
}

.mdi-alpha-l-circle:before {
  content: "󰰍";
}

.mdi-alpha-l-circle-outline:before {
  content: "󰰎";
}

.mdi-alpha-m:before {
  content: "󰫺";
}

.mdi-alpha-m-box:before {
  content: "󰬔";
}

.mdi-alpha-m-box-outline:before {
  content: "󰰏";
}

.mdi-alpha-m-circle:before {
  content: "󰰐";
}

.mdi-alpha-m-circle-outline:before {
  content: "󰰑";
}

.mdi-alpha-n:before {
  content: "󰫻";
}

.mdi-alpha-n-box:before {
  content: "󰬕";
}

.mdi-alpha-n-box-outline:before {
  content: "󰰒";
}

.mdi-alpha-n-circle:before {
  content: "󰰓";
}

.mdi-alpha-n-circle-outline:before {
  content: "󰰔";
}

.mdi-alpha-o:before {
  content: "󰫼";
}

.mdi-alpha-o-box:before {
  content: "󰬖";
}

.mdi-alpha-o-box-outline:before {
  content: "󰰕";
}

.mdi-alpha-o-circle:before {
  content: "󰰖";
}

.mdi-alpha-o-circle-outline:before {
  content: "󰰗";
}

.mdi-alpha-p:before {
  content: "󰫽";
}

.mdi-alpha-p-box:before {
  content: "󰬗";
}

.mdi-alpha-p-box-outline:before {
  content: "󰰘";
}

.mdi-alpha-p-circle:before {
  content: "󰰙";
}

.mdi-alpha-p-circle-outline:before {
  content: "󰰚";
}

.mdi-alpha-q:before {
  content: "󰫾";
}

.mdi-alpha-q-box:before {
  content: "󰬘";
}

.mdi-alpha-q-box-outline:before {
  content: "󰰛";
}

.mdi-alpha-q-circle:before {
  content: "󰰜";
}

.mdi-alpha-q-circle-outline:before {
  content: "󰰝";
}

.mdi-alpha-r:before {
  content: "󰫿";
}

.mdi-alpha-r-box:before {
  content: "󰬙";
}

.mdi-alpha-r-box-outline:before {
  content: "󰰞";
}

.mdi-alpha-r-circle:before {
  content: "󰰟";
}

.mdi-alpha-r-circle-outline:before {
  content: "󰰠";
}

.mdi-alpha-s:before {
  content: "󰬀";
}

.mdi-alpha-s-box:before {
  content: "󰬚";
}

.mdi-alpha-s-box-outline:before {
  content: "󰰡";
}

.mdi-alpha-s-circle:before {
  content: "󰰢";
}

.mdi-alpha-s-circle-outline:before {
  content: "󰰣";
}

.mdi-alpha-t:before {
  content: "󰬁";
}

.mdi-alpha-t-box:before {
  content: "󰬛";
}

.mdi-alpha-t-box-outline:before {
  content: "󰰤";
}

.mdi-alpha-t-circle:before {
  content: "󰰥";
}

.mdi-alpha-t-circle-outline:before {
  content: "󰰦";
}

.mdi-alpha-u:before {
  content: "󰬂";
}

.mdi-alpha-u-box:before {
  content: "󰬜";
}

.mdi-alpha-u-box-outline:before {
  content: "󰰧";
}

.mdi-alpha-u-circle:before {
  content: "󰰨";
}

.mdi-alpha-u-circle-outline:before {
  content: "󰰩";
}

.mdi-alpha-v:before {
  content: "󰬃";
}

.mdi-alpha-v-box:before {
  content: "󰬝";
}

.mdi-alpha-v-box-outline:before {
  content: "󰰪";
}

.mdi-alpha-v-circle:before {
  content: "󰰫";
}

.mdi-alpha-v-circle-outline:before {
  content: "󰰬";
}

.mdi-alpha-w:before {
  content: "󰬄";
}

.mdi-alpha-w-box:before {
  content: "󰬞";
}

.mdi-alpha-w-box-outline:before {
  content: "󰰭";
}

.mdi-alpha-w-circle:before {
  content: "󰰮";
}

.mdi-alpha-w-circle-outline:before {
  content: "󰰯";
}

.mdi-alpha-x:before {
  content: "󰬅";
}

.mdi-alpha-x-box:before {
  content: "󰬟";
}

.mdi-alpha-x-box-outline:before {
  content: "󰰰";
}

.mdi-alpha-x-circle:before {
  content: "󰰱";
}

.mdi-alpha-x-circle-outline:before {
  content: "󰰲";
}

.mdi-alpha-y:before {
  content: "󰬆";
}

.mdi-alpha-y-box:before {
  content: "󰬠";
}

.mdi-alpha-y-box-outline:before {
  content: "󰰳";
}

.mdi-alpha-y-circle:before {
  content: "󰰴";
}

.mdi-alpha-y-circle-outline:before {
  content: "󰰵";
}

.mdi-alpha-z:before {
  content: "󰬇";
}

.mdi-alpha-z-box:before {
  content: "󰬡";
}

.mdi-alpha-z-box-outline:before {
  content: "󰰶";
}

.mdi-alpha-z-circle:before {
  content: "󰰷";
}

.mdi-alpha-z-circle-outline:before {
  content: "󰰸";
}

.mdi-alphabet-aurebesh:before {
  content: "󱌬";
}

.mdi-alphabet-cyrillic:before {
  content: "󱌭";
}

.mdi-alphabet-greek:before {
  content: "󱌮";
}

.mdi-alphabet-latin:before {
  content: "󱌯";
}

.mdi-alphabet-piqad:before {
  content: "󱌰";
}

.mdi-alphabet-tengwar:before {
  content: "󱌷";
}

.mdi-alphabetical:before {
  content: "󰀬";
}

.mdi-alphabetical-off:before {
  content: "󱀌";
}

.mdi-alphabetical-variant:before {
  content: "󱀍";
}

.mdi-alphabetical-variant-off:before {
  content: "󱀎";
}

.mdi-altimeter:before {
  content: "󰗗";
}

.mdi-ambulance:before {
  content: "󰀯";
}

.mdi-ammunition:before {
  content: "󰳨";
}

.mdi-ampersand:before {
  content: "󰪍";
}

.mdi-amplifier:before {
  content: "󰀰";
}

.mdi-amplifier-off:before {
  content: "󱆵";
}

.mdi-anchor:before {
  content: "󰀱";
}

.mdi-android:before {
  content: "󰀲";
}

.mdi-android-studio:before {
  content: "󰀴";
}

.mdi-angle-acute:before {
  content: "󰤷";
}

.mdi-angle-obtuse:before {
  content: "󰤸";
}

.mdi-angle-right:before {
  content: "󰤹";
}

.mdi-angular:before {
  content: "󰚲";
}

.mdi-angularjs:before {
  content: "󰚿";
}

.mdi-animation:before {
  content: "󰗘";
}

.mdi-animation-outline:before {
  content: "󰪏";
}

.mdi-animation-play:before {
  content: "󰤺";
}

.mdi-animation-play-outline:before {
  content: "󰪐";
}

.mdi-ansible:before {
  content: "󱂚";
}

.mdi-antenna:before {
  content: "󱄙";
}

.mdi-anvil:before {
  content: "󰢛";
}

.mdi-apache-kafka:before {
  content: "󱀏";
}

.mdi-api:before {
  content: "󱂛";
}

.mdi-api-off:before {
  content: "󱉗";
}

.mdi-apple:before {
  content: "󰀵";
}

.mdi-apple-finder:before {
  content: "󰀶";
}

.mdi-apple-icloud:before {
  content: "󰀸";
}

.mdi-apple-ios:before {
  content: "󰀷";
}

.mdi-apple-keyboard-caps:before {
  content: "󰘲";
}

.mdi-apple-keyboard-command:before {
  content: "󰘳";
}

.mdi-apple-keyboard-control:before {
  content: "󰘴";
}

.mdi-apple-keyboard-option:before {
  content: "󰘵";
}

.mdi-apple-keyboard-shift:before {
  content: "󰘶";
}

.mdi-apple-safari:before {
  content: "󰀹";
}

.mdi-application:before {
  content: "󰣆";
}

.mdi-application-array:before {
  content: "󱃵";
}

.mdi-application-array-outline:before {
  content: "󱃶";
}

.mdi-application-braces:before {
  content: "󱃷";
}

.mdi-application-braces-outline:before {
  content: "󱃸";
}

.mdi-application-brackets:before {
  content: "󰲋";
}

.mdi-application-brackets-outline:before {
  content: "󰲌";
}

.mdi-application-cog:before {
  content: "󰙵";
}

.mdi-application-cog-outline:before {
  content: "󱕷";
}

.mdi-application-edit:before {
  content: "󰂮";
}

.mdi-application-edit-outline:before {
  content: "󰘙";
}

.mdi-application-export:before {
  content: "󰶭";
}

.mdi-application-import:before {
  content: "󰶮";
}

.mdi-application-outline:before {
  content: "󰘔";
}

.mdi-application-parentheses:before {
  content: "󱃹";
}

.mdi-application-parentheses-outline:before {
  content: "󱃺";
}

.mdi-application-settings:before {
  content: "󰭠";
}

.mdi-application-settings-outline:before {
  content: "󱕕";
}

.mdi-application-variable:before {
  content: "󱃻";
}

.mdi-application-variable-outline:before {
  content: "󱃼";
}

.mdi-approximately-equal:before {
  content: "󰾞";
}

.mdi-approximately-equal-box:before {
  content: "󰾟";
}

.mdi-apps:before {
  content: "󰀻";
}

.mdi-apps-box:before {
  content: "󰵆";
}

.mdi-arch:before {
  content: "󰣇";
}

.mdi-archive:before {
  content: "󰀼";
}

.mdi-archive-alert:before {
  content: "󱓽";
}

.mdi-archive-alert-outline:before {
  content: "󱓾";
}

.mdi-archive-arrow-down:before {
  content: "󱉙";
}

.mdi-archive-arrow-down-outline:before {
  content: "󱉚";
}

.mdi-archive-arrow-up:before {
  content: "󱉛";
}

.mdi-archive-arrow-up-outline:before {
  content: "󱉜";
}

.mdi-archive-cancel:before {
  content: "󱝋";
}

.mdi-archive-cancel-outline:before {
  content: "󱝌";
}

.mdi-archive-check:before {
  content: "󱝍";
}

.mdi-archive-check-outline:before {
  content: "󱝎";
}

.mdi-archive-clock:before {
  content: "󱝏";
}

.mdi-archive-clock-outline:before {
  content: "󱝐";
}

.mdi-archive-cog:before {
  content: "󱝑";
}

.mdi-archive-cog-outline:before {
  content: "󱝒";
}

.mdi-archive-edit:before {
  content: "󱝓";
}

.mdi-archive-edit-outline:before {
  content: "󱝔";
}

.mdi-archive-eye:before {
  content: "󱝕";
}

.mdi-archive-eye-outline:before {
  content: "󱝖";
}

.mdi-archive-lock:before {
  content: "󱝗";
}

.mdi-archive-lock-open:before {
  content: "󱝘";
}

.mdi-archive-lock-open-outline:before {
  content: "󱝙";
}

.mdi-archive-lock-outline:before {
  content: "󱝚";
}

.mdi-archive-marker:before {
  content: "󱝛";
}

.mdi-archive-marker-outline:before {
  content: "󱝜";
}

.mdi-archive-minus:before {
  content: "󱝝";
}

.mdi-archive-minus-outline:before {
  content: "󱝞";
}

.mdi-archive-music:before {
  content: "󱝟";
}

.mdi-archive-music-outline:before {
  content: "󱝠";
}

.mdi-archive-off:before {
  content: "󱝡";
}

.mdi-archive-off-outline:before {
  content: "󱝢";
}

.mdi-archive-outline:before {
  content: "󱈎";
}

.mdi-archive-plus:before {
  content: "󱝣";
}

.mdi-archive-plus-outline:before {
  content: "󱝤";
}

.mdi-archive-refresh:before {
  content: "󱝥";
}

.mdi-archive-refresh-outline:before {
  content: "󱝦";
}

.mdi-archive-remove:before {
  content: "󱝧";
}

.mdi-archive-remove-outline:before {
  content: "󱝨";
}

.mdi-archive-search:before {
  content: "󱝩";
}

.mdi-archive-search-outline:before {
  content: "󱝪";
}

.mdi-archive-settings:before {
  content: "󱝫";
}

.mdi-archive-settings-outline:before {
  content: "󱝬";
}

.mdi-archive-star:before {
  content: "󱝭";
}

.mdi-archive-star-outline:before {
  content: "󱝮";
}

.mdi-archive-sync:before {
  content: "󱝯";
}

.mdi-archive-sync-outline:before {
  content: "󱝰";
}

.mdi-arm-flex:before {
  content: "󰿗";
}

.mdi-arm-flex-outline:before {
  content: "󰿖";
}

.mdi-arrange-bring-forward:before {
  content: "󰀽";
}

.mdi-arrange-bring-to-front:before {
  content: "󰀾";
}

.mdi-arrange-send-backward:before {
  content: "󰀿";
}

.mdi-arrange-send-to-back:before {
  content: "󰁀";
}

.mdi-arrow-all:before {
  content: "󰁁";
}

.mdi-arrow-bottom-left:before {
  content: "󰁂";
}

.mdi-arrow-bottom-left-bold-box:before {
  content: "󱥤";
}

.mdi-arrow-bottom-left-bold-box-outline:before {
  content: "󱥥";
}

.mdi-arrow-bottom-left-bold-outline:before {
  content: "󰦷";
}

.mdi-arrow-bottom-left-thick:before {
  content: "󰦸";
}

.mdi-arrow-bottom-left-thin:before {
  content: "󱦶";
}

.mdi-arrow-bottom-left-thin-circle-outline:before {
  content: "󱖖";
}

.mdi-arrow-bottom-right:before {
  content: "󰁃";
}

.mdi-arrow-bottom-right-bold-box:before {
  content: "󱥦";
}

.mdi-arrow-bottom-right-bold-box-outline:before {
  content: "󱥧";
}

.mdi-arrow-bottom-right-bold-outline:before {
  content: "󰦹";
}

.mdi-arrow-bottom-right-thick:before {
  content: "󰦺";
}

.mdi-arrow-bottom-right-thin:before {
  content: "󱦷";
}

.mdi-arrow-bottom-right-thin-circle-outline:before {
  content: "󱖕";
}

.mdi-arrow-collapse:before {
  content: "󰘕";
}

.mdi-arrow-collapse-all:before {
  content: "󰁄";
}

.mdi-arrow-collapse-down:before {
  content: "󰞒";
}

.mdi-arrow-collapse-horizontal:before {
  content: "󰡌";
}

.mdi-arrow-collapse-left:before {
  content: "󰞓";
}

.mdi-arrow-collapse-right:before {
  content: "󰞔";
}

.mdi-arrow-collapse-up:before {
  content: "󰞕";
}

.mdi-arrow-collapse-vertical:before {
  content: "󰡍";
}

.mdi-arrow-decision:before {
  content: "󰦻";
}

.mdi-arrow-decision-auto:before {
  content: "󰦼";
}

.mdi-arrow-decision-auto-outline:before {
  content: "󰦽";
}

.mdi-arrow-decision-outline:before {
  content: "󰦾";
}

.mdi-arrow-down:before {
  content: "󰁅";
}

.mdi-arrow-down-bold:before {
  content: "󰜮";
}

.mdi-arrow-down-bold-box:before {
  content: "󰜯";
}

.mdi-arrow-down-bold-box-outline:before {
  content: "󰜰";
}

.mdi-arrow-down-bold-circle:before {
  content: "󰁇";
}

.mdi-arrow-down-bold-circle-outline:before {
  content: "󰁈";
}

.mdi-arrow-down-bold-hexagon-outline:before {
  content: "󰁉";
}

.mdi-arrow-down-bold-outline:before {
  content: "󰦿";
}

.mdi-arrow-down-box:before {
  content: "󰛀";
}

.mdi-arrow-down-circle:before {
  content: "󰳛";
}

.mdi-arrow-down-circle-outline:before {
  content: "󰳜";
}

.mdi-arrow-down-drop-circle:before {
  content: "󰁊";
}

.mdi-arrow-down-drop-circle-outline:before {
  content: "󰁋";
}

.mdi-arrow-down-left:before {
  content: "󱞡";
}

.mdi-arrow-down-left-bold:before {
  content: "󱞢";
}

.mdi-arrow-down-right:before {
  content: "󱞣";
}

.mdi-arrow-down-right-bold:before {
  content: "󱞤";
}

.mdi-arrow-down-thick:before {
  content: "󰁆";
}

.mdi-arrow-down-thin:before {
  content: "󱦳";
}

.mdi-arrow-down-thin-circle-outline:before {
  content: "󱖙";
}

.mdi-arrow-expand:before {
  content: "󰘖";
}

.mdi-arrow-expand-all:before {
  content: "󰁌";
}

.mdi-arrow-expand-down:before {
  content: "󰞖";
}

.mdi-arrow-expand-horizontal:before {
  content: "󰡎";
}

.mdi-arrow-expand-left:before {
  content: "󰞗";
}

.mdi-arrow-expand-right:before {
  content: "󰞘";
}

.mdi-arrow-expand-up:before {
  content: "󰞙";
}

.mdi-arrow-expand-vertical:before {
  content: "󰡏";
}

.mdi-arrow-horizontal-lock:before {
  content: "󱅛";
}

.mdi-arrow-left:before {
  content: "󰁍";
}

.mdi-arrow-left-bold:before {
  content: "󰜱";
}

.mdi-arrow-left-bold-box:before {
  content: "󰜲";
}

.mdi-arrow-left-bold-box-outline:before {
  content: "󰜳";
}

.mdi-arrow-left-bold-circle:before {
  content: "󰁏";
}

.mdi-arrow-left-bold-circle-outline:before {
  content: "󰁐";
}

.mdi-arrow-left-bold-hexagon-outline:before {
  content: "󰁑";
}

.mdi-arrow-left-bold-outline:before {
  content: "󰧀";
}

.mdi-arrow-left-bottom:before {
  content: "󱞥";
}

.mdi-arrow-left-bottom-bold:before {
  content: "󱞦";
}

.mdi-arrow-left-box:before {
  content: "󰛁";
}

.mdi-arrow-left-circle:before {
  content: "󰳝";
}

.mdi-arrow-left-circle-outline:before {
  content: "󰳞";
}

.mdi-arrow-left-drop-circle:before {
  content: "󰁒";
}

.mdi-arrow-left-drop-circle-outline:before {
  content: "󰁓";
}

.mdi-arrow-left-right:before {
  content: "󰹳";
}

.mdi-arrow-left-right-bold:before {
  content: "󰹴";
}

.mdi-arrow-left-right-bold-outline:before {
  content: "󰧁";
}

.mdi-arrow-left-thick:before {
  content: "󰁎";
}

.mdi-arrow-left-thin:before {
  content: "󱦱";
}

.mdi-arrow-left-thin-circle-outline:before {
  content: "󱖚";
}

.mdi-arrow-left-top:before {
  content: "󱞧";
}

.mdi-arrow-left-top-bold:before {
  content: "󱞨";
}

.mdi-arrow-projectile:before {
  content: "󱡀";
}

.mdi-arrow-projectile-multiple:before {
  content: "󱠿";
}

.mdi-arrow-right:before {
  content: "󰁔";
}

.mdi-arrow-right-bold:before {
  content: "󰜴";
}

.mdi-arrow-right-bold-box:before {
  content: "󰜵";
}

.mdi-arrow-right-bold-box-outline:before {
  content: "󰜶";
}

.mdi-arrow-right-bold-circle:before {
  content: "󰁖";
}

.mdi-arrow-right-bold-circle-outline:before {
  content: "󰁗";
}

.mdi-arrow-right-bold-hexagon-outline:before {
  content: "󰁘";
}

.mdi-arrow-right-bold-outline:before {
  content: "󰧂";
}

.mdi-arrow-right-bottom:before {
  content: "󱞩";
}

.mdi-arrow-right-bottom-bold:before {
  content: "󱞪";
}

.mdi-arrow-right-box:before {
  content: "󰛂";
}

.mdi-arrow-right-circle:before {
  content: "󰳟";
}

.mdi-arrow-right-circle-outline:before {
  content: "󰳠";
}

.mdi-arrow-right-drop-circle:before {
  content: "󰁙";
}

.mdi-arrow-right-drop-circle-outline:before {
  content: "󰁚";
}

.mdi-arrow-right-thick:before {
  content: "󰁕";
}

.mdi-arrow-right-thin:before {
  content: "󱦰";
}

.mdi-arrow-right-thin-circle-outline:before {
  content: "󱖘";
}

.mdi-arrow-right-top:before {
  content: "󱞫";
}

.mdi-arrow-right-top-bold:before {
  content: "󱞬";
}

.mdi-arrow-split-horizontal:before {
  content: "󰤻";
}

.mdi-arrow-split-vertical:before {
  content: "󰤼";
}

.mdi-arrow-top-left:before {
  content: "󰁛";
}

.mdi-arrow-top-left-bold-box:before {
  content: "󱥨";
}

.mdi-arrow-top-left-bold-box-outline:before {
  content: "󱥩";
}

.mdi-arrow-top-left-bold-outline:before {
  content: "󰧃";
}

.mdi-arrow-top-left-bottom-right:before {
  content: "󰹵";
}

.mdi-arrow-top-left-bottom-right-bold:before {
  content: "󰹶";
}

.mdi-arrow-top-left-thick:before {
  content: "󰧄";
}

.mdi-arrow-top-left-thin:before {
  content: "󱦵";
}

.mdi-arrow-top-left-thin-circle-outline:before {
  content: "󱖓";
}

.mdi-arrow-top-right:before {
  content: "󰁜";
}

.mdi-arrow-top-right-bold-box:before {
  content: "󱥪";
}

.mdi-arrow-top-right-bold-box-outline:before {
  content: "󱥫";
}

.mdi-arrow-top-right-bold-outline:before {
  content: "󰧅";
}

.mdi-arrow-top-right-bottom-left:before {
  content: "󰹷";
}

.mdi-arrow-top-right-bottom-left-bold:before {
  content: "󰹸";
}

.mdi-arrow-top-right-thick:before {
  content: "󰧆";
}

.mdi-arrow-top-right-thin:before {
  content: "󱦴";
}

.mdi-arrow-top-right-thin-circle-outline:before {
  content: "󱖔";
}

.mdi-arrow-u-down-left:before {
  content: "󱞭";
}

.mdi-arrow-u-down-left-bold:before {
  content: "󱞮";
}

.mdi-arrow-u-down-right:before {
  content: "󱞯";
}

.mdi-arrow-u-down-right-bold:before {
  content: "󱞰";
}

.mdi-arrow-u-left-bottom:before {
  content: "󱞱";
}

.mdi-arrow-u-left-bottom-bold:before {
  content: "󱞲";
}

.mdi-arrow-u-left-top:before {
  content: "󱞳";
}

.mdi-arrow-u-left-top-bold:before {
  content: "󱞴";
}

.mdi-arrow-u-right-bottom:before {
  content: "󱞵";
}

.mdi-arrow-u-right-bottom-bold:before {
  content: "󱞶";
}

.mdi-arrow-u-right-top:before {
  content: "󱞷";
}

.mdi-arrow-u-right-top-bold:before {
  content: "󱞸";
}

.mdi-arrow-u-up-left:before {
  content: "󱞹";
}

.mdi-arrow-u-up-left-bold:before {
  content: "󱞺";
}

.mdi-arrow-u-up-right:before {
  content: "󱞻";
}

.mdi-arrow-u-up-right-bold:before {
  content: "󱞼";
}

.mdi-arrow-up:before {
  content: "󰁝";
}

.mdi-arrow-up-bold:before {
  content: "󰜷";
}

.mdi-arrow-up-bold-box:before {
  content: "󰜸";
}

.mdi-arrow-up-bold-box-outline:before {
  content: "󰜹";
}

.mdi-arrow-up-bold-circle:before {
  content: "󰁟";
}

.mdi-arrow-up-bold-circle-outline:before {
  content: "󰁠";
}

.mdi-arrow-up-bold-hexagon-outline:before {
  content: "󰁡";
}

.mdi-arrow-up-bold-outline:before {
  content: "󰧇";
}

.mdi-arrow-up-box:before {
  content: "󰛃";
}

.mdi-arrow-up-circle:before {
  content: "󰳡";
}

.mdi-arrow-up-circle-outline:before {
  content: "󰳢";
}

.mdi-arrow-up-down:before {
  content: "󰹹";
}

.mdi-arrow-up-down-bold:before {
  content: "󰹺";
}

.mdi-arrow-up-down-bold-outline:before {
  content: "󰧈";
}

.mdi-arrow-up-drop-circle:before {
  content: "󰁢";
}

.mdi-arrow-up-drop-circle-outline:before {
  content: "󰁣";
}

.mdi-arrow-up-left:before {
  content: "󱞽";
}

.mdi-arrow-up-left-bold:before {
  content: "󱞾";
}

.mdi-arrow-up-right:before {
  content: "󱞿";
}

.mdi-arrow-up-right-bold:before {
  content: "󱟀";
}

.mdi-arrow-up-thick:before {
  content: "󰁞";
}

.mdi-arrow-up-thin:before {
  content: "󱦲";
}

.mdi-arrow-up-thin-circle-outline:before {
  content: "󱖗";
}

.mdi-arrow-vertical-lock:before {
  content: "󱅜";
}

.mdi-artboard:before {
  content: "󱮚";
}

.mdi-artstation:before {
  content: "󰭛";
}

.mdi-aspect-ratio:before {
  content: "󰨤";
}

.mdi-assistant:before {
  content: "󰁤";
}

.mdi-asterisk:before {
  content: "󰛄";
}

.mdi-asterisk-circle-outline:before {
  content: "󱨧";
}

.mdi-at:before {
  content: "󰁥";
}

.mdi-atlassian:before {
  content: "󰠄";
}

.mdi-atm:before {
  content: "󰵇";
}

.mdi-atom:before {
  content: "󰝨";
}

.mdi-atom-variant:before {
  content: "󰹻";
}

.mdi-attachment:before {
  content: "󰁦";
}

.mdi-attachment-check:before {
  content: "󱫁";
}

.mdi-attachment-lock:before {
  content: "󱧄";
}

.mdi-attachment-minus:before {
  content: "󱫂";
}

.mdi-attachment-off:before {
  content: "󱫃";
}

.mdi-attachment-plus:before {
  content: "󱫄";
}

.mdi-attachment-remove:before {
  content: "󱫅";
}

.mdi-atv:before {
  content: "󱭰";
}

.mdi-audio-input-rca:before {
  content: "󱡫";
}

.mdi-audio-input-stereo-minijack:before {
  content: "󱡬";
}

.mdi-audio-input-xlr:before {
  content: "󱡭";
}

.mdi-audio-video:before {
  content: "󰤽";
}

.mdi-audio-video-off:before {
  content: "󱆶";
}

.mdi-augmented-reality:before {
  content: "󰡐";
}

.mdi-aurora:before {
  content: "󱮹";
}

.mdi-auto-download:before {
  content: "󱍾";
}

.mdi-auto-fix:before {
  content: "󰁨";
}

.mdi-auto-mode:before {
  content: "󱰠";
}

.mdi-auto-upload:before {
  content: "󰁩";
}

.mdi-autorenew:before {
  content: "󰁪";
}

.mdi-autorenew-off:before {
  content: "󱧧";
}

.mdi-av-timer:before {
  content: "󰁫";
}

.mdi-awning:before {
  content: "󱮇";
}

.mdi-awning-outline:before {
  content: "󱮈";
}

.mdi-aws:before {
  content: "󰸏";
}

.mdi-axe:before {
  content: "󰣈";
}

.mdi-axe-battle:before {
  content: "󱡂";
}

.mdi-axis:before {
  content: "󰵈";
}

.mdi-axis-arrow:before {
  content: "󰵉";
}

.mdi-axis-arrow-info:before {
  content: "󱐎";
}

.mdi-axis-arrow-lock:before {
  content: "󰵊";
}

.mdi-axis-lock:before {
  content: "󰵋";
}

.mdi-axis-x-arrow:before {
  content: "󰵌";
}

.mdi-axis-x-arrow-lock:before {
  content: "󰵍";
}

.mdi-axis-x-rotate-clockwise:before {
  content: "󰵎";
}

.mdi-axis-x-rotate-counterclockwise:before {
  content: "󰵏";
}

.mdi-axis-x-y-arrow-lock:before {
  content: "󰵐";
}

.mdi-axis-y-arrow:before {
  content: "󰵑";
}

.mdi-axis-y-arrow-lock:before {
  content: "󰵒";
}

.mdi-axis-y-rotate-clockwise:before {
  content: "󰵓";
}

.mdi-axis-y-rotate-counterclockwise:before {
  content: "󰵔";
}

.mdi-axis-z-arrow:before {
  content: "󰵕";
}

.mdi-axis-z-arrow-lock:before {
  content: "󰵖";
}

.mdi-axis-z-rotate-clockwise:before {
  content: "󰵗";
}

.mdi-axis-z-rotate-counterclockwise:before {
  content: "󰵘";
}

.mdi-babel:before {
  content: "󰨥";
}

.mdi-baby:before {
  content: "󰁬";
}

.mdi-baby-bottle:before {
  content: "󰼹";
}

.mdi-baby-bottle-outline:before {
  content: "󰼺";
}

.mdi-baby-buggy:before {
  content: "󱏠";
}

.mdi-baby-buggy-off:before {
  content: "󱫳";
}

.mdi-baby-carriage:before {
  content: "󰚏";
}

.mdi-baby-carriage-off:before {
  content: "󰾠";
}

.mdi-baby-face:before {
  content: "󰹼";
}

.mdi-baby-face-outline:before {
  content: "󰹽";
}

.mdi-backburger:before {
  content: "󰁭";
}

.mdi-backspace:before {
  content: "󰁮";
}

.mdi-backspace-outline:before {
  content: "󰭜";
}

.mdi-backspace-reverse:before {
  content: "󰹾";
}

.mdi-backspace-reverse-outline:before {
  content: "󰹿";
}

.mdi-backup-restore:before {
  content: "󰁯";
}

.mdi-bacteria:before {
  content: "󰻕";
}

.mdi-bacteria-outline:before {
  content: "󰻖";
}

.mdi-badge-account:before {
  content: "󰶧";
}

.mdi-badge-account-alert:before {
  content: "󰶨";
}

.mdi-badge-account-alert-outline:before {
  content: "󰶩";
}

.mdi-badge-account-horizontal:before {
  content: "󰸍";
}

.mdi-badge-account-horizontal-outline:before {
  content: "󰸎";
}

.mdi-badge-account-outline:before {
  content: "󰶪";
}

.mdi-badminton:before {
  content: "󰡑";
}

.mdi-bag-carry-on:before {
  content: "󰼻";
}

.mdi-bag-carry-on-check:before {
  content: "󰵥";
}

.mdi-bag-carry-on-off:before {
  content: "󰼼";
}

.mdi-bag-checked:before {
  content: "󰼽";
}

.mdi-bag-personal:before {
  content: "󰸐";
}

.mdi-bag-personal-off:before {
  content: "󰸑";
}

.mdi-bag-personal-off-outline:before {
  content: "󰸒";
}

.mdi-bag-personal-outline:before {
  content: "󰸓";
}

.mdi-bag-personal-tag:before {
  content: "󱬌";
}

.mdi-bag-personal-tag-outline:before {
  content: "󱬍";
}

.mdi-bag-suitcase:before {
  content: "󱖋";
}

.mdi-bag-suitcase-off:before {
  content: "󱖍";
}

.mdi-bag-suitcase-off-outline:before {
  content: "󱖎";
}

.mdi-bag-suitcase-outline:before {
  content: "󱖌";
}

.mdi-baguette:before {
  content: "󰼾";
}

.mdi-balcony:before {
  content: "󱠗";
}

.mdi-balloon:before {
  content: "󰨦";
}

.mdi-ballot:before {
  content: "󰧉";
}

.mdi-ballot-outline:before {
  content: "󰧊";
}

.mdi-ballot-recount:before {
  content: "󰰹";
}

.mdi-ballot-recount-outline:before {
  content: "󰰺";
}

.mdi-bandage:before {
  content: "󰶯";
}

.mdi-bank:before {
  content: "󰁰";
}

.mdi-bank-check:before {
  content: "󱙕";
}

.mdi-bank-circle:before {
  content: "󱰃";
}

.mdi-bank-circle-outline:before {
  content: "󱰄";
}

.mdi-bank-minus:before {
  content: "󰶰";
}

.mdi-bank-off:before {
  content: "󱙖";
}

.mdi-bank-off-outline:before {
  content: "󱙗";
}

.mdi-bank-outline:before {
  content: "󰺀";
}

.mdi-bank-plus:before {
  content: "󰶱";
}

.mdi-bank-remove:before {
  content: "󰶲";
}

.mdi-bank-transfer:before {
  content: "󰨧";
}

.mdi-bank-transfer-in:before {
  content: "󰨨";
}

.mdi-bank-transfer-out:before {
  content: "󰨩";
}

.mdi-barcode:before {
  content: "󰁱";
}

.mdi-barcode-off:before {
  content: "󱈶";
}

.mdi-barcode-scan:before {
  content: "󰁲";
}

.mdi-barley:before {
  content: "󰁳";
}

.mdi-barley-off:before {
  content: "󰭝";
}

.mdi-barn:before {
  content: "󰭞";
}

.mdi-barrel:before {
  content: "󰁴";
}

.mdi-barrel-outline:before {
  content: "󱨨";
}

.mdi-baseball:before {
  content: "󰡒";
}

.mdi-baseball-bat:before {
  content: "󰡓";
}

.mdi-baseball-diamond:before {
  content: "󱗬";
}

.mdi-baseball-diamond-outline:before {
  content: "󱗭";
}

.mdi-baseball-outline:before {
  content: "󱱚";
}

.mdi-bash:before {
  content: "󱆃";
}

.mdi-basket:before {
  content: "󰁶";
}

.mdi-basket-check:before {
  content: "󱣥";
}

.mdi-basket-check-outline:before {
  content: "󱣦";
}

.mdi-basket-fill:before {
  content: "󰁷";
}

.mdi-basket-minus:before {
  content: "󱔣";
}

.mdi-basket-minus-outline:before {
  content: "󱔤";
}

.mdi-basket-off:before {
  content: "󱔥";
}

.mdi-basket-off-outline:before {
  content: "󱔦";
}

.mdi-basket-outline:before {
  content: "󱆁";
}

.mdi-basket-plus:before {
  content: "󱔧";
}

.mdi-basket-plus-outline:before {
  content: "󱔨";
}

.mdi-basket-remove:before {
  content: "󱔩";
}

.mdi-basket-remove-outline:before {
  content: "󱔪";
}

.mdi-basket-unfill:before {
  content: "󰁸";
}

.mdi-basketball:before {
  content: "󰠆";
}

.mdi-basketball-hoop:before {
  content: "󰰻";
}

.mdi-basketball-hoop-outline:before {
  content: "󰰼";
}

.mdi-bat:before {
  content: "󰭟";
}

.mdi-bathtub:before {
  content: "󱠘";
}

.mdi-bathtub-outline:before {
  content: "󱠙";
}

.mdi-battery:before {
  content: "󰁹";
}

.mdi-battery-10:before {
  content: "󰁺";
}

.mdi-battery-10-bluetooth:before {
  content: "󰤾";
}

.mdi-battery-20:before {
  content: "󰁻";
}

.mdi-battery-20-bluetooth:before {
  content: "󰤿";
}

.mdi-battery-30:before {
  content: "󰁼";
}

.mdi-battery-30-bluetooth:before {
  content: "󰥀";
}

.mdi-battery-40:before {
  content: "󰁽";
}

.mdi-battery-40-bluetooth:before {
  content: "󰥁";
}

.mdi-battery-50:before {
  content: "󰁾";
}

.mdi-battery-50-bluetooth:before {
  content: "󰥂";
}

.mdi-battery-60:before {
  content: "󰁿";
}

.mdi-battery-60-bluetooth:before {
  content: "󰥃";
}

.mdi-battery-70:before {
  content: "󰂀";
}

.mdi-battery-70-bluetooth:before {
  content: "󰥄";
}

.mdi-battery-80:before {
  content: "󰂁";
}

.mdi-battery-80-bluetooth:before {
  content: "󰥅";
}

.mdi-battery-90:before {
  content: "󰂂";
}

.mdi-battery-90-bluetooth:before {
  content: "󰥆";
}

.mdi-battery-alert:before {
  content: "󰂃";
}

.mdi-battery-alert-bluetooth:before {
  content: "󰥇";
}

.mdi-battery-alert-variant:before {
  content: "󱃌";
}

.mdi-battery-alert-variant-outline:before {
  content: "󱃍";
}

.mdi-battery-arrow-down:before {
  content: "󱟞";
}

.mdi-battery-arrow-down-outline:before {
  content: "󱟟";
}

.mdi-battery-arrow-up:before {
  content: "󱟠";
}

.mdi-battery-arrow-up-outline:before {
  content: "󱟡";
}

.mdi-battery-bluetooth:before {
  content: "󰥈";
}

.mdi-battery-bluetooth-variant:before {
  content: "󰥉";
}

.mdi-battery-charging:before {
  content: "󰂄";
}

.mdi-battery-charging-10:before {
  content: "󰢜";
}

.mdi-battery-charging-100:before {
  content: "󰂅";
}

.mdi-battery-charging-20:before {
  content: "󰂆";
}

.mdi-battery-charging-30:before {
  content: "󰂇";
}

.mdi-battery-charging-40:before {
  content: "󰂈";
}

.mdi-battery-charging-50:before {
  content: "󰢝";
}

.mdi-battery-charging-60:before {
  content: "󰂉";
}

.mdi-battery-charging-70:before {
  content: "󰢞";
}

.mdi-battery-charging-80:before {
  content: "󰂊";
}

.mdi-battery-charging-90:before {
  content: "󰂋";
}

.mdi-battery-charging-high:before {
  content: "󱊦";
}

.mdi-battery-charging-low:before {
  content: "󱊤";
}

.mdi-battery-charging-medium:before {
  content: "󱊥";
}

.mdi-battery-charging-outline:before {
  content: "󰢟";
}

.mdi-battery-charging-wireless:before {
  content: "󰠇";
}

.mdi-battery-charging-wireless-10:before {
  content: "󰠈";
}

.mdi-battery-charging-wireless-20:before {
  content: "󰠉";
}

.mdi-battery-charging-wireless-30:before {
  content: "󰠊";
}

.mdi-battery-charging-wireless-40:before {
  content: "󰠋";
}

.mdi-battery-charging-wireless-50:before {
  content: "󰠌";
}

.mdi-battery-charging-wireless-60:before {
  content: "󰠍";
}

.mdi-battery-charging-wireless-70:before {
  content: "󰠎";
}

.mdi-battery-charging-wireless-80:before {
  content: "󰠏";
}

.mdi-battery-charging-wireless-90:before {
  content: "󰠐";
}

.mdi-battery-charging-wireless-alert:before {
  content: "󰠑";
}

.mdi-battery-charging-wireless-outline:before {
  content: "󰠒";
}

.mdi-battery-check:before {
  content: "󱟢";
}

.mdi-battery-check-outline:before {
  content: "󱟣";
}

.mdi-battery-clock:before {
  content: "󱧥";
}

.mdi-battery-clock-outline:before {
  content: "󱧦";
}

.mdi-battery-heart:before {
  content: "󱈏";
}

.mdi-battery-heart-outline:before {
  content: "󱈐";
}

.mdi-battery-heart-variant:before {
  content: "󱈑";
}

.mdi-battery-high:before {
  content: "󱊣";
}

.mdi-battery-lock:before {
  content: "󱞜";
}

.mdi-battery-lock-open:before {
  content: "󱞝";
}

.mdi-battery-low:before {
  content: "󱊡";
}

.mdi-battery-medium:before {
  content: "󱊢";
}

.mdi-battery-minus:before {
  content: "󱟤";
}

.mdi-battery-minus-outline:before {
  content: "󱟥";
}

.mdi-battery-minus-variant:before {
  content: "󰂌";
}

.mdi-battery-negative:before {
  content: "󰂍";
}

.mdi-battery-off:before {
  content: "󱉝";
}

.mdi-battery-off-outline:before {
  content: "󱉞";
}

.mdi-battery-outline:before {
  content: "󰂎";
}

.mdi-battery-plus:before {
  content: "󱟦";
}

.mdi-battery-plus-outline:before {
  content: "󱟧";
}

.mdi-battery-plus-variant:before {
  content: "󰂏";
}

.mdi-battery-positive:before {
  content: "󰂐";
}

.mdi-battery-remove:before {
  content: "󱟨";
}

.mdi-battery-remove-outline:before {
  content: "󱟩";
}

.mdi-battery-sync:before {
  content: "󱠴";
}

.mdi-battery-sync-outline:before {
  content: "󱠵";
}

.mdi-battery-unknown:before {
  content: "󰂑";
}

.mdi-battery-unknown-bluetooth:before {
  content: "󰥊";
}

.mdi-beach:before {
  content: "󰂒";
}

.mdi-beaker:before {
  content: "󰳪";
}

.mdi-beaker-alert:before {
  content: "󱈩";
}

.mdi-beaker-alert-outline:before {
  content: "󱈪";
}

.mdi-beaker-check:before {
  content: "󱈫";
}

.mdi-beaker-check-outline:before {
  content: "󱈬";
}

.mdi-beaker-minus:before {
  content: "󱈭";
}

.mdi-beaker-minus-outline:before {
  content: "󱈮";
}

.mdi-beaker-outline:before {
  content: "󰚐";
}

.mdi-beaker-plus:before {
  content: "󱈯";
}

.mdi-beaker-plus-outline:before {
  content: "󱈰";
}

.mdi-beaker-question:before {
  content: "󱈱";
}

.mdi-beaker-question-outline:before {
  content: "󱈲";
}

.mdi-beaker-remove:before {
  content: "󱈳";
}

.mdi-beaker-remove-outline:before {
  content: "󱈴";
}

.mdi-bed:before {
  content: "󰋣";
}

.mdi-bed-clock:before {
  content: "󱮔";
}

.mdi-bed-double:before {
  content: "󰿔";
}

.mdi-bed-double-outline:before {
  content: "󰿓";
}

.mdi-bed-empty:before {
  content: "󰢠";
}

.mdi-bed-king:before {
  content: "󰿒";
}

.mdi-bed-king-outline:before {
  content: "󰿑";
}

.mdi-bed-outline:before {
  content: "󰂙";
}

.mdi-bed-queen:before {
  content: "󰿐";
}

.mdi-bed-queen-outline:before {
  content: "󰿛";
}

.mdi-bed-single:before {
  content: "󱁭";
}

.mdi-bed-single-outline:before {
  content: "󱁮";
}

.mdi-bee:before {
  content: "󰾡";
}

.mdi-bee-flower:before {
  content: "󰾢";
}

.mdi-beehive-off-outline:before {
  content: "󱏭";
}

.mdi-beehive-outline:before {
  content: "󱃎";
}

.mdi-beekeeper:before {
  content: "󱓢";
}

.mdi-beer:before {
  content: "󰂘";
}

.mdi-beer-outline:before {
  content: "󱌌";
}

.mdi-bell:before {
  content: "󰂚";
}

.mdi-bell-alert:before {
  content: "󰵙";
}

.mdi-bell-alert-outline:before {
  content: "󰺁";
}

.mdi-bell-badge:before {
  content: "󱅫";
}

.mdi-bell-badge-outline:before {
  content: "󰅸";
}

.mdi-bell-cancel:before {
  content: "󱏧";
}

.mdi-bell-cancel-outline:before {
  content: "󱏨";
}

.mdi-bell-check:before {
  content: "󱇥";
}

.mdi-bell-check-outline:before {
  content: "󱇦";
}

.mdi-bell-circle:before {
  content: "󰵚";
}

.mdi-bell-circle-outline:before {
  content: "󰵛";
}

.mdi-bell-cog:before {
  content: "󱨩";
}

.mdi-bell-cog-outline:before {
  content: "󱨪";
}

.mdi-bell-minus:before {
  content: "󱏩";
}

.mdi-bell-minus-outline:before {
  content: "󱏪";
}

.mdi-bell-off:before {
  content: "󰂛";
}

.mdi-bell-off-outline:before {
  content: "󰪑";
}

.mdi-bell-outline:before {
  content: "󰂜";
}

.mdi-bell-plus:before {
  content: "󰂝";
}

.mdi-bell-plus-outline:before {
  content: "󰪒";
}

.mdi-bell-remove:before {
  content: "󱏫";
}

.mdi-bell-remove-outline:before {
  content: "󱏬";
}

.mdi-bell-ring:before {
  content: "󰂞";
}

.mdi-bell-ring-outline:before {
  content: "󰂟";
}

.mdi-bell-sleep:before {
  content: "󰂠";
}

.mdi-bell-sleep-outline:before {
  content: "󰪓";
}

.mdi-bench:before {
  content: "󱰡";
}

.mdi-bench-back:before {
  content: "󱰢";
}

.mdi-beta:before {
  content: "󰂡";
}

.mdi-betamax:before {
  content: "󰧋";
}

.mdi-biathlon:before {
  content: "󰸔";
}

.mdi-bicycle:before {
  content: "󱂜";
}

.mdi-bicycle-basket:before {
  content: "󱈵";
}

.mdi-bicycle-cargo:before {
  content: "󱢜";
}

.mdi-bicycle-electric:before {
  content: "󱖴";
}

.mdi-bicycle-penny-farthing:before {
  content: "󱗩";
}

.mdi-bike:before {
  content: "󰂣";
}

.mdi-bike-fast:before {
  content: "󱄟";
}

.mdi-bike-pedal:before {
  content: "󱰣";
}

.mdi-bike-pedal-clipless:before {
  content: "󱰤";
}

.mdi-bike-pedal-mountain:before {
  content: "󱰥";
}

.mdi-billboard:before {
  content: "󱀐";
}

.mdi-billiards:before {
  content: "󰭡";
}

.mdi-billiards-rack:before {
  content: "󰭢";
}

.mdi-binoculars:before {
  content: "󰂥";
}

.mdi-bio:before {
  content: "󰂦";
}

.mdi-biohazard:before {
  content: "󰂧";
}

.mdi-bird:before {
  content: "󱗆";
}

.mdi-bitbucket:before {
  content: "󰂨";
}

.mdi-bitcoin:before {
  content: "󰠓";
}

.mdi-black-mesa:before {
  content: "󰂩";
}

.mdi-blender:before {
  content: "󰳫";
}

.mdi-blender-outline:before {
  content: "󱠚";
}

.mdi-blender-software:before {
  content: "󰂫";
}

.mdi-blinds:before {
  content: "󰂬";
}

.mdi-blinds-horizontal:before {
  content: "󱨫";
}

.mdi-blinds-horizontal-closed:before {
  content: "󱨬";
}

.mdi-blinds-open:before {
  content: "󱀑";
}

.mdi-blinds-vertical:before {
  content: "󱨭";
}

.mdi-blinds-vertical-closed:before {
  content: "󱨮";
}

.mdi-block-helper:before {
  content: "󰂭";
}

.mdi-blood-bag:before {
  content: "󰳬";
}

.mdi-bluetooth:before {
  content: "󰂯";
}

.mdi-bluetooth-audio:before {
  content: "󰂰";
}

.mdi-bluetooth-connect:before {
  content: "󰂱";
}

.mdi-bluetooth-off:before {
  content: "󰂲";
}

.mdi-bluetooth-settings:before {
  content: "󰂳";
}

.mdi-bluetooth-transfer:before {
  content: "󰂴";
}

.mdi-blur:before {
  content: "󰂵";
}

.mdi-blur-linear:before {
  content: "󰂶";
}

.mdi-blur-off:before {
  content: "󰂷";
}

.mdi-blur-radial:before {
  content: "󰂸";
}

.mdi-bolt:before {
  content: "󰶳";
}

.mdi-bomb:before {
  content: "󰚑";
}

.mdi-bomb-off:before {
  content: "󰛅";
}

.mdi-bone:before {
  content: "󰂹";
}

.mdi-bone-off:before {
  content: "󱧠";
}

.mdi-book:before {
  content: "󰂺";
}

.mdi-book-account:before {
  content: "󱎭";
}

.mdi-book-account-outline:before {
  content: "󱎮";
}

.mdi-book-alert:before {
  content: "󱙼";
}

.mdi-book-alert-outline:before {
  content: "󱙽";
}

.mdi-book-alphabet:before {
  content: "󰘝";
}

.mdi-book-arrow-down:before {
  content: "󱙾";
}

.mdi-book-arrow-down-outline:before {
  content: "󱙿";
}

.mdi-book-arrow-left:before {
  content: "󱚀";
}

.mdi-book-arrow-left-outline:before {
  content: "󱚁";
}

.mdi-book-arrow-right:before {
  content: "󱚂";
}

.mdi-book-arrow-right-outline:before {
  content: "󱚃";
}

.mdi-book-arrow-up:before {
  content: "󱚄";
}

.mdi-book-arrow-up-outline:before {
  content: "󱚅";
}

.mdi-book-cancel:before {
  content: "󱚆";
}

.mdi-book-cancel-outline:before {
  content: "󱚇";
}

.mdi-book-check:before {
  content: "󱓳";
}

.mdi-book-check-outline:before {
  content: "󱓴";
}

.mdi-book-clock:before {
  content: "󱚈";
}

.mdi-book-clock-outline:before {
  content: "󱚉";
}

.mdi-book-cog:before {
  content: "󱚊";
}

.mdi-book-cog-outline:before {
  content: "󱚋";
}

.mdi-book-cross:before {
  content: "󰂢";
}

.mdi-book-edit:before {
  content: "󱚌";
}

.mdi-book-edit-outline:before {
  content: "󱚍";
}

.mdi-book-education:before {
  content: "󱛉";
}

.mdi-book-education-outline:before {
  content: "󱛊";
}

.mdi-book-heart:before {
  content: "󱨝";
}

.mdi-book-heart-outline:before {
  content: "󱨞";
}

.mdi-book-information-variant:before {
  content: "󱁯";
}

.mdi-book-lock:before {
  content: "󰞚";
}

.mdi-book-lock-open:before {
  content: "󰞛";
}

.mdi-book-lock-open-outline:before {
  content: "󱚎";
}

.mdi-book-lock-outline:before {
  content: "󱚏";
}

.mdi-book-marker:before {
  content: "󱚐";
}

.mdi-book-marker-outline:before {
  content: "󱚑";
}

.mdi-book-minus:before {
  content: "󰗙";
}

.mdi-book-minus-multiple:before {
  content: "󰪔";
}

.mdi-book-minus-multiple-outline:before {
  content: "󰤋";
}

.mdi-book-minus-outline:before {
  content: "󱚒";
}

.mdi-book-multiple:before {
  content: "󰂻";
}

.mdi-book-multiple-outline:before {
  content: "󰐶";
}

.mdi-book-music:before {
  content: "󰁧";
}

.mdi-book-music-outline:before {
  content: "󱚓";
}

.mdi-book-off:before {
  content: "󱚔";
}

.mdi-book-off-outline:before {
  content: "󱚕";
}

.mdi-book-open:before {
  content: "󰂽";
}

.mdi-book-open-blank-variant:before {
  content: "󰂾";
}

.mdi-book-open-outline:before {
  content: "󰭣";
}

.mdi-book-open-page-variant:before {
  content: "󰗚";
}

.mdi-book-open-page-variant-outline:before {
  content: "󱗖";
}

.mdi-book-open-variant:before {
  content: "󱓷";
}

.mdi-book-outline:before {
  content: "󰭤";
}

.mdi-book-play:before {
  content: "󰺂";
}

.mdi-book-play-outline:before {
  content: "󰺃";
}

.mdi-book-plus:before {
  content: "󰗛";
}

.mdi-book-plus-multiple:before {
  content: "󰪕";
}

.mdi-book-plus-multiple-outline:before {
  content: "󰫞";
}

.mdi-book-plus-outline:before {
  content: "󱚖";
}

.mdi-book-refresh:before {
  content: "󱚗";
}

.mdi-book-refresh-outline:before {
  content: "󱚘";
}

.mdi-book-remove:before {
  content: "󰪗";
}

.mdi-book-remove-multiple:before {
  content: "󰪖";
}

.mdi-book-remove-multiple-outline:before {
  content: "󰓊";
}

.mdi-book-remove-outline:before {
  content: "󱚙";
}

.mdi-book-search:before {
  content: "󰺄";
}

.mdi-book-search-outline:before {
  content: "󰺅";
}

.mdi-book-settings:before {
  content: "󱚚";
}

.mdi-book-settings-outline:before {
  content: "󱚛";
}

.mdi-book-sync:before {
  content: "󱚜";
}

.mdi-book-sync-outline:before {
  content: "󱛈";
}

.mdi-book-variant:before {
  content: "󰂿";
}

.mdi-bookmark:before {
  content: "󰃀";
}

.mdi-bookmark-box:before {
  content: "󱭵";
}

.mdi-bookmark-box-multiple:before {
  content: "󱥬";
}

.mdi-bookmark-box-multiple-outline:before {
  content: "󱥭";
}

.mdi-bookmark-box-outline:before {
  content: "󱭶";
}

.mdi-bookmark-check:before {
  content: "󰃁";
}

.mdi-bookmark-check-outline:before {
  content: "󱍻";
}

.mdi-bookmark-minus:before {
  content: "󰧌";
}

.mdi-bookmark-minus-outline:before {
  content: "󰧍";
}

.mdi-bookmark-multiple:before {
  content: "󰸕";
}

.mdi-bookmark-multiple-outline:before {
  content: "󰸖";
}

.mdi-bookmark-music:before {
  content: "󰃂";
}

.mdi-bookmark-music-outline:before {
  content: "󱍹";
}

.mdi-bookmark-off:before {
  content: "󰧎";
}

.mdi-bookmark-off-outline:before {
  content: "󰧏";
}

.mdi-bookmark-outline:before {
  content: "󰃃";
}

.mdi-bookmark-plus:before {
  content: "󰃅";
}

.mdi-bookmark-plus-outline:before {
  content: "󰃄";
}

.mdi-bookmark-remove:before {
  content: "󰃆";
}

.mdi-bookmark-remove-outline:before {
  content: "󱍺";
}

.mdi-bookshelf:before {
  content: "󱉟";
}

.mdi-boom-gate:before {
  content: "󰺆";
}

.mdi-boom-gate-alert:before {
  content: "󰺇";
}

.mdi-boom-gate-alert-outline:before {
  content: "󰺈";
}

.mdi-boom-gate-arrow-down:before {
  content: "󰺉";
}

.mdi-boom-gate-arrow-down-outline:before {
  content: "󰺊";
}

.mdi-boom-gate-arrow-up:before {
  content: "󰺌";
}

.mdi-boom-gate-arrow-up-outline:before {
  content: "󰺍";
}

.mdi-boom-gate-outline:before {
  content: "󰺋";
}

.mdi-boom-gate-up:before {
  content: "󱟹";
}

.mdi-boom-gate-up-outline:before {
  content: "󱟺";
}

.mdi-boombox:before {
  content: "󰗜";
}

.mdi-boomerang:before {
  content: "󱃏";
}

.mdi-bootstrap:before {
  content: "󰛆";
}

.mdi-border-all:before {
  content: "󰃇";
}

.mdi-border-all-variant:before {
  content: "󰢡";
}

.mdi-border-bottom:before {
  content: "󰃈";
}

.mdi-border-bottom-variant:before {
  content: "󰢢";
}

.mdi-border-color:before {
  content: "󰃉";
}

.mdi-border-horizontal:before {
  content: "󰃊";
}

.mdi-border-inside:before {
  content: "󰃋";
}

.mdi-border-left:before {
  content: "󰃌";
}

.mdi-border-left-variant:before {
  content: "󰢣";
}

.mdi-border-none:before {
  content: "󰃍";
}

.mdi-border-none-variant:before {
  content: "󰢤";
}

.mdi-border-outside:before {
  content: "󰃎";
}

.mdi-border-radius:before {
  content: "󱫴";
}

.mdi-border-right:before {
  content: "󰃏";
}

.mdi-border-right-variant:before {
  content: "󰢥";
}

.mdi-border-style:before {
  content: "󰃐";
}

.mdi-border-top:before {
  content: "󰃑";
}

.mdi-border-top-variant:before {
  content: "󰢦";
}

.mdi-border-vertical:before {
  content: "󰃒";
}

.mdi-bottle-soda:before {
  content: "󱁰";
}

.mdi-bottle-soda-classic:before {
  content: "󱁱";
}

.mdi-bottle-soda-classic-outline:before {
  content: "󱍣";
}

.mdi-bottle-soda-outline:before {
  content: "󱁲";
}

.mdi-bottle-tonic:before {
  content: "󱄮";
}

.mdi-bottle-tonic-outline:before {
  content: "󱄯";
}

.mdi-bottle-tonic-plus:before {
  content: "󱄰";
}

.mdi-bottle-tonic-plus-outline:before {
  content: "󱄱";
}

.mdi-bottle-tonic-skull:before {
  content: "󱄲";
}

.mdi-bottle-tonic-skull-outline:before {
  content: "󱄳";
}

.mdi-bottle-wine:before {
  content: "󰡔";
}

.mdi-bottle-wine-outline:before {
  content: "󱌐";
}

.mdi-bow-arrow:before {
  content: "󱡁";
}

.mdi-bow-tie:before {
  content: "󰙸";
}

.mdi-bowl:before {
  content: "󰊎";
}

.mdi-bowl-mix:before {
  content: "󰘗";
}

.mdi-bowl-mix-outline:before {
  content: "󰋤";
}

.mdi-bowl-outline:before {
  content: "󰊩";
}

.mdi-bowling:before {
  content: "󰃓";
}

.mdi-box:before {
  content: "󰃔";
}

.mdi-box-cutter:before {
  content: "󰃕";
}

.mdi-box-cutter-off:before {
  content: "󰭊";
}

.mdi-box-shadow:before {
  content: "󰘷";
}

.mdi-boxing-glove:before {
  content: "󰭥";
}

.mdi-braille:before {
  content: "󰧐";
}

.mdi-brain:before {
  content: "󰧑";
}

.mdi-bread-slice:before {
  content: "󰳮";
}

.mdi-bread-slice-outline:before {
  content: "󰳯";
}

.mdi-bridge:before {
  content: "󰘘";
}

.mdi-briefcase:before {
  content: "󰃖";
}

.mdi-briefcase-account:before {
  content: "󰳰";
}

.mdi-briefcase-account-outline:before {
  content: "󰳱";
}

.mdi-briefcase-arrow-left-right:before {
  content: "󱪍";
}

.mdi-briefcase-arrow-left-right-outline:before {
  content: "󱪎";
}

.mdi-briefcase-arrow-up-down:before {
  content: "󱪏";
}

.mdi-briefcase-arrow-up-down-outline:before {
  content: "󱪐";
}

.mdi-briefcase-check:before {
  content: "󰃗";
}

.mdi-briefcase-check-outline:before {
  content: "󱌞";
}

.mdi-briefcase-clock:before {
  content: "󱃐";
}

.mdi-briefcase-clock-outline:before {
  content: "󱃑";
}

.mdi-briefcase-download:before {
  content: "󰃘";
}

.mdi-briefcase-download-outline:before {
  content: "󰰽";
}

.mdi-briefcase-edit:before {
  content: "󰪘";
}

.mdi-briefcase-edit-outline:before {
  content: "󰰾";
}

.mdi-briefcase-eye:before {
  content: "󱟙";
}

.mdi-briefcase-eye-outline:before {
  content: "󱟚";
}

.mdi-briefcase-minus:before {
  content: "󰨪";
}

.mdi-briefcase-minus-outline:before {
  content: "󰰿";
}

.mdi-briefcase-off:before {
  content: "󱙘";
}

.mdi-briefcase-off-outline:before {
  content: "󱙙";
}

.mdi-briefcase-outline:before {
  content: "󰠔";
}

.mdi-briefcase-plus:before {
  content: "󰨫";
}

.mdi-briefcase-plus-outline:before {
  content: "󰱀";
}

.mdi-briefcase-remove:before {
  content: "󰨬";
}

.mdi-briefcase-remove-outline:before {
  content: "󰱁";
}

.mdi-briefcase-search:before {
  content: "󰨭";
}

.mdi-briefcase-search-outline:before {
  content: "󰱂";
}

.mdi-briefcase-upload:before {
  content: "󰃙";
}

.mdi-briefcase-upload-outline:before {
  content: "󰱃";
}

.mdi-briefcase-variant:before {
  content: "󱒔";
}

.mdi-briefcase-variant-off:before {
  content: "󱙚";
}

.mdi-briefcase-variant-off-outline:before {
  content: "󱙛";
}

.mdi-briefcase-variant-outline:before {
  content: "󱒕";
}

.mdi-brightness-1:before {
  content: "󰃚";
}

.mdi-brightness-2:before {
  content: "󰃛";
}

.mdi-brightness-3:before {
  content: "󰃜";
}

.mdi-brightness-4:before {
  content: "󰃝";
}

.mdi-brightness-5:before {
  content: "󰃞";
}

.mdi-brightness-6:before {
  content: "󰃟";
}

.mdi-brightness-7:before {
  content: "󰃠";
}

.mdi-brightness-auto:before {
  content: "󰃡";
}

.mdi-brightness-percent:before {
  content: "󰳲";
}

.mdi-broadcast:before {
  content: "󱜠";
}

.mdi-broadcast-off:before {
  content: "󱜡";
}

.mdi-broom:before {
  content: "󰃢";
}

.mdi-brush:before {
  content: "󰃣";
}

.mdi-brush-off:before {
  content: "󱝱";
}

.mdi-brush-outline:before {
  content: "󱨍";
}

.mdi-brush-variant:before {
  content: "󱠓";
}

.mdi-bucket:before {
  content: "󱐕";
}

.mdi-bucket-outline:before {
  content: "󱐖";
}

.mdi-buffet:before {
  content: "󰕸";
}

.mdi-bug:before {
  content: "󰃤";
}

.mdi-bug-check:before {
  content: "󰨮";
}

.mdi-bug-check-outline:before {
  content: "󰨯";
}

.mdi-bug-outline:before {
  content: "󰨰";
}

.mdi-bug-pause:before {
  content: "󱫵";
}

.mdi-bug-pause-outline:before {
  content: "󱫶";
}

.mdi-bug-play:before {
  content: "󱫷";
}

.mdi-bug-play-outline:before {
  content: "󱫸";
}

.mdi-bug-stop:before {
  content: "󱫹";
}

.mdi-bug-stop-outline:before {
  content: "󱫺";
}

.mdi-bugle:before {
  content: "󰶴";
}

.mdi-bulkhead-light:before {
  content: "󱨯";
}

.mdi-bulldozer:before {
  content: "󰬢";
}

.mdi-bullet:before {
  content: "󰳳";
}

.mdi-bulletin-board:before {
  content: "󰃥";
}

.mdi-bullhorn:before {
  content: "󰃦";
}

.mdi-bullhorn-outline:before {
  content: "󰬣";
}

.mdi-bullhorn-variant:before {
  content: "󱥮";
}

.mdi-bullhorn-variant-outline:before {
  content: "󱥯";
}

.mdi-bullseye:before {
  content: "󰗝";
}

.mdi-bullseye-arrow:before {
  content: "󰣉";
}

.mdi-bulma:before {
  content: "󱋧";
}

.mdi-bunk-bed:before {
  content: "󱌂";
}

.mdi-bunk-bed-outline:before {
  content: "󰂗";
}

.mdi-bus:before {
  content: "󰃧";
}

.mdi-bus-alert:before {
  content: "󰪙";
}

.mdi-bus-articulated-end:before {
  content: "󰞜";
}

.mdi-bus-articulated-front:before {
  content: "󰞝";
}

.mdi-bus-clock:before {
  content: "󰣊";
}

.mdi-bus-double-decker:before {
  content: "󰞞";
}

.mdi-bus-electric:before {
  content: "󱤝";
}

.mdi-bus-marker:before {
  content: "󱈒";
}

.mdi-bus-multiple:before {
  content: "󰼿";
}

.mdi-bus-school:before {
  content: "󰞟";
}

.mdi-bus-side:before {
  content: "󰞠";
}

.mdi-bus-stop:before {
  content: "󱀒";
}

.mdi-bus-stop-covered:before {
  content: "󱀓";
}

.mdi-bus-stop-uncovered:before {
  content: "󱀔";
}

.mdi-butterfly:before {
  content: "󱖉";
}

.mdi-butterfly-outline:before {
  content: "󱖊";
}

.mdi-button-cursor:before {
  content: "󱭏";
}

.mdi-button-pointer:before {
  content: "󱭐";
}

.mdi-cabin-a-frame:before {
  content: "󱢌";
}

.mdi-cable-data:before {
  content: "󱎔";
}

.mdi-cached:before {
  content: "󰃨";
}

.mdi-cactus:before {
  content: "󰶵";
}

.mdi-cake:before {
  content: "󰃩";
}

.mdi-cake-layered:before {
  content: "󰃪";
}

.mdi-cake-variant:before {
  content: "󰃫";
}

.mdi-cake-variant-outline:before {
  content: "󱟰";
}

.mdi-calculator:before {
  content: "󰃬";
}

.mdi-calculator-variant:before {
  content: "󰪚";
}

.mdi-calculator-variant-outline:before {
  content: "󱖦";
}

.mdi-calendar:before {
  content: "󰃭";
}

.mdi-calendar-account:before {
  content: "󰻗";
}

.mdi-calendar-account-outline:before {
  content: "󰻘";
}

.mdi-calendar-alert:before {
  content: "󰨱";
}

.mdi-calendar-alert-outline:before {
  content: "󱭢";
}

.mdi-calendar-arrow-left:before {
  content: "󱄴";
}

.mdi-calendar-arrow-right:before {
  content: "󱄵";
}

.mdi-calendar-badge:before {
  content: "󱮝";
}

.mdi-calendar-badge-outline:before {
  content: "󱮞";
}

.mdi-calendar-blank:before {
  content: "󰃮";
}

.mdi-calendar-blank-multiple:before {
  content: "󱁳";
}

.mdi-calendar-blank-outline:before {
  content: "󰭦";
}

.mdi-calendar-check:before {
  content: "󰃯";
}

.mdi-calendar-check-outline:before {
  content: "󰱄";
}

.mdi-calendar-clock:before {
  content: "󰃰";
}

.mdi-calendar-clock-outline:before {
  content: "󱛡";
}

.mdi-calendar-collapse-horizontal:before {
  content: "󱢝";
}

.mdi-calendar-collapse-horizontal-outline:before {
  content: "󱭣";
}

.mdi-calendar-cursor:before {
  content: "󱕻";
}

.mdi-calendar-cursor-outline:before {
  content: "󱭤";
}

.mdi-calendar-edit:before {
  content: "󰢧";
}

.mdi-calendar-edit-outline:before {
  content: "󱭥";
}

.mdi-calendar-end:before {
  content: "󱙬";
}

.mdi-calendar-end-outline:before {
  content: "󱭦";
}

.mdi-calendar-expand-horizontal:before {
  content: "󱢞";
}

.mdi-calendar-expand-horizontal-outline:before {
  content: "󱭧";
}

.mdi-calendar-export:before {
  content: "󰬤";
}

.mdi-calendar-export-outline:before {
  content: "󱭨";
}

.mdi-calendar-filter:before {
  content: "󱨲";
}

.mdi-calendar-filter-outline:before {
  content: "󱨳";
}

.mdi-calendar-heart:before {
  content: "󰧒";
}

.mdi-calendar-heart-outline:before {
  content: "󱭩";
}

.mdi-calendar-import:before {
  content: "󰬥";
}

.mdi-calendar-import-outline:before {
  content: "󱭪";
}

.mdi-calendar-lock:before {
  content: "󱙁";
}

.mdi-calendar-lock-open:before {
  content: "󱭛";
}

.mdi-calendar-lock-open-outline:before {
  content: "󱭜";
}

.mdi-calendar-lock-outline:before {
  content: "󱙂";
}

.mdi-calendar-minus:before {
  content: "󰵜";
}

.mdi-calendar-minus-outline:before {
  content: "󱭫";
}

.mdi-calendar-month:before {
  content: "󰸗";
}

.mdi-calendar-month-outline:before {
  content: "󰸘";
}

.mdi-calendar-multiple:before {
  content: "󰃱";
}

.mdi-calendar-multiple-check:before {
  content: "󰃲";
}

.mdi-calendar-multiselect:before {
  content: "󰨲";
}

.mdi-calendar-multiselect-outline:before {
  content: "󱭕";
}

.mdi-calendar-outline:before {
  content: "󰭧";
}

.mdi-calendar-plus:before {
  content: "󰃳";
}

.mdi-calendar-plus-outline:before {
  content: "󱭬";
}

.mdi-calendar-question:before {
  content: "󰚒";
}

.mdi-calendar-question-outline:before {
  content: "󱭭";
}

.mdi-calendar-range:before {
  content: "󰙹";
}

.mdi-calendar-range-outline:before {
  content: "󰭨";
}

.mdi-calendar-refresh:before {
  content: "󰇡";
}

.mdi-calendar-refresh-outline:before {
  content: "󰈃";
}

.mdi-calendar-remove:before {
  content: "󰃴";
}

.mdi-calendar-remove-outline:before {
  content: "󰱅";
}

.mdi-calendar-search:before {
  content: "󰥌";
}

.mdi-calendar-search-outline:before {
  content: "󱭮";
}

.mdi-calendar-star:before {
  content: "󰧓";
}

.mdi-calendar-star-four-points:before {
  content: "󱰟";
}

.mdi-calendar-star-outline:before {
  content: "󱭓";
}

.mdi-calendar-start:before {
  content: "󱙭";
}

.mdi-calendar-start-outline:before {
  content: "󱭯";
}

.mdi-calendar-sync:before {
  content: "󰺎";
}

.mdi-calendar-sync-outline:before {
  content: "󰺏";
}

.mdi-calendar-text:before {
  content: "󰃵";
}

.mdi-calendar-text-outline:before {
  content: "󰱆";
}

.mdi-calendar-today:before {
  content: "󰃶";
}

.mdi-calendar-today-outline:before {
  content: "󱨰";
}

.mdi-calendar-week:before {
  content: "󰨳";
}

.mdi-calendar-week-begin:before {
  content: "󰨴";
}

.mdi-calendar-week-begin-outline:before {
  content: "󱨱";
}

.mdi-calendar-week-outline:before {
  content: "󱨴";
}

.mdi-calendar-weekend:before {
  content: "󰻙";
}

.mdi-calendar-weekend-outline:before {
  content: "󰻚";
}

.mdi-call-made:before {
  content: "󰃷";
}

.mdi-call-merge:before {
  content: "󰃸";
}

.mdi-call-missed:before {
  content: "󰃹";
}

.mdi-call-received:before {
  content: "󰃺";
}

.mdi-call-split:before {
  content: "󰃻";
}

.mdi-camcorder:before {
  content: "󰃼";
}

.mdi-camcorder-off:before {
  content: "󰃿";
}

.mdi-camera:before {
  content: "󰄀";
}

.mdi-camera-account:before {
  content: "󰣋";
}

.mdi-camera-burst:before {
  content: "󰚓";
}

.mdi-camera-control:before {
  content: "󰭩";
}

.mdi-camera-document:before {
  content: "󱡱";
}

.mdi-camera-document-off:before {
  content: "󱡲";
}

.mdi-camera-enhance:before {
  content: "󰄁";
}

.mdi-camera-enhance-outline:before {
  content: "󰭪";
}

.mdi-camera-flip:before {
  content: "󱗙";
}

.mdi-camera-flip-outline:before {
  content: "󱗚";
}

.mdi-camera-front:before {
  content: "󰄂";
}

.mdi-camera-front-variant:before {
  content: "󰄃";
}

.mdi-camera-gopro:before {
  content: "󰞡";
}

.mdi-camera-image:before {
  content: "󰣌";
}

.mdi-camera-iris:before {
  content: "󰄄";
}

.mdi-camera-lock:before {
  content: "󱨔";
}

.mdi-camera-lock-open:before {
  content: "󱰍";
}

.mdi-camera-lock-open-outline:before {
  content: "󱰎";
}

.mdi-camera-lock-outline:before {
  content: "󱨕";
}

.mdi-camera-marker:before {
  content: "󱦧";
}

.mdi-camera-marker-outline:before {
  content: "󱦨";
}

.mdi-camera-metering-center:before {
  content: "󰞢";
}

.mdi-camera-metering-matrix:before {
  content: "󰞣";
}

.mdi-camera-metering-partial:before {
  content: "󰞤";
}

.mdi-camera-metering-spot:before {
  content: "󰞥";
}

.mdi-camera-off:before {
  content: "󰗟";
}

.mdi-camera-off-outline:before {
  content: "󱦿";
}

.mdi-camera-outline:before {
  content: "󰵝";
}

.mdi-camera-party-mode:before {
  content: "󰄅";
}

.mdi-camera-plus:before {
  content: "󰻛";
}

.mdi-camera-plus-outline:before {
  content: "󰻜";
}

.mdi-camera-rear:before {
  content: "󰄆";
}

.mdi-camera-rear-variant:before {
  content: "󰄇";
}

.mdi-camera-retake:before {
  content: "󰸙";
}

.mdi-camera-retake-outline:before {
  content: "󰸚";
}

.mdi-camera-switch:before {
  content: "󰄈";
}

.mdi-camera-switch-outline:before {
  content: "󰡊";
}

.mdi-camera-timer:before {
  content: "󰄉";
}

.mdi-camera-wireless:before {
  content: "󰶶";
}

.mdi-camera-wireless-outline:before {
  content: "󰶷";
}

.mdi-campfire:before {
  content: "󰻝";
}

.mdi-cancel:before {
  content: "󰜺";
}

.mdi-candelabra:before {
  content: "󱟒";
}

.mdi-candelabra-fire:before {
  content: "󱟓";
}

.mdi-candle:before {
  content: "󰗢";
}

.mdi-candy:before {
  content: "󱥰";
}

.mdi-candy-off:before {
  content: "󱥱";
}

.mdi-candy-off-outline:before {
  content: "󱥲";
}

.mdi-candy-outline:before {
  content: "󱥳";
}

.mdi-candycane:before {
  content: "󰄊";
}

.mdi-cannabis:before {
  content: "󰞦";
}

.mdi-cannabis-off:before {
  content: "󱙮";
}

.mdi-caps-lock:before {
  content: "󰪛";
}

.mdi-car:before {
  content: "󰄋";
}

.mdi-car-2-plus:before {
  content: "󱀕";
}

.mdi-car-3-plus:before {
  content: "󱀖";
}

.mdi-car-arrow-left:before {
  content: "󱎲";
}

.mdi-car-arrow-right:before {
  content: "󱎳";
}

.mdi-car-back:before {
  content: "󰸛";
}

.mdi-car-battery:before {
  content: "󰄌";
}

.mdi-car-brake-abs:before {
  content: "󰱇";
}

.mdi-car-brake-alert:before {
  content: "󰱈";
}

.mdi-car-brake-fluid-level:before {
  content: "󱤉";
}

.mdi-car-brake-hold:before {
  content: "󰵞";
}

.mdi-car-brake-low-pressure:before {
  content: "󱤊";
}

.mdi-car-brake-parking:before {
  content: "󰵟";
}

.mdi-car-brake-retarder:before {
  content: "󱀗";
}

.mdi-car-brake-temperature:before {
  content: "󱤋";
}

.mdi-car-brake-worn-linings:before {
  content: "󱤌";
}

.mdi-car-child-seat:before {
  content: "󰾣";
}

.mdi-car-clock:before {
  content: "󱥴";
}

.mdi-car-clutch:before {
  content: "󱀘";
}

.mdi-car-cog:before {
  content: "󱏌";
}

.mdi-car-connected:before {
  content: "󰄍";
}

.mdi-car-convertible:before {
  content: "󰞧";
}

.mdi-car-coolant-level:before {
  content: "󱀙";
}

.mdi-car-cruise-control:before {
  content: "󰵠";
}

.mdi-car-defrost-front:before {
  content: "󰵡";
}

.mdi-car-defrost-rear:before {
  content: "󰵢";
}

.mdi-car-door:before {
  content: "󰭫";
}

.mdi-car-door-lock:before {
  content: "󱂝";
}

.mdi-car-electric:before {
  content: "󰭬";
}

.mdi-car-electric-outline:before {
  content: "󱖵";
}

.mdi-car-emergency:before {
  content: "󱘏";
}

.mdi-car-esp:before {
  content: "󰱉";
}

.mdi-car-estate:before {
  content: "󰞨";
}

.mdi-car-hatchback:before {
  content: "󰞩";
}

.mdi-car-info:before {
  content: "󱆾";
}

.mdi-car-key:before {
  content: "󰭭";
}

.mdi-car-lifted-pickup:before {
  content: "󱔭";
}

.mdi-car-light-alert:before {
  content: "󱤍";
}

.mdi-car-light-dimmed:before {
  content: "󰱊";
}

.mdi-car-light-fog:before {
  content: "󰱋";
}

.mdi-car-light-high:before {
  content: "󰱌";
}

.mdi-car-limousine:before {
  content: "󰣍";
}

.mdi-car-multiple:before {
  content: "󰭮";
}

.mdi-car-off:before {
  content: "󰸜";
}

.mdi-car-outline:before {
  content: "󱓭";
}

.mdi-car-parking-lights:before {
  content: "󰵣";
}

.mdi-car-pickup:before {
  content: "󰞪";
}

.mdi-car-search:before {
  content: "󱮍";
}

.mdi-car-search-outline:before {
  content: "󱮎";
}

.mdi-car-seat:before {
  content: "󰾤";
}

.mdi-car-seat-cooler:before {
  content: "󰾥";
}

.mdi-car-seat-heater:before {
  content: "󰾦";
}

.mdi-car-select:before {
  content: "󱡹";
}

.mdi-car-settings:before {
  content: "󱏍";
}

.mdi-car-shift-pattern:before {
  content: "󰽀";
}

.mdi-car-side:before {
  content: "󰞫";
}

.mdi-car-speed-limiter:before {
  content: "󱤎";
}

.mdi-car-sports:before {
  content: "󰞬";
}

.mdi-car-tire-alert:before {
  content: "󰱍";
}

.mdi-car-traction-control:before {
  content: "󰵤";
}

.mdi-car-turbocharger:before {
  content: "󱀚";
}

.mdi-car-wash:before {
  content: "󰄎";
}

.mdi-car-windshield:before {
  content: "󱀛";
}

.mdi-car-windshield-outline:before {
  content: "󱀜";
}

.mdi-car-wireless:before {
  content: "󱡸";
}

.mdi-car-wrench:before {
  content: "󱠔";
}

.mdi-carabiner:before {
  content: "󱓀";
}

.mdi-caravan:before {
  content: "󰞭";
}

.mdi-card:before {
  content: "󰭯";
}

.mdi-card-account-details:before {
  content: "󰗒";
}

.mdi-card-account-details-outline:before {
  content: "󰶫";
}

.mdi-card-account-details-star:before {
  content: "󰊣";
}

.mdi-card-account-details-star-outline:before {
  content: "󰛛";
}

.mdi-card-account-mail:before {
  content: "󰆎";
}

.mdi-card-account-mail-outline:before {
  content: "󰺘";
}

.mdi-card-account-phone:before {
  content: "󰺙";
}

.mdi-card-account-phone-outline:before {
  content: "󰺚";
}

.mdi-card-bulleted:before {
  content: "󰭰";
}

.mdi-card-bulleted-off:before {
  content: "󰭱";
}

.mdi-card-bulleted-off-outline:before {
  content: "󰭲";
}

.mdi-card-bulleted-outline:before {
  content: "󰭳";
}

.mdi-card-bulleted-settings:before {
  content: "󰭴";
}

.mdi-card-bulleted-settings-outline:before {
  content: "󰭵";
}

.mdi-card-minus:before {
  content: "󱘀";
}

.mdi-card-minus-outline:before {
  content: "󱘁";
}

.mdi-card-multiple:before {
  content: "󱟱";
}

.mdi-card-multiple-outline:before {
  content: "󱟲";
}

.mdi-card-off:before {
  content: "󱘂";
}

.mdi-card-off-outline:before {
  content: "󱘃";
}

.mdi-card-outline:before {
  content: "󰭶";
}

.mdi-card-plus:before {
  content: "󱇿";
}

.mdi-card-plus-outline:before {
  content: "󱈀";
}

.mdi-card-remove:before {
  content: "󱘄";
}

.mdi-card-remove-outline:before {
  content: "󱘅";
}

.mdi-card-search:before {
  content: "󱁴";
}

.mdi-card-search-outline:before {
  content: "󱁵";
}

.mdi-card-text:before {
  content: "󰭷";
}

.mdi-card-text-outline:before {
  content: "󰭸";
}

.mdi-cards:before {
  content: "󰘸";
}

.mdi-cards-club:before {
  content: "󰣎";
}

.mdi-cards-club-outline:before {
  content: "󱢟";
}

.mdi-cards-diamond:before {
  content: "󰣏";
}

.mdi-cards-diamond-outline:before {
  content: "󱀝";
}

.mdi-cards-heart:before {
  content: "󰣐";
}

.mdi-cards-heart-outline:before {
  content: "󱢠";
}

.mdi-cards-outline:before {
  content: "󰘹";
}

.mdi-cards-playing:before {
  content: "󱢡";
}

.mdi-cards-playing-club:before {
  content: "󱢢";
}

.mdi-cards-playing-club-multiple:before {
  content: "󱢣";
}

.mdi-cards-playing-club-multiple-outline:before {
  content: "󱢤";
}

.mdi-cards-playing-club-outline:before {
  content: "󱢥";
}

.mdi-cards-playing-diamond:before {
  content: "󱢦";
}

.mdi-cards-playing-diamond-multiple:before {
  content: "󱢧";
}

.mdi-cards-playing-diamond-multiple-outline:before {
  content: "󱢨";
}

.mdi-cards-playing-diamond-outline:before {
  content: "󱢩";
}

.mdi-cards-playing-heart:before {
  content: "󱢪";
}

.mdi-cards-playing-heart-multiple:before {
  content: "󱢫";
}

.mdi-cards-playing-heart-multiple-outline:before {
  content: "󱢬";
}

.mdi-cards-playing-heart-outline:before {
  content: "󱢭";
}

.mdi-cards-playing-outline:before {
  content: "󰘺";
}

.mdi-cards-playing-spade:before {
  content: "󱢮";
}

.mdi-cards-playing-spade-multiple:before {
  content: "󱢯";
}

.mdi-cards-playing-spade-multiple-outline:before {
  content: "󱢰";
}

.mdi-cards-playing-spade-outline:before {
  content: "󱢱";
}

.mdi-cards-spade:before {
  content: "󰣑";
}

.mdi-cards-spade-outline:before {
  content: "󱢲";
}

.mdi-cards-variant:before {
  content: "󰛇";
}

.mdi-carrot:before {
  content: "󰄏";
}

.mdi-cart:before {
  content: "󰄐";
}

.mdi-cart-arrow-down:before {
  content: "󰵦";
}

.mdi-cart-arrow-right:before {
  content: "󰱎";
}

.mdi-cart-arrow-up:before {
  content: "󰵧";
}

.mdi-cart-check:before {
  content: "󱗪";
}

.mdi-cart-heart:before {
  content: "󱣠";
}

.mdi-cart-minus:before {
  content: "󰵨";
}

.mdi-cart-off:before {
  content: "󰙫";
}

.mdi-cart-outline:before {
  content: "󰄑";
}

.mdi-cart-percent:before {
  content: "󱮮";
}

.mdi-cart-plus:before {
  content: "󰄒";
}

.mdi-cart-remove:before {
  content: "󰵩";
}

.mdi-cart-variant:before {
  content: "󱗫";
}

.mdi-case-sensitive-alt:before {
  content: "󰄓";
}

.mdi-cash:before {
  content: "󰄔";
}

.mdi-cash-100:before {
  content: "󰄕";
}

.mdi-cash-check:before {
  content: "󱓮";
}

.mdi-cash-clock:before {
  content: "󱪑";
}

.mdi-cash-fast:before {
  content: "󱡜";
}

.mdi-cash-lock:before {
  content: "󱓪";
}

.mdi-cash-lock-open:before {
  content: "󱓫";
}

.mdi-cash-marker:before {
  content: "󰶸";
}

.mdi-cash-minus:before {
  content: "󱉠";
}

.mdi-cash-multiple:before {
  content: "󰄖";
}

.mdi-cash-off:before {
  content: "󱱹";
}

.mdi-cash-plus:before {
  content: "󱉡";
}

.mdi-cash-refund:before {
  content: "󰪜";
}

.mdi-cash-register:before {
  content: "󰳴";
}

.mdi-cash-remove:before {
  content: "󱉢";
}

.mdi-cash-sync:before {
  content: "󱪒";
}

.mdi-cassette:before {
  content: "󰧔";
}

.mdi-cast:before {
  content: "󰄘";
}

.mdi-cast-audio:before {
  content: "󱀞";
}

.mdi-cast-audio-variant:before {
  content: "󱝉";
}

.mdi-cast-connected:before {
  content: "󰄙";
}

.mdi-cast-education:before {
  content: "󰸝";
}

.mdi-cast-off:before {
  content: "󰞊";
}

.mdi-cast-variant:before {
  content: "󰀟";
}

.mdi-castle:before {
  content: "󰄚";
}

.mdi-cat:before {
  content: "󰄛";
}

.mdi-cctv:before {
  content: "󰞮";
}

.mdi-cctv-off:before {
  content: "󱡟";
}

.mdi-ceiling-fan:before {
  content: "󱞗";
}

.mdi-ceiling-fan-light:before {
  content: "󱞘";
}

.mdi-ceiling-light:before {
  content: "󰝩";
}

.mdi-ceiling-light-multiple:before {
  content: "󱣝";
}

.mdi-ceiling-light-multiple-outline:before {
  content: "󱣞";
}

.mdi-ceiling-light-outline:before {
  content: "󱟇";
}

.mdi-cellphone:before {
  content: "󰄜";
}

.mdi-cellphone-arrow-down:before {
  content: "󰧕";
}

.mdi-cellphone-arrow-down-variant:before {
  content: "󱧅";
}

.mdi-cellphone-basic:before {
  content: "󰄞";
}

.mdi-cellphone-charging:before {
  content: "󱎗";
}

.mdi-cellphone-check:before {
  content: "󱟽";
}

.mdi-cellphone-cog:before {
  content: "󰥑";
}

.mdi-cellphone-dock:before {
  content: "󰄟";
}

.mdi-cellphone-information:before {
  content: "󰽁";
}

.mdi-cellphone-key:before {
  content: "󰥎";
}

.mdi-cellphone-link:before {
  content: "󰄡";
}

.mdi-cellphone-link-off:before {
  content: "󰄢";
}

.mdi-cellphone-lock:before {
  content: "󰥏";
}

.mdi-cellphone-marker:before {
  content: "󱠺";
}

.mdi-cellphone-message:before {
  content: "󰣓";
}

.mdi-cellphone-message-off:before {
  content: "󱃒";
}

.mdi-cellphone-nfc:before {
  content: "󰺐";
}

.mdi-cellphone-nfc-off:before {
  content: "󱋘";
}

.mdi-cellphone-off:before {
  content: "󰥐";
}

.mdi-cellphone-play:before {
  content: "󱀟";
}

.mdi-cellphone-remove:before {
  content: "󰥍";
}

.mdi-cellphone-screenshot:before {
  content: "󰨵";
}

.mdi-cellphone-settings:before {
  content: "󰄣";
}

.mdi-cellphone-sound:before {
  content: "󰥒";
}

.mdi-cellphone-text:before {
  content: "󰣒";
}

.mdi-cellphone-wireless:before {
  content: "󰠕";
}

.mdi-centos:before {
  content: "󱄚";
}

.mdi-certificate:before {
  content: "󰄤";
}

.mdi-certificate-outline:before {
  content: "󱆈";
}

.mdi-chair-rolling:before {
  content: "󰽈";
}

.mdi-chair-school:before {
  content: "󰄥";
}

.mdi-chandelier:before {
  content: "󱞓";
}

.mdi-charity:before {
  content: "󰱏";
}

.mdi-chart-arc:before {
  content: "󰄦";
}

.mdi-chart-areaspline:before {
  content: "󰄧";
}

.mdi-chart-areaspline-variant:before {
  content: "󰺑";
}

.mdi-chart-bar:before {
  content: "󰄨";
}

.mdi-chart-bar-stacked:before {
  content: "󰝪";
}

.mdi-chart-bell-curve:before {
  content: "󰱐";
}

.mdi-chart-bell-curve-cumulative:before {
  content: "󰾧";
}

.mdi-chart-box:before {
  content: "󱕍";
}

.mdi-chart-box-outline:before {
  content: "󱕎";
}

.mdi-chart-box-plus-outline:before {
  content: "󱕏";
}

.mdi-chart-bubble:before {
  content: "󰗣";
}

.mdi-chart-donut:before {
  content: "󰞯";
}

.mdi-chart-donut-variant:before {
  content: "󰞰";
}

.mdi-chart-gantt:before {
  content: "󰙬";
}

.mdi-chart-histogram:before {
  content: "󰄩";
}

.mdi-chart-line:before {
  content: "󰄪";
}

.mdi-chart-line-stacked:before {
  content: "󰝫";
}

.mdi-chart-line-variant:before {
  content: "󰞱";
}

.mdi-chart-multiline:before {
  content: "󰣔";
}

.mdi-chart-multiple:before {
  content: "󱈓";
}

.mdi-chart-pie:before {
  content: "󰄫";
}

.mdi-chart-pie-outline:before {
  content: "󱯟";
}

.mdi-chart-ppf:before {
  content: "󱎀";
}

.mdi-chart-sankey:before {
  content: "󱇟";
}

.mdi-chart-sankey-variant:before {
  content: "󱇠";
}

.mdi-chart-scatter-plot:before {
  content: "󰺒";
}

.mdi-chart-scatter-plot-hexbin:before {
  content: "󰙭";
}

.mdi-chart-timeline:before {
  content: "󰙮";
}

.mdi-chart-timeline-variant:before {
  content: "󰺓";
}

.mdi-chart-timeline-variant-shimmer:before {
  content: "󱖶";
}

.mdi-chart-tree:before {
  content: "󰺔";
}

.mdi-chart-waterfall:before {
  content: "󱤘";
}

.mdi-chat:before {
  content: "󰭹";
}

.mdi-chat-alert:before {
  content: "󰭺";
}

.mdi-chat-alert-outline:before {
  content: "󱋉";
}

.mdi-chat-minus:before {
  content: "󱐐";
}

.mdi-chat-minus-outline:before {
  content: "󱐓";
}

.mdi-chat-outline:before {
  content: "󰻞";
}

.mdi-chat-plus:before {
  content: "󱐏";
}

.mdi-chat-plus-outline:before {
  content: "󱐒";
}

.mdi-chat-processing:before {
  content: "󰭻";
}

.mdi-chat-processing-outline:before {
  content: "󱋊";
}

.mdi-chat-question:before {
  content: "󱜸";
}

.mdi-chat-question-outline:before {
  content: "󱜹";
}

.mdi-chat-remove:before {
  content: "󱐑";
}

.mdi-chat-remove-outline:before {
  content: "󱐔";
}

.mdi-chat-sleep:before {
  content: "󱋑";
}

.mdi-chat-sleep-outline:before {
  content: "󱋒";
}

.mdi-check:before {
  content: "󰄬";
}

.mdi-check-all:before {
  content: "󰄭";
}

.mdi-check-bold:before {
  content: "󰸞";
}

.mdi-check-circle:before {
  content: "󰗠";
}

.mdi-check-circle-outline:before {
  content: "󰗡";
}

.mdi-check-decagram:before {
  content: "󰞑";
}

.mdi-check-decagram-outline:before {
  content: "󱝀";
}

.mdi-check-network:before {
  content: "󰱓";
}

.mdi-check-network-outline:before {
  content: "󰱔";
}

.mdi-check-outline:before {
  content: "󰡕";
}

.mdi-check-underline:before {
  content: "󰸟";
}

.mdi-check-underline-circle:before {
  content: "󰸠";
}

.mdi-check-underline-circle-outline:before {
  content: "󰸡";
}

.mdi-checkbook:before {
  content: "󰪝";
}

.mdi-checkbook-arrow-left:before {
  content: "󱰝";
}

.mdi-checkbook-arrow-right:before {
  content: "󱰞";
}

.mdi-checkbox-blank:before {
  content: "󰄮";
}

.mdi-checkbox-blank-badge:before {
  content: "󱅶";
}

.mdi-checkbox-blank-badge-outline:before {
  content: "󰄗";
}

.mdi-checkbox-blank-circle:before {
  content: "󰄯";
}

.mdi-checkbox-blank-circle-outline:before {
  content: "󰄰";
}

.mdi-checkbox-blank-off:before {
  content: "󱋬";
}

.mdi-checkbox-blank-off-outline:before {
  content: "󱋭";
}

.mdi-checkbox-blank-outline:before {
  content: "󰄱";
}

.mdi-checkbox-intermediate:before {
  content: "󰡖";
}

.mdi-checkbox-intermediate-variant:before {
  content: "󱭔";
}

.mdi-checkbox-marked:before {
  content: "󰄲";
}

.mdi-checkbox-marked-circle:before {
  content: "󰄳";
}

.mdi-checkbox-marked-circle-auto-outline:before {
  content: "󱰦";
}

.mdi-checkbox-marked-circle-minus-outline:before {
  content: "󱰧";
}

.mdi-checkbox-marked-circle-outline:before {
  content: "󰄴";
}

.mdi-checkbox-marked-circle-plus-outline:before {
  content: "󱤧";
}

.mdi-checkbox-marked-outline:before {
  content: "󰄵";
}

.mdi-checkbox-multiple-blank:before {
  content: "󰄶";
}

.mdi-checkbox-multiple-blank-circle:before {
  content: "󰘻";
}

.mdi-checkbox-multiple-blank-circle-outline:before {
  content: "󰘼";
}

.mdi-checkbox-multiple-blank-outline:before {
  content: "󰄷";
}

.mdi-checkbox-multiple-marked:before {
  content: "󰄸";
}

.mdi-checkbox-multiple-marked-circle:before {
  content: "󰘽";
}

.mdi-checkbox-multiple-marked-circle-outline:before {
  content: "󰘾";
}

.mdi-checkbox-multiple-marked-outline:before {
  content: "󰄹";
}

.mdi-checkbox-multiple-outline:before {
  content: "󰱑";
}

.mdi-checkbox-outline:before {
  content: "󰱒";
}

.mdi-checkerboard:before {
  content: "󰄺";
}

.mdi-checkerboard-minus:before {
  content: "󱈂";
}

.mdi-checkerboard-plus:before {
  content: "󱈁";
}

.mdi-checkerboard-remove:before {
  content: "󱈃";
}

.mdi-cheese:before {
  content: "󱊹";
}

.mdi-cheese-off:before {
  content: "󱏮";
}

.mdi-chef-hat:before {
  content: "󰭼";
}

.mdi-chemical-weapon:before {
  content: "󰄻";
}

.mdi-chess-bishop:before {
  content: "󰡜";
}

.mdi-chess-king:before {
  content: "󰡗";
}

.mdi-chess-knight:before {
  content: "󰡘";
}

.mdi-chess-pawn:before {
  content: "󰡙";
}

.mdi-chess-queen:before {
  content: "󰡚";
}

.mdi-chess-rook:before {
  content: "󰡛";
}

.mdi-chevron-double-down:before {
  content: "󰄼";
}

.mdi-chevron-double-left:before {
  content: "󰄽";
}

.mdi-chevron-double-right:before {
  content: "󰄾";
}

.mdi-chevron-double-up:before {
  content: "󰄿";
}

.mdi-chevron-down:before {
  content: "󰅀";
}

.mdi-chevron-down-box:before {
  content: "󰧖";
}

.mdi-chevron-down-box-outline:before {
  content: "󰧗";
}

.mdi-chevron-down-circle:before {
  content: "󰬦";
}

.mdi-chevron-down-circle-outline:before {
  content: "󰬧";
}

.mdi-chevron-left:before {
  content: "󰅁";
}

.mdi-chevron-left-box:before {
  content: "󰧘";
}

.mdi-chevron-left-box-outline:before {
  content: "󰧙";
}

.mdi-chevron-left-circle:before {
  content: "󰬨";
}

.mdi-chevron-left-circle-outline:before {
  content: "󰬩";
}

.mdi-chevron-right:before {
  content: "󰅂";
}

.mdi-chevron-right-box:before {
  content: "󰧚";
}

.mdi-chevron-right-box-outline:before {
  content: "󰧛";
}

.mdi-chevron-right-circle:before {
  content: "󰬪";
}

.mdi-chevron-right-circle-outline:before {
  content: "󰬫";
}

.mdi-chevron-triple-down:before {
  content: "󰶹";
}

.mdi-chevron-triple-left:before {
  content: "󰶺";
}

.mdi-chevron-triple-right:before {
  content: "󰶻";
}

.mdi-chevron-triple-up:before {
  content: "󰶼";
}

.mdi-chevron-up:before {
  content: "󰅃";
}

.mdi-chevron-up-box:before {
  content: "󰧜";
}

.mdi-chevron-up-box-outline:before {
  content: "󰧝";
}

.mdi-chevron-up-circle:before {
  content: "󰬬";
}

.mdi-chevron-up-circle-outline:before {
  content: "󰬭";
}

.mdi-chili-alert:before {
  content: "󱟪";
}

.mdi-chili-alert-outline:before {
  content: "󱟫";
}

.mdi-chili-hot:before {
  content: "󰞲";
}

.mdi-chili-hot-outline:before {
  content: "󱟬";
}

.mdi-chili-medium:before {
  content: "󰞳";
}

.mdi-chili-medium-outline:before {
  content: "󱟭";
}

.mdi-chili-mild:before {
  content: "󰞴";
}

.mdi-chili-mild-outline:before {
  content: "󱟮";
}

.mdi-chili-off:before {
  content: "󱑧";
}

.mdi-chili-off-outline:before {
  content: "󱟯";
}

.mdi-chip:before {
  content: "󰘚";
}

.mdi-church:before {
  content: "󰅄";
}

.mdi-church-outline:before {
  content: "󱬂";
}

.mdi-cigar:before {
  content: "󱆉";
}

.mdi-cigar-off:before {
  content: "󱐛";
}

.mdi-circle:before {
  content: "󰝥";
}

.mdi-circle-box:before {
  content: "󱗜";
}

.mdi-circle-box-outline:before {
  content: "󱗝";
}

.mdi-circle-double:before {
  content: "󰺕";
}

.mdi-circle-edit-outline:before {
  content: "󰣕";
}

.mdi-circle-expand:before {
  content: "󰺖";
}

.mdi-circle-half:before {
  content: "󱎕";
}

.mdi-circle-half-full:before {
  content: "󱎖";
}

.mdi-circle-medium:before {
  content: "󰧞";
}

.mdi-circle-multiple:before {
  content: "󰬸";
}

.mdi-circle-multiple-outline:before {
  content: "󰚕";
}

.mdi-circle-off-outline:before {
  content: "󱃓";
}

.mdi-circle-opacity:before {
  content: "󱡓";
}

.mdi-circle-outline:before {
  content: "󰝦";
}

.mdi-circle-slice-1:before {
  content: "󰪞";
}

.mdi-circle-slice-2:before {
  content: "󰪟";
}

.mdi-circle-slice-3:before {
  content: "󰪠";
}

.mdi-circle-slice-4:before {
  content: "󰪡";
}

.mdi-circle-slice-5:before {
  content: "󰪢";
}

.mdi-circle-slice-6:before {
  content: "󰪣";
}

.mdi-circle-slice-7:before {
  content: "󰪤";
}

.mdi-circle-slice-8:before {
  content: "󰪥";
}

.mdi-circle-small:before {
  content: "󰧟";
}

.mdi-circular-saw:before {
  content: "󰸢";
}

.mdi-city:before {
  content: "󰅆";
}

.mdi-city-switch:before {
  content: "󱰨";
}

.mdi-city-variant:before {
  content: "󰨶";
}

.mdi-city-variant-outline:before {
  content: "󰨷";
}

.mdi-clipboard:before {
  content: "󰅇";
}

.mdi-clipboard-account:before {
  content: "󰅈";
}

.mdi-clipboard-account-outline:before {
  content: "󰱕";
}

.mdi-clipboard-alert:before {
  content: "󰅉";
}

.mdi-clipboard-alert-outline:before {
  content: "󰳷";
}

.mdi-clipboard-arrow-down:before {
  content: "󰅊";
}

.mdi-clipboard-arrow-down-outline:before {
  content: "󰱖";
}

.mdi-clipboard-arrow-left:before {
  content: "󰅋";
}

.mdi-clipboard-arrow-left-outline:before {
  content: "󰳸";
}

.mdi-clipboard-arrow-right:before {
  content: "󰳹";
}

.mdi-clipboard-arrow-right-outline:before {
  content: "󰳺";
}

.mdi-clipboard-arrow-up:before {
  content: "󰱗";
}

.mdi-clipboard-arrow-up-outline:before {
  content: "󰱘";
}

.mdi-clipboard-check:before {
  content: "󰅎";
}

.mdi-clipboard-check-multiple:before {
  content: "󱉣";
}

.mdi-clipboard-check-multiple-outline:before {
  content: "󱉤";
}

.mdi-clipboard-check-outline:before {
  content: "󰢨";
}

.mdi-clipboard-clock:before {
  content: "󱛢";
}

.mdi-clipboard-clock-outline:before {
  content: "󱛣";
}

.mdi-clipboard-edit:before {
  content: "󱓥";
}

.mdi-clipboard-edit-outline:before {
  content: "󱓦";
}

.mdi-clipboard-file:before {
  content: "󱉥";
}

.mdi-clipboard-file-outline:before {
  content: "󱉦";
}

.mdi-clipboard-flow:before {
  content: "󰛈";
}

.mdi-clipboard-flow-outline:before {
  content: "󱄗";
}

.mdi-clipboard-list:before {
  content: "󱃔";
}

.mdi-clipboard-list-outline:before {
  content: "󱃕";
}

.mdi-clipboard-minus:before {
  content: "󱘘";
}

.mdi-clipboard-minus-outline:before {
  content: "󱘙";
}

.mdi-clipboard-multiple:before {
  content: "󱉧";
}

.mdi-clipboard-multiple-outline:before {
  content: "󱉨";
}

.mdi-clipboard-off:before {
  content: "󱘚";
}

.mdi-clipboard-off-outline:before {
  content: "󱘛";
}

.mdi-clipboard-outline:before {
  content: "󰅌";
}

.mdi-clipboard-play:before {
  content: "󰱙";
}

.mdi-clipboard-play-multiple:before {
  content: "󱉩";
}

.mdi-clipboard-play-multiple-outline:before {
  content: "󱉪";
}

.mdi-clipboard-play-outline:before {
  content: "󰱚";
}

.mdi-clipboard-plus:before {
  content: "󰝑";
}

.mdi-clipboard-plus-outline:before {
  content: "󱌟";
}

.mdi-clipboard-pulse:before {
  content: "󰡝";
}

.mdi-clipboard-pulse-outline:before {
  content: "󰡞";
}

.mdi-clipboard-remove:before {
  content: "󱘜";
}

.mdi-clipboard-remove-outline:before {
  content: "󱘝";
}

.mdi-clipboard-search:before {
  content: "󱘞";
}

.mdi-clipboard-search-outline:before {
  content: "󱘟";
}

.mdi-clipboard-text:before {
  content: "󰅍";
}

.mdi-clipboard-text-clock:before {
  content: "󱣹";
}

.mdi-clipboard-text-clock-outline:before {
  content: "󱣺";
}

.mdi-clipboard-text-multiple:before {
  content: "󱉫";
}

.mdi-clipboard-text-multiple-outline:before {
  content: "󱉬";
}

.mdi-clipboard-text-off:before {
  content: "󱘠";
}

.mdi-clipboard-text-off-outline:before {
  content: "󱘡";
}

.mdi-clipboard-text-outline:before {
  content: "󰨸";
}

.mdi-clipboard-text-play:before {
  content: "󰱛";
}

.mdi-clipboard-text-play-outline:before {
  content: "󰱜";
}

.mdi-clipboard-text-search:before {
  content: "󱘢";
}

.mdi-clipboard-text-search-outline:before {
  content: "󱘣";
}

.mdi-clippy:before {
  content: "󰅏";
}

.mdi-clock:before {
  content: "󰥔";
}

.mdi-clock-alert:before {
  content: "󰥕";
}

.mdi-clock-alert-outline:before {
  content: "󰗎";
}

.mdi-clock-check:before {
  content: "󰾨";
}

.mdi-clock-check-outline:before {
  content: "󰾩";
}

.mdi-clock-digital:before {
  content: "󰺗";
}

.mdi-clock-edit:before {
  content: "󱦺";
}

.mdi-clock-edit-outline:before {
  content: "󱦻";
}

.mdi-clock-end:before {
  content: "󰅑";
}

.mdi-clock-fast:before {
  content: "󰅒";
}

.mdi-clock-in:before {
  content: "󰅓";
}

.mdi-clock-minus:before {
  content: "󱡣";
}

.mdi-clock-minus-outline:before {
  content: "󱡤";
}

.mdi-clock-out:before {
  content: "󰅔";
}

.mdi-clock-outline:before {
  content: "󰅐";
}

.mdi-clock-plus:before {
  content: "󱡡";
}

.mdi-clock-plus-outline:before {
  content: "󱡢";
}

.mdi-clock-remove:before {
  content: "󱡥";
}

.mdi-clock-remove-outline:before {
  content: "󱡦";
}

.mdi-clock-star-four-points:before {
  content: "󱰩";
}

.mdi-clock-star-four-points-outline:before {
  content: "󱰪";
}

.mdi-clock-start:before {
  content: "󰅕";
}

.mdi-clock-time-eight:before {
  content: "󱑆";
}

.mdi-clock-time-eight-outline:before {
  content: "󱑒";
}

.mdi-clock-time-eleven:before {
  content: "󱑉";
}

.mdi-clock-time-eleven-outline:before {
  content: "󱑕";
}

.mdi-clock-time-five:before {
  content: "󱑃";
}

.mdi-clock-time-five-outline:before {
  content: "󱑏";
}

.mdi-clock-time-four:before {
  content: "󱑂";
}

.mdi-clock-time-four-outline:before {
  content: "󱑎";
}

.mdi-clock-time-nine:before {
  content: "󱑇";
}

.mdi-clock-time-nine-outline:before {
  content: "󱑓";
}

.mdi-clock-time-one:before {
  content: "󱐿";
}

.mdi-clock-time-one-outline:before {
  content: "󱑋";
}

.mdi-clock-time-seven:before {
  content: "󱑅";
}

.mdi-clock-time-seven-outline:before {
  content: "󱑑";
}

.mdi-clock-time-six:before {
  content: "󱑄";
}

.mdi-clock-time-six-outline:before {
  content: "󱑐";
}

.mdi-clock-time-ten:before {
  content: "󱑈";
}

.mdi-clock-time-ten-outline:before {
  content: "󱑔";
}

.mdi-clock-time-three:before {
  content: "󱑁";
}

.mdi-clock-time-three-outline:before {
  content: "󱑍";
}

.mdi-clock-time-twelve:before {
  content: "󱑊";
}

.mdi-clock-time-twelve-outline:before {
  content: "󱑖";
}

.mdi-clock-time-two:before {
  content: "󱑀";
}

.mdi-clock-time-two-outline:before {
  content: "󱑌";
}

.mdi-close:before {
  content: "󰅖";
}

.mdi-close-box:before {
  content: "󰅗";
}

.mdi-close-box-multiple:before {
  content: "󰱝";
}

.mdi-close-box-multiple-outline:before {
  content: "󰱞";
}

.mdi-close-box-outline:before {
  content: "󰅘";
}

.mdi-close-circle:before {
  content: "󰅙";
}

.mdi-close-circle-multiple:before {
  content: "󰘪";
}

.mdi-close-circle-multiple-outline:before {
  content: "󰢃";
}

.mdi-close-circle-outline:before {
  content: "󰅚";
}

.mdi-close-network:before {
  content: "󰅛";
}

.mdi-close-network-outline:before {
  content: "󰱟";
}

.mdi-close-octagon:before {
  content: "󰅜";
}

.mdi-close-octagon-outline:before {
  content: "󰅝";
}

.mdi-close-outline:before {
  content: "󰛉";
}

.mdi-close-thick:before {
  content: "󱎘";
}

.mdi-closed-caption:before {
  content: "󰅞";
}

.mdi-closed-caption-outline:before {
  content: "󰶽";
}

.mdi-cloud:before {
  content: "󰅟";
}

.mdi-cloud-alert:before {
  content: "󰧠";
}

.mdi-cloud-alert-outline:before {
  content: "󱯠";
}

.mdi-cloud-arrow-down:before {
  content: "󱯡";
}

.mdi-cloud-arrow-down-outline:before {
  content: "󱯢";
}

.mdi-cloud-arrow-left:before {
  content: "󱯣";
}

.mdi-cloud-arrow-left-outline:before {
  content: "󱯤";
}

.mdi-cloud-arrow-right:before {
  content: "󱯥";
}

.mdi-cloud-arrow-right-outline:before {
  content: "󱯦";
}

.mdi-cloud-arrow-up:before {
  content: "󱯧";
}

.mdi-cloud-arrow-up-outline:before {
  content: "󱯨";
}

.mdi-cloud-braces:before {
  content: "󰞵";
}

.mdi-cloud-cancel:before {
  content: "󱯩";
}

.mdi-cloud-cancel-outline:before {
  content: "󱯪";
}

.mdi-cloud-check:before {
  content: "󱯫";
}

.mdi-cloud-check-outline:before {
  content: "󱯬";
}

.mdi-cloud-check-variant:before {
  content: "󰅠";
}

.mdi-cloud-check-variant-outline:before {
  content: "󱋌";
}

.mdi-cloud-circle:before {
  content: "󰅡";
}

.mdi-cloud-circle-outline:before {
  content: "󱯭";
}

.mdi-cloud-clock:before {
  content: "󱯮";
}

.mdi-cloud-clock-outline:before {
  content: "󱯯";
}

.mdi-cloud-cog:before {
  content: "󱯰";
}

.mdi-cloud-cog-outline:before {
  content: "󱯱";
}

.mdi-cloud-download:before {
  content: "󰅢";
}

.mdi-cloud-download-outline:before {
  content: "󰭽";
}

.mdi-cloud-lock:before {
  content: "󱇱";
}

.mdi-cloud-lock-open:before {
  content: "󱯲";
}

.mdi-cloud-lock-open-outline:before {
  content: "󱯳";
}

.mdi-cloud-lock-outline:before {
  content: "󱇲";
}

.mdi-cloud-minus:before {
  content: "󱯴";
}

.mdi-cloud-minus-outline:before {
  content: "󱯵";
}

.mdi-cloud-off:before {
  content: "󱯶";
}

.mdi-cloud-off-outline:before {
  content: "󰅤";
}

.mdi-cloud-outline:before {
  content: "󰅣";
}

.mdi-cloud-percent:before {
  content: "󱨵";
}

.mdi-cloud-percent-outline:before {
  content: "󱨶";
}

.mdi-cloud-plus:before {
  content: "󱯷";
}

.mdi-cloud-plus-outline:before {
  content: "󱯸";
}

.mdi-cloud-print:before {
  content: "󰅥";
}

.mdi-cloud-print-outline:before {
  content: "󰅦";
}

.mdi-cloud-question:before {
  content: "󰨹";
}

.mdi-cloud-question-outline:before {
  content: "󱯹";
}

.mdi-cloud-refresh:before {
  content: "󱯺";
}

.mdi-cloud-refresh-outline:before {
  content: "󱯻";
}

.mdi-cloud-refresh-variant:before {
  content: "󰔪";
}

.mdi-cloud-refresh-variant-outline:before {
  content: "󱯼";
}

.mdi-cloud-remove:before {
  content: "󱯽";
}

.mdi-cloud-remove-outline:before {
  content: "󱯾";
}

.mdi-cloud-search:before {
  content: "󰥖";
}

.mdi-cloud-search-outline:before {
  content: "󰥗";
}

.mdi-cloud-sync:before {
  content: "󰘿";
}

.mdi-cloud-sync-outline:before {
  content: "󱋖";
}

.mdi-cloud-tags:before {
  content: "󰞶";
}

.mdi-cloud-upload:before {
  content: "󰅧";
}

.mdi-cloud-upload-outline:before {
  content: "󰭾";
}

.mdi-clouds:before {
  content: "󱮕";
}

.mdi-clover:before {
  content: "󰠖";
}

.mdi-clover-outline:before {
  content: "󱱢";
}

.mdi-coach-lamp:before {
  content: "󱀠";
}

.mdi-coach-lamp-variant:before {
  content: "󱨷";
}

.mdi-coat-rack:before {
  content: "󱂞";
}

.mdi-code-array:before {
  content: "󰅨";
}

.mdi-code-braces:before {
  content: "󰅩";
}

.mdi-code-braces-box:before {
  content: "󱃖";
}

.mdi-code-brackets:before {
  content: "󰅪";
}

.mdi-code-equal:before {
  content: "󰅫";
}

.mdi-code-greater-than:before {
  content: "󰅬";
}

.mdi-code-greater-than-or-equal:before {
  content: "󰅭";
}

.mdi-code-json:before {
  content: "󰘦";
}

.mdi-code-less-than:before {
  content: "󰅮";
}

.mdi-code-less-than-or-equal:before {
  content: "󰅯";
}

.mdi-code-not-equal:before {
  content: "󰅰";
}

.mdi-code-not-equal-variant:before {
  content: "󰅱";
}

.mdi-code-parentheses:before {
  content: "󰅲";
}

.mdi-code-parentheses-box:before {
  content: "󱃗";
}

.mdi-code-string:before {
  content: "󰅳";
}

.mdi-code-tags:before {
  content: "󰅴";
}

.mdi-code-tags-check:before {
  content: "󰚔";
}

.mdi-codepen:before {
  content: "󰅵";
}

.mdi-coffee:before {
  content: "󰅶";
}

.mdi-coffee-maker:before {
  content: "󱂟";
}

.mdi-coffee-maker-check:before {
  content: "󱤱";
}

.mdi-coffee-maker-check-outline:before {
  content: "󱤲";
}

.mdi-coffee-maker-outline:before {
  content: "󱠛";
}

.mdi-coffee-off:before {
  content: "󰾪";
}

.mdi-coffee-off-outline:before {
  content: "󰾫";
}

.mdi-coffee-outline:before {
  content: "󰛊";
}

.mdi-coffee-to-go:before {
  content: "󰅷";
}

.mdi-coffee-to-go-outline:before {
  content: "󱌎";
}

.mdi-coffin:before {
  content: "󰭿";
}

.mdi-cog:before {
  content: "󰒓";
}

.mdi-cog-box:before {
  content: "󰒔";
}

.mdi-cog-clockwise:before {
  content: "󱇝";
}

.mdi-cog-counterclockwise:before {
  content: "󱇞";
}

.mdi-cog-off:before {
  content: "󱏎";
}

.mdi-cog-off-outline:before {
  content: "󱏏";
}

.mdi-cog-outline:before {
  content: "󰢻";
}

.mdi-cog-pause:before {
  content: "󱤳";
}

.mdi-cog-pause-outline:before {
  content: "󱤴";
}

.mdi-cog-play:before {
  content: "󱤵";
}

.mdi-cog-play-outline:before {
  content: "󱤶";
}

.mdi-cog-refresh:before {
  content: "󱑞";
}

.mdi-cog-refresh-outline:before {
  content: "󱑟";
}

.mdi-cog-stop:before {
  content: "󱤷";
}

.mdi-cog-stop-outline:before {
  content: "󱤸";
}

.mdi-cog-sync:before {
  content: "󱑠";
}

.mdi-cog-sync-outline:before {
  content: "󱑡";
}

.mdi-cog-transfer:before {
  content: "󱁛";
}

.mdi-cog-transfer-outline:before {
  content: "󱁜";
}

.mdi-cogs:before {
  content: "󰣖";
}

.mdi-collage:before {
  content: "󰙀";
}

.mdi-collapse-all:before {
  content: "󰪦";
}

.mdi-collapse-all-outline:before {
  content: "󰪧";
}

.mdi-color-helper:before {
  content: "󰅹";
}

.mdi-comma:before {
  content: "󰸣";
}

.mdi-comma-box:before {
  content: "󰸫";
}

.mdi-comma-box-outline:before {
  content: "󰸤";
}

.mdi-comma-circle:before {
  content: "󰸥";
}

.mdi-comma-circle-outline:before {
  content: "󰸦";
}

.mdi-comment:before {
  content: "󰅺";
}

.mdi-comment-account:before {
  content: "󰅻";
}

.mdi-comment-account-outline:before {
  content: "󰅼";
}

.mdi-comment-alert:before {
  content: "󰅽";
}

.mdi-comment-alert-outline:before {
  content: "󰅾";
}

.mdi-comment-arrow-left:before {
  content: "󰧡";
}

.mdi-comment-arrow-left-outline:before {
  content: "󰧢";
}

.mdi-comment-arrow-right:before {
  content: "󰧣";
}

.mdi-comment-arrow-right-outline:before {
  content: "󰧤";
}

.mdi-comment-bookmark:before {
  content: "󱖮";
}

.mdi-comment-bookmark-outline:before {
  content: "󱖯";
}

.mdi-comment-check:before {
  content: "󰅿";
}

.mdi-comment-check-outline:before {
  content: "󰆀";
}

.mdi-comment-edit:before {
  content: "󱆿";
}

.mdi-comment-edit-outline:before {
  content: "󱋄";
}

.mdi-comment-eye:before {
  content: "󰨺";
}

.mdi-comment-eye-outline:before {
  content: "󰨻";
}

.mdi-comment-flash:before {
  content: "󱖰";
}

.mdi-comment-flash-outline:before {
  content: "󱖱";
}

.mdi-comment-minus:before {
  content: "󱗟";
}

.mdi-comment-minus-outline:before {
  content: "󱗠";
}

.mdi-comment-multiple:before {
  content: "󰡟";
}

.mdi-comment-multiple-outline:before {
  content: "󰆁";
}

.mdi-comment-off:before {
  content: "󱗡";
}

.mdi-comment-off-outline:before {
  content: "󱗢";
}

.mdi-comment-outline:before {
  content: "󰆂";
}

.mdi-comment-plus:before {
  content: "󰧥";
}

.mdi-comment-plus-outline:before {
  content: "󰆃";
}

.mdi-comment-processing:before {
  content: "󰆄";
}

.mdi-comment-processing-outline:before {
  content: "󰆅";
}

.mdi-comment-question:before {
  content: "󰠗";
}

.mdi-comment-question-outline:before {
  content: "󰆆";
}

.mdi-comment-quote:before {
  content: "󱀡";
}

.mdi-comment-quote-outline:before {
  content: "󱀢";
}

.mdi-comment-remove:before {
  content: "󰗞";
}

.mdi-comment-remove-outline:before {
  content: "󰆇";
}

.mdi-comment-search:before {
  content: "󰨼";
}

.mdi-comment-search-outline:before {
  content: "󰨽";
}

.mdi-comment-text:before {
  content: "󰆈";
}

.mdi-comment-text-multiple:before {
  content: "󰡠";
}

.mdi-comment-text-multiple-outline:before {
  content: "󰡡";
}

.mdi-comment-text-outline:before {
  content: "󰆉";
}

.mdi-compare:before {
  content: "󰆊";
}

.mdi-compare-horizontal:before {
  content: "󱒒";
}

.mdi-compare-remove:before {
  content: "󱢳";
}

.mdi-compare-vertical:before {
  content: "󱒓";
}

.mdi-compass:before {
  content: "󰆋";
}

.mdi-compass-off:before {
  content: "󰮀";
}

.mdi-compass-off-outline:before {
  content: "󰮁";
}

.mdi-compass-outline:before {
  content: "󰆌";
}

.mdi-compass-rose:before {
  content: "󱎂";
}

.mdi-compost:before {
  content: "󱨸";
}

.mdi-cone:before {
  content: "󱥌";
}

.mdi-cone-off:before {
  content: "󱥍";
}

.mdi-connection:before {
  content: "󱘖";
}

.mdi-console:before {
  content: "󰆍";
}

.mdi-console-line:before {
  content: "󰞷";
}

.mdi-console-network:before {
  content: "󰢩";
}

.mdi-console-network-outline:before {
  content: "󰱠";
}

.mdi-consolidate:before {
  content: "󱃘";
}

.mdi-contactless-payment:before {
  content: "󰵪";
}

.mdi-contactless-payment-circle:before {
  content: "󰌡";
}

.mdi-contactless-payment-circle-outline:before {
  content: "󰐈";
}

.mdi-contacts:before {
  content: "󰛋";
}

.mdi-contacts-outline:before {
  content: "󰖸";
}

.mdi-contain:before {
  content: "󰨾";
}

.mdi-contain-end:before {
  content: "󰨿";
}

.mdi-contain-start:before {
  content: "󰩀";
}

.mdi-content-copy:before {
  content: "󰆏";
}

.mdi-content-cut:before {
  content: "󰆐";
}

.mdi-content-duplicate:before {
  content: "󰆑";
}

.mdi-content-paste:before {
  content: "󰆒";
}

.mdi-content-save:before {
  content: "󰆓";
}

.mdi-content-save-alert:before {
  content: "󰽂";
}

.mdi-content-save-alert-outline:before {
  content: "󰽃";
}

.mdi-content-save-all:before {
  content: "󰆔";
}

.mdi-content-save-all-outline:before {
  content: "󰽄";
}

.mdi-content-save-check:before {
  content: "󱣪";
}

.mdi-content-save-check-outline:before {
  content: "󱣫";
}

.mdi-content-save-cog:before {
  content: "󱑛";
}

.mdi-content-save-cog-outline:before {
  content: "󱑜";
}

.mdi-content-save-edit:before {
  content: "󰳻";
}

.mdi-content-save-edit-outline:before {
  content: "󰳼";
}

.mdi-content-save-minus:before {
  content: "󱭃";
}

.mdi-content-save-minus-outline:before {
  content: "󱭄";
}

.mdi-content-save-move:before {
  content: "󰸧";
}

.mdi-content-save-move-outline:before {
  content: "󰸨";
}

.mdi-content-save-off:before {
  content: "󱙃";
}

.mdi-content-save-off-outline:before {
  content: "󱙄";
}

.mdi-content-save-outline:before {
  content: "󰠘";
}

.mdi-content-save-plus:before {
  content: "󱭁";
}

.mdi-content-save-plus-outline:before {
  content: "󱭂";
}

.mdi-content-save-settings:before {
  content: "󰘛";
}

.mdi-content-save-settings-outline:before {
  content: "󰬮";
}

.mdi-contrast:before {
  content: "󰆕";
}

.mdi-contrast-box:before {
  content: "󰆖";
}

.mdi-contrast-circle:before {
  content: "󰆗";
}

.mdi-controller:before {
  content: "󰊴";
}

.mdi-controller-classic:before {
  content: "󰮂";
}

.mdi-controller-classic-outline:before {
  content: "󰮃";
}

.mdi-controller-off:before {
  content: "󰊵";
}

.mdi-cookie:before {
  content: "󰆘";
}

.mdi-cookie-alert:before {
  content: "󱛐";
}

.mdi-cookie-alert-outline:before {
  content: "󱛑";
}

.mdi-cookie-check:before {
  content: "󱛒";
}

.mdi-cookie-check-outline:before {
  content: "󱛓";
}

.mdi-cookie-clock:before {
  content: "󱛤";
}

.mdi-cookie-clock-outline:before {
  content: "󱛥";
}

.mdi-cookie-cog:before {
  content: "󱛔";
}

.mdi-cookie-cog-outline:before {
  content: "󱛕";
}

.mdi-cookie-edit:before {
  content: "󱛦";
}

.mdi-cookie-edit-outline:before {
  content: "󱛧";
}

.mdi-cookie-lock:before {
  content: "󱛨";
}

.mdi-cookie-lock-outline:before {
  content: "󱛩";
}

.mdi-cookie-minus:before {
  content: "󱛚";
}

.mdi-cookie-minus-outline:before {
  content: "󱛛";
}

.mdi-cookie-off:before {
  content: "󱛪";
}

.mdi-cookie-off-outline:before {
  content: "󱛫";
}

.mdi-cookie-outline:before {
  content: "󱛞";
}

.mdi-cookie-plus:before {
  content: "󱛖";
}

.mdi-cookie-plus-outline:before {
  content: "󱛗";
}

.mdi-cookie-refresh:before {
  content: "󱛬";
}

.mdi-cookie-refresh-outline:before {
  content: "󱛭";
}

.mdi-cookie-remove:before {
  content: "󱛘";
}

.mdi-cookie-remove-outline:before {
  content: "󱛙";
}

.mdi-cookie-settings:before {
  content: "󱛜";
}

.mdi-cookie-settings-outline:before {
  content: "󱛝";
}

.mdi-coolant-temperature:before {
  content: "󰏈";
}

.mdi-copyleft:before {
  content: "󱤹";
}

.mdi-copyright:before {
  content: "󰗦";
}

.mdi-cordova:before {
  content: "󰥘";
}

.mdi-corn:before {
  content: "󰞸";
}

.mdi-corn-off:before {
  content: "󱏯";
}

.mdi-cosine-wave:before {
  content: "󱑹";
}

.mdi-counter:before {
  content: "󰆙";
}

.mdi-countertop:before {
  content: "󱠜";
}

.mdi-countertop-outline:before {
  content: "󱠝";
}

.mdi-cow:before {
  content: "󰆚";
}

.mdi-cow-off:before {
  content: "󱣼";
}

.mdi-cpu-32-bit:before {
  content: "󰻟";
}

.mdi-cpu-64-bit:before {
  content: "󰻠";
}

.mdi-cradle:before {
  content: "󱦋";
}

.mdi-cradle-outline:before {
  content: "󱦑";
}

.mdi-crane:before {
  content: "󰡢";
}

.mdi-creation:before {
  content: "󰙴";
}

.mdi-creation-outline:before {
  content: "󱰫";
}

.mdi-creative-commons:before {
  content: "󰵫";
}

.mdi-credit-card:before {
  content: "󰿯";
}

.mdi-credit-card-check:before {
  content: "󱏐";
}

.mdi-credit-card-check-outline:before {
  content: "󱏑";
}

.mdi-credit-card-chip:before {
  content: "󱤏";
}

.mdi-credit-card-chip-outline:before {
  content: "󱤐";
}

.mdi-credit-card-clock:before {
  content: "󰻡";
}

.mdi-credit-card-clock-outline:before {
  content: "󰻢";
}

.mdi-credit-card-edit:before {
  content: "󱟗";
}

.mdi-credit-card-edit-outline:before {
  content: "󱟘";
}

.mdi-credit-card-fast:before {
  content: "󱤑";
}

.mdi-credit-card-fast-outline:before {
  content: "󱤒";
}

.mdi-credit-card-lock:before {
  content: "󱣧";
}

.mdi-credit-card-lock-outline:before {
  content: "󱣨";
}

.mdi-credit-card-marker:before {
  content: "󰚨";
}

.mdi-credit-card-marker-outline:before {
  content: "󰶾";
}

.mdi-credit-card-minus:before {
  content: "󰾬";
}

.mdi-credit-card-minus-outline:before {
  content: "󰾭";
}

.mdi-credit-card-multiple:before {
  content: "󰿰";
}

.mdi-credit-card-multiple-outline:before {
  content: "󰆜";
}

.mdi-credit-card-off:before {
  content: "󰿱";
}

.mdi-credit-card-off-outline:before {
  content: "󰗤";
}

.mdi-credit-card-outline:before {
  content: "󰆛";
}

.mdi-credit-card-plus:before {
  content: "󰿲";
}

.mdi-credit-card-plus-outline:before {
  content: "󰙶";
}

.mdi-credit-card-refresh:before {
  content: "󱙅";
}

.mdi-credit-card-refresh-outline:before {
  content: "󱙆";
}

.mdi-credit-card-refund:before {
  content: "󰿳";
}

.mdi-credit-card-refund-outline:before {
  content: "󰪨";
}

.mdi-credit-card-remove:before {
  content: "󰾮";
}

.mdi-credit-card-remove-outline:before {
  content: "󰾯";
}

.mdi-credit-card-scan:before {
  content: "󰿴";
}

.mdi-credit-card-scan-outline:before {
  content: "󰆝";
}

.mdi-credit-card-search:before {
  content: "󱙇";
}

.mdi-credit-card-search-outline:before {
  content: "󱙈";
}

.mdi-credit-card-settings:before {
  content: "󰿵";
}

.mdi-credit-card-settings-outline:before {
  content: "󰣗";
}

.mdi-credit-card-sync:before {
  content: "󱙉";
}

.mdi-credit-card-sync-outline:before {
  content: "󱙊";
}

.mdi-credit-card-wireless:before {
  content: "󰠂";
}

.mdi-credit-card-wireless-off:before {
  content: "󰕺";
}

.mdi-credit-card-wireless-off-outline:before {
  content: "󰕻";
}

.mdi-credit-card-wireless-outline:before {
  content: "󰵬";
}

.mdi-cricket:before {
  content: "󰵭";
}

.mdi-crop:before {
  content: "󰆞";
}

.mdi-crop-free:before {
  content: "󰆟";
}

.mdi-crop-landscape:before {
  content: "󰆠";
}

.mdi-crop-portrait:before {
  content: "󰆡";
}

.mdi-crop-rotate:before {
  content: "󰚖";
}

.mdi-crop-square:before {
  content: "󰆢";
}

.mdi-cross:before {
  content: "󰥓";
}

.mdi-cross-bolnisi:before {
  content: "󰳭";
}

.mdi-cross-celtic:before {
  content: "󰳵";
}

.mdi-cross-outline:before {
  content: "󰳶";
}

.mdi-crosshairs:before {
  content: "󰆣";
}

.mdi-crosshairs-gps:before {
  content: "󰆤";
}

.mdi-crosshairs-off:before {
  content: "󰽅";
}

.mdi-crosshairs-question:before {
  content: "󱄶";
}

.mdi-crowd:before {
  content: "󱥵";
}

.mdi-crown:before {
  content: "󰆥";
}

.mdi-crown-circle:before {
  content: "󱟜";
}

.mdi-crown-circle-outline:before {
  content: "󱟝";
}

.mdi-crown-outline:before {
  content: "󱇐";
}

.mdi-cryengine:before {
  content: "󰥙";
}

.mdi-crystal-ball:before {
  content: "󰬯";
}

.mdi-cube:before {
  content: "󰆦";
}

.mdi-cube-off:before {
  content: "󱐜";
}

.mdi-cube-off-outline:before {
  content: "󱐝";
}

.mdi-cube-outline:before {
  content: "󰆧";
}

.mdi-cube-scan:before {
  content: "󰮄";
}

.mdi-cube-send:before {
  content: "󰆨";
}

.mdi-cube-unfolded:before {
  content: "󰆩";
}

.mdi-cup:before {
  content: "󰆪";
}

.mdi-cup-off:before {
  content: "󰗥";
}

.mdi-cup-off-outline:before {
  content: "󱍽";
}

.mdi-cup-outline:before {
  content: "󱌏";
}

.mdi-cup-water:before {
  content: "󰆫";
}

.mdi-cupboard:before {
  content: "󰽆";
}

.mdi-cupboard-outline:before {
  content: "󰽇";
}

.mdi-cupcake:before {
  content: "󰥚";
}

.mdi-curling:before {
  content: "󰡣";
}

.mdi-currency-bdt:before {
  content: "󰡤";
}

.mdi-currency-brl:before {
  content: "󰮅";
}

.mdi-currency-btc:before {
  content: "󰆬";
}

.mdi-currency-cny:before {
  content: "󰞺";
}

.mdi-currency-eth:before {
  content: "󰞻";
}

.mdi-currency-eur:before {
  content: "󰆭";
}

.mdi-currency-eur-off:before {
  content: "󱌕";
}

.mdi-currency-fra:before {
  content: "󱨹";
}

.mdi-currency-gbp:before {
  content: "󰆮";
}

.mdi-currency-ils:before {
  content: "󰱡";
}

.mdi-currency-inr:before {
  content: "󰆯";
}

.mdi-currency-jpy:before {
  content: "󰞼";
}

.mdi-currency-krw:before {
  content: "󰞽";
}

.mdi-currency-kzt:before {
  content: "󰡥";
}

.mdi-currency-mnt:before {
  content: "󱔒";
}

.mdi-currency-ngn:before {
  content: "󰆰";
}

.mdi-currency-php:before {
  content: "󰧦";
}

.mdi-currency-rial:before {
  content: "󰺜";
}

.mdi-currency-rub:before {
  content: "󰆱";
}

.mdi-currency-rupee:before {
  content: "󱥶";
}

.mdi-currency-sign:before {
  content: "󰞾";
}

.mdi-currency-thb:before {
  content: "󱰅";
}

.mdi-currency-try:before {
  content: "󰆲";
}

.mdi-currency-twd:before {
  content: "󰞿";
}

.mdi-currency-uah:before {
  content: "󱮛";
}

.mdi-currency-usd:before {
  content: "󰇁";
}

.mdi-currency-usd-off:before {
  content: "󰙺";
}

.mdi-current-ac:before {
  content: "󱒀";
}

.mdi-current-dc:before {
  content: "󰥜";
}

.mdi-cursor-default:before {
  content: "󰇀";
}

.mdi-cursor-default-click:before {
  content: "󰳽";
}

.mdi-cursor-default-click-outline:before {
  content: "󰳾";
}

.mdi-cursor-default-gesture:before {
  content: "󱄧";
}

.mdi-cursor-default-gesture-outline:before {
  content: "󱄨";
}

.mdi-cursor-default-outline:before {
  content: "󰆿";
}

.mdi-cursor-move:before {
  content: "󰆾";
}

.mdi-cursor-pointer:before {
  content: "󰆽";
}

.mdi-cursor-text:before {
  content: "󰗧";
}

.mdi-curtains:before {
  content: "󱡆";
}

.mdi-curtains-closed:before {
  content: "󱡇";
}

.mdi-cylinder:before {
  content: "󱥎";
}

.mdi-cylinder-off:before {
  content: "󱥏";
}

.mdi-dance-ballroom:before {
  content: "󱗻";
}

.mdi-dance-pole:before {
  content: "󱕸";
}

.mdi-data-matrix:before {
  content: "󱔼";
}

.mdi-data-matrix-edit:before {
  content: "󱔽";
}

.mdi-data-matrix-minus:before {
  content: "󱔾";
}

.mdi-data-matrix-plus:before {
  content: "󱔿";
}

.mdi-data-matrix-remove:before {
  content: "󱕀";
}

.mdi-data-matrix-scan:before {
  content: "󱕁";
}

.mdi-database:before {
  content: "󰆼";
}

.mdi-database-alert:before {
  content: "󱘺";
}

.mdi-database-alert-outline:before {
  content: "󱘤";
}

.mdi-database-arrow-down:before {
  content: "󱘻";
}

.mdi-database-arrow-down-outline:before {
  content: "󱘥";
}

.mdi-database-arrow-left:before {
  content: "󱘼";
}

.mdi-database-arrow-left-outline:before {
  content: "󱘦";
}

.mdi-database-arrow-right:before {
  content: "󱘽";
}

.mdi-database-arrow-right-outline:before {
  content: "󱘧";
}

.mdi-database-arrow-up:before {
  content: "󱘾";
}

.mdi-database-arrow-up-outline:before {
  content: "󱘨";
}

.mdi-database-check:before {
  content: "󰪩";
}

.mdi-database-check-outline:before {
  content: "󱘩";
}

.mdi-database-clock:before {
  content: "󱘿";
}

.mdi-database-clock-outline:before {
  content: "󱘪";
}

.mdi-database-cog:before {
  content: "󱙋";
}

.mdi-database-cog-outline:before {
  content: "󱙌";
}

.mdi-database-edit:before {
  content: "󰮆";
}

.mdi-database-edit-outline:before {
  content: "󱘫";
}

.mdi-database-export:before {
  content: "󰥞";
}

.mdi-database-export-outline:before {
  content: "󱘬";
}

.mdi-database-eye:before {
  content: "󱤟";
}

.mdi-database-eye-off:before {
  content: "󱤠";
}

.mdi-database-eye-off-outline:before {
  content: "󱤡";
}

.mdi-database-eye-outline:before {
  content: "󱤢";
}

.mdi-database-import:before {
  content: "󰥝";
}

.mdi-database-import-outline:before {
  content: "󱘭";
}

.mdi-database-lock:before {
  content: "󰪪";
}

.mdi-database-lock-outline:before {
  content: "󱘮";
}

.mdi-database-marker:before {
  content: "󱋶";
}

.mdi-database-marker-outline:before {
  content: "󱘯";
}

.mdi-database-minus:before {
  content: "󰆻";
}

.mdi-database-minus-outline:before {
  content: "󱘰";
}

.mdi-database-off:before {
  content: "󱙀";
}

.mdi-database-off-outline:before {
  content: "󱘱";
}

.mdi-database-outline:before {
  content: "󱘲";
}

.mdi-database-plus:before {
  content: "󰆺";
}

.mdi-database-plus-outline:before {
  content: "󱘳";
}

.mdi-database-refresh:before {
  content: "󰗂";
}

.mdi-database-refresh-outline:before {
  content: "󱘴";
}

.mdi-database-remove:before {
  content: "󰴀";
}

.mdi-database-remove-outline:before {
  content: "󱘵";
}

.mdi-database-search:before {
  content: "󰡦";
}

.mdi-database-search-outline:before {
  content: "󱘶";
}

.mdi-database-settings:before {
  content: "󰴁";
}

.mdi-database-settings-outline:before {
  content: "󱘷";
}

.mdi-database-sync:before {
  content: "󰳿";
}

.mdi-database-sync-outline:before {
  content: "󱘸";
}

.mdi-death-star:before {
  content: "󰣘";
}

.mdi-death-star-variant:before {
  content: "󰣙";
}

.mdi-deathly-hallows:before {
  content: "󰮇";
}

.mdi-debian:before {
  content: "󰣚";
}

.mdi-debug-step-into:before {
  content: "󰆹";
}

.mdi-debug-step-out:before {
  content: "󰆸";
}

.mdi-debug-step-over:before {
  content: "󰆷";
}

.mdi-decagram:before {
  content: "󰝬";
}

.mdi-decagram-outline:before {
  content: "󰝭";
}

.mdi-decimal:before {
  content: "󱂡";
}

.mdi-decimal-comma:before {
  content: "󱂢";
}

.mdi-decimal-comma-decrease:before {
  content: "󱂣";
}

.mdi-decimal-comma-increase:before {
  content: "󱂤";
}

.mdi-decimal-decrease:before {
  content: "󰆶";
}

.mdi-decimal-increase:before {
  content: "󰆵";
}

.mdi-delete:before {
  content: "󰆴";
}

.mdi-delete-alert:before {
  content: "󱂥";
}

.mdi-delete-alert-outline:before {
  content: "󱂦";
}

.mdi-delete-circle:before {
  content: "󰚃";
}

.mdi-delete-circle-outline:before {
  content: "󰮈";
}

.mdi-delete-clock:before {
  content: "󱕖";
}

.mdi-delete-clock-outline:before {
  content: "󱕗";
}

.mdi-delete-empty:before {
  content: "󰛌";
}

.mdi-delete-empty-outline:before {
  content: "󰺝";
}

.mdi-delete-forever:before {
  content: "󰗨";
}

.mdi-delete-forever-outline:before {
  content: "󰮉";
}

.mdi-delete-off:before {
  content: "󱂧";
}

.mdi-delete-off-outline:before {
  content: "󱂨";
}

.mdi-delete-outline:before {
  content: "󰧧";
}

.mdi-delete-restore:before {
  content: "󰠙";
}

.mdi-delete-sweep:before {
  content: "󰗩";
}

.mdi-delete-sweep-outline:before {
  content: "󰱢";
}

.mdi-delete-variant:before {
  content: "󰆳";
}

.mdi-delta:before {
  content: "󰇂";
}

.mdi-desk:before {
  content: "󱈹";
}

.mdi-desk-lamp:before {
  content: "󰥟";
}

.mdi-desk-lamp-off:before {
  content: "󱬟";
}

.mdi-desk-lamp-on:before {
  content: "󱬠";
}

.mdi-deskphone:before {
  content: "󰇃";
}

.mdi-desktop-classic:before {
  content: "󰟀";
}

.mdi-desktop-tower:before {
  content: "󰇅";
}

.mdi-desktop-tower-monitor:before {
  content: "󰪫";
}

.mdi-details:before {
  content: "󰇆";
}

.mdi-dev-to:before {
  content: "󰵮";
}

.mdi-developer-board:before {
  content: "󰚗";
}

.mdi-deviantart:before {
  content: "󰇇";
}

.mdi-devices:before {
  content: "󰾰";
}

.mdi-dharmachakra:before {
  content: "󰥋";
}

.mdi-diabetes:before {
  content: "󱄦";
}

.mdi-dialpad:before {
  content: "󰘜";
}

.mdi-diameter:before {
  content: "󰱣";
}

.mdi-diameter-outline:before {
  content: "󰱤";
}

.mdi-diameter-variant:before {
  content: "󰱥";
}

.mdi-diamond:before {
  content: "󰮊";
}

.mdi-diamond-outline:before {
  content: "󰮋";
}

.mdi-diamond-stone:before {
  content: "󰇈";
}

.mdi-dice-1:before {
  content: "󰇊";
}

.mdi-dice-1-outline:before {
  content: "󱅊";
}

.mdi-dice-2:before {
  content: "󰇋";
}

.mdi-dice-2-outline:before {
  content: "󱅋";
}

.mdi-dice-3:before {
  content: "󰇌";
}

.mdi-dice-3-outline:before {
  content: "󱅌";
}

.mdi-dice-4:before {
  content: "󰇍";
}

.mdi-dice-4-outline:before {
  content: "󱅍";
}

.mdi-dice-5:before {
  content: "󰇎";
}

.mdi-dice-5-outline:before {
  content: "󱅎";
}

.mdi-dice-6:before {
  content: "󰇏";
}

.mdi-dice-6-outline:before {
  content: "󱅏";
}

.mdi-dice-d10:before {
  content: "󱅓";
}

.mdi-dice-d10-outline:before {
  content: "󰝯";
}

.mdi-dice-d12:before {
  content: "󱅔";
}

.mdi-dice-d12-outline:before {
  content: "󰡧";
}

.mdi-dice-d20:before {
  content: "󱅕";
}

.mdi-dice-d20-outline:before {
  content: "󰗪";
}

.mdi-dice-d4:before {
  content: "󱅐";
}

.mdi-dice-d4-outline:before {
  content: "󰗫";
}

.mdi-dice-d6:before {
  content: "󱅑";
}

.mdi-dice-d6-outline:before {
  content: "󰗭";
}

.mdi-dice-d8:before {
  content: "󱅒";
}

.mdi-dice-d8-outline:before {
  content: "󰗬";
}

.mdi-dice-multiple:before {
  content: "󰝮";
}

.mdi-dice-multiple-outline:before {
  content: "󱅖";
}

.mdi-digital-ocean:before {
  content: "󱈷";
}

.mdi-dip-switch:before {
  content: "󰟁";
}

.mdi-directions:before {
  content: "󰇐";
}

.mdi-directions-fork:before {
  content: "󰙁";
}

.mdi-disc:before {
  content: "󰗮";
}

.mdi-disc-alert:before {
  content: "󰇑";
}

.mdi-disc-player:before {
  content: "󰥠";
}

.mdi-dishwasher:before {
  content: "󰪬";
}

.mdi-dishwasher-alert:before {
  content: "󱆸";
}

.mdi-dishwasher-off:before {
  content: "󱆹";
}

.mdi-disqus:before {
  content: "󰇒";
}

.mdi-distribute-horizontal-center:before {
  content: "󱇉";
}

.mdi-distribute-horizontal-left:before {
  content: "󱇈";
}

.mdi-distribute-horizontal-right:before {
  content: "󱇊";
}

.mdi-distribute-vertical-bottom:before {
  content: "󱇋";
}

.mdi-distribute-vertical-center:before {
  content: "󱇌";
}

.mdi-distribute-vertical-top:before {
  content: "󱇍";
}

.mdi-diversify:before {
  content: "󱡷";
}

.mdi-diving:before {
  content: "󱥷";
}

.mdi-diving-flippers:before {
  content: "󰶿";
}

.mdi-diving-helmet:before {
  content: "󰷀";
}

.mdi-diving-scuba:before {
  content: "󱭷";
}

.mdi-diving-scuba-flag:before {
  content: "󰷂";
}

.mdi-diving-scuba-mask:before {
  content: "󰷁";
}

.mdi-diving-scuba-tank:before {
  content: "󰷃";
}

.mdi-diving-scuba-tank-multiple:before {
  content: "󰷄";
}

.mdi-diving-snorkel:before {
  content: "󰷅";
}

.mdi-division:before {
  content: "󰇔";
}

.mdi-division-box:before {
  content: "󰇕";
}

.mdi-dlna:before {
  content: "󰩁";
}

.mdi-dna:before {
  content: "󰚄";
}

.mdi-dns:before {
  content: "󰇖";
}

.mdi-dns-outline:before {
  content: "󰮌";
}

.mdi-dock-bottom:before {
  content: "󱂩";
}

.mdi-dock-left:before {
  content: "󱂪";
}

.mdi-dock-right:before {
  content: "󱂫";
}

.mdi-dock-top:before {
  content: "󱔓";
}

.mdi-dock-window:before {
  content: "󱂬";
}

.mdi-docker:before {
  content: "󰡨";
}

.mdi-doctor:before {
  content: "󰩂";
}

.mdi-dog:before {
  content: "󰩃";
}

.mdi-dog-service:before {
  content: "󰪭";
}

.mdi-dog-side:before {
  content: "󰩄";
}

.mdi-dog-side-off:before {
  content: "󱛮";
}

.mdi-dolby:before {
  content: "󰚳";
}

.mdi-dolly:before {
  content: "󰺞";
}

.mdi-dolphin:before {
  content: "󱢴";
}

.mdi-domain:before {
  content: "󰇗";
}

.mdi-domain-off:before {
  content: "󰵯";
}

.mdi-domain-plus:before {
  content: "󱂭";
}

.mdi-domain-remove:before {
  content: "󱂮";
}

.mdi-domain-switch:before {
  content: "󱰬";
}

.mdi-dome-light:before {
  content: "󱐞";
}

.mdi-domino-mask:before {
  content: "󱀣";
}

.mdi-donkey:before {
  content: "󰟂";
}

.mdi-door:before {
  content: "󰠚";
}

.mdi-door-closed:before {
  content: "󰠛";
}

.mdi-door-closed-lock:before {
  content: "󱂯";
}

.mdi-door-open:before {
  content: "󰠜";
}

.mdi-door-sliding:before {
  content: "󱠞";
}

.mdi-door-sliding-lock:before {
  content: "󱠟";
}

.mdi-door-sliding-open:before {
  content: "󱠠";
}

.mdi-doorbell:before {
  content: "󱋦";
}

.mdi-doorbell-video:before {
  content: "󰡩";
}

.mdi-dot-net:before {
  content: "󰪮";
}

.mdi-dots-circle:before {
  content: "󱥸";
}

.mdi-dots-grid:before {
  content: "󱗼";
}

.mdi-dots-hexagon:before {
  content: "󱗿";
}

.mdi-dots-horizontal:before {
  content: "󰇘";
}

.mdi-dots-horizontal-circle:before {
  content: "󰟃";
}

.mdi-dots-horizontal-circle-outline:before {
  content: "󰮍";
}

.mdi-dots-square:before {
  content: "󱗽";
}

.mdi-dots-triangle:before {
  content: "󱗾";
}

.mdi-dots-vertical:before {
  content: "󰇙";
}

.mdi-dots-vertical-circle:before {
  content: "󰟄";
}

.mdi-dots-vertical-circle-outline:before {
  content: "󰮎";
}

.mdi-download:before {
  content: "󰇚";
}

.mdi-download-box:before {
  content: "󱑢";
}

.mdi-download-box-outline:before {
  content: "󱑣";
}

.mdi-download-circle:before {
  content: "󱑤";
}

.mdi-download-circle-outline:before {
  content: "󱑥";
}

.mdi-download-lock:before {
  content: "󱌠";
}

.mdi-download-lock-outline:before {
  content: "󱌡";
}

.mdi-download-multiple:before {
  content: "󰧩";
}

.mdi-download-network:before {
  content: "󰛴";
}

.mdi-download-network-outline:before {
  content: "󰱦";
}

.mdi-download-off:before {
  content: "󱂰";
}

.mdi-download-off-outline:before {
  content: "󱂱";
}

.mdi-download-outline:before {
  content: "󰮏";
}

.mdi-drag:before {
  content: "󰇛";
}

.mdi-drag-horizontal:before {
  content: "󰇜";
}

.mdi-drag-horizontal-variant:before {
  content: "󱋰";
}

.mdi-drag-variant:before {
  content: "󰮐";
}

.mdi-drag-vertical:before {
  content: "󰇝";
}

.mdi-drag-vertical-variant:before {
  content: "󱋱";
}

.mdi-drama-masks:before {
  content: "󰴂";
}

.mdi-draw:before {
  content: "󰽉";
}

.mdi-draw-pen:before {
  content: "󱦹";
}

.mdi-drawing:before {
  content: "󰇞";
}

.mdi-drawing-box:before {
  content: "󰇟";
}

.mdi-dresser:before {
  content: "󰽊";
}

.mdi-dresser-outline:before {
  content: "󰽋";
}

.mdi-drone:before {
  content: "󰇢";
}

.mdi-dropbox:before {
  content: "󰇣";
}

.mdi-drupal:before {
  content: "󰇤";
}

.mdi-duck:before {
  content: "󰇥";
}

.mdi-dumbbell:before {
  content: "󰇦";
}

.mdi-dump-truck:before {
  content: "󰱧";
}

.mdi-ear-hearing:before {
  content: "󰟅";
}

.mdi-ear-hearing-loop:before {
  content: "󱫮";
}

.mdi-ear-hearing-off:before {
  content: "󰩅";
}

.mdi-earbuds:before {
  content: "󱡏";
}

.mdi-earbuds-off:before {
  content: "󱡐";
}

.mdi-earbuds-off-outline:before {
  content: "󱡑";
}

.mdi-earbuds-outline:before {
  content: "󱡒";
}

.mdi-earth:before {
  content: "󰇧";
}

.mdi-earth-arrow-right:before {
  content: "󱌑";
}

.mdi-earth-box:before {
  content: "󰛍";
}

.mdi-earth-box-minus:before {
  content: "󱐇";
}

.mdi-earth-box-off:before {
  content: "󰛎";
}

.mdi-earth-box-plus:before {
  content: "󱐆";
}

.mdi-earth-box-remove:before {
  content: "󱐈";
}

.mdi-earth-minus:before {
  content: "󱐄";
}

.mdi-earth-off:before {
  content: "󰇨";
}

.mdi-earth-plus:before {
  content: "󱐃";
}

.mdi-earth-remove:before {
  content: "󱐅";
}

.mdi-egg:before {
  content: "󰪯";
}

.mdi-egg-easter:before {
  content: "󰪰";
}

.mdi-egg-fried:before {
  content: "󱡊";
}

.mdi-egg-off:before {
  content: "󱏰";
}

.mdi-egg-off-outline:before {
  content: "󱏱";
}

.mdi-egg-outline:before {
  content: "󱏲";
}

.mdi-eiffel-tower:before {
  content: "󱕫";
}

.mdi-eight-track:before {
  content: "󰧪";
}

.mdi-eject:before {
  content: "󰇪";
}

.mdi-eject-circle:before {
  content: "󱬣";
}

.mdi-eject-circle-outline:before {
  content: "󱬤";
}

.mdi-eject-outline:before {
  content: "󰮑";
}

.mdi-electric-switch:before {
  content: "󰺟";
}

.mdi-electric-switch-closed:before {
  content: "󱃙";
}

.mdi-electron-framework:before {
  content: "󱀤";
}

.mdi-elephant:before {
  content: "󰟆";
}

.mdi-elevation-decline:before {
  content: "󰇫";
}

.mdi-elevation-rise:before {
  content: "󰇬";
}

.mdi-elevator:before {
  content: "󰇭";
}

.mdi-elevator-down:before {
  content: "󱋂";
}

.mdi-elevator-passenger:before {
  content: "󱎁";
}

.mdi-elevator-passenger-off:before {
  content: "󱥹";
}

.mdi-elevator-passenger-off-outline:before {
  content: "󱥺";
}

.mdi-elevator-passenger-outline:before {
  content: "󱥻";
}

.mdi-elevator-up:before {
  content: "󱋁";
}

.mdi-ellipse:before {
  content: "󰺠";
}

.mdi-ellipse-outline:before {
  content: "󰺡";
}

.mdi-email:before {
  content: "󰇮";
}

.mdi-email-alert:before {
  content: "󰛏";
}

.mdi-email-alert-outline:before {
  content: "󰵂";
}

.mdi-email-arrow-left:before {
  content: "󱃚";
}

.mdi-email-arrow-left-outline:before {
  content: "󱃛";
}

.mdi-email-arrow-right:before {
  content: "󱃜";
}

.mdi-email-arrow-right-outline:before {
  content: "󱃝";
}

.mdi-email-box:before {
  content: "󰴃";
}

.mdi-email-check:before {
  content: "󰪱";
}

.mdi-email-check-outline:before {
  content: "󰪲";
}

.mdi-email-edit:before {
  content: "󰻣";
}

.mdi-email-edit-outline:before {
  content: "󰻤";
}

.mdi-email-fast:before {
  content: "󱡯";
}

.mdi-email-fast-outline:before {
  content: "󱡰";
}

.mdi-email-heart-outline:before {
  content: "󱱛";
}

.mdi-email-lock:before {
  content: "󰇱";
}

.mdi-email-lock-outline:before {
  content: "󱭡";
}

.mdi-email-mark-as-unread:before {
  content: "󰮒";
}

.mdi-email-minus:before {
  content: "󰻥";
}

.mdi-email-minus-outline:before {
  content: "󰻦";
}

.mdi-email-multiple:before {
  content: "󰻧";
}

.mdi-email-multiple-outline:before {
  content: "󰻨";
}

.mdi-email-newsletter:before {
  content: "󰾱";
}

.mdi-email-off:before {
  content: "󱏣";
}

.mdi-email-off-outline:before {
  content: "󱏤";
}

.mdi-email-open:before {
  content: "󰇯";
}

.mdi-email-open-heart-outline:before {
  content: "󱱜";
}

.mdi-email-open-multiple:before {
  content: "󰻩";
}

.mdi-email-open-multiple-outline:before {
  content: "󰻪";
}

.mdi-email-open-outline:before {
  content: "󰗯";
}

.mdi-email-outline:before {
  content: "󰇰";
}

.mdi-email-plus:before {
  content: "󰧫";
}

.mdi-email-plus-outline:before {
  content: "󰧬";
}

.mdi-email-remove:before {
  content: "󱙡";
}

.mdi-email-remove-outline:before {
  content: "󱙢";
}

.mdi-email-seal:before {
  content: "󱥛";
}

.mdi-email-seal-outline:before {
  content: "󱥜";
}

.mdi-email-search:before {
  content: "󰥡";
}

.mdi-email-search-outline:before {
  content: "󰥢";
}

.mdi-email-sync:before {
  content: "󱋇";
}

.mdi-email-sync-outline:before {
  content: "󱋈";
}

.mdi-email-variant:before {
  content: "󰗰";
}

.mdi-ember:before {
  content: "󰬰";
}

.mdi-emby:before {
  content: "󰚴";
}

.mdi-emoticon:before {
  content: "󰱨";
}

.mdi-emoticon-angry:before {
  content: "󰱩";
}

.mdi-emoticon-angry-outline:before {
  content: "󰱪";
}

.mdi-emoticon-confused:before {
  content: "󱃞";
}

.mdi-emoticon-confused-outline:before {
  content: "󱃟";
}

.mdi-emoticon-cool:before {
  content: "󰱫";
}

.mdi-emoticon-cool-outline:before {
  content: "󰇳";
}

.mdi-emoticon-cry:before {
  content: "󰱬";
}

.mdi-emoticon-cry-outline:before {
  content: "󰱭";
}

.mdi-emoticon-dead:before {
  content: "󰱮";
}

.mdi-emoticon-dead-outline:before {
  content: "󰚛";
}

.mdi-emoticon-devil:before {
  content: "󰱯";
}

.mdi-emoticon-devil-outline:before {
  content: "󰇴";
}

.mdi-emoticon-excited:before {
  content: "󰱰";
}

.mdi-emoticon-excited-outline:before {
  content: "󰚜";
}

.mdi-emoticon-frown:before {
  content: "󰽌";
}

.mdi-emoticon-frown-outline:before {
  content: "󰽍";
}

.mdi-emoticon-happy:before {
  content: "󰱱";
}

.mdi-emoticon-happy-outline:before {
  content: "󰇵";
}

.mdi-emoticon-kiss:before {
  content: "󰱲";
}

.mdi-emoticon-kiss-outline:before {
  content: "󰱳";
}

.mdi-emoticon-lol:before {
  content: "󱈔";
}

.mdi-emoticon-lol-outline:before {
  content: "󱈕";
}

.mdi-emoticon-neutral:before {
  content: "󰱴";
}

.mdi-emoticon-neutral-outline:before {
  content: "󰇶";
}

.mdi-emoticon-outline:before {
  content: "󰇲";
}

.mdi-emoticon-poop:before {
  content: "󰇷";
}

.mdi-emoticon-poop-outline:before {
  content: "󰱵";
}

.mdi-emoticon-sad:before {
  content: "󰱶";
}

.mdi-emoticon-sad-outline:before {
  content: "󰇸";
}

.mdi-emoticon-sick:before {
  content: "󱕼";
}

.mdi-emoticon-sick-outline:before {
  content: "󱕽";
}

.mdi-emoticon-tongue:before {
  content: "󰇹";
}

.mdi-emoticon-tongue-outline:before {
  content: "󰱷";
}

.mdi-emoticon-wink:before {
  content: "󰱸";
}

.mdi-emoticon-wink-outline:before {
  content: "󰱹";
}

.mdi-engine:before {
  content: "󰇺";
}

.mdi-engine-off:before {
  content: "󰩆";
}

.mdi-engine-off-outline:before {
  content: "󰩇";
}

.mdi-engine-outline:before {
  content: "󰇻";
}

.mdi-epsilon:before {
  content: "󱃠";
}

.mdi-equal:before {
  content: "󰇼";
}

.mdi-equal-box:before {
  content: "󰇽";
}

.mdi-equalizer:before {
  content: "󰺢";
}

.mdi-equalizer-outline:before {
  content: "󰺣";
}

.mdi-eraser:before {
  content: "󰇾";
}

.mdi-eraser-variant:before {
  content: "󰙂";
}

.mdi-escalator:before {
  content: "󰇿";
}

.mdi-escalator-box:before {
  content: "󱎙";
}

.mdi-escalator-down:before {
  content: "󱋀";
}

.mdi-escalator-up:before {
  content: "󱊿";
}

.mdi-eslint:before {
  content: "󰱺";
}

.mdi-et:before {
  content: "󰪳";
}

.mdi-ethereum:before {
  content: "󰡪";
}

.mdi-ethernet:before {
  content: "󰈀";
}

.mdi-ethernet-cable:before {
  content: "󰈁";
}

.mdi-ethernet-cable-off:before {
  content: "󰈂";
}

.mdi-ev-plug-ccs1:before {
  content: "󱔙";
}

.mdi-ev-plug-ccs2:before {
  content: "󱔚";
}

.mdi-ev-plug-chademo:before {
  content: "󱔛";
}

.mdi-ev-plug-tesla:before {
  content: "󱔜";
}

.mdi-ev-plug-type1:before {
  content: "󱔝";
}

.mdi-ev-plug-type2:before {
  content: "󱔞";
}

.mdi-ev-station:before {
  content: "󰗱";
}

.mdi-evernote:before {
  content: "󰈄";
}

.mdi-excavator:before {
  content: "󱀥";
}

.mdi-exclamation:before {
  content: "󰈅";
}

.mdi-exclamation-thick:before {
  content: "󱈸";
}

.mdi-exit-run:before {
  content: "󰩈";
}

.mdi-exit-to-app:before {
  content: "󰈆";
}

.mdi-expand-all:before {
  content: "󰪴";
}

.mdi-expand-all-outline:before {
  content: "󰪵";
}

.mdi-expansion-card:before {
  content: "󰢮";
}

.mdi-expansion-card-variant:before {
  content: "󰾲";
}

.mdi-exponent:before {
  content: "󰥣";
}

.mdi-exponent-box:before {
  content: "󰥤";
}

.mdi-export:before {
  content: "󰈇";
}

.mdi-export-variant:before {
  content: "󰮓";
}

.mdi-eye:before {
  content: "󰈈";
}

.mdi-eye-arrow-left:before {
  content: "󱣽";
}

.mdi-eye-arrow-left-outline:before {
  content: "󱣾";
}

.mdi-eye-arrow-right:before {
  content: "󱣿";
}

.mdi-eye-arrow-right-outline:before {
  content: "󱤀";
}

.mdi-eye-check:before {
  content: "󰴄";
}

.mdi-eye-check-outline:before {
  content: "󰴅";
}

.mdi-eye-circle:before {
  content: "󰮔";
}

.mdi-eye-circle-outline:before {
  content: "󰮕";
}

.mdi-eye-lock:before {
  content: "󱰆";
}

.mdi-eye-lock-open:before {
  content: "󱰇";
}

.mdi-eye-lock-open-outline:before {
  content: "󱰈";
}

.mdi-eye-lock-outline:before {
  content: "󱰉";
}

.mdi-eye-minus:before {
  content: "󱀦";
}

.mdi-eye-minus-outline:before {
  content: "󱀧";
}

.mdi-eye-off:before {
  content: "󰈉";
}

.mdi-eye-off-outline:before {
  content: "󰛑";
}

.mdi-eye-outline:before {
  content: "󰛐";
}

.mdi-eye-plus:before {
  content: "󰡫";
}

.mdi-eye-plus-outline:before {
  content: "󰡬";
}

.mdi-eye-refresh:before {
  content: "󱥼";
}

.mdi-eye-refresh-outline:before {
  content: "󱥽";
}

.mdi-eye-remove:before {
  content: "󱗣";
}

.mdi-eye-remove-outline:before {
  content: "󱗤";
}

.mdi-eye-settings:before {
  content: "󰡭";
}

.mdi-eye-settings-outline:before {
  content: "󰡮";
}

.mdi-eyedropper:before {
  content: "󰈊";
}

.mdi-eyedropper-minus:before {
  content: "󱏝";
}

.mdi-eyedropper-off:before {
  content: "󱏟";
}

.mdi-eyedropper-plus:before {
  content: "󱏜";
}

.mdi-eyedropper-remove:before {
  content: "󱏞";
}

.mdi-eyedropper-variant:before {
  content: "󰈋";
}

.mdi-face-agent:before {
  content: "󰵰";
}

.mdi-face-man:before {
  content: "󰙃";
}

.mdi-face-man-outline:before {
  content: "󰮖";
}

.mdi-face-man-profile:before {
  content: "󰙄";
}

.mdi-face-man-shimmer:before {
  content: "󱗌";
}

.mdi-face-man-shimmer-outline:before {
  content: "󱗍";
}

.mdi-face-mask:before {
  content: "󱖆";
}

.mdi-face-mask-outline:before {
  content: "󱖇";
}

.mdi-face-recognition:before {
  content: "󰱻";
}

.mdi-face-woman:before {
  content: "󱁷";
}

.mdi-face-woman-outline:before {
  content: "󱁸";
}

.mdi-face-woman-profile:before {
  content: "󱁶";
}

.mdi-face-woman-shimmer:before {
  content: "󱗎";
}

.mdi-face-woman-shimmer-outline:before {
  content: "󱗏";
}

.mdi-facebook:before {
  content: "󰈌";
}

.mdi-facebook-gaming:before {
  content: "󰟝";
}

.mdi-facebook-messenger:before {
  content: "󰈎";
}

.mdi-facebook-workplace:before {
  content: "󰬱";
}

.mdi-factory:before {
  content: "󰈏";
}

.mdi-family-tree:before {
  content: "󱘎";
}

.mdi-fan:before {
  content: "󰈐";
}

.mdi-fan-alert:before {
  content: "󱑬";
}

.mdi-fan-auto:before {
  content: "󱜝";
}

.mdi-fan-chevron-down:before {
  content: "󱑭";
}

.mdi-fan-chevron-up:before {
  content: "󱑮";
}

.mdi-fan-clock:before {
  content: "󱨺";
}

.mdi-fan-minus:before {
  content: "󱑰";
}

.mdi-fan-off:before {
  content: "󰠝";
}

.mdi-fan-plus:before {
  content: "󱑯";
}

.mdi-fan-remove:before {
  content: "󱑱";
}

.mdi-fan-speed-1:before {
  content: "󱑲";
}

.mdi-fan-speed-2:before {
  content: "󱑳";
}

.mdi-fan-speed-3:before {
  content: "󱑴";
}

.mdi-fast-forward:before {
  content: "󰈑";
}

.mdi-fast-forward-10:before {
  content: "󰵱";
}

.mdi-fast-forward-15:before {
  content: "󱤺";
}

.mdi-fast-forward-30:before {
  content: "󰴆";
}

.mdi-fast-forward-45:before {
  content: "󱬒";
}

.mdi-fast-forward-5:before {
  content: "󱇸";
}

.mdi-fast-forward-60:before {
  content: "󱘋";
}

.mdi-fast-forward-outline:before {
  content: "󰛒";
}

.mdi-faucet:before {
  content: "󱬩";
}

.mdi-faucet-variant:before {
  content: "󱬪";
}

.mdi-fax:before {
  content: "󰈒";
}

.mdi-feather:before {
  content: "󰛓";
}

.mdi-feature-search:before {
  content: "󰩉";
}

.mdi-feature-search-outline:before {
  content: "󰩊";
}

.mdi-fedora:before {
  content: "󰣛";
}

.mdi-fence:before {
  content: "󱞚";
}

.mdi-fence-electric:before {
  content: "󱟶";
}

.mdi-fencing:before {
  content: "󱓁";
}

.mdi-ferris-wheel:before {
  content: "󰺤";
}

.mdi-ferry:before {
  content: "󰈓";
}

.mdi-file:before {
  content: "󰈔";
}

.mdi-file-account:before {
  content: "󰜻";
}

.mdi-file-account-outline:before {
  content: "󱀨";
}

.mdi-file-alert:before {
  content: "󰩋";
}

.mdi-file-alert-outline:before {
  content: "󰩌";
}

.mdi-file-arrow-left-right:before {
  content: "󱪓";
}

.mdi-file-arrow-left-right-outline:before {
  content: "󱪔";
}

.mdi-file-arrow-up-down:before {
  content: "󱪕";
}

.mdi-file-arrow-up-down-outline:before {
  content: "󱪖";
}

.mdi-file-cabinet:before {
  content: "󰪶";
}

.mdi-file-cad:before {
  content: "󰻫";
}

.mdi-file-cad-box:before {
  content: "󰻬";
}

.mdi-file-cancel:before {
  content: "󰷆";
}

.mdi-file-cancel-outline:before {
  content: "󰷇";
}

.mdi-file-certificate:before {
  content: "󱆆";
}

.mdi-file-certificate-outline:before {
  content: "󱆇";
}

.mdi-file-chart:before {
  content: "󰈕";
}

.mdi-file-chart-check:before {
  content: "󱧆";
}

.mdi-file-chart-check-outline:before {
  content: "󱧇";
}

.mdi-file-chart-outline:before {
  content: "󱀩";
}

.mdi-file-check:before {
  content: "󰈖";
}

.mdi-file-check-outline:before {
  content: "󰸩";
}

.mdi-file-clock:before {
  content: "󱋡";
}

.mdi-file-clock-outline:before {
  content: "󱋢";
}

.mdi-file-cloud:before {
  content: "󰈗";
}

.mdi-file-cloud-outline:before {
  content: "󱀪";
}

.mdi-file-code:before {
  content: "󰈮";
}

.mdi-file-code-outline:before {
  content: "󱀫";
}

.mdi-file-cog:before {
  content: "󱁻";
}

.mdi-file-cog-outline:before {
  content: "󱁼";
}

.mdi-file-compare:before {
  content: "󰢪";
}

.mdi-file-delimited:before {
  content: "󰈘";
}

.mdi-file-delimited-outline:before {
  content: "󰺥";
}

.mdi-file-document:before {
  content: "󰈙";
}

.mdi-file-document-alert:before {
  content: "󱪗";
}

.mdi-file-document-alert-outline:before {
  content: "󱪘";
}

.mdi-file-document-arrow-right:before {
  content: "󱰏";
}

.mdi-file-document-arrow-right-outline:before {
  content: "󱰐";
}

.mdi-file-document-check:before {
  content: "󱪙";
}

.mdi-file-document-check-outline:before {
  content: "󱪚";
}

.mdi-file-document-edit:before {
  content: "󰷈";
}

.mdi-file-document-edit-outline:before {
  content: "󰷉";
}

.mdi-file-document-minus:before {
  content: "󱪛";
}

.mdi-file-document-minus-outline:before {
  content: "󱪜";
}

.mdi-file-document-multiple:before {
  content: "󱔗";
}

.mdi-file-document-multiple-outline:before {
  content: "󱔘";
}

.mdi-file-document-outline:before {
  content: "󰧮";
}

.mdi-file-document-plus:before {
  content: "󱪝";
}

.mdi-file-document-plus-outline:before {
  content: "󱪞";
}

.mdi-file-document-refresh:before {
  content: "󱱺";
}

.mdi-file-document-refresh-outline:before {
  content: "󱱻";
}

.mdi-file-document-remove:before {
  content: "󱪟";
}

.mdi-file-document-remove-outline:before {
  content: "󱪠";
}

.mdi-file-download:before {
  content: "󰥥";
}

.mdi-file-download-outline:before {
  content: "󰥦";
}

.mdi-file-edit:before {
  content: "󱇧";
}

.mdi-file-edit-outline:before {
  content: "󱇨";
}

.mdi-file-excel:before {
  content: "󰈛";
}

.mdi-file-excel-box:before {
  content: "󰈜";
}

.mdi-file-excel-box-outline:before {
  content: "󱀬";
}

.mdi-file-excel-outline:before {
  content: "󱀭";
}

.mdi-file-export:before {
  content: "󰈝";
}

.mdi-file-export-outline:before {
  content: "󱀮";
}

.mdi-file-eye:before {
  content: "󰷊";
}

.mdi-file-eye-outline:before {
  content: "󰷋";
}

.mdi-file-find:before {
  content: "󰈞";
}

.mdi-file-find-outline:before {
  content: "󰮗";
}

.mdi-file-gif-box:before {
  content: "󰵸";
}

.mdi-file-hidden:before {
  content: "󰘓";
}

.mdi-file-image:before {
  content: "󰈟";
}

.mdi-file-image-marker:before {
  content: "󱝲";
}

.mdi-file-image-marker-outline:before {
  content: "󱝳";
}

.mdi-file-image-minus:before {
  content: "󱤻";
}

.mdi-file-image-minus-outline:before {
  content: "󱤼";
}

.mdi-file-image-outline:before {
  content: "󰺰";
}

.mdi-file-image-plus:before {
  content: "󱤽";
}

.mdi-file-image-plus-outline:before {
  content: "󱤾";
}

.mdi-file-image-remove:before {
  content: "󱤿";
}

.mdi-file-image-remove-outline:before {
  content: "󱥀";
}

.mdi-file-import:before {
  content: "󰈠";
}

.mdi-file-import-outline:before {
  content: "󱀯";
}

.mdi-file-jpg-box:before {
  content: "󰈥";
}

.mdi-file-key:before {
  content: "󱆄";
}

.mdi-file-key-outline:before {
  content: "󱆅";
}

.mdi-file-link:before {
  content: "󱅷";
}

.mdi-file-link-outline:before {
  content: "󱅸";
}

.mdi-file-lock:before {
  content: "󰈡";
}

.mdi-file-lock-open:before {
  content: "󱧈";
}

.mdi-file-lock-open-outline:before {
  content: "󱧉";
}

.mdi-file-lock-outline:before {
  content: "󱀰";
}

.mdi-file-marker:before {
  content: "󱝴";
}

.mdi-file-marker-outline:before {
  content: "󱝵";
}

.mdi-file-minus:before {
  content: "󱪡";
}

.mdi-file-minus-outline:before {
  content: "󱪢";
}

.mdi-file-move:before {
  content: "󰪹";
}

.mdi-file-move-outline:before {
  content: "󱀱";
}

.mdi-file-multiple:before {
  content: "󰈢";
}

.mdi-file-multiple-outline:before {
  content: "󱀲";
}

.mdi-file-music:before {
  content: "󰈣";
}

.mdi-file-music-outline:before {
  content: "󰸪";
}

.mdi-file-outline:before {
  content: "󰈤";
}

.mdi-file-pdf-box:before {
  content: "󰈦";
}

.mdi-file-percent:before {
  content: "󰠞";
}

.mdi-file-percent-outline:before {
  content: "󱀳";
}

.mdi-file-phone:before {
  content: "󱅹";
}

.mdi-file-phone-outline:before {
  content: "󱅺";
}

.mdi-file-plus:before {
  content: "󰝒";
}

.mdi-file-plus-outline:before {
  content: "󰻭";
}

.mdi-file-png-box:before {
  content: "󰸭";
}

.mdi-file-powerpoint:before {
  content: "󰈧";
}

.mdi-file-powerpoint-box:before {
  content: "󰈨";
}

.mdi-file-powerpoint-box-outline:before {
  content: "󱀴";
}

.mdi-file-powerpoint-outline:before {
  content: "󱀵";
}

.mdi-file-presentation-box:before {
  content: "󰈩";
}

.mdi-file-question:before {
  content: "󰡯";
}

.mdi-file-question-outline:before {
  content: "󱀶";
}

.mdi-file-refresh:before {
  content: "󰤘";
}

.mdi-file-refresh-outline:before {
  content: "󰕁";
}

.mdi-file-remove:before {
  content: "󰮘";
}

.mdi-file-remove-outline:before {
  content: "󱀷";
}

.mdi-file-replace:before {
  content: "󰬲";
}

.mdi-file-replace-outline:before {
  content: "󰬳";
}

.mdi-file-restore:before {
  content: "󰙰";
}

.mdi-file-restore-outline:before {
  content: "󱀸";
}

.mdi-file-rotate-left:before {
  content: "󱨻";
}

.mdi-file-rotate-left-outline:before {
  content: "󱨼";
}

.mdi-file-rotate-right:before {
  content: "󱨽";
}

.mdi-file-rotate-right-outline:before {
  content: "󱨾";
}

.mdi-file-search:before {
  content: "󰱼";
}

.mdi-file-search-outline:before {
  content: "󰱽";
}

.mdi-file-send:before {
  content: "󰈪";
}

.mdi-file-send-outline:before {
  content: "󱀹";
}

.mdi-file-settings:before {
  content: "󱁹";
}

.mdi-file-settings-outline:before {
  content: "󱁺";
}

.mdi-file-sign:before {
  content: "󱧃";
}

.mdi-file-star:before {
  content: "󱀺";
}

.mdi-file-star-four-points:before {
  content: "󱰭";
}

.mdi-file-star-four-points-outline:before {
  content: "󱰮";
}

.mdi-file-star-outline:before {
  content: "󱀻";
}

.mdi-file-swap:before {
  content: "󰾴";
}

.mdi-file-swap-outline:before {
  content: "󰾵";
}

.mdi-file-sync:before {
  content: "󱈖";
}

.mdi-file-sync-outline:before {
  content: "󱈗";
}

.mdi-file-table:before {
  content: "󰱾";
}

.mdi-file-table-box:before {
  content: "󱃡";
}

.mdi-file-table-box-multiple:before {
  content: "󱃢";
}

.mdi-file-table-box-multiple-outline:before {
  content: "󱃣";
}

.mdi-file-table-box-outline:before {
  content: "󱃤";
}

.mdi-file-table-outline:before {
  content: "󰱿";
}

.mdi-file-tree:before {
  content: "󰙅";
}

.mdi-file-tree-outline:before {
  content: "󱏒";
}

.mdi-file-undo:before {
  content: "󰣜";
}

.mdi-file-undo-outline:before {
  content: "󱀼";
}

.mdi-file-upload:before {
  content: "󰩍";
}

.mdi-file-upload-outline:before {
  content: "󰩎";
}

.mdi-file-video:before {
  content: "󰈫";
}

.mdi-file-video-outline:before {
  content: "󰸬";
}

.mdi-file-word:before {
  content: "󰈬";
}

.mdi-file-word-box:before {
  content: "󰈭";
}

.mdi-file-word-box-outline:before {
  content: "󱀽";
}

.mdi-file-word-outline:before {
  content: "󱀾";
}

.mdi-file-xml-box:before {
  content: "󱭋";
}

.mdi-film:before {
  content: "󰈯";
}

.mdi-filmstrip:before {
  content: "󰈰";
}

.mdi-filmstrip-box:before {
  content: "󰌲";
}

.mdi-filmstrip-box-multiple:before {
  content: "󰴘";
}

.mdi-filmstrip-off:before {
  content: "󰈱";
}

.mdi-filter:before {
  content: "󰈲";
}

.mdi-filter-check:before {
  content: "󱣬";
}

.mdi-filter-check-outline:before {
  content: "󱣭";
}

.mdi-filter-cog:before {
  content: "󱪣";
}

.mdi-filter-cog-outline:before {
  content: "󱪤";
}

.mdi-filter-menu:before {
  content: "󱃥";
}

.mdi-filter-menu-outline:before {
  content: "󱃦";
}

.mdi-filter-minus:before {
  content: "󰻮";
}

.mdi-filter-minus-outline:before {
  content: "󰻯";
}

.mdi-filter-multiple:before {
  content: "󱨿";
}

.mdi-filter-multiple-outline:before {
  content: "󱩀";
}

.mdi-filter-off:before {
  content: "󱓯";
}

.mdi-filter-off-outline:before {
  content: "󱓰";
}

.mdi-filter-outline:before {
  content: "󰈳";
}

.mdi-filter-plus:before {
  content: "󰻰";
}

.mdi-filter-plus-outline:before {
  content: "󰻱";
}

.mdi-filter-remove:before {
  content: "󰈴";
}

.mdi-filter-remove-outline:before {
  content: "󰈵";
}

.mdi-filter-settings:before {
  content: "󱪥";
}

.mdi-filter-settings-outline:before {
  content: "󱪦";
}

.mdi-filter-variant:before {
  content: "󰈶";
}

.mdi-filter-variant-minus:before {
  content: "󱄒";
}

.mdi-filter-variant-plus:before {
  content: "󱄓";
}

.mdi-filter-variant-remove:before {
  content: "󱀿";
}

.mdi-finance:before {
  content: "󰠟";
}

.mdi-find-replace:before {
  content: "󰛔";
}

.mdi-fingerprint:before {
  content: "󰈷";
}

.mdi-fingerprint-off:before {
  content: "󰺱";
}

.mdi-fire:before {
  content: "󰈸";
}

.mdi-fire-alert:before {
  content: "󱗗";
}

.mdi-fire-circle:before {
  content: "󱠇";
}

.mdi-fire-extinguisher:before {
  content: "󰻲";
}

.mdi-fire-hydrant:before {
  content: "󱄷";
}

.mdi-fire-hydrant-alert:before {
  content: "󱄸";
}

.mdi-fire-hydrant-off:before {
  content: "󱄹";
}

.mdi-fire-off:before {
  content: "󱜢";
}

.mdi-fire-truck:before {
  content: "󰢫";
}

.mdi-firebase:before {
  content: "󰥧";
}

.mdi-firefox:before {
  content: "󰈹";
}

.mdi-fireplace:before {
  content: "󰸮";
}

.mdi-fireplace-off:before {
  content: "󰸯";
}

.mdi-firewire:before {
  content: "󰖾";
}

.mdi-firework:before {
  content: "󰸰";
}

.mdi-firework-off:before {
  content: "󱜣";
}

.mdi-fish:before {
  content: "󰈺";
}

.mdi-fish-off:before {
  content: "󱏳";
}

.mdi-fishbowl:before {
  content: "󰻳";
}

.mdi-fishbowl-outline:before {
  content: "󰻴";
}

.mdi-fit-to-page:before {
  content: "󰻵";
}

.mdi-fit-to-page-outline:before {
  content: "󰻶";
}

.mdi-fit-to-screen:before {
  content: "󱣴";
}

.mdi-fit-to-screen-outline:before {
  content: "󱣵";
}

.mdi-flag:before {
  content: "󰈻";
}

.mdi-flag-checkered:before {
  content: "󰈼";
}

.mdi-flag-minus:before {
  content: "󰮙";
}

.mdi-flag-minus-outline:before {
  content: "󱂲";
}

.mdi-flag-off:before {
  content: "󱣮";
}

.mdi-flag-off-outline:before {
  content: "󱣯";
}

.mdi-flag-outline:before {
  content: "󰈽";
}

.mdi-flag-plus:before {
  content: "󰮚";
}

.mdi-flag-plus-outline:before {
  content: "󱂳";
}

.mdi-flag-remove:before {
  content: "󰮛";
}

.mdi-flag-remove-outline:before {
  content: "󱂴";
}

.mdi-flag-triangle:before {
  content: "󰈿";
}

.mdi-flag-variant:before {
  content: "󰉀";
}

.mdi-flag-variant-minus:before {
  content: "󱮴";
}

.mdi-flag-variant-minus-outline:before {
  content: "󱮵";
}

.mdi-flag-variant-off:before {
  content: "󱮰";
}

.mdi-flag-variant-off-outline:before {
  content: "󱮱";
}

.mdi-flag-variant-outline:before {
  content: "󰈾";
}

.mdi-flag-variant-plus:before {
  content: "󱮲";
}

.mdi-flag-variant-plus-outline:before {
  content: "󱮳";
}

.mdi-flag-variant-remove:before {
  content: "󱮶";
}

.mdi-flag-variant-remove-outline:before {
  content: "󱮷";
}

.mdi-flare:before {
  content: "󰵲";
}

.mdi-flash:before {
  content: "󰉁";
}

.mdi-flash-alert:before {
  content: "󰻷";
}

.mdi-flash-alert-outline:before {
  content: "󰻸";
}

.mdi-flash-auto:before {
  content: "󰉂";
}

.mdi-flash-off:before {
  content: "󰉃";
}

.mdi-flash-off-outline:before {
  content: "󱭅";
}

.mdi-flash-outline:before {
  content: "󰛕";
}

.mdi-flash-red-eye:before {
  content: "󰙻";
}

.mdi-flash-triangle:before {
  content: "󱬝";
}

.mdi-flash-triangle-outline:before {
  content: "󱬞";
}

.mdi-flashlight:before {
  content: "󰉄";
}

.mdi-flashlight-off:before {
  content: "󰉅";
}

.mdi-flask:before {
  content: "󰂓";
}

.mdi-flask-empty:before {
  content: "󰂔";
}

.mdi-flask-empty-minus:before {
  content: "󱈺";
}

.mdi-flask-empty-minus-outline:before {
  content: "󱈻";
}

.mdi-flask-empty-off:before {
  content: "󱏴";
}

.mdi-flask-empty-off-outline:before {
  content: "󱏵";
}

.mdi-flask-empty-outline:before {
  content: "󰂕";
}

.mdi-flask-empty-plus:before {
  content: "󱈼";
}

.mdi-flask-empty-plus-outline:before {
  content: "󱈽";
}

.mdi-flask-empty-remove:before {
  content: "󱈾";
}

.mdi-flask-empty-remove-outline:before {
  content: "󱈿";
}

.mdi-flask-minus:before {
  content: "󱉀";
}

.mdi-flask-minus-outline:before {
  content: "󱉁";
}

.mdi-flask-off:before {
  content: "󱏶";
}

.mdi-flask-off-outline:before {
  content: "󱏷";
}

.mdi-flask-outline:before {
  content: "󰂖";
}

.mdi-flask-plus:before {
  content: "󱉂";
}

.mdi-flask-plus-outline:before {
  content: "󱉃";
}

.mdi-flask-remove:before {
  content: "󱉄";
}

.mdi-flask-remove-outline:before {
  content: "󱉅";
}

.mdi-flask-round-bottom:before {
  content: "󱉋";
}

.mdi-flask-round-bottom-empty:before {
  content: "󱉌";
}

.mdi-flask-round-bottom-empty-outline:before {
  content: "󱉍";
}

.mdi-flask-round-bottom-outline:before {
  content: "󱉎";
}

.mdi-fleur-de-lis:before {
  content: "󱌃";
}

.mdi-flip-horizontal:before {
  content: "󱃧";
}

.mdi-flip-to-back:before {
  content: "󰉇";
}

.mdi-flip-to-front:before {
  content: "󰉈";
}

.mdi-flip-vertical:before {
  content: "󱃨";
}

.mdi-floor-lamp:before {
  content: "󰣝";
}

.mdi-floor-lamp-dual:before {
  content: "󱁀";
}

.mdi-floor-lamp-dual-outline:before {
  content: "󱟎";
}

.mdi-floor-lamp-outline:before {
  content: "󱟈";
}

.mdi-floor-lamp-torchiere:before {
  content: "󱝇";
}

.mdi-floor-lamp-torchiere-outline:before {
  content: "󱟖";
}

.mdi-floor-lamp-torchiere-variant:before {
  content: "󱁁";
}

.mdi-floor-lamp-torchiere-variant-outline:before {
  content: "󱟏";
}

.mdi-floor-plan:before {
  content: "󰠡";
}

.mdi-floppy:before {
  content: "󰉉";
}

.mdi-floppy-variant:before {
  content: "󰧯";
}

.mdi-flower:before {
  content: "󰉊";
}

.mdi-flower-outline:before {
  content: "󰧰";
}

.mdi-flower-pollen:before {
  content: "󱢅";
}

.mdi-flower-pollen-outline:before {
  content: "󱢆";
}

.mdi-flower-poppy:before {
  content: "󰴈";
}

.mdi-flower-tulip:before {
  content: "󰧱";
}

.mdi-flower-tulip-outline:before {
  content: "󰧲";
}

.mdi-focus-auto:before {
  content: "󰽎";
}

.mdi-focus-field:before {
  content: "󰽏";
}

.mdi-focus-field-horizontal:before {
  content: "󰽐";
}

.mdi-focus-field-vertical:before {
  content: "󰽑";
}

.mdi-folder:before {
  content: "󰉋";
}

.mdi-folder-account:before {
  content: "󰉌";
}

.mdi-folder-account-outline:before {
  content: "󰮜";
}

.mdi-folder-alert:before {
  content: "󰷌";
}

.mdi-folder-alert-outline:before {
  content: "󰷍";
}

.mdi-folder-arrow-down:before {
  content: "󱧨";
}

.mdi-folder-arrow-down-outline:before {
  content: "󱧩";
}

.mdi-folder-arrow-left:before {
  content: "󱧪";
}

.mdi-folder-arrow-left-outline:before {
  content: "󱧫";
}

.mdi-folder-arrow-left-right:before {
  content: "󱧬";
}

.mdi-folder-arrow-left-right-outline:before {
  content: "󱧭";
}

.mdi-folder-arrow-right:before {
  content: "󱧮";
}

.mdi-folder-arrow-right-outline:before {
  content: "󱧯";
}

.mdi-folder-arrow-up:before {
  content: "󱧰";
}

.mdi-folder-arrow-up-down:before {
  content: "󱧱";
}

.mdi-folder-arrow-up-down-outline:before {
  content: "󱧲";
}

.mdi-folder-arrow-up-outline:before {
  content: "󱧳";
}

.mdi-folder-cancel:before {
  content: "󱧴";
}

.mdi-folder-cancel-outline:before {
  content: "󱧵";
}

.mdi-folder-check:before {
  content: "󱥾";
}

.mdi-folder-check-outline:before {
  content: "󱥿";
}

.mdi-folder-clock:before {
  content: "󰪺";
}

.mdi-folder-clock-outline:before {
  content: "󰪻";
}

.mdi-folder-cog:before {
  content: "󱁿";
}

.mdi-folder-cog-outline:before {
  content: "󱂀";
}

.mdi-folder-download:before {
  content: "󰉍";
}

.mdi-folder-download-outline:before {
  content: "󱃩";
}

.mdi-folder-edit:before {
  content: "󰣞";
}

.mdi-folder-edit-outline:before {
  content: "󰷎";
}

.mdi-folder-eye:before {
  content: "󱞊";
}

.mdi-folder-eye-outline:before {
  content: "󱞋";
}

.mdi-folder-file:before {
  content: "󱧶";
}

.mdi-folder-file-outline:before {
  content: "󱧷";
}

.mdi-folder-google-drive:before {
  content: "󰉎";
}

.mdi-folder-heart:before {
  content: "󱃪";
}

.mdi-folder-heart-outline:before {
  content: "󱃫";
}

.mdi-folder-hidden:before {
  content: "󱞞";
}

.mdi-folder-home:before {
  content: "󱂵";
}

.mdi-folder-home-outline:before {
  content: "󱂶";
}

.mdi-folder-image:before {
  content: "󰉏";
}

.mdi-folder-information:before {
  content: "󱂷";
}

.mdi-folder-information-outline:before {
  content: "󱂸";
}

.mdi-folder-key:before {
  content: "󰢬";
}

.mdi-folder-key-network:before {
  content: "󰢭";
}

.mdi-folder-key-network-outline:before {
  content: "󰲀";
}

.mdi-folder-key-outline:before {
  content: "󱃬";
}

.mdi-folder-lock:before {
  content: "󰉐";
}

.mdi-folder-lock-open:before {
  content: "󰉑";
}

.mdi-folder-lock-open-outline:before {
  content: "󱪧";
}

.mdi-folder-lock-outline:before {
  content: "󱪨";
}

.mdi-folder-marker:before {
  content: "󱉭";
}

.mdi-folder-marker-outline:before {
  content: "󱉮";
}

.mdi-folder-minus:before {
  content: "󱭉";
}

.mdi-folder-minus-outline:before {
  content: "󱭊";
}

.mdi-folder-move:before {
  content: "󰉒";
}

.mdi-folder-move-outline:before {
  content: "󱉆";
}

.mdi-folder-multiple:before {
  content: "󰉓";
}

.mdi-folder-multiple-image:before {
  content: "󰉔";
}

.mdi-folder-multiple-outline:before {
  content: "󰉕";
}

.mdi-folder-multiple-plus:before {
  content: "󱑾";
}

.mdi-folder-multiple-plus-outline:before {
  content: "󱑿";
}

.mdi-folder-music:before {
  content: "󱍙";
}

.mdi-folder-music-outline:before {
  content: "󱍚";
}

.mdi-folder-network:before {
  content: "󰡰";
}

.mdi-folder-network-outline:before {
  content: "󰲁";
}

.mdi-folder-off:before {
  content: "󱧸";
}

.mdi-folder-off-outline:before {
  content: "󱧹";
}

.mdi-folder-open:before {
  content: "󰝰";
}

.mdi-folder-open-outline:before {
  content: "󰷏";
}

.mdi-folder-outline:before {
  content: "󰉖";
}

.mdi-folder-play:before {
  content: "󱧺";
}

.mdi-folder-play-outline:before {
  content: "󱧻";
}

.mdi-folder-plus:before {
  content: "󰉗";
}

.mdi-folder-plus-outline:before {
  content: "󰮝";
}

.mdi-folder-pound:before {
  content: "󰴉";
}

.mdi-folder-pound-outline:before {
  content: "󰴊";
}

.mdi-folder-question:before {
  content: "󱧊";
}

.mdi-folder-question-outline:before {
  content: "󱧋";
}

.mdi-folder-refresh:before {
  content: "󰝉";
}

.mdi-folder-refresh-outline:before {
  content: "󰕂";
}

.mdi-folder-remove:before {
  content: "󰉘";
}

.mdi-folder-remove-outline:before {
  content: "󰮞";
}

.mdi-folder-search:before {
  content: "󰥨";
}

.mdi-folder-search-outline:before {
  content: "󰥩";
}

.mdi-folder-settings:before {
  content: "󱁽";
}

.mdi-folder-settings-outline:before {
  content: "󱁾";
}

.mdi-folder-star:before {
  content: "󰚝";
}

.mdi-folder-star-multiple:before {
  content: "󱏓";
}

.mdi-folder-star-multiple-outline:before {
  content: "󱏔";
}

.mdi-folder-star-outline:before {
  content: "󰮟";
}

.mdi-folder-swap:before {
  content: "󰾶";
}

.mdi-folder-swap-outline:before {
  content: "󰾷";
}

.mdi-folder-sync:before {
  content: "󰴋";
}

.mdi-folder-sync-outline:before {
  content: "󰴌";
}

.mdi-folder-table:before {
  content: "󱋣";
}

.mdi-folder-table-outline:before {
  content: "󱋤";
}

.mdi-folder-text:before {
  content: "󰲂";
}

.mdi-folder-text-outline:before {
  content: "󰲃";
}

.mdi-folder-upload:before {
  content: "󰉙";
}

.mdi-folder-upload-outline:before {
  content: "󱃭";
}

.mdi-folder-wrench:before {
  content: "󱧼";
}

.mdi-folder-wrench-outline:before {
  content: "󱧽";
}

.mdi-folder-zip:before {
  content: "󰛫";
}

.mdi-folder-zip-outline:before {
  content: "󰞹";
}

.mdi-font-awesome:before {
  content: "󰀺";
}

.mdi-food:before {
  content: "󰉚";
}

.mdi-food-apple:before {
  content: "󰉛";
}

.mdi-food-apple-outline:before {
  content: "󰲄";
}

.mdi-food-croissant:before {
  content: "󰟈";
}

.mdi-food-drumstick:before {
  content: "󱐟";
}

.mdi-food-drumstick-off:before {
  content: "󱑨";
}

.mdi-food-drumstick-off-outline:before {
  content: "󱑩";
}

.mdi-food-drumstick-outline:before {
  content: "󱐠";
}

.mdi-food-fork-drink:before {
  content: "󰗲";
}

.mdi-food-halal:before {
  content: "󱕲";
}

.mdi-food-hot-dog:before {
  content: "󱡋";
}

.mdi-food-kosher:before {
  content: "󱕳";
}

.mdi-food-off:before {
  content: "󰗳";
}

.mdi-food-off-outline:before {
  content: "󱤕";
}

.mdi-food-outline:before {
  content: "󱤖";
}

.mdi-food-steak:before {
  content: "󱑪";
}

.mdi-food-steak-off:before {
  content: "󱑫";
}

.mdi-food-takeout-box:before {
  content: "󱠶";
}

.mdi-food-takeout-box-outline:before {
  content: "󱠷";
}

.mdi-food-turkey:before {
  content: "󱜜";
}

.mdi-food-variant:before {
  content: "󰉜";
}

.mdi-food-variant-off:before {
  content: "󱏥";
}

.mdi-foot-print:before {
  content: "󰽒";
}

.mdi-football:before {
  content: "󰉝";
}

.mdi-football-australian:before {
  content: "󰉞";
}

.mdi-football-helmet:before {
  content: "󰉟";
}

.mdi-forest:before {
  content: "󱢗";
}

.mdi-forest-outline:before {
  content: "󱱣";
}

.mdi-forklift:before {
  content: "󰟉";
}

.mdi-form-dropdown:before {
  content: "󱐀";
}

.mdi-form-select:before {
  content: "󱐁";
}

.mdi-form-textarea:before {
  content: "󱂕";
}

.mdi-form-textbox:before {
  content: "󰘎";
}

.mdi-form-textbox-lock:before {
  content: "󱍝";
}

.mdi-form-textbox-password:before {
  content: "󰟵";
}

.mdi-format-align-bottom:before {
  content: "󰝓";
}

.mdi-format-align-center:before {
  content: "󰉠";
}

.mdi-format-align-justify:before {
  content: "󰉡";
}

.mdi-format-align-left:before {
  content: "󰉢";
}

.mdi-format-align-middle:before {
  content: "󰝔";
}

.mdi-format-align-right:before {
  content: "󰉣";
}

.mdi-format-align-top:before {
  content: "󰝕";
}

.mdi-format-annotation-minus:before {
  content: "󰪼";
}

.mdi-format-annotation-plus:before {
  content: "󰙆";
}

.mdi-format-bold:before {
  content: "󰉤";
}

.mdi-format-clear:before {
  content: "󰉥";
}

.mdi-format-color-fill:before {
  content: "󰉦";
}

.mdi-format-color-highlight:before {
  content: "󰸱";
}

.mdi-format-color-marker-cancel:before {
  content: "󱌓";
}

.mdi-format-color-text:before {
  content: "󰚞";
}

.mdi-format-columns:before {
  content: "󰣟";
}

.mdi-format-float-center:before {
  content: "󰉧";
}

.mdi-format-float-left:before {
  content: "󰉨";
}

.mdi-format-float-none:before {
  content: "󰉩";
}

.mdi-format-float-right:before {
  content: "󰉪";
}

.mdi-format-font:before {
  content: "󰛖";
}

.mdi-format-font-size-decrease:before {
  content: "󰧳";
}

.mdi-format-font-size-increase:before {
  content: "󰧴";
}

.mdi-format-header-1:before {
  content: "󰉫";
}

.mdi-format-header-2:before {
  content: "󰉬";
}

.mdi-format-header-3:before {
  content: "󰉭";
}

.mdi-format-header-4:before {
  content: "󰉮";
}

.mdi-format-header-5:before {
  content: "󰉯";
}

.mdi-format-header-6:before {
  content: "󰉰";
}

.mdi-format-header-decrease:before {
  content: "󰉱";
}

.mdi-format-header-equal:before {
  content: "󰉲";
}

.mdi-format-header-increase:before {
  content: "󰉳";
}

.mdi-format-header-pound:before {
  content: "󰉴";
}

.mdi-format-horizontal-align-center:before {
  content: "󰘞";
}

.mdi-format-horizontal-align-left:before {
  content: "󰘟";
}

.mdi-format-horizontal-align-right:before {
  content: "󰘠";
}

.mdi-format-indent-decrease:before {
  content: "󰉵";
}

.mdi-format-indent-increase:before {
  content: "󰉶";
}

.mdi-format-italic:before {
  content: "󰉷";
}

.mdi-format-letter-case:before {
  content: "󰬴";
}

.mdi-format-letter-case-lower:before {
  content: "󰬵";
}

.mdi-format-letter-case-upper:before {
  content: "󰬶";
}

.mdi-format-letter-ends-with:before {
  content: "󰾸";
}

.mdi-format-letter-matches:before {
  content: "󰾹";
}

.mdi-format-letter-spacing:before {
  content: "󱥖";
}

.mdi-format-letter-spacing-variant:before {
  content: "󱫻";
}

.mdi-format-letter-starts-with:before {
  content: "󰾺";
}

.mdi-format-line-height:before {
  content: "󱫼";
}

.mdi-format-line-spacing:before {
  content: "󰉸";
}

.mdi-format-line-style:before {
  content: "󰗈";
}

.mdi-format-line-weight:before {
  content: "󰗉";
}

.mdi-format-list-bulleted:before {
  content: "󰉹";
}

.mdi-format-list-bulleted-square:before {
  content: "󰷐";
}

.mdi-format-list-bulleted-triangle:before {
  content: "󰺲";
}

.mdi-format-list-bulleted-type:before {
  content: "󰉺";
}

.mdi-format-list-checkbox:before {
  content: "󰥪";
}

.mdi-format-list-checks:before {
  content: "󰝖";
}

.mdi-format-list-group:before {
  content: "󱡠";
}

.mdi-format-list-group-plus:before {
  content: "󱭖";
}

.mdi-format-list-numbered:before {
  content: "󰉻";
}

.mdi-format-list-numbered-rtl:before {
  content: "󰴍";
}

.mdi-format-list-text:before {
  content: "󱉯";
}

.mdi-format-overline:before {
  content: "󰺳";
}

.mdi-format-page-break:before {
  content: "󰛗";
}

.mdi-format-page-split:before {
  content: "󱤗";
}

.mdi-format-paint:before {
  content: "󰉼";
}

.mdi-format-paragraph:before {
  content: "󰉽";
}

.mdi-format-paragraph-spacing:before {
  content: "󱫽";
}

.mdi-format-pilcrow:before {
  content: "󰛘";
}

.mdi-format-pilcrow-arrow-left:before {
  content: "󰊆";
}

.mdi-format-pilcrow-arrow-right:before {
  content: "󰊅";
}

.mdi-format-quote-close:before {
  content: "󰉾";
}

.mdi-format-quote-close-outline:before {
  content: "󱆨";
}

.mdi-format-quote-open:before {
  content: "󰝗";
}

.mdi-format-quote-open-outline:before {
  content: "󱆧";
}

.mdi-format-rotate-90:before {
  content: "󰚪";
}

.mdi-format-section:before {
  content: "󰚟";
}

.mdi-format-size:before {
  content: "󰉿";
}

.mdi-format-strikethrough:before {
  content: "󰊀";
}

.mdi-format-strikethrough-variant:before {
  content: "󰊁";
}

.mdi-format-subscript:before {
  content: "󰊂";
}

.mdi-format-superscript:before {
  content: "󰊃";
}

.mdi-format-text:before {
  content: "󰊄";
}

.mdi-format-text-rotation-angle-down:before {
  content: "󰾻";
}

.mdi-format-text-rotation-angle-up:before {
  content: "󰾼";
}

.mdi-format-text-rotation-down:before {
  content: "󰵳";
}

.mdi-format-text-rotation-down-vertical:before {
  content: "󰾽";
}

.mdi-format-text-rotation-none:before {
  content: "󰵴";
}

.mdi-format-text-rotation-up:before {
  content: "󰾾";
}

.mdi-format-text-rotation-vertical:before {
  content: "󰾿";
}

.mdi-format-text-variant:before {
  content: "󰸲";
}

.mdi-format-text-variant-outline:before {
  content: "󱔏";
}

.mdi-format-text-wrapping-clip:before {
  content: "󰴎";
}

.mdi-format-text-wrapping-overflow:before {
  content: "󰴏";
}

.mdi-format-text-wrapping-wrap:before {
  content: "󰴐";
}

.mdi-format-textbox:before {
  content: "󰴑";
}

.mdi-format-title:before {
  content: "󰗴";
}

.mdi-format-underline:before {
  content: "󰊇";
}

.mdi-format-underline-wavy:before {
  content: "󱣩";
}

.mdi-format-vertical-align-bottom:before {
  content: "󰘡";
}

.mdi-format-vertical-align-center:before {
  content: "󰘢";
}

.mdi-format-vertical-align-top:before {
  content: "󰘣";
}

.mdi-format-wrap-inline:before {
  content: "󰊈";
}

.mdi-format-wrap-square:before {
  content: "󰊉";
}

.mdi-format-wrap-tight:before {
  content: "󰊊";
}

.mdi-format-wrap-top-bottom:before {
  content: "󰊋";
}

.mdi-forum:before {
  content: "󰊌";
}

.mdi-forum-minus:before {
  content: "󱪩";
}

.mdi-forum-minus-outline:before {
  content: "󱪪";
}

.mdi-forum-outline:before {
  content: "󰠢";
}

.mdi-forum-plus:before {
  content: "󱪫";
}

.mdi-forum-plus-outline:before {
  content: "󱪬";
}

.mdi-forum-remove:before {
  content: "󱪭";
}

.mdi-forum-remove-outline:before {
  content: "󱪮";
}

.mdi-forward:before {
  content: "󰊍";
}

.mdi-forwardburger:before {
  content: "󰵵";
}

.mdi-fountain:before {
  content: "󰥫";
}

.mdi-fountain-pen:before {
  content: "󰴒";
}

.mdi-fountain-pen-tip:before {
  content: "󰴓";
}

.mdi-fraction-one-half:before {
  content: "󱦒";
}

.mdi-freebsd:before {
  content: "󰣠";
}

.mdi-french-fries:before {
  content: "󱥗";
}

.mdi-frequently-asked-questions:before {
  content: "󰺴";
}

.mdi-fridge:before {
  content: "󰊐";
}

.mdi-fridge-alert:before {
  content: "󱆱";
}

.mdi-fridge-alert-outline:before {
  content: "󱆲";
}

.mdi-fridge-bottom:before {
  content: "󰊒";
}

.mdi-fridge-industrial:before {
  content: "󱗮";
}

.mdi-fridge-industrial-alert:before {
  content: "󱗯";
}

.mdi-fridge-industrial-alert-outline:before {
  content: "󱗰";
}

.mdi-fridge-industrial-off:before {
  content: "󱗱";
}

.mdi-fridge-industrial-off-outline:before {
  content: "󱗲";
}

.mdi-fridge-industrial-outline:before {
  content: "󱗳";
}

.mdi-fridge-off:before {
  content: "󱆯";
}

.mdi-fridge-off-outline:before {
  content: "󱆰";
}

.mdi-fridge-outline:before {
  content: "󰊏";
}

.mdi-fridge-top:before {
  content: "󰊑";
}

.mdi-fridge-variant:before {
  content: "󱗴";
}

.mdi-fridge-variant-alert:before {
  content: "󱗵";
}

.mdi-fridge-variant-alert-outline:before {
  content: "󱗶";
}

.mdi-fridge-variant-off:before {
  content: "󱗷";
}

.mdi-fridge-variant-off-outline:before {
  content: "󱗸";
}

.mdi-fridge-variant-outline:before {
  content: "󱗹";
}

.mdi-fruit-cherries:before {
  content: "󱁂";
}

.mdi-fruit-cherries-off:before {
  content: "󱏸";
}

.mdi-fruit-citrus:before {
  content: "󱁃";
}

.mdi-fruit-citrus-off:before {
  content: "󱏹";
}

.mdi-fruit-grapes:before {
  content: "󱁄";
}

.mdi-fruit-grapes-outline:before {
  content: "󱁅";
}

.mdi-fruit-pear:before {
  content: "󱨎";
}

.mdi-fruit-pineapple:before {
  content: "󱁆";
}

.mdi-fruit-watermelon:before {
  content: "󱁇";
}

.mdi-fuel:before {
  content: "󰟊";
}

.mdi-fuel-cell:before {
  content: "󱢵";
}

.mdi-fullscreen:before {
  content: "󰊓";
}

.mdi-fullscreen-exit:before {
  content: "󰊔";
}

.mdi-function:before {
  content: "󰊕";
}

.mdi-function-variant:before {
  content: "󰡱";
}

.mdi-furigana-horizontal:before {
  content: "󱂁";
}

.mdi-furigana-vertical:before {
  content: "󱂂";
}

.mdi-fuse:before {
  content: "󰲅";
}

.mdi-fuse-alert:before {
  content: "󱐭";
}

.mdi-fuse-blade:before {
  content: "󰲆";
}

.mdi-fuse-off:before {
  content: "󱐬";
}

.mdi-gamepad:before {
  content: "󰊖";
}

.mdi-gamepad-circle:before {
  content: "󰸳";
}

.mdi-gamepad-circle-down:before {
  content: "󰸴";
}

.mdi-gamepad-circle-left:before {
  content: "󰸵";
}

.mdi-gamepad-circle-outline:before {
  content: "󰸶";
}

.mdi-gamepad-circle-right:before {
  content: "󰸷";
}

.mdi-gamepad-circle-up:before {
  content: "󰸸";
}

.mdi-gamepad-down:before {
  content: "󰸹";
}

.mdi-gamepad-left:before {
  content: "󰸺";
}

.mdi-gamepad-outline:before {
  content: "󱤙";
}

.mdi-gamepad-right:before {
  content: "󰸻";
}

.mdi-gamepad-round:before {
  content: "󰸼";
}

.mdi-gamepad-round-down:before {
  content: "󰸽";
}

.mdi-gamepad-round-left:before {
  content: "󰸾";
}

.mdi-gamepad-round-outline:before {
  content: "󰸿";
}

.mdi-gamepad-round-right:before {
  content: "󰹀";
}

.mdi-gamepad-round-up:before {
  content: "󰹁";
}

.mdi-gamepad-square:before {
  content: "󰺵";
}

.mdi-gamepad-square-outline:before {
  content: "󰺶";
}

.mdi-gamepad-up:before {
  content: "󰹂";
}

.mdi-gamepad-variant:before {
  content: "󰊗";
}

.mdi-gamepad-variant-outline:before {
  content: "󰺷";
}

.mdi-gamma:before {
  content: "󱃮";
}

.mdi-gantry-crane:before {
  content: "󰷑";
}

.mdi-garage:before {
  content: "󰛙";
}

.mdi-garage-alert:before {
  content: "󰡲";
}

.mdi-garage-alert-variant:before {
  content: "󱋕";
}

.mdi-garage-lock:before {
  content: "󱟻";
}

.mdi-garage-open:before {
  content: "󰛚";
}

.mdi-garage-open-variant:before {
  content: "󱋔";
}

.mdi-garage-variant:before {
  content: "󱋓";
}

.mdi-garage-variant-lock:before {
  content: "󱟼";
}

.mdi-gas-burner:before {
  content: "󱨛";
}

.mdi-gas-cylinder:before {
  content: "󰙇";
}

.mdi-gas-station:before {
  content: "󰊘";
}

.mdi-gas-station-off:before {
  content: "󱐉";
}

.mdi-gas-station-off-outline:before {
  content: "󱐊";
}

.mdi-gas-station-outline:before {
  content: "󰺸";
}

.mdi-gate:before {
  content: "󰊙";
}

.mdi-gate-alert:before {
  content: "󱟸";
}

.mdi-gate-and:before {
  content: "󰣡";
}

.mdi-gate-arrow-left:before {
  content: "󱟷";
}

.mdi-gate-arrow-right:before {
  content: "󱅩";
}

.mdi-gate-buffer:before {
  content: "󱫾";
}

.mdi-gate-nand:before {
  content: "󰣢";
}

.mdi-gate-nor:before {
  content: "󰣣";
}

.mdi-gate-not:before {
  content: "󰣤";
}

.mdi-gate-open:before {
  content: "󱅪";
}

.mdi-gate-or:before {
  content: "󰣥";
}

.mdi-gate-xnor:before {
  content: "󰣦";
}

.mdi-gate-xor:before {
  content: "󰣧";
}

.mdi-gatsby:before {
  content: "󰹃";
}

.mdi-gauge:before {
  content: "󰊚";
}

.mdi-gauge-empty:before {
  content: "󰡳";
}

.mdi-gauge-full:before {
  content: "󰡴";
}

.mdi-gauge-low:before {
  content: "󰡵";
}

.mdi-gavel:before {
  content: "󰊛";
}

.mdi-gender-female:before {
  content: "󰊜";
}

.mdi-gender-male:before {
  content: "󰊝";
}

.mdi-gender-male-female:before {
  content: "󰊞";
}

.mdi-gender-male-female-variant:before {
  content: "󱄿";
}

.mdi-gender-non-binary:before {
  content: "󱅀";
}

.mdi-gender-transgender:before {
  content: "󰊟";
}

.mdi-gentoo:before {
  content: "󰣨";
}

.mdi-gesture:before {
  content: "󰟋";
}

.mdi-gesture-double-tap:before {
  content: "󰜼";
}

.mdi-gesture-pinch:before {
  content: "󰪽";
}

.mdi-gesture-spread:before {
  content: "󰪾";
}

.mdi-gesture-swipe:before {
  content: "󰵶";
}

.mdi-gesture-swipe-down:before {
  content: "󰜽";
}

.mdi-gesture-swipe-horizontal:before {
  content: "󰪿";
}

.mdi-gesture-swipe-left:before {
  content: "󰜾";
}

.mdi-gesture-swipe-right:before {
  content: "󰜿";
}

.mdi-gesture-swipe-up:before {
  content: "󰝀";
}

.mdi-gesture-swipe-vertical:before {
  content: "󰫀";
}

.mdi-gesture-tap:before {
  content: "󰝁";
}

.mdi-gesture-tap-box:before {
  content: "󱊩";
}

.mdi-gesture-tap-button:before {
  content: "󱊨";
}

.mdi-gesture-tap-hold:before {
  content: "󰵷";
}

.mdi-gesture-two-double-tap:before {
  content: "󰝂";
}

.mdi-gesture-two-tap:before {
  content: "󰝃";
}

.mdi-ghost:before {
  content: "󰊠";
}

.mdi-ghost-off:before {
  content: "󰧵";
}

.mdi-ghost-off-outline:before {
  content: "󱙜";
}

.mdi-ghost-outline:before {
  content: "󱙝";
}

.mdi-gift:before {
  content: "󰹄";
}

.mdi-gift-off:before {
  content: "󱛯";
}

.mdi-gift-off-outline:before {
  content: "󱛰";
}

.mdi-gift-open:before {
  content: "󱛱";
}

.mdi-gift-open-outline:before {
  content: "󱛲";
}

.mdi-gift-outline:before {
  content: "󰊡";
}

.mdi-git:before {
  content: "󰊢";
}

.mdi-github:before {
  content: "󰊤";
}

.mdi-gitlab:before {
  content: "󰮠";
}

.mdi-glass-cocktail:before {
  content: "󰍖";
}

.mdi-glass-cocktail-off:before {
  content: "󱗦";
}

.mdi-glass-flute:before {
  content: "󰊥";
}

.mdi-glass-fragile:before {
  content: "󱡳";
}

.mdi-glass-mug:before {
  content: "󰊦";
}

.mdi-glass-mug-off:before {
  content: "󱗧";
}

.mdi-glass-mug-variant:before {
  content: "󱄖";
}

.mdi-glass-mug-variant-off:before {
  content: "󱗨";
}

.mdi-glass-pint-outline:before {
  content: "󱌍";
}

.mdi-glass-stange:before {
  content: "󰊧";
}

.mdi-glass-tulip:before {
  content: "󰊨";
}

.mdi-glass-wine:before {
  content: "󰡶";
}

.mdi-glasses:before {
  content: "󰊪";
}

.mdi-globe-light:before {
  content: "󰙯";
}

.mdi-globe-light-outline:before {
  content: "󱋗";
}

.mdi-globe-model:before {
  content: "󰣩";
}

.mdi-gmail:before {
  content: "󰊫";
}

.mdi-gnome:before {
  content: "󰊬";
}

.mdi-go-kart:before {
  content: "󰵹";
}

.mdi-go-kart-track:before {
  content: "󰵺";
}

.mdi-gog:before {
  content: "󰮡";
}

.mdi-gold:before {
  content: "󱉏";
}

.mdi-golf:before {
  content: "󰠣";
}

.mdi-golf-cart:before {
  content: "󱆤";
}

.mdi-golf-tee:before {
  content: "󱂃";
}

.mdi-gondola:before {
  content: "󰚆";
}

.mdi-goodreads:before {
  content: "󰵻";
}

.mdi-google:before {
  content: "󰊭";
}

.mdi-google-ads:before {
  content: "󰲇";
}

.mdi-google-analytics:before {
  content: "󰟌";
}

.mdi-google-assistant:before {
  content: "󰟍";
}

.mdi-google-cardboard:before {
  content: "󰊮";
}

.mdi-google-chrome:before {
  content: "󰊯";
}

.mdi-google-circles:before {
  content: "󰊰";
}

.mdi-google-circles-communities:before {
  content: "󰊱";
}

.mdi-google-circles-extended:before {
  content: "󰊲";
}

.mdi-google-circles-group:before {
  content: "󰊳";
}

.mdi-google-classroom:before {
  content: "󰋀";
}

.mdi-google-cloud:before {
  content: "󱇶";
}

.mdi-google-downasaur:before {
  content: "󱍢";
}

.mdi-google-drive:before {
  content: "󰊶";
}

.mdi-google-earth:before {
  content: "󰊷";
}

.mdi-google-fit:before {
  content: "󰥬";
}

.mdi-google-glass:before {
  content: "󰊸";
}

.mdi-google-hangouts:before {
  content: "󰋉";
}

.mdi-google-keep:before {
  content: "󰛜";
}

.mdi-google-lens:before {
  content: "󰧶";
}

.mdi-google-maps:before {
  content: "󰗵";
}

.mdi-google-my-business:before {
  content: "󱁈";
}

.mdi-google-nearby:before {
  content: "󰊹";
}

.mdi-google-play:before {
  content: "󰊼";
}

.mdi-google-plus:before {
  content: "󰊽";
}

.mdi-google-podcast:before {
  content: "󰺹";
}

.mdi-google-spreadsheet:before {
  content: "󰧷";
}

.mdi-google-street-view:before {
  content: "󰲈";
}

.mdi-google-translate:before {
  content: "󰊿";
}

.mdi-gradient-horizontal:before {
  content: "󱝊";
}

.mdi-gradient-vertical:before {
  content: "󰚠";
}

.mdi-grain:before {
  content: "󰵼";
}

.mdi-graph:before {
  content: "󱁉";
}

.mdi-graph-outline:before {
  content: "󱁊";
}

.mdi-graphql:before {
  content: "󰡷";
}

.mdi-grass:before {
  content: "󱔐";
}

.mdi-grave-stone:before {
  content: "󰮢";
}

.mdi-grease-pencil:before {
  content: "󰙈";
}

.mdi-greater-than:before {
  content: "󰥭";
}

.mdi-greater-than-or-equal:before {
  content: "󰥮";
}

.mdi-greenhouse:before {
  content: "󰀭";
}

.mdi-grid:before {
  content: "󰋁";
}

.mdi-grid-large:before {
  content: "󰝘";
}

.mdi-grid-off:before {
  content: "󰋂";
}

.mdi-grill:before {
  content: "󰹅";
}

.mdi-grill-outline:before {
  content: "󱆊";
}

.mdi-group:before {
  content: "󰋃";
}

.mdi-guitar-acoustic:before {
  content: "󰝱";
}

.mdi-guitar-electric:before {
  content: "󰋄";
}

.mdi-guitar-pick:before {
  content: "󰋅";
}

.mdi-guitar-pick-outline:before {
  content: "󰋆";
}

.mdi-guy-fawkes-mask:before {
  content: "󰠥";
}

.mdi-gymnastics:before {
  content: "󱩁";
}

.mdi-hail:before {
  content: "󰫁";
}

.mdi-hair-dryer:before {
  content: "󱃯";
}

.mdi-hair-dryer-outline:before {
  content: "󱃰";
}

.mdi-halloween:before {
  content: "󰮣";
}

.mdi-hamburger:before {
  content: "󰚅";
}

.mdi-hamburger-check:before {
  content: "󱝶";
}

.mdi-hamburger-minus:before {
  content: "󱝷";
}

.mdi-hamburger-off:before {
  content: "󱝸";
}

.mdi-hamburger-plus:before {
  content: "󱝹";
}

.mdi-hamburger-remove:before {
  content: "󱝺";
}

.mdi-hammer:before {
  content: "󰣪";
}

.mdi-hammer-screwdriver:before {
  content: "󱌢";
}

.mdi-hammer-sickle:before {
  content: "󱢇";
}

.mdi-hammer-wrench:before {
  content: "󱌣";
}

.mdi-hand-back-left:before {
  content: "󰹆";
}

.mdi-hand-back-left-off:before {
  content: "󱠰";
}

.mdi-hand-back-left-off-outline:before {
  content: "󱠲";
}

.mdi-hand-back-left-outline:before {
  content: "󱠬";
}

.mdi-hand-back-right:before {
  content: "󰹇";
}

.mdi-hand-back-right-off:before {
  content: "󱠱";
}

.mdi-hand-back-right-off-outline:before {
  content: "󱠳";
}

.mdi-hand-back-right-outline:before {
  content: "󱠭";
}

.mdi-hand-clap:before {
  content: "󱥋";
}

.mdi-hand-clap-off:before {
  content: "󱩂";
}

.mdi-hand-coin:before {
  content: "󱢏";
}

.mdi-hand-coin-outline:before {
  content: "󱢐";
}

.mdi-hand-cycle:before {
  content: "󱮜";
}

.mdi-hand-extended:before {
  content: "󱢶";
}

.mdi-hand-extended-outline:before {
  content: "󱢷";
}

.mdi-hand-front-left:before {
  content: "󱠫";
}

.mdi-hand-front-left-outline:before {
  content: "󱠮";
}

.mdi-hand-front-right:before {
  content: "󰩏";
}

.mdi-hand-front-right-outline:before {
  content: "󱠯";
}

.mdi-hand-heart:before {
  content: "󱃱";
}

.mdi-hand-heart-outline:before {
  content: "󱕾";
}

.mdi-hand-okay:before {
  content: "󰩐";
}

.mdi-hand-peace:before {
  content: "󰩑";
}

.mdi-hand-peace-variant:before {
  content: "󰩒";
}

.mdi-hand-pointing-down:before {
  content: "󰩓";
}

.mdi-hand-pointing-left:before {
  content: "󰩔";
}

.mdi-hand-pointing-right:before {
  content: "󰋇";
}

.mdi-hand-pointing-up:before {
  content: "󰩕";
}

.mdi-hand-saw:before {
  content: "󰹈";
}

.mdi-hand-wash:before {
  content: "󱕿";
}

.mdi-hand-wash-outline:before {
  content: "󱖀";
}

.mdi-hand-water:before {
  content: "󱎟";
}

.mdi-hand-wave:before {
  content: "󱠡";
}

.mdi-hand-wave-outline:before {
  content: "󱠢";
}

.mdi-handball:before {
  content: "󰽓";
}

.mdi-handcuffs:before {
  content: "󱄾";
}

.mdi-hands-pray:before {
  content: "󰕹";
}

.mdi-handshake:before {
  content: "󱈘";
}

.mdi-handshake-outline:before {
  content: "󱖡";
}

.mdi-hanger:before {
  content: "󰋈";
}

.mdi-hard-hat:before {
  content: "󰥯";
}

.mdi-harddisk:before {
  content: "󰋊";
}

.mdi-harddisk-plus:before {
  content: "󱁋";
}

.mdi-harddisk-remove:before {
  content: "󱁌";
}

.mdi-hat-fedora:before {
  content: "󰮤";
}

.mdi-hazard-lights:before {
  content: "󰲉";
}

.mdi-hdmi-port:before {
  content: "󱮸";
}

.mdi-hdr:before {
  content: "󰵽";
}

.mdi-hdr-off:before {
  content: "󰵾";
}

.mdi-head:before {
  content: "󱍞";
}

.mdi-head-alert:before {
  content: "󱌸";
}

.mdi-head-alert-outline:before {
  content: "󱌹";
}

.mdi-head-check:before {
  content: "󱌺";
}

.mdi-head-check-outline:before {
  content: "󱌻";
}

.mdi-head-cog:before {
  content: "󱌼";
}

.mdi-head-cog-outline:before {
  content: "󱌽";
}

.mdi-head-dots-horizontal:before {
  content: "󱌾";
}

.mdi-head-dots-horizontal-outline:before {
  content: "󱌿";
}

.mdi-head-flash:before {
  content: "󱍀";
}

.mdi-head-flash-outline:before {
  content: "󱍁";
}

.mdi-head-heart:before {
  content: "󱍂";
}

.mdi-head-heart-outline:before {
  content: "󱍃";
}

.mdi-head-lightbulb:before {
  content: "󱍄";
}

.mdi-head-lightbulb-outline:before {
  content: "󱍅";
}

.mdi-head-minus:before {
  content: "󱍆";
}

.mdi-head-minus-outline:before {
  content: "󱍇";
}

.mdi-head-outline:before {
  content: "󱍟";
}

.mdi-head-plus:before {
  content: "󱍈";
}

.mdi-head-plus-outline:before {
  content: "󱍉";
}

.mdi-head-question:before {
  content: "󱍊";
}

.mdi-head-question-outline:before {
  content: "󱍋";
}

.mdi-head-remove:before {
  content: "󱍌";
}

.mdi-head-remove-outline:before {
  content: "󱍍";
}

.mdi-head-snowflake:before {
  content: "󱍎";
}

.mdi-head-snowflake-outline:before {
  content: "󱍏";
}

.mdi-head-sync:before {
  content: "󱍐";
}

.mdi-head-sync-outline:before {
  content: "󱍑";
}

.mdi-headphones:before {
  content: "󰋋";
}

.mdi-headphones-bluetooth:before {
  content: "󰥰";
}

.mdi-headphones-box:before {
  content: "󰋌";
}

.mdi-headphones-off:before {
  content: "󰟎";
}

.mdi-headphones-settings:before {
  content: "󰋍";
}

.mdi-headset:before {
  content: "󰋎";
}

.mdi-headset-dock:before {
  content: "󰋏";
}

.mdi-headset-off:before {
  content: "󰋐";
}

.mdi-heart:before {
  content: "󰋑";
}

.mdi-heart-box:before {
  content: "󰋒";
}

.mdi-heart-box-outline:before {
  content: "󰋓";
}

.mdi-heart-broken:before {
  content: "󰋔";
}

.mdi-heart-broken-outline:before {
  content: "󰴔";
}

.mdi-heart-circle:before {
  content: "󰥱";
}

.mdi-heart-circle-outline:before {
  content: "󰥲";
}

.mdi-heart-cog:before {
  content: "󱙣";
}

.mdi-heart-cog-outline:before {
  content: "󱙤";
}

.mdi-heart-flash:before {
  content: "󰻹";
}

.mdi-heart-half:before {
  content: "󰛟";
}

.mdi-heart-half-full:before {
  content: "󰛞";
}

.mdi-heart-half-outline:before {
  content: "󰛠";
}

.mdi-heart-minus:before {
  content: "󱐯";
}

.mdi-heart-minus-outline:before {
  content: "󱐲";
}

.mdi-heart-multiple:before {
  content: "󰩖";
}

.mdi-heart-multiple-outline:before {
  content: "󰩗";
}

.mdi-heart-off:before {
  content: "󰝙";
}

.mdi-heart-off-outline:before {
  content: "󱐴";
}

.mdi-heart-outline:before {
  content: "󰋕";
}

.mdi-heart-plus:before {
  content: "󱐮";
}

.mdi-heart-plus-outline:before {
  content: "󱐱";
}

.mdi-heart-pulse:before {
  content: "󰗶";
}

.mdi-heart-remove:before {
  content: "󱐰";
}

.mdi-heart-remove-outline:before {
  content: "󱐳";
}

.mdi-heart-settings:before {
  content: "󱙥";
}

.mdi-heart-settings-outline:before {
  content: "󱙦";
}

.mdi-heat-pump:before {
  content: "󱩃";
}

.mdi-heat-pump-outline:before {
  content: "󱩄";
}

.mdi-heat-wave:before {
  content: "󱩅";
}

.mdi-heating-coil:before {
  content: "󱪯";
}

.mdi-helicopter:before {
  content: "󰫂";
}

.mdi-help:before {
  content: "󰋖";
}

.mdi-help-box:before {
  content: "󰞋";
}

.mdi-help-box-multiple:before {
  content: "󱰊";
}

.mdi-help-box-multiple-outline:before {
  content: "󱰋";
}

.mdi-help-box-outline:before {
  content: "󱰌";
}

.mdi-help-circle:before {
  content: "󰋗";
}

.mdi-help-circle-outline:before {
  content: "󰘥";
}

.mdi-help-network:before {
  content: "󰛵";
}

.mdi-help-network-outline:before {
  content: "󰲊";
}

.mdi-help-rhombus:before {
  content: "󰮥";
}

.mdi-help-rhombus-outline:before {
  content: "󰮦";
}

.mdi-hexadecimal:before {
  content: "󱊧";
}

.mdi-hexagon:before {
  content: "󰋘";
}

.mdi-hexagon-multiple:before {
  content: "󰛡";
}

.mdi-hexagon-multiple-outline:before {
  content: "󱃲";
}

.mdi-hexagon-outline:before {
  content: "󰋙";
}

.mdi-hexagon-slice-1:before {
  content: "󰫃";
}

.mdi-hexagon-slice-2:before {
  content: "󰫄";
}

.mdi-hexagon-slice-3:before {
  content: "󰫅";
}

.mdi-hexagon-slice-4:before {
  content: "󰫆";
}

.mdi-hexagon-slice-5:before {
  content: "󰫇";
}

.mdi-hexagon-slice-6:before {
  content: "󰫈";
}

.mdi-hexagram:before {
  content: "󰫉";
}

.mdi-hexagram-outline:before {
  content: "󰫊";
}

.mdi-high-definition:before {
  content: "󰟏";
}

.mdi-high-definition-box:before {
  content: "󰡸";
}

.mdi-highway:before {
  content: "󰗷";
}

.mdi-hiking:before {
  content: "󰵿";
}

.mdi-history:before {
  content: "󰋚";
}

.mdi-hockey-puck:before {
  content: "󰡹";
}

.mdi-hockey-sticks:before {
  content: "󰡺";
}

.mdi-hololens:before {
  content: "󰋛";
}

.mdi-home:before {
  content: "󰋜";
}

.mdi-home-account:before {
  content: "󰠦";
}

.mdi-home-alert:before {
  content: "󰡻";
}

.mdi-home-alert-outline:before {
  content: "󱗐";
}

.mdi-home-analytics:before {
  content: "󰺺";
}

.mdi-home-assistant:before {
  content: "󰟐";
}

.mdi-home-automation:before {
  content: "󰟑";
}

.mdi-home-battery:before {
  content: "󱤁";
}

.mdi-home-battery-outline:before {
  content: "󱤂";
}

.mdi-home-circle:before {
  content: "󰟒";
}

.mdi-home-circle-outline:before {
  content: "󱁍";
}

.mdi-home-city:before {
  content: "󰴕";
}

.mdi-home-city-outline:before {
  content: "󰴖";
}

.mdi-home-clock:before {
  content: "󱨒";
}

.mdi-home-clock-outline:before {
  content: "󱨓";
}

.mdi-home-edit:before {
  content: "󱅙";
}

.mdi-home-edit-outline:before {
  content: "󱅚";
}

.mdi-home-export-outline:before {
  content: "󰾛";
}

.mdi-home-flood:before {
  content: "󰻺";
}

.mdi-home-floor-0:before {
  content: "󰷒";
}

.mdi-home-floor-1:before {
  content: "󰶀";
}

.mdi-home-floor-2:before {
  content: "󰶁";
}

.mdi-home-floor-3:before {
  content: "󰶂";
}

.mdi-home-floor-a:before {
  content: "󰶃";
}

.mdi-home-floor-b:before {
  content: "󰶄";
}

.mdi-home-floor-g:before {
  content: "󰶅";
}

.mdi-home-floor-l:before {
  content: "󰶆";
}

.mdi-home-floor-negative-1:before {
  content: "󰷓";
}

.mdi-home-group:before {
  content: "󰷔";
}

.mdi-home-group-minus:before {
  content: "󱧁";
}

.mdi-home-group-plus:before {
  content: "󱧀";
}

.mdi-home-group-remove:before {
  content: "󱧂";
}

.mdi-home-heart:before {
  content: "󰠧";
}

.mdi-home-import-outline:before {
  content: "󰾜";
}

.mdi-home-lightbulb:before {
  content: "󱉑";
}

.mdi-home-lightbulb-outline:before {
  content: "󱉒";
}

.mdi-home-lightning-bolt:before {
  content: "󱤃";
}

.mdi-home-lightning-bolt-outline:before {
  content: "󱤄";
}

.mdi-home-lock:before {
  content: "󰣫";
}

.mdi-home-lock-open:before {
  content: "󰣬";
}

.mdi-home-map-marker:before {
  content: "󰗸";
}

.mdi-home-minus:before {
  content: "󰥴";
}

.mdi-home-minus-outline:before {
  content: "󱏕";
}

.mdi-home-modern:before {
  content: "󰋝";
}

.mdi-home-off:before {
  content: "󱩆";
}

.mdi-home-off-outline:before {
  content: "󱩇";
}

.mdi-home-outline:before {
  content: "󰚡";
}

.mdi-home-percent:before {
  content: "󱱼";
}

.mdi-home-percent-outline:before {
  content: "󱱽";
}

.mdi-home-plus:before {
  content: "󰥵";
}

.mdi-home-plus-outline:before {
  content: "󱏖";
}

.mdi-home-remove:before {
  content: "󱉇";
}

.mdi-home-remove-outline:before {
  content: "󱏗";
}

.mdi-home-roof:before {
  content: "󱄫";
}

.mdi-home-search:before {
  content: "󱎰";
}

.mdi-home-search-outline:before {
  content: "󱎱";
}

.mdi-home-silo:before {
  content: "󱮠";
}

.mdi-home-silo-outline:before {
  content: "󱮡";
}

.mdi-home-sound-in:before {
  content: "󱰯";
}

.mdi-home-sound-in-outline:before {
  content: "󱰰";
}

.mdi-home-sound-out:before {
  content: "󱰱";
}

.mdi-home-sound-out-outline:before {
  content: "󱰲";
}

.mdi-home-switch:before {
  content: "󱞔";
}

.mdi-home-switch-outline:before {
  content: "󱞕";
}

.mdi-home-thermometer:before {
  content: "󰽔";
}

.mdi-home-thermometer-outline:before {
  content: "󰽕";
}

.mdi-home-variant:before {
  content: "󰋞";
}

.mdi-home-variant-outline:before {
  content: "󰮧";
}

.mdi-hook:before {
  content: "󰛢";
}

.mdi-hook-off:before {
  content: "󰛣";
}

.mdi-hoop-house:before {
  content: "󰹖";
}

.mdi-hops:before {
  content: "󰋟";
}

.mdi-horizontal-rotate-clockwise:before {
  content: "󱃳";
}

.mdi-horizontal-rotate-counterclockwise:before {
  content: "󱃴";
}

.mdi-horse:before {
  content: "󱖿";
}

.mdi-horse-human:before {
  content: "󱗀";
}

.mdi-horse-variant:before {
  content: "󱗁";
}

.mdi-horse-variant-fast:before {
  content: "󱡮";
}

.mdi-horseshoe:before {
  content: "󰩘";
}

.mdi-hospital:before {
  content: "󰿶";
}

.mdi-hospital-box:before {
  content: "󰋠";
}

.mdi-hospital-box-outline:before {
  content: "󰿷";
}

.mdi-hospital-building:before {
  content: "󰋡";
}

.mdi-hospital-marker:before {
  content: "󰋢";
}

.mdi-hot-tub:before {
  content: "󰠨";
}

.mdi-hours-24:before {
  content: "󱑸";
}

.mdi-hubspot:before {
  content: "󰴗";
}

.mdi-hulu:before {
  content: "󰠩";
}

.mdi-human:before {
  content: "󰋦";
}

.mdi-human-baby-changing-table:before {
  content: "󱎋";
}

.mdi-human-cane:before {
  content: "󱖁";
}

.mdi-human-capacity-decrease:before {
  content: "󱖛";
}

.mdi-human-capacity-increase:before {
  content: "󱖜";
}

.mdi-human-child:before {
  content: "󰋧";
}

.mdi-human-dolly:before {
  content: "󱦀";
}

.mdi-human-edit:before {
  content: "󱓨";
}

.mdi-human-female:before {
  content: "󰙉";
}

.mdi-human-female-boy:before {
  content: "󰩙";
}

.mdi-human-female-dance:before {
  content: "󱗉";
}

.mdi-human-female-female:before {
  content: "󰩚";
}

.mdi-human-female-girl:before {
  content: "󰩛";
}

.mdi-human-greeting:before {
  content: "󱟄";
}

.mdi-human-greeting-proximity:before {
  content: "󱖝";
}

.mdi-human-greeting-variant:before {
  content: "󰙊";
}

.mdi-human-handsdown:before {
  content: "󰙋";
}

.mdi-human-handsup:before {
  content: "󰙌";
}

.mdi-human-male:before {
  content: "󰙍";
}

.mdi-human-male-board:before {
  content: "󰢐";
}

.mdi-human-male-board-poll:before {
  content: "󰡆";
}

.mdi-human-male-boy:before {
  content: "󰩜";
}

.mdi-human-male-child:before {
  content: "󱎌";
}

.mdi-human-male-female:before {
  content: "󰋨";
}

.mdi-human-male-female-child:before {
  content: "󱠣";
}

.mdi-human-male-girl:before {
  content: "󰩝";
}

.mdi-human-male-height:before {
  content: "󰻻";
}

.mdi-human-male-height-variant:before {
  content: "󰻼";
}

.mdi-human-male-male:before {
  content: "󰩞";
}

.mdi-human-non-binary:before {
  content: "󱡈";
}

.mdi-human-pregnant:before {
  content: "󰗏";
}

.mdi-human-queue:before {
  content: "󱕱";
}

.mdi-human-scooter:before {
  content: "󱇩";
}

.mdi-human-walker:before {
  content: "󱭱";
}

.mdi-human-wheelchair:before {
  content: "󱎍";
}

.mdi-human-white-cane:before {
  content: "󱦁";
}

.mdi-humble-bundle:before {
  content: "󰝄";
}

.mdi-hvac:before {
  content: "󱍒";
}

.mdi-hvac-off:before {
  content: "󱖞";
}

.mdi-hydraulic-oil-level:before {
  content: "󱌤";
}

.mdi-hydraulic-oil-temperature:before {
  content: "󱌥";
}

.mdi-hydro-power:before {
  content: "󱋥";
}

.mdi-hydrogen-station:before {
  content: "󱢔";
}

.mdi-ice-cream:before {
  content: "󰠪";
}

.mdi-ice-cream-off:before {
  content: "󰹒";
}

.mdi-ice-pop:before {
  content: "󰻽";
}

.mdi-id-card:before {
  content: "󰿀";
}

.mdi-identifier:before {
  content: "󰻾";
}

.mdi-ideogram-cjk:before {
  content: "󱌱";
}

.mdi-ideogram-cjk-variant:before {
  content: "󱌲";
}

.mdi-image:before {
  content: "󰋩";
}

.mdi-image-album:before {
  content: "󰋪";
}

.mdi-image-area:before {
  content: "󰋫";
}

.mdi-image-area-close:before {
  content: "󰋬";
}

.mdi-image-auto-adjust:before {
  content: "󰿁";
}

.mdi-image-broken:before {
  content: "󰋭";
}

.mdi-image-broken-variant:before {
  content: "󰋮";
}

.mdi-image-check:before {
  content: "󱬥";
}

.mdi-image-check-outline:before {
  content: "󱬦";
}

.mdi-image-edit:before {
  content: "󱇣";
}

.mdi-image-edit-outline:before {
  content: "󱇤";
}

.mdi-image-filter-black-white:before {
  content: "󰋰";
}

.mdi-image-filter-center-focus:before {
  content: "󰋱";
}

.mdi-image-filter-center-focus-strong:before {
  content: "󰻿";
}

.mdi-image-filter-center-focus-strong-outline:before {
  content: "󰼀";
}

.mdi-image-filter-center-focus-weak:before {
  content: "󰋲";
}

.mdi-image-filter-drama:before {
  content: "󰋳";
}

.mdi-image-filter-drama-outline:before {
  content: "󱯿";
}

.mdi-image-filter-frames:before {
  content: "󰋴";
}

.mdi-image-filter-hdr:before {
  content: "󰋵";
}

.mdi-image-filter-hdr-outline:before {
  content: "󱱤";
}

.mdi-image-filter-none:before {
  content: "󰋶";
}

.mdi-image-filter-tilt-shift:before {
  content: "󰋷";
}

.mdi-image-filter-vintage:before {
  content: "󰋸";
}

.mdi-image-frame:before {
  content: "󰹉";
}

.mdi-image-lock:before {
  content: "󱪰";
}

.mdi-image-lock-outline:before {
  content: "󱪱";
}

.mdi-image-marker:before {
  content: "󱝻";
}

.mdi-image-marker-outline:before {
  content: "󱝼";
}

.mdi-image-minus:before {
  content: "󱐙";
}

.mdi-image-minus-outline:before {
  content: "󱭇";
}

.mdi-image-move:before {
  content: "󰧸";
}

.mdi-image-multiple:before {
  content: "󰋹";
}

.mdi-image-multiple-outline:before {
  content: "󰋯";
}

.mdi-image-off:before {
  content: "󰠫";
}

.mdi-image-off-outline:before {
  content: "󱇑";
}

.mdi-image-outline:before {
  content: "󰥶";
}

.mdi-image-plus:before {
  content: "󰡼";
}

.mdi-image-plus-outline:before {
  content: "󱭆";
}

.mdi-image-refresh:before {
  content: "󱧾";
}

.mdi-image-refresh-outline:before {
  content: "󱧿";
}

.mdi-image-remove:before {
  content: "󱐘";
}

.mdi-image-remove-outline:before {
  content: "󱭈";
}

.mdi-image-search:before {
  content: "󰥷";
}

.mdi-image-search-outline:before {
  content: "󰥸";
}

.mdi-image-size-select-actual:before {
  content: "󰲍";
}

.mdi-image-size-select-large:before {
  content: "󰲎";
}

.mdi-image-size-select-small:before {
  content: "󰲏";
}

.mdi-image-sync:before {
  content: "󱨀";
}

.mdi-image-sync-outline:before {
  content: "󱨁";
}

.mdi-image-text:before {
  content: "󱘍";
}

.mdi-import:before {
  content: "󰋺";
}

.mdi-inbox:before {
  content: "󰚇";
}

.mdi-inbox-arrow-down:before {
  content: "󰋻";
}

.mdi-inbox-arrow-down-outline:before {
  content: "󱉰";
}

.mdi-inbox-arrow-up:before {
  content: "󰏑";
}

.mdi-inbox-arrow-up-outline:before {
  content: "󱉱";
}

.mdi-inbox-full:before {
  content: "󱉲";
}

.mdi-inbox-full-outline:before {
  content: "󱉳";
}

.mdi-inbox-multiple:before {
  content: "󰢰";
}

.mdi-inbox-multiple-outline:before {
  content: "󰮨";
}

.mdi-inbox-outline:before {
  content: "󱉴";
}

.mdi-inbox-remove:before {
  content: "󱖟";
}

.mdi-inbox-remove-outline:before {
  content: "󱖠";
}

.mdi-incognito:before {
  content: "󰗹";
}

.mdi-incognito-circle:before {
  content: "󱐡";
}

.mdi-incognito-circle-off:before {
  content: "󱐢";
}

.mdi-incognito-off:before {
  content: "󰁵";
}

.mdi-induction:before {
  content: "󱡌";
}

.mdi-infinity:before {
  content: "󰛤";
}

.mdi-information:before {
  content: "󰋼";
}

.mdi-information-box:before {
  content: "󱱥";
}

.mdi-information-box-outline:before {
  content: "󱱦";
}

.mdi-information-off:before {
  content: "󱞌";
}

.mdi-information-off-outline:before {
  content: "󱞍";
}

.mdi-information-outline:before {
  content: "󰋽";
}

.mdi-information-slab-box:before {
  content: "󱱧";
}

.mdi-information-slab-box-outline:before {
  content: "󱱨";
}

.mdi-information-slab-circle:before {
  content: "󱱩";
}

.mdi-information-slab-circle-outline:before {
  content: "󱱪";
}

.mdi-information-slab-symbol:before {
  content: "󱱫";
}

.mdi-information-symbol:before {
  content: "󱱬";
}

.mdi-information-variant:before {
  content: "󰙎";
}

.mdi-information-variant-box:before {
  content: "󱱭";
}

.mdi-information-variant-box-outline:before {
  content: "󱱮";
}

.mdi-information-variant-circle:before {
  content: "󱱯";
}

.mdi-information-variant-circle-outline:before {
  content: "󱱰";
}

.mdi-instagram:before {
  content: "󰋾";
}

.mdi-instrument-triangle:before {
  content: "󱁎";
}

.mdi-integrated-circuit-chip:before {
  content: "󱤓";
}

.mdi-invert-colors:before {
  content: "󰌁";
}

.mdi-invert-colors-off:before {
  content: "󰹊";
}

.mdi-iobroker:before {
  content: "󱋨";
}

.mdi-ip:before {
  content: "󰩟";
}

.mdi-ip-network:before {
  content: "󰩠";
}

.mdi-ip-network-outline:before {
  content: "󰲐";
}

.mdi-ip-outline:before {
  content: "󱦂";
}

.mdi-ipod:before {
  content: "󰲑";
}

.mdi-iron:before {
  content: "󱠤";
}

.mdi-iron-board:before {
  content: "󱠸";
}

.mdi-iron-outline:before {
  content: "󱠥";
}

.mdi-island:before {
  content: "󱁏";
}

.mdi-iv-bag:before {
  content: "󱂹";
}

.mdi-jabber:before {
  content: "󰷕";
}

.mdi-jeepney:before {
  content: "󰌂";
}

.mdi-jellyfish:before {
  content: "󰼁";
}

.mdi-jellyfish-outline:before {
  content: "󰼂";
}

.mdi-jira:before {
  content: "󰌃";
}

.mdi-jquery:before {
  content: "󰡽";
}

.mdi-jsfiddle:before {
  content: "󰌄";
}

.mdi-jump-rope:before {
  content: "󱋿";
}

.mdi-kabaddi:before {
  content: "󰶇";
}

.mdi-kangaroo:before {
  content: "󱕘";
}

.mdi-karate:before {
  content: "󰠬";
}

.mdi-kayaking:before {
  content: "󰢯";
}

.mdi-keg:before {
  content: "󰌅";
}

.mdi-kettle:before {
  content: "󰗺";
}

.mdi-kettle-alert:before {
  content: "󱌗";
}

.mdi-kettle-alert-outline:before {
  content: "󱌘";
}

.mdi-kettle-off:before {
  content: "󱌛";
}

.mdi-kettle-off-outline:before {
  content: "󱌜";
}

.mdi-kettle-outline:before {
  content: "󰽖";
}

.mdi-kettle-pour-over:before {
  content: "󱜼";
}

.mdi-kettle-steam:before {
  content: "󱌙";
}

.mdi-kettle-steam-outline:before {
  content: "󱌚";
}

.mdi-kettlebell:before {
  content: "󱌀";
}

.mdi-key:before {
  content: "󰌆";
}

.mdi-key-alert:before {
  content: "󱦃";
}

.mdi-key-alert-outline:before {
  content: "󱦄";
}

.mdi-key-arrow-right:before {
  content: "󱌒";
}

.mdi-key-chain:before {
  content: "󱕴";
}

.mdi-key-chain-variant:before {
  content: "󱕵";
}

.mdi-key-change:before {
  content: "󰌇";
}

.mdi-key-link:before {
  content: "󱆟";
}

.mdi-key-minus:before {
  content: "󰌈";
}

.mdi-key-outline:before {
  content: "󰷖";
}

.mdi-key-plus:before {
  content: "󰌉";
}

.mdi-key-remove:before {
  content: "󰌊";
}

.mdi-key-star:before {
  content: "󱆞";
}

.mdi-key-variant:before {
  content: "󰌋";
}

.mdi-key-wireless:before {
  content: "󰿂";
}

.mdi-keyboard:before {
  content: "󰌌";
}

.mdi-keyboard-backspace:before {
  content: "󰌍";
}

.mdi-keyboard-caps:before {
  content: "󰌎";
}

.mdi-keyboard-close:before {
  content: "󰌏";
}

.mdi-keyboard-close-outline:before {
  content: "󱰀";
}

.mdi-keyboard-esc:before {
  content: "󱊷";
}

.mdi-keyboard-f1:before {
  content: "󱊫";
}

.mdi-keyboard-f10:before {
  content: "󱊴";
}

.mdi-keyboard-f11:before {
  content: "󱊵";
}

.mdi-keyboard-f12:before {
  content: "󱊶";
}

.mdi-keyboard-f2:before {
  content: "󱊬";
}

.mdi-keyboard-f3:before {
  content: "󱊭";
}

.mdi-keyboard-f4:before {
  content: "󱊮";
}

.mdi-keyboard-f5:before {
  content: "󱊯";
}

.mdi-keyboard-f6:before {
  content: "󱊰";
}

.mdi-keyboard-f7:before {
  content: "󱊱";
}

.mdi-keyboard-f8:before {
  content: "󱊲";
}

.mdi-keyboard-f9:before {
  content: "󱊳";
}

.mdi-keyboard-off:before {
  content: "󰌐";
}

.mdi-keyboard-off-outline:before {
  content: "󰹋";
}

.mdi-keyboard-outline:before {
  content: "󰥻";
}

.mdi-keyboard-return:before {
  content: "󰌑";
}

.mdi-keyboard-settings:before {
  content: "󰧹";
}

.mdi-keyboard-settings-outline:before {
  content: "󰧺";
}

.mdi-keyboard-space:before {
  content: "󱁐";
}

.mdi-keyboard-tab:before {
  content: "󰌒";
}

.mdi-keyboard-tab-reverse:before {
  content: "󰌥";
}

.mdi-keyboard-variant:before {
  content: "󰌓";
}

.mdi-khanda:before {
  content: "󱃽";
}

.mdi-kickstarter:before {
  content: "󰝅";
}

.mdi-kite:before {
  content: "󱦅";
}

.mdi-kite-outline:before {
  content: "󱦆";
}

.mdi-kitesurfing:before {
  content: "󱝄";
}

.mdi-klingon:before {
  content: "󱍛";
}

.mdi-knife:before {
  content: "󰧻";
}

.mdi-knife-military:before {
  content: "󰧼";
}

.mdi-knob:before {
  content: "󱮖";
}

.mdi-koala:before {
  content: "󱜿";
}

.mdi-kodi:before {
  content: "󰌔";
}

.mdi-kubernetes:before {
  content: "󱃾";
}

.mdi-label:before {
  content: "󰌕";
}

.mdi-label-multiple:before {
  content: "󱍵";
}

.mdi-label-multiple-outline:before {
  content: "󱍶";
}

.mdi-label-off:before {
  content: "󰫋";
}

.mdi-label-off-outline:before {
  content: "󰫌";
}

.mdi-label-outline:before {
  content: "󰌖";
}

.mdi-label-percent:before {
  content: "󱋪";
}

.mdi-label-percent-outline:before {
  content: "󱋫";
}

.mdi-label-variant:before {
  content: "󰫍";
}

.mdi-label-variant-outline:before {
  content: "󰫎";
}

.mdi-ladder:before {
  content: "󱖢";
}

.mdi-ladybug:before {
  content: "󰠭";
}

.mdi-lambda:before {
  content: "󰘧";
}

.mdi-lamp:before {
  content: "󰚵";
}

.mdi-lamp-outline:before {
  content: "󱟐";
}

.mdi-lamps:before {
  content: "󱕶";
}

.mdi-lamps-outline:before {
  content: "󱟑";
}

.mdi-lan:before {
  content: "󰌗";
}

.mdi-lan-check:before {
  content: "󱊪";
}

.mdi-lan-connect:before {
  content: "󰌘";
}

.mdi-lan-disconnect:before {
  content: "󰌙";
}

.mdi-lan-pending:before {
  content: "󰌚";
}

.mdi-land-fields:before {
  content: "󱪲";
}

.mdi-land-plots:before {
  content: "󱪳";
}

.mdi-land-plots-circle:before {
  content: "󱪴";
}

.mdi-land-plots-circle-variant:before {
  content: "󱪵";
}

.mdi-land-plots-marker:before {
  content: "󱱝";
}

.mdi-land-rows-horizontal:before {
  content: "󱪶";
}

.mdi-land-rows-vertical:before {
  content: "󱪷";
}

.mdi-landslide:before {
  content: "󱩈";
}

.mdi-landslide-outline:before {
  content: "󱩉";
}

.mdi-language-c:before {
  content: "󰙱";
}

.mdi-language-cpp:before {
  content: "󰙲";
}

.mdi-language-csharp:before {
  content: "󰌛";
}

.mdi-language-css3:before {
  content: "󰌜";
}

.mdi-language-fortran:before {
  content: "󱈚";
}

.mdi-language-go:before {
  content: "󰟓";
}

.mdi-language-haskell:before {
  content: "󰲒";
}

.mdi-language-html5:before {
  content: "󰌝";
}

.mdi-language-java:before {
  content: "󰬷";
}

.mdi-language-javascript:before {
  content: "󰌞";
}

.mdi-language-kotlin:before {
  content: "󱈙";
}

.mdi-language-lua:before {
  content: "󰢱";
}

.mdi-language-markdown:before {
  content: "󰍔";
}

.mdi-language-markdown-outline:before {
  content: "󰽛";
}

.mdi-language-php:before {
  content: "󰌟";
}

.mdi-language-python:before {
  content: "󰌠";
}

.mdi-language-r:before {
  content: "󰟔";
}

.mdi-language-ruby:before {
  content: "󰴭";
}

.mdi-language-ruby-on-rails:before {
  content: "󰫏";
}

.mdi-language-rust:before {
  content: "󱘗";
}

.mdi-language-swift:before {
  content: "󰛥";
}

.mdi-language-typescript:before {
  content: "󰛦";
}

.mdi-language-xaml:before {
  content: "󰙳";
}

.mdi-laptop:before {
  content: "󰌢";
}

.mdi-laptop-account:before {
  content: "󱩊";
}

.mdi-laptop-off:before {
  content: "󰛧";
}

.mdi-laravel:before {
  content: "󰫐";
}

.mdi-laser-pointer:before {
  content: "󱒄";
}

.mdi-lasso:before {
  content: "󰼃";
}

.mdi-lastpass:before {
  content: "󰑆";
}

.mdi-latitude:before {
  content: "󰽗";
}

.mdi-launch:before {
  content: "󰌧";
}

.mdi-lava-lamp:before {
  content: "󰟕";
}

.mdi-layers:before {
  content: "󰌨";
}

.mdi-layers-edit:before {
  content: "󱢒";
}

.mdi-layers-minus:before {
  content: "󰹌";
}

.mdi-layers-off:before {
  content: "󰌩";
}

.mdi-layers-off-outline:before {
  content: "󰧽";
}

.mdi-layers-outline:before {
  content: "󰧾";
}

.mdi-layers-plus:before {
  content: "󰹍";
}

.mdi-layers-remove:before {
  content: "󰹎";
}

.mdi-layers-search:before {
  content: "󱈆";
}

.mdi-layers-search-outline:before {
  content: "󱈇";
}

.mdi-layers-triple:before {
  content: "󰽘";
}

.mdi-layers-triple-outline:before {
  content: "󰽙";
}

.mdi-lead-pencil:before {
  content: "󰙏";
}

.mdi-leaf:before {
  content: "󰌪";
}

.mdi-leaf-circle:before {
  content: "󱤅";
}

.mdi-leaf-circle-outline:before {
  content: "󱤆";
}

.mdi-leaf-maple:before {
  content: "󰲓";
}

.mdi-leaf-maple-off:before {
  content: "󱋚";
}

.mdi-leaf-off:before {
  content: "󱋙";
}

.mdi-leak:before {
  content: "󰷗";
}

.mdi-leak-off:before {
  content: "󰷘";
}

.mdi-lectern:before {
  content: "󱫰";
}

.mdi-led-off:before {
  content: "󰌫";
}

.mdi-led-on:before {
  content: "󰌬";
}

.mdi-led-outline:before {
  content: "󰌭";
}

.mdi-led-strip:before {
  content: "󰟖";
}

.mdi-led-strip-variant:before {
  content: "󱁑";
}

.mdi-led-strip-variant-off:before {
  content: "󱩋";
}

.mdi-led-variant-off:before {
  content: "󰌮";
}

.mdi-led-variant-on:before {
  content: "󰌯";
}

.mdi-led-variant-outline:before {
  content: "󰌰";
}

.mdi-leek:before {
  content: "󱅽";
}

.mdi-less-than:before {
  content: "󰥼";
}

.mdi-less-than-or-equal:before {
  content: "󰥽";
}

.mdi-library:before {
  content: "󰌱";
}

.mdi-library-outline:before {
  content: "󱨢";
}

.mdi-library-shelves:before {
  content: "󰮩";
}

.mdi-license:before {
  content: "󰿃";
}

.mdi-lifebuoy:before {
  content: "󰡾";
}

.mdi-light-flood-down:before {
  content: "󱦇";
}

.mdi-light-flood-up:before {
  content: "󱦈";
}

.mdi-light-recessed:before {
  content: "󱞛";
}

.mdi-light-switch:before {
  content: "󰥾";
}

.mdi-light-switch-off:before {
  content: "󱨤";
}

.mdi-lightbulb:before {
  content: "󰌵";
}

.mdi-lightbulb-alert:before {
  content: "󱧡";
}

.mdi-lightbulb-alert-outline:before {
  content: "󱧢";
}

.mdi-lightbulb-auto:before {
  content: "󱠀";
}

.mdi-lightbulb-auto-outline:before {
  content: "󱠁";
}

.mdi-lightbulb-cfl:before {
  content: "󱈈";
}

.mdi-lightbulb-cfl-off:before {
  content: "󱈉";
}

.mdi-lightbulb-cfl-spiral:before {
  content: "󱉵";
}

.mdi-lightbulb-cfl-spiral-off:before {
  content: "󱋃";
}

.mdi-lightbulb-fluorescent-tube:before {
  content: "󱠄";
}

.mdi-lightbulb-fluorescent-tube-outline:before {
  content: "󱠅";
}

.mdi-lightbulb-group:before {
  content: "󱉓";
}

.mdi-lightbulb-group-off:before {
  content: "󱋍";
}

.mdi-lightbulb-group-off-outline:before {
  content: "󱋎";
}

.mdi-lightbulb-group-outline:before {
  content: "󱉔";
}

.mdi-lightbulb-multiple:before {
  content: "󱉕";
}

.mdi-lightbulb-multiple-off:before {
  content: "󱋏";
}

.mdi-lightbulb-multiple-off-outline:before {
  content: "󱋐";
}

.mdi-lightbulb-multiple-outline:before {
  content: "󱉖";
}

.mdi-lightbulb-night:before {
  content: "󱩌";
}

.mdi-lightbulb-night-outline:before {
  content: "󱩍";
}

.mdi-lightbulb-off:before {
  content: "󰹏";
}

.mdi-lightbulb-off-outline:before {
  content: "󰹐";
}

.mdi-lightbulb-on:before {
  content: "󰛨";
}

.mdi-lightbulb-on-10:before {
  content: "󱩎";
}

.mdi-lightbulb-on-20:before {
  content: "󱩏";
}

.mdi-lightbulb-on-30:before {
  content: "󱩐";
}

.mdi-lightbulb-on-40:before {
  content: "󱩑";
}

.mdi-lightbulb-on-50:before {
  content: "󱩒";
}

.mdi-lightbulb-on-60:before {
  content: "󱩓";
}

.mdi-lightbulb-on-70:before {
  content: "󱩔";
}

.mdi-lightbulb-on-80:before {
  content: "󱩕";
}

.mdi-lightbulb-on-90:before {
  content: "󱩖";
}

.mdi-lightbulb-on-outline:before {
  content: "󰛩";
}

.mdi-lightbulb-outline:before {
  content: "󰌶";
}

.mdi-lightbulb-question:before {
  content: "󱧣";
}

.mdi-lightbulb-question-outline:before {
  content: "󱧤";
}

.mdi-lightbulb-spot:before {
  content: "󱟴";
}

.mdi-lightbulb-spot-off:before {
  content: "󱟵";
}

.mdi-lightbulb-variant:before {
  content: "󱠂";
}

.mdi-lightbulb-variant-outline:before {
  content: "󱠃";
}

.mdi-lighthouse:before {
  content: "󰧿";
}

.mdi-lighthouse-on:before {
  content: "󰨀";
}

.mdi-lightning-bolt:before {
  content: "󱐋";
}

.mdi-lightning-bolt-circle:before {
  content: "󰠠";
}

.mdi-lightning-bolt-outline:before {
  content: "󱐌";
}

.mdi-line-scan:before {
  content: "󰘤";
}

.mdi-lingerie:before {
  content: "󱑶";
}

.mdi-link:before {
  content: "󰌷";
}

.mdi-link-box:before {
  content: "󰴚";
}

.mdi-link-box-outline:before {
  content: "󰴛";
}

.mdi-link-box-variant:before {
  content: "󰴜";
}

.mdi-link-box-variant-outline:before {
  content: "󰴝";
}

.mdi-link-lock:before {
  content: "󱂺";
}

.mdi-link-off:before {
  content: "󰌸";
}

.mdi-link-plus:before {
  content: "󰲔";
}

.mdi-link-variant:before {
  content: "󰌹";
}

.mdi-link-variant-minus:before {
  content: "󱃿";
}

.mdi-link-variant-off:before {
  content: "󰌺";
}

.mdi-link-variant-plus:before {
  content: "󱄀";
}

.mdi-link-variant-remove:before {
  content: "󱄁";
}

.mdi-linkedin:before {
  content: "󰌻";
}

.mdi-linux:before {
  content: "󰌽";
}

.mdi-linux-mint:before {
  content: "󰣭";
}

.mdi-lipstick:before {
  content: "󱎵";
}

.mdi-liquid-spot:before {
  content: "󱠦";
}

.mdi-liquor:before {
  content: "󱤞";
}

.mdi-list-box:before {
  content: "󱭻";
}

.mdi-list-box-outline:before {
  content: "󱭼";
}

.mdi-list-status:before {
  content: "󱖫";
}

.mdi-litecoin:before {
  content: "󰩡";
}

.mdi-loading:before {
  content: "󰝲";
}

.mdi-location-enter:before {
  content: "󰿄";
}

.mdi-location-exit:before {
  content: "󰿅";
}

.mdi-lock:before {
  content: "󰌾";
}

.mdi-lock-alert:before {
  content: "󰣮";
}

.mdi-lock-alert-outline:before {
  content: "󱗑";
}

.mdi-lock-check:before {
  content: "󱎚";
}

.mdi-lock-check-outline:before {
  content: "󱚨";
}

.mdi-lock-clock:before {
  content: "󰥿";
}

.mdi-lock-minus:before {
  content: "󱚩";
}

.mdi-lock-minus-outline:before {
  content: "󱚪";
}

.mdi-lock-off:before {
  content: "󱙱";
}

.mdi-lock-off-outline:before {
  content: "󱙲";
}

.mdi-lock-open:before {
  content: "󰌿";
}

.mdi-lock-open-alert:before {
  content: "󱎛";
}

.mdi-lock-open-alert-outline:before {
  content: "󱗒";
}

.mdi-lock-open-check:before {
  content: "󱎜";
}

.mdi-lock-open-check-outline:before {
  content: "󱚫";
}

.mdi-lock-open-minus:before {
  content: "󱚬";
}

.mdi-lock-open-minus-outline:before {
  content: "󱚭";
}

.mdi-lock-open-outline:before {
  content: "󰍀";
}

.mdi-lock-open-plus:before {
  content: "󱚮";
}

.mdi-lock-open-plus-outline:before {
  content: "󱚯";
}

.mdi-lock-open-remove:before {
  content: "󱚰";
}

.mdi-lock-open-remove-outline:before {
  content: "󱚱";
}

.mdi-lock-open-variant:before {
  content: "󰿆";
}

.mdi-lock-open-variant-outline:before {
  content: "󰿇";
}

.mdi-lock-outline:before {
  content: "󰍁";
}

.mdi-lock-pattern:before {
  content: "󰛪";
}

.mdi-lock-percent:before {
  content: "󱰒";
}

.mdi-lock-percent-open:before {
  content: "󱰓";
}

.mdi-lock-percent-open-outline:before {
  content: "󱰔";
}

.mdi-lock-percent-open-variant:before {
  content: "󱰕";
}

.mdi-lock-percent-open-variant-outline:before {
  content: "󱰖";
}

.mdi-lock-percent-outline:before {
  content: "󱰗";
}

.mdi-lock-plus:before {
  content: "󰗻";
}

.mdi-lock-plus-outline:before {
  content: "󱚲";
}

.mdi-lock-question:before {
  content: "󰣯";
}

.mdi-lock-remove:before {
  content: "󱚳";
}

.mdi-lock-remove-outline:before {
  content: "󱚴";
}

.mdi-lock-reset:before {
  content: "󰝳";
}

.mdi-lock-smart:before {
  content: "󰢲";
}

.mdi-locker:before {
  content: "󰟗";
}

.mdi-locker-multiple:before {
  content: "󰟘";
}

.mdi-login:before {
  content: "󰍂";
}

.mdi-login-variant:before {
  content: "󰗼";
}

.mdi-logout:before {
  content: "󰍃";
}

.mdi-logout-variant:before {
  content: "󰗽";
}

.mdi-longitude:before {
  content: "󰽚";
}

.mdi-looks:before {
  content: "󰍄";
}

.mdi-lotion:before {
  content: "󱖂";
}

.mdi-lotion-outline:before {
  content: "󱖃";
}

.mdi-lotion-plus:before {
  content: "󱖄";
}

.mdi-lotion-plus-outline:before {
  content: "󱖅";
}

.mdi-loupe:before {
  content: "󰍅";
}

.mdi-lumx:before {
  content: "󰍆";
}

.mdi-lungs:before {
  content: "󱂄";
}

.mdi-mace:before {
  content: "󱡃";
}

.mdi-magazine-pistol:before {
  content: "󰌤";
}

.mdi-magazine-rifle:before {
  content: "󰌣";
}

.mdi-magic-staff:before {
  content: "󱡄";
}

.mdi-magnet:before {
  content: "󰍇";
}

.mdi-magnet-on:before {
  content: "󰍈";
}

.mdi-magnify:before {
  content: "󰍉";
}

.mdi-magnify-close:before {
  content: "󰦀";
}

.mdi-magnify-expand:before {
  content: "󱡴";
}

.mdi-magnify-minus:before {
  content: "󰍊";
}

.mdi-magnify-minus-cursor:before {
  content: "󰩢";
}

.mdi-magnify-minus-outline:before {
  content: "󰛬";
}

.mdi-magnify-plus:before {
  content: "󰍋";
}

.mdi-magnify-plus-cursor:before {
  content: "󰩣";
}

.mdi-magnify-plus-outline:before {
  content: "󰛭";
}

.mdi-magnify-remove-cursor:before {
  content: "󱈌";
}

.mdi-magnify-remove-outline:before {
  content: "󱈍";
}

.mdi-magnify-scan:before {
  content: "󱉶";
}

.mdi-mail:before {
  content: "󰺻";
}

.mdi-mailbox:before {
  content: "󰛮";
}

.mdi-mailbox-open:before {
  content: "󰶈";
}

.mdi-mailbox-open-outline:before {
  content: "󰶉";
}

.mdi-mailbox-open-up:before {
  content: "󰶊";
}

.mdi-mailbox-open-up-outline:before {
  content: "󰶋";
}

.mdi-mailbox-outline:before {
  content: "󰶌";
}

.mdi-mailbox-up:before {
  content: "󰶍";
}

.mdi-mailbox-up-outline:before {
  content: "󰶎";
}

.mdi-manjaro:before {
  content: "󱘊";
}

.mdi-map:before {
  content: "󰍍";
}

.mdi-map-check:before {
  content: "󰺼";
}

.mdi-map-check-outline:before {
  content: "󰺽";
}

.mdi-map-clock:before {
  content: "󰴞";
}

.mdi-map-clock-outline:before {
  content: "󰴟";
}

.mdi-map-legend:before {
  content: "󰨁";
}

.mdi-map-marker:before {
  content: "󰍎";
}

.mdi-map-marker-account:before {
  content: "󱣣";
}

.mdi-map-marker-account-outline:before {
  content: "󱣤";
}

.mdi-map-marker-alert:before {
  content: "󰼅";
}

.mdi-map-marker-alert-outline:before {
  content: "󰼆";
}

.mdi-map-marker-check:before {
  content: "󰲕";
}

.mdi-map-marker-check-outline:before {
  content: "󱋻";
}

.mdi-map-marker-circle:before {
  content: "󰍏";
}

.mdi-map-marker-distance:before {
  content: "󰣰";
}

.mdi-map-marker-down:before {
  content: "󱄂";
}

.mdi-map-marker-left:before {
  content: "󱋛";
}

.mdi-map-marker-left-outline:before {
  content: "󱋝";
}

.mdi-map-marker-minus:before {
  content: "󰙐";
}

.mdi-map-marker-minus-outline:before {
  content: "󱋹";
}

.mdi-map-marker-multiple:before {
  content: "󰍐";
}

.mdi-map-marker-multiple-outline:before {
  content: "󱉷";
}

.mdi-map-marker-off:before {
  content: "󰍑";
}

.mdi-map-marker-off-outline:before {
  content: "󱋽";
}

.mdi-map-marker-outline:before {
  content: "󰟙";
}

.mdi-map-marker-path:before {
  content: "󰴠";
}

.mdi-map-marker-plus:before {
  content: "󰙑";
}

.mdi-map-marker-plus-outline:before {
  content: "󱋸";
}

.mdi-map-marker-question:before {
  content: "󰼇";
}

.mdi-map-marker-question-outline:before {
  content: "󰼈";
}

.mdi-map-marker-radius:before {
  content: "󰍒";
}

.mdi-map-marker-radius-outline:before {
  content: "󱋼";
}

.mdi-map-marker-remove:before {
  content: "󰼉";
}

.mdi-map-marker-remove-outline:before {
  content: "󱋺";
}

.mdi-map-marker-remove-variant:before {
  content: "󰼊";
}

.mdi-map-marker-right:before {
  content: "󱋜";
}

.mdi-map-marker-right-outline:before {
  content: "󱋞";
}

.mdi-map-marker-star:before {
  content: "󱘈";
}

.mdi-map-marker-star-outline:before {
  content: "󱘉";
}

.mdi-map-marker-up:before {
  content: "󱄃";
}

.mdi-map-minus:before {
  content: "󰦁";
}

.mdi-map-outline:before {
  content: "󰦂";
}

.mdi-map-plus:before {
  content: "󰦃";
}

.mdi-map-search:before {
  content: "󰦄";
}

.mdi-map-search-outline:before {
  content: "󰦅";
}

.mdi-mapbox:before {
  content: "󰮪";
}

.mdi-margin:before {
  content: "󰍓";
}

.mdi-marker:before {
  content: "󰙒";
}

.mdi-marker-cancel:before {
  content: "󰷙";
}

.mdi-marker-check:before {
  content: "󰍕";
}

.mdi-mastodon:before {
  content: "󰫑";
}

.mdi-material-design:before {
  content: "󰦆";
}

.mdi-material-ui:before {
  content: "󰍗";
}

.mdi-math-compass:before {
  content: "󰍘";
}

.mdi-math-cos:before {
  content: "󰲖";
}

.mdi-math-integral:before {
  content: "󰿈";
}

.mdi-math-integral-box:before {
  content: "󰿉";
}

.mdi-math-log:before {
  content: "󱂅";
}

.mdi-math-norm:before {
  content: "󰿊";
}

.mdi-math-norm-box:before {
  content: "󰿋";
}

.mdi-math-sin:before {
  content: "󰲗";
}

.mdi-math-tan:before {
  content: "󰲘";
}

.mdi-matrix:before {
  content: "󰘨";
}

.mdi-medal:before {
  content: "󰦇";
}

.mdi-medal-outline:before {
  content: "󱌦";
}

.mdi-medical-bag:before {
  content: "󰛯";
}

.mdi-medical-cotton-swab:before {
  content: "󱪸";
}

.mdi-medication:before {
  content: "󱬔";
}

.mdi-medication-outline:before {
  content: "󱬕";
}

.mdi-meditation:before {
  content: "󱅻";
}

.mdi-memory:before {
  content: "󰍛";
}

.mdi-menorah:before {
  content: "󱟔";
}

.mdi-menorah-fire:before {
  content: "󱟕";
}

.mdi-menu:before {
  content: "󰍜";
}

.mdi-menu-down:before {
  content: "󰍝";
}

.mdi-menu-down-outline:before {
  content: "󰚶";
}

.mdi-menu-left:before {
  content: "󰍞";
}

.mdi-menu-left-outline:before {
  content: "󰨂";
}

.mdi-menu-open:before {
  content: "󰮫";
}

.mdi-menu-right:before {
  content: "󰍟";
}

.mdi-menu-right-outline:before {
  content: "󰨃";
}

.mdi-menu-swap:before {
  content: "󰩤";
}

.mdi-menu-swap-outline:before {
  content: "󰩥";
}

.mdi-menu-up:before {
  content: "󰍠";
}

.mdi-menu-up-outline:before {
  content: "󰚷";
}

.mdi-merge:before {
  content: "󰽜";
}

.mdi-message:before {
  content: "󰍡";
}

.mdi-message-alert:before {
  content: "󰍢";
}

.mdi-message-alert-outline:before {
  content: "󰨄";
}

.mdi-message-arrow-left:before {
  content: "󱋲";
}

.mdi-message-arrow-left-outline:before {
  content: "󱋳";
}

.mdi-message-arrow-right:before {
  content: "󱋴";
}

.mdi-message-arrow-right-outline:before {
  content: "󱋵";
}

.mdi-message-badge:before {
  content: "󱥁";
}

.mdi-message-badge-outline:before {
  content: "󱥂";
}

.mdi-message-bookmark:before {
  content: "󱖬";
}

.mdi-message-bookmark-outline:before {
  content: "󱖭";
}

.mdi-message-bulleted:before {
  content: "󰚢";
}

.mdi-message-bulleted-off:before {
  content: "󰚣";
}

.mdi-message-check:before {
  content: "󱮊";
}

.mdi-message-check-outline:before {
  content: "󱮋";
}

.mdi-message-cog:before {
  content: "󰛱";
}

.mdi-message-cog-outline:before {
  content: "󱅲";
}

.mdi-message-draw:before {
  content: "󰍣";
}

.mdi-message-fast:before {
  content: "󱧌";
}

.mdi-message-fast-outline:before {
  content: "󱧍";
}

.mdi-message-flash:before {
  content: "󱖩";
}

.mdi-message-flash-outline:before {
  content: "󱖪";
}

.mdi-message-image:before {
  content: "󰍤";
}

.mdi-message-image-outline:before {
  content: "󱅬";
}

.mdi-message-lock:before {
  content: "󰿌";
}

.mdi-message-lock-outline:before {
  content: "󱅭";
}

.mdi-message-minus:before {
  content: "󱅮";
}

.mdi-message-minus-outline:before {
  content: "󱅯";
}

.mdi-message-off:before {
  content: "󱙍";
}

.mdi-message-off-outline:before {
  content: "󱙎";
}

.mdi-message-outline:before {
  content: "󰍥";
}

.mdi-message-plus:before {
  content: "󰙓";
}

.mdi-message-plus-outline:before {
  content: "󱂻";
}

.mdi-message-processing:before {
  content: "󰍦";
}

.mdi-message-processing-outline:before {
  content: "󱅰";
}

.mdi-message-question:before {
  content: "󱜺";
}

.mdi-message-question-outline:before {
  content: "󱜻";
}

.mdi-message-reply:before {
  content: "󰍧";
}

.mdi-message-reply-outline:before {
  content: "󱜽";
}

.mdi-message-reply-text:before {
  content: "󰍨";
}

.mdi-message-reply-text-outline:before {
  content: "󱜾";
}

.mdi-message-settings:before {
  content: "󰛰";
}

.mdi-message-settings-outline:before {
  content: "󱅱";
}

.mdi-message-star:before {
  content: "󰚚";
}

.mdi-message-star-outline:before {
  content: "󱉐";
}

.mdi-message-text:before {
  content: "󰍩";
}

.mdi-message-text-clock:before {
  content: "󱅳";
}

.mdi-message-text-clock-outline:before {
  content: "󱅴";
}

.mdi-message-text-fast:before {
  content: "󱧎";
}

.mdi-message-text-fast-outline:before {
  content: "󱧏";
}

.mdi-message-text-lock:before {
  content: "󰿍";
}

.mdi-message-text-lock-outline:before {
  content: "󱅵";
}

.mdi-message-text-outline:before {
  content: "󰍪";
}

.mdi-message-video:before {
  content: "󰍫";
}

.mdi-meteor:before {
  content: "󰘩";
}

.mdi-meter-electric:before {
  content: "󱩗";
}

.mdi-meter-electric-outline:before {
  content: "󱩘";
}

.mdi-meter-gas:before {
  content: "󱩙";
}

.mdi-meter-gas-outline:before {
  content: "󱩚";
}

.mdi-metronome:before {
  content: "󰟚";
}

.mdi-metronome-tick:before {
  content: "󰟛";
}

.mdi-micro-sd:before {
  content: "󰟜";
}

.mdi-microphone:before {
  content: "󰍬";
}

.mdi-microphone-message:before {
  content: "󰔊";
}

.mdi-microphone-message-off:before {
  content: "󰔋";
}

.mdi-microphone-minus:before {
  content: "󰢳";
}

.mdi-microphone-off:before {
  content: "󰍭";
}

.mdi-microphone-outline:before {
  content: "󰍮";
}

.mdi-microphone-plus:before {
  content: "󰢴";
}

.mdi-microphone-question:before {
  content: "󱦉";
}

.mdi-microphone-question-outline:before {
  content: "󱦊";
}

.mdi-microphone-settings:before {
  content: "󰍯";
}

.mdi-microphone-variant:before {
  content: "󰍰";
}

.mdi-microphone-variant-off:before {
  content: "󰍱";
}

.mdi-microscope:before {
  content: "󰙔";
}

.mdi-microsoft:before {
  content: "󰍲";
}

.mdi-microsoft-access:before {
  content: "󱎎";
}

.mdi-microsoft-azure:before {
  content: "󰠅";
}

.mdi-microsoft-azure-devops:before {
  content: "󰿕";
}

.mdi-microsoft-bing:before {
  content: "󰂤";
}

.mdi-microsoft-dynamics-365:before {
  content: "󰦈";
}

.mdi-microsoft-edge:before {
  content: "󰇩";
}

.mdi-microsoft-excel:before {
  content: "󱎏";
}

.mdi-microsoft-internet-explorer:before {
  content: "󰌀";
}

.mdi-microsoft-office:before {
  content: "󰏆";
}

.mdi-microsoft-onedrive:before {
  content: "󰏊";
}

.mdi-microsoft-onenote:before {
  content: "󰝇";
}

.mdi-microsoft-outlook:before {
  content: "󰴢";
}

.mdi-microsoft-powerpoint:before {
  content: "󱎐";
}

.mdi-microsoft-sharepoint:before {
  content: "󱎑";
}

.mdi-microsoft-teams:before {
  content: "󰊻";
}

.mdi-microsoft-visual-studio:before {
  content: "󰘐";
}

.mdi-microsoft-visual-studio-code:before {
  content: "󰨞";
}

.mdi-microsoft-windows:before {
  content: "󰖳";
}

.mdi-microsoft-windows-classic:before {
  content: "󰨡";
}

.mdi-microsoft-word:before {
  content: "󱎒";
}

.mdi-microsoft-xbox:before {
  content: "󰖹";
}

.mdi-microsoft-xbox-controller:before {
  content: "󰖺";
}

.mdi-microsoft-xbox-controller-battery-alert:before {
  content: "󰝋";
}

.mdi-microsoft-xbox-controller-battery-charging:before {
  content: "󰨢";
}

.mdi-microsoft-xbox-controller-battery-empty:before {
  content: "󰝌";
}

.mdi-microsoft-xbox-controller-battery-full:before {
  content: "󰝍";
}

.mdi-microsoft-xbox-controller-battery-low:before {
  content: "󰝎";
}

.mdi-microsoft-xbox-controller-battery-medium:before {
  content: "󰝏";
}

.mdi-microsoft-xbox-controller-battery-unknown:before {
  content: "󰝐";
}

.mdi-microsoft-xbox-controller-menu:before {
  content: "󰹯";
}

.mdi-microsoft-xbox-controller-off:before {
  content: "󰖻";
}

.mdi-microsoft-xbox-controller-view:before {
  content: "󰹰";
}

.mdi-microwave:before {
  content: "󰲙";
}

.mdi-microwave-off:before {
  content: "󱐣";
}

.mdi-middleware:before {
  content: "󰽝";
}

.mdi-middleware-outline:before {
  content: "󰽞";
}

.mdi-midi:before {
  content: "󰣱";
}

.mdi-midi-port:before {
  content: "󰣲";
}

.mdi-mine:before {
  content: "󰷚";
}

.mdi-minecraft:before {
  content: "󰍳";
}

.mdi-mini-sd:before {
  content: "󰨅";
}

.mdi-minidisc:before {
  content: "󰨆";
}

.mdi-minus:before {
  content: "󰍴";
}

.mdi-minus-box:before {
  content: "󰍵";
}

.mdi-minus-box-multiple:before {
  content: "󱅁";
}

.mdi-minus-box-multiple-outline:before {
  content: "󱅂";
}

.mdi-minus-box-outline:before {
  content: "󰛲";
}

.mdi-minus-circle:before {
  content: "󰍶";
}

.mdi-minus-circle-multiple:before {
  content: "󰍚";
}

.mdi-minus-circle-multiple-outline:before {
  content: "󰫓";
}

.mdi-minus-circle-off:before {
  content: "󱑙";
}

.mdi-minus-circle-off-outline:before {
  content: "󱑚";
}

.mdi-minus-circle-outline:before {
  content: "󰍷";
}

.mdi-minus-network:before {
  content: "󰍸";
}

.mdi-minus-network-outline:before {
  content: "󰲚";
}

.mdi-minus-thick:before {
  content: "󱘹";
}

.mdi-mirror:before {
  content: "󱇽";
}

.mdi-mirror-rectangle:before {
  content: "󱞟";
}

.mdi-mirror-variant:before {
  content: "󱞠";
}

.mdi-mixed-martial-arts:before {
  content: "󰶏";
}

.mdi-mixed-reality:before {
  content: "󰡿";
}

.mdi-molecule:before {
  content: "󰮬";
}

.mdi-molecule-co:before {
  content: "󱋾";
}

.mdi-molecule-co2:before {
  content: "󰟤";
}

.mdi-monitor:before {
  content: "󰍹";
}

.mdi-monitor-account:before {
  content: "󱩛";
}

.mdi-monitor-arrow-down:before {
  content: "󱧐";
}

.mdi-monitor-arrow-down-variant:before {
  content: "󱧑";
}

.mdi-monitor-cellphone:before {
  content: "󰦉";
}

.mdi-monitor-cellphone-star:before {
  content: "󰦊";
}

.mdi-monitor-dashboard:before {
  content: "󰨇";
}

.mdi-monitor-edit:before {
  content: "󱋆";
}

.mdi-monitor-eye:before {
  content: "󱎴";
}

.mdi-monitor-lock:before {
  content: "󰷛";
}

.mdi-monitor-multiple:before {
  content: "󰍺";
}

.mdi-monitor-off:before {
  content: "󰶐";
}

.mdi-monitor-screenshot:before {
  content: "󰹑";
}

.mdi-monitor-share:before {
  content: "󱒃";
}

.mdi-monitor-shimmer:before {
  content: "󱄄";
}

.mdi-monitor-small:before {
  content: "󱡶";
}

.mdi-monitor-speaker:before {
  content: "󰽟";
}

.mdi-monitor-speaker-off:before {
  content: "󰽠";
}

.mdi-monitor-star:before {
  content: "󰷜";
}

.mdi-monitor-vertical:before {
  content: "󱰳";
}

.mdi-moon-first-quarter:before {
  content: "󰽡";
}

.mdi-moon-full:before {
  content: "󰽢";
}

.mdi-moon-last-quarter:before {
  content: "󰽣";
}

.mdi-moon-new:before {
  content: "󰽤";
}

.mdi-moon-waning-crescent:before {
  content: "󰽥";
}

.mdi-moon-waning-gibbous:before {
  content: "󰽦";
}

.mdi-moon-waxing-crescent:before {
  content: "󰽧";
}

.mdi-moon-waxing-gibbous:before {
  content: "󰽨";
}

.mdi-moped:before {
  content: "󱂆";
}

.mdi-moped-electric:before {
  content: "󱖷";
}

.mdi-moped-electric-outline:before {
  content: "󱖸";
}

.mdi-moped-outline:before {
  content: "󱖹";
}

.mdi-more:before {
  content: "󰍻";
}

.mdi-mortar-pestle:before {
  content: "󱝈";
}

.mdi-mortar-pestle-plus:before {
  content: "󰏱";
}

.mdi-mosque:before {
  content: "󰵅";
}

.mdi-mosque-outline:before {
  content: "󱠧";
}

.mdi-mother-heart:before {
  content: "󱌔";
}

.mdi-mother-nurse:before {
  content: "󰴡";
}

.mdi-motion:before {
  content: "󱖲";
}

.mdi-motion-outline:before {
  content: "󱖳";
}

.mdi-motion-pause:before {
  content: "󱖐";
}

.mdi-motion-pause-outline:before {
  content: "󱖒";
}

.mdi-motion-play:before {
  content: "󱖏";
}

.mdi-motion-play-outline:before {
  content: "󱖑";
}

.mdi-motion-sensor:before {
  content: "󰶑";
}

.mdi-motion-sensor-off:before {
  content: "󱐵";
}

.mdi-motorbike:before {
  content: "󰍼";
}

.mdi-motorbike-electric:before {
  content: "󱖺";
}

.mdi-motorbike-off:before {
  content: "󱬖";
}

.mdi-mouse:before {
  content: "󰍽";
}

.mdi-mouse-bluetooth:before {
  content: "󰦋";
}

.mdi-mouse-move-down:before {
  content: "󱕐";
}

.mdi-mouse-move-up:before {
  content: "󱕑";
}

.mdi-mouse-move-vertical:before {
  content: "󱕒";
}

.mdi-mouse-off:before {
  content: "󰍾";
}

.mdi-mouse-variant:before {
  content: "󰍿";
}

.mdi-mouse-variant-off:before {
  content: "󰎀";
}

.mdi-move-resize:before {
  content: "󰙕";
}

.mdi-move-resize-variant:before {
  content: "󰙖";
}

.mdi-movie:before {
  content: "󰎁";
}

.mdi-movie-check:before {
  content: "󱛳";
}

.mdi-movie-check-outline:before {
  content: "󱛴";
}

.mdi-movie-cog:before {
  content: "󱛵";
}

.mdi-movie-cog-outline:before {
  content: "󱛶";
}

.mdi-movie-edit:before {
  content: "󱄢";
}

.mdi-movie-edit-outline:before {
  content: "󱄣";
}

.mdi-movie-filter:before {
  content: "󱄤";
}

.mdi-movie-filter-outline:before {
  content: "󱄥";
}

.mdi-movie-minus:before {
  content: "󱛷";
}

.mdi-movie-minus-outline:before {
  content: "󱛸";
}

.mdi-movie-off:before {
  content: "󱛹";
}

.mdi-movie-off-outline:before {
  content: "󱛺";
}

.mdi-movie-open:before {
  content: "󰿎";
}

.mdi-movie-open-check:before {
  content: "󱛻";
}

.mdi-movie-open-check-outline:before {
  content: "󱛼";
}

.mdi-movie-open-cog:before {
  content: "󱛽";
}

.mdi-movie-open-cog-outline:before {
  content: "󱛾";
}

.mdi-movie-open-edit:before {
  content: "󱛿";
}

.mdi-movie-open-edit-outline:before {
  content: "󱜀";
}

.mdi-movie-open-minus:before {
  content: "󱜁";
}

.mdi-movie-open-minus-outline:before {
  content: "󱜂";
}

.mdi-movie-open-off:before {
  content: "󱜃";
}

.mdi-movie-open-off-outline:before {
  content: "󱜄";
}

.mdi-movie-open-outline:before {
  content: "󰿏";
}

.mdi-movie-open-play:before {
  content: "󱜅";
}

.mdi-movie-open-play-outline:before {
  content: "󱜆";
}

.mdi-movie-open-plus:before {
  content: "󱜇";
}

.mdi-movie-open-plus-outline:before {
  content: "󱜈";
}

.mdi-movie-open-remove:before {
  content: "󱜉";
}

.mdi-movie-open-remove-outline:before {
  content: "󱜊";
}

.mdi-movie-open-settings:before {
  content: "󱜋";
}

.mdi-movie-open-settings-outline:before {
  content: "󱜌";
}

.mdi-movie-open-star:before {
  content: "󱜍";
}

.mdi-movie-open-star-outline:before {
  content: "󱜎";
}

.mdi-movie-outline:before {
  content: "󰷝";
}

.mdi-movie-play:before {
  content: "󱜏";
}

.mdi-movie-play-outline:before {
  content: "󱜐";
}

.mdi-movie-plus:before {
  content: "󱜑";
}

.mdi-movie-plus-outline:before {
  content: "󱜒";
}

.mdi-movie-remove:before {
  content: "󱜓";
}

.mdi-movie-remove-outline:before {
  content: "󱜔";
}

.mdi-movie-roll:before {
  content: "󰟞";
}

.mdi-movie-search:before {
  content: "󱇒";
}

.mdi-movie-search-outline:before {
  content: "󱇓";
}

.mdi-movie-settings:before {
  content: "󱜕";
}

.mdi-movie-settings-outline:before {
  content: "󱜖";
}

.mdi-movie-star:before {
  content: "󱜗";
}

.mdi-movie-star-outline:before {
  content: "󱜘";
}

.mdi-mower:before {
  content: "󱙯";
}

.mdi-mower-bag:before {
  content: "󱙰";
}

.mdi-mower-bag-on:before {
  content: "󱭠";
}

.mdi-mower-on:before {
  content: "󱭟";
}

.mdi-muffin:before {
  content: "󰦌";
}

.mdi-multicast:before {
  content: "󱢓";
}

.mdi-multimedia:before {
  content: "󱮗";
}

.mdi-multiplication:before {
  content: "󰎂";
}

.mdi-multiplication-box:before {
  content: "󰎃";
}

.mdi-mushroom:before {
  content: "󰟟";
}

.mdi-mushroom-off:before {
  content: "󱏺";
}

.mdi-mushroom-off-outline:before {
  content: "󱏻";
}

.mdi-mushroom-outline:before {
  content: "󰟠";
}

.mdi-music:before {
  content: "󰝚";
}

.mdi-music-accidental-double-flat:before {
  content: "󰽩";
}

.mdi-music-accidental-double-sharp:before {
  content: "󰽪";
}

.mdi-music-accidental-flat:before {
  content: "󰽫";
}

.mdi-music-accidental-natural:before {
  content: "󰽬";
}

.mdi-music-accidental-sharp:before {
  content: "󰽭";
}

.mdi-music-box:before {
  content: "󰎄";
}

.mdi-music-box-multiple:before {
  content: "󰌳";
}

.mdi-music-box-multiple-outline:before {
  content: "󰼄";
}

.mdi-music-box-outline:before {
  content: "󰎅";
}

.mdi-music-circle:before {
  content: "󰎆";
}

.mdi-music-circle-outline:before {
  content: "󰫔";
}

.mdi-music-clef-alto:before {
  content: "󰽮";
}

.mdi-music-clef-bass:before {
  content: "󰽯";
}

.mdi-music-clef-treble:before {
  content: "󰽰";
}

.mdi-music-note:before {
  content: "󰎇";
}

.mdi-music-note-bluetooth:before {
  content: "󰗾";
}

.mdi-music-note-bluetooth-off:before {
  content: "󰗿";
}

.mdi-music-note-eighth:before {
  content: "󰎈";
}

.mdi-music-note-eighth-dotted:before {
  content: "󰽱";
}

.mdi-music-note-half:before {
  content: "󰎉";
}

.mdi-music-note-half-dotted:before {
  content: "󰽲";
}

.mdi-music-note-minus:before {
  content: "󱮉";
}

.mdi-music-note-off:before {
  content: "󰎊";
}

.mdi-music-note-off-outline:before {
  content: "󰽳";
}

.mdi-music-note-outline:before {
  content: "󰽴";
}

.mdi-music-note-plus:before {
  content: "󰷞";
}

.mdi-music-note-quarter:before {
  content: "󰎋";
}

.mdi-music-note-quarter-dotted:before {
  content: "󰽵";
}

.mdi-music-note-sixteenth:before {
  content: "󰎌";
}

.mdi-music-note-sixteenth-dotted:before {
  content: "󰽶";
}

.mdi-music-note-whole:before {
  content: "󰎍";
}

.mdi-music-note-whole-dotted:before {
  content: "󰽷";
}

.mdi-music-off:before {
  content: "󰝛";
}

.mdi-music-rest-eighth:before {
  content: "󰽸";
}

.mdi-music-rest-half:before {
  content: "󰽹";
}

.mdi-music-rest-quarter:before {
  content: "󰽺";
}

.mdi-music-rest-sixteenth:before {
  content: "󰽻";
}

.mdi-music-rest-whole:before {
  content: "󰽼";
}

.mdi-mustache:before {
  content: "󱗞";
}

.mdi-nail:before {
  content: "󰷟";
}

.mdi-nas:before {
  content: "󰣳";
}

.mdi-nativescript:before {
  content: "󰢀";
}

.mdi-nature:before {
  content: "󰎎";
}

.mdi-nature-outline:before {
  content: "󱱱";
}

.mdi-nature-people:before {
  content: "󰎏";
}

.mdi-nature-people-outline:before {
  content: "󱱲";
}

.mdi-navigation:before {
  content: "󰎐";
}

.mdi-navigation-outline:before {
  content: "󱘇";
}

.mdi-navigation-variant:before {
  content: "󱣰";
}

.mdi-navigation-variant-outline:before {
  content: "󱣱";
}

.mdi-near-me:before {
  content: "󰗍";
}

.mdi-necklace:before {
  content: "󰼋";
}

.mdi-needle:before {
  content: "󰎑";
}

.mdi-needle-off:before {
  content: "󱧒";
}

.mdi-netflix:before {
  content: "󰝆";
}

.mdi-network:before {
  content: "󰛳";
}

.mdi-network-off:before {
  content: "󰲛";
}

.mdi-network-off-outline:before {
  content: "󰲜";
}

.mdi-network-outline:before {
  content: "󰲝";
}

.mdi-network-pos:before {
  content: "󱫋";
}

.mdi-network-strength-1:before {
  content: "󰣴";
}

.mdi-network-strength-1-alert:before {
  content: "󰣵";
}

.mdi-network-strength-2:before {
  content: "󰣶";
}

.mdi-network-strength-2-alert:before {
  content: "󰣷";
}

.mdi-network-strength-3:before {
  content: "󰣸";
}

.mdi-network-strength-3-alert:before {
  content: "󰣹";
}

.mdi-network-strength-4:before {
  content: "󰣺";
}

.mdi-network-strength-4-alert:before {
  content: "󰣻";
}

.mdi-network-strength-4-cog:before {
  content: "󱤚";
}

.mdi-network-strength-off:before {
  content: "󰣼";
}

.mdi-network-strength-off-outline:before {
  content: "󰣽";
}

.mdi-network-strength-outline:before {
  content: "󰣾";
}

.mdi-new-box:before {
  content: "󰎔";
}

.mdi-newspaper:before {
  content: "󰎕";
}

.mdi-newspaper-check:before {
  content: "󱥃";
}

.mdi-newspaper-minus:before {
  content: "󰼌";
}

.mdi-newspaper-plus:before {
  content: "󰼍";
}

.mdi-newspaper-remove:before {
  content: "󱥄";
}

.mdi-newspaper-variant:before {
  content: "󱀁";
}

.mdi-newspaper-variant-multiple:before {
  content: "󱀂";
}

.mdi-newspaper-variant-multiple-outline:before {
  content: "󱀃";
}

.mdi-newspaper-variant-outline:before {
  content: "󱀄";
}

.mdi-nfc:before {
  content: "󰎖";
}

.mdi-nfc-search-variant:before {
  content: "󰹓";
}

.mdi-nfc-tap:before {
  content: "󰎗";
}

.mdi-nfc-variant:before {
  content: "󰎘";
}

.mdi-nfc-variant-off:before {
  content: "󰹔";
}

.mdi-ninja:before {
  content: "󰝴";
}

.mdi-nintendo-game-boy:before {
  content: "󱎓";
}

.mdi-nintendo-switch:before {
  content: "󰟡";
}

.mdi-nintendo-wii:before {
  content: "󰖫";
}

.mdi-nintendo-wiiu:before {
  content: "󰜭";
}

.mdi-nix:before {
  content: "󱄅";
}

.mdi-nodejs:before {
  content: "󰎙";
}

.mdi-noodles:before {
  content: "󱅾";
}

.mdi-not-equal:before {
  content: "󰦍";
}

.mdi-not-equal-variant:before {
  content: "󰦎";
}

.mdi-note:before {
  content: "󰎚";
}

.mdi-note-alert:before {
  content: "󱝽";
}

.mdi-note-alert-outline:before {
  content: "󱝾";
}

.mdi-note-check:before {
  content: "󱝿";
}

.mdi-note-check-outline:before {
  content: "󱞀";
}

.mdi-note-edit:before {
  content: "󱞁";
}

.mdi-note-edit-outline:before {
  content: "󱞂";
}

.mdi-note-minus:before {
  content: "󱙏";
}

.mdi-note-minus-outline:before {
  content: "󱙐";
}

.mdi-note-multiple:before {
  content: "󰚸";
}

.mdi-note-multiple-outline:before {
  content: "󰚹";
}

.mdi-note-off:before {
  content: "󱞃";
}

.mdi-note-off-outline:before {
  content: "󱞄";
}

.mdi-note-outline:before {
  content: "󰎛";
}

.mdi-note-plus:before {
  content: "󰎜";
}

.mdi-note-plus-outline:before {
  content: "󰎝";
}

.mdi-note-remove:before {
  content: "󱙑";
}

.mdi-note-remove-outline:before {
  content: "󱙒";
}

.mdi-note-search:before {
  content: "󱙓";
}

.mdi-note-search-outline:before {
  content: "󱙔";
}

.mdi-note-text:before {
  content: "󰎞";
}

.mdi-note-text-outline:before {
  content: "󱇗";
}

.mdi-notebook:before {
  content: "󰠮";
}

.mdi-notebook-check:before {
  content: "󱓵";
}

.mdi-notebook-check-outline:before {
  content: "󱓶";
}

.mdi-notebook-edit:before {
  content: "󱓧";
}

.mdi-notebook-edit-outline:before {
  content: "󱓩";
}

.mdi-notebook-heart:before {
  content: "󱨋";
}

.mdi-notebook-heart-outline:before {
  content: "󱨌";
}

.mdi-notebook-minus:before {
  content: "󱘐";
}

.mdi-notebook-minus-outline:before {
  content: "󱘑";
}

.mdi-notebook-multiple:before {
  content: "󰹕";
}

.mdi-notebook-outline:before {
  content: "󰺿";
}

.mdi-notebook-plus:before {
  content: "󱘒";
}

.mdi-notebook-plus-outline:before {
  content: "󱘓";
}

.mdi-notebook-remove:before {
  content: "󱘔";
}

.mdi-notebook-remove-outline:before {
  content: "󱘕";
}

.mdi-notification-clear-all:before {
  content: "󰎟";
}

.mdi-npm:before {
  content: "󰛷";
}

.mdi-nuke:before {
  content: "󰚤";
}

.mdi-null:before {
  content: "󰟢";
}

.mdi-numeric:before {
  content: "󰎠";
}

.mdi-numeric-0:before {
  content: "󰬹";
}

.mdi-numeric-0-box:before {
  content: "󰎡";
}

.mdi-numeric-0-box-multiple:before {
  content: "󰼎";
}

.mdi-numeric-0-box-multiple-outline:before {
  content: "󰎢";
}

.mdi-numeric-0-box-outline:before {
  content: "󰎣";
}

.mdi-numeric-0-circle:before {
  content: "󰲞";
}

.mdi-numeric-0-circle-outline:before {
  content: "󰲟";
}

.mdi-numeric-1:before {
  content: "󰬺";
}

.mdi-numeric-1-box:before {
  content: "󰎤";
}

.mdi-numeric-1-box-multiple:before {
  content: "󰼏";
}

.mdi-numeric-1-box-multiple-outline:before {
  content: "󰎥";
}

.mdi-numeric-1-box-outline:before {
  content: "󰎦";
}

.mdi-numeric-1-circle:before {
  content: "󰲠";
}

.mdi-numeric-1-circle-outline:before {
  content: "󰲡";
}

.mdi-numeric-10:before {
  content: "󰿩";
}

.mdi-numeric-10-box:before {
  content: "󰽽";
}

.mdi-numeric-10-box-multiple:before {
  content: "󰿪";
}

.mdi-numeric-10-box-multiple-outline:before {
  content: "󰿫";
}

.mdi-numeric-10-box-outline:before {
  content: "󰽾";
}

.mdi-numeric-10-circle:before {
  content: "󰿬";
}

.mdi-numeric-10-circle-outline:before {
  content: "󰿭";
}

.mdi-numeric-2:before {
  content: "󰬻";
}

.mdi-numeric-2-box:before {
  content: "󰎧";
}

.mdi-numeric-2-box-multiple:before {
  content: "󰼐";
}

.mdi-numeric-2-box-multiple-outline:before {
  content: "󰎨";
}

.mdi-numeric-2-box-outline:before {
  content: "󰎩";
}

.mdi-numeric-2-circle:before {
  content: "󰲢";
}

.mdi-numeric-2-circle-outline:before {
  content: "󰲣";
}

.mdi-numeric-3:before {
  content: "󰬼";
}

.mdi-numeric-3-box:before {
  content: "󰎪";
}

.mdi-numeric-3-box-multiple:before {
  content: "󰼑";
}

.mdi-numeric-3-box-multiple-outline:before {
  content: "󰎫";
}

.mdi-numeric-3-box-outline:before {
  content: "󰎬";
}

.mdi-numeric-3-circle:before {
  content: "󰲤";
}

.mdi-numeric-3-circle-outline:before {
  content: "󰲥";
}

.mdi-numeric-4:before {
  content: "󰬽";
}

.mdi-numeric-4-box:before {
  content: "󰎭";
}

.mdi-numeric-4-box-multiple:before {
  content: "󰼒";
}

.mdi-numeric-4-box-multiple-outline:before {
  content: "󰎲";
}

.mdi-numeric-4-box-outline:before {
  content: "󰎮";
}

.mdi-numeric-4-circle:before {
  content: "󰲦";
}

.mdi-numeric-4-circle-outline:before {
  content: "󰲧";
}

.mdi-numeric-5:before {
  content: "󰬾";
}

.mdi-numeric-5-box:before {
  content: "󰎱";
}

.mdi-numeric-5-box-multiple:before {
  content: "󰼓";
}

.mdi-numeric-5-box-multiple-outline:before {
  content: "󰎯";
}

.mdi-numeric-5-box-outline:before {
  content: "󰎰";
}

.mdi-numeric-5-circle:before {
  content: "󰲨";
}

.mdi-numeric-5-circle-outline:before {
  content: "󰲩";
}

.mdi-numeric-6:before {
  content: "󰬿";
}

.mdi-numeric-6-box:before {
  content: "󰎳";
}

.mdi-numeric-6-box-multiple:before {
  content: "󰼔";
}

.mdi-numeric-6-box-multiple-outline:before {
  content: "󰎴";
}

.mdi-numeric-6-box-outline:before {
  content: "󰎵";
}

.mdi-numeric-6-circle:before {
  content: "󰲪";
}

.mdi-numeric-6-circle-outline:before {
  content: "󰲫";
}

.mdi-numeric-7:before {
  content: "󰭀";
}

.mdi-numeric-7-box:before {
  content: "󰎶";
}

.mdi-numeric-7-box-multiple:before {
  content: "󰼕";
}

.mdi-numeric-7-box-multiple-outline:before {
  content: "󰎷";
}

.mdi-numeric-7-box-outline:before {
  content: "󰎸";
}

.mdi-numeric-7-circle:before {
  content: "󰲬";
}

.mdi-numeric-7-circle-outline:before {
  content: "󰲭";
}

.mdi-numeric-8:before {
  content: "󰭁";
}

.mdi-numeric-8-box:before {
  content: "󰎹";
}

.mdi-numeric-8-box-multiple:before {
  content: "󰼖";
}

.mdi-numeric-8-box-multiple-outline:before {
  content: "󰎺";
}

.mdi-numeric-8-box-outline:before {
  content: "󰎻";
}

.mdi-numeric-8-circle:before {
  content: "󰲮";
}

.mdi-numeric-8-circle-outline:before {
  content: "󰲯";
}

.mdi-numeric-9:before {
  content: "󰭂";
}

.mdi-numeric-9-box:before {
  content: "󰎼";
}

.mdi-numeric-9-box-multiple:before {
  content: "󰼗";
}

.mdi-numeric-9-box-multiple-outline:before {
  content: "󰎽";
}

.mdi-numeric-9-box-outline:before {
  content: "󰎾";
}

.mdi-numeric-9-circle:before {
  content: "󰲰";
}

.mdi-numeric-9-circle-outline:before {
  content: "󰲱";
}

.mdi-numeric-9-plus:before {
  content: "󰿮";
}

.mdi-numeric-9-plus-box:before {
  content: "󰎿";
}

.mdi-numeric-9-plus-box-multiple:before {
  content: "󰼘";
}

.mdi-numeric-9-plus-box-multiple-outline:before {
  content: "󰏀";
}

.mdi-numeric-9-plus-box-outline:before {
  content: "󰏁";
}

.mdi-numeric-9-plus-circle:before {
  content: "󰲲";
}

.mdi-numeric-9-plus-circle-outline:before {
  content: "󰲳";
}

.mdi-numeric-negative-1:before {
  content: "󱁒";
}

.mdi-numeric-off:before {
  content: "󱧓";
}

.mdi-numeric-positive-1:before {
  content: "󱗋";
}

.mdi-nut:before {
  content: "󰛸";
}

.mdi-nutrition:before {
  content: "󰏂";
}

.mdi-nuxt:before {
  content: "󱄆";
}

.mdi-oar:before {
  content: "󰙼";
}

.mdi-ocarina:before {
  content: "󰷠";
}

.mdi-oci:before {
  content: "󱋩";
}

.mdi-ocr:before {
  content: "󱄺";
}

.mdi-octagon:before {
  content: "󰏃";
}

.mdi-octagon-outline:before {
  content: "󰏄";
}

.mdi-octagram:before {
  content: "󰛹";
}

.mdi-octagram-edit:before {
  content: "󱰴";
}

.mdi-octagram-edit-outline:before {
  content: "󱰵";
}

.mdi-octagram-minus:before {
  content: "󱰶";
}

.mdi-octagram-minus-outline:before {
  content: "󱰷";
}

.mdi-octagram-outline:before {
  content: "󰝵";
}

.mdi-octagram-plus:before {
  content: "󱰸";
}

.mdi-octagram-plus-outline:before {
  content: "󱰹";
}

.mdi-octahedron:before {
  content: "󱥐";
}

.mdi-octahedron-off:before {
  content: "󱥑";
}

.mdi-odnoklassniki:before {
  content: "󰏅";
}

.mdi-offer:before {
  content: "󱈛";
}

.mdi-office-building:before {
  content: "󰦑";
}

.mdi-office-building-cog:before {
  content: "󱥉";
}

.mdi-office-building-cog-outline:before {
  content: "󱥊";
}

.mdi-office-building-marker:before {
  content: "󱔠";
}

.mdi-office-building-marker-outline:before {
  content: "󱔡";
}

.mdi-office-building-minus:before {
  content: "󱮪";
}

.mdi-office-building-minus-outline:before {
  content: "󱮫";
}

.mdi-office-building-outline:before {
  content: "󱔟";
}

.mdi-office-building-plus:before {
  content: "󱮨";
}

.mdi-office-building-plus-outline:before {
  content: "󱮩";
}

.mdi-office-building-remove:before {
  content: "󱮬";
}

.mdi-office-building-remove-outline:before {
  content: "󱮭";
}

.mdi-oil:before {
  content: "󰏇";
}

.mdi-oil-lamp:before {
  content: "󰼙";
}

.mdi-oil-level:before {
  content: "󱁓";
}

.mdi-oil-temperature:before {
  content: "󰿸";
}

.mdi-om:before {
  content: "󰥳";
}

.mdi-omega:before {
  content: "󰏉";
}

.mdi-one-up:before {
  content: "󰮭";
}

.mdi-onepassword:before {
  content: "󰢁";
}

.mdi-opacity:before {
  content: "󰗌";
}

.mdi-open-in-app:before {
  content: "󰏋";
}

.mdi-open-in-new:before {
  content: "󰏌";
}

.mdi-open-source-initiative:before {
  content: "󰮮";
}

.mdi-openid:before {
  content: "󰏍";
}

.mdi-opera:before {
  content: "󰏎";
}

.mdi-orbit:before {
  content: "󰀘";
}

.mdi-orbit-variant:before {
  content: "󱗛";
}

.mdi-order-alphabetical-ascending:before {
  content: "󰈍";
}

.mdi-order-alphabetical-descending:before {
  content: "󰴇";
}

.mdi-order-bool-ascending:before {
  content: "󰊾";
}

.mdi-order-bool-ascending-variant:before {
  content: "󰦏";
}

.mdi-order-bool-descending:before {
  content: "󱎄";
}

.mdi-order-bool-descending-variant:before {
  content: "󰦐";
}

.mdi-order-numeric-ascending:before {
  content: "󰕅";
}

.mdi-order-numeric-descending:before {
  content: "󰕆";
}

.mdi-origin:before {
  content: "󰭃";
}

.mdi-ornament:before {
  content: "󰏏";
}

.mdi-ornament-variant:before {
  content: "󰏐";
}

.mdi-outdoor-lamp:before {
  content: "󱁔";
}

.mdi-overscan:before {
  content: "󱀅";
}

.mdi-owl:before {
  content: "󰏒";
}

.mdi-pac-man:before {
  content: "󰮯";
}

.mdi-package:before {
  content: "󰏓";
}

.mdi-package-check:before {
  content: "󱭑";
}

.mdi-package-down:before {
  content: "󰏔";
}

.mdi-package-up:before {
  content: "󰏕";
}

.mdi-package-variant:before {
  content: "󰏖";
}

.mdi-package-variant-closed:before {
  content: "󰏗";
}

.mdi-package-variant-closed-check:before {
  content: "󱭒";
}

.mdi-package-variant-closed-minus:before {
  content: "󱧔";
}

.mdi-package-variant-closed-plus:before {
  content: "󱧕";
}

.mdi-package-variant-closed-remove:before {
  content: "󱧖";
}

.mdi-package-variant-minus:before {
  content: "󱧗";
}

.mdi-package-variant-plus:before {
  content: "󱧘";
}

.mdi-package-variant-remove:before {
  content: "󱧙";
}

.mdi-page-first:before {
  content: "󰘀";
}

.mdi-page-last:before {
  content: "󰘁";
}

.mdi-page-layout-body:before {
  content: "󰛺";
}

.mdi-page-layout-footer:before {
  content: "󰛻";
}

.mdi-page-layout-header:before {
  content: "󰛼";
}

.mdi-page-layout-header-footer:before {
  content: "󰽿";
}

.mdi-page-layout-sidebar-left:before {
  content: "󰛽";
}

.mdi-page-layout-sidebar-right:before {
  content: "󰛾";
}

.mdi-page-next:before {
  content: "󰮰";
}

.mdi-page-next-outline:before {
  content: "󰮱";
}

.mdi-page-previous:before {
  content: "󰮲";
}

.mdi-page-previous-outline:before {
  content: "󰮳";
}

.mdi-pail:before {
  content: "󱐗";
}

.mdi-pail-minus:before {
  content: "󱐷";
}

.mdi-pail-minus-outline:before {
  content: "󱐼";
}

.mdi-pail-off:before {
  content: "󱐹";
}

.mdi-pail-off-outline:before {
  content: "󱐾";
}

.mdi-pail-outline:before {
  content: "󱐺";
}

.mdi-pail-plus:before {
  content: "󱐶";
}

.mdi-pail-plus-outline:before {
  content: "󱐻";
}

.mdi-pail-remove:before {
  content: "󱐸";
}

.mdi-pail-remove-outline:before {
  content: "󱐽";
}

.mdi-palette:before {
  content: "󰏘";
}

.mdi-palette-advanced:before {
  content: "󰏙";
}

.mdi-palette-outline:before {
  content: "󰸌";
}

.mdi-palette-swatch:before {
  content: "󰢵";
}

.mdi-palette-swatch-outline:before {
  content: "󱍜";
}

.mdi-palette-swatch-variant:before {
  content: "󱥚";
}

.mdi-palm-tree:before {
  content: "󱁕";
}

.mdi-pan:before {
  content: "󰮴";
}

.mdi-pan-bottom-left:before {
  content: "󰮵";
}

.mdi-pan-bottom-right:before {
  content: "󰮶";
}

.mdi-pan-down:before {
  content: "󰮷";
}

.mdi-pan-horizontal:before {
  content: "󰮸";
}

.mdi-pan-left:before {
  content: "󰮹";
}

.mdi-pan-right:before {
  content: "󰮺";
}

.mdi-pan-top-left:before {
  content: "󰮻";
}

.mdi-pan-top-right:before {
  content: "󰮼";
}

.mdi-pan-up:before {
  content: "󰮽";
}

.mdi-pan-vertical:before {
  content: "󰮾";
}

.mdi-panda:before {
  content: "󰏚";
}

.mdi-pandora:before {
  content: "󰏛";
}

.mdi-panorama:before {
  content: "󰏜";
}

.mdi-panorama-fisheye:before {
  content: "󰏝";
}

.mdi-panorama-horizontal:before {
  content: "󱤨";
}

.mdi-panorama-horizontal-outline:before {
  content: "󰏞";
}

.mdi-panorama-outline:before {
  content: "󱦌";
}

.mdi-panorama-sphere:before {
  content: "󱦍";
}

.mdi-panorama-sphere-outline:before {
  content: "󱦎";
}

.mdi-panorama-variant:before {
  content: "󱦏";
}

.mdi-panorama-variant-outline:before {
  content: "󱦐";
}

.mdi-panorama-vertical:before {
  content: "󱤩";
}

.mdi-panorama-vertical-outline:before {
  content: "󰏟";
}

.mdi-panorama-wide-angle:before {
  content: "󱥟";
}

.mdi-panorama-wide-angle-outline:before {
  content: "󰏠";
}

.mdi-paper-cut-vertical:before {
  content: "󰏡";
}

.mdi-paper-roll:before {
  content: "󱅗";
}

.mdi-paper-roll-outline:before {
  content: "󱅘";
}

.mdi-paperclip:before {
  content: "󰏢";
}

.mdi-paperclip-check:before {
  content: "󱫆";
}

.mdi-paperclip-lock:before {
  content: "󱧚";
}

.mdi-paperclip-minus:before {
  content: "󱫇";
}

.mdi-paperclip-off:before {
  content: "󱫈";
}

.mdi-paperclip-plus:before {
  content: "󱫉";
}

.mdi-paperclip-remove:before {
  content: "󱫊";
}

.mdi-parachute:before {
  content: "󰲴";
}

.mdi-parachute-outline:before {
  content: "󰲵";
}

.mdi-paragliding:before {
  content: "󱝅";
}

.mdi-parking:before {
  content: "󰏣";
}

.mdi-party-popper:before {
  content: "󱁖";
}

.mdi-passport:before {
  content: "󰟣";
}

.mdi-passport-biometric:before {
  content: "󰷡";
}

.mdi-pasta:before {
  content: "󱅠";
}

.mdi-patio-heater:before {
  content: "󰾀";
}

.mdi-patreon:before {
  content: "󰢂";
}

.mdi-pause:before {
  content: "󰏤";
}

.mdi-pause-box:before {
  content: "󰂼";
}

.mdi-pause-box-outline:before {
  content: "󱭺";
}

.mdi-pause-circle:before {
  content: "󰏥";
}

.mdi-pause-circle-outline:before {
  content: "󰏦";
}

.mdi-pause-octagon:before {
  content: "󰏧";
}

.mdi-pause-octagon-outline:before {
  content: "󰏨";
}

.mdi-paw:before {
  content: "󰏩";
}

.mdi-paw-off:before {
  content: "󰙗";
}

.mdi-paw-off-outline:before {
  content: "󱙶";
}

.mdi-paw-outline:before {
  content: "󱙵";
}

.mdi-peace:before {
  content: "󰢄";
}

.mdi-peanut:before {
  content: "󰿼";
}

.mdi-peanut-off:before {
  content: "󰿽";
}

.mdi-peanut-off-outline:before {
  content: "󰿿";
}

.mdi-peanut-outline:before {
  content: "󰿾";
}

.mdi-pen:before {
  content: "󰏪";
}

.mdi-pen-lock:before {
  content: "󰷢";
}

.mdi-pen-minus:before {
  content: "󰷣";
}

.mdi-pen-off:before {
  content: "󰷤";
}

.mdi-pen-plus:before {
  content: "󰷥";
}

.mdi-pen-remove:before {
  content: "󰷦";
}

.mdi-pencil:before {
  content: "󰏫";
}

.mdi-pencil-box:before {
  content: "󰏬";
}

.mdi-pencil-box-multiple:before {
  content: "󱅄";
}

.mdi-pencil-box-multiple-outline:before {
  content: "󱅅";
}

.mdi-pencil-box-outline:before {
  content: "󰏭";
}

.mdi-pencil-circle:before {
  content: "󰛿";
}

.mdi-pencil-circle-outline:before {
  content: "󰝶";
}

.mdi-pencil-lock:before {
  content: "󰏮";
}

.mdi-pencil-lock-outline:before {
  content: "󰷧";
}

.mdi-pencil-minus:before {
  content: "󰷨";
}

.mdi-pencil-minus-outline:before {
  content: "󰷩";
}

.mdi-pencil-off:before {
  content: "󰏯";
}

.mdi-pencil-off-outline:before {
  content: "󰷪";
}

.mdi-pencil-outline:before {
  content: "󰲶";
}

.mdi-pencil-plus:before {
  content: "󰷫";
}

.mdi-pencil-plus-outline:before {
  content: "󰷬";
}

.mdi-pencil-remove:before {
  content: "󰷭";
}

.mdi-pencil-remove-outline:before {
  content: "󰷮";
}

.mdi-pencil-ruler:before {
  content: "󱍓";
}

.mdi-pencil-ruler-outline:before {
  content: "󱰑";
}

.mdi-penguin:before {
  content: "󰻀";
}

.mdi-pentagon:before {
  content: "󰜁";
}

.mdi-pentagon-outline:before {
  content: "󰜀";
}

.mdi-pentagram:before {
  content: "󱙧";
}

.mdi-percent:before {
  content: "󰏰";
}

.mdi-percent-box:before {
  content: "󱨂";
}

.mdi-percent-box-outline:before {
  content: "󱨃";
}

.mdi-percent-circle:before {
  content: "󱨄";
}

.mdi-percent-circle-outline:before {
  content: "󱨅";
}

.mdi-percent-outline:before {
  content: "󱉸";
}

.mdi-periodic-table:before {
  content: "󰢶";
}

.mdi-perspective-less:before {
  content: "󰴣";
}

.mdi-perspective-more:before {
  content: "󰴤";
}

.mdi-ph:before {
  content: "󱟅";
}

.mdi-phone:before {
  content: "󰏲";
}

.mdi-phone-alert:before {
  content: "󰼚";
}

.mdi-phone-alert-outline:before {
  content: "󱆎";
}

.mdi-phone-bluetooth:before {
  content: "󰏳";
}

.mdi-phone-bluetooth-outline:before {
  content: "󱆏";
}

.mdi-phone-cancel:before {
  content: "󱂼";
}

.mdi-phone-cancel-outline:before {
  content: "󱆐";
}

.mdi-phone-check:before {
  content: "󱆩";
}

.mdi-phone-check-outline:before {
  content: "󱆪";
}

.mdi-phone-classic:before {
  content: "󰘂";
}

.mdi-phone-classic-off:before {
  content: "󱉹";
}

.mdi-phone-clock:before {
  content: "󱧛";
}

.mdi-phone-dial:before {
  content: "󱕙";
}

.mdi-phone-dial-outline:before {
  content: "󱕚";
}

.mdi-phone-forward:before {
  content: "󰏴";
}

.mdi-phone-forward-outline:before {
  content: "󱆑";
}

.mdi-phone-hangup:before {
  content: "󰏵";
}

.mdi-phone-hangup-outline:before {
  content: "󱆒";
}

.mdi-phone-in-talk:before {
  content: "󰏶";
}

.mdi-phone-in-talk-outline:before {
  content: "󱆂";
}

.mdi-phone-incoming:before {
  content: "󰏷";
}

.mdi-phone-incoming-outgoing:before {
  content: "󱬿";
}

.mdi-phone-incoming-outgoing-outline:before {
  content: "󱭀";
}

.mdi-phone-incoming-outline:before {
  content: "󱆓";
}

.mdi-phone-lock:before {
  content: "󰏸";
}

.mdi-phone-lock-outline:before {
  content: "󱆔";
}

.mdi-phone-log:before {
  content: "󰏹";
}

.mdi-phone-log-outline:before {
  content: "󱆕";
}

.mdi-phone-message:before {
  content: "󱆖";
}

.mdi-phone-message-outline:before {
  content: "󱆗";
}

.mdi-phone-minus:before {
  content: "󰙘";
}

.mdi-phone-minus-outline:before {
  content: "󱆘";
}

.mdi-phone-missed:before {
  content: "󰏺";
}

.mdi-phone-missed-outline:before {
  content: "󱆥";
}

.mdi-phone-off:before {
  content: "󰷯";
}

.mdi-phone-off-outline:before {
  content: "󱆦";
}

.mdi-phone-outgoing:before {
  content: "󰏻";
}

.mdi-phone-outgoing-outline:before {
  content: "󱆙";
}

.mdi-phone-outline:before {
  content: "󰷰";
}

.mdi-phone-paused:before {
  content: "󰏼";
}

.mdi-phone-paused-outline:before {
  content: "󱆚";
}

.mdi-phone-plus:before {
  content: "󰙙";
}

.mdi-phone-plus-outline:before {
  content: "󱆛";
}

.mdi-phone-refresh:before {
  content: "󱦓";
}

.mdi-phone-refresh-outline:before {
  content: "󱦔";
}

.mdi-phone-remove:before {
  content: "󱔯";
}

.mdi-phone-remove-outline:before {
  content: "󱔰";
}

.mdi-phone-return:before {
  content: "󰠯";
}

.mdi-phone-return-outline:before {
  content: "󱆜";
}

.mdi-phone-ring:before {
  content: "󱆫";
}

.mdi-phone-ring-outline:before {
  content: "󱆬";
}

.mdi-phone-rotate-landscape:before {
  content: "󰢅";
}

.mdi-phone-rotate-portrait:before {
  content: "󰢆";
}

.mdi-phone-settings:before {
  content: "󰏽";
}

.mdi-phone-settings-outline:before {
  content: "󱆝";
}

.mdi-phone-sync:before {
  content: "󱦕";
}

.mdi-phone-sync-outline:before {
  content: "󱦖";
}

.mdi-phone-voip:before {
  content: "󰏾";
}

.mdi-pi:before {
  content: "󰏿";
}

.mdi-pi-box:before {
  content: "󰐀";
}

.mdi-pi-hole:before {
  content: "󰷱";
}

.mdi-piano:before {
  content: "󰙽";
}

.mdi-piano-off:before {
  content: "󰚘";
}

.mdi-pickaxe:before {
  content: "󰢷";
}

.mdi-picture-in-picture-bottom-right:before {
  content: "󰹗";
}

.mdi-picture-in-picture-bottom-right-outline:before {
  content: "󰹘";
}

.mdi-picture-in-picture-top-right:before {
  content: "󰹙";
}

.mdi-picture-in-picture-top-right-outline:before {
  content: "󰹚";
}

.mdi-pier:before {
  content: "󰢇";
}

.mdi-pier-crane:before {
  content: "󰢈";
}

.mdi-pig:before {
  content: "󰐁";
}

.mdi-pig-variant:before {
  content: "󱀆";
}

.mdi-pig-variant-outline:before {
  content: "󱙸";
}

.mdi-piggy-bank:before {
  content: "󱀇";
}

.mdi-piggy-bank-outline:before {
  content: "󱙹";
}

.mdi-pill:before {
  content: "󰐂";
}

.mdi-pill-multiple:before {
  content: "󱭌";
}

.mdi-pill-off:before {
  content: "󱩜";
}

.mdi-pillar:before {
  content: "󰜂";
}

.mdi-pin:before {
  content: "󰐃";
}

.mdi-pin-off:before {
  content: "󰐄";
}

.mdi-pin-off-outline:before {
  content: "󰤰";
}

.mdi-pin-outline:before {
  content: "󰤱";
}

.mdi-pine-tree:before {
  content: "󰐅";
}

.mdi-pine-tree-box:before {
  content: "󰐆";
}

.mdi-pine-tree-fire:before {
  content: "󱐚";
}

.mdi-pine-tree-variant:before {
  content: "󱱳";
}

.mdi-pine-tree-variant-outline:before {
  content: "󱱴";
}

.mdi-pinterest:before {
  content: "󰐇";
}

.mdi-pinwheel:before {
  content: "󰫕";
}

.mdi-pinwheel-outline:before {
  content: "󰫖";
}

.mdi-pipe:before {
  content: "󰟥";
}

.mdi-pipe-disconnected:before {
  content: "󰟦";
}

.mdi-pipe-leak:before {
  content: "󰢉";
}

.mdi-pipe-valve:before {
  content: "󱡍";
}

.mdi-pipe-wrench:before {
  content: "󱍔";
}

.mdi-pirate:before {
  content: "󰨈";
}

.mdi-pistol:before {
  content: "󰜃";
}

.mdi-piston:before {
  content: "󰢊";
}

.mdi-pitchfork:before {
  content: "󱕓";
}

.mdi-pizza:before {
  content: "󰐉";
}

.mdi-plane-car:before {
  content: "󱫿";
}

.mdi-plane-train:before {
  content: "󱬀";
}

.mdi-play:before {
  content: "󰐊";
}

.mdi-play-box:before {
  content: "󱉺";
}

.mdi-play-box-edit-outline:before {
  content: "󱰺";
}

.mdi-play-box-lock:before {
  content: "󱨖";
}

.mdi-play-box-lock-open:before {
  content: "󱨗";
}

.mdi-play-box-lock-open-outline:before {
  content: "󱨘";
}

.mdi-play-box-lock-outline:before {
  content: "󱨙";
}

.mdi-play-box-multiple:before {
  content: "󰴙";
}

.mdi-play-box-multiple-outline:before {
  content: "󱏦";
}

.mdi-play-box-outline:before {
  content: "󰐋";
}

.mdi-play-circle:before {
  content: "󰐌";
}

.mdi-play-circle-outline:before {
  content: "󰐍";
}

.mdi-play-network:before {
  content: "󰢋";
}

.mdi-play-network-outline:before {
  content: "󰲷";
}

.mdi-play-outline:before {
  content: "󰼛";
}

.mdi-play-pause:before {
  content: "󰐎";
}

.mdi-play-protected-content:before {
  content: "󰐏";
}

.mdi-play-speed:before {
  content: "󰣿";
}

.mdi-playlist-check:before {
  content: "󰗇";
}

.mdi-playlist-edit:before {
  content: "󰤀";
}

.mdi-playlist-minus:before {
  content: "󰐐";
}

.mdi-playlist-music:before {
  content: "󰲸";
}

.mdi-playlist-music-outline:before {
  content: "󰲹";
}

.mdi-playlist-play:before {
  content: "󰐑";
}

.mdi-playlist-plus:before {
  content: "󰐒";
}

.mdi-playlist-remove:before {
  content: "󰐓";
}

.mdi-playlist-star:before {
  content: "󰷲";
}

.mdi-plex:before {
  content: "󰚺";
}

.mdi-pliers:before {
  content: "󱦤";
}

.mdi-plus:before {
  content: "󰐕";
}

.mdi-plus-box:before {
  content: "󰐖";
}

.mdi-plus-box-multiple:before {
  content: "󰌴";
}

.mdi-plus-box-multiple-outline:before {
  content: "󱅃";
}

.mdi-plus-box-outline:before {
  content: "󰜄";
}

.mdi-plus-circle:before {
  content: "󰐗";
}

.mdi-plus-circle-multiple:before {
  content: "󰍌";
}

.mdi-plus-circle-multiple-outline:before {
  content: "󰐘";
}

.mdi-plus-circle-outline:before {
  content: "󰐙";
}

.mdi-plus-lock:before {
  content: "󱩝";
}

.mdi-plus-lock-open:before {
  content: "󱩞";
}

.mdi-plus-minus:before {
  content: "󰦒";
}

.mdi-plus-minus-box:before {
  content: "󰦓";
}

.mdi-plus-minus-variant:before {
  content: "󱓉";
}

.mdi-plus-network:before {
  content: "󰐚";
}

.mdi-plus-network-outline:before {
  content: "󰲺";
}

.mdi-plus-outline:before {
  content: "󰜅";
}

.mdi-plus-thick:before {
  content: "󱇬";
}

.mdi-podcast:before {
  content: "󰦔";
}

.mdi-podium:before {
  content: "󰴥";
}

.mdi-podium-bronze:before {
  content: "󰴦";
}

.mdi-podium-gold:before {
  content: "󰴧";
}

.mdi-podium-silver:before {
  content: "󰴨";
}

.mdi-point-of-sale:before {
  content: "󰶒";
}

.mdi-pokeball:before {
  content: "󰐝";
}

.mdi-pokemon-go:before {
  content: "󰨉";
}

.mdi-poker-chip:before {
  content: "󰠰";
}

.mdi-polaroid:before {
  content: "󰐞";
}

.mdi-police-badge:before {
  content: "󱅧";
}

.mdi-police-badge-outline:before {
  content: "󱅨";
}

.mdi-police-station:before {
  content: "󱠹";
}

.mdi-poll:before {
  content: "󰐟";
}

.mdi-polo:before {
  content: "󱓃";
}

.mdi-polymer:before {
  content: "󰐡";
}

.mdi-pool:before {
  content: "󰘆";
}

.mdi-pool-thermometer:before {
  content: "󱩟";
}

.mdi-popcorn:before {
  content: "󰐢";
}

.mdi-post:before {
  content: "󱀈";
}

.mdi-post-lamp:before {
  content: "󱩠";
}

.mdi-post-outline:before {
  content: "󱀉";
}

.mdi-postage-stamp:before {
  content: "󰲻";
}

.mdi-pot:before {
  content: "󰋥";
}

.mdi-pot-mix:before {
  content: "󰙛";
}

.mdi-pot-mix-outline:before {
  content: "󰙷";
}

.mdi-pot-outline:before {
  content: "󰋿";
}

.mdi-pot-steam:before {
  content: "󰙚";
}

.mdi-pot-steam-outline:before {
  content: "󰌦";
}

.mdi-pound:before {
  content: "󰐣";
}

.mdi-pound-box:before {
  content: "󰐤";
}

.mdi-pound-box-outline:before {
  content: "󱅿";
}

.mdi-power:before {
  content: "󰐥";
}

.mdi-power-cycle:before {
  content: "󰤁";
}

.mdi-power-off:before {
  content: "󰤂";
}

.mdi-power-on:before {
  content: "󰤃";
}

.mdi-power-plug:before {
  content: "󰚥";
}

.mdi-power-plug-battery:before {
  content: "󱰻";
}

.mdi-power-plug-battery-outline:before {
  content: "󱰼";
}

.mdi-power-plug-off:before {
  content: "󰚦";
}

.mdi-power-plug-off-outline:before {
  content: "󱐤";
}

.mdi-power-plug-outline:before {
  content: "󱐥";
}

.mdi-power-settings:before {
  content: "󰐦";
}

.mdi-power-sleep:before {
  content: "󰤄";
}

.mdi-power-socket:before {
  content: "󰐧";
}

.mdi-power-socket-au:before {
  content: "󰤅";
}

.mdi-power-socket-ch:before {
  content: "󰾳";
}

.mdi-power-socket-de:before {
  content: "󱄇";
}

.mdi-power-socket-eu:before {
  content: "󰟧";
}

.mdi-power-socket-fr:before {
  content: "󱄈";
}

.mdi-power-socket-it:before {
  content: "󱓿";
}

.mdi-power-socket-jp:before {
  content: "󱄉";
}

.mdi-power-socket-uk:before {
  content: "󰟨";
}

.mdi-power-socket-us:before {
  content: "󰟩";
}

.mdi-power-standby:before {
  content: "󰤆";
}

.mdi-powershell:before {
  content: "󰨊";
}

.mdi-prescription:before {
  content: "󰜆";
}

.mdi-presentation:before {
  content: "󰐨";
}

.mdi-presentation-play:before {
  content: "󰐩";
}

.mdi-pretzel:before {
  content: "󱕢";
}

.mdi-printer:before {
  content: "󰐪";
}

.mdi-printer-3d:before {
  content: "󰐫";
}

.mdi-printer-3d-nozzle:before {
  content: "󰹛";
}

.mdi-printer-3d-nozzle-alert:before {
  content: "󱇀";
}

.mdi-printer-3d-nozzle-alert-outline:before {
  content: "󱇁";
}

.mdi-printer-3d-nozzle-heat:before {
  content: "󱢸";
}

.mdi-printer-3d-nozzle-heat-outline:before {
  content: "󱢹";
}

.mdi-printer-3d-nozzle-off:before {
  content: "󱬙";
}

.mdi-printer-3d-nozzle-off-outline:before {
  content: "󱬚";
}

.mdi-printer-3d-nozzle-outline:before {
  content: "󰹜";
}

.mdi-printer-3d-off:before {
  content: "󱬎";
}

.mdi-printer-alert:before {
  content: "󰐬";
}

.mdi-printer-check:before {
  content: "󱅆";
}

.mdi-printer-eye:before {
  content: "󱑘";
}

.mdi-printer-off:before {
  content: "󰹝";
}

.mdi-printer-off-outline:before {
  content: "󱞅";
}

.mdi-printer-outline:before {
  content: "󱞆";
}

.mdi-printer-pos:before {
  content: "󱁗";
}

.mdi-printer-pos-alert:before {
  content: "󱮼";
}

.mdi-printer-pos-alert-outline:before {
  content: "󱮽";
}

.mdi-printer-pos-cancel:before {
  content: "󱮾";
}

.mdi-printer-pos-cancel-outline:before {
  content: "󱮿";
}

.mdi-printer-pos-check:before {
  content: "󱯀";
}

.mdi-printer-pos-check-outline:before {
  content: "󱯁";
}

.mdi-printer-pos-cog:before {
  content: "󱯂";
}

.mdi-printer-pos-cog-outline:before {
  content: "󱯃";
}

.mdi-printer-pos-edit:before {
  content: "󱯄";
}

.mdi-printer-pos-edit-outline:before {
  content: "󱯅";
}

.mdi-printer-pos-minus:before {
  content: "󱯆";
}

.mdi-printer-pos-minus-outline:before {
  content: "󱯇";
}

.mdi-printer-pos-network:before {
  content: "󱯈";
}

.mdi-printer-pos-network-outline:before {
  content: "󱯉";
}

.mdi-printer-pos-off:before {
  content: "󱯊";
}

.mdi-printer-pos-off-outline:before {
  content: "󱯋";
}

.mdi-printer-pos-outline:before {
  content: "󱯌";
}

.mdi-printer-pos-pause:before {
  content: "󱯍";
}

.mdi-printer-pos-pause-outline:before {
  content: "󱯎";
}

.mdi-printer-pos-play:before {
  content: "󱯏";
}

.mdi-printer-pos-play-outline:before {
  content: "󱯐";
}

.mdi-printer-pos-plus:before {
  content: "󱯑";
}

.mdi-printer-pos-plus-outline:before {
  content: "󱯒";
}

.mdi-printer-pos-refresh:before {
  content: "󱯓";
}

.mdi-printer-pos-refresh-outline:before {
  content: "󱯔";
}

.mdi-printer-pos-remove:before {
  content: "󱯕";
}

.mdi-printer-pos-remove-outline:before {
  content: "󱯖";
}

.mdi-printer-pos-star:before {
  content: "󱯗";
}

.mdi-printer-pos-star-outline:before {
  content: "󱯘";
}

.mdi-printer-pos-stop:before {
  content: "󱯙";
}

.mdi-printer-pos-stop-outline:before {
  content: "󱯚";
}

.mdi-printer-pos-sync:before {
  content: "󱯛";
}

.mdi-printer-pos-sync-outline:before {
  content: "󱯜";
}

.mdi-printer-pos-wrench:before {
  content: "󱯝";
}

.mdi-printer-pos-wrench-outline:before {
  content: "󱯞";
}

.mdi-printer-search:before {
  content: "󱑗";
}

.mdi-printer-settings:before {
  content: "󰜇";
}

.mdi-printer-wireless:before {
  content: "󰨋";
}

.mdi-priority-high:before {
  content: "󰘃";
}

.mdi-priority-low:before {
  content: "󰘄";
}

.mdi-professional-hexagon:before {
  content: "󰐭";
}

.mdi-progress-alert:before {
  content: "󰲼";
}

.mdi-progress-check:before {
  content: "󰦕";
}

.mdi-progress-clock:before {
  content: "󰦖";
}

.mdi-progress-close:before {
  content: "󱄊";
}

.mdi-progress-download:before {
  content: "󰦗";
}

.mdi-progress-helper:before {
  content: "󱮢";
}

.mdi-progress-pencil:before {
  content: "󱞇";
}

.mdi-progress-question:before {
  content: "󱔢";
}

.mdi-progress-star:before {
  content: "󱞈";
}

.mdi-progress-star-four-points:before {
  content: "󱰽";
}

.mdi-progress-upload:before {
  content: "󰦘";
}

.mdi-progress-wrench:before {
  content: "󰲽";
}

.mdi-projector:before {
  content: "󰐮";
}

.mdi-projector-off:before {
  content: "󱨣";
}

.mdi-projector-screen:before {
  content: "󰐯";
}

.mdi-projector-screen-off:before {
  content: "󱠍";
}

.mdi-projector-screen-off-outline:before {
  content: "󱠎";
}

.mdi-projector-screen-outline:before {
  content: "󱜤";
}

.mdi-projector-screen-variant:before {
  content: "󱠏";
}

.mdi-projector-screen-variant-off:before {
  content: "󱠐";
}

.mdi-projector-screen-variant-off-outline:before {
  content: "󱠑";
}

.mdi-projector-screen-variant-outline:before {
  content: "󱠒";
}

.mdi-propane-tank:before {
  content: "󱍗";
}

.mdi-propane-tank-outline:before {
  content: "󱍘";
}

.mdi-protocol:before {
  content: "󰿘";
}

.mdi-publish:before {
  content: "󰚧";
}

.mdi-publish-off:before {
  content: "󱥅";
}

.mdi-pulse:before {
  content: "󰐰";
}

.mdi-pump:before {
  content: "󱐂";
}

.mdi-pump-off:before {
  content: "󱬢";
}

.mdi-pumpkin:before {
  content: "󰮿";
}

.mdi-purse:before {
  content: "󰼜";
}

.mdi-purse-outline:before {
  content: "󰼝";
}

.mdi-puzzle:before {
  content: "󰐱";
}

.mdi-puzzle-check:before {
  content: "󱐦";
}

.mdi-puzzle-check-outline:before {
  content: "󱐧";
}

.mdi-puzzle-edit:before {
  content: "󱓓";
}

.mdi-puzzle-edit-outline:before {
  content: "󱓙";
}

.mdi-puzzle-heart:before {
  content: "󱓔";
}

.mdi-puzzle-heart-outline:before {
  content: "󱓚";
}

.mdi-puzzle-minus:before {
  content: "󱓑";
}

.mdi-puzzle-minus-outline:before {
  content: "󱓗";
}

.mdi-puzzle-outline:before {
  content: "󰩦";
}

.mdi-puzzle-plus:before {
  content: "󱓐";
}

.mdi-puzzle-plus-outline:before {
  content: "󱓖";
}

.mdi-puzzle-remove:before {
  content: "󱓒";
}

.mdi-puzzle-remove-outline:before {
  content: "󱓘";
}

.mdi-puzzle-star:before {
  content: "󱓕";
}

.mdi-puzzle-star-outline:before {
  content: "󱓛";
}

.mdi-pyramid:before {
  content: "󱥒";
}

.mdi-pyramid-off:before {
  content: "󱥓";
}

.mdi-qi:before {
  content: "󰦙";
}

.mdi-qqchat:before {
  content: "󰘅";
}

.mdi-qrcode:before {
  content: "󰐲";
}

.mdi-qrcode-edit:before {
  content: "󰢸";
}

.mdi-qrcode-minus:before {
  content: "󱆌";
}

.mdi-qrcode-plus:before {
  content: "󱆋";
}

.mdi-qrcode-remove:before {
  content: "󱆍";
}

.mdi-qrcode-scan:before {
  content: "󰐳";
}

.mdi-quadcopter:before {
  content: "󰐴";
}

.mdi-quality-high:before {
  content: "󰐵";
}

.mdi-quality-low:before {
  content: "󰨌";
}

.mdi-quality-medium:before {
  content: "󰨍";
}

.mdi-quora:before {
  content: "󰴩";
}

.mdi-rabbit:before {
  content: "󰤇";
}

.mdi-rabbit-variant:before {
  content: "󱩡";
}

.mdi-rabbit-variant-outline:before {
  content: "󱩢";
}

.mdi-racing-helmet:before {
  content: "󰶓";
}

.mdi-racquetball:before {
  content: "󰶔";
}

.mdi-radar:before {
  content: "󰐷";
}

.mdi-radiator:before {
  content: "󰐸";
}

.mdi-radiator-disabled:before {
  content: "󰫗";
}

.mdi-radiator-off:before {
  content: "󰫘";
}

.mdi-radio:before {
  content: "󰐹";
}

.mdi-radio-am:before {
  content: "󰲾";
}

.mdi-radio-fm:before {
  content: "󰲿";
}

.mdi-radio-handheld:before {
  content: "󰐺";
}

.mdi-radio-off:before {
  content: "󱈜";
}

.mdi-radio-tower:before {
  content: "󰐻";
}

.mdi-radioactive:before {
  content: "󰐼";
}

.mdi-radioactive-circle:before {
  content: "󱡝";
}

.mdi-radioactive-circle-outline:before {
  content: "󱡞";
}

.mdi-radioactive-off:before {
  content: "󰻁";
}

.mdi-radiobox-blank:before {
  content: "󰐽";
}

.mdi-radiobox-indeterminate-variant:before {
  content: "󱱞";
}

.mdi-radiobox-marked:before {
  content: "󰐾";
}

.mdi-radiology-box:before {
  content: "󱓅";
}

.mdi-radiology-box-outline:before {
  content: "󱓆";
}

.mdi-radius:before {
  content: "󰳀";
}

.mdi-radius-outline:before {
  content: "󰳁";
}

.mdi-railroad-light:before {
  content: "󰼞";
}

.mdi-rake:before {
  content: "󱕄";
}

.mdi-raspberry-pi:before {
  content: "󰐿";
}

.mdi-raw:before {
  content: "󱨏";
}

.mdi-raw-off:before {
  content: "󱨐";
}

.mdi-ray-end:before {
  content: "󰑀";
}

.mdi-ray-end-arrow:before {
  content: "󰑁";
}

.mdi-ray-start:before {
  content: "󰑂";
}

.mdi-ray-start-arrow:before {
  content: "󰑃";
}

.mdi-ray-start-end:before {
  content: "󰑄";
}

.mdi-ray-start-vertex-end:before {
  content: "󱗘";
}

.mdi-ray-vertex:before {
  content: "󰑅";
}

.mdi-razor-double-edge:before {
  content: "󱦗";
}

.mdi-razor-single-edge:before {
  content: "󱦘";
}

.mdi-react:before {
  content: "󰜈";
}

.mdi-read:before {
  content: "󰑇";
}

.mdi-receipt:before {
  content: "󰠤";
}

.mdi-receipt-clock:before {
  content: "󱰾";
}

.mdi-receipt-clock-outline:before {
  content: "󱰿";
}

.mdi-receipt-outline:before {
  content: "󰓷";
}

.mdi-receipt-send:before {
  content: "󱱀";
}

.mdi-receipt-send-outline:before {
  content: "󱱁";
}

.mdi-receipt-text:before {
  content: "󰑉";
}

.mdi-receipt-text-arrow-left:before {
  content: "󱱂";
}

.mdi-receipt-text-arrow-left-outline:before {
  content: "󱱃";
}

.mdi-receipt-text-arrow-right:before {
  content: "󱱄";
}

.mdi-receipt-text-arrow-right-outline:before {
  content: "󱱅";
}

.mdi-receipt-text-check:before {
  content: "󱩣";
}

.mdi-receipt-text-check-outline:before {
  content: "󱩤";
}

.mdi-receipt-text-clock:before {
  content: "󱱆";
}

.mdi-receipt-text-clock-outline:before {
  content: "󱱇";
}

.mdi-receipt-text-edit:before {
  content: "󱱈";
}

.mdi-receipt-text-edit-outline:before {
  content: "󱱉";
}

.mdi-receipt-text-minus:before {
  content: "󱩥";
}

.mdi-receipt-text-minus-outline:before {
  content: "󱩦";
}

.mdi-receipt-text-outline:before {
  content: "󱧜";
}

.mdi-receipt-text-plus:before {
  content: "󱩧";
}

.mdi-receipt-text-plus-outline:before {
  content: "󱩨";
}

.mdi-receipt-text-remove:before {
  content: "󱩩";
}

.mdi-receipt-text-remove-outline:before {
  content: "󱩪";
}

.mdi-receipt-text-send:before {
  content: "󱱊";
}

.mdi-receipt-text-send-outline:before {
  content: "󱱋";
}

.mdi-record:before {
  content: "󰑊";
}

.mdi-record-circle:before {
  content: "󰻂";
}

.mdi-record-circle-outline:before {
  content: "󰻃";
}

.mdi-record-player:before {
  content: "󰦚";
}

.mdi-record-rec:before {
  content: "󰑋";
}

.mdi-rectangle:before {
  content: "󰹞";
}

.mdi-rectangle-outline:before {
  content: "󰹟";
}

.mdi-recycle:before {
  content: "󰑌";
}

.mdi-recycle-variant:before {
  content: "󱎝";
}

.mdi-reddit:before {
  content: "󰑍";
}

.mdi-redhat:before {
  content: "󱄛";
}

.mdi-redo:before {
  content: "󰑎";
}

.mdi-redo-variant:before {
  content: "󰑏";
}

.mdi-reflect-horizontal:before {
  content: "󰨎";
}

.mdi-reflect-vertical:before {
  content: "󰨏";
}

.mdi-refresh:before {
  content: "󰑐";
}

.mdi-refresh-auto:before {
  content: "󱣲";
}

.mdi-refresh-circle:before {
  content: "󱍷";
}

.mdi-regex:before {
  content: "󰑑";
}

.mdi-registered-trademark:before {
  content: "󰩧";
}

.mdi-reiterate:before {
  content: "󱖈";
}

.mdi-relation-many-to-many:before {
  content: "󱒖";
}

.mdi-relation-many-to-one:before {
  content: "󱒗";
}

.mdi-relation-many-to-one-or-many:before {
  content: "󱒘";
}

.mdi-relation-many-to-only-one:before {
  content: "󱒙";
}

.mdi-relation-many-to-zero-or-many:before {
  content: "󱒚";
}

.mdi-relation-many-to-zero-or-one:before {
  content: "󱒛";
}

.mdi-relation-one-or-many-to-many:before {
  content: "󱒜";
}

.mdi-relation-one-or-many-to-one:before {
  content: "󱒝";
}

.mdi-relation-one-or-many-to-one-or-many:before {
  content: "󱒞";
}

.mdi-relation-one-or-many-to-only-one:before {
  content: "󱒟";
}

.mdi-relation-one-or-many-to-zero-or-many:before {
  content: "󱒠";
}

.mdi-relation-one-or-many-to-zero-or-one:before {
  content: "󱒡";
}

.mdi-relation-one-to-many:before {
  content: "󱒢";
}

.mdi-relation-one-to-one:before {
  content: "󱒣";
}

.mdi-relation-one-to-one-or-many:before {
  content: "󱒤";
}

.mdi-relation-one-to-only-one:before {
  content: "󱒥";
}

.mdi-relation-one-to-zero-or-many:before {
  content: "󱒦";
}

.mdi-relation-one-to-zero-or-one:before {
  content: "󱒧";
}

.mdi-relation-only-one-to-many:before {
  content: "󱒨";
}

.mdi-relation-only-one-to-one:before {
  content: "󱒩";
}

.mdi-relation-only-one-to-one-or-many:before {
  content: "󱒪";
}

.mdi-relation-only-one-to-only-one:before {
  content: "󱒫";
}

.mdi-relation-only-one-to-zero-or-many:before {
  content: "󱒬";
}

.mdi-relation-only-one-to-zero-or-one:before {
  content: "󱒭";
}

.mdi-relation-zero-or-many-to-many:before {
  content: "󱒮";
}

.mdi-relation-zero-or-many-to-one:before {
  content: "󱒯";
}

.mdi-relation-zero-or-many-to-one-or-many:before {
  content: "󱒰";
}

.mdi-relation-zero-or-many-to-only-one:before {
  content: "󱒱";
}

.mdi-relation-zero-or-many-to-zero-or-many:before {
  content: "󱒲";
}

.mdi-relation-zero-or-many-to-zero-or-one:before {
  content: "󱒳";
}

.mdi-relation-zero-or-one-to-many:before {
  content: "󱒴";
}

.mdi-relation-zero-or-one-to-one:before {
  content: "󱒵";
}

.mdi-relation-zero-or-one-to-one-or-many:before {
  content: "󱒶";
}

.mdi-relation-zero-or-one-to-only-one:before {
  content: "󱒷";
}

.mdi-relation-zero-or-one-to-zero-or-many:before {
  content: "󱒸";
}

.mdi-relation-zero-or-one-to-zero-or-one:before {
  content: "󱒹";
}

.mdi-relative-scale:before {
  content: "󰑒";
}

.mdi-reload:before {
  content: "󰑓";
}

.mdi-reload-alert:before {
  content: "󱄋";
}

.mdi-reminder:before {
  content: "󰢌";
}

.mdi-remote:before {
  content: "󰑔";
}

.mdi-remote-desktop:before {
  content: "󰢹";
}

.mdi-remote-off:before {
  content: "󰻄";
}

.mdi-remote-tv:before {
  content: "󰻅";
}

.mdi-remote-tv-off:before {
  content: "󰻆";
}

.mdi-rename:before {
  content: "󱰘";
}

.mdi-rename-box:before {
  content: "󰑕";
}

.mdi-rename-box-outline:before {
  content: "󱰙";
}

.mdi-rename-outline:before {
  content: "󱰚";
}

.mdi-reorder-horizontal:before {
  content: "󰚈";
}

.mdi-reorder-vertical:before {
  content: "󰚉";
}

.mdi-repeat:before {
  content: "󰑖";
}

.mdi-repeat-off:before {
  content: "󰑗";
}

.mdi-repeat-once:before {
  content: "󰑘";
}

.mdi-repeat-variant:before {
  content: "󰕇";
}

.mdi-replay:before {
  content: "󰑙";
}

.mdi-reply:before {
  content: "󰑚";
}

.mdi-reply-all:before {
  content: "󰑛";
}

.mdi-reply-all-outline:before {
  content: "󰼟";
}

.mdi-reply-circle:before {
  content: "󱆮";
}

.mdi-reply-outline:before {
  content: "󰼠";
}

.mdi-reproduction:before {
  content: "󰑜";
}

.mdi-resistor:before {
  content: "󰭄";
}

.mdi-resistor-nodes:before {
  content: "󰭅";
}

.mdi-resize:before {
  content: "󰩨";
}

.mdi-resize-bottom-right:before {
  content: "󰑝";
}

.mdi-responsive:before {
  content: "󰑞";
}

.mdi-restart:before {
  content: "󰜉";
}

.mdi-restart-alert:before {
  content: "󱄌";
}

.mdi-restart-off:before {
  content: "󰶕";
}

.mdi-restore:before {
  content: "󰦛";
}

.mdi-restore-alert:before {
  content: "󱄍";
}

.mdi-rewind:before {
  content: "󰑟";
}

.mdi-rewind-10:before {
  content: "󰴪";
}

.mdi-rewind-15:before {
  content: "󱥆";
}

.mdi-rewind-30:before {
  content: "󰶖";
}

.mdi-rewind-45:before {
  content: "󱬓";
}

.mdi-rewind-5:before {
  content: "󱇹";
}

.mdi-rewind-60:before {
  content: "󱘌";
}

.mdi-rewind-outline:before {
  content: "󰜊";
}

.mdi-rhombus:before {
  content: "󰜋";
}

.mdi-rhombus-medium:before {
  content: "󰨐";
}

.mdi-rhombus-medium-outline:before {
  content: "󱓜";
}

.mdi-rhombus-outline:before {
  content: "󰜌";
}

.mdi-rhombus-split:before {
  content: "󰨑";
}

.mdi-rhombus-split-outline:before {
  content: "󱓝";
}

.mdi-ribbon:before {
  content: "󰑠";
}

.mdi-rice:before {
  content: "󰟪";
}

.mdi-rickshaw:before {
  content: "󱖻";
}

.mdi-rickshaw-electric:before {
  content: "󱖼";
}

.mdi-ring:before {
  content: "󰟫";
}

.mdi-rivet:before {
  content: "󰹠";
}

.mdi-road:before {
  content: "󰑡";
}

.mdi-road-variant:before {
  content: "󰑢";
}

.mdi-robber:before {
  content: "󱁘";
}

.mdi-robot:before {
  content: "󰚩";
}

.mdi-robot-angry:before {
  content: "󱚝";
}

.mdi-robot-angry-outline:before {
  content: "󱚞";
}

.mdi-robot-confused:before {
  content: "󱚟";
}

.mdi-robot-confused-outline:before {
  content: "󱚠";
}

.mdi-robot-dead:before {
  content: "󱚡";
}

.mdi-robot-dead-outline:before {
  content: "󱚢";
}

.mdi-robot-excited:before {
  content: "󱚣";
}

.mdi-robot-excited-outline:before {
  content: "󱚤";
}

.mdi-robot-happy:before {
  content: "󱜙";
}

.mdi-robot-happy-outline:before {
  content: "󱜚";
}

.mdi-robot-industrial:before {
  content: "󰭆";
}

.mdi-robot-industrial-outline:before {
  content: "󱨚";
}

.mdi-robot-love:before {
  content: "󱚥";
}

.mdi-robot-love-outline:before {
  content: "󱚦";
}

.mdi-robot-mower:before {
  content: "󱇷";
}

.mdi-robot-mower-outline:before {
  content: "󱇳";
}

.mdi-robot-off:before {
  content: "󱚧";
}

.mdi-robot-off-outline:before {
  content: "󱙻";
}

.mdi-robot-outline:before {
  content: "󱙺";
}

.mdi-robot-vacuum:before {
  content: "󰜍";
}

.mdi-robot-vacuum-alert:before {
  content: "󱭝";
}

.mdi-robot-vacuum-off:before {
  content: "󱰁";
}

.mdi-robot-vacuum-variant:before {
  content: "󰤈";
}

.mdi-robot-vacuum-variant-alert:before {
  content: "󱭞";
}

.mdi-robot-vacuum-variant-off:before {
  content: "󱰂";
}

.mdi-rocket:before {
  content: "󰑣";
}

.mdi-rocket-launch:before {
  content: "󱓞";
}

.mdi-rocket-launch-outline:before {
  content: "󱓟";
}

.mdi-rocket-outline:before {
  content: "󱎯";
}

.mdi-rodent:before {
  content: "󱌧";
}

.mdi-roller-shade:before {
  content: "󱩫";
}

.mdi-roller-shade-closed:before {
  content: "󱩬";
}

.mdi-roller-skate:before {
  content: "󰴫";
}

.mdi-roller-skate-off:before {
  content: "󰅅";
}

.mdi-rollerblade:before {
  content: "󰴬";
}

.mdi-rollerblade-off:before {
  content: "󰀮";
}

.mdi-rollupjs:before {
  content: "󰯀";
}

.mdi-rolodex:before {
  content: "󱪹";
}

.mdi-rolodex-outline:before {
  content: "󱪺";
}

.mdi-roman-numeral-1:before {
  content: "󱂈";
}

.mdi-roman-numeral-10:before {
  content: "󱂑";
}

.mdi-roman-numeral-2:before {
  content: "󱂉";
}

.mdi-roman-numeral-3:before {
  content: "󱂊";
}

.mdi-roman-numeral-4:before {
  content: "󱂋";
}

.mdi-roman-numeral-5:before {
  content: "󱂌";
}

.mdi-roman-numeral-6:before {
  content: "󱂍";
}

.mdi-roman-numeral-7:before {
  content: "󱂎";
}

.mdi-roman-numeral-8:before {
  content: "󱂏";
}

.mdi-roman-numeral-9:before {
  content: "󱂐";
}

.mdi-room-service:before {
  content: "󰢍";
}

.mdi-room-service-outline:before {
  content: "󰶗";
}

.mdi-rotate-360:before {
  content: "󱦙";
}

.mdi-rotate-3d:before {
  content: "󰻇";
}

.mdi-rotate-3d-variant:before {
  content: "󰑤";
}

.mdi-rotate-left:before {
  content: "󰑥";
}

.mdi-rotate-left-variant:before {
  content: "󰑦";
}

.mdi-rotate-orbit:before {
  content: "󰶘";
}

.mdi-rotate-right:before {
  content: "󰑧";
}

.mdi-rotate-right-variant:before {
  content: "󰑨";
}

.mdi-rounded-corner:before {
  content: "󰘇";
}

.mdi-router:before {
  content: "󱇢";
}

.mdi-router-network:before {
  content: "󱂇";
}

.mdi-router-wireless:before {
  content: "󰑩";
}

.mdi-router-wireless-off:before {
  content: "󱖣";
}

.mdi-router-wireless-settings:before {
  content: "󰩩";
}

.mdi-routes:before {
  content: "󰑪";
}

.mdi-routes-clock:before {
  content: "󱁙";
}

.mdi-rowing:before {
  content: "󰘈";
}

.mdi-rss:before {
  content: "󰑫";
}

.mdi-rss-box:before {
  content: "󰑬";
}

.mdi-rss-off:before {
  content: "󰼡";
}

.mdi-rug:before {
  content: "󱑵";
}

.mdi-rugby:before {
  content: "󰶙";
}

.mdi-ruler:before {
  content: "󰑭";
}

.mdi-ruler-square:before {
  content: "󰳂";
}

.mdi-ruler-square-compass:before {
  content: "󰺾";
}

.mdi-run:before {
  content: "󰜎";
}

.mdi-run-fast:before {
  content: "󰑮";
}

.mdi-rv-truck:before {
  content: "󱇔";
}

.mdi-sack:before {
  content: "󰴮";
}

.mdi-sack-outline:before {
  content: "󱱌";
}

.mdi-sack-percent:before {
  content: "󰴯";
}

.mdi-safe:before {
  content: "󰩪";
}

.mdi-safe-square:before {
  content: "󱉼";
}

.mdi-safe-square-outline:before {
  content: "󱉽";
}

.mdi-safety-goggles:before {
  content: "󰴰";
}

.mdi-sail-boat:before {
  content: "󰻈";
}

.mdi-sail-boat-sink:before {
  content: "󱫯";
}

.mdi-sale:before {
  content: "󰑯";
}

.mdi-sale-outline:before {
  content: "󱨆";
}

.mdi-salesforce:before {
  content: "󰢎";
}

.mdi-sass:before {
  content: "󰟬";
}

.mdi-satellite:before {
  content: "󰑰";
}

.mdi-satellite-uplink:before {
  content: "󰤉";
}

.mdi-satellite-variant:before {
  content: "󰑱";
}

.mdi-sausage:before {
  content: "󰢺";
}

.mdi-sausage-off:before {
  content: "󱞉";
}

.mdi-saw-blade:before {
  content: "󰹡";
}

.mdi-sawtooth-wave:before {
  content: "󱑺";
}

.mdi-saxophone:before {
  content: "󰘉";
}

.mdi-scale:before {
  content: "󰑲";
}

.mdi-scale-balance:before {
  content: "󰗑";
}

.mdi-scale-bathroom:before {
  content: "󰑳";
}

.mdi-scale-off:before {
  content: "󱁚";
}

.mdi-scale-unbalanced:before {
  content: "󱦸";
}

.mdi-scan-helper:before {
  content: "󱏘";
}

.mdi-scanner:before {
  content: "󰚫";
}

.mdi-scanner-off:before {
  content: "󰤊";
}

.mdi-scatter-plot:before {
  content: "󰻉";
}

.mdi-scatter-plot-outline:before {
  content: "󰻊";
}

.mdi-scent:before {
  content: "󱥘";
}

.mdi-scent-off:before {
  content: "󱥙";
}

.mdi-school:before {
  content: "󰑴";
}

.mdi-school-outline:before {
  content: "󱆀";
}

.mdi-scissors-cutting:before {
  content: "󰩫";
}

.mdi-scooter:before {
  content: "󱖽";
}

.mdi-scooter-electric:before {
  content: "󱖾";
}

.mdi-scoreboard:before {
  content: "󱉾";
}

.mdi-scoreboard-outline:before {
  content: "󱉿";
}

.mdi-screen-rotation:before {
  content: "󰑵";
}

.mdi-screen-rotation-lock:before {
  content: "󰑸";
}

.mdi-screw-flat-top:before {
  content: "󰷳";
}

.mdi-screw-lag:before {
  content: "󰷴";
}

.mdi-screw-machine-flat-top:before {
  content: "󰷵";
}

.mdi-screw-machine-round-top:before {
  content: "󰷶";
}

.mdi-screw-round-top:before {
  content: "󰷷";
}

.mdi-screwdriver:before {
  content: "󰑶";
}

.mdi-script:before {
  content: "󰯁";
}

.mdi-script-outline:before {
  content: "󰑷";
}

.mdi-script-text:before {
  content: "󰯂";
}

.mdi-script-text-key:before {
  content: "󱜥";
}

.mdi-script-text-key-outline:before {
  content: "󱜦";
}

.mdi-script-text-outline:before {
  content: "󰯃";
}

.mdi-script-text-play:before {
  content: "󱜧";
}

.mdi-script-text-play-outline:before {
  content: "󱜨";
}

.mdi-sd:before {
  content: "󰑹";
}

.mdi-seal:before {
  content: "󰑺";
}

.mdi-seal-variant:before {
  content: "󰿙";
}

.mdi-search-web:before {
  content: "󰜏";
}

.mdi-seat:before {
  content: "󰳃";
}

.mdi-seat-flat:before {
  content: "󰑻";
}

.mdi-seat-flat-angled:before {
  content: "󰑼";
}

.mdi-seat-individual-suite:before {
  content: "󰑽";
}

.mdi-seat-legroom-extra:before {
  content: "󰑾";
}

.mdi-seat-legroom-normal:before {
  content: "󰑿";
}

.mdi-seat-legroom-reduced:before {
  content: "󰒀";
}

.mdi-seat-outline:before {
  content: "󰳄";
}

.mdi-seat-passenger:before {
  content: "󱉉";
}

.mdi-seat-recline-extra:before {
  content: "󰒁";
}

.mdi-seat-recline-normal:before {
  content: "󰒂";
}

.mdi-seatbelt:before {
  content: "󰳅";
}

.mdi-security:before {
  content: "󰒃";
}

.mdi-security-network:before {
  content: "󰒄";
}

.mdi-seed:before {
  content: "󰹢";
}

.mdi-seed-off:before {
  content: "󱏽";
}

.mdi-seed-off-outline:before {
  content: "󱏾";
}

.mdi-seed-outline:before {
  content: "󰹣";
}

.mdi-seed-plus:before {
  content: "󱩭";
}

.mdi-seed-plus-outline:before {
  content: "󱩮";
}

.mdi-seesaw:before {
  content: "󱖤";
}

.mdi-segment:before {
  content: "󰻋";
}

.mdi-select:before {
  content: "󰒅";
}

.mdi-select-all:before {
  content: "󰒆";
}

.mdi-select-arrow-down:before {
  content: "󱭙";
}

.mdi-select-arrow-up:before {
  content: "󱭘";
}

.mdi-select-color:before {
  content: "󰴱";
}

.mdi-select-compare:before {
  content: "󰫙";
}

.mdi-select-drag:before {
  content: "󰩬";
}

.mdi-select-group:before {
  content: "󰾂";
}

.mdi-select-inverse:before {
  content: "󰒇";
}

.mdi-select-marker:before {
  content: "󱊀";
}

.mdi-select-multiple:before {
  content: "󱊁";
}

.mdi-select-multiple-marker:before {
  content: "󱊂";
}

.mdi-select-off:before {
  content: "󰒈";
}

.mdi-select-place:before {
  content: "󰿚";
}

.mdi-select-remove:before {
  content: "󱟁";
}

.mdi-select-search:before {
  content: "󱈄";
}

.mdi-selection:before {
  content: "󰒉";
}

.mdi-selection-drag:before {
  content: "󰩭";
}

.mdi-selection-ellipse:before {
  content: "󰴲";
}

.mdi-selection-ellipse-arrow-inside:before {
  content: "󰼢";
}

.mdi-selection-ellipse-remove:before {
  content: "󱟂";
}

.mdi-selection-marker:before {
  content: "󱊃";
}

.mdi-selection-multiple:before {
  content: "󱊅";
}

.mdi-selection-multiple-marker:before {
  content: "󱊄";
}

.mdi-selection-off:before {
  content: "󰝷";
}

.mdi-selection-remove:before {
  content: "󱟃";
}

.mdi-selection-search:before {
  content: "󱈅";
}

.mdi-semantic-web:before {
  content: "󱌖";
}

.mdi-send:before {
  content: "󰒊";
}

.mdi-send-check:before {
  content: "󱅡";
}

.mdi-send-check-outline:before {
  content: "󱅢";
}

.mdi-send-circle:before {
  content: "󰷸";
}

.mdi-send-circle-outline:before {
  content: "󰷹";
}

.mdi-send-clock:before {
  content: "󱅣";
}

.mdi-send-clock-outline:before {
  content: "󱅤";
}

.mdi-send-lock:before {
  content: "󰟭";
}

.mdi-send-lock-outline:before {
  content: "󱅦";
}

.mdi-send-outline:before {
  content: "󱅥";
}

.mdi-send-variant:before {
  content: "󱱍";
}

.mdi-send-variant-clock:before {
  content: "󱱾";
}

.mdi-send-variant-clock-outline:before {
  content: "󱱿";
}

.mdi-send-variant-outline:before {
  content: "󱱎";
}

.mdi-serial-port:before {
  content: "󰙜";
}

.mdi-server:before {
  content: "󰒋";
}

.mdi-server-minus:before {
  content: "󰒌";
}

.mdi-server-network:before {
  content: "󰒍";
}

.mdi-server-network-off:before {
  content: "󰒎";
}

.mdi-server-off:before {
  content: "󰒏";
}

.mdi-server-plus:before {
  content: "󰒐";
}

.mdi-server-remove:before {
  content: "󰒑";
}

.mdi-server-security:before {
  content: "󰒒";
}

.mdi-set-all:before {
  content: "󰝸";
}

.mdi-set-center:before {
  content: "󰝹";
}

.mdi-set-center-right:before {
  content: "󰝺";
}

.mdi-set-left:before {
  content: "󰝻";
}

.mdi-set-left-center:before {
  content: "󰝼";
}

.mdi-set-left-right:before {
  content: "󰝽";
}

.mdi-set-merge:before {
  content: "󱓠";
}

.mdi-set-none:before {
  content: "󰝾";
}

.mdi-set-right:before {
  content: "󰝿";
}

.mdi-set-split:before {
  content: "󱓡";
}

.mdi-set-square:before {
  content: "󱑝";
}

.mdi-set-top-box:before {
  content: "󰦟";
}

.mdi-settings-helper:before {
  content: "󰩮";
}

.mdi-shaker:before {
  content: "󱄎";
}

.mdi-shaker-outline:before {
  content: "󱄏";
}

.mdi-shape:before {
  content: "󰠱";
}

.mdi-shape-circle-plus:before {
  content: "󰙝";
}

.mdi-shape-outline:before {
  content: "󰠲";
}

.mdi-shape-oval-plus:before {
  content: "󱇺";
}

.mdi-shape-plus:before {
  content: "󰒕";
}

.mdi-shape-plus-outline:before {
  content: "󱱏";
}

.mdi-shape-polygon-plus:before {
  content: "󰙞";
}

.mdi-shape-rectangle-plus:before {
  content: "󰙟";
}

.mdi-shape-square-plus:before {
  content: "󰙠";
}

.mdi-shape-square-rounded-plus:before {
  content: "󱓺";
}

.mdi-share:before {
  content: "󰒖";
}

.mdi-share-all:before {
  content: "󱇴";
}

.mdi-share-all-outline:before {
  content: "󱇵";
}

.mdi-share-circle:before {
  content: "󱆭";
}

.mdi-share-off:before {
  content: "󰼣";
}

.mdi-share-off-outline:before {
  content: "󰼤";
}

.mdi-share-outline:before {
  content: "󰤲";
}

.mdi-share-variant:before {
  content: "󰒗";
}

.mdi-share-variant-outline:before {
  content: "󱔔";
}

.mdi-shark:before {
  content: "󱢺";
}

.mdi-shark-fin:before {
  content: "󱙳";
}

.mdi-shark-fin-outline:before {
  content: "󱙴";
}

.mdi-shark-off:before {
  content: "󱢻";
}

.mdi-sheep:before {
  content: "󰳆";
}

.mdi-shield:before {
  content: "󰒘";
}

.mdi-shield-account:before {
  content: "󰢏";
}

.mdi-shield-account-outline:before {
  content: "󰨒";
}

.mdi-shield-account-variant:before {
  content: "󱖧";
}

.mdi-shield-account-variant-outline:before {
  content: "󱖨";
}

.mdi-shield-airplane:before {
  content: "󰚻";
}

.mdi-shield-airplane-outline:before {
  content: "󰳇";
}

.mdi-shield-alert:before {
  content: "󰻌";
}

.mdi-shield-alert-outline:before {
  content: "󰻍";
}

.mdi-shield-bug:before {
  content: "󱏚";
}

.mdi-shield-bug-outline:before {
  content: "󱏛";
}

.mdi-shield-car:before {
  content: "󰾃";
}

.mdi-shield-check:before {
  content: "󰕥";
}

.mdi-shield-check-outline:before {
  content: "󰳈";
}

.mdi-shield-cross:before {
  content: "󰳉";
}

.mdi-shield-cross-outline:before {
  content: "󰳊";
}

.mdi-shield-crown:before {
  content: "󱢼";
}

.mdi-shield-crown-outline:before {
  content: "󱢽";
}

.mdi-shield-edit:before {
  content: "󱆠";
}

.mdi-shield-edit-outline:before {
  content: "󱆡";
}

.mdi-shield-half:before {
  content: "󱍠";
}

.mdi-shield-half-full:before {
  content: "󰞀";
}

.mdi-shield-home:before {
  content: "󰚊";
}

.mdi-shield-home-outline:before {
  content: "󰳋";
}

.mdi-shield-key:before {
  content: "󰯄";
}

.mdi-shield-key-outline:before {
  content: "󰯅";
}

.mdi-shield-link-variant:before {
  content: "󰴳";
}

.mdi-shield-link-variant-outline:before {
  content: "󰴴";
}

.mdi-shield-lock:before {
  content: "󰦝";
}

.mdi-shield-lock-open:before {
  content: "󱦚";
}

.mdi-shield-lock-open-outline:before {
  content: "󱦛";
}

.mdi-shield-lock-outline:before {
  content: "󰳌";
}

.mdi-shield-moon:before {
  content: "󱠨";
}

.mdi-shield-moon-outline:before {
  content: "󱠩";
}

.mdi-shield-off:before {
  content: "󰦞";
}

.mdi-shield-off-outline:before {
  content: "󰦜";
}

.mdi-shield-outline:before {
  content: "󰒙";
}

.mdi-shield-plus:before {
  content: "󰫚";
}

.mdi-shield-plus-outline:before {
  content: "󰫛";
}

.mdi-shield-refresh:before {
  content: "󰂪";
}

.mdi-shield-refresh-outline:before {
  content: "󰇠";
}

.mdi-shield-remove:before {
  content: "󰫜";
}

.mdi-shield-remove-outline:before {
  content: "󰫝";
}

.mdi-shield-search:before {
  content: "󰶚";
}

.mdi-shield-star:before {
  content: "󱄻";
}

.mdi-shield-star-outline:before {
  content: "󱄼";
}

.mdi-shield-sun:before {
  content: "󱁝";
}

.mdi-shield-sun-outline:before {
  content: "󱁞";
}

.mdi-shield-sword:before {
  content: "󱢾";
}

.mdi-shield-sword-outline:before {
  content: "󱢿";
}

.mdi-shield-sync:before {
  content: "󱆢";
}

.mdi-shield-sync-outline:before {
  content: "󱆣";
}

.mdi-shimmer:before {
  content: "󱕅";
}

.mdi-ship-wheel:before {
  content: "󰠳";
}

.mdi-shipping-pallet:before {
  content: "󱡎";
}

.mdi-shoe-ballet:before {
  content: "󱗊";
}

.mdi-shoe-cleat:before {
  content: "󱗇";
}

.mdi-shoe-formal:before {
  content: "󰭇";
}

.mdi-shoe-heel:before {
  content: "󰭈";
}

.mdi-shoe-print:before {
  content: "󰷺";
}

.mdi-shoe-sneaker:before {
  content: "󱗈";
}

.mdi-shopping:before {
  content: "󰒚";
}

.mdi-shopping-music:before {
  content: "󰒛";
}

.mdi-shopping-outline:before {
  content: "󱇕";
}

.mdi-shopping-search:before {
  content: "󰾄";
}

.mdi-shopping-search-outline:before {
  content: "󱩯";
}

.mdi-shore:before {
  content: "󱓹";
}

.mdi-shovel:before {
  content: "󰜐";
}

.mdi-shovel-off:before {
  content: "󰜑";
}

.mdi-shower:before {
  content: "󰦠";
}

.mdi-shower-head:before {
  content: "󰦡";
}

.mdi-shredder:before {
  content: "󰒜";
}

.mdi-shuffle:before {
  content: "󰒝";
}

.mdi-shuffle-disabled:before {
  content: "󰒞";
}

.mdi-shuffle-variant:before {
  content: "󰒟";
}

.mdi-shuriken:before {
  content: "󱍿";
}

.mdi-sickle:before {
  content: "󱣀";
}

.mdi-sigma:before {
  content: "󰒠";
}

.mdi-sigma-lower:before {
  content: "󰘫";
}

.mdi-sign-caution:before {
  content: "󰒡";
}

.mdi-sign-direction:before {
  content: "󰞁";
}

.mdi-sign-direction-minus:before {
  content: "󱀀";
}

.mdi-sign-direction-plus:before {
  content: "󰿜";
}

.mdi-sign-direction-remove:before {
  content: "󰿝";
}

.mdi-sign-language:before {
  content: "󱭍";
}

.mdi-sign-language-outline:before {
  content: "󱭎";
}

.mdi-sign-pole:before {
  content: "󱓸";
}

.mdi-sign-real-estate:before {
  content: "󱄘";
}

.mdi-sign-text:before {
  content: "󰞂";
}

.mdi-sign-yield:before {
  content: "󱮯";
}

.mdi-signal:before {
  content: "󰒢";
}

.mdi-signal-2g:before {
  content: "󰜒";
}

.mdi-signal-3g:before {
  content: "󰜓";
}

.mdi-signal-4g:before {
  content: "󰜔";
}

.mdi-signal-5g:before {
  content: "󰩯";
}

.mdi-signal-cellular-1:before {
  content: "󰢼";
}

.mdi-signal-cellular-2:before {
  content: "󰢽";
}

.mdi-signal-cellular-3:before {
  content: "󰢾";
}

.mdi-signal-cellular-outline:before {
  content: "󰢿";
}

.mdi-signal-distance-variant:before {
  content: "󰹤";
}

.mdi-signal-hspa:before {
  content: "󰜕";
}

.mdi-signal-hspa-plus:before {
  content: "󰜖";
}

.mdi-signal-off:before {
  content: "󰞃";
}

.mdi-signal-variant:before {
  content: "󰘊";
}

.mdi-signature:before {
  content: "󰷻";
}

.mdi-signature-freehand:before {
  content: "󰷼";
}

.mdi-signature-image:before {
  content: "󰷽";
}

.mdi-signature-text:before {
  content: "󰷾";
}

.mdi-silo:before {
  content: "󱮟";
}

.mdi-silo-outline:before {
  content: "󰭉";
}

.mdi-silverware:before {
  content: "󰒣";
}

.mdi-silverware-clean:before {
  content: "󰿞";
}

.mdi-silverware-fork:before {
  content: "󰒤";
}

.mdi-silverware-fork-knife:before {
  content: "󰩰";
}

.mdi-silverware-spoon:before {
  content: "󰒥";
}

.mdi-silverware-variant:before {
  content: "󰒦";
}

.mdi-sim:before {
  content: "󰒧";
}

.mdi-sim-alert:before {
  content: "󰒨";
}

.mdi-sim-alert-outline:before {
  content: "󱗓";
}

.mdi-sim-off:before {
  content: "󰒩";
}

.mdi-sim-off-outline:before {
  content: "󱗔";
}

.mdi-sim-outline:before {
  content: "󱗕";
}

.mdi-simple-icons:before {
  content: "󱌝";
}

.mdi-sina-weibo:before {
  content: "󰫟";
}

.mdi-sine-wave:before {
  content: "󰥛";
}

.mdi-sitemap:before {
  content: "󰒪";
}

.mdi-sitemap-outline:before {
  content: "󱦜";
}

.mdi-size-l:before {
  content: "󱎦";
}

.mdi-size-m:before {
  content: "󱎥";
}

.mdi-size-s:before {
  content: "󱎤";
}

.mdi-size-xl:before {
  content: "󱎧";
}

.mdi-size-xs:before {
  content: "󱎣";
}

.mdi-size-xxl:before {
  content: "󱎨";
}

.mdi-size-xxs:before {
  content: "󱎢";
}

.mdi-size-xxxl:before {
  content: "󱎩";
}

.mdi-skate:before {
  content: "󰴵";
}

.mdi-skate-off:before {
  content: "󰚙";
}

.mdi-skateboard:before {
  content: "󱓂";
}

.mdi-skateboarding:before {
  content: "󰔁";
}

.mdi-skew-less:before {
  content: "󰴶";
}

.mdi-skew-more:before {
  content: "󰴷";
}

.mdi-ski:before {
  content: "󱌄";
}

.mdi-ski-cross-country:before {
  content: "󱌅";
}

.mdi-ski-water:before {
  content: "󱌆";
}

.mdi-skip-backward:before {
  content: "󰒫";
}

.mdi-skip-backward-outline:before {
  content: "󰼥";
}

.mdi-skip-forward:before {
  content: "󰒬";
}

.mdi-skip-forward-outline:before {
  content: "󰼦";
}

.mdi-skip-next:before {
  content: "󰒭";
}

.mdi-skip-next-circle:before {
  content: "󰙡";
}

.mdi-skip-next-circle-outline:before {
  content: "󰙢";
}

.mdi-skip-next-outline:before {
  content: "󰼧";
}

.mdi-skip-previous:before {
  content: "󰒮";
}

.mdi-skip-previous-circle:before {
  content: "󰙣";
}

.mdi-skip-previous-circle-outline:before {
  content: "󰙤";
}

.mdi-skip-previous-outline:before {
  content: "󰼨";
}

.mdi-skull:before {
  content: "󰚌";
}

.mdi-skull-crossbones:before {
  content: "󰯆";
}

.mdi-skull-crossbones-outline:before {
  content: "󰯇";
}

.mdi-skull-outline:before {
  content: "󰯈";
}

.mdi-skull-scan:before {
  content: "󱓇";
}

.mdi-skull-scan-outline:before {
  content: "󱓈";
}

.mdi-skype:before {
  content: "󰒯";
}

.mdi-skype-business:before {
  content: "󰒰";
}

.mdi-slack:before {
  content: "󰒱";
}

.mdi-slash-forward:before {
  content: "󰿟";
}

.mdi-slash-forward-box:before {
  content: "󰿠";
}

.mdi-sledding:before {
  content: "󰐛";
}

.mdi-sleep:before {
  content: "󰒲";
}

.mdi-sleep-off:before {
  content: "󰒳";
}

.mdi-slide:before {
  content: "󱖥";
}

.mdi-slope-downhill:before {
  content: "󰷿";
}

.mdi-slope-uphill:before {
  content: "󰸀";
}

.mdi-slot-machine:before {
  content: "󱄔";
}

.mdi-slot-machine-outline:before {
  content: "󱄕";
}

.mdi-smart-card:before {
  content: "󱂽";
}

.mdi-smart-card-off:before {
  content: "󱣷";
}

.mdi-smart-card-off-outline:before {
  content: "󱣸";
}

.mdi-smart-card-outline:before {
  content: "󱂾";
}

.mdi-smart-card-reader:before {
  content: "󱂿";
}

.mdi-smart-card-reader-outline:before {
  content: "󱃀";
}

.mdi-smog:before {
  content: "󰩱";
}

.mdi-smoke:before {
  content: "󱞙";
}

.mdi-smoke-detector:before {
  content: "󰎒";
}

.mdi-smoke-detector-alert:before {
  content: "󱤮";
}

.mdi-smoke-detector-alert-outline:before {
  content: "󱤯";
}

.mdi-smoke-detector-off:before {
  content: "󱠉";
}

.mdi-smoke-detector-off-outline:before {
  content: "󱠊";
}

.mdi-smoke-detector-outline:before {
  content: "󱠈";
}

.mdi-smoke-detector-variant:before {
  content: "󱠋";
}

.mdi-smoke-detector-variant-alert:before {
  content: "󱤰";
}

.mdi-smoke-detector-variant-off:before {
  content: "󱠌";
}

.mdi-smoking:before {
  content: "󰒴";
}

.mdi-smoking-off:before {
  content: "󰒵";
}

.mdi-smoking-pipe:before {
  content: "󱐍";
}

.mdi-smoking-pipe-off:before {
  content: "󱐨";
}

.mdi-snail:before {
  content: "󱙷";
}

.mdi-snake:before {
  content: "󱔎";
}

.mdi-snapchat:before {
  content: "󰒶";
}

.mdi-snowboard:before {
  content: "󱌇";
}

.mdi-snowflake:before {
  content: "󰜗";
}

.mdi-snowflake-alert:before {
  content: "󰼩";
}

.mdi-snowflake-check:before {
  content: "󱩰";
}

.mdi-snowflake-melt:before {
  content: "󱋋";
}

.mdi-snowflake-off:before {
  content: "󱓣";
}

.mdi-snowflake-thermometer:before {
  content: "󱩱";
}

.mdi-snowflake-variant:before {
  content: "󰼪";
}

.mdi-snowman:before {
  content: "󰒷";
}

.mdi-snowmobile:before {
  content: "󰛝";
}

.mdi-snowshoeing:before {
  content: "󱩲";
}

.mdi-soccer:before {
  content: "󰒸";
}

.mdi-soccer-field:before {
  content: "󰠴";
}

.mdi-social-distance-2-meters:before {
  content: "󱕹";
}

.mdi-social-distance-6-feet:before {
  content: "󱕺";
}

.mdi-sofa:before {
  content: "󰒹";
}

.mdi-sofa-outline:before {
  content: "󱕭";
}

.mdi-sofa-single:before {
  content: "󱕮";
}

.mdi-sofa-single-outline:before {
  content: "󱕯";
}

.mdi-solar-panel:before {
  content: "󰶛";
}

.mdi-solar-panel-large:before {
  content: "󰶜";
}

.mdi-solar-power:before {
  content: "󰩲";
}

.mdi-solar-power-variant:before {
  content: "󱩳";
}

.mdi-solar-power-variant-outline:before {
  content: "󱩴";
}

.mdi-soldering-iron:before {
  content: "󱂒";
}

.mdi-solid:before {
  content: "󰚍";
}

.mdi-sony-playstation:before {
  content: "󰐔";
}

.mdi-sort:before {
  content: "󰒺";
}

.mdi-sort-alphabetical-ascending:before {
  content: "󰖽";
}

.mdi-sort-alphabetical-ascending-variant:before {
  content: "󱅈";
}

.mdi-sort-alphabetical-descending:before {
  content: "󰖿";
}

.mdi-sort-alphabetical-descending-variant:before {
  content: "󱅉";
}

.mdi-sort-alphabetical-variant:before {
  content: "󰒻";
}

.mdi-sort-ascending:before {
  content: "󰒼";
}

.mdi-sort-bool-ascending:before {
  content: "󱎅";
}

.mdi-sort-bool-ascending-variant:before {
  content: "󱎆";
}

.mdi-sort-bool-descending:before {
  content: "󱎇";
}

.mdi-sort-bool-descending-variant:before {
  content: "󱎈";
}

.mdi-sort-calendar-ascending:before {
  content: "󱕇";
}

.mdi-sort-calendar-descending:before {
  content: "󱕈";
}

.mdi-sort-clock-ascending:before {
  content: "󱕉";
}

.mdi-sort-clock-ascending-outline:before {
  content: "󱕊";
}

.mdi-sort-clock-descending:before {
  content: "󱕋";
}

.mdi-sort-clock-descending-outline:before {
  content: "󱕌";
}

.mdi-sort-descending:before {
  content: "󰒽";
}

.mdi-sort-numeric-ascending:before {
  content: "󱎉";
}

.mdi-sort-numeric-ascending-variant:before {
  content: "󰤍";
}

.mdi-sort-numeric-descending:before {
  content: "󱎊";
}

.mdi-sort-numeric-descending-variant:before {
  content: "󰫒";
}

.mdi-sort-numeric-variant:before {
  content: "󰒾";
}

.mdi-sort-reverse-variant:before {
  content: "󰌼";
}

.mdi-sort-variant:before {
  content: "󰒿";
}

.mdi-sort-variant-lock:before {
  content: "󰳍";
}

.mdi-sort-variant-lock-open:before {
  content: "󰳎";
}

.mdi-sort-variant-off:before {
  content: "󱪻";
}

.mdi-sort-variant-remove:before {
  content: "󱅇";
}

.mdi-soundbar:before {
  content: "󱟛";
}

.mdi-soundcloud:before {
  content: "󰓀";
}

.mdi-source-branch:before {
  content: "󰘬";
}

.mdi-source-branch-check:before {
  content: "󱓏";
}

.mdi-source-branch-minus:before {
  content: "󱓋";
}

.mdi-source-branch-plus:before {
  content: "󱓊";
}

.mdi-source-branch-refresh:before {
  content: "󱓍";
}

.mdi-source-branch-remove:before {
  content: "󱓌";
}

.mdi-source-branch-sync:before {
  content: "󱓎";
}

.mdi-source-commit:before {
  content: "󰜘";
}

.mdi-source-commit-end:before {
  content: "󰜙";
}

.mdi-source-commit-end-local:before {
  content: "󰜚";
}

.mdi-source-commit-local:before {
  content: "󰜛";
}

.mdi-source-commit-next-local:before {
  content: "󰜜";
}

.mdi-source-commit-start:before {
  content: "󰜝";
}

.mdi-source-commit-start-next-local:before {
  content: "󰜞";
}

.mdi-source-fork:before {
  content: "󰓁";
}

.mdi-source-merge:before {
  content: "󰘭";
}

.mdi-source-pull:before {
  content: "󰓂";
}

.mdi-source-repository:before {
  content: "󰳏";
}

.mdi-source-repository-multiple:before {
  content: "󰳐";
}

.mdi-soy-sauce:before {
  content: "󰟮";
}

.mdi-soy-sauce-off:before {
  content: "󱏼";
}

.mdi-spa:before {
  content: "󰳑";
}

.mdi-spa-outline:before {
  content: "󰳒";
}

.mdi-space-invaders:before {
  content: "󰯉";
}

.mdi-space-station:before {
  content: "󱎃";
}

.mdi-spade:before {
  content: "󰹥";
}

.mdi-speaker:before {
  content: "󰓃";
}

.mdi-speaker-bluetooth:before {
  content: "󰦢";
}

.mdi-speaker-message:before {
  content: "󱬑";
}

.mdi-speaker-multiple:before {
  content: "󰴸";
}

.mdi-speaker-off:before {
  content: "󰓄";
}

.mdi-speaker-pause:before {
  content: "󱭳";
}

.mdi-speaker-play:before {
  content: "󱭲";
}

.mdi-speaker-stop:before {
  content: "󱭴";
}

.mdi-speaker-wireless:before {
  content: "󰜟";
}

.mdi-spear:before {
  content: "󱡅";
}

.mdi-speedometer:before {
  content: "󰓅";
}

.mdi-speedometer-medium:before {
  content: "󰾅";
}

.mdi-speedometer-slow:before {
  content: "󰾆";
}

.mdi-spellcheck:before {
  content: "󰓆";
}

.mdi-sphere:before {
  content: "󱥔";
}

.mdi-sphere-off:before {
  content: "󱥕";
}

.mdi-spider:before {
  content: "󱇪";
}

.mdi-spider-outline:before {
  content: "󱱵";
}

.mdi-spider-thread:before {
  content: "󱇫";
}

.mdi-spider-web:before {
  content: "󰯊";
}

.mdi-spirit-level:before {
  content: "󱓱";
}

.mdi-spoon-sugar:before {
  content: "󱐩";
}

.mdi-spotify:before {
  content: "󰓇";
}

.mdi-spotlight:before {
  content: "󰓈";
}

.mdi-spotlight-beam:before {
  content: "󰓉";
}

.mdi-spray:before {
  content: "󰙥";
}

.mdi-spray-bottle:before {
  content: "󰫠";
}

.mdi-sprinkler:before {
  content: "󱁟";
}

.mdi-sprinkler-fire:before {
  content: "󱦝";
}

.mdi-sprinkler-variant:before {
  content: "󱁠";
}

.mdi-sprout:before {
  content: "󰹦";
}

.mdi-sprout-outline:before {
  content: "󰹧";
}

.mdi-square:before {
  content: "󰝤";
}

.mdi-square-circle:before {
  content: "󱔀";
}

.mdi-square-circle-outline:before {
  content: "󱱐";
}

.mdi-square-edit-outline:before {
  content: "󰤌";
}

.mdi-square-medium:before {
  content: "󰨓";
}

.mdi-square-medium-outline:before {
  content: "󰨔";
}

.mdi-square-off:before {
  content: "󱋮";
}

.mdi-square-off-outline:before {
  content: "󱋯";
}

.mdi-square-opacity:before {
  content: "󱡔";
}

.mdi-square-outline:before {
  content: "󰝣";
}

.mdi-square-root:before {
  content: "󰞄";
}

.mdi-square-root-box:before {
  content: "󰦣";
}

.mdi-square-rounded:before {
  content: "󱓻";
}

.mdi-square-rounded-badge:before {
  content: "󱨇";
}

.mdi-square-rounded-badge-outline:before {
  content: "󱨈";
}

.mdi-square-rounded-outline:before {
  content: "󱓼";
}

.mdi-square-small:before {
  content: "󰨕";
}

.mdi-square-wave:before {
  content: "󱑻";
}

.mdi-squeegee:before {
  content: "󰫡";
}

.mdi-ssh:before {
  content: "󰣀";
}

.mdi-stack-exchange:before {
  content: "󰘋";
}

.mdi-stack-overflow:before {
  content: "󰓌";
}

.mdi-stackpath:before {
  content: "󰍙";
}

.mdi-stadium:before {
  content: "󰿹";
}

.mdi-stadium-outline:before {
  content: "󱬃";
}

.mdi-stadium-variant:before {
  content: "󰜠";
}

.mdi-stairs:before {
  content: "󰓍";
}

.mdi-stairs-box:before {
  content: "󱎞";
}

.mdi-stairs-down:before {
  content: "󱊾";
}

.mdi-stairs-up:before {
  content: "󱊽";
}

.mdi-stamper:before {
  content: "󰴹";
}

.mdi-standard-definition:before {
  content: "󰟯";
}

.mdi-star:before {
  content: "󰓎";
}

.mdi-star-box:before {
  content: "󰩳";
}

.mdi-star-box-multiple:before {
  content: "󱊆";
}

.mdi-star-box-multiple-outline:before {
  content: "󱊇";
}

.mdi-star-box-outline:before {
  content: "󰩴";
}

.mdi-star-check:before {
  content: "󱕦";
}

.mdi-star-check-outline:before {
  content: "󱕪";
}

.mdi-star-circle:before {
  content: "󰓏";
}

.mdi-star-circle-outline:before {
  content: "󰦤";
}

.mdi-star-cog:before {
  content: "󱙨";
}

.mdi-star-cog-outline:before {
  content: "󱙩";
}

.mdi-star-crescent:before {
  content: "󰥹";
}

.mdi-star-david:before {
  content: "󰥺";
}

.mdi-star-face:before {
  content: "󰦥";
}

.mdi-star-four-points:before {
  content: "󰫢";
}

.mdi-star-four-points-box:before {
  content: "󱱑";
}

.mdi-star-four-points-box-outline:before {
  content: "󱱒";
}

.mdi-star-four-points-circle:before {
  content: "󱱓";
}

.mdi-star-four-points-circle-outline:before {
  content: "󱱔";
}

.mdi-star-four-points-outline:before {
  content: "󰫣";
}

.mdi-star-four-points-small:before {
  content: "󱱕";
}

.mdi-star-half:before {
  content: "󰉆";
}

.mdi-star-half-full:before {
  content: "󰓐";
}

.mdi-star-minus:before {
  content: "󱕤";
}

.mdi-star-minus-outline:before {
  content: "󱕨";
}

.mdi-star-off:before {
  content: "󰓑";
}

.mdi-star-off-outline:before {
  content: "󱕛";
}

.mdi-star-outline:before {
  content: "󰓒";
}

.mdi-star-plus:before {
  content: "󱕣";
}

.mdi-star-plus-outline:before {
  content: "󱕧";
}

.mdi-star-remove:before {
  content: "󱕥";
}

.mdi-star-remove-outline:before {
  content: "󱕩";
}

.mdi-star-settings:before {
  content: "󱙪";
}

.mdi-star-settings-outline:before {
  content: "󱙫";
}

.mdi-star-shooting:before {
  content: "󱝁";
}

.mdi-star-shooting-outline:before {
  content: "󱝂";
}

.mdi-star-three-points:before {
  content: "󰫤";
}

.mdi-star-three-points-outline:before {
  content: "󰫥";
}

.mdi-state-machine:before {
  content: "󱇯";
}

.mdi-steam:before {
  content: "󰓓";
}

.mdi-steering:before {
  content: "󰓔";
}

.mdi-steering-off:before {
  content: "󰤎";
}

.mdi-step-backward:before {
  content: "󰓕";
}

.mdi-step-backward-2:before {
  content: "󰓖";
}

.mdi-step-forward:before {
  content: "󰓗";
}

.mdi-step-forward-2:before {
  content: "󰓘";
}

.mdi-stethoscope:before {
  content: "󰓙";
}

.mdi-sticker:before {
  content: "󱍤";
}

.mdi-sticker-alert:before {
  content: "󱍥";
}

.mdi-sticker-alert-outline:before {
  content: "󱍦";
}

.mdi-sticker-check:before {
  content: "󱍧";
}

.mdi-sticker-check-outline:before {
  content: "󱍨";
}

.mdi-sticker-circle-outline:before {
  content: "󰗐";
}

.mdi-sticker-emoji:before {
  content: "󰞅";
}

.mdi-sticker-minus:before {
  content: "󱍩";
}

.mdi-sticker-minus-outline:before {
  content: "󱍪";
}

.mdi-sticker-outline:before {
  content: "󱍫";
}

.mdi-sticker-plus:before {
  content: "󱍬";
}

.mdi-sticker-plus-outline:before {
  content: "󱍭";
}

.mdi-sticker-remove:before {
  content: "󱍮";
}

.mdi-sticker-remove-outline:before {
  content: "󱍯";
}

.mdi-sticker-text:before {
  content: "󱞎";
}

.mdi-sticker-text-outline:before {
  content: "󱞏";
}

.mdi-stocking:before {
  content: "󰓚";
}

.mdi-stomach:before {
  content: "󱂓";
}

.mdi-stool:before {
  content: "󱥝";
}

.mdi-stool-outline:before {
  content: "󱥞";
}

.mdi-stop:before {
  content: "󰓛";
}

.mdi-stop-circle:before {
  content: "󰙦";
}

.mdi-stop-circle-outline:before {
  content: "󰙧";
}

.mdi-storage-tank:before {
  content: "󱩵";
}

.mdi-storage-tank-outline:before {
  content: "󱩶";
}

.mdi-store:before {
  content: "󰓜";
}

.mdi-store-24-hour:before {
  content: "󰓝";
}

.mdi-store-alert:before {
  content: "󱣁";
}

.mdi-store-alert-outline:before {
  content: "󱣂";
}

.mdi-store-check:before {
  content: "󱣃";
}

.mdi-store-check-outline:before {
  content: "󱣄";
}

.mdi-store-clock:before {
  content: "󱣅";
}

.mdi-store-clock-outline:before {
  content: "󱣆";
}

.mdi-store-cog:before {
  content: "󱣇";
}

.mdi-store-cog-outline:before {
  content: "󱣈";
}

.mdi-store-edit:before {
  content: "󱣉";
}

.mdi-store-edit-outline:before {
  content: "󱣊";
}

.mdi-store-marker:before {
  content: "󱣋";
}

.mdi-store-marker-outline:before {
  content: "󱣌";
}

.mdi-store-minus:before {
  content: "󱙞";
}

.mdi-store-minus-outline:before {
  content: "󱣍";
}

.mdi-store-off:before {
  content: "󱣎";
}

.mdi-store-off-outline:before {
  content: "󱣏";
}

.mdi-store-outline:before {
  content: "󱍡";
}

.mdi-store-plus:before {
  content: "󱙟";
}

.mdi-store-plus-outline:before {
  content: "󱣐";
}

.mdi-store-remove:before {
  content: "󱙠";
}

.mdi-store-remove-outline:before {
  content: "󱣑";
}

.mdi-store-search:before {
  content: "󱣒";
}

.mdi-store-search-outline:before {
  content: "󱣓";
}

.mdi-store-settings:before {
  content: "󱣔";
}

.mdi-store-settings-outline:before {
  content: "󱣕";
}

.mdi-storefront:before {
  content: "󰟇";
}

.mdi-storefront-check:before {
  content: "󱭽";
}

.mdi-storefront-check-outline:before {
  content: "󱭾";
}

.mdi-storefront-edit:before {
  content: "󱭿";
}

.mdi-storefront-edit-outline:before {
  content: "󱮀";
}

.mdi-storefront-minus:before {
  content: "󱮃";
}

.mdi-storefront-minus-outline:before {
  content: "󱮄";
}

.mdi-storefront-outline:before {
  content: "󱃁";
}

.mdi-storefront-plus:before {
  content: "󱮁";
}

.mdi-storefront-plus-outline:before {
  content: "󱮂";
}

.mdi-storefront-remove:before {
  content: "󱮅";
}

.mdi-storefront-remove-outline:before {
  content: "󱮆";
}

.mdi-stove:before {
  content: "󰓞";
}

.mdi-strategy:before {
  content: "󱇖";
}

.mdi-stretch-to-page:before {
  content: "󰼫";
}

.mdi-stretch-to-page-outline:before {
  content: "󰼬";
}

.mdi-string-lights:before {
  content: "󱊺";
}

.mdi-string-lights-off:before {
  content: "󱊻";
}

.mdi-subdirectory-arrow-left:before {
  content: "󰘌";
}

.mdi-subdirectory-arrow-right:before {
  content: "󰘍";
}

.mdi-submarine:before {
  content: "󱕬";
}

.mdi-subtitles:before {
  content: "󰨖";
}

.mdi-subtitles-outline:before {
  content: "󰨗";
}

.mdi-subway:before {
  content: "󰚬";
}

.mdi-subway-alert-variant:before {
  content: "󰶝";
}

.mdi-subway-variant:before {
  content: "󰓟";
}

.mdi-summit:before {
  content: "󰞆";
}

.mdi-sun-angle:before {
  content: "󱬧";
}

.mdi-sun-angle-outline:before {
  content: "󱬨";
}

.mdi-sun-clock:before {
  content: "󱩷";
}

.mdi-sun-clock-outline:before {
  content: "󱩸";
}

.mdi-sun-compass:before {
  content: "󱦥";
}

.mdi-sun-snowflake:before {
  content: "󱞖";
}

.mdi-sun-snowflake-variant:before {
  content: "󱩹";
}

.mdi-sun-thermometer:before {
  content: "󱣖";
}

.mdi-sun-thermometer-outline:before {
  content: "󱣗";
}

.mdi-sun-wireless:before {
  content: "󱟾";
}

.mdi-sun-wireless-outline:before {
  content: "󱟿";
}

.mdi-sunglasses:before {
  content: "󰓠";
}

.mdi-surfing:before {
  content: "󱝆";
}

.mdi-surround-sound:before {
  content: "󰗅";
}

.mdi-surround-sound-2-0:before {
  content: "󰟰";
}

.mdi-surround-sound-2-1:before {
  content: "󱜩";
}

.mdi-surround-sound-3-1:before {
  content: "󰟱";
}

.mdi-surround-sound-5-1:before {
  content: "󰟲";
}

.mdi-surround-sound-5-1-2:before {
  content: "󱜪";
}

.mdi-surround-sound-7-1:before {
  content: "󰟳";
}

.mdi-svg:before {
  content: "󰜡";
}

.mdi-swap-horizontal:before {
  content: "󰓡";
}

.mdi-swap-horizontal-bold:before {
  content: "󰯍";
}

.mdi-swap-horizontal-circle:before {
  content: "󰿡";
}

.mdi-swap-horizontal-circle-outline:before {
  content: "󰿢";
}

.mdi-swap-horizontal-variant:before {
  content: "󰣁";
}

.mdi-swap-vertical:before {
  content: "󰓢";
}

.mdi-swap-vertical-bold:before {
  content: "󰯎";
}

.mdi-swap-vertical-circle:before {
  content: "󰿣";
}

.mdi-swap-vertical-circle-outline:before {
  content: "󰿤";
}

.mdi-swap-vertical-variant:before {
  content: "󰣂";
}

.mdi-swim:before {
  content: "󰓣";
}

.mdi-switch:before {
  content: "󰓤";
}

.mdi-sword:before {
  content: "󰓥";
}

.mdi-sword-cross:before {
  content: "󰞇";
}

.mdi-syllabary-hangul:before {
  content: "󱌳";
}

.mdi-syllabary-hiragana:before {
  content: "󱌴";
}

.mdi-syllabary-katakana:before {
  content: "󱌵";
}

.mdi-syllabary-katakana-halfwidth:before {
  content: "󱌶";
}

.mdi-symbol:before {
  content: "󱔁";
}

.mdi-symfony:before {
  content: "󰫦";
}

.mdi-synagogue:before {
  content: "󱬄";
}

.mdi-synagogue-outline:before {
  content: "󱬅";
}

.mdi-sync:before {
  content: "󰓦";
}

.mdi-sync-alert:before {
  content: "󰓧";
}

.mdi-sync-circle:before {
  content: "󱍸";
}

.mdi-sync-off:before {
  content: "󰓨";
}

.mdi-tab:before {
  content: "󰓩";
}

.mdi-tab-minus:before {
  content: "󰭋";
}

.mdi-tab-plus:before {
  content: "󰝜";
}

.mdi-tab-remove:before {
  content: "󰭌";
}

.mdi-tab-search:before {
  content: "󱦞";
}

.mdi-tab-unselected:before {
  content: "󰓪";
}

.mdi-table:before {
  content: "󰓫";
}

.mdi-table-account:before {
  content: "󱎹";
}

.mdi-table-alert:before {
  content: "󱎺";
}

.mdi-table-arrow-down:before {
  content: "󱎻";
}

.mdi-table-arrow-left:before {
  content: "󱎼";
}

.mdi-table-arrow-right:before {
  content: "󱎽";
}

.mdi-table-arrow-up:before {
  content: "󱎾";
}

.mdi-table-border:before {
  content: "󰨘";
}

.mdi-table-cancel:before {
  content: "󱎿";
}

.mdi-table-chair:before {
  content: "󱁡";
}

.mdi-table-check:before {
  content: "󱏀";
}

.mdi-table-clock:before {
  content: "󱏁";
}

.mdi-table-cog:before {
  content: "󱏂";
}

.mdi-table-column:before {
  content: "󰠵";
}

.mdi-table-column-plus-after:before {
  content: "󰓬";
}

.mdi-table-column-plus-before:before {
  content: "󰓭";
}

.mdi-table-column-remove:before {
  content: "󰓮";
}

.mdi-table-column-width:before {
  content: "󰓯";
}

.mdi-table-edit:before {
  content: "󰓰";
}

.mdi-table-eye:before {
  content: "󱂔";
}

.mdi-table-eye-off:before {
  content: "󱏃";
}

.mdi-table-filter:before {
  content: "󱮌";
}

.mdi-table-furniture:before {
  content: "󰖼";
}

.mdi-table-headers-eye:before {
  content: "󱈝";
}

.mdi-table-headers-eye-off:before {
  content: "󱈞";
}

.mdi-table-heart:before {
  content: "󱏄";
}

.mdi-table-key:before {
  content: "󱏅";
}

.mdi-table-large:before {
  content: "󰓱";
}

.mdi-table-large-plus:before {
  content: "󰾇";
}

.mdi-table-large-remove:before {
  content: "󰾈";
}

.mdi-table-lock:before {
  content: "󱏆";
}

.mdi-table-merge-cells:before {
  content: "󰦦";
}

.mdi-table-minus:before {
  content: "󱏇";
}

.mdi-table-multiple:before {
  content: "󱏈";
}

.mdi-table-network:before {
  content: "󱏉";
}

.mdi-table-of-contents:before {
  content: "󰠶";
}

.mdi-table-off:before {
  content: "󱏊";
}

.mdi-table-picnic:before {
  content: "󱝃";
}

.mdi-table-pivot:before {
  content: "󱠼";
}

.mdi-table-plus:before {
  content: "󰩵";
}

.mdi-table-question:before {
  content: "󱬡";
}

.mdi-table-refresh:before {
  content: "󱎠";
}

.mdi-table-remove:before {
  content: "󰩶";
}

.mdi-table-row:before {
  content: "󰠷";
}

.mdi-table-row-height:before {
  content: "󰓲";
}

.mdi-table-row-plus-after:before {
  content: "󰓳";
}

.mdi-table-row-plus-before:before {
  content: "󰓴";
}

.mdi-table-row-remove:before {
  content: "󰓵";
}

.mdi-table-search:before {
  content: "󰤏";
}

.mdi-table-settings:before {
  content: "󰠸";
}

.mdi-table-split-cell:before {
  content: "󱐪";
}

.mdi-table-star:before {
  content: "󱏋";
}

.mdi-table-sync:before {
  content: "󱎡";
}

.mdi-table-tennis:before {
  content: "󰹨";
}

.mdi-tablet:before {
  content: "󰓶";
}

.mdi-tablet-cellphone:before {
  content: "󰦧";
}

.mdi-tablet-dashboard:before {
  content: "󰻎";
}

.mdi-taco:before {
  content: "󰝢";
}

.mdi-tag:before {
  content: "󰓹";
}

.mdi-tag-arrow-down:before {
  content: "󱜫";
}

.mdi-tag-arrow-down-outline:before {
  content: "󱜬";
}

.mdi-tag-arrow-left:before {
  content: "󱜭";
}

.mdi-tag-arrow-left-outline:before {
  content: "󱜮";
}

.mdi-tag-arrow-right:before {
  content: "󱜯";
}

.mdi-tag-arrow-right-outline:before {
  content: "󱜰";
}

.mdi-tag-arrow-up:before {
  content: "󱜱";
}

.mdi-tag-arrow-up-outline:before {
  content: "󱜲";
}

.mdi-tag-check:before {
  content: "󱩺";
}

.mdi-tag-check-outline:before {
  content: "󱩻";
}

.mdi-tag-faces:before {
  content: "󰓺";
}

.mdi-tag-heart:before {
  content: "󰚋";
}

.mdi-tag-heart-outline:before {
  content: "󰯏";
}

.mdi-tag-hidden:before {
  content: "󱱶";
}

.mdi-tag-minus:before {
  content: "󰤐";
}

.mdi-tag-minus-outline:before {
  content: "󱈟";
}

.mdi-tag-multiple:before {
  content: "󰓻";
}

.mdi-tag-multiple-outline:before {
  content: "󱋷";
}

.mdi-tag-off:before {
  content: "󱈠";
}

.mdi-tag-off-outline:before {
  content: "󱈡";
}

.mdi-tag-outline:before {
  content: "󰓼";
}

.mdi-tag-plus:before {
  content: "󰜢";
}

.mdi-tag-plus-outline:before {
  content: "󱈢";
}

.mdi-tag-remove:before {
  content: "󰜣";
}

.mdi-tag-remove-outline:before {
  content: "󱈣";
}

.mdi-tag-search:before {
  content: "󱤇";
}

.mdi-tag-search-outline:before {
  content: "󱤈";
}

.mdi-tag-text:before {
  content: "󱈤";
}

.mdi-tag-text-outline:before {
  content: "󰓽";
}

.mdi-tailwind:before {
  content: "󱏿";
}

.mdi-tally-mark-1:before {
  content: "󱪼";
}

.mdi-tally-mark-2:before {
  content: "󱪽";
}

.mdi-tally-mark-3:before {
  content: "󱪾";
}

.mdi-tally-mark-4:before {
  content: "󱪿";
}

.mdi-tally-mark-5:before {
  content: "󱫀";
}

.mdi-tangram:before {
  content: "󰓸";
}

.mdi-tank:before {
  content: "󰴺";
}

.mdi-tanker-truck:before {
  content: "󰿥";
}

.mdi-tape-drive:before {
  content: "󱛟";
}

.mdi-tape-measure:before {
  content: "󰭍";
}

.mdi-target:before {
  content: "󰓾";
}

.mdi-target-account:before {
  content: "󰯐";
}

.mdi-target-variant:before {
  content: "󰩷";
}

.mdi-taxi:before {
  content: "󰓿";
}

.mdi-tea:before {
  content: "󰶞";
}

.mdi-tea-outline:before {
  content: "󰶟";
}

.mdi-teamviewer:before {
  content: "󰔀";
}

.mdi-teddy-bear:before {
  content: "󱣻";
}

.mdi-telescope:before {
  content: "󰭎";
}

.mdi-television:before {
  content: "󰔂";
}

.mdi-television-ambient-light:before {
  content: "󱍖";
}

.mdi-television-box:before {
  content: "󰠹";
}

.mdi-television-classic:before {
  content: "󰟴";
}

.mdi-television-classic-off:before {
  content: "󰠺";
}

.mdi-television-guide:before {
  content: "󰔃";
}

.mdi-television-off:before {
  content: "󰠻";
}

.mdi-television-pause:before {
  content: "󰾉";
}

.mdi-television-play:before {
  content: "󰻏";
}

.mdi-television-shimmer:before {
  content: "󱄐";
}

.mdi-television-speaker:before {
  content: "󱬛";
}

.mdi-television-speaker-off:before {
  content: "󱬜";
}

.mdi-television-stop:before {
  content: "󰾊";
}

.mdi-temperature-celsius:before {
  content: "󰔄";
}

.mdi-temperature-fahrenheit:before {
  content: "󰔅";
}

.mdi-temperature-kelvin:before {
  content: "󰔆";
}

.mdi-temple-buddhist:before {
  content: "󱬆";
}

.mdi-temple-buddhist-outline:before {
  content: "󱬇";
}

.mdi-temple-hindu:before {
  content: "󱬈";
}

.mdi-temple-hindu-outline:before {
  content: "󱬉";
}

.mdi-tennis:before {
  content: "󰶠";
}

.mdi-tennis-ball:before {
  content: "󰔇";
}

.mdi-tennis-ball-outline:before {
  content: "󱱟";
}

.mdi-tent:before {
  content: "󰔈";
}

.mdi-terraform:before {
  content: "󱁢";
}

.mdi-terrain:before {
  content: "󰔉";
}

.mdi-test-tube:before {
  content: "󰙨";
}

.mdi-test-tube-empty:before {
  content: "󰤑";
}

.mdi-test-tube-off:before {
  content: "󰤒";
}

.mdi-text:before {
  content: "󰦨";
}

.mdi-text-account:before {
  content: "󱕰";
}

.mdi-text-box:before {
  content: "󰈚";
}

.mdi-text-box-check:before {
  content: "󰺦";
}

.mdi-text-box-check-outline:before {
  content: "󰺧";
}

.mdi-text-box-edit:before {
  content: "󱩼";
}

.mdi-text-box-edit-outline:before {
  content: "󱩽";
}

.mdi-text-box-minus:before {
  content: "󰺨";
}

.mdi-text-box-minus-outline:before {
  content: "󰺩";
}

.mdi-text-box-multiple:before {
  content: "󰪷";
}

.mdi-text-box-multiple-outline:before {
  content: "󰪸";
}

.mdi-text-box-outline:before {
  content: "󰧭";
}

.mdi-text-box-plus:before {
  content: "󰺪";
}

.mdi-text-box-plus-outline:before {
  content: "󰺫";
}

.mdi-text-box-remove:before {
  content: "󰺬";
}

.mdi-text-box-remove-outline:before {
  content: "󰺭";
}

.mdi-text-box-search:before {
  content: "󰺮";
}

.mdi-text-box-search-outline:before {
  content: "󰺯";
}

.mdi-text-long:before {
  content: "󰦪";
}

.mdi-text-recognition:before {
  content: "󱄽";
}

.mdi-text-search:before {
  content: "󱎸";
}

.mdi-text-search-variant:before {
  content: "󱩾";
}

.mdi-text-shadow:before {
  content: "󰙩";
}

.mdi-text-short:before {
  content: "󰦩";
}

.mdi-texture:before {
  content: "󰔌";
}

.mdi-texture-box:before {
  content: "󰿦";
}

.mdi-theater:before {
  content: "󰔍";
}

.mdi-theme-light-dark:before {
  content: "󰔎";
}

.mdi-thermometer:before {
  content: "󰔏";
}

.mdi-thermometer-alert:before {
  content: "󰸁";
}

.mdi-thermometer-auto:before {
  content: "󱬏";
}

.mdi-thermometer-bluetooth:before {
  content: "󱢕";
}

.mdi-thermometer-check:before {
  content: "󱩿";
}

.mdi-thermometer-chevron-down:before {
  content: "󰸂";
}

.mdi-thermometer-chevron-up:before {
  content: "󰸃";
}

.mdi-thermometer-high:before {
  content: "󱃂";
}

.mdi-thermometer-lines:before {
  content: "󰔐";
}

.mdi-thermometer-low:before {
  content: "󱃃";
}

.mdi-thermometer-minus:before {
  content: "󰸄";
}

.mdi-thermometer-off:before {
  content: "󱔱";
}

.mdi-thermometer-plus:before {
  content: "󰸅";
}

.mdi-thermometer-probe:before {
  content: "󱬫";
}

.mdi-thermometer-probe-off:before {
  content: "󱬬";
}

.mdi-thermometer-water:before {
  content: "󱪀";
}

.mdi-thermostat:before {
  content: "󰎓";
}

.mdi-thermostat-auto:before {
  content: "󱬗";
}

.mdi-thermostat-box:before {
  content: "󰢑";
}

.mdi-thermostat-box-auto:before {
  content: "󱬘";
}

.mdi-thermostat-cog:before {
  content: "󱲀";
}

.mdi-thought-bubble:before {
  content: "󰟶";
}

.mdi-thought-bubble-outline:before {
  content: "󰟷";
}

.mdi-thumb-down:before {
  content: "󰔑";
}

.mdi-thumb-down-outline:before {
  content: "󰔒";
}

.mdi-thumb-up:before {
  content: "󰔓";
}

.mdi-thumb-up-outline:before {
  content: "󰔔";
}

.mdi-thumbs-up-down:before {
  content: "󰔕";
}

.mdi-thumbs-up-down-outline:before {
  content: "󱤔";
}

.mdi-ticket:before {
  content: "󰔖";
}

.mdi-ticket-account:before {
  content: "󰔗";
}

.mdi-ticket-confirmation:before {
  content: "󰔘";
}

.mdi-ticket-confirmation-outline:before {
  content: "󱎪";
}

.mdi-ticket-outline:before {
  content: "󰤓";
}

.mdi-ticket-percent:before {
  content: "󰜤";
}

.mdi-ticket-percent-outline:before {
  content: "󱐫";
}

.mdi-tie:before {
  content: "󰔙";
}

.mdi-tilde:before {
  content: "󰜥";
}

.mdi-tilde-off:before {
  content: "󱣳";
}

.mdi-timelapse:before {
  content: "󰔚";
}

.mdi-timeline:before {
  content: "󰯑";
}

.mdi-timeline-alert:before {
  content: "󰾕";
}

.mdi-timeline-alert-outline:before {
  content: "󰾘";
}

.mdi-timeline-check:before {
  content: "󱔲";
}

.mdi-timeline-check-outline:before {
  content: "󱔳";
}

.mdi-timeline-clock:before {
  content: "󱇻";
}

.mdi-timeline-clock-outline:before {
  content: "󱇼";
}

.mdi-timeline-minus:before {
  content: "󱔴";
}

.mdi-timeline-minus-outline:before {
  content: "󱔵";
}

.mdi-timeline-outline:before {
  content: "󰯒";
}

.mdi-timeline-plus:before {
  content: "󰾖";
}

.mdi-timeline-plus-outline:before {
  content: "󰾗";
}

.mdi-timeline-question:before {
  content: "󰾙";
}

.mdi-timeline-question-outline:before {
  content: "󰾚";
}

.mdi-timeline-remove:before {
  content: "󱔶";
}

.mdi-timeline-remove-outline:before {
  content: "󱔷";
}

.mdi-timeline-text:before {
  content: "󰯓";
}

.mdi-timeline-text-outline:before {
  content: "󰯔";
}

.mdi-timer:before {
  content: "󱎫";
}

.mdi-timer-10:before {
  content: "󰔜";
}

.mdi-timer-3:before {
  content: "󰔝";
}

.mdi-timer-alert:before {
  content: "󱫌";
}

.mdi-timer-alert-outline:before {
  content: "󱫍";
}

.mdi-timer-cancel:before {
  content: "󱫎";
}

.mdi-timer-cancel-outline:before {
  content: "󱫏";
}

.mdi-timer-check:before {
  content: "󱫐";
}

.mdi-timer-check-outline:before {
  content: "󱫑";
}

.mdi-timer-cog:before {
  content: "󱤥";
}

.mdi-timer-cog-outline:before {
  content: "󱤦";
}

.mdi-timer-edit:before {
  content: "󱫒";
}

.mdi-timer-edit-outline:before {
  content: "󱫓";
}

.mdi-timer-lock:before {
  content: "󱫔";
}

.mdi-timer-lock-open:before {
  content: "󱫕";
}

.mdi-timer-lock-open-outline:before {
  content: "󱫖";
}

.mdi-timer-lock-outline:before {
  content: "󱫗";
}

.mdi-timer-marker:before {
  content: "󱫘";
}

.mdi-timer-marker-outline:before {
  content: "󱫙";
}

.mdi-timer-minus:before {
  content: "󱫚";
}

.mdi-timer-minus-outline:before {
  content: "󱫛";
}

.mdi-timer-music:before {
  content: "󱫜";
}

.mdi-timer-music-outline:before {
  content: "󱫝";
}

.mdi-timer-off:before {
  content: "󱎬";
}

.mdi-timer-off-outline:before {
  content: "󰔞";
}

.mdi-timer-outline:before {
  content: "󰔛";
}

.mdi-timer-pause:before {
  content: "󱫞";
}

.mdi-timer-pause-outline:before {
  content: "󱫟";
}

.mdi-timer-play:before {
  content: "󱫠";
}

.mdi-timer-play-outline:before {
  content: "󱫡";
}

.mdi-timer-plus:before {
  content: "󱫢";
}

.mdi-timer-plus-outline:before {
  content: "󱫣";
}

.mdi-timer-refresh:before {
  content: "󱫤";
}

.mdi-timer-refresh-outline:before {
  content: "󱫥";
}

.mdi-timer-remove:before {
  content: "󱫦";
}

.mdi-timer-remove-outline:before {
  content: "󱫧";
}

.mdi-timer-sand:before {
  content: "󰔟";
}

.mdi-timer-sand-complete:before {
  content: "󱦟";
}

.mdi-timer-sand-empty:before {
  content: "󰚭";
}

.mdi-timer-sand-full:before {
  content: "󰞌";
}

.mdi-timer-sand-paused:before {
  content: "󱦠";
}

.mdi-timer-settings:before {
  content: "󱤣";
}

.mdi-timer-settings-outline:before {
  content: "󱤤";
}

.mdi-timer-star:before {
  content: "󱫨";
}

.mdi-timer-star-outline:before {
  content: "󱫩";
}

.mdi-timer-stop:before {
  content: "󱫪";
}

.mdi-timer-stop-outline:before {
  content: "󱫫";
}

.mdi-timer-sync:before {
  content: "󱫬";
}

.mdi-timer-sync-outline:before {
  content: "󱫭";
}

.mdi-timetable:before {
  content: "󰔠";
}

.mdi-tire:before {
  content: "󱢖";
}

.mdi-toaster:before {
  content: "󱁣";
}

.mdi-toaster-off:before {
  content: "󱆷";
}

.mdi-toaster-oven:before {
  content: "󰳓";
}

.mdi-toggle-switch:before {
  content: "󰔡";
}

.mdi-toggle-switch-off:before {
  content: "󰔢";
}

.mdi-toggle-switch-off-outline:before {
  content: "󰨙";
}

.mdi-toggle-switch-outline:before {
  content: "󰨚";
}

.mdi-toggle-switch-variant:before {
  content: "󱨥";
}

.mdi-toggle-switch-variant-off:before {
  content: "󱨦";
}

.mdi-toilet:before {
  content: "󰦫";
}

.mdi-toolbox:before {
  content: "󰦬";
}

.mdi-toolbox-outline:before {
  content: "󰦭";
}

.mdi-tools:before {
  content: "󱁤";
}

.mdi-tooltip:before {
  content: "󰔣";
}

.mdi-tooltip-account:before {
  content: "󰀌";
}

.mdi-tooltip-cellphone:before {
  content: "󱠻";
}

.mdi-tooltip-check:before {
  content: "󱕜";
}

.mdi-tooltip-check-outline:before {
  content: "󱕝";
}

.mdi-tooltip-edit:before {
  content: "󰔤";
}

.mdi-tooltip-edit-outline:before {
  content: "󱋅";
}

.mdi-tooltip-image:before {
  content: "󰔥";
}

.mdi-tooltip-image-outline:before {
  content: "󰯕";
}

.mdi-tooltip-minus:before {
  content: "󱕞";
}

.mdi-tooltip-minus-outline:before {
  content: "󱕟";
}

.mdi-tooltip-outline:before {
  content: "󰔦";
}

.mdi-tooltip-plus:before {
  content: "󰯖";
}

.mdi-tooltip-plus-outline:before {
  content: "󰔧";
}

.mdi-tooltip-question:before {
  content: "󱮺";
}

.mdi-tooltip-question-outline:before {
  content: "󱮻";
}

.mdi-tooltip-remove:before {
  content: "󱕠";
}

.mdi-tooltip-remove-outline:before {
  content: "󱕡";
}

.mdi-tooltip-text:before {
  content: "󰔨";
}

.mdi-tooltip-text-outline:before {
  content: "󰯗";
}

.mdi-tooth:before {
  content: "󰣃";
}

.mdi-tooth-outline:before {
  content: "󰔩";
}

.mdi-toothbrush:before {
  content: "󱄩";
}

.mdi-toothbrush-electric:before {
  content: "󱄬";
}

.mdi-toothbrush-paste:before {
  content: "󱄪";
}

.mdi-torch:before {
  content: "󱘆";
}

.mdi-tortoise:before {
  content: "󰴻";
}

.mdi-toslink:before {
  content: "󱊸";
}

.mdi-touch-text-outline:before {
  content: "󱱠";
}

.mdi-tournament:before {
  content: "󰦮";
}

.mdi-tow-truck:before {
  content: "󰠼";
}

.mdi-tower-beach:before {
  content: "󰚁";
}

.mdi-tower-fire:before {
  content: "󰚂";
}

.mdi-town-hall:before {
  content: "󱡵";
}

.mdi-toy-brick:before {
  content: "󱊈";
}

.mdi-toy-brick-marker:before {
  content: "󱊉";
}

.mdi-toy-brick-marker-outline:before {
  content: "󱊊";
}

.mdi-toy-brick-minus:before {
  content: "󱊋";
}

.mdi-toy-brick-minus-outline:before {
  content: "󱊌";
}

.mdi-toy-brick-outline:before {
  content: "󱊍";
}

.mdi-toy-brick-plus:before {
  content: "󱊎";
}

.mdi-toy-brick-plus-outline:before {
  content: "󱊏";
}

.mdi-toy-brick-remove:before {
  content: "󱊐";
}

.mdi-toy-brick-remove-outline:before {
  content: "󱊑";
}

.mdi-toy-brick-search:before {
  content: "󱊒";
}

.mdi-toy-brick-search-outline:before {
  content: "󱊓";
}

.mdi-track-light:before {
  content: "󰤔";
}

.mdi-track-light-off:before {
  content: "󱬁";
}

.mdi-trackpad:before {
  content: "󰟸";
}

.mdi-trackpad-lock:before {
  content: "󰤳";
}

.mdi-tractor:before {
  content: "󰢒";
}

.mdi-tractor-variant:before {
  content: "󱓄";
}

.mdi-trademark:before {
  content: "󰩸";
}

.mdi-traffic-cone:before {
  content: "󱍼";
}

.mdi-traffic-light:before {
  content: "󰔫";
}

.mdi-traffic-light-outline:before {
  content: "󱠪";
}

.mdi-train:before {
  content: "󰔬";
}

.mdi-train-car:before {
  content: "󰯘";
}

.mdi-train-car-autorack:before {
  content: "󱬭";
}

.mdi-train-car-box:before {
  content: "󱬮";
}

.mdi-train-car-box-full:before {
  content: "󱬯";
}

.mdi-train-car-box-open:before {
  content: "󱬰";
}

.mdi-train-car-caboose:before {
  content: "󱬱";
}

.mdi-train-car-centerbeam:before {
  content: "󱬲";
}

.mdi-train-car-centerbeam-full:before {
  content: "󱬳";
}

.mdi-train-car-container:before {
  content: "󱬴";
}

.mdi-train-car-flatbed:before {
  content: "󱬵";
}

.mdi-train-car-flatbed-car:before {
  content: "󱬶";
}

.mdi-train-car-flatbed-tank:before {
  content: "󱬷";
}

.mdi-train-car-gondola:before {
  content: "󱬸";
}

.mdi-train-car-gondola-full:before {
  content: "󱬹";
}

.mdi-train-car-hopper:before {
  content: "󱬺";
}

.mdi-train-car-hopper-covered:before {
  content: "󱬻";
}

.mdi-train-car-hopper-full:before {
  content: "󱬼";
}

.mdi-train-car-intermodal:before {
  content: "󱬽";
}

.mdi-train-car-passenger:before {
  content: "󱜳";
}

.mdi-train-car-passenger-door:before {
  content: "󱜴";
}

.mdi-train-car-passenger-door-open:before {
  content: "󱜵";
}

.mdi-train-car-passenger-variant:before {
  content: "󱜶";
}

.mdi-train-car-tank:before {
  content: "󱬾";
}

.mdi-train-variant:before {
  content: "󰣄";
}

.mdi-tram:before {
  content: "󰔭";
}

.mdi-tram-side:before {
  content: "󰿧";
}

.mdi-transcribe:before {
  content: "󰔮";
}

.mdi-transcribe-close:before {
  content: "󰔯";
}

.mdi-transfer:before {
  content: "󱁥";
}

.mdi-transfer-down:before {
  content: "󰶡";
}

.mdi-transfer-left:before {
  content: "󰶢";
}

.mdi-transfer-right:before {
  content: "󰔰";
}

.mdi-transfer-up:before {
  content: "󰶣";
}

.mdi-transit-connection:before {
  content: "󰴼";
}

.mdi-transit-connection-horizontal:before {
  content: "󱕆";
}

.mdi-transit-connection-variant:before {
  content: "󰴽";
}

.mdi-transit-detour:before {
  content: "󰾋";
}

.mdi-transit-skip:before {
  content: "󱔕";
}

.mdi-transit-transfer:before {
  content: "󰚮";
}

.mdi-transition:before {
  content: "󰤕";
}

.mdi-transition-masked:before {
  content: "󰤖";
}

.mdi-translate:before {
  content: "󰗊";
}

.mdi-translate-off:before {
  content: "󰸆";
}

.mdi-translate-variant:before {
  content: "󱮙";
}

.mdi-transmission-tower:before {
  content: "󰴾";
}

.mdi-transmission-tower-export:before {
  content: "󱤬";
}

.mdi-transmission-tower-import:before {
  content: "󱤭";
}

.mdi-transmission-tower-off:before {
  content: "󱧝";
}

.mdi-trash-can:before {
  content: "󰩹";
}

.mdi-trash-can-outline:before {
  content: "󰩺";
}

.mdi-tray:before {
  content: "󱊔";
}

.mdi-tray-alert:before {
  content: "󱊕";
}

.mdi-tray-arrow-down:before {
  content: "󰄠";
}

.mdi-tray-arrow-up:before {
  content: "󰄝";
}

.mdi-tray-full:before {
  content: "󱊖";
}

.mdi-tray-minus:before {
  content: "󱊗";
}

.mdi-tray-plus:before {
  content: "󱊘";
}

.mdi-tray-remove:before {
  content: "󱊙";
}

.mdi-treasure-chest:before {
  content: "󰜦";
}

.mdi-treasure-chest-outline:before {
  content: "󱱷";
}

.mdi-tree:before {
  content: "󰔱";
}

.mdi-tree-outline:before {
  content: "󰹩";
}

.mdi-trello:before {
  content: "󰔲";
}

.mdi-trending-down:before {
  content: "󰔳";
}

.mdi-trending-neutral:before {
  content: "󰔴";
}

.mdi-trending-up:before {
  content: "󰔵";
}

.mdi-triangle:before {
  content: "󰔶";
}

.mdi-triangle-down:before {
  content: "󱱖";
}

.mdi-triangle-down-outline:before {
  content: "󱱗";
}

.mdi-triangle-outline:before {
  content: "󰔷";
}

.mdi-triangle-small-down:before {
  content: "󱨉";
}

.mdi-triangle-small-up:before {
  content: "󱨊";
}

.mdi-triangle-wave:before {
  content: "󱑼";
}

.mdi-triforce:before {
  content: "󰯙";
}

.mdi-trophy:before {
  content: "󰔸";
}

.mdi-trophy-award:before {
  content: "󰔹";
}

.mdi-trophy-broken:before {
  content: "󰶤";
}

.mdi-trophy-outline:before {
  content: "󰔺";
}

.mdi-trophy-variant:before {
  content: "󰔻";
}

.mdi-trophy-variant-outline:before {
  content: "󰔼";
}

.mdi-truck:before {
  content: "󰔽";
}

.mdi-truck-alert:before {
  content: "󱧞";
}

.mdi-truck-alert-outline:before {
  content: "󱧟";
}

.mdi-truck-cargo-container:before {
  content: "󱣘";
}

.mdi-truck-check:before {
  content: "󰳔";
}

.mdi-truck-check-outline:before {
  content: "󱊚";
}

.mdi-truck-delivery:before {
  content: "󰔾";
}

.mdi-truck-delivery-outline:before {
  content: "󱊛";
}

.mdi-truck-fast:before {
  content: "󰞈";
}

.mdi-truck-fast-outline:before {
  content: "󱊜";
}

.mdi-truck-flatbed:before {
  content: "󱢑";
}

.mdi-truck-minus:before {
  content: "󱦮";
}

.mdi-truck-minus-outline:before {
  content: "󱦽";
}

.mdi-truck-outline:before {
  content: "󱊝";
}

.mdi-truck-plus:before {
  content: "󱦭";
}

.mdi-truck-plus-outline:before {
  content: "󱦼";
}

.mdi-truck-remove:before {
  content: "󱦯";
}

.mdi-truck-remove-outline:before {
  content: "󱦾";
}

.mdi-truck-snowflake:before {
  content: "󱦦";
}

.mdi-truck-trailer:before {
  content: "󰜧";
}

.mdi-trumpet:before {
  content: "󱂖";
}

.mdi-tshirt-crew:before {
  content: "󰩻";
}

.mdi-tshirt-crew-outline:before {
  content: "󰔿";
}

.mdi-tshirt-v:before {
  content: "󰩼";
}

.mdi-tshirt-v-outline:before {
  content: "󰕀";
}

.mdi-tsunami:before {
  content: "󱪁";
}

.mdi-tumble-dryer:before {
  content: "󰤗";
}

.mdi-tumble-dryer-alert:before {
  content: "󱆺";
}

.mdi-tumble-dryer-off:before {
  content: "󱆻";
}

.mdi-tune:before {
  content: "󰘮";
}

.mdi-tune-variant:before {
  content: "󱕂";
}

.mdi-tune-vertical:before {
  content: "󰙪";
}

.mdi-tune-vertical-variant:before {
  content: "󱕃";
}

.mdi-tunnel:before {
  content: "󱠽";
}

.mdi-tunnel-outline:before {
  content: "󱠾";
}

.mdi-turbine:before {
  content: "󱪂";
}

.mdi-turkey:before {
  content: "󱜛";
}

.mdi-turnstile:before {
  content: "󰳕";
}

.mdi-turnstile-outline:before {
  content: "󰳖";
}

.mdi-turtle:before {
  content: "󰳗";
}

.mdi-twitch:before {
  content: "󰕃";
}

.mdi-twitter:before {
  content: "󰕄";
}

.mdi-two-factor-authentication:before {
  content: "󰦯";
}

.mdi-typewriter:before {
  content: "󰼭";
}

.mdi-ubisoft:before {
  content: "󰯚";
}

.mdi-ubuntu:before {
  content: "󰕈";
}

.mdi-ufo:before {
  content: "󱃄";
}

.mdi-ufo-outline:before {
  content: "󱃅";
}

.mdi-ultra-high-definition:before {
  content: "󰟹";
}

.mdi-umbraco:before {
  content: "󰕉";
}

.mdi-umbrella:before {
  content: "󰕊";
}

.mdi-umbrella-beach:before {
  content: "󱢊";
}

.mdi-umbrella-beach-outline:before {
  content: "󱢋";
}

.mdi-umbrella-closed:before {
  content: "󰦰";
}

.mdi-umbrella-closed-outline:before {
  content: "󱏢";
}

.mdi-umbrella-closed-variant:before {
  content: "󱏡";
}

.mdi-umbrella-outline:before {
  content: "󰕋";
}

.mdi-undo:before {
  content: "󰕌";
}

.mdi-undo-variant:before {
  content: "󰕍";
}

.mdi-unfold-less-horizontal:before {
  content: "󰕎";
}

.mdi-unfold-less-vertical:before {
  content: "󰝠";
}

.mdi-unfold-more-horizontal:before {
  content: "󰕏";
}

.mdi-unfold-more-vertical:before {
  content: "󰝡";
}

.mdi-ungroup:before {
  content: "󰕐";
}

.mdi-unicode:before {
  content: "󰻐";
}

.mdi-unicorn:before {
  content: "󱗂";
}

.mdi-unicorn-variant:before {
  content: "󱗃";
}

.mdi-unicycle:before {
  content: "󱗥";
}

.mdi-unity:before {
  content: "󰚯";
}

.mdi-unreal:before {
  content: "󰦱";
}

.mdi-update:before {
  content: "󰚰";
}

.mdi-upload:before {
  content: "󰕒";
}

.mdi-upload-lock:before {
  content: "󱍳";
}

.mdi-upload-lock-outline:before {
  content: "󱍴";
}

.mdi-upload-multiple:before {
  content: "󰠽";
}

.mdi-upload-network:before {
  content: "󰛶";
}

.mdi-upload-network-outline:before {
  content: "󰳘";
}

.mdi-upload-off:before {
  content: "󱃆";
}

.mdi-upload-off-outline:before {
  content: "󱃇";
}

.mdi-upload-outline:before {
  content: "󰸇";
}

.mdi-usb:before {
  content: "󰕓";
}

.mdi-usb-flash-drive:before {
  content: "󱊞";
}

.mdi-usb-flash-drive-outline:before {
  content: "󱊟";
}

.mdi-usb-port:before {
  content: "󱇰";
}

.mdi-vacuum:before {
  content: "󱦡";
}

.mdi-vacuum-outline:before {
  content: "󱦢";
}

.mdi-valve:before {
  content: "󱁦";
}

.mdi-valve-closed:before {
  content: "󱁧";
}

.mdi-valve-open:before {
  content: "󱁨";
}

.mdi-van-passenger:before {
  content: "󰟺";
}

.mdi-van-utility:before {
  content: "󰟻";
}

.mdi-vanish:before {
  content: "󰟼";
}

.mdi-vanish-quarter:before {
  content: "󱕔";
}

.mdi-vanity-light:before {
  content: "󱇡";
}

.mdi-variable:before {
  content: "󰫧";
}

.mdi-variable-box:before {
  content: "󱄑";
}

.mdi-vector-arrange-above:before {
  content: "󰕔";
}

.mdi-vector-arrange-below:before {
  content: "󰕕";
}

.mdi-vector-bezier:before {
  content: "󰫨";
}

.mdi-vector-circle:before {
  content: "󰕖";
}

.mdi-vector-circle-variant:before {
  content: "󰕗";
}

.mdi-vector-combine:before {
  content: "󰕘";
}

.mdi-vector-curve:before {
  content: "󰕙";
}

.mdi-vector-difference:before {
  content: "󰕚";
}

.mdi-vector-difference-ab:before {
  content: "󰕛";
}

.mdi-vector-difference-ba:before {
  content: "󰕜";
}

.mdi-vector-ellipse:before {
  content: "󰢓";
}

.mdi-vector-intersection:before {
  content: "󰕝";
}

.mdi-vector-line:before {
  content: "󰕞";
}

.mdi-vector-link:before {
  content: "󰿨";
}

.mdi-vector-point:before {
  content: "󰇄";
}

.mdi-vector-point-edit:before {
  content: "󰧨";
}

.mdi-vector-point-minus:before {
  content: "󱭸";
}

.mdi-vector-point-plus:before {
  content: "󱭹";
}

.mdi-vector-point-select:before {
  content: "󰕟";
}

.mdi-vector-polygon:before {
  content: "󰕠";
}

.mdi-vector-polygon-variant:before {
  content: "󱡖";
}

.mdi-vector-polyline:before {
  content: "󰕡";
}

.mdi-vector-polyline-edit:before {
  content: "󱈥";
}

.mdi-vector-polyline-minus:before {
  content: "󱈦";
}

.mdi-vector-polyline-plus:before {
  content: "󱈧";
}

.mdi-vector-polyline-remove:before {
  content: "󱈨";
}

.mdi-vector-radius:before {
  content: "󰝊";
}

.mdi-vector-rectangle:before {
  content: "󰗆";
}

.mdi-vector-selection:before {
  content: "󰕢";
}

.mdi-vector-square:before {
  content: "󰀁";
}

.mdi-vector-square-close:before {
  content: "󱡗";
}

.mdi-vector-square-edit:before {
  content: "󱣙";
}

.mdi-vector-square-minus:before {
  content: "󱣚";
}

.mdi-vector-square-open:before {
  content: "󱡘";
}

.mdi-vector-square-plus:before {
  content: "󱣛";
}

.mdi-vector-square-remove:before {
  content: "󱣜";
}

.mdi-vector-triangle:before {
  content: "󰕣";
}

.mdi-vector-union:before {
  content: "󰕤";
}

.mdi-vhs:before {
  content: "󰨛";
}

.mdi-vibrate:before {
  content: "󰕦";
}

.mdi-vibrate-off:before {
  content: "󰳙";
}

.mdi-video:before {
  content: "󰕧";
}

.mdi-video-2d:before {
  content: "󱨜";
}

.mdi-video-3d:before {
  content: "󰟽";
}

.mdi-video-3d-off:before {
  content: "󱏙";
}

.mdi-video-3d-variant:before {
  content: "󰻑";
}

.mdi-video-4k-box:before {
  content: "󰠾";
}

.mdi-video-account:before {
  content: "󰤙";
}

.mdi-video-box:before {
  content: "󰃽";
}

.mdi-video-box-off:before {
  content: "󰃾";
}

.mdi-video-check:before {
  content: "󱁩";
}

.mdi-video-check-outline:before {
  content: "󱁪";
}

.mdi-video-high-definition:before {
  content: "󱔮";
}

.mdi-video-image:before {
  content: "󰤚";
}

.mdi-video-input-antenna:before {
  content: "󰠿";
}

.mdi-video-input-component:before {
  content: "󰡀";
}

.mdi-video-input-hdmi:before {
  content: "󰡁";
}

.mdi-video-input-scart:before {
  content: "󰾌";
}

.mdi-video-input-svideo:before {
  content: "󰡂";
}

.mdi-video-marker:before {
  content: "󱦩";
}

.mdi-video-marker-outline:before {
  content: "󱦪";
}

.mdi-video-minus:before {
  content: "󰦲";
}

.mdi-video-minus-outline:before {
  content: "󰊺";
}

.mdi-video-off:before {
  content: "󰕨";
}

.mdi-video-off-outline:before {
  content: "󰯛";
}

.mdi-video-outline:before {
  content: "󰯜";
}

.mdi-video-plus:before {
  content: "󰦳";
}

.mdi-video-plus-outline:before {
  content: "󰇓";
}

.mdi-video-stabilization:before {
  content: "󰤛";
}

.mdi-video-switch:before {
  content: "󰕩";
}

.mdi-video-switch-outline:before {
  content: "󰞐";
}

.mdi-video-vintage:before {
  content: "󰨜";
}

.mdi-video-wireless:before {
  content: "󰻒";
}

.mdi-video-wireless-outline:before {
  content: "󰻓";
}

.mdi-view-agenda:before {
  content: "󰕪";
}

.mdi-view-agenda-outline:before {
  content: "󱇘";
}

.mdi-view-array:before {
  content: "󰕫";
}

.mdi-view-array-outline:before {
  content: "󱒅";
}

.mdi-view-carousel:before {
  content: "󰕬";
}

.mdi-view-carousel-outline:before {
  content: "󱒆";
}

.mdi-view-column:before {
  content: "󰕭";
}

.mdi-view-column-outline:before {
  content: "󱒇";
}

.mdi-view-comfy:before {
  content: "󰹪";
}

.mdi-view-comfy-outline:before {
  content: "󱒈";
}

.mdi-view-compact:before {
  content: "󰹫";
}

.mdi-view-compact-outline:before {
  content: "󰹬";
}

.mdi-view-dashboard:before {
  content: "󰕮";
}

.mdi-view-dashboard-edit:before {
  content: "󱥇";
}

.mdi-view-dashboard-edit-outline:before {
  content: "󱥈";
}

.mdi-view-dashboard-outline:before {
  content: "󰨝";
}

.mdi-view-dashboard-variant:before {
  content: "󰡃";
}

.mdi-view-dashboard-variant-outline:before {
  content: "󱒉";
}

.mdi-view-day:before {
  content: "󰕯";
}

.mdi-view-day-outline:before {
  content: "󱒊";
}

.mdi-view-gallery:before {
  content: "󱢈";
}

.mdi-view-gallery-outline:before {
  content: "󱢉";
}

.mdi-view-grid:before {
  content: "󰕰";
}

.mdi-view-grid-compact:before {
  content: "󱱡";
}

.mdi-view-grid-outline:before {
  content: "󱇙";
}

.mdi-view-grid-plus:before {
  content: "󰾍";
}

.mdi-view-grid-plus-outline:before {
  content: "󱇚";
}

.mdi-view-headline:before {
  content: "󰕱";
}

.mdi-view-list:before {
  content: "󰕲";
}

.mdi-view-list-outline:before {
  content: "󱒋";
}

.mdi-view-module:before {
  content: "󰕳";
}

.mdi-view-module-outline:before {
  content: "󱒌";
}

.mdi-view-parallel:before {
  content: "󰜨";
}

.mdi-view-parallel-outline:before {
  content: "󱒍";
}

.mdi-view-quilt:before {
  content: "󰕴";
}

.mdi-view-quilt-outline:before {
  content: "󱒎";
}

.mdi-view-sequential:before {
  content: "󰜩";
}

.mdi-view-sequential-outline:before {
  content: "󱒏";
}

.mdi-view-split-horizontal:before {
  content: "󰯋";
}

.mdi-view-split-vertical:before {
  content: "󰯌";
}

.mdi-view-stream:before {
  content: "󰕵";
}

.mdi-view-stream-outline:before {
  content: "󱒐";
}

.mdi-view-week:before {
  content: "󰕶";
}

.mdi-view-week-outline:before {
  content: "󱒑";
}

.mdi-vimeo:before {
  content: "󰕷";
}

.mdi-violin:before {
  content: "󰘏";
}

.mdi-virtual-reality:before {
  content: "󰢔";
}

.mdi-virus:before {
  content: "󱎶";
}

.mdi-virus-off:before {
  content: "󱣡";
}

.mdi-virus-off-outline:before {
  content: "󱣢";
}

.mdi-virus-outline:before {
  content: "󱎷";
}

.mdi-vlc:before {
  content: "󰕼";
}

.mdi-voicemail:before {
  content: "󰕽";
}

.mdi-volcano:before {
  content: "󱪃";
}

.mdi-volcano-outline:before {
  content: "󱪄";
}

.mdi-volleyball:before {
  content: "󰦴";
}

.mdi-volume-equal:before {
  content: "󱬐";
}

.mdi-volume-high:before {
  content: "󰕾";
}

.mdi-volume-low:before {
  content: "󰕿";
}

.mdi-volume-medium:before {
  content: "󰖀";
}

.mdi-volume-minus:before {
  content: "󰝞";
}

.mdi-volume-mute:before {
  content: "󰝟";
}

.mdi-volume-off:before {
  content: "󰖁";
}

.mdi-volume-plus:before {
  content: "󰝝";
}

.mdi-volume-source:before {
  content: "󱄠";
}

.mdi-volume-variant-off:before {
  content: "󰸈";
}

.mdi-volume-vibrate:before {
  content: "󱄡";
}

.mdi-vote:before {
  content: "󰨟";
}

.mdi-vote-outline:before {
  content: "󰨠";
}

.mdi-vpn:before {
  content: "󰖂";
}

.mdi-vuejs:before {
  content: "󰡄";
}

.mdi-vuetify:before {
  content: "󰹭";
}

.mdi-walk:before {
  content: "󰖃";
}

.mdi-wall:before {
  content: "󰟾";
}

.mdi-wall-fire:before {
  content: "󱨑";
}

.mdi-wall-sconce:before {
  content: "󰤜";
}

.mdi-wall-sconce-flat:before {
  content: "󰤝";
}

.mdi-wall-sconce-flat-outline:before {
  content: "󱟉";
}

.mdi-wall-sconce-flat-variant:before {
  content: "󰐜";
}

.mdi-wall-sconce-flat-variant-outline:before {
  content: "󱟊";
}

.mdi-wall-sconce-outline:before {
  content: "󱟋";
}

.mdi-wall-sconce-round:before {
  content: "󰝈";
}

.mdi-wall-sconce-round-outline:before {
  content: "󱟌";
}

.mdi-wall-sconce-round-variant:before {
  content: "󰤞";
}

.mdi-wall-sconce-round-variant-outline:before {
  content: "󱟍";
}

.mdi-wallet:before {
  content: "󰖄";
}

.mdi-wallet-bifold:before {
  content: "󱱘";
}

.mdi-wallet-bifold-outline:before {
  content: "󱱙";
}

.mdi-wallet-giftcard:before {
  content: "󰖅";
}

.mdi-wallet-membership:before {
  content: "󰖆";
}

.mdi-wallet-outline:before {
  content: "󰯝";
}

.mdi-wallet-plus:before {
  content: "󰾎";
}

.mdi-wallet-plus-outline:before {
  content: "󰾏";
}

.mdi-wallet-travel:before {
  content: "󰖇";
}

.mdi-wallpaper:before {
  content: "󰸉";
}

.mdi-wan:before {
  content: "󰖈";
}

.mdi-wardrobe:before {
  content: "󰾐";
}

.mdi-wardrobe-outline:before {
  content: "󰾑";
}

.mdi-warehouse:before {
  content: "󰾁";
}

.mdi-washing-machine:before {
  content: "󰜪";
}

.mdi-washing-machine-alert:before {
  content: "󱆼";
}

.mdi-washing-machine-off:before {
  content: "󱆽";
}

.mdi-watch:before {
  content: "󰖉";
}

.mdi-watch-export:before {
  content: "󰖊";
}

.mdi-watch-export-variant:before {
  content: "󰢕";
}

.mdi-watch-import:before {
  content: "󰖋";
}

.mdi-watch-import-variant:before {
  content: "󰢖";
}

.mdi-watch-variant:before {
  content: "󰢗";
}

.mdi-watch-vibrate:before {
  content: "󰚱";
}

.mdi-watch-vibrate-off:before {
  content: "󰳚";
}

.mdi-water:before {
  content: "󰖌";
}

.mdi-water-alert:before {
  content: "󱔂";
}

.mdi-water-alert-outline:before {
  content: "󱔃";
}

.mdi-water-boiler:before {
  content: "󰾒";
}

.mdi-water-boiler-alert:before {
  content: "󱆳";
}

.mdi-water-boiler-auto:before {
  content: "󱮘";
}

.mdi-water-boiler-off:before {
  content: "󱆴";
}

.mdi-water-check:before {
  content: "󱔄";
}

.mdi-water-check-outline:before {
  content: "󱔅";
}

.mdi-water-circle:before {
  content: "󱠆";
}

.mdi-water-minus:before {
  content: "󱔆";
}

.mdi-water-minus-outline:before {
  content: "󱔇";
}

.mdi-water-off:before {
  content: "󰖍";
}

.mdi-water-off-outline:before {
  content: "󱔈";
}

.mdi-water-opacity:before {
  content: "󱡕";
}

.mdi-water-outline:before {
  content: "󰸊";
}

.mdi-water-percent:before {
  content: "󰖎";
}

.mdi-water-percent-alert:before {
  content: "󱔉";
}

.mdi-water-plus:before {
  content: "󱔊";
}

.mdi-water-plus-outline:before {
  content: "󱔋";
}

.mdi-water-polo:before {
  content: "󱊠";
}

.mdi-water-pump:before {
  content: "󰖏";
}

.mdi-water-pump-off:before {
  content: "󰾓";
}

.mdi-water-remove:before {
  content: "󱔌";
}

.mdi-water-remove-outline:before {
  content: "󱔍";
}

.mdi-water-sync:before {
  content: "󱟆";
}

.mdi-water-thermometer:before {
  content: "󱪅";
}

.mdi-water-thermometer-outline:before {
  content: "󱪆";
}

.mdi-water-well:before {
  content: "󱁫";
}

.mdi-water-well-outline:before {
  content: "󱁬";
}

.mdi-waterfall:before {
  content: "󱡉";
}

.mdi-watering-can:before {
  content: "󱒁";
}

.mdi-watering-can-outline:before {
  content: "󱒂";
}

.mdi-watermark:before {
  content: "󰘒";
}

.mdi-wave:before {
  content: "󰼮";
}

.mdi-waveform:before {
  content: "󱑽";
}

.mdi-waves:before {
  content: "󰞍";
}

.mdi-waves-arrow-left:before {
  content: "󱡙";
}

.mdi-waves-arrow-right:before {
  content: "󱡚";
}

.mdi-waves-arrow-up:before {
  content: "󱡛";
}

.mdi-waze:before {
  content: "󰯞";
}

.mdi-weather-cloudy:before {
  content: "󰖐";
}

.mdi-weather-cloudy-alert:before {
  content: "󰼯";
}

.mdi-weather-cloudy-arrow-right:before {
  content: "󰹮";
}

.mdi-weather-cloudy-clock:before {
  content: "󱣶";
}

.mdi-weather-dust:before {
  content: "󱭚";
}

.mdi-weather-fog:before {
  content: "󰖑";
}

.mdi-weather-hail:before {
  content: "󰖒";
}

.mdi-weather-hazy:before {
  content: "󰼰";
}

.mdi-weather-hurricane:before {
  content: "󰢘";
}

.mdi-weather-hurricane-outline:before {
  content: "󱱸";
}

.mdi-weather-lightning:before {
  content: "󰖓";
}

.mdi-weather-lightning-rainy:before {
  content: "󰙾";
}

.mdi-weather-night:before {
  content: "󰖔";
}

.mdi-weather-night-partly-cloudy:before {
  content: "󰼱";
}

.mdi-weather-partly-cloudy:before {
  content: "󰖕";
}

.mdi-weather-partly-lightning:before {
  content: "󰼲";
}

.mdi-weather-partly-rainy:before {
  content: "󰼳";
}

.mdi-weather-partly-snowy:before {
  content: "󰼴";
}

.mdi-weather-partly-snowy-rainy:before {
  content: "󰼵";
}

.mdi-weather-pouring:before {
  content: "󰖖";
}

.mdi-weather-rainy:before {
  content: "󰖗";
}

.mdi-weather-snowy:before {
  content: "󰖘";
}

.mdi-weather-snowy-heavy:before {
  content: "󰼶";
}

.mdi-weather-snowy-rainy:before {
  content: "󰙿";
}

.mdi-weather-sunny:before {
  content: "󰖙";
}

.mdi-weather-sunny-alert:before {
  content: "󰼷";
}

.mdi-weather-sunny-off:before {
  content: "󱓤";
}

.mdi-weather-sunset:before {
  content: "󰖚";
}

.mdi-weather-sunset-down:before {
  content: "󰖛";
}

.mdi-weather-sunset-up:before {
  content: "󰖜";
}

.mdi-weather-tornado:before {
  content: "󰼸";
}

.mdi-weather-windy:before {
  content: "󰖝";
}

.mdi-weather-windy-variant:before {
  content: "󰖞";
}

.mdi-web:before {
  content: "󰖟";
}

.mdi-web-box:before {
  content: "󰾔";
}

.mdi-web-cancel:before {
  content: "󱞐";
}

.mdi-web-check:before {
  content: "󰞉";
}

.mdi-web-clock:before {
  content: "󱉊";
}

.mdi-web-minus:before {
  content: "󱂠";
}

.mdi-web-off:before {
  content: "󰪎";
}

.mdi-web-plus:before {
  content: "󰀳";
}

.mdi-web-refresh:before {
  content: "󱞑";
}

.mdi-web-remove:before {
  content: "󰕑";
}

.mdi-web-sync:before {
  content: "󱞒";
}

.mdi-webcam:before {
  content: "󰖠";
}

.mdi-webcam-off:before {
  content: "󱜷";
}

.mdi-webhook:before {
  content: "󰘯";
}

.mdi-webpack:before {
  content: "󰜫";
}

.mdi-webrtc:before {
  content: "󱉈";
}

.mdi-wechat:before {
  content: "󰘑";
}

.mdi-weight:before {
  content: "󰖡";
}

.mdi-weight-gram:before {
  content: "󰴿";
}

.mdi-weight-kilogram:before {
  content: "󰖢";
}

.mdi-weight-lifter:before {
  content: "󱅝";
}

.mdi-weight-pound:before {
  content: "󰦵";
}

.mdi-whatsapp:before {
  content: "󰖣";
}

.mdi-wheel-barrow:before {
  content: "󱓲";
}

.mdi-wheelchair:before {
  content: "󱪇";
}

.mdi-wheelchair-accessibility:before {
  content: "󰖤";
}

.mdi-whistle:before {
  content: "󰦶";
}

.mdi-whistle-outline:before {
  content: "󱊼";
}

.mdi-white-balance-auto:before {
  content: "󰖥";
}

.mdi-white-balance-incandescent:before {
  content: "󰖦";
}

.mdi-white-balance-iridescent:before {
  content: "󰖧";
}

.mdi-white-balance-sunny:before {
  content: "󰖨";
}

.mdi-widgets:before {
  content: "󰜬";
}

.mdi-widgets-outline:before {
  content: "󱍕";
}

.mdi-wifi:before {
  content: "󰖩";
}

.mdi-wifi-alert:before {
  content: "󱚵";
}

.mdi-wifi-arrow-down:before {
  content: "󱚶";
}

.mdi-wifi-arrow-left:before {
  content: "󱚷";
}

.mdi-wifi-arrow-left-right:before {
  content: "󱚸";
}

.mdi-wifi-arrow-right:before {
  content: "󱚹";
}

.mdi-wifi-arrow-up:before {
  content: "󱚺";
}

.mdi-wifi-arrow-up-down:before {
  content: "󱚻";
}

.mdi-wifi-cancel:before {
  content: "󱚼";
}

.mdi-wifi-check:before {
  content: "󱚽";
}

.mdi-wifi-cog:before {
  content: "󱚾";
}

.mdi-wifi-lock:before {
  content: "󱚿";
}

.mdi-wifi-lock-open:before {
  content: "󱛀";
}

.mdi-wifi-marker:before {
  content: "󱛁";
}

.mdi-wifi-minus:before {
  content: "󱛂";
}

.mdi-wifi-off:before {
  content: "󰖪";
}

.mdi-wifi-plus:before {
  content: "󱛃";
}

.mdi-wifi-refresh:before {
  content: "󱛄";
}

.mdi-wifi-remove:before {
  content: "󱛅";
}

.mdi-wifi-settings:before {
  content: "󱛆";
}

.mdi-wifi-star:before {
  content: "󰸋";
}

.mdi-wifi-strength-1:before {
  content: "󰤟";
}

.mdi-wifi-strength-1-alert:before {
  content: "󰤠";
}

.mdi-wifi-strength-1-lock:before {
  content: "󰤡";
}

.mdi-wifi-strength-1-lock-open:before {
  content: "󱛋";
}

.mdi-wifi-strength-2:before {
  content: "󰤢";
}

.mdi-wifi-strength-2-alert:before {
  content: "󰤣";
}

.mdi-wifi-strength-2-lock:before {
  content: "󰤤";
}

.mdi-wifi-strength-2-lock-open:before {
  content: "󱛌";
}

.mdi-wifi-strength-3:before {
  content: "󰤥";
}

.mdi-wifi-strength-3-alert:before {
  content: "󰤦";
}

.mdi-wifi-strength-3-lock:before {
  content: "󰤧";
}

.mdi-wifi-strength-3-lock-open:before {
  content: "󱛍";
}

.mdi-wifi-strength-4:before {
  content: "󰤨";
}

.mdi-wifi-strength-4-alert:before {
  content: "󰤩";
}

.mdi-wifi-strength-4-lock:before {
  content: "󰤪";
}

.mdi-wifi-strength-4-lock-open:before {
  content: "󱛎";
}

.mdi-wifi-strength-alert-outline:before {
  content: "󰤫";
}

.mdi-wifi-strength-lock-open-outline:before {
  content: "󱛏";
}

.mdi-wifi-strength-lock-outline:before {
  content: "󰤬";
}

.mdi-wifi-strength-off:before {
  content: "󰤭";
}

.mdi-wifi-strength-off-outline:before {
  content: "󰤮";
}

.mdi-wifi-strength-outline:before {
  content: "󰤯";
}

.mdi-wifi-sync:before {
  content: "󱛇";
}

.mdi-wikipedia:before {
  content: "󰖬";
}

.mdi-wind-power:before {
  content: "󱪈";
}

.mdi-wind-power-outline:before {
  content: "󱪉";
}

.mdi-wind-turbine:before {
  content: "󰶥";
}

.mdi-wind-turbine-alert:before {
  content: "󱦫";
}

.mdi-wind-turbine-check:before {
  content: "󱦬";
}

.mdi-window-close:before {
  content: "󰖭";
}

.mdi-window-closed:before {
  content: "󰖮";
}

.mdi-window-closed-variant:before {
  content: "󱇛";
}

.mdi-window-maximize:before {
  content: "󰖯";
}

.mdi-window-minimize:before {
  content: "󰖰";
}

.mdi-window-open:before {
  content: "󰖱";
}

.mdi-window-open-variant:before {
  content: "󱇜";
}

.mdi-window-restore:before {
  content: "󰖲";
}

.mdi-window-shutter:before {
  content: "󱄜";
}

.mdi-window-shutter-alert:before {
  content: "󱄝";
}

.mdi-window-shutter-auto:before {
  content: "󱮣";
}

.mdi-window-shutter-cog:before {
  content: "󱪊";
}

.mdi-window-shutter-open:before {
  content: "󱄞";
}

.mdi-window-shutter-settings:before {
  content: "󱪋";
}

.mdi-windsock:before {
  content: "󱗺";
}

.mdi-wiper:before {
  content: "󰫩";
}

.mdi-wiper-wash:before {
  content: "󰶦";
}

.mdi-wiper-wash-alert:before {
  content: "󱣟";
}

.mdi-wizard-hat:before {
  content: "󱑷";
}

.mdi-wordpress:before {
  content: "󰖴";
}

.mdi-wrap:before {
  content: "󰖶";
}

.mdi-wrap-disabled:before {
  content: "󰯟";
}

.mdi-wrench:before {
  content: "󰖷";
}

.mdi-wrench-check:before {
  content: "󱮏";
}

.mdi-wrench-check-outline:before {
  content: "󱮐";
}

.mdi-wrench-clock:before {
  content: "󱦣";
}

.mdi-wrench-clock-outline:before {
  content: "󱮓";
}

.mdi-wrench-cog:before {
  content: "󱮑";
}

.mdi-wrench-cog-outline:before {
  content: "󱮒";
}

.mdi-wrench-outline:before {
  content: "󰯠";
}

.mdi-xamarin:before {
  content: "󰡅";
}

.mdi-xml:before {
  content: "󰗀";
}

.mdi-xmpp:before {
  content: "󰟿";
}

.mdi-yahoo:before {
  content: "󰭏";
}

.mdi-yeast:before {
  content: "󰗁";
}

.mdi-yin-yang:before {
  content: "󰚀";
}

.mdi-yoga:before {
  content: "󱅼";
}

.mdi-youtube:before {
  content: "󰗃";
}

.mdi-youtube-gaming:before {
  content: "󰡈";
}

.mdi-youtube-studio:before {
  content: "󰡇";
}

.mdi-youtube-subscription:before {
  content: "󰵀";
}

.mdi-youtube-tv:before {
  content: "󰑈";
}

.mdi-yurt:before {
  content: "󱔖";
}

.mdi-z-wave:before {
  content: "󰫪";
}

.mdi-zend:before {
  content: "󰫫";
}

.mdi-zigbee:before {
  content: "󰵁";
}

.mdi-zip-box:before {
  content: "󰗄";
}

.mdi-zip-box-outline:before {
  content: "󰿺";
}

.mdi-zip-disk:before {
  content: "󰨣";
}

.mdi-zodiac-aquarius:before {
  content: "󰩽";
}

.mdi-zodiac-aries:before {
  content: "󰩾";
}

.mdi-zodiac-cancer:before {
  content: "󰩿";
}

.mdi-zodiac-capricorn:before {
  content: "󰪀";
}

.mdi-zodiac-gemini:before {
  content: "󰪁";
}

.mdi-zodiac-leo:before {
  content: "󰪂";
}

.mdi-zodiac-libra:before {
  content: "󰪃";
}

.mdi-zodiac-pisces:before {
  content: "󰪄";
}

.mdi-zodiac-sagittarius:before {
  content: "󰪅";
}

.mdi-zodiac-scorpio:before {
  content: "󰪆";
}

.mdi-zodiac-taurus:before {
  content: "󰪇";
}

.mdi-zodiac-virgo:before {
  content: "󰪈";
}

.mdi-blank:before {
  content: "";
  visibility: hidden;
}

.mdi-18px.mdi-set, .mdi-18px.mdi:before {
  font-size: 18px;
}

.mdi-24px.mdi-set, .mdi-24px.mdi:before {
  font-size: 24px;
}

.mdi-36px.mdi-set, .mdi-36px.mdi:before {
  font-size: 36px;
}

.mdi-48px.mdi-set, .mdi-48px.mdi:before {
  font-size: 48px;
}

.mdi-dark:before {
  color: #0000008a;
}

.mdi-dark.mdi-inactive:before {
  color: #00000042;
}

.mdi-light:before {
  color: #fff;
}

.mdi-light.mdi-inactive:before {
  color: #ffffff4d;
}

.mdi-rotate-45:before {
  transform: rotate(45deg);
}

.mdi-rotate-90:before {
  transform: rotate(90deg);
}

.mdi-rotate-135:before {
  transform: rotate(135deg);
}

.mdi-rotate-180:before {
  transform: rotate(180deg);
}

.mdi-rotate-225:before {
  transform: rotate(225deg);
}

.mdi-rotate-270:before {
  transform: rotate(270deg);
}

.mdi-rotate-315:before {
  transform: rotate(315deg);
}

.mdi-flip-h:before {
  filter: FlipH;
  -ms-filter: "FlipH";
  transform: scaleX(-1);
}

.mdi-flip-v:before {
  filter: FlipV;
  -ms-filter: "FlipV";
  transform: scaleY(-1);
}

.mdi-spin:before {
  animation: 2s linear infinite mdi-spin;
}

@keyframes mdi-spin {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.leaflet-pane, .leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-tile-container, .leaflet-pane > svg, .leaflet-pane > canvas, .leaflet-zoom-box, .leaflet-image-layer, .leaflet-layer {
  position: absolute;
  top: 0;
  left: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.leaflet-tile::selection {
  background: none;
}

.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

.leaflet-safari .leaflet-tile-container {
  -webkit-transform-origin: 0 0;
  width: 1600px;
  height: 1600px;
}

.leaflet-marker-icon, .leaflet-marker-shadow {
  display: block;
}

.leaflet-container .leaflet-overlay-pane svg {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container .leaflet-marker-pane img, .leaflet-container .leaflet-shadow-pane img, .leaflet-container .leaflet-tile-pane img, .leaflet-container img.leaflet-image-layer, .leaflet-container .leaflet-tile {
  width: auto;
  padding: 0;
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: #33b5e566;
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  box-sizing: border-box;
  z-index: 800;
  width: 0;
  height: 0;
}

.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url("#default#VML");
  display: inline-block;
  position: absolute;
}

.leaflet-control {
  z-index: 800;
  pointer-events: visiblePainted;
  pointer-events: auto;
  position: relative;
}

.leaflet-top, .leaflet-bottom {
  z-index: 1000;
  pointer-events: none;
  position: absolute;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  transition: opacity .2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  transform-origin: 0 0;
}

svg.leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1);
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1);
  transition: transform .25s cubic-bezier(0, 0, .25, 1);
}

.leaflet-zoom-anim .leaflet-tile, .leaflet-pan-anim .leaflet-tile {
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.leaflet-crosshair, .leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane, .leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab, .leaflet-dragging .leaflet-grab .leaflet-interactive, .leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-image-layer, .leaflet-pane > svg path, .leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive, .leaflet-image-layer.leaflet-interactive, .leaflet-pane > svg path.leaflet-interactive, svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted;
  pointer-events: auto;
}

.leaflet-container {
  outline-offset: 1px;
  background: #ddd;
}

.leaflet-container a {
  color: #0078a8;
}

.leaflet-zoom-box {
  background: #ffffff80;
  border: 2px dotted #38f;
}

.leaflet-container {
  font-family: Helvetica Neue, Arial, Helvetica, sans-serif;
  font-size: .75rem;
  line-height: 1.5;
}

.leaflet-bar {
  border-radius: 4px;
  box-shadow: 0 1px 5px #000000a6;
}

.leaflet-bar a {
  text-align: center;
  color: #000;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-decoration: none;
  display: block;
}

.leaflet-bar a, .leaflet-control-layers-toggle {
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover, .leaflet-bar a:focus {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom: none;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  color: #bbb;
  background-color: #f4f4f4;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.leaflet-control-zoom-in, .leaflet-control-zoom-out {
  text-indent: 1px;
  font: 700 18px Lucida Console, Monaco, monospace;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

.leaflet-control-layers {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px #0006;
}

.leaflet-control-layers-toggle {
  background-image: url("layers-55W3Q4RM-55W3Q4RM.760a0456.png");
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url("layers-2x-TBM42ERR-TBM42ERR.b7b89169.png");
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list, .leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  color: #333;
  background: #fff;
  padding: 6px 10px 6px 6px;
}

.leaflet-control-layers-scrollbar {
  padding-right: 5px;
  overflow: hidden scroll;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  font-size: 1.08333em;
  display: block;
}

.leaflet-control-layers-separator {
  border-top: 1px solid #ddd;
  height: 0;
  margin: 5px -10px 5px -6px;
}

.leaflet-default-icon-path {
  background-image: url("marker-icon-2V3QKKVC-2V3QKKVC.3f7d3721.png");
}

.leaflet-container .leaflet-control-attribution {
  background: #fffc;
  margin: 0;
}

.leaflet-control-attribution, .leaflet-control-scale-line {
  color: #333;
  padding: 0 5px;
  line-height: 1.4;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover, .leaflet-control-attribution a:focus {
  text-decoration: underline;
}

.leaflet-attribution-flag {
  width: 1em;
  height: .6669em;
  vertical-align: baseline !important;
  display: inline !important;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  white-space: nowrap;
  box-sizing: border-box;
  text-shadow: 1px 1px #fff;
  background: #fffc;
  border: 2px solid #777;
  border-top: none;
  padding: 2px 5px 1px;
  line-height: 1.1;
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution, .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  background-clip: padding-box;
  border: 2px solid #0003;
}

.leaflet-popup {
  text-align: center;
  margin-bottom: 20px;
  position: absolute;
}

.leaflet-popup-content-wrapper {
  text-align: left;
  border-radius: 12px;
  padding: 1px;
}

.leaflet-popup-content {
  min-height: 1px;
  margin: 13px 24px 13px 20px;
  font-size: 1.08333em;
  line-height: 1.3;
}

.leaflet-popup-content p {
  margin: 1.3em 0;
}

.leaflet-popup-tip-container {
  pointer-events: none;
  width: 40px;
  height: 20px;
  margin-top: -1px;
  margin-left: -20px;
  position: absolute;
  left: 50%;
  overflow: hidden;
}

.leaflet-popup-tip {
  pointer-events: auto;
  width: 17px;
  height: 17px;
  margin: -10px auto 0;
  padding: 1px;
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  color: #333;
  background: #fff;
  box-shadow: 0 3px 14px #0006;
}

.leaflet-container a.leaflet-popup-close-button {
  text-align: center;
  color: #757575;
  background: none;
  border: none;
  width: 24px;
  height: 24px;
  font: 16px / 24px Tahoma, Verdana, sans-serif;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
}

.leaflet-container a.leaflet-popup-close-button:hover, .leaflet-container a.leaflet-popup-close-button:focus {
  color: #585858;
}

.leaflet-popup-scrolled {
  overflow: auto;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  -ms-zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform. Microsoft. Matrix(M11= .707107, M12= .707107, M21= -.707107, M22= .707107);
  width: 24px;
  margin: 0 auto;
}

.leaflet-oldie .leaflet-control-zoom, .leaflet-oldie .leaflet-control-layers, .leaflet-oldie .leaflet-popup-content-wrapper, .leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

.leaflet-tooltip {
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 6px;
  position: absolute;
  box-shadow: 0 1px 3px #0006;
}

.leaflet-tooltip.leaflet-interactive {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before, .leaflet-tooltip-bottom:before, .leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  pointer-events: none;
  content: "";
  background: none;
  border: 6px solid #0000;
  position: absolute;
}

.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before, .leaflet-tooltip-top:before {
  margin-left: -6px;
  left: 50%;
}

.leaflet-tooltip-top:before {
  border-top-color: #fff;
  margin-bottom: -12px;
  bottom: 0;
}

.leaflet-tooltip-bottom:before {
  border-bottom-color: #fff;
  margin-top: -12px;
  margin-left: -6px;
  top: 0;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  margin-top: -6px;
  top: 50%;
}

.leaflet-tooltip-left:before {
  border-left-color: #fff;
  margin-right: -12px;
  right: 0;
}

.leaflet-tooltip-right:before {
  border-right-color: #fff;
  margin-left: -12px;
  left: 0;
}

@media print {
  .leaflet-control {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

@font-face {
  font-family: unicons;
  src: url("unicons-TKFDIOSO-TKFDIOSO.c3916a73.eot");
  src: url("unicons-TKFDIOSO-TKFDIOSO.c3916a73.eot") format("embedded-opentype"), url("unicons-NFZKYUTL-NFZKYUTL.de5d1928.woff2") format("woff2"), url("unicons-G4ER26AL-G4ER26AL.38b834ad.woff") format("woff"), url("unicons-FAXYAXJ6-FAXYAXJ6.49da8869.ttf") format("truetype"), url("unicons-R3LQNHNX-R3LQNHNX.9503002a.svg") format("svg");
  font-weight: 400;
  font-style: normal;
}

[class^="uil-"]:before, [class*=" uil-"]:before {
  speak: none;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: unicons;
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  display: inline-block;
}

.uil-0-plus:before {
  content: "";
}

.uil-10-plus:before {
  content: "";
}

.uil-12-plus:before {
  content: "";
}

.uil-13-plus:before {
  content: "";
}

.uil-16-plus:before {
  content: "";
}

.uil-17-plus:before {
  content: "";
}

.uil-18-plus:before {
  content: "";
}

.uil-21-plus:before {
  content: "";
}

.uil-3-plus:before {
  content: "";
}

.uil-500px:before {
  content: "";
}

.uil-6-plus:before {
  content: "";
}

.uil-abacus:before {
  content: "";
}

.uil-accessible-icon-alt:before {
  content: "";
}

.uil-adjust-alt:before {
  content: "";
}

.uil-adjust-circle:before {
  content: "";
}

.uil-adjust-half:before {
  content: "";
}

.uil-adjust:before {
  content: "";
}

.uil-adobe:before {
  content: "";
}

.uil-airplay:before {
  content: "";
}

.uil-align-alt:before {
  content: "";
}

.uil-align-center-alt:before {
  content: "";
}

.uil-align-center-h:before {
  content: "";
}

.uil-align-center-justify:before {
  content: "";
}

.uil-align-center-v:before {
  content: "";
}

.uil-align-center:before {
  content: "";
}

.uil-align-justify:before {
  content: "";
}

.uil-align-left-justify:before {
  content: "";
}

.uil-align-left:before {
  content: "";
}

.uil-align-letter-right:before {
  content: "";
}

.uil-align-right-justify:before {
  content: "";
}

.uil-align-right:before {
  content: "";
}

.uil-align:before {
  content: "";
}

.uil-ambulance:before {
  content: "";
}

.uil-analysis:before {
  content: "";
}

.uil-analytics:before {
  content: "";
}

.uil-anchor:before {
  content: "";
}

.uil-android-phone-slash:before {
  content: "";
}

.uil-angle-double-down:before {
  content: "";
}

.uil-angle-double-left:before {
  content: "";
}

.uil-angle-double-right:before {
  content: "";
}

.uil-angle-double-up:before {
  content: "";
}

.uil-angle-down:before {
  content: "";
}

.uil-angle-left:before {
  content: "";
}

.uil-angle-right-b:before {
  content: "";
}

.uil-angle-right:before {
  content: "";
}

.uil-angle-up:before {
  content: "";
}

.uil-angry:before {
  content: "";
}

.uil-ankh:before {
  content: "";
}

.uil-annoyed-alt:before {
  content: "";
}

.uil-annoyed:before {
  content: "";
}

.uil-apple:before {
  content: "";
}

.uil-apps:before {
  content: "";
}

.uil-archive-alt:before {
  content: "";
}

.uil-archive:before {
  content: "";
}

.uil-archway:before {
  content: "";
}

.uil-arrow-break:before {
  content: "";
}

.uil-arrow-circle-down:before {
  content: "";
}

.uil-arrow-circle-left:before {
  content: "";
}

.uil-arrow-circle-right:before {
  content: "";
}

.uil-arrow-circle-up:before {
  content: "";
}

.uil-arrow-compress-h:before {
  content: "";
}

.uil-arrow-down-left:before {
  content: "";
}

.uil-arrow-down-right:before {
  content: "";
}

.uil-arrow-down:before {
  content: "";
}

.uil-arrow-from-right:before {
  content: "";
}

.uil-arrow-from-top:before {
  content: "";
}

.uil-arrow-growth:before {
  content: "";
}

.uil-arrow-left:before {
  content: "";
}

.uil-arrow-random:before {
  content: "";
}

.uil-arrow-resize-diagonal:before {
  content: "";
}

.uil-arrow-right:before {
  content: "";
}

.uil-arrow-to-bottom:before {
  content: "";
}

.uil-arrow-to-right:before {
  content: "";
}

.uil-arrow-up-left:before {
  content: "";
}

.uil-arrow-up-right:before {
  content: "";
}

.uil-arrow-up:before {
  content: "";
}

.uil-arrows-h-alt:before {
  content: "";
}

.uil-arrows-h:before {
  content: "";
}

.uil-arrows-left-down:before {
  content: "";
}

.uil-arrows-maximize:before {
  content: "";
}

.uil-arrows-merge:before {
  content: "";
}

.uil-arrows-resize-h:before {
  content: "";
}

.uil-arrows-resize-v:before {
  content: "";
}

.uil-arrows-resize:before {
  content: "";
}

.uil-arrows-right-down:before {
  content: "";
}

.uil-arrows-shrink-h:before {
  content: "";
}

.uil-arrows-shrink-v:before {
  content: "";
}

.uil-arrows-up-right:before {
  content: "";
}

.uil-arrows-v-alt:before {
  content: "";
}

.uil-arrows-v:before {
  content: "";
}

.uil-assistive-listening-systems:before {
  content: "";
}

.uil-asterisk:before {
  content: "";
}

.uil-at:before {
  content: "";
}

.uil-atm-card:before {
  content: "";
}

.uil-atom:before {
  content: "";
}

.uil-auto-flash:before {
  content: "";
}

.uil-award-alt:before {
  content: "";
}

.uil-award:before {
  content: "";
}

.uil-baby-carriage:before {
  content: "";
}

.uil-backpack:before {
  content: "";
}

.uil-backspace:before {
  content: "";
}

.uil-backward:before {
  content: "";
}

.uil-bag-alt:before {
  content: "";
}

.uil-bag-slash:before {
  content: "";
}

.uil-bag:before {
  content: "";
}

.uil-balance-scale:before {
  content: "";
}

.uil-ball:before {
  content: "";
}

.uil-ban:before {
  content: "";
}

.uil-bars:before {
  content: "";
}

.uil-basketball-hoop:before {
  content: "";
}

.uil-basketball:before {
  content: "";
}

.uil-battery-bolt:before {
  content: "";
}

.uil-battery-empty:before {
  content: "";
}

.uil-bed-double:before {
  content: "";
}

.uil-bed:before {
  content: "";
}

.uil-behance:before {
  content: "";
}

.uil-bell-school:before {
  content: "";
}

.uil-bell-slash:before {
  content: "";
}

.uil-bell:before {
  content: "";
}

.uil-bill:before {
  content: "";
}

.uil-bitcoin-circle:before {
  content: "";
}

.uil-bitcoin:before {
  content: "";
}

.uil-black-berry:before {
  content: "";
}

.uil-bluetooth-b:before {
  content: "";
}

.uil-bold:before {
  content: "";
}

.uil-bolt-alt:before {
  content: "";
}

.uil-bolt-slash:before {
  content: "";
}

.uil-bolt:before {
  content: "";
}

.uil-book-alt:before {
  content: "";
}

.uil-book-medical:before {
  content: "";
}

.uil-book-open:before {
  content: "";
}

.uil-book-reader:before {
  content: "";
}

.uil-book:before {
  content: "";
}

.uil-bookmark-full:before {
  content: "";
}

.uil-bookmark:before {
  content: "";
}

.uil-books:before {
  content: "";
}

.uil-boombox:before {
  content: "";
}

.uil-border-alt:before {
  content: "";
}

.uil-border-bottom:before {
  content: "";
}

.uil-border-clear:before {
  content: "";
}

.uil-border-horizontal:before {
  content: "";
}

.uil-border-inner:before {
  content: "";
}

.uil-border-left:before {
  content: "";
}

.uil-border-out:before {
  content: "";
}

.uil-border-right:before {
  content: "";
}

.uil-border-top:before {
  content: "";
}

.uil-border-vertical:before {
  content: "";
}

.uil-box:before {
  content: "";
}

.uil-briefcase-alt:before {
  content: "";
}

.uil-briefcase:before {
  content: "";
}

.uil-bright:before {
  content: "";
}

.uil-brightness-empty:before {
  content: "";
}

.uil-brightness-half:before {
  content: "";
}

.uil-brightness-low:before {
  content: "";
}

.uil-brightness-minus:before {
  content: "";
}

.uil-brightness-plus:before {
  content: "";
}

.uil-brightness:before {
  content: "";
}

.uil-bring-bottom:before {
  content: "";
}

.uil-bring-front:before {
  content: "";
}

.uil-brush-alt:before {
  content: "";
}

.uil-bug:before {
  content: "";
}

.uil-building:before {
  content: "";
}

.uil-bullseye:before {
  content: "";
}

.uil-bus-alt:before {
  content: "";
}

.uil-bus-school:before {
  content: "";
}

.uil-bus:before {
  content: "";
}

.uil-calcualtor:before {
  content: "";
}

.uil-calculator-alt:before {
  content: "";
}

.uil-calendar-alt:before {
  content: "";
}

.uil-calendar-slash:before {
  content: "";
}

.uil-calender:before {
  content: "";
}

.uil-calling:before {
  content: "";
}

.uil-camera-change:before {
  content: "";
}

.uil-camera-plus:before {
  content: "";
}

.uil-camera-slash:before {
  content: "";
}

.uil-camera:before {
  content: "";
}

.uil-cancel:before {
  content: "";
}

.uil-capsule:before {
  content: "";
}

.uil-capture:before {
  content: "";
}

.uil-car-sideview:before {
  content: "";
}

.uil-car-slash:before {
  content: "";
}

.uil-car-wash:before {
  content: "";
}

.uil-car:before {
  content: "";
}

.uil-card-atm:before {
  content: "";
}

.uil-caret-right:before {
  content: "";
}

.uil-cart:before {
  content: "";
}

.uil-cell:before {
  content: "";
}

.uil-celsius:before {
  content: "";
}

.uil-chart-bar-alt:before {
  content: "";
}

.uil-chart-bar:before {
  content: "";
}

.uil-chart-down:before {
  content: "";
}

.uil-chart-growth-alt:before {
  content: "";
}

.uil-chart-growth:before {
  content: "";
}

.uil-chart-line:before {
  content: "";
}

.uil-chart-pie-alt:before {
  content: "";
}

.uil-chart-pie:before {
  content: "";
}

.uil-chart:before {
  content: "";
}

.uil-chat-bubble-user:before {
  content: "";
}

.uil-chat-info:before {
  content: "";
}

.uil-chat:before {
  content: "";
}

.uil-check-circle:before {
  content: "";
}

.uil-check-square:before {
  content: "";
}

.uil-check:before {
  content: "";
}

.uil-circle-layer:before {
  content: "";
}

.uil-circle:before {
  content: "";
}

.uil-circuit:before {
  content: "";
}

.uil-clapper-board:before {
  content: "";
}

.uil-clipboard-alt:before {
  content: "";
}

.uil-clipboard-blank:before {
  content: "";
}

.uil-clipboard-notes:before {
  content: "";
}

.uil-clipboard:before {
  content: "";
}

.uil-clock-eight:before {
  content: "";
}

.uil-clock-five:before {
  content: "";
}

.uil-clock-nine:before {
  content: "";
}

.uil-clock-seven:before {
  content: "";
}

.uil-clock-ten:before {
  content: "";
}

.uil-clock-three:before {
  content: "";
}

.uil-clock-two:before {
  content: "";
}

.uil-clock:before {
  content: "";
}

.uil-closed-captioning:before {
  content: "";
}

.uil-cloud-block:before {
  content: "";
}

.uil-cloud-bookmark:before {
  content: "";
}

.uil-cloud-check:before {
  content: "";
}

.uil-cloud-computing:before {
  content: "";
}

.uil-cloud-data-connection:before {
  content: "";
}

.uil-cloud-database-tree:before {
  content: "";
}

.uil-cloud-download:before {
  content: "";
}

.uil-cloud-drizzle:before {
  content: "";
}

.uil-cloud-exclamation:before {
  content: "";
}

.uil-cloud-hail:before {
  content: "";
}

.uil-cloud-heart:before {
  content: "";
}

.uil-cloud-info:before {
  content: "";
}

.uil-cloud-lock:before {
  content: "";
}

.uil-cloud-meatball:before {
  content: "";
}

.uil-cloud-moon-hail:before {
  content: "";
}

.uil-cloud-moon-meatball:before {
  content: "";
}

.uil-cloud-moon-rain:before {
  content: "";
}

.uil-cloud-moon-showers:before {
  content: "";
}

.uil-cloud-moon:before {
  content: "";
}

.uil-cloud-question:before {
  content: "";
}

.uil-cloud-rain-sun:before {
  content: "";
}

.uil-cloud-rain:before {
  content: "";
}

.uil-cloud-redo:before {
  content: "";
}

.uil-cloud-set:before {
  content: "";
}

.uil-cloud-share:before {
  content: "";
}

.uil-cloud-shield:before {
  content: "";
}

.uil-cloud-showers-alt:before {
  content: "";
}

.uil-cloud-showers-heavy:before {
  content: "";
}

.uil-cloud-showers:before {
  content: "";
}

.uil-cloud-slash:before {
  content: "";
}

.uil-cloud-sun-hail:before {
  content: "";
}

.uil-cloud-sun-meatball:before {
  content: "";
}

.uil-cloud-sun-rain-alt:before {
  content: "";
}

.uil-cloud-sun-rain:before {
  content: "";
}

.uil-cloud-sun-tear:before {
  content: "";
}

.uil-cloud-sun:before {
  content: "";
}

.uil-cloud-times:before {
  content: "";
}

.uil-cloud-unlock:before {
  content: "";
}

.uil-cloud-upload:before {
  content: "";
}

.uil-cloud-wifi:before {
  content: "";
}

.uil-cloud-wind:before {
  content: "";
}

.uil-cloud:before {
  content: "";
}

.uil-clouds:before {
  content: "";
}

.uil-club:before {
  content: "";
}

.uil-code:before {
  content: "";
}

.uil-coffee:before {
  content: "";
}

.uil-cog:before {
  content: "";
}

.uil-coins:before {
  content: "";
}

.uil-columns:before {
  content: "";
}

.uil-comment-alt-block:before {
  content: "";
}

.uil-comment-alt-chart-lines:before {
  content: "";
}

.uil-comment-alt-check:before {
  content: "";
}

.uil-comment-alt-dots:before {
  content: "";
}

.uil-comment-alt-download:before {
  content: "";
}

.uil-comment-alt-edit:before {
  content: "";
}

.uil-comment-alt-exclamation:before {
  content: "";
}

.uil-comment-alt-heart:before {
  content: "";
}

.uil-comment-alt-image:before {
  content: "";
}

.uil-comment-alt-info:before {
  content: "";
}

.uil-comment-alt-lines:before {
  content: "";
}

.uil-comment-alt-lock:before {
  content: "";
}

.uil-comment-alt-medical:before {
  content: "";
}

.uil-comment-alt-message:before {
  content: "";
}

.uil-comment-alt-notes:before {
  content: "";
}

.uil-comment-alt-plus:before {
  content: "";
}

.uil-comment-alt-question:before {
  content: "";
}

.uil-comment-alt-redo:before {
  content: "";
}

.uil-comment-alt-search:before {
  content: "";
}

.uil-comment-alt-share:before {
  content: "";
}

.uil-comment-alt-shield:before {
  content: "";
}

.uil-comment-alt-slash:before {
  content: "";
}

.uil-comment-alt-upload:before {
  content: "";
}

.uil-comment-alt-verify:before {
  content: "";
}

.uil-comment-alt:before {
  content: "";
}

.uil-comment-block:before {
  content: "";
}

.uil-comment-chart-line:before {
  content: "";
}

.uil-comment-check:before {
  content: "";
}

.uil-comment-dots:before {
  content: "";
}

.uil-comment-download:before {
  content: "";
}

.uil-comment-edit:before {
  content: "";
}

.uil-comment-exclamation:before {
  content: "";
}

.uil-comment-heart:before {
  content: "";
}

.uil-comment-image:before {
  content: "";
}

.uil-comment-info-alt:before {
  content: "";
}

.uil-comment-info:before {
  content: "";
}

.uil-comment-lines:before {
  content: "";
}

.uil-comment-lock:before {
  content: "";
}

.uil-comment-medical:before {
  content: "";
}

.uil-comment-message:before {
  content: "";
}

.uil-comment-notes:before {
  content: "";
}

.uil-comment-plus:before {
  content: "";
}

.uil-comment-question:before {
  content: "";
}

.uil-comment-redo:before {
  content: "";
}

.uil-comment-search:before {
  content: "";
}

.uil-comment-share:before {
  content: "";
}

.uil-comment-shield:before {
  content: "";
}

.uil-comment-slash:before {
  content: "";
}

.uil-comment-upload:before {
  content: "";
}

.uil-comment-verify:before {
  content: "";
}

.uil-comment:before {
  content: "";
}

.uil-comments-alt:before {
  content: "";
}

.uil-comments:before {
  content: "";
}

.uil-commnet-alt-slash:before {
  content: "";
}

.uil-compact-disc:before {
  content: "";
}

.uil-compass:before {
  content: "";
}

.uil-compress-alt-left:before {
  content: "";
}

.uil-compress-alt:before {
  content: "";
}

.uil-compress-arrows:before {
  content: "";
}

.uil-compress-lines:before {
  content: "";
}

.uil-compress-point:before {
  content: "";
}

.uil-compress-v:before {
  content: "";
}

.uil-compress:before {
  content: "";
}

.uil-computer-mouse:before {
  content: "";
}

.uil-confused:before {
  content: "";
}

.uil-constructor:before {
  content: "";
}

.uil-copy-alt:before {
  content: "";
}

.uil-copy-landscape:before {
  content: "";
}

.uil-copy:before {
  content: "";
}

.uil-copyright:before {
  content: "";
}

.uil-corner-down-left:before {
  content: "";
}

.uil-corner-down-right-alt:before {
  content: "";
}

.uil-corner-down-right:before {
  content: "";
}

.uil-corner-left-down:before {
  content: "";
}

.uil-corner-right-down:before {
  content: "";
}

.uil-corner-up-left-alt:before {
  content: "";
}

.uil-corner-up-left:before {
  content: "";
}

.uil-corner-up-right-alt:before {
  content: "";
}

.uil-corner-up-right:before {
  content: "";
}

.uil-creative-commons-pd-alt:before {
  content: "";
}

.uil-creative-commons-pd:before {
  content: "";
}

.uil-crockery:before {
  content: "";
}

.uil-crop-alt-rotate-left:before {
  content: "";
}

.uil-crop-alt-rotate-right:before {
  content: "";
}

.uil-crop-alt:before {
  content: "";
}

.uil-crosshair-alt:before {
  content: "";
}

.uil-crosshair:before {
  content: "";
}

.uil-crosshairs:before {
  content: "";
}

.uil-cube:before {
  content: "";
}

.uil-dashboard:before {
  content: "";
}

.uil-data-sharing:before {
  content: "";
}

.uil-database-alt:before {
  content: "";
}

.uil-database:before {
  content: "";
}

.uil-desert:before {
  content: "";
}

.uil-desktop-alt-slash:before {
  content: "";
}

.uil-desktop-alt:before {
  content: "";
}

.uil-desktop-cloud-alt:before {
  content: "";
}

.uil-desktop-slash:before {
  content: "";
}

.uil-desktop:before {
  content: "";
}

.uil-dialpad-alt:before {
  content: "";
}

.uil-dialpad:before {
  content: "";
}

.uil-diamond:before {
  content: "";
}

.uil-diary-alt:before {
  content: "";
}

.uil-diary:before {
  content: "";
}

.uil-dice-five:before {
  content: "";
}

.uil-dice-four:before {
  content: "";
}

.uil-dice-one:before {
  content: "";
}

.uil-dice-six:before {
  content: "";
}

.uil-dice-three:before {
  content: "";
}

.uil-dice-two:before {
  content: "";
}

.uil-direction:before {
  content: "";
}

.uil-directions:before {
  content: "";
}

.uil-dizzy-meh:before {
  content: "";
}

.uil-dna:before {
  content: "";
}

.uil-document-layout-center:before {
  content: "";
}

.uil-document-layout-left:before {
  content: "";
}

.uil-document-layout-right:before {
  content: "";
}

.uil-document:before {
  content: "";
}

.uil-dollar-alt:before {
  content: "";
}

.uil-dollar-sign-alt:before {
  content: "";
}

.uil-dollar-sign:before {
  content: "";
}

.uil-down-arrow:before {
  content: "";
}

.uil-download-alt:before {
  content: "";
}

.uil-dribbble:before {
  content: "";
}

.uil-drill:before {
  content: "";
}

.uil-dropbox:before {
  content: "";
}

.uil-dumbbell:before {
  content: "";
}

.uil-ear:before {
  content: "";
}

.uil-edit-alt:before {
  content: "";
}

.uil-edit:before {
  content: "";
}

.uil-ellipsis-h:before {
  content: "";
}

.uil-ellipsis-v:before {
  content: "";
}

.uil-emoji:before {
  content: "";
}

.uil-enter:before {
  content: "";
}

.uil-entry:before {
  content: "";
}

.uil-envelope-add:before {
  content: "";
}

.uil-envelope-alt:before {
  content: "";
}

.uil-envelope-block:before {
  content: "";
}

.uil-envelope-bookmark:before {
  content: "";
}

.uil-envelope-check:before {
  content: "";
}

.uil-envelope-download-alt:before {
  content: "";
}

.uil-envelope-download:before {
  content: "";
}

.uil-envelope-edit:before {
  content: "";
}

.uil-envelope-exclamation:before {
  content: "";
}

.uil-envelope-heart:before {
  content: "";
}

.uil-envelope-info:before {
  content: "";
}

.uil-envelope-lock:before {
  content: "";
}

.uil-envelope-minus:before {
  content: "";
}

.uil-envelope-open:before {
  content: "";
}

.uil-envelope-question:before {
  content: "";
}

.uil-envelope-receive:before {
  content: "";
}

.uil-envelope-redo:before {
  content: "";
}

.uil-envelope-search:before {
  content: "";
}

.uil-envelope-send:before {
  content: "";
}

.uil-envelope-share:before {
  content: "";
}

.uil-envelope-shield:before {
  content: "";
}

.uil-envelope-star:before {
  content: "";
}

.uil-envelope-times:before {
  content: "";
}

.uil-envelope-upload-alt:before {
  content: "";
}

.uil-envelope-upload:before {
  content: "";
}

.uil-envelope:before {
  content: "";
}

.uil-envelopes:before {
  content: "";
}

.uil-equal-circle:before {
  content: "";
}

.uil-euro-circle:before {
  content: "";
}

.uil-euro:before {
  content: "";
}

.uil-exchange-alt:before {
  content: "";
}

.uil-exchange:before {
  content: "";
}

.uil-exclamation-circle:before {
  content: "";
}

.uil-exclamation-octagon:before {
  content: "";
}

.uil-exclamation-triangle:before {
  content: "";
}

.uil-exclude:before {
  content: "";
}

.uil-exit:before {
  content: "";
}

.uil-expand-alt:before {
  content: "";
}

.uil-expand-arrows-alt:before {
  content: "";
}

.uil-expand-arrows:before {
  content: "";
}

.uil-expand-from-corner:before {
  content: "";
}

.uil-expand-left:before {
  content: "";
}

.uil-expand-right:before {
  content: "";
}

.uil-export:before {
  content: "";
}

.uil-exposure-alt:before {
  content: "";
}

.uil-exposure-increase:before {
  content: "";
}

.uil-external-link-alt:before {
  content: "";
}

.uil-eye-slash:before {
  content: "";
}

.uil-eye:before {
  content: "";
}

.uil-facebook-f:before {
  content: "";
}

.uil-facebook-messenger:before {
  content: "";
}

.uil-facebook:before {
  content: "";
}

.uil-fahrenheit:before {
  content: "";
}

.uil-fast-mail-alt:before {
  content: "";
}

.uil-fast-mail:before {
  content: "";
}

.uil-favorite:before {
  content: "";
}

.uil-feedback:before {
  content: "";
}

.uil-file-alt:before {
  content: "";
}

.uil-file-blank:before {
  content: "";
}

.uil-file-block-alt:before {
  content: "";
}

.uil-file-bookmark-alt:before {
  content: "";
}

.uil-file-check-alt:before {
  content: "";
}

.uil-file-check:before {
  content: "";
}

.uil-file-contract-dollar:before {
  content: "";
}

.uil-file-copy-alt:before {
  content: "";
}

.uil-file-download-alt:before {
  content: "";
}

.uil-file-download:before {
  content: "";
}

.uil-file-edit-alt:before {
  content: "";
}

.uil-file-exclamation-alt:before {
  content: "";
}

.uil-file-exclamation:before {
  content: "";
}

.uil-file-heart:before {
  content: "";
}

.uil-file-info-alt:before {
  content: "";
}

.uil-file-landscape-alt:before {
  content: "";
}

.uil-file-landscape:before {
  content: "";
}

.uil-file-lanscape-slash:before {
  content: "";
}

.uil-file-lock-alt:before {
  content: "";
}

.uil-file-medical-alt:before {
  content: "";
}

.uil-file-medical:before {
  content: "";
}

.uil-file-minus-alt:before {
  content: "";
}

.uil-file-minus:before {
  content: "";
}

.uil-file-network:before {
  content: "";
}

.uil-file-plus-alt:before {
  content: "";
}

.uil-file-plus:before {
  content: "";
}

.uil-file-question-alt:before {
  content: "";
}

.uil-file-question:before {
  content: "";
}

.uil-file-redo-alt:before {
  content: "";
}

.uil-file-search-alt:before {
  content: "";
}

.uil-file-share-alt:before {
  content: "";
}

.uil-file-shield-alt:before {
  content: "";
}

.uil-file-slash:before {
  content: "";
}

.uil-file-times-alt:before {
  content: "";
}

.uil-file-times:before {
  content: "";
}

.uil-file-upload-alt:before {
  content: "";
}

.uil-file-upload:before {
  content: "";
}

.uil-file:before {
  content: "";
}

.uil-files-landscapes-alt:before {
  content: "";
}

.uil-files-landscapes:before {
  content: "";
}

.uil-film:before {
  content: "";
}

.uil-filter-slash:before {
  content: "";
}

.uil-filter:before {
  content: "";
}

.uil-flask-potion:before {
  content: "";
}

.uil-flask:before {
  content: "";
}

.uil-flip-h-alt:before {
  content: "";
}

.uil-flip-h:before {
  content: "";
}

.uil-flip-v-alt:before {
  content: "";
}

.uil-flip-v:before {
  content: "";
}

.uil-flower:before {
  content: "";
}

.uil-focus-add:before {
  content: "";
}

.uil-focus-target:before {
  content: "";
}

.uil-focus:before {
  content: "";
}

.uil-folder-check:before {
  content: "";
}

.uil-folder-download:before {
  content: "";
}

.uil-folder-exclamation:before {
  content: "";
}

.uil-folder-heart:before {
  content: "";
}

.uil-folder-info:before {
  content: "";
}

.uil-folder-lock:before {
  content: "";
}

.uil-folder-medical:before {
  content: "";
}

.uil-folder-minus:before {
  content: "";
}

.uil-folder-network:before {
  content: "";
}

.uil-folder-plus:before {
  content: "";
}

.uil-folder-question:before {
  content: "";
}

.uil-folder-slash:before {
  content: "";
}

.uil-folder-times:before {
  content: "";
}

.uil-folder-upload:before {
  content: "";
}

.uil-folder:before {
  content: "";
}

.uil-food:before {
  content: "";
}

.uil-football-american:before {
  content: "";
}

.uil-football-ball:before {
  content: "";
}

.uil-football:before {
  content: "";
}

.uil-forecastcloud-moon-tear:before {
  content: "";
}

.uil-forwaded-call:before {
  content: "";
}

.uil-forward:before {
  content: "";
}

.uil-frown:before {
  content: "";
}

.uil-game-structure:before {
  content: "";
}

.uil-game:before {
  content: "";
}

.uil-gift:before {
  content: "";
}

.uil-github-alt:before {
  content: "";
}

.uil-github:before {
  content: "";
}

.uil-glass-martini-alt-slash:before {
  content: "";
}

.uil-glass-martini-alt:before {
  content: "";
}

.uil-glass-martini:before {
  content: "";
}

.uil-glass-tea:before {
  content: "";
}

.uil-glass:before {
  content: "";
}

.uil-globe:before {
  content: "";
}

.uil-gold:before {
  content: "";
}

.uil-google-drive-alt:before {
  content: "";
}

.uil-google-drive:before {
  content: "";
}

.uil-google-hangouts:before {
  content: "";
}

.uil-google-play:before {
  content: "";
}

.uil-google:before {
  content: "";
}

.uil-graduation-hat:before {
  content: "";
}

.uil-graph-bar:before {
  content: "";
}

.uil-grid:before {
  content: "";
}

.uil-grids:before {
  content: "";
}

.uil-grin-tongue-wink-alt:before {
  content: "";
}

.uil-grin-tongue-wink:before {
  content: "";
}

.uil-grin:before {
  content: "";
}

.uil-grip-horizontal-line:before {
  content: "";
}

.uil-hdd:before {
  content: "";
}

.uil-headphones-alt:before {
  content: "";
}

.uil-headphones:before {
  content: "";
}

.uil-heart-alt:before {
  content: "";
}

.uil-heart-medical:before {
  content: "";
}

.uil-heart-rate:before {
  content: "";
}

.uil-heart-sign:before {
  content: "";
}

.uil-heart:before {
  content: "";
}

.uil-heartbeat:before {
  content: "";
}

.uil-history-alt:before {
  content: "";
}

.uil-history:before {
  content: "";
}

.uil-home-alt:before {
  content: "";
}

.uil-home:before {
  content: "";
}

.uil-horizontal-align-center:before {
  content: "";
}

.uil-horizontal-align-left:before {
  content: "";
}

.uil-horizontal-align-right:before {
  content: "";
}

.uil-horizontal-distribution-center:before {
  content: "";
}

.uil-horizontal-distribution-left:before {
  content: "";
}

.uil-horizontal-distribution-right:before {
  content: "";
}

.uil-hunting:before {
  content: "";
}

.uil-image-alt-slash:before {
  content: "";
}

.uil-image-block:before {
  content: "";
}

.uil-image-broken:before {
  content: "";
}

.uil-image-check:before {
  content: "";
}

.uil-image-download:before {
  content: "";
}

.uil-image-edit:before {
  content: "";
}

.uil-image-lock:before {
  content: "";
}

.uil-image-minus:before {
  content: "";
}

.uil-image-plus:before {
  content: "";
}

.uil-image-question:before {
  content: "";
}

.uil-image-redo:before {
  content: "";
}

.uil-image-resize-landscape:before {
  content: "";
}

.uil-image-resize-square:before {
  content: "";
}

.uil-image-search:before {
  content: "";
}

.uil-image-share:before {
  content: "";
}

.uil-image-shield:before {
  content: "";
}

.uil-image-slash:before {
  content: "";
}

.uil-image-times:before {
  content: "";
}

.uil-image-upload:before {
  content: "";
}

.uil-image-v:before {
  content: "";
}

.uil-image:before {
  content: "";
}

.uil-images:before {
  content: "";
}

.uil-incoming-call:before {
  content: "";
}

.uil-info-circle:before {
  content: "";
}

.uil-instagram-alt:before {
  content: "";
}

.uil-instagram:before {
  content: "";
}

.uil-intercom:before {
  content: "";
}

.uil-invoice:before {
  content: "";
}

.uil-italic:before {
  content: "";
}

.uil-jackhammer:before {
  content: "";
}

.uil-java-script:before {
  content: "";
}

.uil-kayak:before {
  content: "";
}

.uil-key-skeleton-alt:before {
  content: "";
}

.uil-key-skeleton:before {
  content: "";
}

.uil-keyboard-alt:before {
  content: "";
}

.uil-keyboard-hide:before {
  content: "";
}

.uil-keyboard-show:before {
  content: "";
}

.uil-keyboard:before {
  content: "";
}

.uil-keyhole-circle:before {
  content: "";
}

.uil-keyhole-square-full:before {
  content: "";
}

.uil-keyhole-square:before {
  content: "";
}

.uil-kid:before {
  content: "";
}

.uil-label-alt:before {
  content: "";
}

.uil-label:before {
  content: "";
}

.uil-lamp:before {
  content: "";
}

.uil-laptop-cloud:before {
  content: "";
}

.uil-laptop:before {
  content: "";
}

.uil-laughing:before {
  content: "";
}

.uil-layer-group-slash:before {
  content: "";
}

.uil-layer-group:before {
  content: "";
}

.uil-layers-alt:before {
  content: "";
}

.uil-layers-slash:before {
  content: "";
}

.uil-layers:before {
  content: "";
}

.uil-left-arrow-from-left:before {
  content: "";
}

.uil-left-arrow-to-left:before {
  content: "";
}

.uil-left-indent-alt:before {
  content: "";
}

.uil-left-indent:before {
  content: "";
}

.uil-left-to-right-text-direction:before {
  content: "";
}

.uil-life-ring:before {
  content: "";
}

.uil-lightbulb-alt:before {
  content: "";
}

.uil-lightbulb:before {
  content: "";
}

.uil-line-alt:before {
  content: "";
}

.uil-line-spacing:before {
  content: "";
}

.uil-line:before {
  content: "";
}

.uil-link-alt:before {
  content: "";
}

.uil-link-broken:before {
  content: "";
}

.uil-link-h:before {
  content: "";
}

.uil-link:before {
  content: "";
}

.uil-linkedin-alt:before {
  content: "";
}

.uil-linkedin:before {
  content: "";
}

.uil-list-ui-alt:before {
  content: "";
}

.uil-list-ul:before {
  content: "";
}

.uil-location-arrow-alt:before {
  content: "";
}

.uil-location-arrow:before {
  content: "";
}

.uil-location-pin-alt:before {
  content: "";
}

.uil-location-point:before {
  content: "";
}

.uil-location:before {
  content: "";
}

.uil-lock-access:before {
  content: "";
}

.uil-lock-alt:before {
  content: "";
}

.uil-lock-open-alt:before {
  content: "";
}

.uil-lock-slash:before {
  content: "";
}

.uil-lock:before {
  content: "";
}

.uil-mailbox-alt:before {
  content: "";
}

.uil-mailbox:before {
  content: "";
}

.uil-map-marker-alt:before {
  content: "";
}

.uil-map-marker-edit:before {
  content: "";
}

.uil-map-marker-info:before {
  content: "";
}

.uil-map-marker-minus:before {
  content: "";
}

.uil-map-marker-plus:before {
  content: "";
}

.uil-map-marker-question:before {
  content: "";
}

.uil-map-marker-shield:before {
  content: "";
}

.uil-map-marker-slash:before {
  content: "";
}

.uil-map-marker:before {
  content: "";
}

.uil-map-pin-alt:before {
  content: "";
}

.uil-map-pin:before {
  content: "";
}

.uil-map:before {
  content: "";
}

.uil-mars:before {
  content: "";
}

.uil-maximize-left:before {
  content: "";
}

.uil-medal:before {
  content: "";
}

.uil-medical-drip:before {
  content: "";
}

.uil-medical-square-full:before {
  content: "";
}

.uil-medical-square:before {
  content: "";
}

.uil-medical:before {
  content: "";
}

.uil-medium-m:before {
  content: "";
}

.uil-medkit:before {
  content: "";
}

.uil-meeting-board:before {
  content: "";
}

.uil-meh-alt:before {
  content: "";
}

.uil-meh-closed-eye:before {
  content: "";
}

.uil-meh:before {
  content: "";
}

.uil-message:before {
  content: "";
}

.uil-microphone-slash:before {
  content: "";
}

.uil-microphone:before {
  content: "";
}

.uil-minus-circle:before {
  content: "";
}

.uil-minus-path:before {
  content: "";
}

.uil-minus-square-full:before {
  content: "";
}

.uil-minus-square:before {
  content: "";
}

.uil-minus:before {
  content: "";
}

.uil-missed-call:before {
  content: "";
}

.uil-mobey-bill-slash:before {
  content: "";
}

.uil-mobile-android-alt:before {
  content: "";
}

.uil-mobile-android:before {
  content: "";
}

.uil-mobile-vibrate:before {
  content: "";
}

.uil-modem:before {
  content: "";
}

.uil-money-bill-stack:before {
  content: "";
}

.uil-money-bill:before {
  content: "";
}

.uil-money-insert:before {
  content: "";
}

.uil-money-stack:before {
  content: "";
}

.uil-money-withdraw:before {
  content: "";
}

.uil-money-withdrawal:before {
  content: "";
}

.uil-moneybag-alt:before {
  content: "";
}

.uil-moneybag:before {
  content: "";
}

.uil-monitor-heart-rate:before {
  content: "";
}

.uil-monitor:before {
  content: "";
}

.uil-moon-eclipse:before {
  content: "";
}

.uil-moon:before {
  content: "";
}

.uil-moonset:before {
  content: "";
}

.uil-mountains-sun:before {
  content: "";
}

.uil-mountains:before {
  content: "";
}

.uil-mouse-alt:before {
  content: "";
}

.uil-mouse:before {
  content: "";
}

.uil-multiply:before {
  content: "";
}

.uil-music-note:before {
  content: "";
}

.uil-music-tune-slash:before {
  content: "";
}

.uil-music:before {
  content: "";
}

.uil-n-a:before {
  content: "";
}

.uil-navigator:before {
  content: "";
}

.uil-nerd:before {
  content: "";
}

.uil-newspaper:before {
  content: "";
}

.uil-ninja:before {
  content: "";
}

.uil-no-entry:before {
  content: "";
}

.uil-notebooks:before {
  content: "";
}

.uil-notes:before {
  content: "";
}

.uil-object-group:before {
  content: "";
}

.uil-object-ungroup:before {
  content: "";
}

.uil-octagon:before {
  content: "";
}

.uil-outgoing-call:before {
  content: "";
}

.uil-package:before {
  content: "";
}

.uil-padlock:before {
  content: "";
}

.uil-paint-tool:before {
  content: "";
}

.uil-palette:before {
  content: "";
}

.uil-panorama-h-alt:before {
  content: "";
}

.uil-panorama-h:before {
  content: "";
}

.uil-panorama-v:before {
  content: "";
}

.uil-paperclip:before {
  content: "";
}

.uil-paragraph:before {
  content: "";
}

.uil-parcel:before {
  content: "";
}

.uil-parking-square:before {
  content: "";
}

.uil-pathfinder-unite:before {
  content: "";
}

.uil-pathfinder:before {
  content: "";
}

.uil-pause-circle:before {
  content: "";
}

.uil-pause:before {
  content: "";
}

.uil-paypal:before {
  content: "";
}

.uil-pen:before {
  content: "";
}

.uil-pentagon:before {
  content: "";
}

.uil-phone-alt:before {
  content: "";
}

.uil-phone-pause:before {
  content: "";
}

.uil-phone-slash:before {
  content: "";
}

.uil-phone-times:before {
  content: "";
}

.uil-phone-volume:before {
  content: "";
}

.uil-phone:before {
  content: "";
}

.uil-picture:before {
  content: "";
}

.uil-plane-arrival:before {
  content: "";
}

.uil-plane-departure:before {
  content: "";
}

.uil-plane-fly:before {
  content: "";
}

.uil-plane:before {
  content: "";
}

.uil-play-circle:before {
  content: "";
}

.uil-play:before {
  content: "";
}

.uil-plug:before {
  content: "";
}

.uil-plus-circle:before {
  content: "";
}

.uil-plus-square:before {
  content: "";
}

.uil-plus:before {
  content: "";
}

.uil-podium:before {
  content: "";
}

.uil-polygon:before {
  content: "";
}

.uil-post-stamp:before {
  content: "";
}

.uil-postcard:before {
  content: "";
}

.uil-pound-circle:before {
  content: "";
}

.uil-pound:before {
  content: "";
}

.uil-power:before {
  content: "";
}

.uil-prescription-bottle:before {
  content: "";
}

.uil-presentation-check:before {
  content: "";
}

.uil-presentation-edit:before {
  content: "";
}

.uil-presentation-line:before {
  content: "";
}

.uil-presentation-lines-alt:before {
  content: "";
}

.uil-presentation-minus:before {
  content: "";
}

.uil-presentation-play:before {
  content: "";
}

.uil-presentation-plus:before {
  content: "";
}

.uil-presentation-times:before {
  content: "";
}

.uil-presentation:before {
  content: "";
}

.uil-previous:before {
  content: "";
}

.uil-pricetag-alt:before {
  content: "";
}

.uil-print-slash:before {
  content: "";
}

.uil-print:before {
  content: "";
}

.uil-processor:before {
  content: "";
}

.uil-pump:before {
  content: "";
}

.uil-puzzle-piece:before {
  content: "";
}

.uil-question-circle:before {
  content: "";
}

.uil-raddit-alien-alt:before {
  content: "";
}

.uil-rainbow:before {
  content: "";
}

.uil-raindrops-alt:before {
  content: "";
}

.uil-raindrops:before {
  content: "";
}

.uil-receipt-alt:before {
  content: "";
}

.uil-receipt:before {
  content: "";
}

.uil-record-audio:before {
  content: "";
}

.uil-redo:before {
  content: "";
}

.uil-refresh:before {
  content: "";
}

.uil-registered:before {
  content: "";
}

.uil-repeat:before {
  content: "";
}

.uil-restaurant:before {
  content: "";
}

.uil-right-indent-alt:before {
  content: "";
}

.uil-right-to-left-text-direction:before {
  content: "";
}

.uil-robot:before {
  content: "";
}

.uil-rope-way:before {
  content: "";
}

.uil-rotate-360:before {
  content: "";
}

.uil-rss-alt:before {
  content: "";
}

.uil-rss-interface:before {
  content: "";
}

.uil-rss:before {
  content: "";
}

.uil-ruler-combined:before {
  content: "";
}

.uil-ruler:before {
  content: "";
}

.uil-sad-cry:before {
  content: "";
}

.uil-sad-crying:before {
  content: "";
}

.uil-sad-dizzy:before {
  content: "";
}

.uil-sad-squint:before {
  content: "";
}

.uil-sad:before {
  content: "";
}

.uil-scaling-left:before {
  content: "";
}

.uil-scaling-right:before {
  content: "";
}

.uil-scenery:before {
  content: "";
}

.uil-schedule:before {
  content: "";
}

.uil-science:before {
  content: "";
}

.uil-screw:before {
  content: "";
}

.uil-scroll-h:before {
  content: "";
}

.uil-scroll:before {
  content: "";
}

.uil-search-alt:before {
  content: "";
}

.uil-search-minus:before {
  content: "";
}

.uil-search-plus:before {
  content: "";
}

.uil-search:before {
  content: "";
}

.uil-selfie:before {
  content: "";
}

.uil-server-alt:before {
  content: "";
}

.uil-server-connection:before {
  content: "";
}

.uil-server-network-alt:before {
  content: "";
}

.uil-server-network:before {
  content: "";
}

.uil-server:before {
  content: "";
}

.uil-servers:before {
  content: "";
}

.uil-servicemark:before {
  content: "";
}

.uil-share-alt:before {
  content: "";
}

.uil-shield-check:before {
  content: "";
}

.uil-shield-exclamation:before {
  content: "";
}

.uil-shield-question:before {
  content: "";
}

.uil-shield-slash:before {
  content: "";
}

.uil-shield:before {
  content: "";
}

.uil-ship:before {
  content: "";
}

.uil-shop:before {
  content: "";
}

.uil-shopping-basket:before {
  content: "";
}

.uil-shopping-cart-alt:before {
  content: "";
}

.uil-shopping-trolley:before {
  content: "";
}

.uil-shovel:before {
  content: "";
}

.uil-shrink:before {
  content: "";
}

.uil-shuffle:before {
  content: "";
}

.uil-shutter-alt:before {
  content: "";
}

.uil-shutter:before {
  content: "";
}

.uil-sick:before {
  content: "";
}

.uil-sigma:before {
  content: "";
}

.uil-sign-alt:before {
  content: "";
}

.uil-sign-in-alt:before {
  content: "";
}

.uil-sign-left:before {
  content: "";
}

.uil-sign-out-alt:before {
  content: "";
}

.uil-sign-right:before {
  content: "";
}

.uil-signal-alt-3:before {
  content: "";
}

.uil-signal-alt:before {
  content: "";
}

.uil-signal:before {
  content: "";
}

.uil-silence:before {
  content: "";
}

.uil-silent-squint:before {
  content: "";
}

.uil-sim-card:before {
  content: "";
}

.uil-sitemap:before {
  content: "";
}

.uil-skip-forward-alt:before {
  content: "";
}

.uil-skip-forward-circle:before {
  content: "";
}

.uil-skip-forward:before {
  content: "";
}

.uil-slack-alt:before {
  content: "";
}

.uil-slack:before {
  content: "";
}

.uil-sliders-v-alt:before {
  content: "";
}

.uil-sliders-v:before {
  content: "";
}

.uil-smile-beam:before {
  content: "";
}

.uil-smile-dizzy:before {
  content: "";
}

.uil-smile-squint-wink-alt:before {
  content: "";
}

.uil-smile-squint-wink:before {
  content: "";
}

.uil-smile-wink-alt:before {
  content: "";
}

.uil-smile-wink:before {
  content: "";
}

.uil-smile:before {
  content: "";
}

.uil-snapchat-ghost:before {
  content: "";
}

.uil-snapchat-square:before {
  content: "";
}

.uil-snow-flake:before {
  content: "";
}

.uil-snowflake-alt:before {
  content: "";
}

.uil-snowflake:before {
  content: "";
}

.uil-sort-amount-down:before {
  content: "";
}

.uil-sort-amount-up:before {
  content: "";
}

.uil-sort:before {
  content: "";
}

.uil-sorting:before {
  content: "";
}

.uil-space-key:before {
  content: "";
}

.uil-spade:before {
  content: "";
}

.uil-sperms:before {
  content: "";
}

.uil-spin:before {
  content: "";
}

.uil-sport:before {
  content: "";
}

.uil-square-full:before {
  content: "";
}

.uil-square-shape:before {
  content: "";
}

.uil-square:before {
  content: "";
}

.uil-squint:before {
  content: "";
}

.uil-star-half-alt:before {
  content: "";
}

.uil-star:before {
  content: "";
}

.uil-step-backward-alt:before {
  content: "";
}

.uil-step-backward-circle:before {
  content: "";
}

.uil-step-backward:before {
  content: "";
}

.uil-step-forward:before {
  content: "";
}

.uil-stop-circle:before {
  content: "";
}

.uil-stopwatch-slash:before {
  content: "";
}

.uil-stopwatch:before {
  content: "";
}

.uil-store-alt:before {
  content: "";
}

.uil-store:before {
  content: "";
}

.uil-streering:before {
  content: "";
}

.uil-stretcher:before {
  content: "";
}

.uil-subject:before {
  content: "";
}

.uil-subway-alt:before {
  content: "";
}

.uil-subway:before {
  content: "";
}

.uil-suitcase-alt:before {
  content: "";
}

.uil-suitcase:before {
  content: "";
}

.uil-sun:before {
  content: "";
}

.uil-sunset:before {
  content: "";
}

.uil-surprise:before {
  content: "";
}

.uil-swatchbook:before {
  content: "";
}

.uil-swimmer:before {
  content: "";
}

.uil-symbol:before {
  content: "";
}

.uil-sync-exclamation:before {
  content: "";
}

.uil-sync-slash:before {
  content: "";
}

.uil-sync:before {
  content: "";
}

.uil-syringe:before {
  content: "";
}

.uil-table:before {
  content: "";
}

.uil-tablet:before {
  content: "";
}

.uil-tablets:before {
  content: "";
}

.uil-tachometer-fast:before {
  content: "";
}

.uil-tag-alt:before {
  content: "";
}

.uil-tag:before {
  content: "";
}

.uil-tape:before {
  content: "";
}

.uil-taxi:before {
  content: "";
}

.uil-tear:before {
  content: "";
}

.uil-technology:before {
  content: "";
}

.uil-telescope:before {
  content: "";
}

.uil-temperature-empty:before {
  content: "";
}

.uil-temperature-half:before {
  content: "";
}

.uil-temperature-minus:before {
  content: "";
}

.uil-temperature-plus:before {
  content: "";
}

.uil-temperature-quarter:before {
  content: "";
}

.uil-temperature-three-quarter:before {
  content: "";
}

.uil-temperature:before {
  content: "";
}

.uil-text-fields:before {
  content: "";
}

.uil-text-size:before {
  content: "";
}

.uil-text-strike-through:before {
  content: "";
}

.uil-text:before {
  content: "";
}

.uil-th-large:before {
  content: "";
}

.uil-th-slash:before {
  content: "";
}

.uil-th:before {
  content: "";
}

.uil-thermometer:before {
  content: "";
}

.uil-thumbs-down:before {
  content: "";
}

.uil-thumbs-up:before {
  content: "";
}

.uil-thunderstorm-moon:before {
  content: "";
}

.uil-thunderstorm-sun:before {
  content: "";
}

.uil-thunderstorm:before {
  content: "";
}

.uil-ticket:before {
  content: "";
}

.uil-times-circle:before {
  content: "";
}

.uil-times-square:before {
  content: "";
}

.uil-times:before {
  content: "";
}

.uil-toggle-off:before {
  content: "";
}

.uil-toggle-on:before {
  content: "";
}

.uil-top-arrow-from-top:before {
  content: "";
}

.uil-top-arrow-to-top:before {
  content: "";
}

.uil-tornado:before {
  content: "";
}

.uil-trademark-circle:before {
  content: "";
}

.uil-trademark:before {
  content: "";
}

.uil-traffic-barrier:before {
  content: "";
}

.uil-trash-alt:before {
  content: "";
}

.uil-trash:before {
  content: "";
}

.uil-trees:before {
  content: "";
}

.uil-triangle:before {
  content: "";
}

.uil-trophy:before {
  content: "";
}

.uil-trowel:before {
  content: "";
}

.uil-truck-case:before {
  content: "";
}

.uil-truck-loading:before {
  content: "";
}

.uil-truck:before {
  content: "";
}

.uil-tumblr-square:before {
  content: "";
}

.uil-tumblr:before {
  content: "";
}

.uil-tv-retro-slash:before {
  content: "";
}

.uil-tv-retro:before {
  content: "";
}

.uil-twitter:before {
  content: "";
}

.uil-umbrella:before {
  content: "";
}

.uil-unamused:before {
  content: "";
}

.uil-underline:before {
  content: "";
}

.uil-unlock-alt:before {
  content: "";
}

.uil-unlock:before {
  content: "";
}

.uil-upload-alt:before {
  content: "";
}

.uil-upload:before {
  content: "";
}

.uil-usd-circle:before {
  content: "";
}

.uil-usd-square:before {
  content: "";
}

.uil-user-check:before {
  content: "";
}

.uil-user-circle:before {
  content: "";
}

.uil-user-exclamation:before {
  content: "";
}

.uil-user-hard-hat:before {
  content: "";
}

.uil-user-minus:before {
  content: "";
}

.uil-user-plus:before {
  content: "";
}

.uil-user-square:before {
  content: "";
}

.uil-user-times:before {
  content: "";
}

.uil-user:before {
  content: "";
}

.uil-users-alt:before {
  content: "";
}

.uil-utensils-alt:before {
  content: "";
}

.uil-utensils:before {
  content: "";
}

.uil-vector-square-alt:before {
  content: "";
}

.uil-vector-square:before {
  content: "";
}

.uil-venus:before {
  content: "";
}

.uil-vertical-align-bottom:before {
  content: "";
}

.uil-vertical-align-center:before {
  content: "";
}

.uil-vertical-align-top:before {
  content: "";
}

.uil-vertical-distribute-bottom:before {
  content: "";
}

.uil-vertical-distribution-center:before {
  content: "";
}

.uil-vertical-distribution-top:before {
  content: "";
}

.uil-video-slash:before {
  content: "";
}

.uil-video:before {
  content: "";
}

.uil-visual-studio:before {
  content: "";
}

.uil-voicemail-rectangle:before {
  content: "";
}

.uil-voicemail:before {
  content: "";
}

.uil-volleyball:before {
  content: "";
}

.uil-volume-down:before {
  content: "";
}

.uil-volume-mute:before {
  content: "";
}

.uil-volume-off:before {
  content: "";
}

.uil-volume-up:before {
  content: "";
}

.uil-volume:before {
  content: "";
}

.uil-vuejs-alt:before {
  content: "";
}

.uil-vuejs:before {
  content: "";
}

.uil-wall:before {
  content: "";
}

.uil-wallet:before {
  content: "";
}

.uil-watch-alt:before {
  content: "";
}

.uil-watch:before {
  content: "";
}

.uil-water-drop-slash:before {
  content: "";
}

.uil-water-glass:before {
  content: "";
}

.uil-water:before {
  content: "";
}

.uil-web-grid-alt:before {
  content: "";
}

.uil-web-grid:before {
  content: "";
}

.uil-web-section-alt:before {
  content: "";
}

.uil-web-section:before {
  content: "";
}

.uil-webcam:before {
  content: "";
}

.uil-weight:before {
  content: "";
}

.uil-whatsapp:before {
  content: "";
}

.uil-wheel-barrow:before {
  content: "";
}

.uil-wheelchair-alt:before {
  content: "";
}

.uil-wheelchair:before {
  content: "";
}

.uil-wifi-router:before {
  content: "";
}

.uil-wifi-slash:before {
  content: "";
}

.uil-wifi:before {
  content: "";
}

.uil-wind:before {
  content: "";
}

.uil-window-grid:before {
  content: "";
}

.uil-window-maximize:before {
  content: "";
}

.uil-window-restore:before {
  content: "";
}

.uil-window-section:before {
  content: "";
}

.uil-window:before {
  content: "";
}

.uil-windsock:before {
  content: "";
}

.uil-wrap-text:before {
  content: "";
}

.uil-wrench:before {
  content: "";
}

.uil-yellow:before {
  content: "";
}

.uil-yen-circle:before {
  content: "";
}

.uil-yen:before {
  content: "";
}

.uil-youtube-alt:before {
  content: "";
}

.uil-youtube:before {
  content: "";
}

@font-face {
  font-family: dripicons-v2;
  src: url("dripicons-v2-5AYRRFL2-5AYRRFL2.c676bec5.eot");
  src: url("dripicons-v2-5AYRRFL2-5AYRRFL2.c676bec5.eot#iefix") format("embedded-opentype"), url("dripicons-v2-7G663SWC-7G663SWC.e98d67d1.woff") format("woff"), url("dripicons-v2-BAJBQZT5-BAJBQZT5.f6e0990e.ttf") format("truetype"), url("dripicons-v2-VZN7AL23-VZN7AL23.ccb6d089.svg#dripicons-v2") format("svg");
  font-weight: 400;
  font-style: normal;
}

[data-icon]:before {
  content: attr(data-icon);
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1;
  font-variant: normal !important;
  text-transform: none !important;
  font-family: dripicons-v2 !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

[class^="dripicons-"]:before, [class*=" dripicons-"]:before {
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: middle;
  line-height: 1;
  display: inline-block;
  font-variant: normal !important;
  text-transform: none !important;
  font-family: dripicons-v2 !important;
  font-style: normal !important;
  font-weight: 400 !important;
}

.dripicons-alarm:before {
  content: "a";
}

.dripicons-align-center:before {
  content: "b";
}

.dripicons-align-justify:before {
  content: "c";
}

.dripicons-align-left:before {
  content: "d";
}

.dripicons-align-right:before {
  content: "e";
}

.dripicons-anchor:before {
  content: "f";
}

.dripicons-archive:before {
  content: "g";
}

.dripicons-arrow-down:before {
  content: "h";
}

.dripicons-arrow-left:before {
  content: "i";
}

.dripicons-arrow-right:before {
  content: "j";
}

.dripicons-arrow-thin-down:before {
  content: "k";
}

.dripicons-arrow-thin-left:before {
  content: "l";
}

.dripicons-arrow-thin-right:before {
  content: "m";
}

.dripicons-arrow-thin-up:before {
  content: "n";
}

.dripicons-arrow-up:before {
  content: "o";
}

.dripicons-article:before {
  content: "p";
}

.dripicons-backspace:before {
  content: "q";
}

.dripicons-basket:before {
  content: "r";
}

.dripicons-basketball:before {
  content: "s";
}

.dripicons-battery-empty:before {
  content: "t";
}

.dripicons-battery-full:before {
  content: "u";
}

.dripicons-battery-low:before {
  content: "v";
}

.dripicons-battery-medium:before {
  content: "w";
}

.dripicons-bell:before {
  content: "x";
}

.dripicons-blog:before {
  content: "y";
}

.dripicons-bluetooth:before {
  content: "z";
}

.dripicons-bold:before {
  content: "A";
}

.dripicons-bookmark:before {
  content: "B";
}

.dripicons-bookmarks:before {
  content: "C";
}

.dripicons-box:before {
  content: "D";
}

.dripicons-briefcase:before {
  content: "E";
}

.dripicons-brightness-low:before {
  content: "F";
}

.dripicons-brightness-max:before {
  content: "G";
}

.dripicons-brightness-medium:before {
  content: "H";
}

.dripicons-broadcast:before {
  content: "I";
}

.dripicons-browser:before {
  content: "J";
}

.dripicons-browser-upload:before {
  content: "K";
}

.dripicons-brush:before {
  content: "L";
}

.dripicons-calendar:before {
  content: "M";
}

.dripicons-camcorder:before {
  content: "N";
}

.dripicons-camera:before {
  content: "O";
}

.dripicons-card:before {
  content: "P";
}

.dripicons-cart:before {
  content: "Q";
}

.dripicons-checklist:before {
  content: "R";
}

.dripicons-checkmark:before {
  content: "S";
}

.dripicons-chevron-down:before {
  content: "T";
}

.dripicons-chevron-left:before {
  content: "U";
}

.dripicons-chevron-right:before {
  content: "V";
}

.dripicons-chevron-up:before {
  content: "W";
}

.dripicons-clipboard:before {
  content: "X";
}

.dripicons-clock:before {
  content: "Y";
}

.dripicons-clockwise:before {
  content: "Z";
}

.dripicons-cloud:before {
  content: "0";
}

.dripicons-cloud-download:before {
  content: "1";
}

.dripicons-cloud-upload:before {
  content: "2";
}

.dripicons-code:before {
  content: "3";
}

.dripicons-contract:before {
  content: "4";
}

.dripicons-contract-2:before {
  content: "5";
}

.dripicons-conversation:before {
  content: "6";
}

.dripicons-copy:before {
  content: "7";
}

.dripicons-crop:before {
  content: "8";
}

.dripicons-cross:before {
  content: "9";
}

.dripicons-crosshair:before {
  content: "!";
}

.dripicons-cutlery:before {
  content: "\"";
}

.dripicons-device-desktop:before {
  content: "#";
}

.dripicons-device-mobile:before {
  content: "$";
}

.dripicons-device-tablet:before {
  content: "%";
}

.dripicons-direction:before {
  content: "&";
}

.dripicons-disc:before {
  content: "'";
}

.dripicons-document:before {
  content: "(";
}

.dripicons-document-delete:before {
  content: ")";
}

.dripicons-document-edit:before {
  content: "*";
}

.dripicons-document-new:before {
  content: "+";
}

.dripicons-document-remove:before {
  content: ",";
}

.dripicons-dot:before {
  content: "-";
}

.dripicons-dots-2:before {
  content: ".";
}

.dripicons-dots-3:before {
  content: "/";
}

.dripicons-download:before {
  content: ":";
}

.dripicons-duplicate:before {
  content: ";";
}

.dripicons-enter:before {
  content: "<";
}

.dripicons-exit:before {
  content: "=";
}

.dripicons-expand:before {
  content: ">";
}

.dripicons-expand-2:before {
  content: "?";
}

.dripicons-experiment:before {
  content: "@";
}

.dripicons-export:before {
  content: "[";
}

.dripicons-feed:before {
  content: "]";
}

.dripicons-flag:before {
  content: "^";
}

.dripicons-flashlight:before {
  content: "_";
}

.dripicons-folder:before {
  content: "`";
}

.dripicons-folder-open:before {
  content: "{";
}

.dripicons-forward:before {
  content: "|";
}

.dripicons-gaming:before {
  content: "}";
}

.dripicons-gear:before {
  content: "~";
}

.dripicons-graduation:before {
  content: "\\";
}

.dripicons-graph-bar:before {
  content: "";
}

.dripicons-graph-line:before {
  content: "";
}

.dripicons-graph-pie:before {
  content: "";
}

.dripicons-headset:before {
  content: "";
}

.dripicons-heart:before {
  content: "";
}

.dripicons-help:before {
  content: "";
}

.dripicons-home:before {
  content: "";
}

.dripicons-hourglass:before {
  content: "";
}

.dripicons-inbox:before {
  content: "";
}

.dripicons-information:before {
  content: "";
}

.dripicons-italic:before {
  content: "";
}

.dripicons-jewel:before {
  content: "";
}

.dripicons-lifting:before {
  content: "";
}

.dripicons-lightbulb:before {
  content: "";
}

.dripicons-link:before {
  content: "";
}

.dripicons-link-broken:before {
  content: "";
}

.dripicons-list:before {
  content: "";
}

.dripicons-loading:before {
  content: "";
}

.dripicons-location:before {
  content: "";
}

.dripicons-lock:before {
  content: "";
}

.dripicons-lock-open:before {
  content: "";
}

.dripicons-mail:before {
  content: "";
}

.dripicons-map:before {
  content: "";
}

.dripicons-media-loop:before {
  content: "";
}

.dripicons-media-next:before {
  content: "";
}

.dripicons-media-pause:before {
  content: "";
}

.dripicons-media-play:before {
  content: "";
}

.dripicons-media-previous:before {
  content: "";
}

.dripicons-media-record:before {
  content: "";
}

.dripicons-media-shuffle:before {
  content: "";
}

.dripicons-media-stop:before {
  content: "";
}

.dripicons-medical:before {
  content: "";
}

.dripicons-menu:before {
  content: "";
}

.dripicons-message:before {
  content: "";
}

.dripicons-meter:before {
  content: "";
}

.dripicons-microphone:before {
  content: "";
}

.dripicons-minus:before {
  content: "";
}

.dripicons-monitor:before {
  content: "";
}

.dripicons-move:before {
  content: "";
}

.dripicons-music:before {
  content: "";
}

.dripicons-network-1:before {
  content: "";
}

.dripicons-network-2:before {
  content: "";
}

.dripicons-network-3:before {
  content: "";
}

.dripicons-network-4:before {
  content: "";
}

.dripicons-network-5:before {
  content: "";
}

.dripicons-pamphlet:before {
  content: "";
}

.dripicons-paperclip:before {
  content: "";
}

.dripicons-pencil:before {
  content: "";
}

.dripicons-phone:before {
  content: "";
}

.dripicons-photo:before {
  content: "";
}

.dripicons-photo-group:before {
  content: "";
}

.dripicons-pill:before {
  content: "";
}

.dripicons-pin:before {
  content: "";
}

.dripicons-plus:before {
  content: "";
}

.dripicons-power:before {
  content: "";
}

.dripicons-preview:before {
  content: "";
}

.dripicons-print:before {
  content: "";
}

.dripicons-pulse:before {
  content: "";
}

.dripicons-question:before {
  content: "";
}

.dripicons-reply:before {
  content: "";
}

.dripicons-reply-all:before {
  content: "";
}

.dripicons-return:before {
  content: "";
}

.dripicons-retweet:before {
  content: "";
}

.dripicons-rocket:before {
  content: "";
}

.dripicons-scale:before {
  content: "";
}

.dripicons-search:before {
  content: "";
}

.dripicons-shopping-bag:before {
  content: "";
}

.dripicons-skip:before {
  content: "";
}

.dripicons-stack:before {
  content: "";
}

.dripicons-star:before {
  content: "";
}

.dripicons-stopwatch:before {
  content: "";
}

.dripicons-store:before {
  content: "";
}

.dripicons-suitcase:before {
  content: "";
}

.dripicons-swap:before {
  content: "";
}

.dripicons-tag:before {
  content: "";
}

.dripicons-tag-delete:before {
  content: "";
}

.dripicons-tags:before {
  content: "";
}

.dripicons-thumbs-down:before {
  content: "";
}

.dripicons-thumbs-up:before {
  content: "";
}

.dripicons-ticket:before {
  content: "";
}

.dripicons-time-reverse:before {
  content: "";
}

.dripicons-to-do:before {
  content: "";
}

.dripicons-toggles:before {
  content: "";
}

.dripicons-trash:before {
  content: "";
}

.dripicons-trophy:before {
  content: "";
}

.dripicons-upload:before {
  content: "";
}

.dripicons-user:before {
  content: "";
}

.dripicons-user-group:before {
  content: "";
}

.dripicons-user-id:before {
  content: "";
}

.dripicons-vibrate:before {
  content: "";
}

.dripicons-view-apps:before {
  content: "";
}

.dripicons-view-list:before {
  content: "";
}

.dripicons-view-list-large:before {
  content: "";
}

.dripicons-view-thumb:before {
  content: "";
}

.dripicons-volume-full:before {
  content: "";
}

.dripicons-volume-low:before {
  content: "";
}

.dripicons-volume-medium:before {
  content: "";
}

.dripicons-volume-off:before {
  content: "";
}

.dripicons-wallet:before {
  content: "";
}

.dripicons-warning:before {
  content: "";
}

.dripicons-web:before {
  content: "";
}

.dripicons-weight:before {
  content: "";
}

.dripicons-wifi:before {
  content: "";
}

.dripicons-wrong:before {
  content: "";
}

.dripicons-zoom-in:before {
  content: "";
}

.dripicons-zoom-out:before {
  content: "";
}

:root {
  --ct-link-color: #727cf5;
  --ct-link-hover-color: #6169d0;
  --ct-border-color: #dee2e6;
  --ct-box-shadow: 0px 0px 35px 0px #9aa1ab26;
  --ct-box-shadow-sm: 0 .125rem .25rem #00000013;
  --ct-box-shadow-lg: 0 0 45px 0 #0000001f;
  --ct-box-shadow-inset: inset 0 1px 2px #00000013;
  --ct-component-active-color: #fff;
  --ct-component-active-bg: #727cf5;
  --ct-text-muted: #98a6ad;
  --ct-blockquote-footer-color: #98a6ad;
  --ct-mark-bg: #fcf8e3;
  --ct-table-color: #6c757d;
  --ct-table-bg: transparent;
  --ct-table-accent-bg: transparent;
  --ct-table-striped-color: #6c757d;
  --ct-table-striped-bg: #f1f3facc;
  --ct-table-active-color: var(--ct-table-color);
  --ct-table-active-bg: #dee2e666;
  --ct-table-hover-color: var(--ct-table-color);
  --ct-table-hover-bg: #f1f3fa;
  --ct-table-border-color: #eef2f7;
  --ct-table-group-separator-color: #edeff1;
  --ct-table-caption-color: var(--ct-text-muted);
  --ct-input-btn-focus-color: #727cf540;
  --ct-btn-active-box-shadow: inset 0 3px 5px #00000020;
  --ct-btn-link-color: var(--ct-link-color);
  --ct-btn-link-hover-color: var(--ct-link-hover-color);
  --ct-btn-link-disabled-color: #adb5bd;
  --ct-form-text-color: var(--ct-text-muted);
  --ct-input-bg: #fff;
  --ct-input-disabled-bg: #eef2f7;
  --ct-input-color: #6c757d;
  --ct-input-border-color: #dee2e6;
  --ct-input-box-shadow: var(--ct-box-shadow-inset);
  --ct-input-focus-bg: var(--ct-input-bg);
  --ct-input-focus-border-color: #c8cbcf;
  --ct-input-focus-color: var(--ct-input-color);
  --ct-input-placeholder-color: #adb5bd;
  --ct-input-plaintext-color: #6c757d;
  --ct-form-check-input-bg: #fff;
  --ct-form-check-input-border: 1px solid #dee2e6;
  --ct-form-check-input-checked-color: var(--ct-component-active-color);
  --ct-form-check-input-checked-bg-color: var(--ct-component-active-bg);
  --ct-form-check-input-checked-border-color: var(--ct-form-check-input-checked-bg-color);
  --ct-form-check-input-indeterminate-color: var(--ct-component-active-color);
  --ct-form-check-input-indeterminate-bg-color: var(--ct-component-active-bg);
  --ct-form-check-input-indeterminate-border-color: var(--ct-component-active-bg);
  --ct-form-switch-color: #dee2e6;
  --ct-input-group-addon-color: var(--ct-input-color);
  --ct-input-group-addon-bg: #eef2f7;
  --ct-input-group-addon-border-color: #dee2e6;
  --ct-form-select-color: var(--ct-input-color);
  --ct-form-select-bg: var(--ct-input-bg);
  --ct-form-select-disabled-color: #98a6ad;
  --ct-form-select-disabled-bg: #eef2f7;
  --ct-form-select-indicator-color: #343a40;
  --ct-form-range-track-bg: #dee2e6;
  --ct-form-range-track-box-shadow: var(--ct-box-shadow-inset);
  --ct-form-range-thumb-box-shadow: 0 .1rem .25rem #0000001a;
  --ct-form-range-thumb-active-bg: #d5d8fc;
  --ct-form-range-thumb-disabled-bg: #adb5bd;
  --ct-form-file-button-color: var(--ct-input-color);
  --ct-form-file-button-bg: #eef2f7;
  --ct-form-file-button-hover-bg: #e2e6eb;
  --ct-nav-link-disabled-color: #98a6ad;
  --ct-nav-tabs-border-color: #dee2e6;
  --ct-nav-tabs-link-hover-border-color: #eef2f7 #eef2f7 var(--ct-nav-tabs-border-color);
  --ct-nav-tabs-link-active-color: #565e64;
  --ct-nav-tabs-link-active-bg: #fff;
  --ct-nav-tabs-link-active-border-color: #dee2e6 #dee2e6 var(--ct-nav-tabs-link-active-bg);
  --ct-navbar-dark-color: #ffffff8c;
  --ct-navbar-dark-hover-color: #ffffffbf;
  --ct-navbar-dark-active-color: #fff;
  --ct-navbar-dark-disabled-color: #ffffff40;
  --ct-navbar-dark-toggler-border-color: #ffffff1a;
  --ct-navbar-light-color: #676f77;
  --ct-navbar-light-hover-color: #343a40;
  --ct-navbar-light-active-color: #000000e6;
  --ct-navbar-light-disabled-color: #adb5bd;
  --ct-dropdown-color: #6c757d;
  --ct-dropdown-bg: #fff;
  --ct-dropdown-border-color: #e7ebf0;
  --ct-dropdown-divider-bg: #e7ebf0;
  --ct-dropdown-box-shadow: var(--ct-box-shadow);
  --ct-dropdown-link-color: #6c757d;
  --ct-dropdown-link-hover-color: #2c343f;
  --ct-dropdown-link-hover-bg: #f4f6fb;
  --ct-dropdown-link-active-color: #313a46;
  --ct-dropdown-link-active-bg: #f2f5f9;
  --ct-dropdown-link-disabled-color: #98a6ad;
  --ct-dropdown-dark-color: #dee2e6;
  --ct-dropdown-dark-bg: #343a40;
  --ct-dropdown-dark-border-color: var(--ct-dropdown-border-color);
  --ct-dropdown-dark-divider-bg: var(--ct-dropdown-divider-bg);
  --ct-dropdown-dark-link-color: var(--ct-dropdown-dark-color);
  --ct-dropdown-dark-link-hover-color: #fff;
  --ct-dropdown-dark-link-hover-bg: #ffffff26;
  --ct-dropdown-dark-link-active-color: var(--ct-dropdown-link-active-color);
  --ct-dropdown-dark-link-active-bg: var(--ct-dropdown-link-active-bg);
  --ct-dropdown-dark-link-disabled-color: #adb5bd;
  --ct-dropdown-dark-header-color: #adb5bd;
  --ct-pagination-color: #313a46;
  --ct-pagination-bg: #fff;
  --ct-pagination-border-color: #dee2e6;
  --ct-pagination-focus-color: var(--ct-link-hover-color);
  --ct-pagination-focus-bg: #eef2f7;
  --ct-pagination-focus-box-shadow: 0 0 0 .2rem var(--ct-input-btn-focus-color);
  --ct-pagination-hover-color: #313a46;
  --ct-pagination-hover-bg: #eef2f7;
  --ct-pagination-hover-border-color: #dee2e6;
  --ct-pagination-disabled-color: #98a6ad;
  --ct-pagination-disabled-bg: #fff;
  --ct-pagination-disabled-border-color: #dee2e6;
  --ct-card-border-color: #00000020;
  --ct-card-box-shadow: var(--ct-box-shadow);
  --ct-card-cap-bg: #fff;
  --ct-card-bg: #fff;
  --ct-accordion-color: #6c757d;
  --ct-accordion-border-color: #00000020;
  --ct-accordion-button-active-bg: #f1f2fe;
  --ct-accordion-button-active-color: #6770dd;
  --ct-accordion-button-focus-border-color: var(--ct-input-focus-border-color);
  --ct-accordion-button-focus-box-shadow: 0 0 0 .2rem var(--ct-input-btn-focus-color);
  --ct-tooltip-color: #f1f3fa;
  --ct-tooltip-bg: #343a40;
  --ct-popover-bg: #fff;
  --ct-popover-border-color: #dee2e6;
  --ct-popover-header-bg: #f1f3fa;
  --ct-popover-header-color: ;
  --ct-popover-body-color: #6c757d;
  --ct-popover-arrow-color: #fff;
  --ct-popover-arrow-outer-color: #ced4da;
  --ct-toast-background-color: #ffffffd9;
  --ct-toast-border-color: #313a461a;
  --ct-toast-header-background-color: #ffffffd9;
  --ct-toast-header-border-color: #313a460d;
  --ct-badge-color: #fff;
  --ct-modal-content-bg: #fff;
  --ct-modal-content-box-shadow-xs: var(--ct-box-shadow-sm);
  --ct-modal-content-box-shadow-sm-up: var(--ct-box-shadow);
  --ct-modal-backdrop-bg: #313a46;
  --ct-modal-header-border-color: var(--ct-border-color);
  --ct-modal-footer-border-color: var(--ct-border-color);
  --ct-progress-bg: #eef2f7;
  --ct-progress-box-shadow: var(--ct-box-shadow-inset);
  --ct-progress-bar-color: #fff;
  --ct-progress-bar-bg: #727cf5;
  --ct-list-group-color: #313a46;
  --ct-list-group-bg: #fff;
  --ct-list-group-border-color: #eef2f7;
  --ct-list-group-hover-bg: #f1f3fa;
  --ct-list-group-disabled-color: #6c757d;
  --ct-list-group-disabled-bg: #f5f7fc;
  --ct-list-group-action-color: #6c757d;
  --ct-list-group-action-active-color: #6c757d;
  --ct-list-group-action-active-bg: #eef2f7;
  --ct-thumbnail-bg: #fff;
  --ct-thumbnail-border-color: #dee2e6;
  --ct-thumbnail-box-shadow: var(--ct-box-shadow-sm);
  --ct-figure-caption-color: #98a6ad;
  --ct-breadcrumb-divider-color: #ced4da;
  --ct-breadcrumb-active-color: #adb5bd;
  --ct-carousel-control-color: #fff;
  --ct-carousel-indicator-active-bg: #fff;
  --ct-carousel-caption-color: #fff;
  --ct-carousel-dark-indicator-active-bg: #000;
  --ct-carousel-dark-caption-color: #000;
  --ct-btn-close-color: #313a46;
  --ct-code-color: #39afd1;
  --ct-kbd-color: #fff;
  --ct-kbd-bg: #313a46;
  --ct-pre-color: ;
  --ct-bg-leftbar: #fff;
  --ct-menu-item: #6c757d;
  --ct-menu-item-hover: #727cf5;
  --ct-menu-item-active: #727cf5;
  --ct-bg-topbar: #fff;
  --ct-bg-topbar-search: #f1f3fa;
  --ct-nav-user-bg-topbar: #3c4655;
  --ct-nav-user-border-topbar: #414d5d;
  --ct-bg-dark-topbar: #313a46;
  --ct-bg-dark-topbar-search: #3c4655;
  --ct-nav-user-bg-dark-topbar: #3c4655;
  --ct-nav-user-border-dark-topbar: #414d5d;
  --ct-rightbar-bg: #fff;
  --ct-rightbar-title-bg: #313a46;
  --ct-rightbar-title-color: #fff;
  --ct-rightbar-title-btn-bg: #444e5a;
  --ct-rightbar-title-btn-color: #fff;
  --ct-rightbar-overlay-bg: #37404a;
  --ct-bg-detached-leftbar: #fff;
  --ct-bg-leftbar-gradient: linear-gradient(135deg, #8f75da 0%, #727cf5 60%);
  --ct-bg-topnav: #313a46;
  --ct-boxed-layout-bg: #fff;
  --ct-help-box-light-bg: #ffffff12;
  --ct-help-box-dark-bg: #727cf5;
  --ct-nav-pills-bg: #eef2f7;
  --ct-custom-accordion-title-color: #313a46;
  --ct-dragula-bg: #f7f9fb;
  --ct-form-wizard-header-bg: #eef2f7;
  --ct-text-title-color: #6c757d;
  --ct-page-title-color: #6c757d;
  --ct-card-loader-bg: #313a46;
  --ct-chat-primary-user-bg: #fef5e4;
  --ct-chat-secondary-user-bg: #f1f3fa;
  --ct-auth-bg: #fff;
  --ct-apex-grid-color: #f9f9fd;
  --ct-hero-bg: linear-gradient(to bottom, #8669ed, #727cf5);
}

body[data-leftbar-theme="dark"] {
  --ct-bg-leftbar: #313a46;
  --ct-dark-menu-item: #8391a2;
  --ct-dark-menu-item-hover: #bccee4;
  --ct-dark-menu-item-active: #fff;
}

body[data-leftbar-theme="default"] {
  --ct-menu-item: #cedce4;
  --ct-menu-item-hover: #fff;
  --ct-menu-item-active: #fff;
}

body[data-layout-color="dark"] {
  --bs-body-bg: #343a40;
  --bs-body-color: #aab8c5;
  --ct-link-color: #727cf5;
  --ct-link-hover-color: #6169d0;
  --ct-border-color: #464f5b;
  --ct-box-shadow: 0px 0px 35px 0px #31394280;
  --ct-box-shadow-sm: 0 .125rem .25rem #00000013;
  --ct-box-shadow-lg: 0 0 45px 0 #0000001f;
  --ct-box-shadow-inset: inset 0 1px 2px #00000013;
  --ct-component-active-color: #fff;
  --ct-component-active-bg: #727cf5;
  --ct-text-muted: #8391a2;
  --ct-blockquote-footer-color: #ced4da;
  --ct-mark-bg: #fcf8e3;
  --ct-table-color: #aab8c5;
  --ct-table-bg: transparent;
  --ct-table-accent-bg: transparent;
  --ct-table-striped-color: #aab8c5;
  --ct-table-striped-bg: #404954cc;
  --ct-table-active-color: var(--ct-table-color);
  --ct-table-active-bg: #464f5b66;
  --ct-table-hover-color: var(--ct-table-color);
  --ct-table-hover-bg: #404954;
  --ct-table-border-color: #464f5b;
  --ct-table-group-separator-color: #515c69;
  --ct-table-caption-color: var(--ct-text-muted);
  --ct-input-btn-focus-color: #727cf540;
  --ct-btn-active-box-shadow: inset 0 3px 5px #00000020;
  --ct-btn-link-color: var(--ct-link-color);
  --ct-btn-link-hover-color: var(--ct-link-hover-color);
  --ct-btn-link-disabled-color: #aab8c5;
  --ct-form-text-color: var(--ct-text-muted);
  --ct-input-bg: #404954;
  --ct-input-disabled-bg: #37404a;
  --ct-input-color: #e3eaef;
  --ct-input-border-color: #4a525d;
  --ct-input-box-shadow: var(--ct-box-shadow-inset);
  --ct-input-focus-bg: #464f5b;
  --ct-input-focus-border-color: #555f6b;
  --ct-input-focus-color: var(--ct-input-color);
  --ct-input-placeholder-color: #8391a2;
  --ct-input-plaintext-color: #6c757d;
  --ct-form-check-input-bg: transparent;
  --ct-form-check-input-border: 1px solid #4a525d;
  --ct-form-check-input-checked-color: var(--ct-component-active-color);
  --ct-form-check-input-checked-bg-color: var(--ct-component-active-bg);
  --ct-form-check-input-checked-border-color: var(--ct-form-check-input-checked-bg-color);
  --ct-form-check-input-indeterminate-color: var(--ct-component-active-color);
  --ct-form-check-input-indeterminate-bg-color: var(--ct-component-active-bg);
  --ct-form-check-input-indeterminate-border-color: var(--ct-component-active-bg);
  --ct-form-switch-color: tiny-color(#464f5b, 6%);
  --ct-input-group-addon-color: var(--ct-input-color);
  --ct-input-group-addon-bg: #48515d;
  --ct-input-group-addon-border-color: var(--ct-input-border-color);
  --ct-form-select-color: var(--ct-input-color);
  --ct-form-select-bg: var(--ct-input-bg);
  --ct-form-select-disabled-color: #ced4da;
  --ct-form-select-disabled-bg: #37404a;
  --ct-form-select-indicator-color: #e3eaef;
  --ct-form-range-track-bg: #464f5b;
  --ct-form-range-track-box-shadow: inset 0 .25rem .25rem #0000001a;
  --ct-form-range-thumb-box-shadow: 0 .1rem .25rem #0000001a;
  --ct-form-range-thumb-active-bg: #d5d8fc;
  --ct-form-range-thumb-disabled-bg: #aab8c5;
  --ct-form-file-button-color: var(--ct-input-color);
  --ct-form-file-button-bg: #48515d;
  --ct-form-file-button-hover-bg: #434b56;
  --ct-nav-link-disabled-color: #ced4da;
  --ct-nav-tabs-border-color: #464f5b;
  --ct-nav-tabs-link-hover-border-color: #37404a #37404a var(--ct-nav-tabs-border-color);
  --ct-nav-tabs-link-active-color: #e3eaef;
  --ct-nav-tabs-link-active-bg: #4a525d;
  --ct-nav-tabs-link-active-border-color: #464f5b #464f5b var(--ct-nav-tabs-link-active-bg);
  --ct-navbar-dark-color: #ffffff8c;
  --ct-navbar-dark-hover-color: #ffffffbf;
  --ct-navbar-dark-active-color: #fff;
  --ct-navbar-dark-disabled-color: #ffffff40;
  --ct-navbar-dark-toggler-border-color: #ffffff1a;
  --ct-navbar-light-color: #d3d7db;
  --ct-navbar-light-hover-color: #e3eaef;
  --ct-navbar-light-active-color: #ffffffe6;
  --ct-navbar-light-disabled-color: #aab8c5;
  --ct-dropdown-color: #aab8c5;
  --ct-dropdown-bg: #3b444e;
  --ct-dropdown-border-color: #434b55;
  --ct-dropdown-divider-bg: #4d5662;
  --ct-dropdown-box-shadow: var(--ct-box-shadow);
  --ct-dropdown-link-color: #aab8c5;
  --ct-dropdown-link-hover-color: #d9d9d9;
  --ct-dropdown-link-hover-bg: #48515d;
  --ct-dropdown-link-active-color: #fff;
  --ct-dropdown-link-active-bg: #7a8089;
  --ct-dropdown-link-disabled-color: #ced4da;
  --ct-dropdown-dark-color: #464f5b;
  --ct-dropdown-dark-bg: #e3eaef;
  --ct-dropdown-dark-border-color: var(--ct-dropdown-border-color);
  --ct-dropdown-dark-divider-bg: var(--ct-dropdown-divider-bg);
  --ct-dropdown-dark-link-color: var(--ct-dropdown-dark-color);
  --ct-dropdown-dark-link-hover-color: #fff;
  --ct-dropdown-dark-link-hover-bg: #ffffff26;
  --ct-dropdown-dark-link-active-color: var(--ct-dropdown-link-active-color);
  --ct-dropdown-dark-link-active-bg: var(--ct-dropdown-link-active-bg);
  --ct-dropdown-dark-link-disabled-color: #aab8c5;
  --ct-dropdown-dark-header-color: #aab8c5;
  --ct-pagination-color: #8391a2;
  --ct-pagination-bg: #3f4851;
  --ct-pagination-border-color: #464f5b;
  --ct-pagination-focus-color: var(--ct-link-hover-color);
  --ct-pagination-focus-bg: #37404a;
  --ct-pagination-focus-box-shadow: 0 0 0 .2rem var(--ct-input-btn-focus-color);
  --ct-pagination-hover-color: #f1f1f1;
  --ct-pagination-hover-bg: #474f58;
  --ct-pagination-hover-border-color: #464f5b;
  --ct-pagination-disabled-color: #8391a2;
  --ct-pagination-disabled-bg: #4a555f;
  --ct-pagination-disabled-border-color: #4a555f;
  --ct-card-border-color: #00000020;
  --ct-card-box-shadow: var(--ct-box-shadow);
  --ct-card-cap-bg: #464f5b;
  --ct-card-bg: #37404a;
  --ct-accordion-color: #6c757d;
  --ct-accordion-border-color: #00000020;
  --ct-accordion-button-active-bg: #f1f2fe;
  --ct-accordion-button-active-color: #6770dd;
  --ct-accordion-button-focus-border-color: var(--ct-input-focus-border-color);
  --ct-accordion-button-focus-box-shadow: 0 0 0 .2rem var(--ct-input-btn-focus-color);
  --ct-tooltip-color: #37404a;
  --ct-tooltip-bg: #dee2e6;
  --ct-popover-bg: #37404a;
  --ct-popover-border-color: #464f5b;
  --ct-popover-header-bg: #3c4651;
  --ct-popover-header-color: #dee2e6;
  --ct-popover-body-color: #dee2e6;
  --ct-popover-arrow-color: #37404a;
  --ct-popover-arrow-outer-color: #464f5b;
  --ct-toast-background-color: #404954;
  --ct-toast-border-color: #f1f1f11f;
  --ct-toast-header-background-color: #40495433;
  --ct-toast-header-border-color: #f1f1f10d;
  --ct-badge-color: #fff;
  --ct-modal-content-bg: #3b444e;
  --ct-modal-content-box-shadow-xs: var(--ct-box-shadow-sm);
  --ct-modal-content-box-shadow-sm-up: var(--ct-box-shadow);
  --ct-modal-backdrop-bg: #aab8c5;
  --ct-modal-header-border-color: #515c69;
  --ct-modal-footer-border-color: #515c69;
  --ct-progress-bg: #464f5b;
  --ct-progress-box-shadow: var(--ct-box-shadow-inset);
  --ct-progress-bar-color: #fff;
  --ct-progress-bar-bg: #727cf5;
  --ct-list-group-color: #f1f1f1;
  --ct-list-group-bg: var(--ct-card-bg);
  --ct-list-group-border-color: #4d5662;
  --ct-list-group-hover-bg: #404954;
  --ct-list-group-disabled-color: #8391a2;
  --ct-list-group-disabled-bg: #404954;
  --ct-list-group-action-color: #aab8c5;
  --ct-list-group-action-active-color: #dee2e6;
  --ct-list-group-action-active-bg: #404954;
  --ct-thumbnail-bg: #464f5b;
  --ct-thumbnail-border-color: #464f5b;
  --ct-thumbnail-box-shadow: var(--ct-box-shadow-sm);
  --ct-figure-caption-color: #ced4da;
  --ct-breadcrumb-divider-color: #8391a2;
  --ct-breadcrumb-active-color: #aab8c5;
  --ct-carousel-control-color: #fff;
  --ct-carousel-indicator-active-bg: #fff;
  --ct-carousel-caption-color: #fff;
  --ct-carousel-dark-indicator-active-bg: #000;
  --ct-carousel-dark-caption-color: #000;
  --ct-btn-close-color: #e3eaef;
  --ct-code-color: #39afd1;
  --ct-kbd-color: #fff;
  --ct-kbd-bg: #f1f1f1;
  --ct-pre-color: #aab8c5;
  --ct-bg-leftbar: #3a444e;
  --ct-menu-item: #cedce4;
  --ct-menu-item-hover: #fff;
  --ct-menu-item-active: #fff;
  --ct-bg-topbar: #3a444e;
  --ct-bg-topbar-search: #464f5b;
  --ct-nav-user-bg-topbar: #45515d;
  --ct-nav-user-border-topbar: #4a5764;
  --ct-bg-dark-topbar: #3a444e;
  --ct-bg-dark-topbar-search: #464f5b;
  --ct-nav-user-bg-dark-topbar: #45515d;
  --ct-nav-user-border-dark-topbar: #4a5764;
  --ct-rightbar-bg: #37404a;
  --ct-rightbar-title-bg: #727cf5;
  --ct-rightbar-title-color: #fff;
  --ct-rightbar-title-btn-bg: #8a92f7;
  --ct-rightbar-title-btn-color: #fff;
  --ct-rightbar-overlay-bg: #000;
  --ct-bg-detached-leftbar: #37404a;
  --ct-bg-leftbar-gradient: linear-gradient(135deg, #8f75da 0, #727cf5 60%);
  --ct-bg-topnav: linear-gradient(to bottom, #8f75da, #727cf5);
  --ct-boxed-layout-bg: #3e4853;
  --ct-help-box-light-bg: #ffffff1a;
  --ct-help-box-dark-bg: #727cf5;
  --ct-nav-pills-bg: #404954;
  --ct-custom-accordion-title-color: #8391a2;
  --ct-dragula-bg: #404954;
  --ct-form-wizard-header-bg: #404954;
  --ct-text-title-color: #fff;
  --ct-page-title-color: #fff;
  --ct-card-loader-bg: #f1f1f1;
  --ct-chat-primary-user-bg: #404954;
  --ct-chat-secondary-user-bg: #404954;
  --ct-auth-bg: #404954;
  --ct-apex-grid-color: #404954;
  --ct-hero-bg: linear-gradient(to bottom, #697ded, #5e30c1);
}

body[data-layout-color="dark"][data-layout="detached"] {
  --ct-menu-item: #8391a2;
  --ct-menu-item-hover: #727cf5;
  --ct-menu-item-active: #727cf5;
}

body[data-leftbar-theme="light"] {
  --ct-bg-leftbar: #fff;
  --ct-menu-item: #6c757d;
  --ct-menu-item-hover: #727cf5;
  --ct-menu-item-active: #727cf5;
}

:root {
  --bs-blue: #2c8ef8;
  --bs-indigo: #727cf5;
  --bs-purple: #6b5eae;
  --bs-pink: #ff679b;
  --bs-red: #fa5c7c;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffbc00;
  --bs-green: #0acf97;
  --bs-teal: #02a8b5;
  --bs-cyan: #39afd1;
  --bs-white: #fff;
  --bs-gray: #98a6ad;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f1f3fa;
  --bs-gray-200: #eef2f7;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #98a6ad;
  --bs-gray-700: #6c757d;
  --bs-gray-800: #343a40;
  --bs-gray-900: #313a46;
  --bs-primary: #727cf5;
  --bs-secondary: #6c757d;
  --bs-success: #0acf97;
  --bs-info: #39afd1;
  --bs-warning: #ffbc00;
  --bs-danger: #fa5c7c;
  --bs-light: #eef2f7;
  --bs-dark: #313a46;
  --bs-primary-rgb: 114, 124, 245;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 10, 207, 151;
  --bs-info-rgb: 57, 175, 209;
  --bs-warning-rgb: 255, 188, 0;
  --bs-danger-rgb: 250, 92, 124;
  --bs-light-rgb: 238, 242, 247;
  --bs-dark-rgb: 49, 58, 70;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 108, 117, 125;
  --bs-body-bg-rgb: 250, 251, 254;
  --bs-font-sans-serif: "Nunito", sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, #ffffff26, #fff0);
  --bs-body-font-family: Nunito, sans-serif;
  --bs-body-font-size: .9rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #6c757d;
  --bs-body-bg: #fafbfe;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: var(--ct-border-color);
  --bs-border-color-translucent: #0000002d;
  --bs-border-radius: .25rem;
  --bs-border-radius-sm: .2rem;
  --bs-border-radius-lg: .3rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: var(--ct-link-color);
  --bs-link-hover-color: var(--ct-link-hover-color);
  --bs-code-color: var(--ct-code-color);
  --bs-highlight-bg: var(--ct-mark-bg);
}

*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  margin: 0;
}

hr {
  color: inherit;
  opacity: .25;
  border: 0;
  border-top-style: solid;
  margin: 1rem 0;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: .75rem;
  font-weight: 500;
  line-height: 1.1;
}

h1, .h1 {
  font-size: calc(1.35rem + 1.2vw);
}

@media (width >= 1200px) {
  h1, .h1 {
    font-size: 2.25rem;
  }
}

h2, .h2 {
  font-size: calc(1.3125rem + .75vw);
}

@media (width >= 1200px) {
  h2, .h2 {
    font-size: 1.875rem;
  }
}

h3, .h3 {
  font-size: calc(1.275rem + .3vw);
}

@media (width >= 1200px) {
  h3, .h3 {
    font-size: 1.5rem;
  }
}

h4, .h4 {
  font-size: 1.125rem;
}

h5, .h5 {
  font-size: .9375rem;
}

h6, .h6 {
  font-size: .75rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  cursor: help;
  text-decoration-skip-ink: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

small, .small {
  font-size: .75rem;
}

mark, .mark {
  background-color: var(--bs-highlight-bg);
  padding: .2em;
}

sub, sup {
  vertical-align: baseline;
  font-size: .75em;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: var(--bs-link-color);
  text-decoration: none;
}

a:hover {
  color: var(--bs-link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code, kbd, samp {
  font-family: var(--ct-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 87.5%;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: 87.5%;
}

a > code {
  color: inherit;
}

kbd {
  color: var(--ct-kbd-color);
  background-color: var(--ct-kbd-bg);
  border-radius: .2rem;
  padding: .2rem .4rem;
  font-size: 87.5%;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: var(--ct-table-caption-color);
  text-align: left;
  padding-top: .95rem;
  padding-bottom: .95rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled), [type="button"]:not(:disabled), [type="reset"]:not(:disabled), [type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

fieldset {
  border: 0;
  min-width: 0;
  margin: 0;
  padding: 0;
}

legend {
  float: left;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit;
  width: 100%;
  margin-bottom: .5rem;
  padding: 0;
}

@media (width >= 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-datetime-edit-text {
  padding: 0;
}

::-webkit-datetime-edit-minute {
  padding: 0;
}

::-webkit-datetime-edit-hour-field {
  padding: 0;
}

::-webkit-datetime-edit-day-field {
  padding: 0;
}

::-webkit-datetime-edit-month-field {
  padding: 0;
}

::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  cursor: pointer;
  display: list-item;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.125rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.1;
}

@media (width >= 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.1;
}

@media (width >= 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.1;
}

@media (width >= 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.1;
}

@media (width >= 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.1;
}

@media (width >= 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.1;
}

@media (width >= 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled, .list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 6px;
}

.initialism {
  text-transform: uppercase;
  font-size: .75rem;
}

.blockquote {
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  color: var(--ct-blockquote-footer-color);
  margin-top: -1.5rem;
  margin-bottom: 1.5rem;
  font-size: .75rem;
}

.blockquote-footer:before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  background-color: var(--ct-thumbnail-bg);
  border: 1px solid var(--ct-thumbnail-border-color);
  border-radius: .25rem;
  max-width: 100%;
  height: auto;
  padding: .25rem;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: .75rem;
  line-height: 1;
}

.figure-caption {
  color: var(--ct-figure-caption-color);
  font-size: .75rem;
}

.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
  --bs-gutter-x: 12px;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}

@media (width >= 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}

@media (width >= 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}

@media (width >= 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}

@media (width >= 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}

.row {
  --bs-gutter-x: 24px;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.col {
  flex: 1 0;
}

.row-cols-auto > * {
  flex: none;
  width: auto;
}

.row-cols-1 > * {
  flex: none;
  width: 100%;
}

.row-cols-2 > * {
  flex: none;
  width: 50%;
}

.row-cols-3 > * {
  flex: none;
  width: 33.3333%;
}

.row-cols-4 > * {
  flex: none;
  width: 25%;
}

.row-cols-5 > * {
  flex: none;
  width: 20%;
}

.row-cols-6 > * {
  flex: none;
  width: 16.6667%;
}

.col-auto {
  flex: none;
  width: auto;
}

.col-1 {
  flex: none;
  width: 8.33333%;
}

.col-2 {
  flex: none;
  width: 16.6667%;
}

.col-3 {
  flex: none;
  width: 25%;
}

.col-4 {
  flex: none;
  width: 33.3333%;
}

.col-5 {
  flex: none;
  width: 41.6667%;
}

.col-6 {
  flex: none;
  width: 50%;
}

.col-7 {
  flex: none;
  width: 58.3333%;
}

.col-8 {
  flex: none;
  width: 66.6667%;
}

.col-9 {
  flex: none;
  width: 75%;
}

.col-10 {
  flex: none;
  width: 83.3333%;
}

.col-11 {
  flex: none;
  width: 91.6667%;
}

.col-12 {
  flex: none;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.6667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333%;
}

.offset-5 {
  margin-left: 41.6667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333%;
}

.offset-8 {
  margin-left: 66.6667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333%;
}

.offset-11 {
  margin-left: 91.6667%;
}

.g-0, .gx-0 {
  --bs-gutter-x: 0;
}

.g-0, .gy-0 {
  --bs-gutter-y: 0;
}

.g-1, .gx-1 {
  --bs-gutter-x: .375rem;
}

.g-1, .gy-1 {
  --bs-gutter-y: .375rem;
}

.g-2, .gx-2 {
  --bs-gutter-x: .75rem;
}

.g-2, .gy-2 {
  --bs-gutter-y: .75rem;
}

.g-3, .gx-3 {
  --bs-gutter-x: 1.5rem;
}

.g-3, .gy-3 {
  --bs-gutter-y: 1.5rem;
}

.g-4, .gx-4 {
  --bs-gutter-x: 2.25rem;
}

.g-4, .gy-4 {
  --bs-gutter-y: 2.25rem;
}

.g-5, .gx-5 {
  --bs-gutter-x: 4.5rem;
}

.g-5, .gy-5 {
  --bs-gutter-y: 4.5rem;
}

@media (width >= 576px) {
  .col-sm {
    flex: 1 0;
  }

  .row-cols-sm-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-sm-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-auto {
    flex: none;
    width: auto;
  }

  .col-sm-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-sm-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-sm-3 {
    flex: none;
    width: 25%;
  }

  .col-sm-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-sm-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-sm-6 {
    flex: none;
    width: 50%;
  }

  .col-sm-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-sm-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-sm-9 {
    flex: none;
    width: 75%;
  }

  .col-sm-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-sm-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-sm-12 {
    flex: none;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.6667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333%;
  }

  .offset-sm-5 {
    margin-left: 41.6667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333%;
  }

  .offset-sm-8 {
    margin-left: 66.6667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333%;
  }

  .offset-sm-11 {
    margin-left: 91.6667%;
  }

  .g-sm-0, .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0, .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1, .gx-sm-1 {
    --bs-gutter-x: .375rem;
  }

  .g-sm-1, .gy-sm-1 {
    --bs-gutter-y: .375rem;
  }

  .g-sm-2, .gx-sm-2 {
    --bs-gutter-x: .75rem;
  }

  .g-sm-2, .gy-sm-2 {
    --bs-gutter-y: .75rem;
  }

  .g-sm-3, .gx-sm-3 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-3, .gy-sm-3 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-4, .gx-sm-4 {
    --bs-gutter-x: 2.25rem;
  }

  .g-sm-4, .gy-sm-4 {
    --bs-gutter-y: 2.25rem;
  }

  .g-sm-5, .gx-sm-5 {
    --bs-gutter-x: 4.5rem;
  }

  .g-sm-5, .gy-sm-5 {
    --bs-gutter-y: 4.5rem;
  }
}

@media (width >= 768px) {
  .col-md {
    flex: 1 0;
  }

  .row-cols-md-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-md-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-md-auto {
    flex: none;
    width: auto;
  }

  .col-md-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-md-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-md-3 {
    flex: none;
    width: 25%;
  }

  .col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-md-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-md-6 {
    flex: none;
    width: 50%;
  }

  .col-md-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-md-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-md-9 {
    flex: none;
    width: 75%;
  }

  .col-md-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-md-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-md-12 {
    flex: none;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.6667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333%;
  }

  .offset-md-5 {
    margin-left: 41.6667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333%;
  }

  .offset-md-8 {
    margin-left: 66.6667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333%;
  }

  .offset-md-11 {
    margin-left: 91.6667%;
  }

  .g-md-0, .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0, .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1, .gx-md-1 {
    --bs-gutter-x: .375rem;
  }

  .g-md-1, .gy-md-1 {
    --bs-gutter-y: .375rem;
  }

  .g-md-2, .gx-md-2 {
    --bs-gutter-x: .75rem;
  }

  .g-md-2, .gy-md-2 {
    --bs-gutter-y: .75rem;
  }

  .g-md-3, .gx-md-3 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-3, .gy-md-3 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-4, .gx-md-4 {
    --bs-gutter-x: 2.25rem;
  }

  .g-md-4, .gy-md-4 {
    --bs-gutter-y: 2.25rem;
  }

  .g-md-5, .gx-md-5 {
    --bs-gutter-x: 4.5rem;
  }

  .g-md-5, .gy-md-5 {
    --bs-gutter-y: 4.5rem;
  }
}

@media (width >= 992px) {
  .col-lg {
    flex: 1 0;
  }

  .row-cols-lg-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-lg-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-auto {
    flex: none;
    width: auto;
  }

  .col-lg-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-lg-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-lg-3 {
    flex: none;
    width: 25%;
  }

  .col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-lg-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-lg-6 {
    flex: none;
    width: 50%;
  }

  .col-lg-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-lg-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-lg-9 {
    flex: none;
    width: 75%;
  }

  .col-lg-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-lg-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-lg-12 {
    flex: none;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.6667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333%;
  }

  .offset-lg-5 {
    margin-left: 41.6667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333%;
  }

  .offset-lg-8 {
    margin-left: 66.6667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333%;
  }

  .offset-lg-11 {
    margin-left: 91.6667%;
  }

  .g-lg-0, .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0, .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1, .gx-lg-1 {
    --bs-gutter-x: .375rem;
  }

  .g-lg-1, .gy-lg-1 {
    --bs-gutter-y: .375rem;
  }

  .g-lg-2, .gx-lg-2 {
    --bs-gutter-x: .75rem;
  }

  .g-lg-2, .gy-lg-2 {
    --bs-gutter-y: .75rem;
  }

  .g-lg-3, .gx-lg-3 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-3, .gy-lg-3 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-4, .gx-lg-4 {
    --bs-gutter-x: 2.25rem;
  }

  .g-lg-4, .gy-lg-4 {
    --bs-gutter-y: 2.25rem;
  }

  .g-lg-5, .gx-lg-5 {
    --bs-gutter-x: 4.5rem;
  }

  .g-lg-5, .gy-lg-5 {
    --bs-gutter-y: 4.5rem;
  }
}

@media (width >= 1200px) {
  .col-xl {
    flex: 1 0;
  }

  .row-cols-xl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-auto {
    flex: none;
    width: auto;
  }

  .col-xl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xl-3 {
    flex: none;
    width: 25%;
  }

  .col-xl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xl-6 {
    flex: none;
    width: 50%;
  }

  .col-xl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xl-9 {
    flex: none;
    width: 75%;
  }

  .col-xl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.6667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333%;
  }

  .offset-xl-5 {
    margin-left: 41.6667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333%;
  }

  .offset-xl-8 {
    margin-left: 66.6667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333%;
  }

  .offset-xl-11 {
    margin-left: 91.6667%;
  }

  .g-xl-0, .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0, .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1, .gx-xl-1 {
    --bs-gutter-x: .375rem;
  }

  .g-xl-1, .gy-xl-1 {
    --bs-gutter-y: .375rem;
  }

  .g-xl-2, .gx-xl-2 {
    --bs-gutter-x: .75rem;
  }

  .g-xl-2, .gy-xl-2 {
    --bs-gutter-y: .75rem;
  }

  .g-xl-3, .gx-xl-3 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-3, .gy-xl-3 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-4, .gx-xl-4 {
    --bs-gutter-x: 2.25rem;
  }

  .g-xl-4, .gy-xl-4 {
    --bs-gutter-y: 2.25rem;
  }

  .g-xl-5, .gx-xl-5 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xl-5, .gy-xl-5 {
    --bs-gutter-y: 4.5rem;
  }
}

@media (width >= 1400px) {
  .col-xxl {
    flex: 1 0;
  }

  .row-cols-xxl-auto > * {
    flex: none;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: none;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: none;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: none;
    width: 33.3333%;
  }

  .row-cols-xxl-4 > * {
    flex: none;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: none;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-auto {
    flex: none;
    width: auto;
  }

  .col-xxl-1 {
    flex: none;
    width: 8.33333%;
  }

  .col-xxl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-3 {
    flex: none;
    width: 25%;
  }

  .col-xxl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xxl-5 {
    flex: none;
    width: 41.6667%;
  }

  .col-xxl-6 {
    flex: none;
    width: 50%;
  }

  .col-xxl-7 {
    flex: none;
    width: 58.3333%;
  }

  .col-xxl-8 {
    flex: none;
    width: 66.6667%;
  }

  .col-xxl-9 {
    flex: none;
    width: 75%;
  }

  .col-xxl-10 {
    flex: none;
    width: 83.3333%;
  }

  .col-xxl-11 {
    flex: none;
    width: 91.6667%;
  }

  .col-xxl-12 {
    flex: none;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6667%;
  }

  .g-xxl-0, .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0, .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1, .gx-xxl-1 {
    --bs-gutter-x: .375rem;
  }

  .g-xxl-1, .gy-xxl-1 {
    --bs-gutter-y: .375rem;
  }

  .g-xxl-2, .gx-xxl-2 {
    --bs-gutter-x: .75rem;
  }

  .g-xxl-2, .gy-xxl-2 {
    --bs-gutter-y: .75rem;
  }

  .g-xxl-3, .gx-xxl-3 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-3, .gy-xxl-3 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-4, .gx-xxl-4 {
    --bs-gutter-x: 2.25rem;
  }

  .g-xxl-4, .gy-xxl-4 {
    --bs-gutter-y: 2.25rem;
  }

  .g-xxl-5, .gx-xxl-5 {
    --bs-gutter-x: 4.5rem;
  }

  .g-xxl-5, .gy-xxl-5 {
    --bs-gutter-y: 4.5rem;
  }
}

.table {
  --bs-table-color: var(--ct-table-color);
  --bs-table-bg: var(--ct-table-bg);
  --bs-table-border-color: var(--ct-table-border-color);
  --bs-table-accent-bg: var(--ct-table-accent-bg);
  --bs-table-striped-color: var(--ct-table-striped-color);
  --bs-table-striped-bg: var(--ct-table-striped-bg);
  --bs-table-active-color: var(--ct-table-active-color);
  --bs-table-active-bg: var(--ct-table-active-bg);
  --bs-table-hover-color: var(--ct-table-hover-color);
  --bs-table-hover-bg: var(--ct-table-hover-bg);
  color: var(--bs-table-color);
  vertical-align: top;
  border-color: var(--bs-table-border-color);
  width: 100%;
  margin-bottom: 1.5rem;
}

.table > :not(caption) > * > * {
  background-color: var(--bs-table-bg);
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  border-bottom-width: 1px;
  padding: .95rem;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: 2px solid var(--ct-table-group-separator-color);
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: .5rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > *, .table-striped-columns > :not(caption) > tr > :nth-child(2n) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-color: #313a46;
  --bs-table-bg: #e3e5fd;
  --bs-table-border-color: #d1d4eb;
  --bs-table-striped-bg: #313a46;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #9ca1b4;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #d6d8ef;
  --bs-table-hover-color: #313a46;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #313a46;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #d0d2d5;
  --bs-table-striped-bg: #313a46;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #9b9fa5;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #d5d6d9;
  --bs-table-hover-color: #313a46;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #313a46;
  --bs-table-bg: #cef5ea;
  --bs-table-border-color: #bee2da;
  --bs-table-striped-bg: #313a46;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #8faaa8;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #c2e7de;
  --bs-table-hover-color: #313a46;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #313a46;
  --bs-table-bg: #d7eff6;
  --bs-table-border-color: #c6dde4;
  --bs-table-striped-bg: #313a46;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #95a7b0;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #cbe1e9;
  --bs-table-hover-color: #313a46;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #313a46;
  --bs-table-bg: #fff2cc;
  --bs-table-border-color: #eae0bf;
  --bs-table-striped-bg: #313a46;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #ada896;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #f0e4c2;
  --bs-table-hover-color: #313a46;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #313a46;
  --bs-table-bg: #fedee5;
  --bs-table-border-color: #eaced5;
  --bs-table-striped-bg: #313a46;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #ac9ca5;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #efd2d9;
  --bs-table-hover-color: #313a46;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #313a46;
  --bs-table-bg: #eef2f7;
  --bs-table-border-color: #dbe0e5;
  --bs-table-striped-bg: #313a46;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #a2a8b0;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #e0e4ea;
  --bs-table-hover-color: #313a46;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #313a46;
  --bs-table-border-color: #464e59;
  --bs-table-striped-bg: white;
  --bs-table-striped-color: #313a46;
  --bs-table-active-bg: #838990;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #404954;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

@media (width <= 575.98px) {
  .table-responsive-sm {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 767.98px) {
  .table-responsive-md {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 991.98px) {
  .table-responsive-lg {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 1199.98px) {
  .table-responsive-xl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

@media (width <= 1399.98px) {
  .table-responsive-xxl {
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
  }
}

.form-label {
  margin-bottom: .5rem;
}

.col-form-label {
  font-size: inherit;
  margin-bottom: 0;
  padding-top: calc(.45rem + 1px);
  padding-bottom: calc(.45rem + 1px);
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: calc(1.2501rem + .0012vw);
}

@media (width >= 1200px) {
  .col-form-label-lg {
    font-size: 1.251rem;
  }
}

.col-form-label-sm {
  padding-top: calc(.28rem + 1px);
  padding-bottom: calc(.28rem + 1px);
  font-size: .875rem;
}

.form-text {
  color: var(--ct-form-text-color);
  margin-top: .25rem;
  font-size: .75rem;
}

.form-control {
  color: var(--ct-input-color);
  background-color: var(--ct-input-bg);
  border: 1px solid var(--ct-input-border-color);
  appearance: none;
  background-clip: padding-box;
  border-radius: .25rem;
  width: 100%;
  padding: .45rem .9rem;
  font-size: .9rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: var(--ct-input-focus-color);
  background-color: var(--ct-input-focus-bg);
  border-color: var(--ct-input-focus-border-color);
  box-shadow: none;
  outline: 0;
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::placeholder {
  color: var(--ct-input-placeholder-color);
  opacity: 1;
}

.form-control:disabled {
  background-color: var(--ct-input-disabled-bg);
  opacity: 1;
}

.form-control::file-selector-button {
  margin: -.45rem -.9rem;
  color: var(--ct-form-file-button-color);
  background-color: var(--ct-form-file-button-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  margin-inline-end: .9rem;
  padding: .45rem .9rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--ct-form-file-button-hover-bg);
}

.form-control-plaintext {
  color: var(--ct-input-plaintext-color);
  border: solid #0000;
  background-color: #0000;
  border-width: 1px 0;
  width: 100%;
  margin-bottom: 0;
  padding: .45rem 0;
  line-height: 1.5;
  display: block;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-left: 0;
  padding-right: 0;
}

.form-control-sm {
  border-radius: .2rem;
  min-height: calc(1.5em + .56rem + 2px);
  padding: .28rem .8rem;
  font-size: .875rem;
}

.form-control-sm::file-selector-button {
  margin: -.28rem -.8rem;
  margin-inline-end: .8rem;
  padding: .28rem .8rem;
}

.form-control-lg {
  border-radius: .3rem;
  min-height: calc(1.5em + 1rem + 2px);
  padding: .5rem 1rem;
  font-size: calc(1.2501rem + .0012vw);
}

@media (width >= 1200px) {
  .form-control-lg {
    font-size: 1.251rem;
  }
}

.form-control-lg::file-selector-button {
  margin: -.5rem -1rem;
  margin-inline-end: 1rem;
  padding: .5rem 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + .9rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.5em + .56rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + .9rem + 2px);
  padding: .45rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border-radius: .25rem;
  border: 0 !important;
}

.form-control-color::-webkit-color-swatch {
  border-radius: .25rem;
}

.form-control-color.form-control-sm {
  height: calc(1.5em + .56rem + 2px);
}

.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.form-select {
  -moz-padding-start: calc(.9rem - 3px);
  color: var(--ct-form-select-color);
  background-color: var(--ct-form-select-bg);
  border: 1px solid var(--ct-input-border-color);
  appearance: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%238b96a0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-position: right .9rem center;
  background-repeat: no-repeat;
  background-size: 14px 10px;
  border-radius: .25rem;
  width: 100%;
  padding: .45rem 2.7rem .45rem .9rem;
  font-size: .9rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: var(--ct-input-focus-border-color);
  box-shadow: none;
  outline: 0;
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  background-image: none;
  padding-right: .9rem;
}

.form-select:disabled {
  color: var(--ct-form-select-disabled-color);
  background-color: var(--ct-form-select-disabled-bg);
}

.form-select:-moz-focusring {
  color: #0000;
  text-shadow: 0 0 0 var(--ct-form-select-color);
}

.form-select-sm {
  border-radius: .2rem;
  padding-top: .28rem;
  padding-bottom: .28rem;
  padding-left: .8rem;
  font-size: .875rem;
}

.form-select-lg {
  border-radius: .3rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: calc(1.2501rem + .0012vw);
}

@media (width >= 1200px) {
  .form-select-lg {
    font-size: 1.251rem;
  }
}

.form-check {
  min-height: 1.35rem;
  margin-bottom: 0;
  padding-left: 1.612em;
  display: block;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.612em;
}

.form-check-reverse {
  text-align: right;
  padding-left: 0;
  padding-right: 1.612em;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: -1.612em;
}

.form-check-input {
  vertical-align: top;
  background-color: var(--ct-form-check-input-bg);
  border: var(--ct-form-check-input-border);
  appearance: none;
  print-color-adjust: exact;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 1.112em;
  height: 1.112em;
  margin-top: .194em;
  transition: background-color .15s ease-in-out, background-position .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-check-input {
    transition: none;
  }
}

.form-check-input[type="checkbox"] {
  border-radius: .25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: var(--ct-input-focus-border-color);
  box-shadow: none;
  outline: 0;
}

.form-check-input:checked {
  background-color: var(--ct-form-check-input-checked-bg-color);
  border-color: var(--ct-form-check-input-checked-border-color);
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: var(--ct-component-active-bg);
  border-color: var(--ct-component-active-bg);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: .5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238b96a0' opacity='0.5'/%3e%3c/svg%3e");
  background-position: 0;
  border-radius: 2em;
  width: 2em;
  margin-left: -2.5em;
  transition: background-position .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238b96a0' opacity='0.5'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-position: 100%;
}

.form-switch.form-check-reverse {
  padding-left: 0;
  padding-right: 2.5em;
}

.form-switch.form-check-reverse .form-check-input {
  margin-left: 0;
  margin-right: -2.5em;
}

.form-check-inline {
  margin-right: 1rem;
  display: inline-block;
}

.btn-check {
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  position: absolute;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: .65;
}

.form-range {
  appearance: none;
  background-color: #0000;
  width: 100%;
  height: 1.4rem;
  padding: 0;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fafbfe, none;
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fafbfe, none;
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  background-color: var(--ct-component-active-bg);
  appearance: none;
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: var(--ct-form-range-thumb-active-bg);
}

.form-range::-webkit-slider-runnable-track {
  color: #0000;
  cursor: pointer;
  background-color: var(--ct-form-range-track-bg);
  border-color: #0000;
  border-radius: 1rem;
  width: 100%;
  height: .5rem;
}

.form-range::-moz-range-thumb {
  background-color: var(--ct-component-active-bg);
  appearance: none;
  border: 0;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: var(--ct-form-range-thumb-active-bg);
}

.form-range::-moz-range-track {
  color: #0000;
  cursor: pointer;
  background-color: var(--ct-form-range-track-bg);
  border-color: #0000;
  border-radius: 1rem;
  width: 100%;
  height: .5rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--ct-form-range-thumb-disabled-bg);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--ct-form-range-thumb-disabled-bg);
}

.form-floating {
  position: relative;
}

.form-floating > .form-control, .form-floating > .form-control-plaintext, .form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.form-floating > label {
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  transform-origin: 0 0;
  border: 1px solid #0000;
  width: 100%;
  height: 100%;
  padding: 1rem .9rem;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control, .form-floating > .form-control-plaintext {
  padding: 1rem .9rem;
}

.form-floating > .form-control::placeholder, .form-floating > .form-control-plaintext::placeholder {
  color: #0000;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown), :-webkit-any(.form-floating > .form-control:-webkit-autofill, .form-floating > .form-control-plaintext:-webkit-autofill), .form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown), .form-floating > .form-control-plaintext:focus, .form-floating > .form-control-plaintext:not(:placeholder-shown), :is(.form-floating > .form-control:autofill, .form-floating > .form-control-plaintext:autofill), .form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem;
}

.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translate(.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: .65;
  transform: scale(.85)translateY(-.5rem)translate(.15rem);
}

.form-floating > .form-control-plaintext ~ label {
  border-width: 1px 0;
}

.input-group {
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  display: flex;
  position: relative;
}

.input-group > .form-control, .input-group > .form-select, .input-group > .form-floating {
  flex: auto;
  width: 1%;
  min-width: 0;
  position: relative;
}

.input-group > .form-control:focus, .input-group > .form-select:focus, .input-group > .form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  z-index: 2;
  position: relative;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  color: var(--ct-input-group-addon-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--ct-input-group-addon-bg);
  border: 1px solid var(--ct-input-group-addon-border-color);
  border-radius: .25rem;
  align-items: center;
  padding: .45rem .9rem;
  font-size: .9rem;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
}

.input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text, .input-group-lg > .btn {
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: calc(1.2501rem + .0012vw);
}

@media (width >= 1200px) {
  .input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text, .input-group-lg > .btn {
    font-size: 1.251rem;
  }
}

.input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text, .input-group-sm > .btn {
  border-radius: .2rem;
  padding: .28rem .8rem;
  font-size: .875rem;
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 3.6rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control, .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select, .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating), .input-group.has-validation > .dropdown-toggle:nth-last-child(n+4), .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control, .input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.input-group > .form-floating:not(:first-child) > .form-control, .input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  color: #0acf97;
  width: 100%;
  margin-top: .25rem;
  font-size: .75rem;
  display: none;
}

.valid-tooltip {
  z-index: 5;
  color: #fff;
  background-color: #0acf97e6;
  border-radius: .2rem;
  max-width: 100%;
  margin-top: .1rem;
  padding: .4rem .8rem;
  font-size: .8125rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip, .is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230acf97' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .225rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .45rem) calc(.75em + .45rem);
  border-color: #0acf97;
  padding-right: calc(1.5em + .9rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #0acf97;
  box-shadow: 0 0 0 .2rem #0acf9740;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  background-position: right calc(.375em + .225rem) top calc(.375em + .225rem);
  padding-right: calc(1.5em + .9rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #0acf97;
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%238b96a0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230acf97' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .9rem center, right 2.7rem center;
  background-size: 14px 10px, calc(.75em + .45rem) calc(.75em + .45rem);
  padding-right: 4.95rem;
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #0acf97;
  box-shadow: 0 0 0 .2rem #0acf9740;
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3.9rem + 1.5em);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #0acf97;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #0acf97;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 .2rem #0acf9740;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #0acf97;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated .input-group > .form-select:not(:focus):valid, .input-group > .form-select:not(:focus).is-valid, .was-validated .input-group > .form-floating:not(:focus-within):valid, .input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  color: #fa5c7c;
  width: 100%;
  margin-top: .25rem;
  font-size: .75rem;
  display: none;
}

.invalid-tooltip {
  z-index: 5;
  color: #fff;
  background-color: #fa5c7ce6;
  border-radius: .2rem;
  max-width: 100%;
  margin-top: .1rem;
  padding: .4rem .8rem;
  font-size: .8125rem;
  display: none;
  position: absolute;
  top: 100%;
}

.was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip, .is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23fa5c7c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fa5c7c' stroke='none'/%3e%3c/svg%3e");
  background-position: right calc(.375em + .225rem) center;
  background-repeat: no-repeat;
  background-size: calc(.75em + .45rem) calc(.75em + .45rem);
  border-color: #fa5c7c;
  padding-right: calc(1.5em + .9rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #fa5c7c;
  box-shadow: 0 0 0 .2rem #fa5c7c40;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  background-position: right calc(.375em + .225rem) top calc(.375em + .225rem);
  padding-right: calc(1.5em + .9rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #fa5c7c;
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%238b96a0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23fa5c7c'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fa5c7c' stroke='none'/%3e%3c/svg%3e");
  background-position: right .9rem center, right 2.7rem center;
  background-size: 14px 10px, calc(.75em + .45rem) calc(.75em + .45rem);
  padding-right: 4.95rem;
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #fa5c7c;
  box-shadow: 0 0 0 .2rem #fa5c7c40;
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3.9rem + 1.5em);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #fa5c7c;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #fa5c7c;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 .2rem #fa5c7c40;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #fa5c7c;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated .input-group > .form-select:not(:focus):invalid, .input-group > .form-select:not(:focus).is-invalid, .was-validated .input-group > .form-floating:not(:focus-within):invalid, .input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: .9rem;
  --bs-btn-padding-y: .45rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: .9rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #6c757d;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: .15rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: 0px 2px 6px 0px;
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .2rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  box-shadow: var(--bs-btn-focus-box-shadow);
  outline: 0;
}

.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #727cf5;
  --bs-btn-border-color: #727cf5;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6169d0;
  --bs-btn-hover-border-color: #5b63c4;
  --bs-btn-focus-shadow-rgb: 135, 144, 247;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5b63c4;
  --bs-btn-active-border-color: #565db8;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #727cf5;
  --bs-btn-disabled-border-color: #727cf5;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0acf97;
  --bs-btn-border-color: #0acf97;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #09b080;
  --bs-btn-hover-border-color: #08a679;
  --bs-btn-focus-shadow-rgb: 47, 214, 167;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #08a679;
  --bs-btn-active-border-color: #089b71;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0acf97;
  --bs-btn-disabled-border-color: #0acf97;
}

.btn-info {
  --bs-btn-color: #fff;
  --bs-btn-bg: #39afd1;
  --bs-btn-border-color: #39afd1;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3095b2;
  --bs-btn-hover-border-color: #2e8ca7;
  --bs-btn-focus-shadow-rgb: 87, 187, 216;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2e8ca7;
  --bs-btn-active-border-color: #2b839d;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #39afd1;
  --bs-btn-disabled-border-color: #39afd1;
}

.btn-warning {
  --bs-btn-color: #313a46;
  --bs-btn-bg: #ffbc00;
  --bs-btn-border-color: #ffbc00;
  --bs-btn-hover-color: #313a46;
  --bs-btn-hover-bg: #ffc626;
  --bs-btn-hover-border-color: #ffc31a;
  --bs-btn-focus-shadow-rgb: 224, 169, 11;
  --bs-btn-active-color: #313a46;
  --bs-btn-active-bg: #ffc933;
  --bs-btn-active-border-color: #ffc31a;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #313a46;
  --bs-btn-disabled-bg: #ffbc00;
  --bs-btn-disabled-border-color: #ffbc00;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #fa5c7c;
  --bs-btn-border-color: #fa5c7c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #d54e69;
  --bs-btn-hover-border-color: #c84a63;
  --bs-btn-focus-shadow-rgb: 251, 116, 144;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #c84a63;
  --bs-btn-active-border-color: #bc455d;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #fa5c7c;
  --bs-btn-disabled-border-color: #fa5c7c;
}

.btn-light {
  --bs-btn-color: #313a46;
  --bs-btn-bg: #eef2f7;
  --bs-btn-border-color: #eef2f7;
  --bs-btn-hover-color: #313a46;
  --bs-btn-hover-bg: #caced2;
  --bs-btn-hover-border-color: #bec2c6;
  --bs-btn-focus-shadow-rgb: 210, 214, 220;
  --bs-btn-active-color: #313a46;
  --bs-btn-active-bg: #bec2c6;
  --bs-btn-active-border-color: #b3b6b9;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #313a46;
  --bs-btn-disabled-bg: #eef2f7;
  --bs-btn-disabled-border-color: #eef2f7;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #313a46;
  --bs-btn-border-color: #313a46;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #505862;
  --bs-btn-hover-border-color: #464e59;
  --bs-btn-focus-shadow-rgb: 80, 88, 98;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5a616b;
  --bs-btn-active-border-color: #464e59;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #313a46;
  --bs-btn-disabled-border-color: #313a46;
}

.btn-outline-primary {
  --bs-btn-color: #727cf5;
  --bs-btn-border-color: #727cf5;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #727cf5;
  --bs-btn-hover-border-color: #727cf5;
  --bs-btn-focus-shadow-rgb: 114, 124, 245;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #727cf5;
  --bs-btn-active-border-color: #727cf5;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #727cf5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #727cf5;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #0acf97;
  --bs-btn-border-color: #0acf97;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0acf97;
  --bs-btn-hover-border-color: #0acf97;
  --bs-btn-focus-shadow-rgb: 10, 207, 151;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0acf97;
  --bs-btn-active-border-color: #0acf97;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #0acf97;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0acf97;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #39afd1;
  --bs-btn-border-color: #39afd1;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #39afd1;
  --bs-btn-hover-border-color: #39afd1;
  --bs-btn-focus-shadow-rgb: 57, 175, 209;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #39afd1;
  --bs-btn-active-border-color: #39afd1;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #39afd1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #39afd1;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffbc00;
  --bs-btn-border-color: #ffbc00;
  --bs-btn-hover-color: #313a46;
  --bs-btn-hover-bg: #ffbc00;
  --bs-btn-hover-border-color: #ffbc00;
  --bs-btn-focus-shadow-rgb: 255, 188, 0;
  --bs-btn-active-color: #313a46;
  --bs-btn-active-bg: #ffbc00;
  --bs-btn-active-border-color: #ffbc00;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #ffbc00;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffbc00;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #fa5c7c;
  --bs-btn-border-color: #fa5c7c;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #fa5c7c;
  --bs-btn-hover-border-color: #fa5c7c;
  --bs-btn-focus-shadow-rgb: 250, 92, 124;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #fa5c7c;
  --bs-btn-active-border-color: #fa5c7c;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #fa5c7c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fa5c7c;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #eef2f7;
  --bs-btn-border-color: #eef2f7;
  --bs-btn-hover-color: #313a46;
  --bs-btn-hover-bg: #eef2f7;
  --bs-btn-hover-border-color: #eef2f7;
  --bs-btn-focus-shadow-rgb: 238, 242, 247;
  --bs-btn-active-color: #313a46;
  --bs-btn-active-bg: #eef2f7;
  --bs-btn-active-border-color: #eef2f7;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #eef2f7;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #eef2f7;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #313a46;
  --bs-btn-border-color: #313a46;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #313a46;
  --bs-btn-hover-border-color: #313a46;
  --bs-btn-focus-shadow-rgb: 49, 58, 70;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #313a46;
  --bs-btn-active-border-color: #313a46;
  --bs-btn-active-shadow: var(--ct-btn-active-box-shadow);
  --bs-btn-disabled-color: #313a46;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #313a46;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--ct-btn-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--ct-btn-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--ct-btn-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: var(--ct-btn-link-disabled-color);
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: none;
  --bs-btn-focus-shadow-rgb: 135, 144, 247;
  text-decoration: none;
}

.btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: .5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: calc(1.2501rem + .0012vw);
  --bs-btn-border-radius: .15rem;
}

@media (width >= 1200px) {
  .btn-lg, .btn-group-lg > .btn {
    --bs-btn-font-size: 1.251rem;
  }
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: .28rem;
  --bs-btn-padding-x: .8rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: .15rem;
}

.fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  transition: height .35s;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width .35s;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup, .dropend, .dropdown, .dropstart, .dropup-center, .dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle:after {
  vertical-align: .2125em;
  content: "";
  border: .25em solid #0000;
  border-top-color: currentColor;
  border-bottom: 0;
  margin-left: .2125em;
  display: inline-block;
}

.dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: .25rem;
  --bs-dropdown-spacer: .125rem;
  --bs-dropdown-font-size: .9rem;
  --bs-dropdown-color: var(--ct-dropdown-color);
  --bs-dropdown-bg: var(--ct-dropdown-bg);
  --bs-dropdown-border-color: var(--ct-dropdown-border-color);
  --bs-dropdown-border-radius: .25rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(.25rem - 1px);
  --bs-dropdown-divider-bg: var(--ct-dropdown-divider-bg);
  --bs-dropdown-divider-margin-y: .75rem;
  --bs-dropdown-box-shadow: var(--ct-dropdown-box-shadow);
  --bs-dropdown-link-color: var(--ct-dropdown-link-color);
  --bs-dropdown-link-hover-color: var(--ct-dropdown-link-hover-color);
  --bs-dropdown-link-hover-bg: var(--ct-dropdown-link-hover-bg);
  --bs-dropdown-link-active-color: var(--ct-dropdown-link-active-color);
  --bs-dropdown-link-active-bg: var(--ct-dropdown-link-active-bg);
  --bs-dropdown-link-disabled-color: var(--ct-dropdown-link-disabled-color);
  --bs-dropdown-item-padding-x: 1.5rem;
  --bs-dropdown-item-padding-y: .375rem;
  --bs-dropdown-header-color: inherit;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: .5rem;
  z-index: var(--bs-dropdown-zindex);
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  background-color: var(--bs-dropdown-bg);
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  background-clip: padding-box;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
}

.dropdown-menu[data-bs-popper] {
  margin-top: var(--bs-dropdown-spacer);
  top: 100%;
  left: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  left: 0;
  right: auto;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  left: auto;
  right: 0;
}

@media (width >= 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

@media (width >= 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    left: 0;
    right: auto;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    left: auto;
    right: 0;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-toggle:after {
  vertical-align: .2125em;
  content: "";
  border: .25em solid #0000;
  border-top: 0;
  border-bottom-color: currentColor;
  margin-left: .2125em;
  display: inline-block;
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
  top: 0;
  left: 100%;
  right: auto;
}

.dropend .dropdown-toggle:after {
  vertical-align: .2125em;
  content: "";
  border: .25em solid #0000;
  border-left-color: currentColor;
  border-right: 0;
  margin-left: .2125em;
  display: inline-block;
}

.dropend .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropend .dropdown-toggle:after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
  top: 0;
  left: auto;
  right: 100%;
}

.dropstart .dropdown-toggle:after {
  vertical-align: .2125em;
  content: "";
  margin-left: .2125em;
  display: none;
}

.dropstart .dropdown-toggle:before {
  vertical-align: .2125em;
  content: "";
  border-top: .25em solid #0000;
  border-bottom: .25em solid #0000;
  border-right: .25em solid;
  margin-right: .2125em;
  display: inline-block;
}

.dropstart .dropdown-toggle:empty:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle:before {
  vertical-align: 0;
}

.dropdown-divider {
  margin: var(--bs-dropdown-divider-margin-y) 0;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
  height: 0;
  overflow: hidden;
}

.dropdown-item {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  width: 100%;
  font-weight: 400;
  display: block;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  background-color: var(--bs-dropdown-link-active-bg);
  text-decoration: none;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: #0000;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
  margin-bottom: 0;
  font-size: .875rem;
  display: block;
}

.dropdown-item-text {
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
  display: block;
}

.dropdown-menu-dark {
  --bs-dropdown-color: var(--ct-dropdown-dark-color);
  --bs-dropdown-bg: var(--ct-dropdown-dark-bg);
  --bs-dropdown-border-color: var(--ct-dropdown-dark-border-color);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: var(--ct-dropdown-dark-link-color);
  --bs-dropdown-link-hover-color: var(--ct-dropdown-dark-link-hover-color);
  --bs-dropdown-divider-bg: var(--ct-dropdown-dark-divider-bg);
  --bs-dropdown-link-hover-bg: var(--ct-dropdown-dark-link-hover-bg);
  --bs-dropdown-link-active-color: var(--ct-dropdown-dark-link-active-color);
  --bs-dropdown-link-active-bg: var(--ct-dropdown-dark-link-active-bg);
  --bs-dropdown-link-disabled-color: var(--ct-dropdown-dark-link-disabled-color);
  --bs-dropdown-header-color: var(--ct-dropdown-dark-header-color);
}

.btn-group, .btn-group-vertical {
  vertical-align: middle;
  display: inline-flex;
  position: relative;
}

.btn-group > .btn, .btn-group-vertical > .btn {
  flex: auto;
  position: relative;
}

.btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:focus + .btn, .btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn:hover, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  flex-wrap: wrap;
  justify-content: flex-start;
  display: flex;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: .15rem;
}

.btn-group > :not(.btn-check:first-child) + .btn, .btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn.dropdown-toggle-split:first-child, .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn, .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-left: .675rem;
  padding-right: .675rem;
}

.dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after, .dropend .dropdown-toggle-split:after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split:before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-left: .6rem;
  padding-right: .6rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-left: .75rem;
  padding-right: .75rem;
}

.btn-group-vertical {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child), .btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), .btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn, .btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--ct-nav-link-disabled-color);
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link.disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: 1px;
  --bs-nav-tabs-border-color: var(--ct-nav-tabs-border-color);
  --bs-nav-tabs-border-radius: .25rem;
  --bs-nav-tabs-link-hover-border-color: var(--ct-nav-tabs-link-hover-border-color);
  --bs-nav-tabs-link-active-color: var(--ct-nav-tabs-link-active-color);
  --bs-nav-tabs-link-active-bg: var(--ct-nav-tabs-link-active-bg);
  --bs-nav-tabs-link-active-border-color: var(--ct-nav-tabs-link-active-border-color);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
  background: none;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.disabled, .nav-tabs .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: .25rem;
  --bs-nav-pills-link-active-color: var(--ct-component-active-color);
  --bs-nav-pills-link-active-bg: var(--ct-component-active-bg);
}

.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
  background: none;
  border: 0;
}

.nav-pills .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  background-color: #0000;
  border-color: #0000;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-fill > .nav-link, .nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.nav-justified > .nav-link, .nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: .75rem;
  --bs-navbar-color: var(--ct-navbar-light-color);
  --bs-navbar-hover-color: var(--ct-navbar-light-hover-color);
  --bs-navbar-disabled-color: var(--ct-navbar-light-disabled-color);
  --bs-navbar-active-color: var(--ct-navbar-light-active-color);
  --bs-navbar-brand-padding-y: .23675rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.251rem;
  --bs-navbar-brand-color: var(--ct-navbar-light-active-color);
  --bs-navbar-brand-hover-color: var(--ct-navbar-light-active-color);
  --bs-navbar-nav-link-padding-x: .5rem;
  --bs-navbar-toggler-padding-y: .25rem;
  --bs-navbar-toggler-padding-x: .75rem;
  --bs-navbar-toggler-font-size: 1.251rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='var%28--ct-navbar-light-color%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: #0000001a;
  --bs-navbar-toggler-border-radius: .15rem;
  --bs-navbar-toggler-focus-width: .2rem;
  --bs-navbar-toggler-transition: box-shadow .15s ease-in-out;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  flex-wrap: inherit;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: .5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.navbar-nav .show > .nav-link, .navbar-nav .nav-link.active {
  color: var(--bs-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  color: var(--bs-navbar-color);
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.navbar-text a, .navbar-text a:hover, .navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  color: var(--bs-navbar-color);
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
  background-color: #0000;
  line-height: 1;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
  outline: 0;
  text-decoration: none;
}

.navbar-toggler-icon {
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 1.5em;
  height: 1.5em;
  display: inline-block;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (width >= 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

@media (width >= 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    flex-basis: auto;
    display: flex !important;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    z-index: auto;
    flex-grow: 1;
    transition: none;
    position: static;
    visibility: visible !important;
    background-color: #0000 !important;
    border: 0 !important;
    width: auto !important;
    height: auto !important;
    transform: none !important;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  z-index: auto;
  flex-grow: 1;
  transition: none;
  position: static;
  visibility: visible !important;
  background-color: #0000 !important;
  border: 0 !important;
  width: auto !important;
  height: auto !important;
  transform: none !important;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  flex-grow: 0;
  padding: 0;
  display: flex;
  overflow-y: visible;
}

.navbar-dark {
  --bs-navbar-color: var(--ct-navbar-dark-color);
  --bs-navbar-hover-color: var(--ct-navbar-dark-hover-color);
  --bs-navbar-disabled-color: var(--ct-navbar-dark-disabled-color);
  --bs-navbar-active-color: var(--ct-navbar-dark-active-color);
  --bs-navbar-brand-color: var(--ct-navbar-dark-active-color);
  --bs-navbar-brand-hover-color: var(--ct-navbar-dark-active-color);
  --bs-navbar-toggler-border-color: var(--ct-navbar-dark-toggler-border-color);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='var%28--ct-navbar-dark-color%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1.5rem;
  --bs-card-spacer-x: 1.5rem;
  --bs-card-title-spacer-y: .75rem;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--ct-card-border-color);
  --bs-card-border-radius: .25rem;
  --bs-card-box-shadow: var(--ct-card-box-shadow);
  --bs-card-inner-border-radius: calc(.25rem - 1px);
  --bs-card-cap-padding-y: .75rem;
  --bs-card-cap-padding-x: 1.5rem;
  --bs-card-cap-bg: var(--ct-card-cap-bg);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--ct-card-bg);
  --bs-card-img-overlay-padding: 1.25rem;
  --bs-card-group-margin: 12px;
  height: var(--bs-card-height);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  background-clip: border-box;
  flex-direction: column;
  min-width: 0;
  display: flex;
  position: relative;
}

.card > hr {
  margin-left: 0;
  margin-right: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
  border-top-width: 0;
}

.card > .list-group:last-child {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
  border-bottom-width: 0;
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
  flex: auto;
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
}

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  margin-bottom: 0;
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
  position: absolute;
  inset: 0;
}

.card-img, .card-img-top, .card-img-bottom {
  width: 100%;
}

.card-img, .card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (width >= 576px) {
  .card-group {
    flex-flow: wrap;
    display: flex;
  }

  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }

  .card-group > .card + .card {
    border-left: 0;
    margin-left: 0;
  }

  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-top, .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group > .card:not(:last-child) .card-img-bottom, .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-top, .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group > .card:not(:first-child) .card-img-bottom, .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--ct-accordion-color);
  --bs-accordion-bg: transparent;
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-border-color: var(--ct-accordion-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: .25rem;
  --bs-accordion-inner-border-radius: calc(.25rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--ct-accordion-color);
  --bs-accordion-btn-bg: transparent;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--ct-accordion-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--ct-accordion-button-active-color%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: var(--ct-accordion-button-focus-border-color);
  --bs-accordion-btn-focus-box-shadow: var(--ct-accordion-button-focus-box-shadow);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--ct-accordion-button-active-color);
  --bs-accordion-active-bg: var(--ct-accordion-button-active-bg);
}

.accordion-button {
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
  border: 0;
  border-radius: 0;
  align-items: center;
  width: 100%;
  font-size: .9rem;
  display: flex;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed):after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:after {
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
  flex-shrink: 0;
  margin-left: auto;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button:after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  outline: 0;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 1.5rem;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--ct-breadcrumb-divider-color);
  --bs-breadcrumb-item-padding-x: .5rem;
  --bs-breadcrumb-item-active-color: var(--ct-breadcrumb-active-color);
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
  flex-wrap: wrap;
  list-style: none;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.breadcrumb-item + .breadcrumb-item:before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "󰅂");
}

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: .75rem;
  --bs-pagination-padding-y: .375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--ct-pagination-color);
  --bs-pagination-bg: var(--ct-pagination-bg);
  --bs-pagination-border-width: 1px;
  --bs-pagination-border-color: var(--ct-pagination-border-color);
  --bs-pagination-border-radius: .25rem;
  --bs-pagination-hover-color: var(--ct-pagination-hover-color);
  --bs-pagination-hover-bg: var(--ct-pagination-hover-bg);
  --bs-pagination-hover-border-color: var(--ct-pagination-hover-border-color);
  --bs-pagination-focus-color: var(--ct-pagination-focus-color);
  --bs-pagination-focus-bg: var(--ct-pagination-focus-bg);
  --bs-pagination-focus-box-shadow: var(--ct-pagination-focus-box-shadow);
  --bs-pagination-active-color: var(--ct-component-active-color);
  --bs-pagination-active-bg: var(--ct-component-active-bg);
  --bs-pagination-active-border-color: var(--ct-component-active-bg);
  --bs-pagination-disabled-color: var(--ct-pagination-disabled-color);
  --bs-pagination-disabled-bg: var(--ct-pagination-disabled-bg);
  --bs-pagination-disabled-border-color: var(--ct-pagination-disabled-border-color);
  padding-left: 0;
  list-style: none;
  display: flex;
}

.page-link {
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  box-shadow: var(--bs-pagination-focus-box-shadow);
  outline: 0;
}

.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: .75rem;
  --bs-pagination-font-size: calc(1.2501rem + .0012vw);
  --bs-pagination-border-radius: .3rem;
}

@media (width >= 1200px) {
  .pagination-lg {
    --bs-pagination-font-size: 1.251rem;
  }
}

.pagination-sm {
  --bs-pagination-padding-x: .5rem;
  --bs-pagination-padding-y: .25rem;
  --bs-pagination-font-size: .875rem;
  --bs-pagination-border-radius: .2rem;
}

.badge {
  --bs-badge-padding-x: .4em;
  --bs-badge-padding-y: .25em;
  --bs-badge-font-size: .75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: var(--ct-badge-color);
  --bs-badge-border-radius: .25rem;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
  line-height: 1;
  display: inline-block;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1.25rem;
  --bs-alert-padding-y: .75rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: 1px solid var(--bs-alert-border-color);
  --bs-alert-border-radius: .25rem;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
  position: relative;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3.75rem;
}

.alert-dismissible .btn-close {
  z-index: 2;
  padding: .9375rem 1.25rem;
  position: absolute;
  top: 0;
  right: 0;
}

.alert-primary {
  --bs-alert-color: #444a93;
  --bs-alert-bg: #e3e5fd;
  --bs-alert-border-color: #d5d8fc;
}

.alert-secondary {
  --bs-alert-color: #41464b;
  --bs-alert-bg: #e2e3e5;
  --bs-alert-border-color: #d3d6d8;
}

.alert-success {
  --bs-alert-color: #067c5b;
  --bs-alert-bg: #cef5ea;
  --bs-alert-border-color: #b6f1e0;
}

.alert-info {
  --bs-alert-color: #22697d;
  --bs-alert-bg: #d7eff6;
  --bs-alert-border-color: #c4e7f1;
}

.alert-warning {
  --bs-alert-color: #997100;
  --bs-alert-bg: #fff2cc;
  --bs-alert-border-color: #ffebb3;
}

.alert-danger {
  --bs-alert-color: #96374a;
  --bs-alert-bg: #fedee5;
  --bs-alert-border-color: #feced8;
}

.alert-light {
  --bs-alert-color: #8f9194;
  --bs-alert-bg: #fcfcfd;
  --bs-alert-border-color: #fafbfd;
}

.alert-dark {
  --bs-alert-color: #1d232a;
  --bs-alert-bg: #d6d8da;
  --bs-alert-border-color: #c1c4c8;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: .675rem;
  --bs-progress-bg: var(--ct-progress-bg);
  --bs-progress-border-radius: .25rem;
  --bs-progress-box-shadow: var(--ct-progress-box-shadow);
  --bs-progress-bar-color: var(--ct-progress-bar-color);
  --bs-progress-bar-bg: var(--ct-progress-bar-bg);
  --bs-progress-bar-transition: width .6s ease;
  height: var(--bs-progress-height);
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  display: flex;
  overflow: hidden;
}

.progress-bar {
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
  flex-direction: column;
  justify-content: center;
  display: flex;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, #ffffff26 25%, #0000 25% 50%, #ffffff26 50% 75%, #0000 75%, #0000);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--ct-list-group-color);
  --bs-list-group-bg: var(--ct-list-group-bg);
  --bs-list-group-border-color: var(--ct-list-group-border-color);
  --bs-list-group-border-width: 1px;
  --bs-list-group-border-radius: .25rem;
  --bs-list-group-item-padding-x: 1.2rem;
  --bs-list-group-item-padding-y: .75rem;
  --bs-list-group-action-color: var(--ct-list-group-action-color);
  --bs-list-group-action-hover-color: var(--ct-list-group-action-color);
  --bs-list-group-action-hover-bg: var(--ct-list-group-hover-bg);
  --bs-list-group-action-active-color: var(--ct-list-group-action-active-color);
  --bs-list-group-action-active-bg: var(--ct-list-group-action-active-bg);
  --bs-list-group-disabled-color: var(--ct-list-group-disabled-color);
  --bs-list-group-disabled-bg: var(--ct-list-group-disabled-bg);
  --bs-list-group-active-color: var(--ct-component-active-color);
  --bs-list-group-active-bg: var(--ct-component-active-bg);
  --bs-list-group-active-border-color: var(--ct-component-active-bg);
  border-radius: var(--bs-list-group-border-radius);
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.list-group-numbered {
  counter-reset: section;
  list-style-type: none;
}

.list-group-numbered > .list-group-item:before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  color: var(--bs-list-group-action-color);
  text-align: inherit;
  width: 100%;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  background-color: var(--bs-list-group-action-hover-bg);
  text-decoration: none;
}

.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
  display: block;
  position: relative;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (width >= 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (width >= 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #444a93;
  background-color: #d5d8fc;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #444a93;
  background-color: #c0c2e3;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #444a93;
  border-color: #444a93;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #d3d6d8;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #bec1c2;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #067c5b;
  background-color: #b6f1e0;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #067c5b;
  background-color: #a4d9ca;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #067c5b;
  border-color: #067c5b;
}

.list-group-item-info {
  color: #22697d;
  background-color: #c4e7f1;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #22697d;
  background-color: #b0d0d9;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #22697d;
  border-color: #22697d;
}

.list-group-item-warning {
  color: #997100;
  background-color: #ffebb3;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #997100;
  background-color: #e6d4a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #997100;
  border-color: #997100;
}

.list-group-item-danger {
  color: #96374a;
  background-color: #feced8;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #96374a;
  background-color: #e5b9c2;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #96374a;
  border-color: #96374a;
}

.list-group-item-light {
  color: #8f9194;
  background-color: #fafbfd;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #8f9194;
  background-color: #e1e2e4;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #8f9194;
  border-color: #8f9194;
}

.list-group-item-dark {
  color: #1d232a;
  background-color: #c1c4c8;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1d232a;
  background-color: #aeb0b4;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1d232a;
  border-color: #1d232a;
}

.btn-close {
  box-sizing: content-box;
  color: var(--ct-btn-close-color);
  opacity: 1;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238b96a0'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center / 1em no-repeat;
  border: 0;
  border-radius: .25rem;
  width: 1em;
  height: 1em;
  padding: .25em;
}

.btn-close:hover {
  color: var(--ct-btn-close-color);
  opacity: .8;
  text-decoration: none;
}

.btn-close:focus {
  box-shadow: none;
  opacity: 1;
  outline: 0;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  opacity: .25;
}

.btn-close-white {
  filter: invert() grayscale() brightness(200%);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: .75rem;
  --bs-toast-padding-y: .5rem;
  --bs-toast-spacing: 12px;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: .875rem;
  --bs-toast-color: inherit;
  --bs-toast-bg: var(--ct-toast-background-color);
  --bs-toast-border-width: 1px;
  --bs-toast-border-color: var(--ct-toast-border-color);
  --bs-toast-border-radius: .25rem;
  --bs-toast-box-shadow: var(--ct-box-shadow);
  --bs-toast-header-color: inherit;
  --bs-toast-header-bg: var(--ct-toast-header-background-color);
  --bs-toast-header-border-color: var(--ct-toast-header-border-color);
  width: var(--bs-toast-max-width);
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
  background-clip: padding-box;
  max-width: 100%;
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  z-index: var(--bs-toast-zindex);
  pointer-events: none;
  width: max-content;
  max-width: 100%;
  position: absolute;
}

.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius)  - var(--bs-toast-border-width));
  background-clip: padding-box;
  align-items: center;
  display: flex;
}

.toast-header .btn-close {
  margin-right: calc(-.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: .5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--ct-modal-content-bg);
  --bs-modal-border-color: transparent;
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: .2rem;
  --bs-modal-box-shadow: var(--ct-modal-content-box-shadow-xs);
  --bs-modal-inner-border-radius: calc(.2rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--ct-modal-header-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: .5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--ct-modal-footer-border-color);
  --bs-modal-footer-border-width: 1px;
  z-index: var(--bs-modal-zindex);
  outline: 0;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden auto;
}

.modal-dialog {
  margin: var(--bs-modal-margin);
  pointer-events: none;
  width: auto;
  position: relative;
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translateY(-50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  min-height: calc(100% - var(--bs-modal-margin) * 2);
  align-items: center;
  display: flex;
}

.modal-content {
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  width: 100%;
  display: flex;
  position: relative;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: var(--ct-modal-backdrop-bg);
  --bs-backdrop-opacity: .7;
  z-index: var(--bs-backdrop-zindex);
  background-color: var(--bs-backdrop-bg);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
  margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  line-height: var(--bs-modal-title-line-height);
  margin-bottom: 0;
}

.modal-body {
  padding: var(--bs-modal-padding);
  flex: auto;
  position: relative;
}

.modal-footer {
  padding: calc(var(--bs-modal-padding)  - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * .5);
}

@media (width >= 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--ct-modal-content-box-shadow-sm-up);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-left: auto;
    margin-right: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (width >= 992px) {
  .modal-lg, .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (width >= 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  border: 0;
  border-radius: 0;
  height: 100%;
}

.modal-fullscreen .modal-header, .modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (width <= 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-sm-down .modal-header, .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-md-down .modal-header, .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-lg-down .modal-header, .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xl-down .modal-header, .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (width <= 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    border: 0;
    border-radius: 0;
    height: 100%;
  }

  .modal-fullscreen-xxl-down .modal-header, .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: .8rem;
  --bs-tooltip-padding-y: .4rem;
  --bs-tooltip-margin: 0;
  --bs-tooltip-font-size: .8125rem;
  --bs-tooltip-color: var(--ct-tooltip-color);
  --bs-tooltip-bg: var(--ct-tooltip-bg);
  --bs-tooltip-border-radius: .2rem;
  --bs-tooltip-opacity: .9;
  --bs-tooltip-arrow-width: .8rem;
  --bs-tooltip-arrow-height: .4rem;
  z-index: var(--bs-tooltip-zindex);
  padding: var(--bs-tooltip-arrow-height);
  margin: var(--bs-tooltip-margin);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
  text-decoration: none;
  display: block;
}

.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
  display: block;
}

.tooltip .tooltip-arrow:before {
  content: "";
  border-style: solid;
  border-color: #0000;
  position: absolute;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow:before {
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-top-color: var(--bs-tooltip-bg);
  top: -1px;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  left: 0;
}

.bs-tooltip-end .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
  border-right-color: var(--bs-tooltip-bg);
  right: -1px;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow:before {
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
  bottom: -1px;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
  right: 0;
}

.bs-tooltip-start .tooltip-arrow:before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow:before {
  border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
  left: -1px;
}

.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: .875rem;
  --bs-popover-bg: var(--ct-popover-bg);
  --bs-popover-border-width: 1px;
  --bs-popover-border-color: var(--ct-popover-border-color);
  --bs-popover-border-radius: .3rem;
  --bs-popover-inner-border-radius: calc(.3rem - 1px);
  --bs-popover-box-shadow: var(--ct-box-shadow);
  --bs-popover-header-padding-x: .8rem;
  --bs-popover-header-padding-y: .7rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: var(--ct-popover-header-color);
  --bs-popover-header-bg: var(--ct-popover-header-bg);
  --bs-popover-body-padding-x: .75rem;
  --bs-popover-body-padding-y: .5rem;
  --bs-popover-body-color: var(--ct-popover-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: .5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  max-width: var(--bs-popover-max-width);
  text-align: left;
  text-align: start;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  background-clip: padding-box;
  text-decoration: none;
  display: block;
}

.popover .popover-arrow {
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
  display: block;
}

.popover .popover-arrow:before, .popover .popover-arrow:after {
  content: "";
  border: 0 solid #0000;
  display: block;
  position: absolute;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before, .bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-top > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:before {
  border-top-color: var(--bs-popover-arrow-border);
  bottom: 0;
}

.bs-popover-top > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow:after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before, .bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0;
}

.bs-popover-end > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:before {
  border-right-color: var(--bs-popover-arrow-border);
  left: 0;
}

.bs-popover-end > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow:after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before, .bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-bottom > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:before {
  border-bottom-color: var(--bs-popover-arrow-border);
  top: 0;
}

.bs-popover-bottom > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow:after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-bottom .popover-header:before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header:before {
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height))  - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before, .bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height);
}

.bs-popover-start > .popover-arrow:before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:before {
  border-left-color: var(--bs-popover-arrow-border);
  right: 0;
}

.bs-popover-start > .popover-arrow:after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow:after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
  margin-bottom: 0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel-inner:after {
  clear: both;
  content: "";
  display: block;
}

.carousel-item {
  float: left;
  backface-visibility: hidden;
  width: 100%;
  margin-right: -100%;
  transition: transform .6s ease-in-out;
  display: none;
  position: relative;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start), .active.carousel-item-end {
  transform: translate(100%);
}

.carousel-item-prev:not(.carousel-item-end), .active.carousel-item-start {
  transform: translate(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active, .carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start, .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev, .carousel-control-next {
  z-index: 1;
  color: var(--ct-carousel-control-color);
  text-align: center;
  opacity: .5;
  background: none;
  border: 0;
  justify-content: center;
  align-items: center;
  width: 15%;
  padding: 0;
  transition: opacity .15s;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev, .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus, .carousel-control-next:hover, .carousel-control-next:focus {
  color: var(--ct-carousel-control-color);
  opacity: .9;
  outline: 0;
  text-decoration: none;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon, .carousel-control-next-icon {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 2rem;
  height: 2rem;
  display: inline-block;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  z-index: 2;
  justify-content: center;
  margin-bottom: 1rem;
  margin-left: 15%;
  margin-right: 15%;
  padding: 0;
  list-style: none;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  text-indent: -999px;
  cursor: pointer;
  background-color: var(--ct-carousel-indicator-active-bg);
  opacity: .5;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-left: 0;
  border-right: 0;
  flex: 0 auto;
  width: 30px;
  height: 3px;
  margin-left: 3px;
  margin-right: 3px;
  padding: 0;
  transition: opacity .6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  color: var(--ct-carousel-caption-color);
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  position: absolute;
  bottom: 1.25rem;
  left: 15%;
  right: 15%;
}

.carousel-dark .carousel-control-prev-icon, .carousel-dark .carousel-control-next-icon {
  filter: invert() grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: var(--ct-carousel-dark-indicator-active-bg);
}

.carousel-dark .carousel-caption {
  color: var(--ct-carousel-dark-caption-color);
}

.spinner-grow, .spinner-border {
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
  border-radius: 50%;
  display: inline-block;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-border-width: .25em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: #0000;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: .2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -.125em;
  --bs-spinner-animation-speed: .75s;
  --bs-spinner-animation-name: spinner-grow;
  opacity: 0;
  background-color: currentColor;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border, .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: ;
  --bs-offcanvas-bg: var(--ct-modal-content-bg);
  --bs-offcanvas-border-width: 1px;
  --bs-offcanvas-border-color: transparent;
  --bs-offcanvas-box-shadow: var(--ct-modal-content-box-shadow-xs);
}

@media (width <= 575.98px) {
  .offcanvas-sm {
    z-index: var(--bs-offcanvas-zindex);
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (width <= 575.98px) {
  .offcanvas-sm.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translate(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translate(100%);
  }

  .offcanvas-sm.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (width >= 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 767.98px) {
  .offcanvas-md {
    z-index: var(--bs-offcanvas-zindex);
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (width <= 767.98px) {
  .offcanvas-md.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translate(-100%);
  }

  .offcanvas-md.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translate(100%);
  }

  .offcanvas-md.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}

@media (width >= 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 991.98px) {
  .offcanvas-lg {
    z-index: var(--bs-offcanvas-zindex);
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (width <= 991.98px) {
  .offcanvas-lg.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translate(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translate(100%);
  }

  .offcanvas-lg.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (width >= 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 1199.98px) {
  .offcanvas-xl {
    z-index: var(--bs-offcanvas-zindex);
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (width <= 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translate(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translate(100%);
  }

  .offcanvas-xl.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (width >= 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

@media (width <= 1399.98px) {
  .offcanvas-xxl {
    z-index: var(--bs-offcanvas-zindex);
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    flex-direction: column;
    max-width: 100%;
    transition: transform .3s ease-in-out;
    display: flex;
    position: fixed;
    bottom: 0;
  }
}

@media (width <= 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (width <= 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    left: 0;
    transform: translate(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    top: 0;
    right: 0;
    transform: translate(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    height: var(--bs-offcanvas-height);
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    height: var(--bs-offcanvas-height);
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    max-height: 100%;
    left: 0;
    right: 0;
    transform: translateY(100%);
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (width >= 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: #0000 !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    flex-grow: 0;
    padding: 0;
    display: flex;
    overflow-y: visible;
    background-color: #0000 !important;
  }
}

.offcanvas {
  z-index: var(--bs-offcanvas-zindex);
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  flex-direction: column;
  max-width: 100%;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  bottom: 0;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  left: 0;
  transform: translate(-100%);
}

.offcanvas.offcanvas-end {
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  top: 0;
  right: 0;
  transform: translate(100%);
}

.offcanvas.offcanvas-top {
  height: var(--bs-offcanvas-height);
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  height: var(--bs-offcanvas-height);
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  max-height: 100%;
  left: 0;
  right: 0;
  transform: translateY(100%);
}

.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  z-index: 1040;
  background-color: var(--ct-modal-backdrop-bg);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: .7;
}

.offcanvas-header {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
  margin-top: calc(-.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  flex-grow: 1;
  overflow-y: auto;
}

.placeholder {
  vertical-align: middle;
  cursor: wait;
  opacity: .5;
  background-color: currentColor;
  min-height: 1em;
  display: inline-block;
}

.placeholder.btn:before {
  content: "";
  display: inline-block;
}

.placeholder-xs {
  min-height: .6em;
}

.placeholder-sm {
  min-height: .8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: 2s ease-in-out infinite placeholder-glow;
}

@keyframes placeholder-glow {
  50% {
    opacity: .2;
  }
}

.placeholder-wave {
  animation: 2s linear infinite placeholder-wave;
  -webkit-mask-image: linear-gradient(130deg, #000 55%, #000c, #000 95%);
  mask-image: linear-gradient(130deg, #000 55%, #000c, #000 95%);
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
}

@keyframes placeholder-wave {
  to {
    -webkit-mask-position: -200% 0;
    mask-position: -200% 0;
  }
}

.clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(114, 124, 245, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(10, 207, 151, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(57, 175, 209, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #313a46 !important;
  background-color: RGBA(255, 188, 0, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(250, 92, 124, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #313a46 !important;
  background-color: RGBA(238, 242, 247, var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(49, 58, 70, var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: #727cf5 !important;
}

.link-primary:hover, .link-primary:focus {
  color: #6169d0 !important;
}

.link-secondary {
  color: #6c757d !important;
}

.link-secondary:hover, .link-secondary:focus {
  color: #5c636a !important;
}

.link-success {
  color: #0acf97 !important;
}

.link-success:hover, .link-success:focus {
  color: #09b080 !important;
}

.link-info {
  color: #39afd1 !important;
}

.link-info:hover, .link-info:focus {
  color: #3095b2 !important;
}

.link-warning {
  color: #ffbc00 !important;
}

.link-warning:hover, .link-warning:focus {
  color: #ffc626 !important;
}

.link-danger {
  color: #fa5c7c !important;
}

.link-danger:hover, .link-danger:focus {
  color: #d54e69 !important;
}

.link-light {
  color: #eef2f7 !important;
}

.link-light:hover, .link-light:focus {
  color: #f1f4f8 !important;
}

.link-dark {
  color: #313a46 !important;
}

.link-dark:hover, .link-dark:focus {
  color: #2a313c !important;
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571%;
}

.fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.sticky-top {
  z-index: 1020;
  position: sticky;
  top: 0;
}

.sticky-bottom {
  z-index: 1020;
  position: sticky;
  bottom: 0;
}

@media (width >= 576px) {
  .sticky-sm-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-sm-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 768px) {
  .sticky-md-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-md-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 992px) {
  .sticky-lg-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-lg-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 1200px) {
  .sticky-xl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

@media (width >= 1400px) {
  .sticky-xxl-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }

  .sticky-xxl-bottom {
    z-index: 1020;
    position: sticky;
    bottom: 0;
  }
}

.hstack {
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.vstack {
  flex-direction: column;
  flex: auto;
  align-self: stretch;
  display: flex;
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.stretched-link:after {
  z-index: 1;
  content: "";
  position: absolute;
  inset: 0;
}

.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.vr {
  opacity: .25;
  background-color: currentColor;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  display: inline-block;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--ct-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--ct-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--ct-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translate(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-0 {
  --bs-border-width: 0;
}

.border-1 {
  --bs-border-width: 1px;
}

.border-2 {
  --bs-border-width: 2px;
}

.border-3 {
  --bs-border-width: 3px;
}

.border-4 {
  --bs-border-width: 4px;
}

.border-5 {
  --bs-border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: .1;
}

.border-opacity-25 {
  --bs-border-opacity: .25;
}

.border-opacity-50 {
  --bs-border-opacity: .5;
}

.border-opacity-75 {
  --bs-border-opacity: .75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: .375rem !important;
}

.m-2 {
  margin: .75rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.m-4 {
  margin: 2.25rem !important;
}

.m-5 {
  margin: 4.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: .375rem !important;
  margin-right: .375rem !important;
}

.mx-2 {
  margin-left: .75rem !important;
  margin-right: .75rem !important;
}

.mx-3 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-4 {
  margin-left: 2.25rem !important;
  margin-right: 2.25rem !important;
}

.mx-5 {
  margin-left: 4.5rem !important;
  margin-right: 4.5rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: .375rem !important;
  margin-bottom: .375rem !important;
}

.my-2 {
  margin-top: .75rem !important;
  margin-bottom: .75rem !important;
}

.my-3 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-4 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.my-5 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .375rem !important;
}

.mt-2 {
  margin-top: .75rem !important;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.mt-4 {
  margin-top: 2.25rem !important;
}

.mt-5 {
  margin-top: 4.5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: .375rem !important;
}

.me-2 {
  margin-right: .75rem !important;
}

.me-3 {
  margin-right: 1.5rem !important;
}

.me-4 {
  margin-right: 2.25rem !important;
}

.me-5 {
  margin-right: 4.5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .375rem !important;
}

.mb-2 {
  margin-bottom: .75rem !important;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 2.25rem !important;
}

.mb-5 {
  margin-bottom: 4.5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: .375rem !important;
}

.ms-2 {
  margin-left: .75rem !important;
}

.ms-3 {
  margin-left: 1.5rem !important;
}

.ms-4 {
  margin-left: 2.25rem !important;
}

.ms-5 {
  margin-left: 4.5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -.375rem !important;
}

.m-n2 {
  margin: -.75rem !important;
}

.m-n3 {
  margin: -1.5rem !important;
}

.m-n4 {
  margin: -2.25rem !important;
}

.m-n5 {
  margin: -4.5rem !important;
}

.mx-n1 {
  margin-left: -.375rem !important;
  margin-right: -.375rem !important;
}

.mx-n2 {
  margin-left: -.75rem !important;
  margin-right: -.75rem !important;
}

.mx-n3 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.mx-n4 {
  margin-left: -2.25rem !important;
  margin-right: -2.25rem !important;
}

.mx-n5 {
  margin-left: -4.5rem !important;
  margin-right: -4.5rem !important;
}

.my-n1 {
  margin-top: -.375rem !important;
  margin-bottom: -.375rem !important;
}

.my-n2 {
  margin-top: -.75rem !important;
  margin-bottom: -.75rem !important;
}

.my-n3 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n4 {
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important;
}

.my-n5 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important;
}

.mt-n1 {
  margin-top: -.375rem !important;
}

.mt-n2 {
  margin-top: -.75rem !important;
}

.mt-n3 {
  margin-top: -1.5rem !important;
}

.mt-n4 {
  margin-top: -2.25rem !important;
}

.mt-n5 {
  margin-top: -4.5rem !important;
}

.me-n1 {
  margin-right: -.375rem !important;
}

.me-n2 {
  margin-right: -.75rem !important;
}

.me-n3 {
  margin-right: -1.5rem !important;
}

.me-n4 {
  margin-right: -2.25rem !important;
}

.me-n5 {
  margin-right: -4.5rem !important;
}

.mb-n1 {
  margin-bottom: -.375rem !important;
}

.mb-n2 {
  margin-bottom: -.75rem !important;
}

.mb-n3 {
  margin-bottom: -1.5rem !important;
}

.mb-n4 {
  margin-bottom: -2.25rem !important;
}

.mb-n5 {
  margin-bottom: -4.5rem !important;
}

.ms-n1 {
  margin-left: -.375rem !important;
}

.ms-n2 {
  margin-left: -.75rem !important;
}

.ms-n3 {
  margin-left: -1.5rem !important;
}

.ms-n4 {
  margin-left: -2.25rem !important;
}

.ms-n5 {
  margin-left: -4.5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: .375rem !important;
}

.p-2 {
  padding: .75rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.p-4 {
  padding: 2.25rem !important;
}

.p-5 {
  padding: 4.5rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: .375rem !important;
  padding-right: .375rem !important;
}

.px-2 {
  padding-left: .75rem !important;
  padding-right: .75rem !important;
}

.px-3 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-4 {
  padding-left: 2.25rem !important;
  padding-right: 2.25rem !important;
}

.px-5 {
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: .375rem !important;
  padding-bottom: .375rem !important;
}

.py-2 {
  padding-top: .75rem !important;
  padding-bottom: .75rem !important;
}

.py-3 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-4 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.py-5 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: .375rem !important;
}

.pt-2 {
  padding-top: .75rem !important;
}

.pt-3 {
  padding-top: 1.5rem !important;
}

.pt-4 {
  padding-top: 2.25rem !important;
}

.pt-5 {
  padding-top: 4.5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: .375rem !important;
}

.pe-2 {
  padding-right: .75rem !important;
}

.pe-3 {
  padding-right: 1.5rem !important;
}

.pe-4 {
  padding-right: 2.25rem !important;
}

.pe-5 {
  padding-right: 4.5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: .375rem !important;
}

.pb-2 {
  padding-bottom: .75rem !important;
}

.pb-3 {
  padding-bottom: 1.5rem !important;
}

.pb-4 {
  padding-bottom: 2.25rem !important;
}

.pb-5 {
  padding-bottom: 4.5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: .375rem !important;
}

.ps-2 {
  padding-left: .75rem !important;
}

.ps-3 {
  padding-left: 1.5rem !important;
}

.ps-4 {
  padding-left: 2.25rem !important;
}

.ps-5 {
  padding-left: 4.5rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: .375rem !important;
}

.gap-2 {
  gap: .75rem !important;
}

.gap-3 {
  gap: 1.5rem !important;
}

.gap-4 {
  gap: 2.25rem !important;
}

.gap-5 {
  gap: 4.5rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-2 {
  font-size: calc(1.3125rem + .75vw) !important;
}

.fs-3 {
  font-size: calc(1.275rem + .3vw) !important;
}

.fs-4 {
  font-size: 1.125rem !important;
}

.fs-5 {
  font-size: .9375rem !important;
}

.fs-6 {
  font-size: .75rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--ct-text-muted) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: #00000080 !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: #ffffff80 !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: .25;
}

.text-opacity-50 {
  --bs-text-opacity: .5;
}

.text-opacity-75 {
  --bs-text-opacity: .75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: #0000 !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: .1;
}

.bg-opacity-25 {
  --bs-bg-opacity: .25;
}

.bg-opacity-50 {
  --bs-bg-opacity: .5;
}

.bg-opacity-75 {
  --bs-bg-opacity: .75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-2xl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (width >= 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: .375rem !important;
  }

  .m-sm-2 {
    margin: .75rem !important;
  }

  .m-sm-3 {
    margin: 1.5rem !important;
  }

  .m-sm-4 {
    margin: 2.25rem !important;
  }

  .m-sm-5 {
    margin: 4.5rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-sm-1 {
    margin-left: .375rem !important;
    margin-right: .375rem !important;
  }

  .mx-sm-2 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mx-sm-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-sm-4 {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .mx-sm-5 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-sm-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: .375rem !important;
    margin-bottom: .375rem !important;
  }

  .my-sm-2 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .my-sm-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-sm-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: .375rem !important;
  }

  .mt-sm-2 {
    margin-top: .75rem !important;
  }

  .mt-sm-3 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-4 {
    margin-top: 2.25rem !important;
  }

  .mt-sm-5 {
    margin-top: 4.5rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: .375rem !important;
  }

  .me-sm-2 {
    margin-right: .75rem !important;
  }

  .me-sm-3 {
    margin-right: 1.5rem !important;
  }

  .me-sm-4 {
    margin-right: 2.25rem !important;
  }

  .me-sm-5 {
    margin-right: 4.5rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: .375rem !important;
  }

  .mb-sm-2 {
    margin-bottom: .75rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 2.25rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 4.5rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: .375rem !important;
  }

  .ms-sm-2 {
    margin-left: .75rem !important;
  }

  .ms-sm-3 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-4 {
    margin-left: 2.25rem !important;
  }

  .ms-sm-5 {
    margin-left: 4.5rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -.375rem !important;
  }

  .m-sm-n2 {
    margin: -.75rem !important;
  }

  .m-sm-n3 {
    margin: -1.5rem !important;
  }

  .m-sm-n4 {
    margin: -2.25rem !important;
  }

  .m-sm-n5 {
    margin: -4.5rem !important;
  }

  .mx-sm-n1 {
    margin-left: -.375rem !important;
    margin-right: -.375rem !important;
  }

  .mx-sm-n2 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .mx-sm-n3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-sm-n4 {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .mx-sm-n5 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .my-sm-n1 {
    margin-top: -.375rem !important;
    margin-bottom: -.375rem !important;
  }

  .my-sm-n2 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .my-sm-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-sm-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mt-sm-n1 {
    margin-top: -.375rem !important;
  }

  .mt-sm-n2 {
    margin-top: -.75rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n4 {
    margin-top: -2.25rem !important;
  }

  .mt-sm-n5 {
    margin-top: -4.5rem !important;
  }

  .me-sm-n1 {
    margin-right: -.375rem !important;
  }

  .me-sm-n2 {
    margin-right: -.75rem !important;
  }

  .me-sm-n3 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n4 {
    margin-right: -2.25rem !important;
  }

  .me-sm-n5 {
    margin-right: -4.5rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -.375rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -.75rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -2.25rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -4.5rem !important;
  }

  .ms-sm-n1 {
    margin-left: -.375rem !important;
  }

  .ms-sm-n2 {
    margin-left: -.75rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n4 {
    margin-left: -2.25rem !important;
  }

  .ms-sm-n5 {
    margin-left: -4.5rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: .375rem !important;
  }

  .p-sm-2 {
    padding: .75rem !important;
  }

  .p-sm-3 {
    padding: 1.5rem !important;
  }

  .p-sm-4 {
    padding: 2.25rem !important;
  }

  .p-sm-5 {
    padding: 4.5rem !important;
  }

  .px-sm-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-sm-1 {
    padding-left: .375rem !important;
    padding-right: .375rem !important;
  }

  .px-sm-2 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .px-sm-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-sm-4 {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .px-sm-5 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: .375rem !important;
    padding-bottom: .375rem !important;
  }

  .py-sm-2 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .py-sm-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-sm-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: .375rem !important;
  }

  .pt-sm-2 {
    padding-top: .75rem !important;
  }

  .pt-sm-3 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-4 {
    padding-top: 2.25rem !important;
  }

  .pt-sm-5 {
    padding-top: 4.5rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: .375rem !important;
  }

  .pe-sm-2 {
    padding-right: .75rem !important;
  }

  .pe-sm-3 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-4 {
    padding-right: 2.25rem !important;
  }

  .pe-sm-5 {
    padding-right: 4.5rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: .375rem !important;
  }

  .pb-sm-2 {
    padding-bottom: .75rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 2.25rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 4.5rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: .375rem !important;
  }

  .ps-sm-2 {
    padding-left: .75rem !important;
  }

  .ps-sm-3 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-4 {
    padding-left: 2.25rem !important;
  }

  .ps-sm-5 {
    padding-left: 4.5rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: .375rem !important;
  }

  .gap-sm-2 {
    gap: .75rem !important;
  }

  .gap-sm-3 {
    gap: 1.5rem !important;
  }

  .gap-sm-4 {
    gap: 2.25rem !important;
  }

  .gap-sm-5 {
    gap: 4.5rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (width >= 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: .375rem !important;
  }

  .m-md-2 {
    margin: .75rem !important;
  }

  .m-md-3 {
    margin: 1.5rem !important;
  }

  .m-md-4 {
    margin: 2.25rem !important;
  }

  .m-md-5 {
    margin: 4.5rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-md-1 {
    margin-left: .375rem !important;
    margin-right: .375rem !important;
  }

  .mx-md-2 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mx-md-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-md-4 {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .mx-md-5 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-md-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: .375rem !important;
    margin-bottom: .375rem !important;
  }

  .my-md-2 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .my-md-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-md-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: .375rem !important;
  }

  .mt-md-2 {
    margin-top: .75rem !important;
  }

  .mt-md-3 {
    margin-top: 1.5rem !important;
  }

  .mt-md-4 {
    margin-top: 2.25rem !important;
  }

  .mt-md-5 {
    margin-top: 4.5rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: .375rem !important;
  }

  .me-md-2 {
    margin-right: .75rem !important;
  }

  .me-md-3 {
    margin-right: 1.5rem !important;
  }

  .me-md-4 {
    margin-right: 2.25rem !important;
  }

  .me-md-5 {
    margin-right: 4.5rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: .375rem !important;
  }

  .mb-md-2 {
    margin-bottom: .75rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-4 {
    margin-bottom: 2.25rem !important;
  }

  .mb-md-5 {
    margin-bottom: 4.5rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: .375rem !important;
  }

  .ms-md-2 {
    margin-left: .75rem !important;
  }

  .ms-md-3 {
    margin-left: 1.5rem !important;
  }

  .ms-md-4 {
    margin-left: 2.25rem !important;
  }

  .ms-md-5 {
    margin-left: 4.5rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -.375rem !important;
  }

  .m-md-n2 {
    margin: -.75rem !important;
  }

  .m-md-n3 {
    margin: -1.5rem !important;
  }

  .m-md-n4 {
    margin: -2.25rem !important;
  }

  .m-md-n5 {
    margin: -4.5rem !important;
  }

  .mx-md-n1 {
    margin-left: -.375rem !important;
    margin-right: -.375rem !important;
  }

  .mx-md-n2 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .mx-md-n3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-md-n4 {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .mx-md-n5 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .my-md-n1 {
    margin-top: -.375rem !important;
    margin-bottom: -.375rem !important;
  }

  .my-md-n2 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .my-md-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-md-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mt-md-n1 {
    margin-top: -.375rem !important;
  }

  .mt-md-n2 {
    margin-top: -.75rem !important;
  }

  .mt-md-n3 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n4 {
    margin-top: -2.25rem !important;
  }

  .mt-md-n5 {
    margin-top: -4.5rem !important;
  }

  .me-md-n1 {
    margin-right: -.375rem !important;
  }

  .me-md-n2 {
    margin-right: -.75rem !important;
  }

  .me-md-n3 {
    margin-right: -1.5rem !important;
  }

  .me-md-n4 {
    margin-right: -2.25rem !important;
  }

  .me-md-n5 {
    margin-right: -4.5rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -.375rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -.75rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -2.25rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -4.5rem !important;
  }

  .ms-md-n1 {
    margin-left: -.375rem !important;
  }

  .ms-md-n2 {
    margin-left: -.75rem !important;
  }

  .ms-md-n3 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n4 {
    margin-left: -2.25rem !important;
  }

  .ms-md-n5 {
    margin-left: -4.5rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: .375rem !important;
  }

  .p-md-2 {
    padding: .75rem !important;
  }

  .p-md-3 {
    padding: 1.5rem !important;
  }

  .p-md-4 {
    padding: 2.25rem !important;
  }

  .p-md-5 {
    padding: 4.5rem !important;
  }

  .px-md-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-md-1 {
    padding-left: .375rem !important;
    padding-right: .375rem !important;
  }

  .px-md-2 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .px-md-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-md-4 {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .px-md-5 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: .375rem !important;
    padding-bottom: .375rem !important;
  }

  .py-md-2 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .py-md-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-md-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: .375rem !important;
  }

  .pt-md-2 {
    padding-top: .75rem !important;
  }

  .pt-md-3 {
    padding-top: 1.5rem !important;
  }

  .pt-md-4 {
    padding-top: 2.25rem !important;
  }

  .pt-md-5 {
    padding-top: 4.5rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: .375rem !important;
  }

  .pe-md-2 {
    padding-right: .75rem !important;
  }

  .pe-md-3 {
    padding-right: 1.5rem !important;
  }

  .pe-md-4 {
    padding-right: 2.25rem !important;
  }

  .pe-md-5 {
    padding-right: 4.5rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: .375rem !important;
  }

  .pb-md-2 {
    padding-bottom: .75rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-4 {
    padding-bottom: 2.25rem !important;
  }

  .pb-md-5 {
    padding-bottom: 4.5rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: .375rem !important;
  }

  .ps-md-2 {
    padding-left: .75rem !important;
  }

  .ps-md-3 {
    padding-left: 1.5rem !important;
  }

  .ps-md-4 {
    padding-left: 2.25rem !important;
  }

  .ps-md-5 {
    padding-left: 4.5rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: .375rem !important;
  }

  .gap-md-2 {
    gap: .75rem !important;
  }

  .gap-md-3 {
    gap: 1.5rem !important;
  }

  .gap-md-4 {
    gap: 2.25rem !important;
  }

  .gap-md-5 {
    gap: 4.5rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (width >= 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: .375rem !important;
  }

  .m-lg-2 {
    margin: .75rem !important;
  }

  .m-lg-3 {
    margin: 1.5rem !important;
  }

  .m-lg-4 {
    margin: 2.25rem !important;
  }

  .m-lg-5 {
    margin: 4.5rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-lg-1 {
    margin-left: .375rem !important;
    margin-right: .375rem !important;
  }

  .mx-lg-2 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mx-lg-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-lg-4 {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .mx-lg-5 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-lg-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: .375rem !important;
    margin-bottom: .375rem !important;
  }

  .my-lg-2 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .my-lg-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-lg-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: .375rem !important;
  }

  .mt-lg-2 {
    margin-top: .75rem !important;
  }

  .mt-lg-3 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-4 {
    margin-top: 2.25rem !important;
  }

  .mt-lg-5 {
    margin-top: 4.5rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: .375rem !important;
  }

  .me-lg-2 {
    margin-right: .75rem !important;
  }

  .me-lg-3 {
    margin-right: 1.5rem !important;
  }

  .me-lg-4 {
    margin-right: 2.25rem !important;
  }

  .me-lg-5 {
    margin-right: 4.5rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: .375rem !important;
  }

  .mb-lg-2 {
    margin-bottom: .75rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 2.25rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 4.5rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: .375rem !important;
  }

  .ms-lg-2 {
    margin-left: .75rem !important;
  }

  .ms-lg-3 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-4 {
    margin-left: 2.25rem !important;
  }

  .ms-lg-5 {
    margin-left: 4.5rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -.375rem !important;
  }

  .m-lg-n2 {
    margin: -.75rem !important;
  }

  .m-lg-n3 {
    margin: -1.5rem !important;
  }

  .m-lg-n4 {
    margin: -2.25rem !important;
  }

  .m-lg-n5 {
    margin: -4.5rem !important;
  }

  .mx-lg-n1 {
    margin-left: -.375rem !important;
    margin-right: -.375rem !important;
  }

  .mx-lg-n2 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .mx-lg-n3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-lg-n4 {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .mx-lg-n5 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .my-lg-n1 {
    margin-top: -.375rem !important;
    margin-bottom: -.375rem !important;
  }

  .my-lg-n2 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .my-lg-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-lg-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mt-lg-n1 {
    margin-top: -.375rem !important;
  }

  .mt-lg-n2 {
    margin-top: -.75rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n4 {
    margin-top: -2.25rem !important;
  }

  .mt-lg-n5 {
    margin-top: -4.5rem !important;
  }

  .me-lg-n1 {
    margin-right: -.375rem !important;
  }

  .me-lg-n2 {
    margin-right: -.75rem !important;
  }

  .me-lg-n3 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n4 {
    margin-right: -2.25rem !important;
  }

  .me-lg-n5 {
    margin-right: -4.5rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -.375rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -.75rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -2.25rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -4.5rem !important;
  }

  .ms-lg-n1 {
    margin-left: -.375rem !important;
  }

  .ms-lg-n2 {
    margin-left: -.75rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n4 {
    margin-left: -2.25rem !important;
  }

  .ms-lg-n5 {
    margin-left: -4.5rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: .375rem !important;
  }

  .p-lg-2 {
    padding: .75rem !important;
  }

  .p-lg-3 {
    padding: 1.5rem !important;
  }

  .p-lg-4 {
    padding: 2.25rem !important;
  }

  .p-lg-5 {
    padding: 4.5rem !important;
  }

  .px-lg-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-lg-1 {
    padding-left: .375rem !important;
    padding-right: .375rem !important;
  }

  .px-lg-2 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .px-lg-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-lg-4 {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .px-lg-5 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: .375rem !important;
    padding-bottom: .375rem !important;
  }

  .py-lg-2 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .py-lg-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-lg-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: .375rem !important;
  }

  .pt-lg-2 {
    padding-top: .75rem !important;
  }

  .pt-lg-3 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-4 {
    padding-top: 2.25rem !important;
  }

  .pt-lg-5 {
    padding-top: 4.5rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: .375rem !important;
  }

  .pe-lg-2 {
    padding-right: .75rem !important;
  }

  .pe-lg-3 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-4 {
    padding-right: 2.25rem !important;
  }

  .pe-lg-5 {
    padding-right: 4.5rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: .375rem !important;
  }

  .pb-lg-2 {
    padding-bottom: .75rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 2.25rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 4.5rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: .375rem !important;
  }

  .ps-lg-2 {
    padding-left: .75rem !important;
  }

  .ps-lg-3 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-4 {
    padding-left: 2.25rem !important;
  }

  .ps-lg-5 {
    padding-left: 4.5rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: .375rem !important;
  }

  .gap-lg-2 {
    gap: .75rem !important;
  }

  .gap-lg-3 {
    gap: 1.5rem !important;
  }

  .gap-lg-4 {
    gap: 2.25rem !important;
  }

  .gap-lg-5 {
    gap: 4.5rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (width >= 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: .375rem !important;
  }

  .m-xl-2 {
    margin: .75rem !important;
  }

  .m-xl-3 {
    margin: 1.5rem !important;
  }

  .m-xl-4 {
    margin: 2.25rem !important;
  }

  .m-xl-5 {
    margin: 4.5rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xl-1 {
    margin-left: .375rem !important;
    margin-right: .375rem !important;
  }

  .mx-xl-2 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mx-xl-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xl-4 {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .mx-xl-5 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-xl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: .375rem !important;
    margin-bottom: .375rem !important;
  }

  .my-xl-2 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .my-xl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-xl-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: .375rem !important;
  }

  .mt-xl-2 {
    margin-top: .75rem !important;
  }

  .mt-xl-3 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-4 {
    margin-top: 2.25rem !important;
  }

  .mt-xl-5 {
    margin-top: 4.5rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: .375rem !important;
  }

  .me-xl-2 {
    margin-right: .75rem !important;
  }

  .me-xl-3 {
    margin-right: 1.5rem !important;
  }

  .me-xl-4 {
    margin-right: 2.25rem !important;
  }

  .me-xl-5 {
    margin-right: 4.5rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: .375rem !important;
  }

  .mb-xl-2 {
    margin-bottom: .75rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 2.25rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: .375rem !important;
  }

  .ms-xl-2 {
    margin-left: .75rem !important;
  }

  .ms-xl-3 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-4 {
    margin-left: 2.25rem !important;
  }

  .ms-xl-5 {
    margin-left: 4.5rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -.375rem !important;
  }

  .m-xl-n2 {
    margin: -.75rem !important;
  }

  .m-xl-n3 {
    margin: -1.5rem !important;
  }

  .m-xl-n4 {
    margin: -2.25rem !important;
  }

  .m-xl-n5 {
    margin: -4.5rem !important;
  }

  .mx-xl-n1 {
    margin-left: -.375rem !important;
    margin-right: -.375rem !important;
  }

  .mx-xl-n2 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .mx-xl-n3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xl-n4 {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .mx-xl-n5 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .my-xl-n1 {
    margin-top: -.375rem !important;
    margin-bottom: -.375rem !important;
  }

  .my-xl-n2 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .my-xl-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-xl-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mt-xl-n1 {
    margin-top: -.375rem !important;
  }

  .mt-xl-n2 {
    margin-top: -.75rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n4 {
    margin-top: -2.25rem !important;
  }

  .mt-xl-n5 {
    margin-top: -4.5rem !important;
  }

  .me-xl-n1 {
    margin-right: -.375rem !important;
  }

  .me-xl-n2 {
    margin-right: -.75rem !important;
  }

  .me-xl-n3 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n4 {
    margin-right: -2.25rem !important;
  }

  .me-xl-n5 {
    margin-right: -4.5rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -.375rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -.75rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -2.25rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -4.5rem !important;
  }

  .ms-xl-n1 {
    margin-left: -.375rem !important;
  }

  .ms-xl-n2 {
    margin-left: -.75rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n4 {
    margin-left: -2.25rem !important;
  }

  .ms-xl-n5 {
    margin-left: -4.5rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: .375rem !important;
  }

  .p-xl-2 {
    padding: .75rem !important;
  }

  .p-xl-3 {
    padding: 1.5rem !important;
  }

  .p-xl-4 {
    padding: 2.25rem !important;
  }

  .p-xl-5 {
    padding: 4.5rem !important;
  }

  .px-xl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xl-1 {
    padding-left: .375rem !important;
    padding-right: .375rem !important;
  }

  .px-xl-2 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .px-xl-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xl-4 {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .px-xl-5 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: .375rem !important;
    padding-bottom: .375rem !important;
  }

  .py-xl-2 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .py-xl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-xl-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: .375rem !important;
  }

  .pt-xl-2 {
    padding-top: .75rem !important;
  }

  .pt-xl-3 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-4 {
    padding-top: 2.25rem !important;
  }

  .pt-xl-5 {
    padding-top: 4.5rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: .375rem !important;
  }

  .pe-xl-2 {
    padding-right: .75rem !important;
  }

  .pe-xl-3 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-4 {
    padding-right: 2.25rem !important;
  }

  .pe-xl-5 {
    padding-right: 4.5rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: .375rem !important;
  }

  .pb-xl-2 {
    padding-bottom: .75rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 2.25rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 4.5rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: .375rem !important;
  }

  .ps-xl-2 {
    padding-left: .75rem !important;
  }

  .ps-xl-3 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-4 {
    padding-left: 2.25rem !important;
  }

  .ps-xl-5 {
    padding-left: 4.5rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: .375rem !important;
  }

  .gap-xl-2 {
    gap: .75rem !important;
  }

  .gap-xl-3 {
    gap: 1.5rem !important;
  }

  .gap-xl-4 {
    gap: 2.25rem !important;
  }

  .gap-xl-5 {
    gap: 4.5rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (width >= 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: .375rem !important;
  }

  .m-xxl-2 {
    margin: .75rem !important;
  }

  .m-xxl-3 {
    margin: 1.5rem !important;
  }

  .m-xxl-4 {
    margin: 2.25rem !important;
  }

  .m-xxl-5 {
    margin: 4.5rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .mx-xxl-1 {
    margin-left: .375rem !important;
    margin-right: .375rem !important;
  }

  .mx-xxl-2 {
    margin-left: .75rem !important;
    margin-right: .75rem !important;
  }

  .mx-xxl-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }

  .mx-xxl-4 {
    margin-left: 2.25rem !important;
    margin-right: 2.25rem !important;
  }

  .mx-xxl-5 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }

  .mx-xxl-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: .375rem !important;
    margin-bottom: .375rem !important;
  }

  .my-xxl-2 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important;
  }

  .my-xxl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-4 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important;
  }

  .my-xxl-5 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: .375rem !important;
  }

  .mt-xxl-2 {
    margin-top: .75rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-4 {
    margin-top: 2.25rem !important;
  }

  .mt-xxl-5 {
    margin-top: 4.5rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: .375rem !important;
  }

  .me-xxl-2 {
    margin-right: .75rem !important;
  }

  .me-xxl-3 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-4 {
    margin-right: 2.25rem !important;
  }

  .me-xxl-5 {
    margin-right: 4.5rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: .375rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: .75rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 2.25rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 4.5rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: .375rem !important;
  }

  .ms-xxl-2 {
    margin-left: .75rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-4 {
    margin-left: 2.25rem !important;
  }

  .ms-xxl-5 {
    margin-left: 4.5rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -.375rem !important;
  }

  .m-xxl-n2 {
    margin: -.75rem !important;
  }

  .m-xxl-n3 {
    margin: -1.5rem !important;
  }

  .m-xxl-n4 {
    margin: -2.25rem !important;
  }

  .m-xxl-n5 {
    margin: -4.5rem !important;
  }

  .mx-xxl-n1 {
    margin-left: -.375rem !important;
    margin-right: -.375rem !important;
  }

  .mx-xxl-n2 {
    margin-left: -.75rem !important;
    margin-right: -.75rem !important;
  }

  .mx-xxl-n3 {
    margin-left: -1.5rem !important;
    margin-right: -1.5rem !important;
  }

  .mx-xxl-n4 {
    margin-left: -2.25rem !important;
    margin-right: -2.25rem !important;
  }

  .mx-xxl-n5 {
    margin-left: -4.5rem !important;
    margin-right: -4.5rem !important;
  }

  .my-xxl-n1 {
    margin-top: -.375rem !important;
    margin-bottom: -.375rem !important;
  }

  .my-xxl-n2 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n4 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important;
  }

  .my-xxl-n5 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -.375rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -.75rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -2.25rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -4.5rem !important;
  }

  .me-xxl-n1 {
    margin-right: -.375rem !important;
  }

  .me-xxl-n2 {
    margin-right: -.75rem !important;
  }

  .me-xxl-n3 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n4 {
    margin-right: -2.25rem !important;
  }

  .me-xxl-n5 {
    margin-right: -4.5rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -.375rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -.75rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -2.25rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -4.5rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -.375rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -.75rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -2.25rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -4.5rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: .375rem !important;
  }

  .p-xxl-2 {
    padding: .75rem !important;
  }

  .p-xxl-3 {
    padding: 1.5rem !important;
  }

  .p-xxl-4 {
    padding: 2.25rem !important;
  }

  .p-xxl-5 {
    padding: 4.5rem !important;
  }

  .px-xxl-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .px-xxl-1 {
    padding-left: .375rem !important;
    padding-right: .375rem !important;
  }

  .px-xxl-2 {
    padding-left: .75rem !important;
    padding-right: .75rem !important;
  }

  .px-xxl-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }

  .px-xxl-4 {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important;
  }

  .px-xxl-5 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: .375rem !important;
    padding-bottom: .375rem !important;
  }

  .py-xxl-2 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important;
  }

  .py-xxl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-4 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }

  .py-xxl-5 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: .375rem !important;
  }

  .pt-xxl-2 {
    padding-top: .75rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-4 {
    padding-top: 2.25rem !important;
  }

  .pt-xxl-5 {
    padding-top: 4.5rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: .375rem !important;
  }

  .pe-xxl-2 {
    padding-right: .75rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-4 {
    padding-right: 2.25rem !important;
  }

  .pe-xxl-5 {
    padding-right: 4.5rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: .375rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: .75rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 2.25rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 4.5rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: .375rem !important;
  }

  .ps-xxl-2 {
    padding-left: .75rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-4 {
    padding-left: 2.25rem !important;
  }

  .ps-xxl-5 {
    padding-left: 4.5rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: .375rem !important;
  }

  .gap-xxl-2 {
    gap: .75rem !important;
  }

  .gap-xxl-3 {
    gap: 1.5rem !important;
  }

  .gap-xxl-4 {
    gap: 2.25rem !important;
  }

  .gap-xxl-5 {
    gap: 4.5rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (width >= 1200px) {
  .fs-1 {
    font-size: 2.25rem !important;
  }

  .fs-2 {
    font-size: 1.875rem !important;
  }

  .fs-3 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

html {
  min-height: 100%;
  position: relative;
}

body {
  overflow-x: hidden;
}

@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}

.logo {
  width: 260px;
  line-height: 70px;
  display: block;
  position: fixed;
  top: 0;
}

.logo span.logo-lg {
  display: block;
}

.logo span.logo-sm {
  display: none;
}

.logo.logo-light {
  display: block;
}

.logo.logo-dark {
  display: none;
}

.wrapper {
  width: 100%;
  height: 100%;
  overflow: clip;
}

.content-page {
  min-height: 100vh;
  margin-left: 260px;
  padding: 70px 12px 65px;
  overflow: clip;
}

.leftside-menu {
  z-index: 10;
  background: var(--ct-bg-leftbar-gradient);
  box-shadow: var(--ct-box-shadow);
  width: 260px;
  padding-top: 70px;
  position: fixed;
  top: 0;
  bottom: 0;
}

.side-nav {
  padding-left: 0;
  list-style-type: none;
}

.side-nav ul {
  list-style-type: none;
}

.side-nav .side-nav-link {
  color: var(--ct-menu-item);
  padding: 10px 30px;
  font-size: .9375rem;
  list-style: none;
  transition: all .4s;
  display: block;
  position: relative;
}

.side-nav .side-nav-link:hover, .side-nav .side-nav-link:focus, .side-nav .side-nav-link:active, .side-nav .side-nav-link.side-nav--has-link.active {
  text-decoration: none;
  color: var(--ct-menu-item-hover) !important;
}

.side-nav .side-nav-link span {
  vertical-align: middle;
}

.side-nav .side-nav-link i {
  vertical-align: middle;
  width: 20px;
  margin: 0 10px 0 0;
  font-size: 1.1rem;
  line-height: 1.0625rem;
  display: inline-block;
}

.side-nav .menu-arrow {
  text-rendering: auto;
  font-family: Material Design Icons;
  font-size: 1.1rem;
  line-height: 1.5rem;
  transition: transform .15s;
  display: inline-block;
  position: absolute;
  right: 30px;
  transform: translate(0);
}

.side-nav .menu-arrow:before {
  content: "󰅂";
}

.side-nav .badge {
  margin-top: 3px;
}

.side-nav.has-arrow .badge {
  margin-right: 20px;
}

.side-nav .side-nav-item > a[aria-expanded="true"] > span.menu-arrow, .side-nav .side-nav-item.menuitem-active > a:not(.collapsed) > span.menu-arrow, .side-nav .side-nav-item.menuitem-open > a:not(.collapsed) > span.menu-arrow {
  transform: rotate(90deg);
}

.side-nav .side-nav-title {
  letter-spacing: .05em;
  pointer-events: none;
  cursor: default;
  text-transform: uppercase;
  color: var(--ct-menu-item);
  padding: 12px 30px;
  font-size: .6875rem;
  font-weight: 700;
}

.side-nav .menuitem-active > a {
  color: var(--ct-menu-item-active) !important;
}

[dir="ltr"] .side-nav .side-nav-item .menu-arrow:before {
  content: "󰅁" !important;
}

.side-nav-second-level, .side-nav-third-level, .side-nav-forth-level {
  padding-left: 0;
}

.side-nav-second-level li a, .side-nav-second-level li .side-nav-link, .side-nav-third-level li a, .side-nav-third-level li .side-nav-link, .side-nav-forth-level li a, .side-nav-forth-level li .side-nav-link {
  color: var(--ct-menu-item);
  padding: 8px 30px 8px 65px;
  font-size: .89rem;
  transition: all .4s;
  display: block;
  position: relative;
}

.side-nav-second-level li a:focus, .side-nav-second-level li a:hover, .side-nav-second-level li .side-nav-link:focus, .side-nav-second-level li .side-nav-link:hover, .side-nav-third-level li a:focus, .side-nav-third-level li a:hover, .side-nav-third-level li .side-nav-link:focus, .side-nav-third-level li .side-nav-link:hover, .side-nav-forth-level li a:focus, .side-nav-forth-level li a:hover, .side-nav-forth-level li .side-nav-link:focus, .side-nav-forth-level li .side-nav-link:hover {
  color: var(--ct-menu-item-hover);
}

.side-nav-second-level li a .menu-arrow, .side-nav-second-level li .side-nav-link .menu-arrow, .side-nav-third-level li a .menu-arrow, .side-nav-third-level li .side-nav-link .menu-arrow, .side-nav-forth-level li a .menu-arrow, .side-nav-forth-level li .side-nav-link .menu-arrow {
  line-height: 1.3rem;
}

.side-nav-second-level li.active > a, .side-nav-third-level li.active > a, .side-nav-forth-level li.active > a {
  color: var(--ct-menu-item-active);
}

.side-nav-third-level li a, .side-nav-third-level li .side-nav-link {
  padding: 8px 30px 8px 80px;
}

.side-nav-forth-level li a, .side-nav-forth-level li .side-nav-link {
  padding: 8px 30px 8px 100px;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .leftside-menu {
  z-index: 5;
  width: 70px;
  padding-top: 70px;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .leftside-menu .simplebar-mask, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .leftside-menu .simplebar-content-wrapper {
  overflow: visible !important;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .leftside-menu .simplebar-scrollbar {
  display: none !important;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .leftside-menu .simplebar-offset {
  bottom: 0 !important;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .leftside-menu .logo {
  z-index: 1;
  background: var(--ct-bg-leftbar-gradient);
  width: 70px;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .help-box {
  display: none;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .content-page {
  margin-left: 70px;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .navbar-custom, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .wrapper .footer {
  left: 70px;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-title, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .badge, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .menu-arrow {
  display: none !important;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .collapse, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .collapsing {
  display: none;
  height: inherit !important;
  transition: none !important;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .collapse .side-nav-second-level, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .collapse .side-nav-third-level, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .collapse .side-nav-forth-level, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .collapsing .side-nav-second-level, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .collapsing .side-nav-third-level, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .collapsing .side-nav-forth-level {
  display: none !important;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item {
  white-space: nowrap;
  position: relative;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link {
  min-height: 54px;
  padding: 15px 20px;
  transition: none;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link:hover, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link:active, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link:focus {
  color: var(--ct-menu-item-hover);
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link i {
  margin-left: 6px;
  margin-right: 20px;
  font-size: 1.3rem;
  line-height: 1rem;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item .side-nav-link span {
  padding-left: 10px;
  display: none;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover .side-nav-link {
  color: #fff;
  background: var(--ct-bg-leftbar-gradient);
  width: 260px;
  position: relative;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover .side-nav-link span {
  display: inline;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > ul {
  z-index: 9999;
  background: var(--ct-bg-leftbar-gradient);
  box-shadow: var(--ct-box-shadow);
  width: 190px;
  padding: 5px 0;
  position: absolute;
  left: 70px;
  height: auto !important;
  display: block !important;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > ul a {
  width: 190px;
  padding: 8px 20px;
  position: relative;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > ul a:hover {
  color: var(--ct-menu-item-hover);
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapse, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapsing {
  transition: none !important;
  display: block !important;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapse > ul, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapsing > ul {
  background: var(--ct-bg-leftbar-gradient);
  box-shadow: var(--ct-box-shadow);
  width: 190px;
  position: absolute;
  left: 70px;
  display: block !important;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapse > ul a, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapsing > ul a {
  box-shadow: none;
  z-index: 6;
  width: 190px;
  padding: 8px 20px;
  position: relative;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapse > ul a:hover, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapsing > ul a:hover {
  color: var(--ct-menu-item-hover);
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapse > ul li:hover > .collapse, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapsing > ul li:hover > .collapse {
  height: auto !important;
  transition: none !important;
  display: block !important;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapse > ul li:hover > .collapse > ul, body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .side-nav .side-nav-item:hover > .collapsing > ul li:hover > .collapse > ul {
  width: 190px;
  display: block;
  position: absolute;
  top: 0;
  left: 190px;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .logo span.logo-lg {
  display: none;
}

body[data-leftbar-compact-mode="condensed"]:not(.authentication-bg) .logo span.logo-sm {
  color: #727cf5;
  line-height: 70px;
  display: block;
}

.leftside-menu-backdrop {
  z-index: 900;
  background: #0003;
  display: none;
  position: fixed;
  inset: 0;
}

.leftside-menu-backdrop.open {
  display: block;
}

@media (width <= 767.98px) {
  body {
    overflow-x: hidden;
  }

  .leftside-menu {
    box-shadow: var(--ct-box-shadow);
    padding-bottom: 80px;
    transition: transform .2s ease-in-out;
    transform: translate(260px);
    z-index: 901 !important;
  }

  .leftside-menu.open {
    transform: translate(0);
  }

  body.sidebar-enable {
    overflow: hidden;
  }

  .navbar-nav.navbar-right {
    float: right;
  }

  .content-page {
    padding: 65px 10px;
    margin-left: 0 !important;
  }

  body[data-leftbar-compact-mode="condensed"] .leftside-menu {
    margin-left: 0;
  }

  .logo span.logo-lg {
    display: block;
  }

  .logo span.logo-sm {
    display: none;
  }
}

.help-box {
  background-color: var(--ct-help-box-light-bg);
  border-radius: 5px;
  margin: 65px 25px 25px;
  padding: 20px;
  position: relative;
}

.help-box .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

body[data-leftbar-theme="light"] .help-box {
  background-color: #727cf5;
}

body[data-leftbar-theme="light"] .logo.logo-light {
  display: none;
}

body[data-leftbar-theme="light"] .logo.logo-dark {
  display: block;
}

body[data-layout="topnav"] .content-page {
  padding: 0 0 60px;
  margin-left: 0 !important;
}

body[data-layout-mode="boxed"] {
  background-color: var(--ct-boxed-layout-bg);
}

body[data-layout-mode="boxed"] .wrapper {
  box-shadow: var(--ct-box-shadow);
  background-color: #fafbfe;
  max-width: 1300px;
  margin: 0 auto;
}

body[data-layout-mode="boxed"][data-leftbar-compact-mode="condensed"] .logo {
  margin-top: -70px;
  position: relative;
}

@media (width >= 1200px) {
  body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"]) {
    padding-bottom: 0;
  }

  body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"]) .wrapper {
    display: flex;
  }

  body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"]) .leftside-menu {
    min-width: 260px;
    max-width: 260px;
    padding-top: 0;
    position: relative;
  }

  body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"]) .logo {
    margin-top: 0;
    position: relative;
  }

  body[data-leftbar-compact-mode="scrollable"]:not([data-layout="topnav"]) .content-page {
    width: 100%;
    margin-left: 0;
    padding-bottom: 60px;
  }
}

body[data-layout="detached"] {
  padding-bottom: 0;
}

@media (width >= 992px) {
  body[data-layout="detached"] .container-fluid, body[data-layout="detached"] .container-sm, body[data-layout="detached"] .container-md, body[data-layout="detached"] .container-lg, body[data-layout="detached"] .container-xl, body[data-layout="detached"] .container-xxl {
    max-width: 95%;
  }
}

body[data-layout="detached"][data-layout-mode="boxed"] .wrapper {
  max-width: 100%;
}

body[data-layout="detached"] .wrapper {
  overflow: inherit;
  display: flex;
}

body[data-layout="detached"] .content-page {
  width: 100%;
  margin-left: 0;
  margin-right: -15px;
  padding: 0 15px 60px 30px;
  position: relative;
  overflow: hidden;
}

body[data-layout="detached"] .leftside-menu {
  box-shadow: var(--ct-box-shadow);
  min-width: 260px;
  max-width: 260px;
  margin-top: 30px;
  position: relative;
  background: var(--ct-bg-detached-leftbar) !important;
  z-index: 1001 !important;
  padding-top: 0 !important;
}

body[data-layout="detached"] .leftside-menu .side-nav .side-nav-link {
  color: var(--ct-menu-item) !important;
}

body[data-layout="detached"] .leftside-menu .side-nav .side-nav-link:hover, body[data-layout="detached"] .leftside-menu .side-nav .side-nav-link:focus, body[data-layout="detached"] .leftside-menu .side-nav .side-nav-link:active {
  color: var(--ct-menu-item-hover) !important;
}

body[data-layout="detached"] .leftside-menu .side-nav .side-nav-second-level li a, body[data-layout="detached"] .leftside-menu .side-nav .side-nav-third-level li a, body[data-layout="detached"] .leftside-menu .side-nav .side-nav-forth-level li a {
  color: var(--ct-menu-item);
}

body[data-layout="detached"] .leftside-menu .side-nav .side-nav-second-level li a:focus, body[data-layout="detached"] .leftside-menu .side-nav .side-nav-second-level li a:hover, body[data-layout="detached"] .leftside-menu .side-nav .side-nav-third-level li a:focus, body[data-layout="detached"] .leftside-menu .side-nav .side-nav-third-level li a:hover, body[data-layout="detached"] .leftside-menu .side-nav .side-nav-forth-level li a:focus, body[data-layout="detached"] .leftside-menu .side-nav .side-nav-forth-level li a:hover {
  color: var(--ct-menu-item-hover);
}

body[data-layout="detached"] .leftside-menu .side-nav .side-nav-second-level li.mm-active > a, body[data-layout="detached"] .leftside-menu .side-nav .side-nav-third-level li.mm-active > a, body[data-layout="detached"] .leftside-menu .side-nav .side-nav-forth-level li.mm-active > a {
  color: var(--ct-menu-item-active);
}

body[data-layout="detached"] .leftside-menu .side-nav .menuitem-active > a {
  color: var(--ct-menu-item-active) !important;
}

body[data-layout="detached"] .leftside-menu .side-nav .side-nav-title {
  color: var(--ct-menu-item);
}

body[data-layout="detached"] .leftbar-user {
  text-align: center;
  background: url("waves-LETMGBQO-LETMGBQO.bae8cd7a.png") no-repeat;
  padding: 30px 20px;
}

body[data-layout="detached"] .leftbar-user .leftbar-user-name {
  color: #313a46;
  margin-top: 8px;
  margin-left: 12px;
  font-weight: 700;
  display: block;
}

@media (width <= 767.98px) {
  body[data-layout="detached"].sidebar-enable .leftside-menu {
    margin-top: 70px;
    position: fixed;
    left: 0;
    overflow-y: auto;
  }

  body[data-layout="detached"] .wrapper {
    max-width: 100%;
  }

  body[data-layout="detached"] .content-page {
    padding: 0 10px 60px;
    margin-left: 0 !important;
  }

  body[data-layout="detached"] .lang-switch {
    display: none;
  }

  body[data-layout="detached"][data-leftbar-compact-mode="condensed"].sidebar-enable .leftside-menu {
    margin-top: 0;
  }
}

body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .wrapper .leftside-menu {
  min-width: 70px;
  max-width: 70px;
  position: relative;
}

body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .wrapper .leftbar-user {
  display: none;
}

body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .wrapper .content-page {
  margin-left: 0;
}

body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .wrapper .footer {
  left: 0;
}

body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:hover, body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:active, body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:focus {
  color: var(--ct-menu-item-hover);
}

body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover .side-nav-link {
  background: #727cf5;
  transition: none;
  color: #fff !important;
}

body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover ul {
  box-shadow: var(--ct-box-shadow);
  background: var(--ct-bg-leftbar) !important;
}

body[data-layout="detached"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover ul a:hover {
  color: var(--ct-menu-item-hover) !important;
}

@media (width >= 992px) {
  body[data-layout="detached"][data-leftbar-compact-mode="scrollable"] .wrapper {
    padding-top: 70px;
  }
}

.button-menu-mobile .lines {
  width: 18px;
  height: 16px;
  margin-top: 26px;
  margin-left: 10px;
  transition: all .5s;
  display: block;
  position: relative;
}

.button-menu-mobile span {
  background-color: #fffc;
  width: 100%;
  height: 2px;
  margin-bottom: 5px;
  transition: transform .5s;
  display: block;
}

.button-menu-mobile span:nth-of-type(2) {
  width: 24px;
}

body[data-leftbar-theme="light"] .leftside-menu {
  background: var(--ct-bg-leftbar);
}

body[data-leftbar-theme="light"] .leftside-menu .logo {
  background: var(--ct-bg-leftbar) !important;
}

body[data-leftbar-theme="light"] .side-nav .side-nav-link {
  color: var(--ct-menu-item);
}

body[data-leftbar-theme="light"] .side-nav .side-nav-link:hover, body[data-leftbar-theme="light"] .side-nav .side-nav-link:focus, body[data-leftbar-theme="light"] .side-nav .side-nav-link:active {
  color: var(--ct-menu-item-hover);
}

body[data-leftbar-theme="light"] .side-nav .menuitem-active > a {
  color: var(--ct-menu-item-active) !important;
}

body[data-leftbar-theme="light"] .side-nav .side-nav-title, body[data-leftbar-theme="light"] .side-nav .side-nav-second-level li a, body[data-leftbar-theme="light"] .side-nav .side-nav-third-level li a, body[data-leftbar-theme="light"] .side-nav .side-nav-forth-level li a {
  color: var(--ct-menu-item);
}

body[data-leftbar-theme="light"] .side-nav .side-nav-second-level li a:focus, body[data-leftbar-theme="light"] .side-nav .side-nav-second-level li a:hover, body[data-leftbar-theme="light"] .side-nav .side-nav-third-level li a:focus, body[data-leftbar-theme="light"] .side-nav .side-nav-third-level li a:hover, body[data-leftbar-theme="light"] .side-nav .side-nav-forth-level li a:focus, body[data-leftbar-theme="light"] .side-nav .side-nav-forth-level li a:hover {
  color: var(--ct-menu-item-hover);
}

body[data-leftbar-theme="light"] .side-nav .side-nav-second-level li.active > a, body[data-leftbar-theme="light"] .side-nav .side-nav-third-level li.active > a, body[data-leftbar-theme="light"] .side-nav .side-nav-forth-level li.active > a {
  color: var(--ct-menu-item-active);
}

body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:hover, body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:active, body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:focus {
  color: var(--ct-menu-item-hover);
}

body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover .side-nav-link {
  background: #727cf5;
  transition: none;
  color: #fff !important;
}

body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > ul {
  background: var(--ct-bg-leftbar);
  box-shadow: var(--ct-box-shadow);
}

body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > ul a:hover {
  color: var(--ct-menu-item-hover);
}

body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > .collapse > ul, body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > .collapsing > ul {
  background: var(--ct-bg-leftbar);
}

body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > .collapse > ul a:hover, body[data-leftbar-theme="light"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > .collapsing > ul a:hover {
  color: var(--ct-menu-item-hover);
}

body[data-leftbar-theme="dark"] .leftside-menu {
  background: var(--ct-bg-leftbar);
}

body[data-leftbar-theme="dark"] .leftside-menu .logo {
  background: var(--ct-bg-leftbar) !important;
}

body[data-leftbar-theme="dark"] .side-nav .side-nav-link {
  color: var(--ct-dark-menu-item);
}

body[data-leftbar-theme="dark"] .side-nav .side-nav-link:hover, body[data-leftbar-theme="dark"] .side-nav .side-nav-link:focus, body[data-leftbar-theme="dark"] .side-nav .side-nav-link:active {
  color: var(--ct-dark-menu-item-hover);
}

body[data-leftbar-theme="dark"] .side-nav .menuitem-active > a {
  color: var(--ct-dark-menu-item-active) !important;
}

body[data-leftbar-theme="dark"] .side-nav .side-nav-title, body[data-leftbar-theme="dark"] .side-nav .side-nav-second-level li a, body[data-leftbar-theme="dark"] .side-nav .side-nav-third-level li a, body[data-leftbar-theme="dark"] .side-nav .side-nav-forth-level li a {
  color: var(--ct-dark-menu-item);
}

body[data-leftbar-theme="dark"] .side-nav .side-nav-second-level li a:focus, body[data-leftbar-theme="dark"] .side-nav .side-nav-second-level li a:hover, body[data-leftbar-theme="dark"] .side-nav .side-nav-third-level li a:focus, body[data-leftbar-theme="dark"] .side-nav .side-nav-third-level li a:hover, body[data-leftbar-theme="dark"] .side-nav .side-nav-forth-level li a:focus, body[data-leftbar-theme="dark"] .side-nav .side-nav-forth-level li a:hover {
  color: var(--ct-dark-menu-item-hover);
}

body[data-leftbar-theme="dark"] .side-nav .side-nav-second-level li.active > a, body[data-leftbar-theme="dark"] .side-nav .side-nav-third-level li.active > a, body[data-leftbar-theme="dark"] .side-nav .side-nav-forth-level li.active > a {
  color: var(--ct-dark-menu-item-active);
}

body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:hover, body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:active, body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item .side-nav-link:focus {
  color: var(--ct-dark-menu-item-hover);
}

body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover .side-nav-link {
  background: #727cf5;
  transition: none;
  color: #fff !important;
}

body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > ul {
  background: var(--ct-bg-leftbar);
  box-shadow: var(--ct-box-shadow);
}

body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > ul a:hover {
  color: var(--ct-dark-menu-item-hover);
}

body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > .collapse > ul, body[data-leftbar-theme="dark"][data-leftbar-compact-mode="condensed"] .side-nav .side-nav-item:hover > .collapsing > ul {
  background: var(--ct-bg-leftbar);
}

body[data-layout="full"] .navbar-custom .button-menu-mobile.disable-btn {
  display: block;
}

body[data-layout="full"].hide-menu .wrapper .leftside-menu {
  padding-top: 0;
  width: 0 !important;
}

body[data-layout="full"].hide-menu .wrapper .leftside-menu .side-nav {
  opacity: .2;
}

body[data-layout="full"].hide-menu .wrapper .leftside-menu .logo {
  width: 70px;
  background: var(--ct-bg-leftbar-gradient) !important;
}

body[data-layout="full"].hide-menu .wrapper .leftside-menu .logo span.logo-lg {
  display: none;
}

body[data-layout="full"].hide-menu .wrapper .leftside-menu .logo span.logo-sm {
  display: block;
}

body[data-layout="full"].hide-menu .content-page {
  margin-left: 0 !important;
}

body[data-layout="full"].hide-menu .content-page .navbar-custom {
  left: 70px;
}

body[data-layout="full"].hide-menu .content-page .footer {
  left: 0 !important;
}

body[data-layout="full"].hide-menu[data-leftbar-compact-mode="condensed"] .wrapper .leftside-menu {
  padding-top: 70px;
}

body[data-layout="full"].hide-menu[data-leftbar-compact-mode="condensed"] .wrapper .leftside-menu .side-nav {
  visibility: hidden;
  transform: translate(-50px);
}

body[data-layout="full"][data-leftbar-compact-mode="scrollable"].hide-menu .wrapper .leftside-menu {
  min-width: 0;
  max-width: 0;
}

body[data-layout="full"][data-leftbar-compact-mode="scrollable"] .wrapper .leftside-menu {
  padding-top: 0 !important;
}

body[data-layout="full"][data-leftbar-theme="dark"].hide-menu .wrapper .leftside-menu .logo, body[data-layout="full"][data-leftbar-theme="light"].hide-menu .wrapper .leftside-menu .logo {
  background: var(--ct-bg-leftbar) !important;
}

body[data-layout-color="dark"][data-layout-mode="boxed"] .wrapper {
  background-color: var(--ct-body-bg);
}

body[data-layout-color="dark"][data-layout="detached"] .leftbar-user .leftbar-user-name {
  color: #f1f1f1;
}

.navbar-custom {
  background-color: var(--ct-bg-topbar);
  box-shadow: var(--ct-box-shadow);
  z-index: 904;
  min-height: 70px;
  padding: 0 24px;
  position: fixed;
  top: 0;
  left: 260px;
  right: 0;
}

.navbar-custom .topbar-left {
  background-color: var(--ct-bg-topbar);
  z-index: 1;
  text-align: center;
  width: 260px;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
}

.navbar-custom .topbar-left .logo {
  line-height: 70px;
}

.navbar-custom .topbar-left .logo i {
  display: none;
}

.navbar-custom .topbar-menu {
  z-index: 1;
  position: relative;
}

.navbar-custom .topbar-menu li {
  float: left;
  max-height: 70px;
}

.navbar-custom .topbar-menu li .show.nav-link {
  color: #6c757d;
}

.navbar-custom .topbar-menu .nav-link {
  color: #98a6ad;
  text-align: center;
  min-width: 32px;
  margin: 0 10px;
  padding: 0;
  display: block;
  position: relative;
}

.navbar-custom .app-search .react-select__menu, .navbar-custom .app-search .react-select__control {
  width: 100% !important;
}

.navbar-custom .app-search-inline .react-select__menu, .navbar-custom .app-search-inline .react-select__control {
  width: 320px !important;
}

.navbar-custom .app-search {
  padding: 16px 5px 16px 0;
  position: relative;
  overflow-y: hidden;
}

.navbar-custom .app-search .react-select__control, .navbar-custom .app-search .react-select__menu {
  overflow: hidden;
}

.navbar-custom .app-search .react-select__control .react-select__option.react-select__option--is-selected .dropdown-item, .navbar-custom .app-search .react-select__menu .react-select__option.react-select__option--is-selected .dropdown-item {
  color: rgba(var(--ct-input-color), .7) !important;
}

.navbar-custom .app-search .react-select__control .react-select__value-container .react-select__placeholder, .navbar-custom .app-search .react-select__menu .react-select__value-container .react-select__placeholder, .navbar-custom .app-search .react-select__control .react-select__value-container .react-select__input-container, .navbar-custom .app-search .react-select__menu .react-select__value-container .react-select__input-container, .navbar-custom .app-search .react-select__control .react-select__value-container .react-select__input-container .react-select__input, .navbar-custom .app-search .react-select__menu .react-select__value-container .react-select__input-container .react-select__input {
  color: var(--ct-input-color) !important;
}

.topbar-dropdown .nav-link {
  line-height: 70px;
}

.app-search .form-control {
  background-color: var(--ct-bg-topbar-search);
  box-shadow: none;
  border: none;
  height: calc(1.5em + .9rem + 2px);
  padding-left: 20px;
  padding-right: 40px;
}

.app-search button {
  z-index: 4;
  position: absolute;
  top: 16px;
  right: 6px;
}

.notification-list {
  margin-left: 0;
}

.notification-list .noti-title {
  background-color: #0000;
  padding: 15px 20px;
}

.notification-list .noti-icon {
  vertical-align: middle;
  font-size: 22px;
  line-height: 70px;
}

.notification-list .noti-icon-badge {
  background-color: #fa5c7c;
  border-radius: 50%;
  width: 7px;
  height: 7px;
  display: inline-block;
  position: absolute;
  top: 22px;
  right: 6px;
}

.notification-list .notify-item {
  padding: 10px 20px;
}

.notification-list .notify-item.unread-noti {
  background-color: #f3f5fb;
}

.notification-list .notify-item.read-noti {
  background-color: #0000;
  border: 1px solid #eef2f7;
}

.notification-list .notify-item .card-body {
  padding: 14px;
}

.notification-list .notify-item .card-body .noti-close-btn {
  position: absolute;
  top: 3px;
  right: 5px;
}

.notification-list .notify-item .card-body .noti-item-title {
  margin: 0 0 2px;
}

.notification-list .notify-item .card-body .noti-item-title, .notification-list .notify-item .card-body .noti-item-subtitle {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.notification-list .notify-item .notify-icon {
  float: left;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  line-height: 36px;
}

.notification-list .notify-item .notify-details {
  margin-bottom: 0;
  margin-left: 45px;
  overflow: hidden;
}

.notification-list .notify-item .notify-details b {
  font-weight: 500;
}

.notification-list .notify-item .notify-details small, .notification-list .notify-item .notify-details .small {
  display: block;
}

.notification-list .notify-item .user-msg {
  white-space: normal;
  margin-left: 45px;
  line-height: 16px;
}

.notification-list .topbar-dropdown-menu .notify-item {
  padding: 7px 20px;
}

.profile-dropdown {
  min-width: 170px;
}

.profile-dropdown i, .profile-dropdown span {
  vertical-align: middle;
}

.account {
  min-height: 70px;
  text-align: left !important;
}

.button-menu-mobile {
  color: #313a46;
  cursor: pointer;
  float: left;
  z-index: 1;
  background-color: #0000;
  border: none;
  width: 60px;
  height: 70px;
  margin-left: -24px;
  font-size: 24px;
  line-height: 70px;
  position: relative;
}

.button-menu-mobile.disable-btn {
  display: none;
}

@media (width <= 767.98px) {
  .button-menu-mobile {
    margin: 0 !important;
  }
}

[data-keep-enlarged="true"] .navbar-custom {
  padding-left: 0;
}

[data-keep-enlarged="true"] .button-menu-mobile {
  margin: 0;
}

[data-keep-enlarged="true"] .button-menu-mobile.disable-btn {
  display: inline-block;
}

@media (width <= 576px) {
  .navbar-custom .topbar-menu {
    position: initial;
  }

  .navbar-custom .dropdown {
    position: static;
  }

  .navbar-custom .dropdown .dropdown-menu {
    left: 10px !important;
    right: 10px !important;
  }
}

@media (width <= 1200px) {
  .navbar-custom {
    right: 0;
  }

  .navbar-custom .app-search-inline {
    display: none;
  }
}

@media (width <= 991.98px) {
  .nav-user {
    padding: 17px 5px 17px 57px !important;
  }

  .nav-user .account-position, .nav-user .account-user-name {
    display: none;
  }
}

@media (width <= 767.98px) {
  .navbar-custom {
    padding: 0;
    left: 0 !important;
  }

  .button-menu-mobile.disable-btn {
    display: inline-block;
  }
}

@media (width <= 375px) {
  .navbar-custom .topbar-menu .nav-link {
    margin: 0 5px;
  }

  .navbar-custom.topnav-navbar .container-fluid, .navbar-custom.topnav-navbar .container-sm, .navbar-custom.topnav-navbar .container-md, .navbar-custom.topnav-navbar .container-lg, .navbar-custom.topnav-navbar .container-xl, .navbar-custom.topnav-navbar .container-xxl {
    padding-left: 12px;
    padding-right: 12px;
  }

  .navbar-custom.topnav-navbar .navbar-toggle {
    margin: 27px 3px;
  }

  .navbar-custom.topnav-navbar .button-menu-mobile {
    width: auto;
  }
}

@media (width >= 992px) {
  body[data-leftbar-compact-mode="scrollable"]:not([data-layout-mode="boxed"]):not([data-layout="topnav"]) .navbar-custom {
    position: absolute;
  }
}

.topnav-navbar-dark {
  background-color: var(--ct-bg-dark-topbar);
}

.topnav-navbar-dark .nav-user {
  background-color: var(--ct-nav-user-bg-dark-topbar) !important;
  border: 1px solid var(--ct-nav-user-border-dark-topbar) !important;
}

.topnav-navbar-dark .topbar-menu li .show.nav-link {
  color: #fff !important;
}

.topnav-navbar-dark .app-search span {
  color: #98a6ad;
}

.topnav-navbar-dark .navbar-toggle span {
  background-color: #fffc !important;
}

body[data-layout-mode="boxed"] .navbar-custom {
  margin: -70px -12px 0;
  position: relative;
  left: 0 !important;
}

body[data-layout-mode="boxed"][data-layout="topnav"] .navbar-custom {
  margin: 0;
}

body[data-layout-color="dark"] .navbar-custom .topbar-menu li .show.nav-link {
  color: #dee2e6;
}

body[data-layout-color="dark"] .navbar-custom .topbar-menu .nav-link {
  color: #ced4da;
}

body[data-layout-color="dark"] .notification-list .notify-item.unread-noti {
  background-color: #48515d;
}

body[data-layout-color="dark"] .notification-list .notify-item.read-noti {
  border: 1px solid #464f5b;
}

body[data-layout-color="dark"] .button-menu-mobile {
  color: #f1f1f1;
}

body[data-layout-color="dark"] .nav-user {
  background-color: #464f5b;
  border: 1px solid #404954;
}

body[data-layout-color="dark"] .topnav-navbar-dark .app-search span, body[data-layout-color="dark"] .topnav-navbar-dark .app-search .react-select__placeholder {
  color: #ced4da;
}

.end-bar {
  background-color: var(--ct-rightbar-bg);
  z-index: 9999;
  width: 280px;
  padding-bottom: 80px;
  transition: all .2s ease-out;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -290px;
  box-shadow: 0 0 24px #313a461a, 0 1px #313a4614;
  float: right !important;
}

.end-bar .rightbar-title {
  background-color: var(--ct-rightbar-title-bg);
  color: var(--ct-rightbar-title-color);
  padding: 27px 25px;
}

.end-bar .end-bar-toggle {
  background-color: var(--ct-rightbar-title-btn-bg);
  color: var(--ct-rightbar-title-btn-color);
  text-align: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-top: -4px;
  line-height: 22px;
}

.rightbar-overlay {
  background-color: var(--ct-rightbar-overlay-bg);
  opacity: .2;
  z-index: 9998;
  transition: all .2s ease-out;
  display: none;
  position: absolute;
  inset: 0;
}

.end-bar-enabled .end-bar {
  right: 0;
}

.end-bar-enabled .rightbar-overlay {
  display: block;
}

@media (width <= 767.98px) {
  .end-bar {
    overflow: auto;
  }
}

.page-title-box .page-title {
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--ct-page-title-color);
  margin: 0;
  font-size: 18px;
  line-height: 75px;
  overflow: hidden;
}

.page-title-box .page-title-right {
  float: right;
  margin-top: 20px;
}

.page-title-box .breadcrumb {
  padding-top: 8px;
}

.page-title-box-sm .page-title {
  margin-bottom: 25px;
  line-height: 1 !important;
}

.page-title-box-sm .page-title-right {
  float: right;
  margin-top: 0;
}

.page-title-box-sm .breadcrumb {
  padding-top: 0;
  margin-top: -3px !important;
}

.text-title, .text-title:hover {
  color: var(--ct-text-title-color);
}

@media (width <= 767.98px) {
  .page-title-box .page-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 70px;
    display: block;
    overflow: hidden;
  }

  .page-title-box .page-title-right {
    margin-top: 16px;
  }
}

.footer {
  color: #98a6ad;
  border-top: 1px solid #98a6ad33;
  padding: 19px 24px 20px;
  transition: all .2s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 260px;
  right: 0;
}

.footer .footer-links a {
  color: #98a6ad;
  margin-left: 1.5rem;
  transition: all .4s;
}

.footer .footer-links a:hover {
  color: #313a46;
}

.footer .footer-links a:first-of-type {
  margin-left: 0;
}

@media (width <= 767.98px) {
  .footer {
    text-align: center;
    left: 0 !important;
  }
}

.footer-alt {
  text-align: center;
  border: none;
  left: 0;
}

body[data-layout="topnav"] .footer {
  padding: 19px 0 20px;
  left: 0 !important;
}

body[data-layout="topnav"][data-layout-mode="boxed"] .footer {
  max-width: 1300px;
}

body[data-layout-mode="boxed"] .footer {
  box-shadow: var(--ct-box-shadow);
  background-color: #fafbfe;
  border: none;
  max-width: 1040px;
  margin: 0 auto;
}

body[data-layout-mode="boxed"][data-leftbar-compact-mode="condensed"] .footer {
  max-width: 1230px;
}

body[data-layout="detached"] .footer {
  left: 0;
}

body[data-layout="detached"] .footer .container-fluid, body[data-layout="detached"] .footer .container-sm, body[data-layout="detached"] .footer .container-md, body[data-layout="detached"] .footer .container-lg, body[data-layout="detached"] .footer .container-xl, body[data-layout="detached"] .footer .container-xxl {
  max-width: 100%;
  padding: 0;
}

body[data-layout-color="dark"] .footer {
  color: #ced4da;
  border-top: 1px solid #ced4da33;
}

body[data-layout-color="dark"] .footer .footer-links a {
  color: #ced4da;
}

body[data-layout-color="dark"] .footer .footer-links a:hover {
  color: #f1f1f1;
}

body[data-layout-color="dark"][data-layout-mode="boxed"] .footer {
  background-color: var(--ct-body-bg);
}

.topnav-navbar {
  z-index: 1002;
  min-height: 70px;
  margin: 0;
  padding: 0;
  position: relative;
  left: 0 !important;
}

.topnav-navbar .topnav-logo {
  float: left;
  min-width: 160px;
  padding-right: 20px;
  line-height: 70px;
}

.topnav-navbar .topnav-logo .topnav-logo-sm {
  display: none;
}

.topnav-navbar .navbar-toggle {
  cursor: pointer;
  float: left;
  background-color: #0000;
  border: none;
  margin: 27px 20px;
  padding: 0;
  position: relative;
}

.topnav-navbar .navbar-toggle .lines {
  width: 25px;
  height: 16px;
  transition: all .5s;
  display: block;
  position: relative;
}

.topnav-navbar .navbar-toggle span {
  background-color: #313a46cc;
  width: 100%;
  height: 2px;
  margin-bottom: 5px;
  transition: transform .5s;
  display: block;
}

.topnav-navbar .navbar-toggle.open span {
  position: absolute;
}

.topnav-navbar .navbar-toggle.open span:first-child {
  top: 7px;
  transform: rotate(45deg);
}

.topnav-navbar .navbar-toggle.open span:nth-child(2) {
  visibility: hidden;
}

.topnav-navbar .navbar-toggle.open span:last-child {
  width: 100%;
  top: 7px;
  transform: rotate(-45deg);
}

.topnav-navbar .app-search {
  float: left;
}

.topnav {
  background: var(--ct-bg-topnav);
}

.topnav .topnav-menu {
  margin: 0;
  padding: 0;
}

.topnav .navbar-nav .nav-link {
  padding: 1rem 1.3rem;
  font-size: .9375rem;
  position: relative;
}

.topnav .nav-item.active > a {
  color: var(--ct-menu-item-active);
}

.topnav .navbar-dark .dropdown.active > .nav-link, .topnav .navbar-dark .dropdown:active > .nav-link {
  color: #fff;
}

.arrow-down {
  display: inline-block;
}

.arrow-down:after {
  border-color: initial;
  content: "";
  transform-origin: top;
  border-style: solid;
  border-width: 0 0 1px 1px;
  width: .4em;
  height: .4em;
  margin-left: 10px;
  transition: all .3s ease-out;
  display: inline-block;
  top: 50%;
  right: 5px;
  transform: rotate(-45deg)translateY(-50%);
}

body[data-layout="topnav"] .container-fluid, body[data-layout="topnav"] .container-sm, body[data-layout="topnav"] .container-md, body[data-layout="topnav"] .container-lg, body[data-layout="topnav"] .container-xl, body[data-layout="topnav"] .container-xxl {
  padding-left: 24px;
  padding-right: 24px;
}

@media (width >= 992px) {
  body[data-layout="topnav"] .container-fluid, body[data-layout="topnav"] .container-sm, body[data-layout="topnav"] .container-md, body[data-layout="topnav"] .container-lg, body[data-layout="topnav"] .container-xl, body[data-layout="topnav"] .container-xxl {
    max-width: 95%;
  }

  body[data-layout="topnav"][data-layout-mode="boxed"] .container-fluid, body[data-layout="topnav"][data-layout-mode="boxed"] .container-sm, body[data-layout="topnav"][data-layout-mode="boxed"] .container-md, body[data-layout="topnav"][data-layout-mode="boxed"] .container-lg, body[data-layout="topnav"][data-layout-mode="boxed"] .container-xl, body[data-layout="topnav"][data-layout-mode="boxed"] .container-xxl {
    max-width: 97%;
  }

  .topnav .navbar-nav .nav-item:first-of-type .nav-link {
    padding-left: 0;
  }

  .topnav .dropdown .dropdown-menu {
    border-radius: 0 0 .25rem .25rem;
    min-width: 11.5rem;
    margin-top: 0;
    font-size: .89rem;
  }

  .topnav .dropdown .dropdown-menu .arrow-down:after {
    position: absolute;
    right: 15px;
    transform: rotate(-135deg)translateY(-50%);
  }

  .topnav .dropdown .dropdown-menu .dropdown .dropdown-menu {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
  }

  .topnav .dropdown:hover > .dropdown-menu, .topnav .dropdown:hover > .dropdown-menu > .dropdown:hover > .dropdown-menu {
    display: block;
  }

  .dropdown.active > a.dropdown-item {
    color: var(--ct-dropdown-link-active-color);
    background-color: var(--ct-dropdown-link-active-bg);
  }
}

@media (width >= 1400px) {
  body[data-layout="topnav"] .container-fluid, body[data-layout="topnav"] .container-sm, body[data-layout="topnav"] .container-md, body[data-layout="topnav"] .container-lg, body[data-layout="topnav"] .container-xl, body[data-layout="topnav"] .container-xxl {
    max-width: 85%;
  }
}

@media (width >= 992px) {
  .navbar-toggle {
    display: none;
  }
}

@media (width <= 991.98px) {
  .topnav-navbar .topnav-logo-lg {
    display: none;
  }

  .topnav-navbar .topnav-logo {
    text-align: center;
    min-width: 50px;
    padding-right: 0;
  }

  .topnav-navbar .topnav-logo-sm {
    display: block !important;
  }

  .topnav .navbar-nav .nav-link {
    padding: .75rem 1.3rem;
  }

  .topnav .dropdown .dropdown-menu {
    box-shadow: none;
    background-color: #0000;
    border: none;
    padding-left: 15px;
  }

  .topnav .dropdown .dropdown-item {
    background-color: #0000;
    position: relative;
  }

  .topnav .navbar-dark .dropdown .dropdown-item {
    color: #ffffff80;
  }

  .topnav .navbar-dark .dropdown .dropdown-item.active, .topnav .navbar-dark .dropdown .dropdown-item:active {
    color: #fff;
  }

  .topnav .arrow-down:after {
    position: absolute;
    right: 15px;
  }
}

@font-face {
  font-family: Nunito;
  src: url("Nunito-Light-X5EAUTXN-X5EAUTXN.906ca4b9.eot");
  src: local(Nunito Light), local(Nunito-Light), url("Nunito-Light-X5EAUTXN-X5EAUTXN.906ca4b9.eot#iefix") format("embedded-opentype"), url("Nunito-Light-3TZK5RZY-3TZK5RZY.d88c117f.woff") format("woff"), url("Nunito-Light-YYB7VL3Q-YYB7VL3Q.0a762398.ttf") format("truetype"), url("Nunito-Light-HBXDQIZJ-HBXDQIZJ.f97836de.svg#Roboto") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Nunito;
  src: url("Nunito-Regular-TJNIK76I-TJNIK76I.dc159d5b.eot");
  src: local(Nunito Regular), local(Nunito-Regular), url("Nunito-Regular-TJNIK76I-TJNIK76I.dc159d5b.eot#iefix") format("embedded-opentype"), url("Nunito-Regular-ESCVSZXE-ESCVSZXE.a34e1dea.woff") format("woff"), url("Nunito-Regular-PEAJXX4N-PEAJXX4N.4c8bbaa7.ttf") format("truetype"), url("Nunito-Regular-LK7D7DNK-LK7D7DNK.98460481.svg#Roboto") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Nunito;
  src: url("Nunito-SemiBold-NHQNZPGN-NHQNZPGN.a2ff68b5.eot");
  src: local(Nunito SemiBold), local(Nunito-SemiBold), url("Nunito-SemiBold-NHQNZPGN-NHQNZPGN.a2ff68b5.eot#iefix") format("embedded-opentype"), url("Nunito-SemiBold-AZB3JQBV-AZB3JQBV.12fa0786.woff") format("woff"), url("Nunito-SemiBold-CCU4ZGNF-CCU4ZGNF.78e39110.ttf") format("truetype"), url("Nunito-SemiBold-IMWILY44-IMWILY44.ddc593e2.svg#Roboto") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Nunito;
  src: url("Nunito-Bold-ZVEZUODV-ZVEZUODV.80c3347d.eot");
  src: local(Nunito Bold), local(Nunito-Bold), url("Nunito-Bold-ZVEZUODV-ZVEZUODV.80c3347d.eot#iefix") format("embedded-opentype"), url("Nunito-Bold-EPMV4BJJ-EPMV4BJJ.4e1586f5.woff") format("woff"), url("Nunito-Bold-ITCJ7FGM-ITCJ7FGM.860435f7.ttf") format("truetype"), url("Nunito-Bold-VQKOXI2M-VQKOXI2M.c6f7514e.svg#Roboto") format("svg");
  font-weight: 700;
  font-style: normal;
}

.custom-accordion .card {
  box-shadow: none;
}

.custom-accordion .card-header {
  background-color: #f1f3fa;
}

.custom-accordion .card-body {
  border: 1px solid #f1f3fa;
}

.custom-accordion .accordion-arrow {
  font-size: 1.2rem;
  position: absolute;
  right: 0;
}

.custom-accordion a.collapsed i.accordion-arrow:before {
  content: "";
}

.custom-accordion-title {
  color: var(--ct-custom-accordion-title-color);
  position: relative;
}

.custom-accordion-title:hover {
  color: #414d5d;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

body[data-layout-color="dark"] .custom-accordion .card-header {
  background-color: #404954;
}

body[data-layout-color="dark"] .custom-accordion .card-body {
  border: 1px solid #404954;
}

body[data-layout-color="dark"] .custom-accordion-title:hover {
  color: #99a4b2;
}

.alert-primary {
  color: #6c76e9;
  background-color: #727cf52e;
  border-color: #727cf540;
}

.alert-primary .alert-link {
  color: #5057ac;
}

.alert-secondary {
  color: #676f77;
  background-color: #6c757d2e;
  border-color: #6c757d40;
}

.alert-secondary .alert-link {
  color: #4c5258;
}

.alert-success {
  color: #0ac58f;
  background-color: #0acf972e;
  border-color: #0acf9740;
}

.alert-success .alert-link {
  color: #07916a;
}

.alert-info {
  color: #36a6c7;
  background-color: #39afd12e;
  border-color: #39afd140;
}

.alert-info .alert-link {
  color: #287b92;
}

.alert-warning {
  color: #f2b300;
  background-color: #ffbc002e;
  border-color: #ffbc0040;
}

.alert-warning .alert-link {
  color: #b38400;
}

.alert-danger {
  color: #ee5776;
  background-color: #fa5c7c2e;
  border-color: #fa5c7c40;
}

.alert-danger .alert-link {
  color: #af4057;
}

.alert-light {
  color: #e2e6eb;
  background-color: #eef2f72e;
  border-color: #eef2f740;
}

.alert-light .alert-link {
  color: #a7a9ad;
}

.alert-dark {
  color: #2f3743;
  background-color: #313a462e;
  border-color: #313a4640;
}

.alert-dark .alert-link {
  color: #222931;
}

.avatar-xs {
  width: 1.5rem;
  height: 1.5rem;
}

.avatar-sm {
  width: 3rem;
  height: 3rem;
}

.avatar-md {
  width: 4.5rem;
  height: 4.5rem;
}

.avatar-lg {
  width: 6rem;
  height: 6rem;
}

.avatar-xl {
  width: 7.5rem;
  height: 7.5rem;
}

.avatar-title {
  color: #fff;
  background-color: #727cf5;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-weight: 600;
  display: flex;
}

.breadcrumb-item + .breadcrumb-item:before {
  font-family: Material Design Icons;
  font-size: 16px;
  line-height: 1.3;
}

.btn .mdi:before {
  line-height: initial;
}

.btn-primary {
  box-shadow: 0 2px 6px #727cf580;
}

.btn-secondary {
  box-shadow: 0 2px 6px #6c757d80;
}

.btn-success {
  box-shadow: 0 2px 6px #0acf9780;
}

.btn-info {
  box-shadow: 0 2px 6px #39afd180;
}

.btn-warning {
  box-shadow: 0 2px 6px #ffbc0080;
}

.btn-danger {
  box-shadow: 0 2px 6px #fa5c7c80;
}

.btn-light {
  box-shadow: 0 2px 6px #eef2f780;
}

.btn-dark {
  box-shadow: 0 2px 6px #313a4680;
}

body[data-layout-color="dark"] .btn-primary {
  box-shadow: 0 2px 6px #727cf580;
}

body[data-layout-color="dark"] .btn-secondary {
  box-shadow: 0 2px 6px #ced4da80;
}

body[data-layout-color="dark"] .btn-success {
  box-shadow: 0 2px 6px #0acf9780;
}

body[data-layout-color="dark"] .btn-info {
  box-shadow: 0 2px 6px #39afd180;
}

body[data-layout-color="dark"] .btn-warning {
  box-shadow: 0 2px 6px #ffbc0080;
}

body[data-layout-color="dark"] .btn-danger {
  box-shadow: 0 2px 6px #fa5c7c80;
}

body[data-layout-color="dark"] .btn-light {
  box-shadow: 0 2px 6px #464f5b80;
}

body[data-layout-color="dark"] .btn-dark {
  box-shadow: 0 2px 6px #f1f1f180;
}

body[data-layout-color="dark"] .btn-light {
  color: #f1f1f1;
  background-color: #464f5b;
  border-color: #464f5b;
}

body[data-layout-color="dark"] .btn-dark {
  color: #404954;
  background-color: #f1f1f1;
  border-color: #f1f1f1;
}

body[data-layout-color="dark"] .btn-secondary {
  color: #464f5b;
  background-color: #ced4da;
  border-color: #ced4da;
}

body[data-layout-color="dark"] .btn-outline-light {
  color: #464f5b;
  border-color: #464f5b;
}

body[data-layout-color="dark"] .btn-outline-light:hover, body[data-layout-color="dark"] .btn-outline-light.active, body[data-layout-color="dark"] .btn-outline-light:active, body[data-layout-color="dark"] .btn-outline-light:focus {
  background-color: #464f5b;
  color: #f1f1f1 !important;
}

body[data-layout-color="dark"] .btn-outline-dark {
  color: #f1f1f1;
  border-color: #f1f1f1;
}

body[data-layout-color="dark"] .btn-outline-dark:hover, body[data-layout-color="dark"] .btn-outline-dark.active, body[data-layout-color="dark"] .btn-outline-dark:active, body[data-layout-color="dark"] .btn-outline-dark:focus {
  background-color: #f1f1f1;
  color: #404954 !important;
}

body[data-layout-color="dark"] .btn-outline-secondary {
  color: #ced4da;
  border-color: #ced4da;
}

body[data-layout-color="dark"] .btn-outline-secondary:hover, body[data-layout-color="dark"] .btn-outline-secondary.active, body[data-layout-color="dark"] .btn-outline-secondary:active, body[data-layout-color="dark"] .btn-outline-secondary:focus {
  background-color: #ced4da;
  color: #464f5b !important;
}

.badge-primary-lighten {
  color: #727cf5;
  background-color: #727cf52e;
}

.badge-primary-lighten[href] {
  color: #727cf5;
  background-color: #727cf52e;
  text-decoration: none;
}

.badge-primary-lighten[href]:hover, .badge-primary-lighten[href]:focus {
  color: #727cf5;
  background-color: #727cf566;
  text-decoration: none;
}

.badge-secondary-lighten {
  color: #6c757d;
  background-color: #6c757d2e;
}

.badge-secondary-lighten[href] {
  color: #6c757d;
  background-color: #6c757d2e;
  text-decoration: none;
}

.badge-secondary-lighten[href]:hover, .badge-secondary-lighten[href]:focus {
  color: #6c757d;
  background-color: #6c757d66;
  text-decoration: none;
}

.badge-success-lighten {
  color: #0acf97;
  background-color: #0acf972e;
}

.badge-success-lighten[href] {
  color: #0acf97;
  background-color: #0acf972e;
  text-decoration: none;
}

.badge-success-lighten[href]:hover, .badge-success-lighten[href]:focus {
  color: #0acf97;
  background-color: #0acf9766;
  text-decoration: none;
}

.badge-info-lighten {
  color: #39afd1;
  background-color: #39afd12e;
}

.badge-info-lighten[href] {
  color: #39afd1;
  background-color: #39afd12e;
  text-decoration: none;
}

.badge-info-lighten[href]:hover, .badge-info-lighten[href]:focus {
  color: #39afd1;
  background-color: #39afd166;
  text-decoration: none;
}

.badge-warning-lighten {
  color: #ffbc00;
  background-color: #ffbc002e;
}

.badge-warning-lighten[href] {
  color: #ffbc00;
  background-color: #ffbc002e;
  text-decoration: none;
}

.badge-warning-lighten[href]:hover, .badge-warning-lighten[href]:focus {
  color: #ffbc00;
  background-color: #ffbc0066;
  text-decoration: none;
}

.badge-danger-lighten {
  color: #fa5c7c;
  background-color: #fa5c7c2e;
}

.badge-danger-lighten[href] {
  color: #fa5c7c;
  background-color: #fa5c7c2e;
  text-decoration: none;
}

.badge-danger-lighten[href]:hover, .badge-danger-lighten[href]:focus {
  color: #fa5c7c;
  background-color: #fa5c7c66;
  text-decoration: none;
}

.badge-light-lighten {
  color: #eef2f7;
  background-color: #eef2f72e;
}

.badge-light-lighten[href] {
  color: #eef2f7;
  background-color: #eef2f72e;
  text-decoration: none;
}

.badge-light-lighten[href]:hover, .badge-light-lighten[href]:focus {
  color: #eef2f7;
  background-color: #eef2f766;
  text-decoration: none;
}

.badge-dark-lighten {
  color: #313a46;
  background-color: #313a462e;
}

.badge-dark-lighten[href] {
  color: #313a46;
  background-color: #313a462e;
  text-decoration: none;
}

.badge-dark-lighten[href]:hover, .badge-dark-lighten[href]:focus {
  color: #313a46;
  background-color: #313a4666;
  text-decoration: none;
}

.badge-outline-primary {
  color: #727cf5;
  background-color: #0000;
  border: 1px solid #727cf5;
}

.badge-outline-primary[href], .badge-outline-primary[href]:hover, .badge-outline-primary[href]:focus {
  color: #727cf5;
  background-color: #727cf533;
  text-decoration: none;
}

.badge-outline-secondary {
  color: #6c757d;
  background-color: #0000;
  border: 1px solid #6c757d;
}

.badge-outline-secondary[href], .badge-outline-secondary[href]:hover, .badge-outline-secondary[href]:focus {
  color: #6c757d;
  background-color: #6c757d33;
  text-decoration: none;
}

.badge-outline-success {
  color: #0acf97;
  background-color: #0000;
  border: 1px solid #0acf97;
}

.badge-outline-success[href], .badge-outline-success[href]:hover, .badge-outline-success[href]:focus {
  color: #0acf97;
  background-color: #0acf9733;
  text-decoration: none;
}

.badge-outline-info {
  color: #39afd1;
  background-color: #0000;
  border: 1px solid #39afd1;
}

.badge-outline-info[href], .badge-outline-info[href]:hover, .badge-outline-info[href]:focus {
  color: #39afd1;
  background-color: #39afd133;
  text-decoration: none;
}

.badge-outline-warning {
  color: #ffbc00;
  background-color: #0000;
  border: 1px solid #ffbc00;
}

.badge-outline-warning[href], .badge-outline-warning[href]:hover, .badge-outline-warning[href]:focus {
  color: #ffbc00;
  background-color: #ffbc0033;
  text-decoration: none;
}

.badge-outline-danger {
  color: #fa5c7c;
  background-color: #0000;
  border: 1px solid #fa5c7c;
}

.badge-outline-danger[href], .badge-outline-danger[href]:hover, .badge-outline-danger[href]:focus {
  color: #fa5c7c;
  background-color: #fa5c7c33;
  text-decoration: none;
}

.badge-outline-light {
  color: #eef2f7;
  background-color: #0000;
  border: 1px solid #eef2f7;
}

.badge-outline-light[href], .badge-outline-light[href]:hover, .badge-outline-light[href]:focus {
  color: #eef2f7;
  background-color: #eef2f733;
  text-decoration: none;
}

.badge-outline-dark {
  color: #313a46;
  background-color: #0000;
  border: 1px solid #313a46;
}

.badge-outline-dark[href], .badge-outline-dark[href]:hover, .badge-outline-dark[href]:focus {
  color: #313a46;
  background-color: #313a4633;
  text-decoration: none;
}

.badge {
  vertical-align: middle;
}

.badge.badge-lg {
  padding: .5em 1em;
  font-size: .85em;
}

body[data-layout-color="dark"] .badge-primary-lighten {
  color: #727cf5;
  background-color: #727cf52e;
}

body[data-layout-color="dark"] .badge-primary-lighten[href] {
  color: #727cf5;
  background-color: #727cf52e;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-primary-lighten[href]:hover, body[data-layout-color="dark"] .badge-primary-lighten[href]:focus {
  color: #727cf5;
  background-color: #727cf566;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-secondary-lighten {
  color: #ced4da;
  background-color: #ced4da2e;
}

body[data-layout-color="dark"] .badge-secondary-lighten[href] {
  color: #ced4da;
  background-color: #ced4da2e;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-secondary-lighten[href]:hover, body[data-layout-color="dark"] .badge-secondary-lighten[href]:focus {
  color: #ced4da;
  background-color: #ced4da66;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-success-lighten {
  color: #0acf97;
  background-color: #0acf972e;
}

body[data-layout-color="dark"] .badge-success-lighten[href] {
  color: #0acf97;
  background-color: #0acf972e;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-success-lighten[href]:hover, body[data-layout-color="dark"] .badge-success-lighten[href]:focus {
  color: #0acf97;
  background-color: #0acf9766;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-info-lighten {
  color: #39afd1;
  background-color: #39afd12e;
}

body[data-layout-color="dark"] .badge-info-lighten[href] {
  color: #39afd1;
  background-color: #39afd12e;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-info-lighten[href]:hover, body[data-layout-color="dark"] .badge-info-lighten[href]:focus {
  color: #39afd1;
  background-color: #39afd166;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-warning-lighten {
  color: #ffbc00;
  background-color: #ffbc002e;
}

body[data-layout-color="dark"] .badge-warning-lighten[href] {
  color: #ffbc00;
  background-color: #ffbc002e;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-warning-lighten[href]:hover, body[data-layout-color="dark"] .badge-warning-lighten[href]:focus {
  color: #ffbc00;
  background-color: #ffbc0066;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-danger-lighten {
  color: #fa5c7c;
  background-color: #fa5c7c2e;
}

body[data-layout-color="dark"] .badge-danger-lighten[href] {
  color: #fa5c7c;
  background-color: #fa5c7c2e;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-danger-lighten[href]:hover, body[data-layout-color="dark"] .badge-danger-lighten[href]:focus {
  color: #fa5c7c;
  background-color: #fa5c7c66;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-light-lighten {
  color: #464f5b;
  background-color: #464f5b2e;
}

body[data-layout-color="dark"] .badge-light-lighten[href] {
  color: #464f5b;
  background-color: #464f5b2e;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-light-lighten[href]:hover, body[data-layout-color="dark"] .badge-light-lighten[href]:focus {
  color: #464f5b;
  background-color: #464f5b66;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-dark-lighten {
  color: #f1f1f1;
  background-color: #f1f1f12e;
}

body[data-layout-color="dark"] .badge-dark-lighten[href] {
  color: #f1f1f1;
  background-color: #f1f1f12e;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-dark-lighten[href]:hover, body[data-layout-color="dark"] .badge-dark-lighten[href]:focus {
  color: #f1f1f1;
  background-color: #f1f1f166;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-outline-primary {
  color: #727cf5;
  background-color: #0000;
  border: 1px solid #727cf5;
}

body[data-layout-color="dark"] .badge-outline-primary[href], body[data-layout-color="dark"] .badge-outline-primary[href]:hover, body[data-layout-color="dark"] .badge-outline-primary[href]:focus {
  color: #727cf5;
  background-color: #727cf533;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-outline-secondary {
  color: #ced4da;
  background-color: #0000;
  border: 1px solid #ced4da;
}

body[data-layout-color="dark"] .badge-outline-secondary[href], body[data-layout-color="dark"] .badge-outline-secondary[href]:hover, body[data-layout-color="dark"] .badge-outline-secondary[href]:focus {
  color: #ced4da;
  background-color: #ced4da33;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-outline-success {
  color: #0acf97;
  background-color: #0000;
  border: 1px solid #0acf97;
}

body[data-layout-color="dark"] .badge-outline-success[href], body[data-layout-color="dark"] .badge-outline-success[href]:hover, body[data-layout-color="dark"] .badge-outline-success[href]:focus {
  color: #0acf97;
  background-color: #0acf9733;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-outline-info {
  color: #39afd1;
  background-color: #0000;
  border: 1px solid #39afd1;
}

body[data-layout-color="dark"] .badge-outline-info[href], body[data-layout-color="dark"] .badge-outline-info[href]:hover, body[data-layout-color="dark"] .badge-outline-info[href]:focus {
  color: #39afd1;
  background-color: #39afd133;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-outline-warning {
  color: #ffbc00;
  background-color: #0000;
  border: 1px solid #ffbc00;
}

body[data-layout-color="dark"] .badge-outline-warning[href], body[data-layout-color="dark"] .badge-outline-warning[href]:hover, body[data-layout-color="dark"] .badge-outline-warning[href]:focus {
  color: #ffbc00;
  background-color: #ffbc0033;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-outline-danger {
  color: #fa5c7c;
  background-color: #0000;
  border: 1px solid #fa5c7c;
}

body[data-layout-color="dark"] .badge-outline-danger[href], body[data-layout-color="dark"] .badge-outline-danger[href]:hover, body[data-layout-color="dark"] .badge-outline-danger[href]:focus {
  color: #fa5c7c;
  background-color: #fa5c7c33;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-outline-light {
  color: #464f5b;
  background-color: #0000;
  border: 1px solid #464f5b;
}

body[data-layout-color="dark"] .badge-outline-light[href], body[data-layout-color="dark"] .badge-outline-light[href]:hover, body[data-layout-color="dark"] .badge-outline-light[href]:focus {
  color: #464f5b;
  background-color: #464f5b33;
  text-decoration: none;
}

body[data-layout-color="dark"] .badge-outline-dark {
  color: #f1f1f1;
  background-color: #0000;
  border: 1px solid #f1f1f1;
}

body[data-layout-color="dark"] .badge-outline-dark[href], body[data-layout-color="dark"] .badge-outline-dark[href]:hover, body[data-layout-color="dark"] .badge-outline-dark[href]:focus {
  color: #f1f1f1;
  background-color: #f1f1f133;
  text-decoration: none;
}

.card {
  box-shadow: var(--ct-card-box-shadow);
  border: none;
  margin-bottom: 24px;
}

.card .header-title {
  text-transform: uppercase;
  letter-spacing: .02em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: .9rem;
}

.card .card-drop {
  color: inherit;
  font-size: 20px;
}

.card .card-widgets {
  float: right;
  height: 16px;
}

.card .card-widgets > a {
  color: inherit;
  font-size: 18px;
  line-height: 1;
  display: inline-block;
}

.card .card-widgets > a.collapsed i:before {
  content: "󰐕";
}

.card-title, .card-header {
  margin-top: 0;
}

.card-disabled {
  cursor: progress;
  background: #f1f3facc;
  border-radius: .25rem;
  position: absolute;
  inset: 0;
}

.card-disabled .card-portlets-loader {
  background-color: #313a46;
  width: 30px;
  height: 30px;
  margin-top: -12px;
  margin-left: -12px;
  animation: 1.2s ease-in-out infinite rotatebox;
  position: absolute;
  top: 50%;
  left: 50%;
}

@keyframes rotatebox {
  0% {
    transform: perspective(120px)rotateX(0)rotateY(0);
  }

  50% {
    transform: perspective(120px)rotateX(-180.1deg)rotateY(0);
  }

  to {
    transform: perspective(120px)rotateX(-180deg)rotateY(-179.9deg);
  }
}

.card-pricing {
  position: relative;
}

.card-pricing .card-pricing-plan-name {
  padding-bottom: 20px;
}

.card-pricing .card-pricing-icon {
  box-shadow: var(--ct-box-shadow);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 22px;
  line-height: 56px;
  display: inline-block;
}

.card-pricing .card-pricing-price {
  padding: 30px 0 0;
}

.card-pricing .card-pricing-price span {
  color: #98a6ad;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 40%;
}

.card-pricing .card-pricing-features {
  color: #98a6ad;
  margin: 0;
  padding: 20px 0 0;
  list-style: none;
}

.card-pricing .card-pricing-features li {
  padding: 15px;
}

@media (width >= 992px) {
  .card-pricing-recommended {
    margin-top: -1.9375rem;
  }
}

.card-pricing-recommended .card-pricing-plan-tag {
  color: #fa5c7c;
  background-color: #fa5c7c33;
  border-radius: .25rem .25rem 0 0;
  margin: -1.5rem -1.5rem 1.5rem;
  padding: 5px 0;
  font-weight: 700;
}

.card-h-100 {
  height: calc(100% - 24px);
}

body[data-layout-color="dark"] .card-pricing .card-pricing-price span, body[data-layout-color="dark"] .card-pricing .card-pricing-features {
  color: #ced4da;
}

body[data-layout-color="dark"] .card-disabled {
  background: #404954cc;
}

body[data-layout-color="dark"] .card-disabled .card-portlets-loader {
  background-color: #f1f1f1;
}

.form-checkbox-primary .form-check-input:checked, .form-radio-primary .form-check-input:checked {
  background-color: #727cf5;
  border-color: #727cf5;
}

.form-checkbox-secondary .form-check-input:checked, .form-radio-secondary .form-check-input:checked {
  background-color: #6c757d;
  border-color: #6c757d;
}

.form-checkbox-success .form-check-input:checked, .form-radio-success .form-check-input:checked {
  background-color: #0acf97;
  border-color: #0acf97;
}

.form-checkbox-info .form-check-input:checked, .form-radio-info .form-check-input:checked {
  background-color: #39afd1;
  border-color: #39afd1;
}

.form-checkbox-warning .form-check-input:checked, .form-radio-warning .form-check-input:checked {
  background-color: #ffbc00;
  border-color: #ffbc00;
}

.form-checkbox-danger .form-check-input:checked, .form-radio-danger .form-check-input:checked {
  background-color: #fa5c7c;
  border-color: #fa5c7c;
}

.form-checkbox-light .form-check-input:checked, .form-radio-light .form-check-input:checked {
  background-color: #eef2f7;
  border-color: #eef2f7;
}

.form-checkbox-dark .form-check-input:checked, .form-radio-dark .form-check-input:checked {
  background-color: #313a46;
  border-color: #313a46;
}

.dropdown-menu {
  box-shadow: var(--ct-box-shadow);
}

.dropdown-menu-animated.dropdown-menu-end[style] {
  left: auto;
  right: 0;
}

.dropdown-menu-animated {
  z-index: 1000;
  margin: 0;
  animation-name: DropDownSlide;
  animation-duration: .3s;
  animation-fill-mode: both;
  position: absolute;
}

.dropdown-menu-animated.show {
  top: 100% !important;
}

.dropdown-menu-animated i {
  display: inline-block;
}

.dropdown-menu-animated.dropdown-menu[data-popper-placement^="right"], .dropdown-menu-animated.dropdown-menu[data-popper-placement^="top"], .dropdown-menu-animated.dropdown-menu[data-popper-placement^="left"] {
  animation: none !important;
  top: auto !important;
}

@keyframes DropDownSlide {
  to {
    transform: translateY(0);
  }

  0% {
    transform: translateY(10px);
  }
}

@media (width >= 576px) {
  .dropdown-lg {
    width: 320px;
  }
}

.dropdown-icon-item {
  text-align: center;
  color: var(--ct-dropdown-link-color);
  border: 1px solid #0000;
  border-radius: 3px;
  padding: 15px 0 9px;
  line-height: 34px;
  display: block;
}

.dropdown-icon-item img {
  height: 24px;
}

.dropdown-icon-item span {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.dropdown-icon-item:hover {
  background-color: var(--ct-dropdown-link-hover-bg);
  color: var(--ct-dropdown-link-hover-color);
}

.arrow-none:after {
  display: none !important;
}

.hljs {
  color: #313a46;
  border: 1px solid #98a6ad33;
  max-height: 420px;
  margin: -10px 0 -30px;
  padding: 2em;
  display: block;
  overflow-x: auto;
}

.hljs-keyword, .hljs-selector-tag, .hljs-subst {
  color: #313a46;
  font-weight: 700;
}

.hljs-number, .hljs-literal, .hljs-variable, .hljs-template-variable, .hljs-tag .hljs-attr {
  color: #02a8b5;
}

.hljs-string, .hljs-doctag {
  color: #fa5c7c;
}

.hljs-title, .hljs-section, .hljs-selector-id {
  color: #fa5c7c;
  font-weight: 700;
}

.hljs-subst {
  font-weight: 400;
}

.hljs-tag, .hljs-name, .hljs-attribute {
  color: #0768d1;
  font-weight: 400;
}

.hljs-regexp, .hljs-link {
  color: #02a8b5;
}

.hljs-symbol, .hljs-bullet {
  color: #ff679b;
}

.hljs-built_in, .hljs-builtin-name {
  color: #39afd1;
}

.hljs-meta {
  color: #343a40;
  font-weight: 700;
}

.hljs-deletion {
  background: #fdd;
}

.hljs-addition {
  background: #dfd;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: 700;
}

.hljs-comment {
  color: #ced4da;
}

body[data-layout-color="dark"] .hljs {
  border: 1px solid #ced4da33;
}

body[data-layout-color="dark"] .hljs, body[data-layout-color="dark"] .hljs-keyword, body[data-layout-color="dark"] .hljs-selector-tag, body[data-layout-color="dark"] .hljs-subst {
  color: #f1f1f1;
}

body[data-layout-color="dark"] .hljs-meta {
  color: #e3eaef;
}

body[data-layout-color="dark"] .hljs-comment {
  color: #8391a2;
}

.form-control-light {
  background-color: #f1f3fa !important;
  border-color: #f1f3fa !important;
}

input.form-control[type="color"], input.form-control[type="range"] {
  min-height: 39px;
}

.custom-select.is-invalid:focus, .form-control.is-invalid:focus, .custom-select:invalid:focus, .form-control:invalid:focus, .custom-select.is-valid:focus, .form-control.is-valid:focus, .custom-select:valid:focus, .form-control:valid:focus {
  box-shadow: none !important;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(1.5em + .9rem + 2px);
}

select.form-control-sm:not([size]):not([multiple]) {
  height: calc(1.5em + .56rem + 2px);
}

.password-eye:before {
  content: "󰛐";
  font-variant: normal;
  vertical-align: middle;
  font-family: Material Design Icons;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
}

.show-password .password-eye:before {
  content: "󰛑";
}

body[data-layout-color="dark"] .form-control-light {
  background-color: #404954 !important;
  border-color: #404954 !important;
}

.modal-title {
  margin-top: 0;
}

.modal-full-width {
  width: 95%;
  max-width: none;
}

.modal-top {
  margin: 0 auto;
}

.modal-right {
  background-color: var(--ct-modal-content-bg);
  flex-flow: column;
  place-content: center;
  height: 100%;
  margin: 0;
  display: flex;
  position: absolute;
  right: 0;
  transform: translate(25%) !important;
}

.modal-right button.btn-close {
  z-index: 1;
  position: fixed;
  top: 20px;
  right: 20px;
}

.modal.show .modal-right, .modal.show .modal-left {
  transform: translate(0) !important;
}

.modal-bottom {
  flex-flow: column;
  place-content: center flex-end;
  height: 100%;
  margin: 0 auto;
  display: flex;
}

.modal-colored-header {
  color: #fff;
  border-radius: 0;
}

.modal-colored-header .btn-close {
  color: #fff !important;
}

.modal-filled {
  color: #fff;
}

.modal-filled .modal-header {
  background-color: #ffffff12;
}

.modal-filled .modal-header, .modal-filled .modal-footer {
  border: none;
}

.modal-filled .btn-close {
  color: #fff !important;
}

.modal-backdrop-nested {
  z-index: 1060 !important;
}

.modal-nested {
  z-index: 1065 !important;
}

.nav-tabs > li > a, .nav-pills > li > a, .nav-pills > a {
  color: #6c757d;
  font-weight: 600;
}

.bg-nav-pills {
  background-color: var(--ct-nav-pills-bg);
}

.nav-tabs.nav-bordered {
  border-bottom: 2px solid #98a6ad33;
}

.nav-tabs.nav-bordered .nav-item {
  margin-bottom: -1px;
}

.nav-tabs.nav-bordered li a {
  border: 0;
  padding: .625rem 1.25rem;
}

.nav-tabs.nav-bordered li a.active {
  border-bottom: 2px solid #727cf5;
}

body[data-layout-color="dark"] .nav-tabs > li > a, body[data-layout-color="dark"] .nav-pills > li > a, body[data-layout-color="dark"] .nav-pills > a {
  color: #dee2e6;
}

body[data-layout-color="dark"] .nav-tabs.nav-bordered {
  border-bottom: 2px solid #ced4da33;
}

.pagination-rounded .page-link {
  border: none;
  border-radius: 30px !important;
  margin: 0 3px !important;
}

.popover-header {
  margin-top: 0;
}

@media print {
  .leftside-menu, .end-bar, .page-title-box, .navbar-custom, .footer {
    display: none;
  }

  .card-body, .content-page, .end-bar, .content, body {
    margin: 0;
    padding: 0;
  }
}

.progress-sm {
  height: 5px;
}

.progress-md {
  height: 8px;
}

.progress-lg {
  height: 12px;
}

.progress-xl {
  height: 15px;
}

body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

body.loading {
  visibility: hidden;
}

button, a {
  outline: none !important;
}

label {
  font-weight: 600;
}

address.address-lg {
  line-height: 24px;
}

b, strong {
  font-weight: 700;
}

.ribbon-box {
  position: relative;
}

.ribbon-box .ribbon {
  clear: both;
  color: #fff;
  margin-bottom: 15px;
  padding: 5px 12px;
  font-size: 13px;
  font-weight: 600;
  position: relative;
  box-shadow: 2px 5px 10px #313a4626;
}

.ribbon-box .ribbon:before {
  content: " ";
  z-index: -1;
  border-style: solid;
  border-width: 10px;
  margin-bottom: -10px;
  display: block;
  position: absolute;
  bottom: -10px;
  left: 0;
}

.ribbon-box .ribbon.float-start {
  border-radius: 0 3px 3px 0;
  margin-left: -30px;
}

.ribbon-box .ribbon.float-end {
  border-radius: 3px 0 0 3px;
  margin-right: -30px;
}

.ribbon-box .ribbon.float-end:before {
  right: 0;
}

.ribbon-box .ribbon.float-center span {
  margin: 0 auto 20px;
}

.ribbon-box .ribbon-content {
  clear: both;
}

.ribbon-box .ribbon-primary {
  background: #727cf5;
}

.ribbon-box .ribbon-primary:before {
  border-color: #5a66f3 #0000 #0000;
}

.ribbon-box .ribbon-secondary {
  background: #6c757d;
}

.ribbon-box .ribbon-secondary:before {
  border-color: #60686f #0000 #0000;
}

.ribbon-box .ribbon-success {
  background: #0acf97;
}

.ribbon-box .ribbon-success:before {
  border-color: #09b785 #0000 #0000;
}

.ribbon-box .ribbon-info {
  background: #39afd1;
}

.ribbon-box .ribbon-info:before {
  border-color: #2da2c3 #0000 #0000;
}

.ribbon-box .ribbon-warning {
  background: #ffbc00;
}

.ribbon-box .ribbon-warning:before {
  border-color: #e6a900 #0000 #0000;
}

.ribbon-box .ribbon-danger {
  background: #fa5c7c;
}

.ribbon-box .ribbon-danger:before {
  border-color: #f94368 #0000 #0000;
}

.ribbon-box .ribbon-light {
  background: #eef2f7;
}

.ribbon-box .ribbon-light:before {
  border-color: #dde5ef #0000 #0000;
}

.ribbon-box .ribbon-dark {
  background: #313a46;
}

.ribbon-box .ribbon-dark:before {
  border-color: #272e37 #0000 #0000;
}

.ribbon-box .ribbon-two {
  z-index: 1;
  text-align: right;
  width: 75px;
  height: 75px;
  position: absolute;
  top: -5px;
  left: -5px;
  overflow: hidden;
}

.ribbon-box .ribbon-two span {
  color: #fff;
  text-align: center;
  width: 100px;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  display: block;
  position: absolute;
  top: 19px;
  left: -21px;
  transform: rotate(-45deg);
  box-shadow: 0 0 8px #313a4614, 0 1px #313a4608;
}

.ribbon-box .ribbon-two span:before {
  content: "";
  z-index: -1;
  border-bottom: 3px solid #0000;
  border-right: 3px solid #0000;
  position: absolute;
  top: 100%;
  left: 0;
}

.ribbon-box .ribbon-two span:after {
  content: "";
  z-index: -1;
  border-bottom: 3px solid #0000;
  border-left: 3px solid #0000;
  position: absolute;
  top: 100%;
  right: 0;
}

.ribbon-box .ribbon-two-primary span {
  background: #727cf5;
}

.ribbon-box .ribbon-two-primary span:before {
  border-top: 3px solid #5a66f3;
  border-left: 3px solid #5a66f3;
}

.ribbon-box .ribbon-two-primary span:after {
  border-top: 3px solid #5a66f3;
  border-right: 3px solid #5a66f3;
}

.ribbon-box .ribbon-two-secondary span {
  background: #6c757d;
}

.ribbon-box .ribbon-two-secondary span:before {
  border-top: 3px solid #60686f;
  border-left: 3px solid #60686f;
}

.ribbon-box .ribbon-two-secondary span:after {
  border-top: 3px solid #60686f;
  border-right: 3px solid #60686f;
}

.ribbon-box .ribbon-two-success span {
  background: #0acf97;
}

.ribbon-box .ribbon-two-success span:before {
  border-top: 3px solid #09b785;
  border-left: 3px solid #09b785;
}

.ribbon-box .ribbon-two-success span:after {
  border-top: 3px solid #09b785;
  border-right: 3px solid #09b785;
}

.ribbon-box .ribbon-two-info span {
  background: #39afd1;
}

.ribbon-box .ribbon-two-info span:before {
  border-top: 3px solid #2da2c3;
  border-left: 3px solid #2da2c3;
}

.ribbon-box .ribbon-two-info span:after {
  border-top: 3px solid #2da2c3;
  border-right: 3px solid #2da2c3;
}

.ribbon-box .ribbon-two-warning span {
  background: #ffbc00;
}

.ribbon-box .ribbon-two-warning span:before {
  border-top: 3px solid #e6a900;
  border-left: 3px solid #e6a900;
}

.ribbon-box .ribbon-two-warning span:after {
  border-top: 3px solid #e6a900;
  border-right: 3px solid #e6a900;
}

.ribbon-box .ribbon-two-danger span {
  background: #fa5c7c;
}

.ribbon-box .ribbon-two-danger span:before {
  border-top: 3px solid #f94368;
  border-left: 3px solid #f94368;
}

.ribbon-box .ribbon-two-danger span:after {
  border-top: 3px solid #f94368;
  border-right: 3px solid #f94368;
}

.ribbon-box .ribbon-two-light span {
  background: #eef2f7;
}

.ribbon-box .ribbon-two-light span:before {
  border-top: 3px solid #dde5ef;
  border-left: 3px solid #dde5ef;
}

.ribbon-box .ribbon-two-light span:after {
  border-top: 3px solid #dde5ef;
  border-right: 3px solid #dde5ef;
}

.ribbon-box .ribbon-two-dark span {
  background: #313a46;
}

.ribbon-box .ribbon-two-dark span:before {
  border-top: 3px solid #272e37;
  border-left: 3px solid #272e37;
}

.ribbon-box .ribbon-two-dark span:after {
  border-top: 3px solid #272e37;
  border-right: 3px solid #272e37;
}

body[data-layout-color="dark"] .ribbon-primary {
  background: #727cf5;
}

body[data-layout-color="dark"] .ribbon-primary:before {
  border-color: #5a66f3 #0000 #0000;
}

body[data-layout-color="dark"] .ribbon-secondary {
  background: #ced4da;
}

body[data-layout-color="dark"] .ribbon-secondary:before {
  border-color: #bfc7cf #0000 #0000;
}

body[data-layout-color="dark"] .ribbon-success {
  background: #0acf97;
}

body[data-layout-color="dark"] .ribbon-success:before {
  border-color: #09b785 #0000 #0000;
}

body[data-layout-color="dark"] .ribbon-info {
  background: #39afd1;
}

body[data-layout-color="dark"] .ribbon-info:before {
  border-color: #2da2c3 #0000 #0000;
}

body[data-layout-color="dark"] .ribbon-warning {
  background: #ffbc00;
}

body[data-layout-color="dark"] .ribbon-warning:before {
  border-color: #e6a900 #0000 #0000;
}

body[data-layout-color="dark"] .ribbon-danger {
  background: #fa5c7c;
}

body[data-layout-color="dark"] .ribbon-danger:before {
  border-color: #f94368 #0000 #0000;
}

body[data-layout-color="dark"] .ribbon-light {
  background: #464f5b;
}

body[data-layout-color="dark"] .ribbon-light:before {
  border-color: #3b424d #0000 #0000;
}

body[data-layout-color="dark"] .ribbon-dark {
  background: #f1f1f1;
}

body[data-layout-color="dark"] .ribbon-dark:before {
  border-color: #e4e4e4 #0000 #0000;
}

body[data-layout-color="dark"] .ribbon-two-primary span {
  background: #727cf5;
}

body[data-layout-color="dark"] .ribbon-two-primary span:before {
  border-top: 3px solid #5a66f3;
  border-left: 3px solid #5a66f3;
}

body[data-layout-color="dark"] .ribbon-two-primary span:after {
  border-top: 3px solid #5a66f3;
  border-right: 3px solid #5a66f3;
}

body[data-layout-color="dark"] .ribbon-two-secondary span {
  background: #ced4da;
}

body[data-layout-color="dark"] .ribbon-two-secondary span:before {
  border-top: 3px solid #bfc7cf;
  border-left: 3px solid #bfc7cf;
}

body[data-layout-color="dark"] .ribbon-two-secondary span:after {
  border-top: 3px solid #bfc7cf;
  border-right: 3px solid #bfc7cf;
}

body[data-layout-color="dark"] .ribbon-two-success span {
  background: #0acf97;
}

body[data-layout-color="dark"] .ribbon-two-success span:before {
  border-top: 3px solid #09b785;
  border-left: 3px solid #09b785;
}

body[data-layout-color="dark"] .ribbon-two-success span:after {
  border-top: 3px solid #09b785;
  border-right: 3px solid #09b785;
}

body[data-layout-color="dark"] .ribbon-two-info span {
  background: #39afd1;
}

body[data-layout-color="dark"] .ribbon-two-info span:before {
  border-top: 3px solid #2da2c3;
  border-left: 3px solid #2da2c3;
}

body[data-layout-color="dark"] .ribbon-two-info span:after {
  border-top: 3px solid #2da2c3;
  border-right: 3px solid #2da2c3;
}

body[data-layout-color="dark"] .ribbon-two-warning span {
  background: #ffbc00;
}

body[data-layout-color="dark"] .ribbon-two-warning span:before {
  border-top: 3px solid #e6a900;
  border-left: 3px solid #e6a900;
}

body[data-layout-color="dark"] .ribbon-two-warning span:after {
  border-top: 3px solid #e6a900;
  border-right: 3px solid #e6a900;
}

body[data-layout-color="dark"] .ribbon-two-danger span {
  background: #fa5c7c;
}

body[data-layout-color="dark"] .ribbon-two-danger span:before {
  border-top: 3px solid #f94368;
  border-left: 3px solid #f94368;
}

body[data-layout-color="dark"] .ribbon-two-danger span:after {
  border-top: 3px solid #f94368;
  border-right: 3px solid #f94368;
}

body[data-layout-color="dark"] .ribbon-two-light span {
  background: #464f5b;
}

body[data-layout-color="dark"] .ribbon-two-light span:before {
  border-top: 3px solid #3b424d;
  border-left: 3px solid #3b424d;
}

body[data-layout-color="dark"] .ribbon-two-light span:after {
  border-top: 3px solid #3b424d;
  border-right: 3px solid #3b424d;
}

body[data-layout-color="dark"] .ribbon-two-dark span {
  background: #f1f1f1;
}

body[data-layout-color="dark"] .ribbon-two-dark span:before {
  border-top: 3px solid #e4e4e4;
  border-left: 3px solid #e4e4e4;
}

body[data-layout-color="dark"] .ribbon-two-dark span:after {
  border-top: 3px solid #e4e4e4;
  border-right: 3px solid #e4e4e4;
}

input[data-switch] {
  display: none;
}

input[data-switch] + label {
  cursor: pointer;
  text-align: center;
  background-color: #f1f3fa;
  background-image: none;
  border-radius: 2rem;
  width: 56px;
  height: 24px;
  transition: all .1s ease-in-out;
  display: inline-block;
  position: relative;
}

input[data-switch] + label:before {
  color: #313a46;
  content: attr(data-off-label);
  text-align: center;
  min-width: 1.66667rem;
  margin: 0 .21667rem;
  font-family: inherit;
  font-size: .75rem;
  font-weight: 600;
  line-height: 24px;
  transition: all .1s ease-in-out;
  display: block;
  position: absolute;
  top: 0;
  right: 3px;
  overflow: hidden;
}

input[data-switch] + label:after {
  content: "";
  box-shadow: none;
  background-color: #adb5bd;
  border-radius: 2rem;
  width: 18px;
  height: 18px;
  transition: all .1s ease-in-out;
  position: absolute;
  top: 3px;
  left: 4px;
}

input[data-switch]:checked + label {
  background-color: #727cf5;
}

input[data-switch]:checked + label:before {
  color: #fff;
  content: attr(data-on-label);
  left: 4px;
  right: auto;
}

input[data-switch]:checked + label:after {
  background-color: #f1f3fa;
  left: 34px;
}

input[data-switch="bool"] + label {
  background-color: #fa5c7c;
}

input[data-switch="layout"] + label {
  background-color: #ffbc00;
}

input[data-switch="layout"] + label:after {
  background-color: #f1f3fa;
}

input:disabled + label {
  opacity: .5;
  cursor: default;
}

input[data-switch="bool"] + label:before, input[data-switch="bool"]:checked + label:before {
  color: #fff !important;
}

input[data-switch="bool"] + label:after {
  background-color: #f1f3fa;
}

input[data-switch="primary"]:checked + label {
  background-color: #727cf5;
}

input[data-switch="secondary"]:checked + label {
  background-color: #6c757d;
}

input[data-switch="success"]:checked + label {
  background-color: #0acf97;
}

input[data-switch="info"]:checked + label {
  background-color: #39afd1;
}

input[data-switch="warning"]:checked + label {
  background-color: #ffbc00;
}

input[data-switch="danger"]:checked + label {
  background-color: #fa5c7c;
}

input[data-switch="light"]:checked + label {
  background-color: #eef2f7;
}

input[data-switch="dark"]:checked + label {
  background-color: #313a46;
}

body[data-layout-color="dark"] input[data-switch][data-switch="none"] + label {
  background-color: #404954;
}

body[data-layout-color="dark"] input[data-switch] + label:before {
  color: #f1f1f1;
}

body[data-layout-color="dark"] input[data-switch] + label:after {
  background-color: #aab8c5;
}

body[data-layout-color="dark"] input[data-switch][data-switch="layout"] + label:after, body[data-layout-color="dark"] input[data-switch="bool"] + label:after {
  background-color: #404954;
}

body[data-layout-color="dark"] input[data-switch="primary"]:checked + label {
  background-color: #727cf5;
}

body[data-layout-color="dark"] input[data-switch="secondary"]:checked + label {
  background-color: #ced4da;
}

body[data-layout-color="dark"] input[data-switch="success"]:checked + label {
  background-color: #0acf97;
}

body[data-layout-color="dark"] input[data-switch="info"]:checked + label {
  background-color: #39afd1;
}

body[data-layout-color="dark"] input[data-switch="warning"]:checked + label {
  background-color: #ffbc00;
}

body[data-layout-color="dark"] input[data-switch="danger"]:checked + label {
  background-color: #fa5c7c;
}

body[data-layout-color="dark"] input[data-switch="light"]:checked + label {
  background-color: #464f5b;
}

body[data-layout-color="dark"] input[data-switch="dark"]:checked + label {
  background-color: #f1f1f1;
}

.table-centered th, .table-centered td {
  vertical-align: middle !important;
}

.table .table-user img {
  width: 30px;
  height: 30px;
}

.table .action-icon {
  color: #98a6ad;
  padding: 0 3px;
  font-size: 1.2rem;
  display: inline-block;
}

.table .action-icon:hover {
  color: #6c757d;
}

.table > :not(caption) > * > * {
  background-color: var(--ct-table-bg) !important;
}

.table.table-bordered tbody {
  border-top: 1px solid;
  border-top-color: inherit;
}

.table-nowrap th, .table-nowrap td {
  white-space: nowrap;
}

table.table-hover thead tr:hover > *, table.table-hover tbody tr:hover > *, table tr.table-active, table.table-striped tbody tr:nth-of-type(odd) > * {
  --ct-table-accent-bg: #f1f3fa;
}

body[data-layout-color="dark"] table .action-icon {
  color: #ced4da;
}

body[data-layout-color="dark"] table .action-icon:hover {
  color: #dee2e6;
}

body[data-layout-color="dark"] table .table-light {
  --ct-table-bg: #464f5b;
  color: #fff;
  border-color: var(--ct-table-group-separator-color);
}

body[data-layout-color="dark"] table .table-dark {
  --ct-table-bg: #424e5a;
}

body[data-layout-color="dark"] table.table-hover thead tr:hover > *, body[data-layout-color="dark"] table.table-hover tbody tr:hover > *, body[data-layout-color="dark"] table tr.table-active, body[data-layout-color="dark"] table.table-striped tbody tr:nth-of-type(odd) > * {
  --ct-table-accent-bg: #404954cc;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin: 10px 0;
  font-weight: 700;
}

.font-10 {
  font-size: 10px !important;
}

.font-11 {
  font-size: 11px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-24 {
  font-size: 24px !important;
}

.bg-primary-lighten {
  background-color: #727cf540 !important;
}

.bg-secondary-lighten {
  background-color: #6c757d40 !important;
}

.bg-success-lighten {
  background-color: #0acf9740 !important;
}

.bg-info-lighten {
  background-color: #39afd140 !important;
}

.bg-warning-lighten {
  background-color: #ffbc0040 !important;
}

.bg-danger-lighten {
  background-color: #fa5c7c40 !important;
}

.bg-light-lighten {
  background-color: #eef2f740 !important;
}

.bg-dark-lighten {
  background-color: #313a4640 !important;
}

body[data-layout-color="dark"] .bg-primary {
  background-color: #727cf5 !important;
}

body[data-layout-color="dark"] .bg-primary-lighten {
  background-color: #727cf540 !important;
}

body[data-layout-color="dark"] .bg-secondary {
  background-color: #ced4da !important;
}

body[data-layout-color="dark"] .bg-secondary-lighten {
  background-color: #ced4da40 !important;
}

body[data-layout-color="dark"] .bg-success {
  background-color: #0acf97 !important;
}

body[data-layout-color="dark"] .bg-success-lighten {
  background-color: #0acf9740 !important;
}

body[data-layout-color="dark"] .bg-info {
  background-color: #39afd1 !important;
}

body[data-layout-color="dark"] .bg-info-lighten {
  background-color: #39afd140 !important;
}

body[data-layout-color="dark"] .bg-warning {
  background-color: #ffbc00 !important;
}

body[data-layout-color="dark"] .bg-warning-lighten {
  background-color: #ffbc0040 !important;
}

body[data-layout-color="dark"] .bg-danger {
  background-color: #fa5c7c !important;
}

body[data-layout-color="dark"] .bg-danger-lighten {
  background-color: #fa5c7c40 !important;
}

body[data-layout-color="dark"] .bg-light {
  background-color: #464f5b !important;
}

body[data-layout-color="dark"] .bg-light-lighten {
  background-color: #464f5b40 !important;
}

body[data-layout-color="dark"] .bg-dark {
  background-color: #f1f1f1 !important;
}

body[data-layout-color="dark"] .bg-dark-lighten {
  background-color: #f1f1f140 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.text-body {
  color: #6c757d !important;
}

body[data-layout-color="dark"] .text-primary {
  color: #727cf5 !important;
}

body[data-layout-color="dark"] .text-secondary {
  color: #ced4da !important;
}

body[data-layout-color="dark"] .text-success {
  color: #0acf97 !important;
}

body[data-layout-color="dark"] .text-info {
  color: #39afd1 !important;
}

body[data-layout-color="dark"] .text-warning {
  color: #ffbc00 !important;
}

body[data-layout-color="dark"] .text-danger {
  color: #fa5c7c !important;
}

body[data-layout-color="dark"] .text-light {
  color: #464f5b !important;
}

body[data-layout-color="dark"] .text-dark {
  color: #f1f1f1 !important;
}

body[data-layout-color="dark"] .text-body {
  color: #aab8c5 !important;
}

.border-dashed {
  border-style: dashed !important;
}

body[data-layout-color="dark"] .border-primary {
  border-color: #727cf5 !important;
}

body[data-layout-color="dark"] .border-secondary {
  border-color: #ced4da !important;
}

body[data-layout-color="dark"] .border-success {
  border-color: #0acf97 !important;
}

body[data-layout-color="dark"] .border-info {
  border-color: #39afd1 !important;
}

body[data-layout-color="dark"] .border-warning {
  border-color: #ffbc00 !important;
}

body[data-layout-color="dark"] .border-danger {
  border-color: #fa5c7c !important;
}

body[data-layout-color="dark"] .border-light {
  border-color: #464f5b !important;
}

body[data-layout-color="dark"] .border-dark {
  border-color: #f1f1f1 !important;
}

.progress-w-percent {
  min-height: 20px;
  margin-bottom: 20px;
}

.progress-w-percent .progress {
  float: left;
  width: calc(100% - 50px);
  margin-top: 8px;
}

.progress-w-percent .progress-value {
  float: right;
  text-align: right;
  width: 40px;
  line-height: 20px;
}

.widget-flat {
  position: relative;
  overflow: hidden;
}

@media (width >= 1200px) and (width <= 1500px) {
  .widget-flat i.widget-icon {
    display: none;
  }
}

.widget-icon {
  color: #727cf5;
  text-align: center;
  background-color: #727cf540;
  border-radius: 3px;
  width: 40px;
  height: 40px;
  font-size: 20px;
  line-height: 40px;
  display: inline-block;
}

.dash-item-overlay {
  text-align: left;
  z-index: 1;
  max-width: 350px;
  padding: 20px;
  position: absolute;
  left: 8%;
}

.chart-content-bg {
  background-color: #f9f9fd;
}

.chart-content-border {
  border: 1px solid #eef2f7;
}

.chart-widget-list p {
  border-bottom: 1px solid #f1f3fa;
  margin-bottom: .5rem;
  padding-bottom: .5rem;
}

.timeline-alt {
  padding: 20px 0;
  position: relative;
}

.timeline-alt .timeline-item {
  position: relative;
}

.timeline-alt .timeline-item:before {
  content: "";
  z-index: 0;
  background-color: #f1f3fa;
  width: 2px;
  position: absolute;
  top: 20px;
  bottom: 0;
  left: 9px;
}

.timeline-alt .timeline-item .timeline-icon {
  float: left;
  text-align: center;
  background-color: #fff;
  border: 2px solid #0000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 16px;
}

.timeline-alt .timeline-item .timeline-item-info {
  margin-left: 30px;
}

.inbox-widget .inbox-item {
  border-bottom: 1px solid #fff;
  padding: .625rem 0;
  position: relative;
  overflow: hidden;
}

.inbox-widget .inbox-item:last-of-type {
  border-bottom: none;
}

.inbox-widget .inbox-item .inbox-item-img {
  float: left;
  width: 40px;
  margin-right: 15px;
  display: block;
}

.inbox-widget .inbox-item .inbox-item-img img {
  width: 40px;
}

.inbox-widget .inbox-item .inbox-item-author {
  color: #343a40;
  margin-bottom: 3px;
  display: block;
}

.inbox-widget .inbox-item .inbox-item-text {
  color: #adb5bd;
  margin: 0;
  font-size: .8125rem;
  display: block;
}

.inbox-widget .inbox-item .inbox-item-date {
  color: #98a6ad;
  font-size: .6875rem;
  position: absolute;
  top: 10px;
  right: 5px;
}

.tilebox-one .tilebox-icon {
  opacity: .3;
  font-size: 2rem;
  position: absolute;
  right: 1.5rem;
}

.toll-free-box i {
  opacity: .4;
  font-size: 4rem;
  position: absolute;
  bottom: -15px;
  left: 0;
  transform: rotate(30deg);
}

.cta-box {
  background-image: url("bg-pattern-3CU3NKA2-3CU3NKA2.088fedfa.png");
  background-size: cover;
}

.cta-box .cta-box-title {
  font-size: 20px;
  line-height: 30px;
}

.conversation-list {
  padding: 0 15px;
  list-style: none;
}

.conversation-list li {
  margin-bottom: 20px;
}

.conversation-list li .conversation-actions {
  float: right;
  display: none;
}

.conversation-list li:hover .conversation-actions {
  display: block;
}

.conversation-list .chat-avatar {
  float: left;
  text-align: center;
  width: 42px;
}

.conversation-list .chat-avatar img {
  border-radius: 100%;
  width: 100%;
}

.conversation-list .chat-avatar i {
  font-size: 12px;
  font-style: normal;
}

.conversation-list .ctext-wrap {
  background: var(--ct-chat-secondary-user-bg);
  border-radius: 3px;
  padding: 12px;
  display: inline-block;
  position: relative;
}

.conversation-list .ctext-wrap i {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  display: block;
  position: relative;
}

.conversation-list .ctext-wrap p {
  margin: 0;
  padding-top: 3px;
}

.conversation-list .ctext-wrap:after {
  content: " ";
  pointer-events: none;
  border: solid #0000;
  border-top-color: var(--ct-chat-secondary-user-bg);
  border-width: 6px;
  border-right-color: var(--ct-chat-secondary-user-bg);
  width: 0;
  height: 0;
  margin-right: -1px;
  position: absolute;
  top: 0;
  left: -11px;
}

.conversation-list .conversation-text {
  float: left;
  width: 70%;
  margin-left: 12px;
  font-size: 13px;
}

.conversation-list .odd .chat-avatar {
  float: right !important;
}

.conversation-list .odd .conversation-text {
  text-align: right;
  margin-right: 12px;
  float: right !important;
  width: 70% !important;
}

.conversation-list .odd .ctext-wrap {
  background-color: var(--ct-chat-primary-user-bg);
}

.conversation-list .odd .ctext-wrap:after {
  border-color: #0000;
  border-left-color: var(--ct-chat-primary-user-bg);
  border-top-color: var(--ct-chat-primary-user-bg);
  left: auto;
  right: -10px;
}

.conversation-list .odd .conversation-actions {
  float: left;
}

.calendar-widget .datepicker-inline, .calendar-widget table {
  width: 100%;
}

.calendar-widget .datepicker-inline tr td, .calendar-widget .datepicker-inline tr th, .calendar-widget .datepicker-inline tr td.active.day, .calendar-widget .datepicker-inline tr td.today.day, .calendar-widget table tr td, .calendar-widget table tr th, .calendar-widget table tr td.active.day, .calendar-widget table tr td.today.day {
  background-color: #0000 !important;
}

.calendar-widget .datepicker-inline tr td.active.day, .calendar-widget .datepicker-inline tr td.today.day, .calendar-widget table tr td.active.day, .calendar-widget table tr td.today.day {
  text-shadow: none;
  font-weight: 700;
  color: #fa5c7c !important;
}

.calendar-widget .datepicker-inline tr td.active.day:hover, .calendar-widget .datepicker-inline tr td.today.day:hover, .calendar-widget table tr td.active.day:hover, .calendar-widget table tr td.today.day:hover {
  background-color: #0000 !important;
}

.calendar-widget .datepicker-inline td, .calendar-widget .datepicker-inline th, .calendar-widget table td, .calendar-widget table th {
  height: 44.5px;
}

.calendar-widget .datepicker-inline .datepicker-switch, .calendar-widget .datepicker-inline .prev, .calendar-widget .datepicker-inline .next, .calendar-widget table .datepicker-switch, .calendar-widget table .prev, .calendar-widget table .next {
  color: #727cf5;
  border-radius: 0;
  font-size: 1.1rem;
  background-color: #727cf51a !important;
}

.multi-user a {
  border: 3px solid #f1f3fa;
  border-radius: 50px;
  margin-left: -15px;
}

.multi-user a:first-child {
  margin-left: 0;
}

.card-bg-img {
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

body[data-layout-color="dark"] .chart-content-bg {
  background-color: #444e5a;
}

body[data-layout-color="dark"] .chart-content-border {
  border: 1px solid #37404a;
}

body[data-layout-color="dark"] .chart-widget-list p {
  border-bottom: 1px solid #404954;
}

body[data-layout-color="dark"] .timeline-alt .timeline-item:before {
  background-color: #404954;
}

body[data-layout-color="dark"] .inbox-widget .inbox-item {
  border-bottom: 1px solid #4b5662;
}

body[data-layout-color="dark"] .inbox-widget .inbox-item .inbox-item-author {
  color: #e3eaef;
}

body[data-layout-color="dark"] .inbox-widget .inbox-item .inbox-item-text {
  color: #aab8c5;
}

body[data-layout-color="dark"] .inbox-widget .inbox-item .inbox-item-date {
  color: #ced4da;
}

body[data-layout-color="dark"] .multi-user a {
  border: 3px solid #404954;
}

.social-list-item {
  color: #adb5bd;
  border: 2px solid #adb5bd;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  line-height: calc(2rem - 2px);
  display: block;
}

.social-list-item:hover {
  color: #98a6ad;
  border-color: #98a6ad;
}

body[data-layout-color="dark"] .social-list-item {
  border: 2px solid #aab8c5;
}

body[data-layout-color="dark"] .social-list-item:hover {
  color: #ced4da;
  border-color: #ced4da;
}

.horizontal-steps {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.horizontal-steps:before {
  content: "";
  background-color: #eef2f7;
  width: 100%;
  height: .2em;
  display: block;
  position: absolute;
}

.horizontal-steps .process-line {
  background-color: #727cf5;
  width: 50%;
  height: .2em;
  display: block;
  position: absolute;
}

.horizontal-steps .horizontal-steps-content {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  position: relative;
}

.horizontal-steps .horizontal-steps-content .step-item {
  box-sizing: content-box;
  color: #727cf5;
  z-index: 5;
  background-color: currentColor;
  border: .25em solid #fafbfe;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0 2em;
  display: block;
  position: relative;
  bottom: calc(100% + 1em);
}

.horizontal-steps .horizontal-steps-content .step-item:first-child {
  margin-left: 0;
}

.horizontal-steps .horizontal-steps-content .step-item:last-child {
  color: #0acf97;
  margin-right: 0;
}

.horizontal-steps .horizontal-steps-content .step-item span {
  white-space: nowrap;
  color: #adb5bd;
  position: absolute;
  top: calc(100% + 1em);
  left: 50%;
  transform: translate(-50%);
}

.horizontal-steps .horizontal-steps-content .step-item.current:before {
  content: "";
  opacity: 0;
  z-index: -1;
  background-color: currentColor;
  border-radius: 50%;
  padding: 1em;
  animation-name: animation-steps-current;
  animation-duration: 2s;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  display: block;
  position: absolute;
  top: 47.5%;
  left: 51%;
}

.horizontal-steps .horizontal-steps-content .step-item.current span {
  color: #727cf5 !important;
}

@keyframes animation-steps-current {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%)scale(0);
  }

  to {
    opacity: 0;
    transform: translate(-50%, -50%)scale(1);
  }
}

@media (width <= 767.98px) {
  .horizontal-steps .horizontal-steps-content .step-item span {
    white-space: inherit;
  }
}

body[data-layout-color="dark"] .horizontal-steps:before {
  background-color: #37404a;
}

body[data-layout-color="dark"] .horizontal-steps .horizontal-steps-content .step-item {
  border: .25em solid var(--ct-body-bg);
}

body[data-layout-color="dark"] .horizontal-steps .horizontal-steps-content .step-item span {
  color: #aab8c5;
}

#preloader {
  z-index: 9999;
  background-color: #f1f3fa;
  position: absolute;
  inset: 0;
}

#status {
  width: 80px;
  height: 80px;
  margin: -40px 0 0 -40px;
  position: absolute;
  top: 50%;
  left: 50%;
}

@keyframes bouncing-loader {
  to {
    opacity: .1;
    transform: translate3d(0, -16px, 0);
  }
}

.bouncing-loader {
  justify-content: center;
  display: flex;
}

.bouncing-loader > div {
  background: #727cf5;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  margin: 32px 3px;
  animation: .6s infinite alternate bouncing-loader;
}

.bouncing-loader > div:nth-child(2) {
  background: #fa5c7c;
  animation-delay: .2s;
}

.bouncing-loader > div:nth-child(3) {
  background: #0acf97;
  animation-delay: .4s;
}

body[data-layout-color="dark"] #preloader {
  background-color: #404954;
}

.hero-section {
  padding: 80px 0 120px;
  position: relative;
}

.hero-section:after {
  content: " ";
  background-image: var(--ct-hero-bg);
  z-index: -1;
  border-radius: 0;
  width: 100%;
  position: absolute;
  top: -400px;
  bottom: 0;
  right: 0;
  transform: skewY(-3deg);
}

.hero-section .hero-title {
  line-height: 42px;
}

body.authentication-bg {
  background-image: url("bg-pattern-light-GKOLASZM-GKOLASZM.33d52ada.svg");
  background-position: center;
  background-size: cover;
}

body.authentication-bg .account-pages {
  align-items: center;
  min-height: 100vh;
  display: flex;
}

body.authentication-bg[data-layout-color="dark"] {
  background-image: url("bg-pattern-dark-XRVRBGKS-XRVRBGKS.fffa1a15.png");
}

.authentication-bg.enlarged, .auth-fluid-pages.enlarged, body.auth-fluid-pages[data-leftbar-compact-mode="condensed"] {
  min-height: 100px;
}

.logout-icon {
  width: 140px;
}

.auth-fluid {
  background: url("bg-pattern-3CU3NKA2-3CU3NKA2.088fedfa.png") center / cover;
  flex-direction: row;
  align-items: stretch;
  min-height: 100vh;
  display: flex;
  position: relative;
}

.auth-fluid .auth-fluid-form-box {
  z-index: 2;
  background-color: var(--ct-auth-bg);
  border-radius: 0;
  width: 100%;
  max-width: 480px;
  padding: 3rem 2rem;
  position: relative;
}

.auth-fluid .auth-fluid-right, .auth-fluid .auth-fluid-left {
  color: #fff;
  background-color: #0000004d;
  flex: 1;
  padding: 6rem 3rem;
  position: relative;
}

.auth-brand {
  margin-bottom: 2rem;
}

.auth-brand .logo-dark {
  display: block;
}

.auth-brand .logo-light {
  display: none;
}

.auth-user-testimonial {
  margin: 0 auto;
  padding: 0 1.75rem;
  position: absolute;
  bottom: 3rem;
  left: 0;
  right: 0;
}

.auth-user-testimonial p.lead {
  max-width: 700px;
  margin: 0 auto 20px;
  font-size: 1.125rem;
}

@media (width >= 992px) {
  .auth-brand {
    position: absolute;
    top: 3rem;
  }
}

@media (width <= 991.98px) {
  .auth-fluid {
    display: block;
  }

  .auth-fluid .auth-fluid-form-box {
    max-width: 100%;
    min-height: 100vh;
  }

  .auth-fluid .auth-fluid-right {
    display: none;
  }
}

body[data-layout-color="dark"] .auth-brand .logo-dark {
  display: none;
}

body[data-layout-color="dark"] .auth-brand .logo-light {
  display: block;
}

.button-list {
  margin-bottom: -12px;
  margin-left: -8px;
}

.button-list .btn {
  margin-bottom: 12px;
  margin-left: 8px;
}

.scrollspy-example {
  height: 200px;
  margin-top: .5rem;
  position: relative;
  overflow: auto;
}

.grid-structure .grid-container {
  background-color: #f1f3fa;
  margin-bottom: 10px;
  padding: 10px 20px;
  font-size: .8rem;
  font-weight: 600;
}

.icons-list-demo div {
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 45px;
  display: block;
  overflow: hidden;
}

.icons-list-demo div p {
  line-height: inherit;
  margin-bottom: 0;
}

.icons-list-demo i {
  text-align: center;
  vertical-align: middle;
  border-radius: 3px;
  width: 50px;
  height: 50px;
  margin-right: 12px;
  font-size: 22px;
  line-height: 50px;
  transition: all .2s;
  display: inline-block;
}

.icons-list-demo .col-md-4 {
  background-clip: padding-box;
  border-radius: 3px;
  margin-bottom: 10px;
}

.icons-list-demo .col-md-4:hover, .icons-list-demo .col-md-4:hover i {
  color: #727cf5;
}

body[data-layout-color="dark"] .grid-structure .grid-container {
  background-color: #404954;
}

.text-error {
  color: #727cf5;
  text-shadow: 5px 1px #727cf54d, 10px 3px #727cf533;
  font-size: 5.25rem;
  line-height: 5.625rem;
}

.faq-question-q-box {
  color: #727cf5;
  box-shadow: var(--ct-box-shadow-lg);
  text-align: center;
  float: left;
  background-color: #727cf540;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-weight: 700;
  line-height: 30px;
}

.faq-question {
  color: #313a46;
  margin-top: 0;
  margin-left: 50px;
  font-size: 16px;
  font-weight: 600;
}

.faq-answer {
  margin-left: 50px;
}

body[data-layout-color="dark"] .faq-question {
  color: #f1f1f1;
}

.maintenance-icon {
  box-shadow: var(--ct-box-shadow-lg);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 22px;
  line-height: 58px;
  display: inline-block;
}

.board {
  white-space: nowrap;
  display: block;
  overflow-x: auto;
}

.tasks {
  vertical-align: top;
  border: 1px solid #eef2f7;
  border-radius: .25rem;
  width: 22rem;
  margin-bottom: 24px;
  padding: 0 1rem 1rem;
  display: inline-block;
}

.tasks.tasks:not(:last-child) {
  margin-right: 1.25rem;
}

.tasks .card {
  white-space: normal;
  margin-top: 1rem;
}

.tasks .task-header {
  background-color: #f1f3fa;
  margin: 0 -1rem;
  padding: 1rem;
}

.task-list-items {
  min-height: 100px;
  position: relative;
}

.task-list-items:before {
  content: "No Tasks";
  text-align: center;
  width: 100%;
  font-weight: 600;
  line-height: 110px;
  position: absolute;
}

.task-modal-content .form-control-light {
  background-color: #f7f9fb !important;
  border-color: #f7f9fb !important;
}

.gantt-task-details {
  min-width: 220px;
}

body[data-layout-color="dark"] .tasks {
  border: 1px solid #37404a;
}

body[data-layout-color="dark"] .tasks .task-header {
  background-color: #404954;
}

body[data-layout-color="dark"] .task-modal-content .form-control-light {
  background-color: #3c4651 !important;
  border-color: #3c4651 !important;
}

.page-aside-left {
  float: left;
  width: 240px;
  padding: 0 20px 20px 10px;
  position: relative;
}

.page-aside-left:before {
  content: "";
  background-color: #fafbfe;
  width: 5px;
  height: 100%;
  position: absolute;
  bottom: -1.5rem;
  right: -15px;
}

.page-aside-right {
  border-left: 5px solid #fafbfe;
  margin: -1.5rem 0 -1.5rem 250px;
  padding: 1.5rem 0 1.5rem 25px;
}

.email-list {
  padding-left: 0;
  display: block;
  overflow: hidden;
}

.email-list > li {
  cursor: default;
  height: 51px;
  line-height: 50px;
  transition-duration: .3s;
  display: block;
  position: relative;
}

.email-list > li a {
  color: #6c757d;
}

.email-list > li a:hover {
  color: #343a40;
}

.email-list > li .col-mail {
  float: left;
  position: relative;
}

.email-list > li .email-sender-info {
  width: 320px;
}

.email-list > li .email-sender-info .star-toggle, .email-list > li .email-sender-info .checkbox-wrapper-mail {
  float: left;
  display: block;
}

.email-list > li .email-sender-info .checkbox-wrapper-mail {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin: 15px 10px 0 20px;
}

.email-list > li .email-sender-info .star-toggle {
  color: #adb5bd;
  margin-left: 10px;
  font-size: 18px;
}

.email-list > li .email-sender-info .email-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
  line-height: 50px;
  position: absolute;
  top: 0;
  left: 100px;
  right: 0;
  overflow: hidden;
}

.email-list > li .email-content {
  position: absolute;
  inset: 0 0 0 320px;
}

.email-list > li .email-content .email-subject, .email-list > li .email-content .email-date {
  position: absolute;
  top: 0;
}

.email-list > li .email-content .email-subject {
  text-overflow: ellipsis;
  white-space: nowrap;
  left: 0;
  right: 110px;
  overflow: hidden;
}

.email-list > li .email-content .email-date {
  text-align: right;
  width: 100px;
  padding-left: 10px;
  right: 0;
}

.email-list > li.active, .email-list > li.mail-selected {
  background: #f1f3fa;
  transition-duration: 50ms;
}

.email-list > li.unread a {
  color: #272e37;
  font-weight: 600;
}

.email-list > li .email-action-icons {
  opacity: 0;
}

.email-list > li .email-action-icons ul {
  transition: all .5s;
  display: flex;
  position: absolute;
  right: -180px;
}

.email-list > li .email-action-icons ul li {
  margin: 0 10px;
}

.email-list > li .email-action-icons ul .email-action-icons-item {
  font-size: 20px;
  transition: all .5s;
}

.email-list > li .email-action-icons ul .email-action-icons-item:hover {
  color: #fa5c7c;
}

.email-list > li:hover {
  background: #f1f3fa;
  transition-duration: 50ms;
}

.email-list > li:hover .email-action-icons {
  opacity: 1;
}

.email-list > li:hover .email-action-icons ul {
  transition: all .5s;
  right: 10px;
}

.email-list > li:hover .email-content .email-date {
  opacity: 0;
}

.email-list > li:hover .email-content .email-subject {
  transition: all .5s;
  right: 180px;
}

.email-menu-list a {
  color: #6c757d;
  padding: 12px 5px;
  font-size: 15px;
  display: block;
}

.email-menu-list a:hover {
  color: #343a40;
}

.email-menu-list a .badge {
  margin-top: 3px;
}

.labels-list a {
  padding: 7px 5px;
}

.write-mdg-box .CodeMirror {
  height: 150px;
}

@media (width <= 648px) {
  .page-aside-left {
    float: none;
    width: 100%;
    padding: 0 10px 20px;
  }

  .page-aside-left:before {
    width: 0;
  }

  .page-aside-right {
    border: 0;
    margin-left: 0;
    padding-left: 0;
  }

  .email-list li .email-sender-info .checkbox-wrapper-mail {
    margin-left: 0;
  }
}

@media (width <= 520px) {
  .page-aside-right > .btn-group {
    margin-bottom: 10px;
  }

  .email-list li .email-sender-info {
    width: 150px;
  }

  .email-list li .email-sender-info .email-title {
    left: 80px;
  }

  .email-list li .email-content {
    display: none;
  }
}

body[data-layout-color="dark"] .page-aside-left:before {
  background-color: var(--ct-body-bg);
}

body[data-layout-color="dark"] .page-aside-right {
  border-left: 5px solid var(--ct-body-bg);
}

body[data-layout-color="dark"] .email-list > li a {
  color: #dee2e6;
}

body[data-layout-color="dark"] .email-list > li a:hover {
  color: #e3eaef;
}

body[data-layout-color="dark"] .email-list > li .email-sender-info .star-toggle {
  color: #aab8c5;
}

body[data-layout-color="dark"] .email-list > li.active, body[data-layout-color="dark"] .email-list > li.mail-selected, body[data-layout-color="dark"] .email-list > li:hover {
  background: #404954;
}

body[data-layout-color="dark"] .email-menu-list a {
  color: #dee2e6;
}

body[data-layout-color="dark"] .email-menu-list a:hover {
  color: #e3eaef;
}

.timeline {
  margin-bottom: 50px;
  position: relative;
}

.timeline:before {
  content: "";
  z-index: 0;
  background-color: #dee2e6;
  width: 2px;
  position: absolute;
  top: 30px;
  bottom: 0;
  left: 50%;
}

.timeline-show {
  position: relative;
}

.timeline-show .time-show-name {
  background-color: #eef2f7;
  border-radius: 4px;
  padding: 7px 15px;
  display: inline-block;
}

.timeline-box {
  background-color: var(--ct-card-bg);
  box-shadow: var(--ct-box-shadow);
  border-radius: .25rem;
  margin: 15px 0;
  padding: 1.5rem;
  display: block;
  position: relative;
}

.timeline-album {
  margin-top: 12px;
}

.timeline-album a {
  margin-right: 5px;
  display: inline-block;
}

.timeline-album img {
  border-radius: 3px;
  width: auto;
  height: 36px;
}

@media (width >= 768px) {
  .timeline .timeline-box {
    margin-left: 45px;
  }

  .timeline .timeline-icon {
    text-align: center;
    background: #dee2e6;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin-top: -12px;
    display: block;
    position: absolute;
    top: 50%;
    left: -56px;
  }

  .timeline .timeline-icon i {
    color: #98a6ad;
    vertical-align: middle;
    font-size: 1rem;
  }

  .timeline .timeline-desk {
    vertical-align: top;
    width: 50%;
    display: table-cell;
  }

  .timeline-lg-item {
    display: table-row;
  }

  .timeline-lg-item:before {
    content: "";
    width: 50%;
    display: block;
  }

  .timeline-lg-item .timeline-desk .arrow {
    border-top: 12px solid #0000;
    border-bottom: 12px solid #0000;
    width: 0;
    height: 0;
    margin-top: -12px;
    display: block;
    position: absolute;
    top: 50%;
    left: -12px;
    border-right: 12px solid var(--ct-card-bg) !important;
  }

  .timeline-lg-item.timeline-item-left:after {
    content: "";
    width: 50%;
    display: block;
  }

  .timeline-lg-item.timeline-item-left .timeline-desk .arrow-alt {
    border-top: 12px solid #0000;
    border-bottom: 12px solid #0000;
    width: 0;
    height: 0;
    margin-top: -12px;
    display: block;
    position: absolute;
    top: 50%;
    left: auto;
    right: -12px;
    border-left: 12px solid var(--ct-card-bg) !important;
  }

  .timeline-lg-item.timeline-item-left .timeline-desk .album {
    float: right;
    margin-top: 20px;
  }

  .timeline-lg-item.timeline-item-left .timeline-desk .album a {
    float: right;
    margin-left: 5px;
  }

  .timeline-lg-item.timeline-item-left .timeline-icon {
    left: auto;
    right: -58px;
  }

  .timeline-lg-item.timeline-item-left:before {
    display: none;
  }

  .timeline-lg-item.timeline-item-left .timeline-box {
    margin-left: 0;
    margin-right: 45px;
  }
}

@media (width <= 767.98px) {
  .timeline .timeline-icon {
    display: none;
  }
}

body[data-layout-color="dark"] .timeline:before {
  background-color: #464f5b;
}

body[data-layout-color="dark"] .timeline-show .time-show-name {
  background-color: #37404a;
}

@media (width >= 768px) {
  body[data-layout-color="dark"] .timeline .timeline-icon {
    background: #464f5b;
  }

  body[data-layout-color="dark"] .timeline .timeline-icon i {
    color: #ced4da;
  }
}

.daterangepicker {
  color: inherit;
  z-index: 3001;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 278px;
  max-width: none;
  margin-top: 7px;
  padding: 0;
  font-family: arial;
  font-size: 15px;
  line-height: 1em;
  display: none;
  position: absolute;
  top: 100px;
  left: 20px;
}

.daterangepicker:before, .daterangepicker:after {
  content: "";
  border-bottom-color: #0003;
  display: inline-block;
  position: absolute;
}

.daterangepicker:before {
  border-bottom: 7px solid #ccc;
  border-left: 7px solid #0000;
  border-right: 7px solid #0000;
  top: -7px;
}

.daterangepicker:after {
  border-bottom: 6px solid #fff;
  border-left: 6px solid #0000;
  border-right: 6px solid #0000;
  top: -6px;
}

.daterangepicker.opensleft:before {
  right: 9px;
}

.daterangepicker.opensleft:after {
  right: 10px;
}

.daterangepicker.openscenter:before, .daterangepicker.openscenter:after {
  width: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.daterangepicker.opensright:before {
  left: 9px;
}

.daterangepicker.opensright:after {
  left: 10px;
}

.daterangepicker.drop-up {
  margin-top: -7px;
}

.daterangepicker.drop-up:before {
  top: initial;
  border-bottom: initial;
  border-top: 7px solid #ccc;
  bottom: -7px;
}

.daterangepicker.drop-up:after {
  top: initial;
  border-bottom: initial;
  border-top: 6px solid #fff;
  bottom: -6px;
}

.daterangepicker.single .daterangepicker .ranges, .daterangepicker.single .drp-calendar {
  float: none;
}

.daterangepicker.single .drp-selected {
  display: none;
}

.daterangepicker.show-calendar .drp-calendar, .daterangepicker.show-calendar .drp-buttons {
  display: block;
}

.daterangepicker.auto-apply .drp-buttons {
  display: none;
}

.daterangepicker .drp-calendar {
  max-width: 270px;
  display: none;
}

.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px;
}

.daterangepicker .drp-calendar.right {
  padding: 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}

.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
  color: #fff;
  border: 2px solid #000;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  padding: 3px;
  display: inline-block;
}

.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
}

.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #0000;
  border-radius: 4px;
  width: 32px;
  min-width: 32px;
  height: 24px;
  font-size: 12px;
  line-height: 24px;
}

.daterangepicker .calendar-table {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 4px;
}

.daterangepicker .calendar-table table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  margin: 0;
}

.daterangepicker td.available:hover, .daterangepicker th.available:hover {
  color: inherit;
  background-color: #eee;
  border-color: #0000;
}

.daterangepicker td.week, .daterangepicker th.week {
  color: #ccc;
  font-size: 80%;
}

.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
  color: #999;
  background-color: #fff;
  border-color: #0000;
}

.daterangepicker td.in-range {
  color: #000;
  background-color: #ebf4f8;
  border-color: #0000;
  border-radius: 0;
}

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}

.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  color: #fff;
  background-color: #357ebd;
  border-color: #0000;
}

.daterangepicker th.month {
  width: auto;
}

.daterangepicker td.disabled, .daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
  cursor: default;
  height: auto;
  margin: 0;
  padding: 1px;
  font-size: 12px;
}

.daterangepicker select.monthselect {
  width: 56%;
  margin-right: 2%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
  background: #eee;
  border: 1px solid #eee;
  outline: 0;
  width: 50px;
  margin: 0 auto;
  padding: 2px;
  font-size: 12px;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  vertical-align: middle;
  border-top: 1px solid #ddd;
  padding: 8px;
  line-height: 12px;
  display: none;
}

.daterangepicker .drp-selected {
  padding-right: 8px;
  font-size: 12px;
  display: inline-block;
}

.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 700;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}

.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
}

.daterangepicker .ranges ul {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

.daterangepicker .ranges li {
  cursor: pointer;
  padding: 8px 12px;
  font-size: 12px;
}

.daterangepicker .ranges li:hover {
  background-color: #eee;
}

.daterangepicker .ranges li.active {
  color: #fff;
  background-color: #08c;
}

@media (width >= 564px) {
  .daterangepicker {
    width: auto;
  }

  .daterangepicker .ranges ul {
    width: 140px;
  }

  .daterangepicker.single .ranges ul {
    width: 100%;
  }

  .daterangepicker.single .drp-calendar.left {
    clear: none;
  }

  .daterangepicker.single .ranges, .daterangepicker.single .drp-calendar {
    float: left;
  }

  .daterangepicker {
    direction: ltr;
    text-align: left;
  }

  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .daterangepicker .drp-calendar.right {
    margin-left: 0;
  }

  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }

  .daterangepicker .ranges, .daterangepicker .drp-calendar {
    float: left;
  }
}

@media (width >= 730px) {
  .daterangepicker .ranges {
    float: left;
    width: auto;
  }

  .daterangepicker.rtl .ranges {
    float: right;
  }

  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}

.datepicker {
  direction: ltr;
  border-radius: 4px;
  padding: 4px;
}

.datepicker-inline {
  width: 220px;
}

.datepicker-rtl {
  direction: rtl;
}

.datepicker-rtl.dropdown-menu {
  left: auto;
}

.datepicker-rtl table tr td span {
  float: right;
}

.datepicker-dropdown {
  top: 0;
  left: 0;
}

.datepicker-dropdown:before {
  content: "";
  border: 7px solid #0000;
  border-top: 0;
  border-bottom-color: #0003;
  display: inline-block;
  position: absolute;
}

.datepicker-dropdown:after {
  content: "";
  border: 6px solid #0000;
  border-top: 0;
  border-bottom-color: #fff;
  display: inline-block;
  position: absolute;
}

.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px;
}

.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px;
}

.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px;
}

.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px;
}

.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px;
}

.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px;
}

.datepicker-dropdown.datepicker-orient-top:before {
  border-top: 7px solid #999;
  border-bottom: 0;
  bottom: -7px;
}

.datepicker-dropdown.datepicker-orient-top:after {
  border-top: 6px solid #fff;
  border-bottom: 0;
  bottom: -6px;
}

.datepicker table {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
  margin: 0;
}

.datepicker td, .datepicker th {
  text-align: center;
  border: none;
  border-radius: 4px;
  width: 20px;
  height: 20px;
}

.table-striped .datepicker table tr td, .table-striped .datepicker table tr th {
  background-color: #0000;
}

.datepicker table tr td.day.focused, .datepicker table tr td.day:hover {
  cursor: pointer;
  background: #eee;
}

.datepicker table tr td.new, .datepicker table tr td.old {
  color: #999;
}

.datepicker table tr td.disabled, .datepicker table tr td.disabled:hover {
  color: #999;
  cursor: default;
  background: none;
}

.datepicker table tr td.highlighted {
  background: #d9edf7;
  border-radius: 0;
}

.datepicker table tr td.today, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today:hover {
  background-color: #fde19a;
  background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
  background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  color: #000;
  background-image: linear-gradient(#fdd49a, #fdf59a);
  background-repeat: repeat-x;
  border-color: #0000001a #0000001a #00000040;
}

.datepicker table tr td.today.active, .datepicker table tr td.today.disabled, .datepicker table tr td.today.disabled.active, .datepicker table tr td.today.disabled.disabled, .datepicker table tr td.today.disabled:active, .datepicker table tr td.today.disabled:hover, .datepicker table tr td.today.disabled:hover.active, .datepicker table tr td.today.disabled:hover.disabled, .datepicker table tr td.today.disabled:hover:active, .datepicker table tr td.today.disabled:hover:hover, .datepicker table tr td.today.disabled:hover[disabled], .datepicker table tr td.today.disabled[disabled], .datepicker table tr td.today:active, .datepicker table tr td.today:hover, .datepicker table tr td.today:hover.active, .datepicker table tr td.today:hover.disabled, .datepicker table tr td.today:hover:active, .datepicker table tr td.today:hover:hover, .datepicker table tr td.today:hover[disabled], .datepicker table tr td.today[disabled] {
  background-color: #fdf59a;
}

.datepicker table tr td.today.active, .datepicker table tr td.today.disabled.active, .datepicker table tr td.today.disabled:active, .datepicker table tr td.today.disabled:hover.active, .datepicker table tr td.today.disabled:hover:active, .datepicker table tr td.today:active, .datepicker table tr td.today:hover.active, .datepicker table tr td.today:hover:active {
  background-color: #fbf069;
}

.datepicker table tr td.today:hover:hover {
  color: #000;
}

.datepicker table tr td.today.active:hover {
  color: #fff;
}

.datepicker table tr td.range, .datepicker table tr td.range.disabled, .datepicker table tr td.range.disabled:hover, .datepicker table tr td.range:hover {
  background: #eee;
  border-radius: 0;
}

.datepicker table tr td.range.today, .datepicker table tr td.range.today.disabled, .datepicker table tr td.range.today.disabled:hover, .datepicker table tr td.range.today:hover {
  background-color: #f3d17a;
  background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
  background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  background-image: linear-gradient(#f3c17a, #f3e97a);
  background-repeat: repeat-x;
  border-color: #0000001a #0000001a #00000040;
  border-radius: 0;
}

.datepicker table tr td.range.today.active, .datepicker table tr td.range.today.disabled, .datepicker table tr td.range.today.disabled.active, .datepicker table tr td.range.today.disabled.disabled, .datepicker table tr td.range.today.disabled:active, .datepicker table tr td.range.today.disabled:hover, .datepicker table tr td.range.today.disabled:hover.active, .datepicker table tr td.range.today.disabled:hover.disabled, .datepicker table tr td.range.today.disabled:hover:active, .datepicker table tr td.range.today.disabled:hover:hover, .datepicker table tr td.range.today.disabled:hover[disabled], .datepicker table tr td.range.today.disabled[disabled], .datepicker table tr td.range.today:active, .datepicker table tr td.range.today:hover, .datepicker table tr td.range.today:hover.active, .datepicker table tr td.range.today:hover.disabled, .datepicker table tr td.range.today:hover:active, .datepicker table tr td.range.today:hover:hover, .datepicker table tr td.range.today:hover[disabled], .datepicker table tr td.range.today[disabled] {
  background-color: #f3e97a;
}

.datepicker table tr td.range.today.active, .datepicker table tr td.range.today.disabled.active, .datepicker table tr td.range.today.disabled:active, .datepicker table tr td.range.today.disabled:hover.active, .datepicker table tr td.range.today.disabled:hover:active, .datepicker table tr td.range.today:active, .datepicker table tr td.range.today:hover.active, .datepicker table tr td.range.today:hover:active {
  background-color: #efe24b;
}

.datepicker table tr td.selected, .datepicker table tr td.selected.disabled, .datepicker table tr td.selected.disabled:hover, .datepicker table tr td.selected:hover {
  background-color: #9e9e9e;
  background-image: -moz-linear-gradient(to bottom, #b3b3b3, grey);
  background-image: -ms-linear-gradient(to bottom, #b3b3b3, grey);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(gray));
  background-image: -webkit-linear-gradient(to bottom, #b3b3b3, grey);
  background-image: -o-linear-gradient(to bottom, #b3b3b3, grey);
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  color: #fff;
  text-shadow: 0 -1px #00000040;
  background-image: linear-gradient(#b3b3b3, gray);
  background-repeat: repeat-x;
  border-color: #0000001a #0000001a #00000040;
}

.datepicker table tr td.selected.active, .datepicker table tr td.selected.disabled, .datepicker table tr td.selected.disabled.active, .datepicker table tr td.selected.disabled.disabled, .datepicker table tr td.selected.disabled:active, .datepicker table tr td.selected.disabled:hover, .datepicker table tr td.selected.disabled:hover.active, .datepicker table tr td.selected.disabled:hover.disabled, .datepicker table tr td.selected.disabled:hover:active, .datepicker table tr td.selected.disabled:hover:hover, .datepicker table tr td.selected.disabled:hover[disabled], .datepicker table tr td.selected.disabled[disabled], .datepicker table tr td.selected:active, .datepicker table tr td.selected:hover, .datepicker table tr td.selected:hover.active, .datepicker table tr td.selected:hover.disabled, .datepicker table tr td.selected:hover:active, .datepicker table tr td.selected:hover:hover, .datepicker table tr td.selected:hover[disabled], .datepicker table tr td.selected[disabled] {
  background-color: gray;
}

.datepicker table tr td.selected.active, .datepicker table tr td.selected.disabled.active, .datepicker table tr td.selected.disabled:active, .datepicker table tr td.selected.disabled:hover.active, .datepicker table tr td.selected.disabled:hover:active, .datepicker table tr td.selected:active, .datepicker table tr td.selected:hover.active, .datepicker table tr td.selected:hover:active {
  background-color: #666;
}

.datepicker table tr td.active, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active:hover {
  background-color: #006dcc;
  background-image: -moz-linear-gradient(to bottom, #08c, #04c);
  background-image: -ms-linear-gradient(to bottom, #08c, #04c);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#04c));
  background-image: -webkit-linear-gradient(to bottom, #08c, #04c);
  background-image: -o-linear-gradient(to bottom, #08c, #04c);
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  color: #fff;
  text-shadow: 0 -1px #00000040;
  background-image: linear-gradient(#08c, #04c);
  background-repeat: repeat-x;
  border-color: #0000001a #0000001a #00000040;
}

.datepicker table tr td.active.active, .datepicker table tr td.active.disabled, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled.disabled, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover, .datepicker table tr td.active.disabled:hover.active, .datepicker table tr td.active.disabled:hover.disabled, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active.disabled:hover:hover, .datepicker table tr td.active.disabled:hover[disabled], .datepicker table tr td.active.disabled[disabled], .datepicker table tr td.active:active, .datepicker table tr td.active:hover, .datepicker table tr td.active:hover.active, .datepicker table tr td.active:hover.disabled, .datepicker table tr td.active:hover:active, .datepicker table tr td.active:hover:hover, .datepicker table tr td.active:hover[disabled], .datepicker table tr td.active[disabled] {
  background-color: #04c;
}

.datepicker table tr td.active.active, .datepicker table tr td.active.disabled.active, .datepicker table tr td.active.disabled:active, .datepicker table tr td.active.disabled:hover.active, .datepicker table tr td.active.disabled:hover:active, .datepicker table tr td.active:active, .datepicker table tr td.active:hover.active, .datepicker table tr td.active:hover:active {
  background-color: #039;
}

.datepicker table tr td span {
  float: left;
  cursor: pointer;
  border-radius: 4px;
  width: 23%;
  height: 54px;
  margin: 1%;
  line-height: 54px;
  display: block;
}

.datepicker table tr td span.focused, .datepicker table tr td span:hover {
  background: #eee;
}

.datepicker table tr td span.disabled, .datepicker table tr td span.disabled:hover {
  color: #999;
  cursor: default;
  background: none;
}

.datepicker table tr td span.active, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active:hover {
  background-color: #006dcc;
  background-image: -moz-linear-gradient(to bottom, #08c, #04c);
  background-image: -ms-linear-gradient(to bottom, #08c, #04c);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#04c));
  background-image: -webkit-linear-gradient(to bottom, #08c, #04c);
  background-image: -o-linear-gradient(to bottom, #08c, #04c);
  filter: progid:DXImageTransform. Microsoft. gradient(enabled= false);
  color: #fff;
  text-shadow: 0 -1px #00000040;
  background-image: linear-gradient(#08c, #04c);
  background-repeat: repeat-x;
  border-color: #0000001a #0000001a #00000040;
}

.datepicker table tr td span.active.active, .datepicker table tr td span.active.disabled, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled.disabled, .datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover, .datepicker table tr td span.active.disabled:hover.active, .datepicker table tr td span.active.disabled:hover.disabled, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active.disabled:hover:hover, .datepicker table tr td span.active.disabled:hover[disabled], .datepicker table tr td span.active.disabled[disabled], .datepicker table tr td span.active:active, .datepicker table tr td span.active:hover, .datepicker table tr td span.active:hover.active, .datepicker table tr td span.active:hover.disabled, .datepicker table tr td span.active:hover:active, .datepicker table tr td span.active:hover:hover, .datepicker table tr td span.active:hover[disabled], .datepicker table tr td span.active[disabled] {
  background-color: #04c;
}

.datepicker table tr td span.active.active, .datepicker table tr td span.active.disabled.active, .datepicker table tr td span.active.disabled:active, .datepicker table tr td span.active.disabled:hover.active, .datepicker table tr td span.active.disabled:hover:active, .datepicker table tr td span.active:active, .datepicker table tr td span.active:hover.active, .datepicker table tr td span.active:hover:active {
  background-color: #039;
}

.datepicker table tr td span.new, .datepicker table tr td span.old {
  color: #999;
}

.datepicker .datepicker-switch {
  width: 145px;
}

.datepicker .datepicker-switch, .datepicker .next, .datepicker .prev, .datepicker tfoot tr th {
  cursor: pointer;
}

.datepicker .datepicker-switch:hover, .datepicker .next:hover, .datepicker .prev:hover, .datepicker tfoot tr th:hover {
  background: #eee;
}

.datepicker .next.disabled, .datepicker .prev.disabled {
  visibility: hidden;
}

.datepicker .cw {
  vertical-align: middle;
  width: 12px;
  padding: 0 2px 0 5px;
  font-size: 10px;
}

.input-append.date .add-on, .input-prepend.date .add-on {
  cursor: pointer;
}

.input-append.date .add-on i, .input-prepend.date .add-on i {
  margin-top: 3px;
}

.input-daterange input {
  text-align: center;
}

.input-daterange input:first-child {
  border-radius: 3px 0 0 3px;
}

.input-daterange input:last-child {
  border-radius: 0 3px 3px 0;
}

.input-daterange .add-on {
  text-align: center;
  text-shadow: 0 1px #fff;
  vertical-align: middle;
  background-color: #eee;
  border: 1px solid #ccc;
  width: auto;
  min-width: 16px;
  height: 18px;
  margin-left: -5px;
  margin-right: -5px;
  padding: 4px 5px;
  font-weight: 400;
  line-height: 18px;
  display: inline-block;
}

.select2-container {
  box-sizing: border-box;
  vertical-align: middle;
  margin: 0;
  display: inline-block;
  position: relative;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  height: 28px;
  display: block;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 8px;
  padding-right: 20px;
  display: block;
  overflow: hidden;
}

.select2-container .select2-selection--single .select2-selection__clear {
  background-color: #0000;
  border: none;
  font-size: 1em;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-left: 20px;
  padding-right: 8px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  min-height: 32px;
  display: block;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding: 0;
  list-style: none;
  display: inline;
}

.select2-container .select2-selection--multiple .select2-selection__clear {
  background-color: #0000;
  border: none;
  font-size: 1em;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  resize: none;
  vertical-align: bottom;
  word-break: keep-all;
  border: none;
  max-width: 100%;
  height: 18px;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  font-family: sans-serif;
  font-size: 100%;
  overflow: hidden;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  box-sizing: border-box;
  z-index: 1051;
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
  width: 100%;
  display: block;
  position: absolute;
  left: -100000px;
}

.select2-results {
  display: block;
}

.select2-results__options {
  margin: 0;
  padding: 0;
  list-style: none;
}

.select2-results__option {
  -webkit-user-select: none;
  user-select: none;
  padding: 6px;
}

.select2-results__option--selectable {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  padding: 4px;
  display: block;
}

.select2-search--dropdown .select2-search__field {
  box-sizing: border-box;
  width: 100%;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  opacity: 0;
  z-index: 99;
  filter: alpha(opacity= 0);
  background-color: #fff;
  border: 0;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

.select2-hidden-accessible {
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  white-space: nowrap !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  height: 26px;
  margin-right: 20px;
  padding-right: 0;
  font-weight: 700;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  width: 20px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border: 4px solid #0000;
  border-top: 5px solid #888;
  border-bottom-width: 0;
  width: 0;
  height: 0;
  margin-top: -2px;
  margin-left: -4px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  cursor: default;
  background-color: #eee;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-width: 0 4px 5px;
  border-color: #0000 #0000 #888;
}

.select2-container--default .select2-selection--multiple {
  cursor: text;
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
  padding-bottom: 5px;
  padding-right: 5px;
  position: relative;
}

.select2-container--default .select2-selection--multiple.select2-selection--clearable {
  padding-right: 25px;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  height: 20px;
  margin-top: 5px;
  margin-right: 10px;
  padding: 1px;
  font-weight: 700;
  position: absolute;
  right: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  max-width: 100%;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0 0 0 20px;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-right: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0 4px;
  font-size: 1em;
  font-weight: 700;
  position: absolute;
  top: 0;
  left: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  color: #333;
  background-color: #f1f1f1;
  outline: none;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  border-left: 1px solid #aaa;
  border-right: none;
  border-radius: 0 4px 4px 0;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__clear {
  float: left;
  margin-left: 10px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid #000;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  cursor: default;
  background-color: #eee;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  box-shadow: none;
  -webkit-appearance: textfield;
  background: none;
  border: none;
  outline: 0;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--group {
  padding: 0;
}

.select2-container--default .select2-results__option--disabled {
  color: #999;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  color: #fff;
  background-color: #5897fb;
}

.select2-container--default .select2-results__group {
  cursor: default;
  padding: 6px;
  display: block;
}

.select2-container--classic .select2-selection--single {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFFFFFFF", endColorstr= "#FFEEEEEE", GradientType= 0);
  background-color: #f7f7f7;
  background-image: linear-gradient(#fff 50%, #eee);
  background-repeat: repeat-x;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  height: 26px;
  margin-right: 20px;
  font-weight: 700;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFEEEEEE", endColorstr= "#FFCCCCCC", GradientType= 0);
  background-color: #ddd;
  background-image: linear-gradient(#eee 50%, #ccc);
  background-repeat: repeat-x;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 20px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border: 4px solid #0000;
  border-top: 5px solid #888;
  border-bottom-width: 0;
  width: 0;
  height: 0;
  margin-top: -2px;
  margin-left: -4px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 4px 0 0 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: none;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-width: 0 4px 5px;
  border-color: #0000 #0000 #888;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFFFFFFF", endColorstr= "#FFEEEEEE", GradientType= 0);
  background-image: linear-gradient(#fff, #eee 50%);
  background-repeat: repeat-x;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFEEEEEE", endColorstr= "#FFFFFFFF", GradientType= 0);
  background-image: linear-gradient(#eee 50%, #fff);
  background-repeat: repeat-x;
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--classic .select2-selection--multiple {
  cursor: text;
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  padding-bottom: 5px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  display: inline-block;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 0 4px;
  font-size: 1em;
  font-weight: 700;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
  outline: none;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  border-radius: 0 4px 4px 0;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  box-shadow: none;
  outline: 0;
}

.select2-container--classic .select2-dropdown {
  background-color: #fff;
  border: 1px solid #0000;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option--group {
  padding: 0;
}

.select2-container--classic .select2-results__option--disabled {
  color: gray;
}

.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
  color: #fff;
  background-color: #3875d7;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  padding: 6px;
  display: block;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

.jq-toast-wrap, .jq-toast-wrap * {
  margin: 0;
  padding: 0;
}

.jq-toast-wrap {
  letter-spacing: normal;
  width: 250px;
  display: block;
  position: fixed;
  pointer-events: none !important;
  z-index: 9000 !important;
}

.jq-toast-wrap.bottom-left {
  bottom: 20px;
  left: 20px;
}

.jq-toast-wrap.bottom-right {
  bottom: 20px;
  right: 40px;
}

.jq-toast-wrap.top-left {
  top: 20px;
  left: 20px;
}

.jq-toast-wrap.top-right {
  top: 20px;
  right: 40px;
}

.jq-toast-single {
  color: #fff;
  background-color: #444;
  border-radius: 4px;
  width: 100%;
  margin: 0 0 5px;
  padding: 10px;
  font-family: arial, sans-serif;
  font-size: 12px;
  line-height: 17px;
  display: block;
  position: relative;
  pointer-events: all !important;
}

.jq-toast-single h2, .jq-toast-single .h2 {
  color: inherit;
  font-family: arial, sans-serif;
  font-size: 14px;
  line-height: inherit;
  letter-spacing: normal;
  background: none;
  margin: 0 0 7px;
}

.jq-toast-single a {
  color: #eee;
  border-bottom: 1px solid #fff;
  padding-bottom: 3px;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;
}

.jq-toast-single ul {
  background: none;
  margin: 0 0 0 15px;
  padding: 0;
}

.jq-toast-single ul li {
  letter-spacing: normal;
  background: none;
  margin: 0;
  padding: 0;
  line-height: 17px;
  list-style-type: disc !important;
}

.close-jq-toast-single {
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  top: 3px;
  right: 7px;
}

.jq-toast-loader {
  background: red;
  border-radius: 5px;
  width: 0;
  height: 5px;
  display: block;
  position: absolute;
  top: -2px;
  left: 0;
}

.jq-toast-loaded {
  width: 100%;
}

.jq-has-icon {
  background-position: 10px;
  background-repeat: no-repeat;
  padding: 10px 10px 10px 50px;
}

.jq-icon-info {
  color: #d9edf7;
  background-color: #31708f;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=");
  border-color: #bce8f1;
}

.jq-icon-warning {
  color: #fcf8e3;
  background-color: #8a6d3b;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=");
  border-color: #faebcc;
}

.jq-icon-error {
  color: #f2dede;
  background-color: #a94442;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=");
  border-color: #ebccd1;
}

.jq-icon-success {
  color: #dff0d8;
  background-color: #3c763d;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==");
  border-color: #d6e9c6;
}

.bootstrap-timepicker {
  position: relative;
}

.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu {
  left: auto;
  right: 0;
}

.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:before {
  left: auto;
  right: 12px;
}

.bootstrap-timepicker.pull-right .bootstrap-timepicker-widget.dropdown-menu:after {
  left: auto;
  right: 13px;
}

.bootstrap-timepicker .input-group-addon {
  cursor: pointer;
}

.bootstrap-timepicker .input-group-addon i {
  width: 16px;
  height: 16px;
  display: inline-block;
}

.bootstrap-timepicker-widget.dropdown-menu {
  padding: 4px;
}

.bootstrap-timepicker-widget.dropdown-menu.open {
  display: inline-block;
}

.bootstrap-timepicker-widget.dropdown-menu:before {
  content: "";
  border-bottom: 7px solid #0003;
  border-left: 7px solid #0000;
  border-right: 7px solid #0000;
  display: inline-block;
  position: absolute;
}

.bootstrap-timepicker-widget.dropdown-menu:after {
  content: "";
  border-bottom: 6px solid #fff;
  border-left: 6px solid #0000;
  border-right: 6px solid #0000;
  display: inline-block;
  position: absolute;
}

.bootstrap-timepicker-widget.timepicker-orient-left:before {
  left: 6px;
}

.bootstrap-timepicker-widget.timepicker-orient-left:after {
  left: 7px;
}

.bootstrap-timepicker-widget.timepicker-orient-right:before {
  right: 6px;
}

.bootstrap-timepicker-widget.timepicker-orient-right:after {
  right: 7px;
}

.bootstrap-timepicker-widget.timepicker-orient-top:before {
  top: -7px;
}

.bootstrap-timepicker-widget.timepicker-orient-top:after {
  top: -6px;
}

.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
  border-top: 7px solid #999;
  border-bottom: 0;
  bottom: -7px;
}

.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
  border-top: 6px solid #fff;
  border-bottom: 0;
  bottom: -6px;
}

.bootstrap-timepicker-widget a.btn, .bootstrap-timepicker-widget input {
  border-radius: 4px;
}

.bootstrap-timepicker-widget table {
  width: 100%;
  margin: 0;
}

.bootstrap-timepicker-widget table td {
  text-align: center;
  height: 30px;
  margin: 0;
  padding: 2px;
}

.bootstrap-timepicker-widget table td:not(.separator) {
  min-width: 30px;
}

.bootstrap-timepicker-widget table td span {
  width: 100%;
}

.bootstrap-timepicker-widget table td a {
  color: #333;
  border: 1px solid #0000;
  outline: 0;
  width: 100%;
  margin: 0;
  padding: 8px 0;
  display: inline-block;
}

.bootstrap-timepicker-widget table td a:hover {
  background-color: #eee;
  border-color: #ddd;
  border-radius: 4px;
  text-decoration: none;
}

.bootstrap-timepicker-widget table td a i {
  margin-top: 2px;
  font-size: 18px;
}

.bootstrap-timepicker-widget table td input {
  text-align: center;
  width: 25px;
  margin: 0;
}

.bootstrap-timepicker-widget .modal-content {
  padding: 4px;
}

@media (width >= 767px) {
  .bootstrap-timepicker-widget.modal {
    width: 200px;
    margin-left: -100px;
  }
}

@media (width <= 767px) {
  .bootstrap-timepicker, .bootstrap-timepicker .dropdown-menu {
    width: 100%;
  }
}

.bootstrap-touchspin .input-group-btn-vertical {
  z-index: 11;
  height: 100%;
  position: absolute;
  right: 0;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn {
  text-align: center;
  width: 2em;
  height: 50%;
  padding: 0;
  line-height: 1;
  position: absolute;
  right: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0 4px 0 0;
  top: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  border-radius: 0 0 4px;
  bottom: 0;
}

.apex-charts {
  min-height: 10px !important;
}

.apex-charts text {
  fill: #adb5bd;
  font-family: Nunito, sans-serif !important;
}

.apex-charts text tspan {
  fill: #adb5bd;
}

.apex-charts text.apexcharts-title-text {
  fill: #adb5bd !important;
}

.apex-charts .apexcharts-canvas {
  margin: 0 auto;
}

.apexcharts-tooltip-title, .apexcharts-tooltip-text {
  color: #6c757d;
  font-family: Nunito, sans-serif !important;
}

.apexcharts-legend-series {
  font-weight: 600;
}

.apexcharts-gridline {
  pointer-events: none;
  stroke: var(--ct-apex-grid-color);
}

.apexcharts-legend-text {
  color: #98a6ad !important;
  font-family: Nunito, sans-serif !important;
}

.apexcharts-yaxis text, .apexcharts-xaxis text {
  fill: #adb5bd;
  font-family: Nunito, sans-serif !important;
}

.apexcharts-point-annotations text, .apexcharts-xaxis-annotations text, .apexcharts-yaxis-annotations text {
  fill: #fff;
}

.apexcharts-radar-series polygon {
  fill: #0000;
  stroke: #dee2e6;
}

.apexcharts-radar-series line {
  stroke: #dee2e6;
}

.apex-charts text, .apexcharts-pie-label, .apexcharts-datalabel-label, .apexcharts-datalabel-value {
  fill: #fff !important;
}

.apexcharts-xaxis .apexcharts-datalabel, .apexcharts-yaxis text {
  fill: #98a6ad !important;
}

.apexcharts-datalabels-group text {
  fill: #adb5bd !important;
}

.scatter-images-chart .apexcharts-legend {
  min-height: 17px;
  overflow: hidden !important;
}

.scatter-images-chart .apexcharts-legend-marker {
  background: none !important;
  margin-right: 7px !important;
}

.scatter-images-chart .apexcharts-legend-series {
  align-items: flex-start !important;
}

.apexcharts-pie-series path {
  stroke: #0000 !important;
}

.apexcharts-track path {
  stroke: #edeff1;
}

body[data-layout-color="dark"] .apex-charts text, body[data-layout-color="dark"] .apex-charts text tspan {
  fill: #aab8c5;
}

body[data-layout-color="dark"] .apex-charts text.apexcharts-title-text {
  fill: #aab8c5 !important;
}

body[data-layout-color="dark"] .apexcharts-tooltip-title, body[data-layout-color="dark"] .apexcharts-tooltip-text {
  color: #8391a2;
  font-family: Nunito, sans-serif !important;
}

body[data-layout-color="dark"] .apexcharts-legend-text {
  color: #ced4da !important;
}

body[data-layout-color="dark"] .apexcharts-yaxis text, body[data-layout-color="dark"] .apexcharts-xaxis text {
  fill: #aab8c5;
}

body[data-layout-color="dark"] .apexcharts-radar-series polygon, body[data-layout-color="dark"] .apexcharts-radar-series line {
  stroke: #464f5b;
}

body[data-layout-color="dark"] .apexcharts-xaxis .apexcharts-datalabel, body[data-layout-color="dark"] .apexcharts-yaxis text {
  fill: #ced4da !important;
}

body[data-layout-color="dark"] .apexcharts-datalabels-group text {
  fill: #aab8c5 !important;
}

body[data-layout-color="dark"] .apexcharts-track path {
  stroke: #515c69;
}

.britechart, .tick text {
  font-family: Nunito, sans-serif;
  font-size: .75rem;
}

.britechart .tick line {
  stroke: #0000;
}

.horizontal-grid-line, .vertical-grid-line, .extended-x-line, .extended-y-line {
  stroke: #dee2e6;
}

.tick text, .bar-chart .percentage-label, .donut-text, .legend-entry-name, .legend-entry-value {
  fill: #98a6ad;
}

body[data-layout-color="dark"] .horizontal-grid-line, body[data-layout-color="dark"] .vertical-grid-line, body[data-layout-color="dark"] .extended-x-line, body[data-layout-color="dark"] .extended-y-line {
  stroke: #464f5b;
}

body[data-layout-color="dark"] .tick text, body[data-layout-color="dark"] .bar-chart .percentage-label, body[data-layout-color="dark"] .donut-text, body[data-layout-color="dark"] .legend-entry-name, body[data-layout-color="dark"] .legend-entry-value {
  fill: #ced4da;
}

.calendar {
  float: left;
  margin-bottom: 0;
}

#calendar .table-bordered td, #calendar .table-bordered th {
  border: 1px solid var(--ct-table-border-color);
}

.none-border .modal-footer {
  border-top: none;
}

.fc-toolbar {
  margin: 6px 0 5px !important;
}

.fc-toolbar .fc-toolbar-title {
  text-transform: uppercase;
  line-height: 1.875rem;
  font-size: 1.25rem !important;
}

.fc-view {
  margin-top: 30px !important;
}

.fc-day-grid-event .fc-time {
  font-weight: 600;
}

.fc-event-time, .fc-event-title {
  color: #fff;
}

th.fc-col-header-cell {
  padding: .3rem 0;
}

.fc-day {
  background: none;
}

.fc-toolbar .fc-state-active, .fc-toolbar .ui-state-active, .fc-toolbar button:focus, .fc-toolbar button:hover, .fc-toolbar .ui-state-hover {
  z-index: 0;
}

.fc th.fc-widget-header {
  text-transform: uppercase;
  background: #dee2e6;
  padding: 10px 0;
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
}

.fc-unthemed th, .fc-unthemed td, .fc-unthemed thead, .fc-unthemed tbody, .fc-unthemed .fc-divider, .fc-unthemed .fc-row, .fc-unthemed .fc-popover {
  border-color: #dee2e6;
}

.fc-unthemed td.fc-today, .fc-unthemed .fc-divider {
  background: #dee2e6;
}

.fc-button {
  color: #6c757d;
  text-transform: capitalize;
  box-shadow: none;
  background: #dee2e6;
  border: none;
  border-radius: 3px;
  height: auto;
  margin: 0 3px;
  padding: 6px 12px;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 1rem;
}

.fc-state-hover, .fc-state-highlight, .fc-cell-overlay {
  background: #dee2e6;
}

.fc-state-down, .fc-state-active, .fc-state-disabled {
  color: #fff;
  text-shadow: none;
  background-color: #727cf5;
}

.fc-unthemed .fc-today {
  background: #fff;
}

.fc-event {
  cursor: move;
  text-align: center;
  border: none;
  border-radius: 2px;
  margin: 5px 7px;
  padding: 5px;
  font-size: .8125rem;
}

.external-event {
  cursor: move;
  color: #fff;
  border-radius: 4px;
  margin: 10px 0;
  padding: 8px 10px;
}

.fc-basic-view td.fc-week-number span, .fc-basic-view td.fc-day-number {
  padding-right: 8px;
}

.fc-basic-view .fc-content, .fc-time-grid-event .fc-content {
  color: #fff;
}

.fc-content-skeleton .fc-day-top .fc-day-number {
  float: right;
  text-align: center;
  background-color: #f1f3fa;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 5px;
  font-size: 11px;
  line-height: 20px;
}

@media (width <= 767.98px) {
  .fc-toolbar {
    display: block !important;
  }

  .fc-toolbar .fc-left, .fc-toolbar .fc-right, .fc-toolbar .fc-center {
    float: none;
    clear: both;
    margin: 10px 0;
    display: block;
  }

  .fc .fc-toolbar > * > * {
    float: none;
  }

  .fc-today-button {
    display: none;
  }
}

.fc-toolbar .btn {
  --bs-btn-padding-y: .28rem;
  --bs-btn-padding-x: .8rem;
  --bs-btn-font-size: .875rem;
  --bs-btn-border-radius: .15rem;
}

.fc-list-item-title, .fc-list-item-time {
  color: #fff;
}

[dir="rtl"] .fc-toolbar .btn-group .btn:first-child {
  border-radius: .15rem;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

[dir="rtl"] .fc-toolbar .btn-group .btn:last-child {
  border-radius: .15rem;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.fc .fc-daygrid-day-number {
  z-index: 4;
  margin: 5px;
  font-size: 12px;
  position: relative;
}

body[data-layout-color="dark"] .fc th.fc-widget-header {
  background: #464f5b;
}

body[data-layout-color="dark"] .fc-unthemed th, body[data-layout-color="dark"] .fc-unthemed td, body[data-layout-color="dark"] .fc-unthemed thead, body[data-layout-color="dark"] .fc-unthemed tbody, body[data-layout-color="dark"] .fc-unthemed .fc-divider, body[data-layout-color="dark"] .fc-unthemed .fc-row, body[data-layout-color="dark"] .fc-unthemed .fc-popover {
  border-color: #464f5b;
}

body[data-layout-color="dark"] .fc-unthemed td.fc-today, body[data-layout-color="dark"] .fc-unthemed .fc-divider {
  background: #464f5b;
}

body[data-layout-color="dark"] .fc-button {
  color: #dee2e6;
  background: #464f5b;
}

body[data-layout-color="dark"] .fc-state-hover, body[data-layout-color="dark"] .fc-state-highlight, body[data-layout-color="dark"] .fc-cell-overlay {
  background: #464f5b;
}

body[data-layout-color="dark"] .fc-content-skeleton .fc-day-top .fc-day-number {
  background-color: #464f5b;
}

.fc-h-event, .fc-v-event {
  border: 1px solid #0000 !important;
}

.fc-daygrid-dot-event {
  padding: 5px !important;
}

.fc-daygrid-event-dot, .fc-list-event-dot {
  border: 4px solid #fff !important;
}

.fc-daygrid-event-dot:hover, .fc-list-event-dot:hover {
  border: 4px solid #6c757d !important;
}

.fc-list-event {
  color: #fff !important;
}

.fc-list-event:hover {
  color: #6c757d !important;
}

.fc .fc-list-table td, .fc .fc-list-day-cushion {
  padding: 8px 14px !important;
}

.fc-list-day-cushion {
  background-color: var(--ct-input-bg);
}

.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  min-height: 5em !important;
}

.chartjs-chart {
  width: 100%;
  margin: auto;
  position: relative;
}

.dataTables_wrapper.container-fluid, .dataTables_wrapper.container-sm, .dataTables_wrapper.container-md, .dataTables_wrapper.container-lg, .dataTables_wrapper.container-xl, .dataTables_wrapper.container-xxl {
  padding: 0;
}

table.dataTable {
  border-collapse: collapse !important;
  margin-bottom: 15px !important;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before, table.dataTable.dtr-inline.collapsed > tbody > tr th.dtr-control:before {
  background-color: #727cf5;
  box-shadow: 0 0 2px #727cf5;
}

table.dataTable thead:not(.table-light) th {
  background-color: #0000;
  border-bottom-color: #dee2e6;
}

table.dataTable tbody tr.even td, table.dataTable tbody tr.odd td {
  background-color: #0000;
}

table.dataTable tbody > tr.selected td {
  background-color: #727cf5;
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc_disabled:before {
  content: "󰍠";
  font-family: Material Design Icons;
  font-size: 1rem;
  top: 12px;
  left: auto;
  right: .5rem;
}

table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:after {
  content: "󰍝";
  font-family: Material Design Icons;
  font-size: 1rem;
  top: 18px;
  left: auto;
  right: .5em;
}

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting {
  padding-left: .95rem;
  padding-right: 30px;
}

table.dataTable tbody > tr.selected, table.dataTable tbody > tr > .selected {
  background-color: #727cf5;
}

table.dataTable tbody > tr.selected td, table.dataTable tbody > tr > .selected td {
  border-color: #727cf5;
}

table.dataTable tbody td:focus {
  outline: none !important;
}

table.dataTable tbody th.focus, table.dataTable tbody td.focus {
  outline-offset: -1px;
  background-color: #727cf526;
  outline: 2px solid #727cf5 !important;
}

.dataTables_info {
  font-weight: 600;
}

table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th:first-child:before {
  box-shadow: var(--ct-box-shadow-lg);
  background-color: #0acf97;
  top: auto;
  bottom: auto;
}

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before, table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  background-color: #fa5c7c;
}

div.dt-button-info {
  color: #fff;
  box-shadow: none;
  text-align: center;
  z-index: 21;
  background-color: #727cf5;
  border: none;
  border-radius: 3px;
}

div.dt-button-info h2, div.dt-button-info .h2 {
  color: #fff;
  background-color: #fff3;
  border-bottom: none;
}

@media (width <= 767.98px) {
  li.paginate_button.previous, li.paginate_button.next {
    font-size: 1.5rem;
    display: inline-block;
  }

  li.paginate_button {
    display: none;
  }

  .dataTables_paginate ul {
    text-align: center;
    display: block;
    margin: 1.5rem 0 0 !important;
  }

  div.dt-buttons {
    margin-bottom: 1.5rem;
    display: inline-table;
  }
}

.activate-select .sorting_1 {
  background-color: #f1f3fa;
}

div.dataTables_wrapper div.dataTables_filter {
  text-align: right;
}

@media (width <= 576px) {
  div.dataTables_wrapper div.dataTables_filter {
    text-align: center;
  }
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: .5em;
  margin-right: 0;
}

div.dataTables_wrapper div.dataTables_length {
  text-align: left;
}

@media (width <= 576px) {
  div.dataTables_wrapper div.dataTables_length {
    text-align: center;
  }
}

div.dataTables_scrollBody {
  border-left: none !important;
}

div.dataTables_scrollBody > table {
  margin-bottom: 15px !important;
}

div.dataTables_scrollBody > table > :not(:first-child) {
  border-top: none !important;
}

body[data-layout-color="dark"] table.dataTable th {
  border-bottom-color: #59616b;
}

body[data-layout-color="dark"] .activate-select .sorting_1 {
  background-color: #404954;
}

.noUi-target {
  box-shadow: none !important;
  background: #f1f3fa !important;
  border: 1px solid #eef2f7 !important;
  border-radius: 5px !important;
}

.noUi-horizontal {
  height: 10px !important;
}

.noUi-horizontal .noUi-handle {
  outline: none !important;
  width: 24px !important;
  height: 20px !important;
  top: -6px !important;
  right: -12px !important;
}

.noUi-handle {
  box-shadow: none !important;
  background: #fff !important;
  border: 1px solid #dee2e6 !important;
}

.noUi-handle:before, .noUi-handle:after {
  background: #ced4da !important;
  width: 2px !important;
  height: 10px !important;
  top: 4px !important;
  left: 9px !important;
}

.noUi-handle:after {
  left: 12px !important;
}

.noUi-connect {
  background: #727cf5 !important;
}

body[data-layout-color="dark"] .noUi-target {
  background: #404954 !important;
  border: 1px solid #37404a !important;
}

body[data-layout-color="dark"] .noUi-handle {
  border: 1px solid #464f5b !important;
}

body[data-layout-color="dark"] .noUi-handle:before, body[data-layout-color="dark"] .noUi-handle:after {
  background: #8391a2 !important;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  margin-left: -8px;
  position: absolute;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow:before, .react-datepicker__month-read-view--down-arrow:before, .react-datepicker__month-year-read-view--down-arrow:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  box-sizing: content-box;
  border: 8px solid #0000;
  width: 1px;
  height: 0;
  position: absolute;
}

.react-datepicker__year-read-view--down-arrow:before, .react-datepicker__month-read-view--down-arrow:before, .react-datepicker__month-year-read-view--down-arrow:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  content: "";
  z-index: -1;
  border-width: 8px;
  border-bottom-color: var(--ct-dropdown-border-color);
  left: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  margin-top: -8px;
  top: 0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-top: none;
  border-bottom-color: var(--ct-dropdown-bg);
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle:before {
  border-bottom-color: var(--ct-dropdown-border-color);
  top: -1px;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  margin-bottom: -8px;
  bottom: 0;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow:before, .react-datepicker__month-read-view--down-arrow:before, .react-datepicker__month-year-read-view--down-arrow:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before {
  border-top-color: #fff;
  border-bottom: none;
}

.react-datepicker__year-read-view--down-arrow:before, .react-datepicker__month-read-view--down-arrow:before, .react-datepicker__month-year-read-view--down-arrow:before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle:before {
  border-top-color: var(--ct-dropdown-border-color);
  bottom: -1px;
}

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker {
  background-color: var(--ct-dropdown-bg);
  color: #98a6ad;
  border: 1px solid var(--ct-dropdown-border-color);
  border-radius: .3rem;
  font-family: Nunito, sans-serif;
  font-size: .9rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time, .react-datepicker--time-only .react-datepicker__time-box {
  border-radius: .3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker__aria-live {
  display: none;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 10px;
}

.react-datepicker-popper[data-placement^="top"] {
  padding-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  padding-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  padding-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: var(--ct-dropdown-bg);
  border-bottom: 1px solid var(--ct-dropdown-border-color);
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll {
  margin: 0 2px;
  display: inline-block;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
  color: #98a6ad;
  margin-top: 0;
  font-size: .9rem;
  font-weight: 700;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  text-align: center;
  cursor: pointer;
  z-index: 1;
  text-indent: -999em;
  background: none;
  border: .45rem solid #0000;
  width: 10px;
  height: 10px;
  padding: 0;
  line-height: 1.5rem;
  position: absolute;
  top: 10px;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  border-right-color: #98a6ad;
  left: 10px;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #7c8d96;
}

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  cursor: default;
  border-right-color: #b4bfc4;
}

.react-datepicker__navigation--next {
  border-left-color: #98a6ad;
  right: 10px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 110px;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #7c8d96;
}

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
  cursor: default;
  border-left-color: #b4bfc4;
}

.react-datepicker__navigation--years {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
}

.react-datepicker__navigation--years-previous {
  border-top-color: #98a6ad;
  top: 4px;
}

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #7c8d96;
}

.react-datepicker__navigation--years-upcoming {
  border-bottom-color: #98a6ad;
  top: -4px;
}

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #7c8d96;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__month {
  text-align: center;
  margin: .4rem;
}

.react-datepicker__month .react-datepicker__month-text {
  width: 4rem;
  margin: 2px;
  display: inline-block;
}

.react-datepicker__input-time-container {
  clear: both;
  justify-content: center;
  align-items: center;
  margin: 5px 15px 10px;
  display: flex;
}

.react-datepicker__input-time-container .react-datepicker-time__caption, .react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  margin-left: 10px;
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  text-align: center;
  width: 85px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
  color: var(--ct-input-color);
  background-color: var(--ct-input-bg);
  border: 1px solid var(--ct-input-border-color);
  appearance: none;
  background-clip: padding-box;
  border-radius: .25rem;
  padding: .45rem .9rem;
  font-size: .9rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]:focus {
  color: var(--ct-input-focus-color);
  background-color: var(--ct-input-focus-bg);
  border-color: var(--ct-input-focus-border-color);
  box-shadow: none;
  outline: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid var(--ct-dropdown-border-color);
  width: 100px;
}

.react-datepicker__time-container--with-today-button {
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  display: inline;
  position: absolute;
  top: 0;
  right: -72px;
}

.react-datepicker__time-container .react-datepicker__time {
  background: var(--ct-dropdown-bg);
  position: relative;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  text-align: center;
  width: 100px;
  margin: 0 auto;
  overflow-x: hidden;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  box-sizing: content-box;
  width: 100%;
  height: calc(195px + .75rem);
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  list-style: none;
  overflow-y: scroll;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: var(--ct-link-hover-color);
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  color: #fff;
  background-color: #727cf5;
  font-weight: 700;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #727cf5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #98a6ad;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: #0000;
}

.react-datepicker__week-number {
  color: #98a6ad;
  text-align: center;
  width: 1.5rem;
  margin: .166rem;
  line-height: 1.5rem;
  display: inline-block;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  background-color: var(--ct-dropdown-bg);
  border-radius: .3rem;
}

.react-datepicker__day-names, .react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: #98a6ad;
  text-align: center;
  width: 1.5rem;
  margin: .166rem;
  line-height: 1.5rem;
  display: inline-block;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range {
  color: #fff;
  background-color: #727cf5;
  border-radius: .3rem;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover {
  background-color: #5a66f3;
}

.react-datepicker__month--disabled {
  color: #98a6ad;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover {
  cursor: default;
  background-color: #0000;
}

.react-datepicker__day, .react-datepicker__month-text {
  cursor: pointer;
}

.react-datepicker__day:hover, .react-datepicker__month-text:hover {
  background-color: #727cf5;
  border-radius: .3rem;
  color: #fff !important;
}

.react-datepicker__day--today, .react-datepicker__month-text--today {
  font-weight: 700;
}

.react-datepicker__day--highlighted, .react-datepicker__month-text--highlighted {
  color: #fff;
  background-color: #727cf5;
  border-radius: .3rem;
}

.react-datepicker__day--highlighted:hover, .react-datepicker__month-text--highlighted:hover {
  background-color: #5a66f3;
}

.react-datepicker__day--highlighted-custom-1, .react-datepicker__month-text--highlighted-custom-1 {
  color: #f0f;
}

.react-datepicker__day--highlighted-custom-2, .react-datepicker__month-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range {
  color: #fff;
  background-color: #727cf5;
  border-radius: .3rem;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover {
  background-color: #5a66f3;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected {
  color: #fff;
  background-color: #a2a8f8;
  border-radius: .3rem;
}

.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover {
  background-color: #5a66f3;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range) {
  background-color: #727cf580;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range), .react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range) {
  background-color: var(--ct-dropdown-bg);
  color: #98a6ad;
}

.react-datepicker__day--disabled, .react-datepicker__month-text--disabled {
  cursor: default;
  color: #98a6ad;
}

.react-datepicker__day--disabled:hover, .react-datepicker__month-text--disabled:hover {
  background-color: #0000;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover {
  background-color: #727cf5;
}

.react-datepicker__month-text:hover {
  background-color: var(--ct-dropdown-bg);
}

.react-datepicker__input-container {
  display: block;
  position: relative;
}

.react-datepicker__year-read-view, .react-datepicker__month-read-view, .react-datepicker__month-year-read-view {
  border: 1px solid #0000;
  border-radius: .3rem;
}

.react-datepicker__year-read-view:hover, .react-datepicker__month-read-view:hover, .react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #7c8d96;
}

.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow {
  float: right;
  border-width: .45rem;
  border-top-color: #98a6ad;
  margin-left: 20px;
  position: relative;
  top: 8px;
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
  background-color: var(--ct-dropdown-bg);
  z-index: 1;
  text-align: center;
  border: 1px solid var(--ct-dropdown-border-color);
  border-radius: .3rem;
  width: 50%;
  position: absolute;
  top: 30px;
  left: 25%;
}

.react-datepicker__year-dropdown:hover, .react-datepicker__month-dropdown:hover, .react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable, .react-datepicker__month-dropdown--scrollable, .react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  line-height: 20px;
  display: block;
}

.react-datepicker__year-option:first-of-type, .react-datepicker__month-option:first-of-type, .react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem;
}

.react-datepicker__year-option:last-of-type, .react-datepicker__month-option:last-of-type, .react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  user-select: none;
  border-bottom-right-radius: .3rem;
  border-bottom-left-radius: .3rem;
}

.react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover {
  background-color: #98a6ad;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #7c8d96;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous, .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #7c8d96;
}

.react-datepicker__year-option--selected, .react-datepicker__month-option--selected, .react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  vertical-align: middle;
  background-color: #0000;
  border: 0;
  outline: 0;
  width: 16px;
  height: 16px;
  padding: 0;
  position: absolute;
  top: 25%;
  right: 7px;
}

.react-datepicker__close-icon:after {
  box-sizing: border-box;
  color: #fff;
  content: "×";
  cursor: pointer;
  text-align: center;
  background-color: #727cf5;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  margin: -8px auto 0;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  position: absolute;
  bottom: 0;
  right: 0;
}

.react-datepicker__today-button {
  background: var(--ct-dropdown-bg);
  border-top: 1px solid var(--ct-dropdown-border-color);
  cursor: pointer;
  text-align: center;
  clear: left;
  padding: 5px 0;
  font-weight: 700;
}

.react-datepicker__portal {
  z-index: 2147483647;
  background-color: #000c;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (width <= 400px), (height <= 550px) {
  .react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month, .react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.62rem;
}

.react-datepicker__portal .react-datepicker__navigation {
  border: .81rem solid #0000;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #98a6ad;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #7c8d96;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  cursor: default;
  border-right-color: #b4bfc4;
}

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #98a6ad;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #7c8d96;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  cursor: default;
  border-left-color: #b4bfc4;
}

.react-datepicker__day:focus {
  outline: none !important;
}

.calendar-widget > div, .calendar-widget .react-datepicker, .calendar-widget .react-datepicker .react-datepicker__month-container {
  width: 100%;
}

.calendar-widget .react-datepicker .react-datepicker__month-container .react-datepicker__current-month {
  font-size: 1.1rem;
}

.calendar-widget .react-datepicker .react-datepicker__month-container .react-datepicker__day-names, .calendar-widget .react-datepicker .react-datepicker__month-container .react-datepicker__week {
  justify-content: space-evenly;
  align-items: center;
  height: 52px;
  display: flex;
}

body[data-layout-color="dark"] .react-datepicker__navigation--previous:hover {
  border-right-color: #b1bbc4;
}

body[data-layout-color="dark"] .react-datepicker__navigation--next:hover {
  border-left-color: #b1bbc4;
}

body[data-layout-color="dark"] .react-datepicker__navigation--years-previous:hover {
  border-top-color: #b1bbc4;
}

body[data-layout-color="dark"] .react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b1bbc4;
}

body[data-layout-color="dark"] .react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow, body[data-layout-color="dark"] .react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow, body[data-layout-color="dark"] .react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow, body[data-layout-color="dark"] .react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow, body[data-layout-color="dark"] .react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow, body[data-layout-color="dark"] .react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b1bbc4;
}

body[data-layout-color="dark"] .react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming, body[data-layout-color="dark"] .react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming, body[data-layout-color="dark"] .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b1bbc4;
}

body[data-layout-color="dark"] .react-datepicker__year-option:hover .react-datepicker__navigation--years-previous, body[data-layout-color="dark"] .react-datepicker__month-option:hover .react-datepicker__navigation--years-previous, body[data-layout-color="dark"] .react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b1bbc4;
}

body[data-layout-color="dark"] .react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #b1bbc4;
}

body[data-layout-color="dark"] .react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #b1bbc4;
}

.bg-dragula {
  background-color: var(--ct-dragula-bg);
}

.gu-mirror {
  opacity: .8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity= 80);
  z-index: 9999 !important;
  margin: 0 !important;
  position: fixed !important;
}

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.gu-transit {
  opacity: .2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity= 20);
}

.dragula-handle {
  text-align: center;
  cursor: move;
  width: 36px;
  height: 36px;
  font-size: 24px;
  position: relative;
}

.dragula-handle:before {
  content: "󰇛";
  font-family: Material Design Icons;
  position: absolute;
}

.dropzone {
  border: 2px dashed var(--ct-input-border-color);
  background: var(--ct-input-bg);
  cursor: pointer;
  border-radius: 6px;
  min-height: 150px;
  padding: 20px;
}

.dropzone .dz-message {
  text-align: center;
  margin: 2rem 0;
}

.dropzone.dz-started .dz-message {
  display: none;
}

.form-wizard-header {
  background-color: var(--ct-form-wizard-header-bg);
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

svg {
  touch-action: none;
}

image, text, .jvm-zoomin, .jvm-zoomout {
  -webkit-user-select: none;
  user-select: none;
}

.jvm-container {
  touch-action: none;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.jvm-tooltip {
  white-space: nowrap;
  color: #f1f3fa;
  background-color: #343a40;
  border: none;
  border-radius: 3px;
  padding: 5px 8px;
  font-family: Nunito, sans-serif;
  font-size: .9rem;
  display: none;
  position: absolute;
  box-shadow: 1px 2px 12px #0003;
}

.jvm-zoom-btn {
  box-sizing: border-box;
  cursor: pointer;
  color: #fff;
  background-color: #343a40;
  border-radius: 3px;
  width: 15px;
  height: 15px;
  padding: 3px;
  line-height: 10px;
  position: absolute;
  left: 10px;
}

.jvm-zoom-btn.jvm-zoomout {
  top: 30px;
}

.jvm-zoom-btn.jvm-zoomin {
  top: 10px;
}

.jvm-series-container {
  position: absolute;
  right: 15px;
}

.jvm-series-container.jvm-series-h {
  bottom: 15px;
}

.jvm-series-container.jvm-series-v {
  top: 15px;
}

.jvm-series-container .jvm-legend {
  float: left;
  background-color: #fff;
  border: 1px solid #e5e7eb;
  border-radius: .25rem;
  margin-left: .75rem;
  padding: .6rem;
  box-shadow: 0 1px 2px #0000000d;
}

.jvm-series-container .jvm-legend .jvm-legend-title {
  text-align: left;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: .575rem;
  padding-bottom: .5rem;
  line-height: 1;
}

.jvm-series-container .jvm-legend .jvm-legend-inner {
  overflow: hidden;
}

.jvm-series-container .jvm-legend .jvm-legend-inner .jvm-legend-tick {
  min-width: 40px;
  overflow: hidden;
}

.jvm-series-container .jvm-legend .jvm-legend-inner .jvm-legend-tick:not(:first-child) {
  margin-top: .575rem;
}

.jvm-series-container .jvm-legend .jvm-legend-inner .jvm-legend-tick .jvm-legend-tick-sample {
  float: left;
  border-radius: 4px;
  width: 16px;
  height: 16px;
  margin-right: .65rem;
}

.jvm-series-container .jvm-legend .jvm-legend-inner .jvm-legend-tick .jvm-legend-tick-text {
  text-align: center;
  float: left;
  font-size: 12px;
}

.jvm-line[animation="true"] {
  animation: 10s linear infinite forwards jvm-line-animation;
}

@keyframes jvm-line-animation {
  0% {
    stroke-dashoffset: 250px;
  }
}

.gmaps, .gmaps-panaroma {
  background: #f1f3fa;
  border-radius: 3px;
  height: 300px;
}

body[data-layout-color="dark"] .gmaps, body[data-layout-color="dark"] .gmaps-panaroma {
  background: #404954;
}

.ql-editor {
  text-align: left;
}

.ql-container {
  font-family: Nunito, sans-serif;
}

.ql-container.ql-snow {
  border-color: var(--ct-input-border-color);
}

.ql-bubble {
  border: 1px solid var(--ct-input-border-color);
  border-radius: .25rem;
}

.ql-toolbar {
  font-family: Nunito, sans-serif !important;
}

.ql-toolbar span {
  color: var(--ct-dropdown-link-color);
  outline: none !important;
}

.ql-toolbar span:hover {
  color: #727cf5 !important;
}

.ql-toolbar.ql-snow {
  border-color: var(--ct-input-border-color);
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: #0000;
}

.ql-snow .ql-stroke, .ql-snow .ql-script, .ql-snow .ql-strike svg {
  stroke: var(--ct-dropdown-link-color);
}

.ql-snow .ql-fill {
  fill: var(--ct-dropdown-link-color);
}

.ql-snow .ql-picker-options {
  background-color: var(--ct-dropdown-bg);
  border-color: var(--ct-dropdown-border-color) !important;
}

.select2-container {
  width: 100% !important;
}

.select2-container .select2-selection--single {
  border: 1px solid var(--ct-input-border-color);
  background-color: var(--ct-input-bg);
  outline: none;
  height: calc(1.5em + .9rem + 2px);
}

.select2-container .select2-selection--single .select2-selection__rendered {
  color: var(--ct-input-color);
  padding-left: 12px;
  line-height: 36px;
}

.select2-container .select2-selection--single .select2-selection__arrow {
  width: 34px;
  height: 34px;
  right: 3px;
}

.select2-container .select2-selection--single .select2-selection__arrow b {
  border-width: 6px 6px 0;
  border-color: #ced4da #0000 #0000;
}

.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-width: 0 6px 6px !important;
  border-color: #0000 #0000 #ced4da !important;
}

.select2-results__option {
  padding: 6px 12px;
}

.select2-dropdown {
  border: 1px solid var(--ct-dropdown-border-color);
  background-color: var(--ct-dropdown-bg);
  z-index: 1056;
  box-shadow: 0 1px 2px #0000000d;
}

.select2-container--default .select2-results__option--selected {
  background-color: #eef2f7;
}

.select2-container--default .select2-search--dropdown {
  background-color: var(--ct-dropdown-bg);
  padding: 10px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid var(--ct-input-border-color);
  background-color: var(--ct-input-bg);
  color: var(--ct-input-color);
  outline: none;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #727cf5;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: var(--ct-dropdown-bg);
  color: var(--ct-dropdown-link-active-color);
}

.select2-container--default .select2-results__option[aria-selected="true"]:hover {
  color: #fff;
  background-color: #727cf5;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 36px;
  padding-right: 5px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  left: auto;
  right: 1px;
}

.select2-container .select2-selection--multiple {
  background-color: var(--ct-input-bg);
  min-height: calc(1.5em + .9rem + 2px);
  border: 1px solid var(--ct-input-border-color) !important;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  padding: 1px 4px;
}

.select2-container .select2-selection--multiple .select2-search__field {
  color: var(--ct-input-color);
  border: 0;
}

.select2-container .select2-selection--multiple .select2-selection__choice {
  color: #fff;
  background-color: #727cf5;
  border: none;
  border-radius: 3px;
  margin-top: 6px;
  padding: 0 7px 0 0;
}

.select2-container .select2-selection--multiple .select2-selection__choice__remove {
  color: #fff;
  border-color: #8089f6;
  margin-right: 7px;
  padding: 0 8px;
}

.select2-container .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #fff;
  background-color: #727cf5;
}

.select2-container .select2-search--inline .select2-search__field {
  margin-top: 7px;
}

.select2-container .select2-search textarea::placeholder {
  color: var(--ct-input-placeholder-color);
}

[dir="rtl"] .select2-container--open .select2-dropdown {
  left: auto;
  right: 0;
}

body[data-layout-color="dark"] .select2-container .select2-selection--single .select2-selection__arrow b {
  border-color: #8391a2 #0000 #0000;
}

body[data-layout-color="dark"] .select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: #0000 #0000 #8391a2 !important;
}

body[data-layout-color="dark"] .select2-container--default .select2-results__option--selected {
  background-color: #464f5b;
}

[data-simplebar] {
  flex-flow: column wrap;
  place-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  position: relative;
}

.simplebar-wrapper {
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
  overflow: hidden;
}

.simplebar-mask {
  direction: inherit;
  z-index: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  inset: 0;
  overflow: hidden;
  width: auto !important;
  height: auto !important;
}

.simplebar-offset {
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
  position: absolute;
  inset: 0;
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
}

.simplebar-content-wrapper {
  direction: inherit;
  scrollbar-width: none;
  width: auto;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  display: block;
  position: relative;
  overflow: auto;
  box-sizing: border-box !important;
}

.simplebar-content-wrapper::-webkit-scrollbar {
  display: none;
}

.simplebar-hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.simplebar-content:before, .simplebar-content:after {
  content: " ";
  display: table;
}

.simplebar-placeholder {
  pointer-events: none;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

.simplebar-height-auto-observer-wrapper {
  float: left;
  z-index: -1;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
  width: 100%;
  max-width: 1px;
  height: 100%;
  max-height: 1px;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  box-sizing: inherit !important;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  width: 1000%;
  min-width: 1px;
  height: 1000%;
  min-height: 1px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.simplebar-track {
  z-index: 1;
  pointer-events: none;
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  width: 5px;
  min-height: 10px;
  position: absolute;
  right: 2px;
}

.simplebar-scrollbar:before {
  content: "";
  opacity: 0;
  background: #a2adb7;
  border-radius: 7px;
  transition: opacity .2s linear;
  position: absolute;
  left: 0;
  right: 0;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: .5;
  transition: opacity linear;
}

.simplebar-track.simplebar-vertical {
  width: 11px;
  top: 0;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  height: 11px;
  left: 0;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  width: auto;
  min-width: 10px;
  height: 7px;
  min-height: 0;
  top: 2px;
  left: 0;
  right: auto;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
  left: 0;
  right: auto;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  opacity: 0;
  visibility: hidden;
  width: 500px;
  height: 500px;
  position: fixed;
  overflow: scroll hidden;
}

.simplebar-hide-scrollbar {
  visibility: hidden;
  scrollbar-width: none;
  position: fixed;
  left: 0;
  overflow-y: scroll;
}

.custom-scroll {
  height: 100%;
}

[data-simplebar-lg] .simplebar-scrollbar {
  width: 10px;
  right: 1px;
}

[data-simplebar-primary] .simplebar-scrollbar:before {
  background: #727cf5;
}

.jq-toast-single {
  background-color: #727cf5;
  padding: 15px;
  font-family: Nunito, sans-serif;
  font-size: 13px;
  line-height: 22px;
  text-align: left !important;
}

.jq-toast-single h2, .jq-toast-single .h2 {
  font-family: Nunito, sans-serif;
}

.jq-toast-single a {
  font-size: .9rem;
}

.jq-toast-single a:hover {
  color: #fff;
}

.jq-has-icon {
  padding: 10px;
}

.close-jq-toast-single {
  cursor: pointer;
  color: #f1f3fa;
  text-align: center;
  background: #343a40;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  font-size: 20px;
  line-height: 32px;
  position: absolute;
  top: -12px;
  right: -12px;
}

.jq-toast-loader {
  border-radius: 0;
  height: 3px;
  top: 0;
}

.jq-icon-primary {
  color: #fff;
  background-color: #727cf5;
  border-color: #727cf5;
}

.jq-icon-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.jq-icon-success {
  color: #fff;
  background-color: #0acf97;
  border-color: #0acf97;
}

.jq-icon-info {
  color: #fff;
  background-color: #39afd1;
  border-color: #39afd1;
}

.jq-icon-warning {
  color: #fff;
  background-color: #ffbc00;
  border-color: #ffbc00;
}

.jq-icon-danger {
  color: #fff;
  background-color: #fa5c7c;
  border-color: #fa5c7c;
}

.jq-icon-light {
  color: #fff;
  background-color: #eef2f7;
  border-color: #eef2f7;
}

.jq-icon-dark {
  color: #fff;
  background-color: #313a46;
  border-color: #313a46;
}

.jq-icon-error {
  color: #fff;
  background-color: #fa5c7c;
  border-color: #fa5c7c;
}

.jq-icon-info, .jq-icon-warning, .jq-icon-error, .jq-icon-success {
  background-image: none;
}

body[data-layout-color="dark"] .close-jq-toast-single {
  color: #404954;
  background: #e3eaef;
}

.bootstrap-touchspin .btn .input-group-text {
  color: inherit;
  background-color: #0000;
  border: none;
  padding: 0;
}

.bootstrap-timepicker-widget table td input {
  color: #fff;
  background-color: #727cf5;
  border: 0;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  outline: none !important;
}

.bootstrap-timepicker-widget table td a {
  color: #6c757d;
}

.bootstrap-timepicker-widget table td a:hover {
  color: #727cf5;
  background-color: #0000;
  border: 1px solid #0000;
}

.bootstrap-timepicker-widget.dropdown-menu:before {
  border-bottom: 7px solid var(--ct-dropdown-border-color);
  border-left: 7px solid #0000;
  border-right: 7px solid #0000;
}

.bootstrap-timepicker-widget.dropdown-menu:after {
  border-bottom: 6px solid var(--ct-dropdown-bg);
  border-left: 6px solid #0000;
  border-right: 6px solid #0000;
}

.bootstrap-timepicker-widget.timepicker-orient-bottom:after {
  border-bottom: 0;
  border-top: 6px solid var(--ct-dropdown-bg);
  bottom: -6px;
}

.bootstrap-timepicker-widget.timepicker-orient-bottom:before {
  border-bottom: 0;
  border-top: 7px solid var(--ct-dropdown-border-color);
  bottom: -7px;
}

body[data-layout-color="dark"] .bootstrap-timepicker-widget table td a {
  color: #dee2e6;
}

.editor-toolbar.fullscreen, .CodeMirror-fullscreen {
  z-index: 1000;
}

.editor-preview, .editor-preview-side {
  background: #dee2e6;
}

.editor-preview-active {
  background: #e5e8eb;
}

.editor-toolbar {
  border-bottom: 0;
  border: 1px solid var(--ct-input-border-color) !important;
}

.editor-toolbar a {
  color: #6c757d !important;
}

.editor-toolbar a:hover, .editor-toolbar a.active {
  background-color: #0000;
  border-color: #0000;
  color: #727cf5 !important;
}

.editor-toolbar button:hover {
  background-color: var(--ct-input-bg) !important;
  border-color: var(--ct-input-border-color) !important;
}

.editor-toolbar i.separator {
  display: none !important;
}

.editor-toolbar.disabled-for-preview a:not(.no-disable) {
  background: none;
}

.CodeMirror {
  min-height: 100px;
  border: 1px solid var(--ct-input-border-color) !important;
  background: var(--ct-input-bg) !important;
  color: var(--ct-input-color) !important;
}

.CodeMirror-cursor {
  border-left: 1px solid #343a40;
}

.editor-statusbar {
  color: #343a40;
}

body[data-layout-color="dark"] .editor-preview, body[data-layout-color="dark"] .editor-preview-side {
  background: #464f5b;
}

body[data-layout-color="dark"] .editor-preview-active {
  background: #4c5562;
}

body[data-layout-color="dark"] .editor-toolbar a {
  color: #dee2e6 !important;
}

body[data-layout-color="dark"] .CodeMirror-cursor {
  border-left: 1px solid #e3eaef;
}

body[data-layout-color="dark"] .editor-statusbar {
  color: #e3eaef;
}

.twitter-typeahead {
  display: inherit !important;
}

.tt-query, .tt-hint {
  outline: none;
}

.tt-query {
  box-shadow: inset 0 1px 1px #00000013;
}

.tt-hint {
  color: var(--ct-input-color);
  background: var(--ct-input-bg) !important;
}

.tt-menu {
  background-color: var(--ct-dropdown-bg);
  border: 1px solid var(--ct-dropdown-border-color);
  width: 100%;
  max-height: 200px;
  padding: 8px 0;
  overflow-y: auto;
  box-shadow: 0 1px 2px #0000000d;
}

.tt-suggestion {
  padding: 3px 20px;
  line-height: 24px;
}

.tt-suggestion:hover, .tt-suggestion.tt-cursor {
  cursor: pointer;
  color: #fff;
  background-color: #727cf5;
}

.tt-suggestion p {
  margin: 0;
}

.tt-highlight {
  font-family: Nunito, sans-serif;
}

.typeahead-empty-message {
  color: #fa5c7c;
  padding: 5px 10px;
}

.league-name {
  padding: 3px 20px;
}

.jqstooltip {
  box-sizing: content-box;
  box-shadow: var(--ct-box-shadow-lg);
  border-radius: 3px;
  background-color: #fff !important;
  border-color: #fff !important;
  width: auto !important;
  height: auto !important;
  padding: 5px 10px !important;
}

.jqsfield {
  color: #000 !important;
  font-family: Nunito, sans-serif !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 18px !important;
}

.timepicker-orient-bottom {
  top: auto !important;
  bottom: calc(1.5em + .9rem + 2px) !important;
}

.bootstrap-timepicker-widget {
  left: 0 !important;
  right: auto !important;
}

.bootstrap-timepicker-widget.timepicker-orient-left:before {
  left: 6px;
  right: auto;
}

.bootstrap-timepicker-widget.timepicker-orient-left:after {
  left: 7px;
  right: auto;
}

.rateit {
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  display: inline-block;
  position: relative;
}

.rateit .rateit-range * {
  display: block;
}

.rateit .rateit-hover, .rateit .rateit-selected {
  width: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.rateit .rateit-hover-rtl, .rateit .rateit-selected-rtl {
  left: auto;
  right: 0;
}

.rateit .rateit-hover {
  color: #ffbc00;
}

.rateit .rateit-hover-rtl {
  background-position: right -32px;
}

.rateit .rateit-selected {
  color: #fa5c7c;
}

.rateit .rateit-selected-rtl {
  background-position: right -16px;
}

.rateit .rateit-preset {
  color: #fd7e14;
}

.rateit button.rateit-reset {
  float: left;
  border: none;
  outline: none;
  width: 16px;
  height: 16px;
  padding: 0;
}

.rateit .rateit-reset span {
  display: none;
}

.rateit .rateit-range {
  outline: none;
  height: 16px;
  display: inline-block;
  position: relative;
}

.rateit.rateit-font .rateit-reset {
  background: #dee2e6;
  border-radius: 50%;
  width: .6em;
  height: .6em;
  margin-top: 5px;
  margin-right: 5px;
  position: relative;
}

.rateit.rateit-font .rateit-reset span {
  border-bottom: 2px solid #6c757d;
  width: 50%;
  height: calc(50% - .045em);
  margin-left: 25%;
  margin-right: 25%;
  display: block;
  position: absolute;
  top: 2px;
}

.rateit.rateit-font .rateit-reset:hover, .rateit.rateit-font button.rateit-reset:focus {
  background: #fa5c7c;
}

.rateit.rateit-font .rateit-reset:hover span, .rateit.rateit-font button.rateit-reset:focus span {
  border-color: #fff;
}

.rateit-mdi {
  font-family: Material Design Icons;
}

.rateit-font {
  font-size: 24px;
  line-height: 1em;
}

.rateit-font .rateit-range {
  background: none;
  height: auto;
}

.rateit-font .rateit-range > div {
  cursor: default;
  white-space: nowrap;
  background: none;
  overflow: hidden;
}

.rateit-font .rateit-empty {
  color: #ced4da;
}

body[data-layout-color="dark"] .rateit.rateit-font .rateit-reset {
  background: #464f5b;
}

body[data-layout-color="dark"] .rateit.rateit-font .rateit-reset span {
  border-bottom: 2px solid #dee2e6;
}

body[data-layout-color="dark"] .rateit-font .rateit-empty {
  color: #8391a2;
}

.rateit .rateit-hover-rtl, .rateit .rateit-selected-rtl {
  right: auto;
}

.react-select > div {
  border: 1px solid var(--ct-input-border-color);
  background-color: var(--ct-input-bg) !important;
}

.react-select > div:hover {
  border: 1px solid var(--ct-input-border-color);
}

.react-select--is-disabled .react-select__control {
  color: var(--ct-form-select-disabled-color) !important;
  background-color: var(--ct-form-select-disabled-bg) !important;
}

.react-select__control--is-focused {
  border-color: var(--ct-input-focus-border-color) !important;
  box-shadow: none !important;
}

.react-select__indicator-separator {
  display: none !important;
}

.react-select__dropdown-indicator {
  color: #98a6ad !important;
}

.react-select__menu {
  background-color: var(--ct-dropdown-bg) !important;
  box-shadow: none !important;
  z-index: 1000 !important;
  border: none !important;
  border-radius: .25rem !important;
}

.react-select__menu-list {
  border: 1px solid var(--ct-dropdown-border-color) !important;
  border-radius: .25rem !important;
}

.react-select__option {
  color: var(--ct-dropdown-link-color) !important;
  padding: .375rem 1.5rem !important;
}

.react-select__option:hover, .react-select__option--is-focused, .react-select__option--is-selected {
  color: var(--ct-dropdown-link-hover-color) !important;
  background-color: var(--ct-dropdown-link-hover-bg) !important;
}

.react-select__single-value {
  color: #98a6ad !important;
}

.react-select__multi-value {
  color: #727cf5 !important;
  background-color: #727cf533 !important;
}

.react-select__multi-value .react-select__multi-value__label {
  color: var(--ct-input-color);
}

.react-select__multi-value .react-select__multi-value__remove:hover, .rbt-token {
  color: #727cf5 !important;
  background-color: #727cf533 !important;
}

.rbt-token .rbt-token-remove-button {
  background-color: #0000;
  border: none;
}

.rbt-token .rbt-token-remove-button .sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.rbt-input-multi.focus {
  box-shadow: none !important;
  background-color: var(--ct-input-focus-bg) !important;
  border-color: var(--ct-input-focus-border-color) !important;
}

.app-search .react-select__menu-list .react-select__option {
  color: #6c757d;
  background-color: #0000;
  padding: .375rem 1.5rem;
}

.app-search .react-select__menu-list .react-select__option .dropdown-item.active, .app-search .react-select__menu-list .react-select__option .dropdown-item:active {
  background-color: #0000;
}

.app-search .react-select__menu-list .react-select__option:hover {
  color: #313a46;
  background-color: var(--ct-dropdown-link-hover-bg);
}

.app-search .react-select__menu-list .react-select__option--is-selected {
  background-color: var(--ct-dropdown-link-hover-bg);
}

.app-search .react-select__menu-list .react-select__option--is-selected .dropdown-item {
  color: #313a46 !important;
}

.app-search .react-select__menu-list .react-select__option--is-disabled:hover {
  background-color: #0000 !important;
}

.app-search .react-select__value-container--has-value .react-select__single-value i, .app-search .react-select__value-container--has-value .react-select__single-value img, .app-search .react-select__value-container--has-value .react-select__single-value .user-subinfo {
  display: none !important;
}

.app-search .react-select__value-container--has-value .react-select__single-value .dropdown-item, .app-search .react-select__value-container--has-value .react-select__single-value .dropdown-item .drop-username {
  color: #98a6ad !important;
}

.app-search .react-select__value-container--has-value .react-select__single-value .dropdown-item:hover {
  color: var(--ct-dropdown-link-color);
  background-color: #0000;
}

.app-search .react-select__value-container--has-value .react-select__single-value .dropdown-item, .app-search .react-select__value-container--has-value .react-select__single-value .dropdown-item .drop-username {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 110px;
  overflow: hidden;
}

body[data-topbar-color="light"] .app-search .react-select__value-container--has-value .react-select__single-value .dropdown-item, body[data-topbar-color="light"] .app-search .react-select__value-container--has-value .react-select__single-value .drop-username {
  color: #313a46 !important;
}

body[data-layout-color="dark"] .react-select__dropdown-indicator, body[data-layout-color="dark"] .react-select__single-value {
  color: #ced4da !important;
}

body[data-layout-color="dark"] .app-search .react-select__menu-list .react-select__option {
  color: #dee2e6;
}

body[data-layout-color="dark"] .app-search .react-select__value-container--has-value .react-select__single-value .dropdown-item, body[data-layout-color="dark"] .app-search .react-select__value-container--has-value .react-select__single-value .dropdown-item .drop-username {
  color: #ced4da !important;
}

.react-select__control {
  background-color: var(--ct-bg-dark-topbar-search);
  color: var(--ct-input-color) !important;
}

.react-select__input {
  color: var(--ct-input-color) !important;
}

.react-select__placeholder {
  color: #98a6ad;
}

.react-table th, .react-table td {
  white-space: nowrap;
}

.react-table th {
  padding-left: .95rem;
  padding-right: 30px;
}

.react-table .sortable {
  cursor: pointer;
  position: relative;
}

.react-table .sortable:before {
  content: "󰍠";
  opacity: .5;
  font-family: Material Design Icons;
  font-size: 1rem;
  position: absolute;
  top: 12px;
  left: auto;
  right: .5rem;
}

.react-table .sortable:after {
  content: "󰍝";
  opacity: .5;
  font-family: Material Design Icons;
  font-size: 1rem;
  position: absolute;
  top: 18px;
  left: auto;
  right: .5em;
}

.react-table .sortable.sorting_asc:before, .react-table .sortable.sorting_desc:after {
  opacity: 1;
}

.irs {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  font-size: 12px;
  display: block;
  position: relative;
}

.irs-line {
  display: block;
  position: relative;
  overflow: hidden;
  outline: none !important;
}

.irs-bar {
  width: 0;
  display: block;
  position: absolute;
  left: 0;
}

.irs-shadow {
  width: 0;
  display: none;
  position: absolute;
  left: 0;
}

.irs-handle {
  box-sizing: border-box;
  cursor: default;
  z-index: 1;
  display: block;
  position: absolute;
}

.irs-handle.type_last {
  z-index: 2;
}

.irs-min, .irs-max {
  cursor: default;
  display: block;
  position: absolute;
}

.irs-min {
  left: 0;
  right: auto;
}

.irs-max, [dir="rtl"] .irs-min {
  left: auto;
  right: 0;
}

[dir="rtl"] .irs-max {
  left: 0;
  right: auto;
}

.irs-from, .irs-to, .irs-single {
  cursor: default;
  white-space: nowrap;
  display: block;
  position: absolute;
  top: 0;
}

.irs-grid {
  width: 100%;
  height: 20px;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

.irs-with-grid .irs-grid {
  display: block;
}

.irs-grid-pol {
  background: #6c757d;
  width: 1px;
  height: 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.irs-grid-pol.small {
  height: 4px;
}

.irs-grid-text {
  white-space: nowrap;
  text-align: center;
  color: #6c757d;
  padding: 0 3px;
  font-size: 9px;
  line-height: 9px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.irs-disable-mask {
  cursor: default;
  z-index: 2;
  width: 102%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: -1%;
}

.lt-ie9 .irs-disable-mask {
  filter: alpha(opacity= 0);
  cursor: not-allowed;
  background: #6c757d;
}

.irs-disabled {
  opacity: .4;
}

.irs-hidden-input {
  overflow: hidden;
  z-index: -9999 !important;
  background: none !important;
  border-style: solid !important;
  border-color: #0000 !important;
  outline: none !important;
  width: 0 !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 0 !important;
  line-height: 0 !important;
  display: block !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.irs--flat {
  height: 40px;
}

.irs--flat.irs-with-grid {
  height: 60px;
}

.irs--flat .irs-line {
  background-color: var(--ct-progress-bg);
  border-radius: 4px;
  height: 12px;
  top: 25px;
}

.irs--flat .irs-bar {
  background-color: #727cf5;
  height: 12px;
  top: 25px;
}

.irs--flat .irs-bar--single {
  border-radius: 4px 0 0 4px;
}

.irs--flat .irs-shadow {
  background-color: var(--ct-progress-bg);
  height: 1px;
  bottom: 16px;
}

.irs--flat .irs-handle {
  background-color: #0000;
  width: 16px;
  height: 18px;
  top: 22px;
}

.irs--flat .irs-handle > i:first-child {
  background-color: #515df3;
  width: 2px;
  height: 100%;
  margin-left: -1px;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
}

.irs--flat .irs-min, .irs--flat .irs-max {
  color: #6c757d;
  text-shadow: none;
  background-color: var(--ct-progress-bg);
  border-radius: 4px;
  padding: 1px 3px;
  font-size: 10px;
  line-height: 1.333;
  top: 0;
}

.irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single {
  color: #fff;
  text-shadow: none;
  background-color: #727cf5;
  border-radius: 4px;
  padding: 1px 5px;
  font-size: 10px;
  line-height: 1.333;
}

.irs--flat .irs-from:before, .irs--flat .irs-to:before, .irs--flat .irs-single:before {
  content: "";
  border: 3px solid #0000;
  border-top-color: #727cf5;
  width: 0;
  height: 0;
  margin-left: -3px;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 50%;
  overflow: hidden;
}

.irs--flat .irs-grid-pol {
  background-color: var(--ct-progress-bg);
}

.irs--flat .irs-grid-text {
  color: #6c757d;
}

[dir="rtl"] .irs-bar--single {
  border-radius: 0 4px 4px 0 !important;
}

body[data-layout-color="dark"] .irs--flat .irs-min, body[data-layout-color="dark"] .irs--flat .irs-max, body[data-layout-color="dark"] .irs-grid-text {
  color: #dee2e6;
}

.jstree-default {
  height: auto;
  padding: 2px 6px;
}

.jstree-default .jstree-clicked, .jstree-default .jstree-hovered {
  box-shadow: none;
  background: #eef2f7;
}

.jstree-default .jstree-anchor, .jstree-default .jstree-icon, .jstree-default .jstree-icon:empty {
  line-height: 20px;
}

.jstree-wholerow.jstree-wholerow-clicked, .jstree-wholerow.jstree-wholerow-hovered {
  background: #eef2f7;
}

body[data-layout-color="dark"] .jstree-default .jstree-clicked, body[data-layout-color="dark"] .jstree-default .jstree-hovered, body[data-layout-color="dark"] .jstree-wholerow.jstree-wholerow-clicked, body[data-layout-color="dark"] .jstree-wholerow.jstree-wholerow-hovered {
  background: #464f5b;
}

.gantt .bar-progress {
  fill: #727cf5 !important;
}

.gantt .bar-wrapper:hover .bar-progress {
  fill: #8a92f7 !important;
}

.gantt .bar, .gantt .bar-wrapper:hover .bar {
  fill: var(--ct-progress-bg) !important;
}

.gantt .bar-label, .gantt .bar-label.big {
  fill: #6c757d !important;
}

.gantt .grid-header {
  fill: var(--ct-table-hover-bg) !important;
  stroke: var(--ct-table-hover-bg) !important;
}

.gantt .grid-row {
  fill: #0000 !important;
}

.gantt .grid-row:nth-child(2n) {
  fill: var(--ct-table-hover-bg) !important;
}

.gantt .lower-text, .gantt .upper-text {
  fill: var(--ct-table-color) !important;
}

.gantt .row-line {
  stroke: var(--ct-table-border-color) !important;
}

.gantt .today-highlight {
  fill: #eef2f7 !important;
}

.gantt-container .popup-wrapper .pointer {
  display: none;
}

body[data-layout-color="dark"] .gantt .bar-label, body[data-layout-color="dark"] .gantt .bar-label.big {
  fill: #dee2e6 !important;
}

body[data-layout-color="dark"] .gantt .today-highlight {
  fill: #464f5b !important;
}

.circle-loader {
  vertical-align: top;
  border: 1px solid #0003;
  border-left-color: #0acf97;
  border-radius: 50%;
  width: 7em;
  height: 7em;
  animation: 1.2s linear infinite loader-spin;
  display: inline-block;
  position: relative;
}

.circle-loader.success, .circle-loader.fail {
  transition: border .5s ease-out;
  animation: none;
}

.circle-loader .checkmark {
  display: none;
}

.circle-loader .checkmark.draw:after {
  animation-name: checkmark;
  animation-duration: .8s;
  animation-timing-function: ease;
  transform: scaleX(-1)rotate(135deg);
}

.circle-loader .checkmark:after {
  opacity: 1;
  transform-origin: 0 0;
  content: "";
  border-top: 3px solid #0acf97;
  border-right: 3px solid #0acf97;
  width: 1.75em;
  height: 3.5em;
  position: absolute;
  top: 3.5em;
  left: 1.75em;
}

.circle-loader .fail-mark {
  display: none;
}

.circle-loader .fail-mark.draw:before, .circle-loader .fail-mark.draw:after {
  animation-name: fail-mark;
  animation-duration: .8s;
  animation-timing-function: ease;
}

.circle-loader .fail-mark.draw:before {
  transform: scaleX(-1)rotate(45deg);
}

.circle-loader .fail-mark.draw:after {
  transform: scaleX(-1)rotate(135deg);
}

.circle-loader .fail-mark:after, .circle-loader .fail-mark:before {
  opacity: 1;
  transform-origin: center;
  content: "";
  border-right: 3px solid #fa5c7c;
  width: 1px;
  height: 3.5em;
  position: absolute;
  top: 1.5em;
  left: 3.25em;
}

.circle-loader.success {
  border-color: #0acf97;
}

.circle-loader.success .checkmark {
  display: block;
}

.circle-loader.fail {
  border-color: #fa5c7c;
}

.circle-loader.fail .fail-mark {
  display: block;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    opacity: 1;
    width: 0;
    height: 0;
  }

  20% {
    opacity: 1;
    width: 1.75em;
    height: 0;
  }

  40% {
    opacity: 1;
    width: 1.75em;
    height: 3.5em;
  }

  to {
    opacity: 1;
    width: 1.75em;
    height: 3.5em;
  }
}

@keyframes fail-mark {
  0% {
    opacity: 1;
    width: 0;
    height: 0;
  }

  to {
    opacity: 1;
    width: 1px;
    height: 3.5em;
  }
}

.btn-circle {
  text-align: center;
  border-radius: 15px;
  width: 30px;
  height: 30px;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.42857;
}

.btn-circle.btn-lg, .btn-group-lg > .btn-circle.btn {
  border-radius: 25px;
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
}

.btn-circle.btn-xl {
  border-radius: 35px;
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
}

.line-wired {
  justify-content: space-between;
  align-items: center;
  gap: .7rem;
  display: flex;
}

.line-wired > div, .line-wired > a {
  text-align: center;
  flex: 1 1 0;
  width: 0;
  position: relative;
}

.line-wired > div .link-prev, .line-wired > a .link-prev {
  font-size: 1.5rem;
  display: block;
  position: absolute;
  top: .5rem;
  left: -1rem;
}

.line-wired > div:first-child .link-prev, .line-wired > a:first-child .link-prev {
  display: none;
}

.bottom-menu {
  background-color: var(--ct-bg-leftbar);
  z-index: 902;
  grid-template-columns: repeat(4, 25%);
  height: 70px;
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -3px 4px #0000001a;
}

@media (width <= 767.98px) {
  .bottom-menu {
    display: grid;
  }
}

.bottom-menu .bottom-menu-item {
  color: var(--ct-menu-item);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .4rem;
  height: 100%;
  padding: 0 .25rem;
  display: flex;
}

.bottom-menu .bottom-menu-item:hover {
  color: var(--ct-menu-item-hover);
}

.bottom-menu .bottom-menu-item.bottom-menu-item-link.active {
  color: var(--ct-component-active-color);
  background-color: var(--ct-component-active-bg);
}

.bottom-menu .bottom-menu-item i {
  font-size: 1.9rem;
  line-height: 1rem;
}

.bottom-menu .bottom-menu-item span {
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
  gap: .2rem;
  max-width: 100%;
  line-height: 1rem;
  display: inline-flex;
  overflow: hidden;
}

.bottom-menu.with-rounded-buttons-two .bottom-menu-item:nth-child(2) i, .bottom-menu.with-rounded-buttons-two .bottom-menu-item:nth-child(3) i {
  visibility: hidden;
}

@media (width <= 767.98px) {
  .side-nav > .side-nav-item:first-child, .side-nav > .side-nav-item:nth-child(2), .side-nav > .side-nav-item:nth-child(3) {
    display: none;
  }
}

.rounded-buttons-container {
  z-index: 903;
  height: 0;
  position: fixed;
  bottom: 70px;
  right: 1.5rem;
}

@media (width <= 767.98px) {
  .rounded-buttons-container {
    justify-content: center;
    align-items: center;
    display: flex;
    bottom: 70px;
    left: 0;
    right: 0;
  }
}

.rounded-buttons-container .rounded-buttons-group {
  --button-size: 3rem;
  --button-padding: .2rem;
  --button-last-padding: .4rem;
  --base-radius-size: 1.5rem;
  --radius-size-left: var(--base-radius-size);
  --radius-size-right: var(--base-radius-size);
  --radius-size2: 1.6rem;
  justify-content: center;
  align-items: center;
  gap: .7rem;
  padding: .4rem;
  display: flex;
  position: relative;
}

@media (width <= 767.98px) {
  .rounded-buttons-container .rounded-buttons-group {
    gap: 0;
  }
}

.rounded-buttons-container .rounded-buttons-group .rounded-btn {
  width: var(--button-size);
  height: var(--button-size);
  padding: var(--button-padding);
  border-radius: var(--radius-size-left) var(--radius-size-right) var(--radius-size-right) var(--radius-size-left);
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  box-shadow: 3px 3px 10px #0000004d;
}

@media (width <= 767.98px) {
  .rounded-buttons-container .rounded-buttons-group .rounded-btn {
    box-shadow: none;
  }
}

.rounded-buttons-container .rounded-buttons-group .rounded-btn i {
  font-size: 1.8rem;
  line-height: 1rem;
}

.rounded-buttons-container .rounded-buttons-group .rounded-btn .rounded-button-badge {
  background-color: var(--bs-danger);
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 1rem;
  height: 1rem;
  padding: .2rem;
  font-size: .6rem;
  line-height: .6rem;
  display: flex;
  position: absolute;
  top: -5px;
  right: 0;
}

@media (width <= 767.98px) {
  .rounded-buttons-container .rounded-buttons-group {
    --radius-size-left: 0;
    --radius-size-right: 0;
  }

  .rounded-buttons-container .rounded-buttons-group > :first-child {
    --radius-size-left: var(--base-radius-size);
  }

  .rounded-buttons-container .rounded-buttons-group > :last-child {
    --radius-size-right: var(--base-radius-size);
  }

  .rounded-buttons-container .rounded-buttons-group:before {
    content: "";
    background: var(--bs-body-bg);
    z-index: 0;
    border-bottom-left-radius: var(--radius-size2);
    border-bottom-right-radius: var(--radius-size2);
    display: block;
    position: absolute;
    inset: 45% 0 0;
    box-shadow: inset 0 -3px 4px #0000001a;
  }

  .leftside-menu {
    right: 0;
  }
}

.leftside-menu {
  padding-bottom: 55px;
}

.leftside-menu .user-profile-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.navbar-custom .navbar-main-row {
  padding: 16px 0;
}

@media (width <= 767.98px) {
  .navbar-custom .navbar-main-row {
    padding: 0;
  }
}

.navbar-data-row {
  background-color: var(--bs-body-bg);
  padding: .5rem;
  display: none;
}

body.has-topbar-row__desktop .navbar-data-row {
  display: block;
}

body.has-topbar-row__full-size .navbar-data-row {
  margin: 0 -24px;
  padding: 0;
}

@media (width <= 767.98px) {
  body.has-topbar-row__full-size .navbar-data-row {
    margin: 0;
  }

  body.has-topbar-row__mobile .navbar-data-row {
    display: block;
  }

  .navbar-custom {
    transition: top .3s;
  }

  .navbar-custom.navbar-hidden {
    top: -70px;
  }

  .navbar-custom.navbar-hidden.navbar-custom-row {
    top: -120px;
  }

  .navbar-custom .navbar-main-row {
    padding: .82rem .5rem;
  }

  .leftside-menu {
    padding-top: 130px;
  }

  .leftside-menu .user-profile-container {
    top: 70px;
    bottom: auto;
  }

  .leftside-menu .user-profile-container .user-profile:before {
    transform: rotate(180deg);
  }
}

body {
  transition: padding-top .3s ease-in-out;
}

body.has-topbar-row__desktop .content-page {
  padding-top: 90px;
}

@media (width <= 767.98px) {
  body.has-topbar-row__mobile .content-page {
    padding-top: 110px;
  }

  .page-title-box {
    display: none;
  }

  .container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    padding-top: .6rem;
  }
}

.rounded-button--act-create i {
  transform-origin: center;
  transition: transform .3s ease-in-out;
}

.rounded-button--act-create.show i {
  transform: rotate(45deg);
}

.button-portal {
  justify-content: center;
  display: none;
  position: relative;
}

@media (width >= 768px) {
  .button-portal {
    justify-content: right;
  }
}

.button-portal.show {
  display: flex;
}

@media (width <= 767.98px) {
  body:has(.button-portal.show) {
    overflow: hidden;
  }

  .button-portal.show + .button-portal-backdrop {
    display: block;
  }

  .button-portal.show + .button-portal-backdrop + .btn {
    z-index: 1002;
  }
}

.button-portal .button-portal-popover {
  z-index: 1001;
  background-color: var(--ct-bg-leftbar);
  box-shadow: var(--ct-card-box-shadow);
  border-radius: .25rem;
  flex-direction: column;
  width: 400px;
  max-height: calc(100vh - 300px);
  padding-bottom: .5rem;
  display: flex;
  position: absolute;
  bottom: 10px;
}

@media (width <= 767.98px) {
  .button-portal .button-portal-popover {
    max-width: 95vw;
    position: fixed;
    bottom: 104px;
    left: 50%;
    transform: translate(-50%);
  }
}

.button-portal .button-portal-popover .button-portal-popover-header {
  background-color: var(--ct-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
  align-items: center;
  padding: .5rem;
  display: flex;
}

.button-portal .button-portal-popover .button-portal-popover-body {
  height: 100%;
  padding: .5rem .5rem 0;
}

.button-portal .button-portal-popover .button-portal-popover-body.button-portal-popover-body-scroll {
  overflow: hidden auto;
}

.button-portal .button-portal-popover .button-portal-popover-body.button-portal-popover-body-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}

.button-portal .button-portal-popover .button-portal-popover-body.button-portal-popover-body-scroll::-webkit-scrollbar-track {
  background: none;
}

.button-portal .button-portal-popover .button-portal-popover-body.button-portal-popover-body-scroll::-webkit-scrollbar-thumb {
  background-color: #0003;
  border-radius: 8px;
}

.button-portal .button-portal-popover .button-portal-popover-body.button-portal-popover-body-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #0006;
}

.button-portal:after {
  content: "";
  border-left: 10px solid #0000;
  border-right: 10px solid #0000;
  border-top: 10px solid var(--ct-bg-leftbar);
  z-index: 1001;
  position: absolute;
  bottom: 0;
}

@media (width >= 768px) {
  .button-portal:after {
    left: 25%;
  }
}

.button-portal-backdrop {
  display: none;
}

@media (width <= 767.98px) {
  .button-portal-backdrop {
    content: "";
    z-index: 1000;
    background: #0003;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
}

.page-loader {
  z-index: 99999;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.body-lock-scroll {
  overflow: clip;
}

.avatar {
  --avatar-size: 40px;
  --avatar-border-radius: 50%;
  --avatar-border-size: 2px;
  --avatar-border-type: solid;
  width: var(--avatar-size);
  height: var(--avatar-size);
  border-radius: var(--avatar-border-radius);
}

.avatar.avatar--bordered {
  --avatar-border-color: #fff;
  border: var(--avatar-border-size) var(--avatar-border-type) var(--avatar-border-color);
}

.avatar.avatar--colored {
  --avatar-color: #fff;
}

.avatar.avatar--colored.avatar-image {
  background-color: var(--avatar-color);
}

.avatar.avatar--colored.avatar-fallback svg circle {
  fill: var(--avatar-color);
}

.user-profile {
  background-color: rgba(var(--bs-primary-rgb), .2);
  -webkit-user-select: none;
  user-select: none;
  grid-template: "avatar name arrow" 25px
                 "avatar position arrow" 15px
                 / 40px 1fr 20px;
  align-items: center;
  column-gap: 5px;
  padding: .375rem;
  display: grid;
}

body[data-leftbar-compact-mode="condensed"] .user-profile {
  grid-template: "avatar" 40px
  / 1fr;
  justify-items: center;
}

body[data-leftbar-compact-mode="condensed"] .user-profile .user-profile-name, body[data-leftbar-compact-mode="condensed"] .user-profile .user-profile-position, body[data-leftbar-compact-mode="condensed"] .user-profile:before {
  display: none;
}

.user-profile .user-profile-avatar {
  --avatar-size: 40px;
  --avatar-border-radius: 50%;
  grid-area: avatar;
}

.user-profile .user-profile-name, .user-profile .user-profile-position {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  display: inline-block;
  overflow: hidden;
}

.user-profile .user-profile-name {
  grid-area: name;
  font-size: 18px;
  font-weight: 700;
}

.user-profile .user-profile-position {
  color: var(--ct-text-muted);
  grid-area: position;
  font-size: 12px;
}

.user-profile:before {
  content: "";
  opacity: .4;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z' /%3E%3C/svg%3E");
  grid-area: arrow;
  width: 20px;
  height: 20px;
  display: block;
}

.grid-form {
  grid-row-gap: .5rem;
  grid-column-gap: .5rem;
  grid-template-columns: auto max-content;
  align-items: center;
  display: grid;
}

.grid-form.grid-form-col-2 {
  grid-template-columns: max-content auto;
}

.grid-form.grid-form-col-3 {
  grid-template-columns: max-content repeat(2, auto);
}

.grid-form.grid-form-col-3-delete {
  grid-template-columns: repeat(2, auto) max-content;
}

.datatable-mobile .datatable-mobile-row {
  box-shadow: var(--ct-card-box-shadow);
  background-color: var(--ct-card-bg);
  border-radius: .25rem;
  grid-template-columns: auto;
  align-items: stretch;
  gap: .6rem;
  margin-bottom: 5px;
  padding: .7rem;
  display: grid;
  position: relative;
}

.datatable-mobile .datatable-mobile-row.datatable-mobile-row-with-index {
  grid-template-columns: 30px auto;
}

.datatable-mobile .datatable-mobile-row .datatable-mobile-collapse-btn {
  position: absolute;
  bottom: .2rem;
  right: .2rem;
}

.datatable-mobile .datatable-mobile-row .datatable-mobile-row-info {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.datatable-mobile .datatable-mobile-row .datatable-mobile-row-fixed {
  flex-wrap: wrap;
  gap: .25rem 1rem;
  margin-bottom: .2rem;
  display: flex;
}

.datatable-mobile .datatable-mobile-row .datatable-mobile-row-captioned [data-title]:before, .datatable-mobile .datatable-mobile-row .datatable-mobile-row-collapsed [data-title]:before {
  content: attr(data-title) ": ";
  color: var(--ct-text-muted);
  vertical-align: middle;
  font-size: .7rem;
}

.table-footer {
  align-items: center;
  gap: 1rem;
  display: inline-flex;
  position: sticky;
  bottom: 0;
}

.table-footer.table-footer-topbar {
  justify-content: space-between;
  display: flex;
}

.table-footer.table-footer-inline {
  background-color: var(--bs-body-bg);
  padding: .5rem;
}

@media (width <= 767.98px) {
  .table-footer.table-footer-inline {
    display: none;
  }
}

.table th {
  white-space: nowrap;
}

.table th a {
  color: var(--bs-table-color);
  opacity: .8;
  transition: opacity .1s ease-in-out;
}

.table th a:hover {
  opacity: 1;
}

.table th, .table td {
  vertical-align: middle;
  padding: .4rem .6rem;
}

@media (width <= 767.98px) {
  .table th, .table td {
    white-space: nowrap;
    padding: .3rem .5rem;
  }

  .card-body .table th, .card-body .table td {
    white-space: normal;
  }
}

.navbar-tabs-row {
  background-color: var(--ct-border-color);
  scrollbar-color: transparent transparent;
  scrollbar-width: none;
  display: flex;
  overflow: scroll clip;
}

@media (width >= 768px) {
  .navbar-tabs-row {
    display: none;
  }
}

.navbar-tabs-row::-webkit-scrollbar {
  display: none;
}

.navbar-tabs-row::-webkit-scrollbar-track {
  display: none;
}

.navbar-tabs-row::-webkit-scrollbar-thumb {
  display: none;
}

.navbar-tabs-row a {
  white-space: nowrap;
  border-left: 1px solid var(--bs-body-bg);
  color: var(--bs-dark);
  padding: .5rem .7rem;
}

body[data-layout-color="dark"] .navbar-tabs-row a {
  color: var(--bs-light);
}

.navbar-tabs-row a.active {
  background-color: rgba(var(--bs-primary-rgb), .5);
  color: var(--bs-light);
}

.navbar-tabs-row a:first-child {
  border-left: none;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: .375rem !important;
}

.row-gap-2 {
  row-gap: .75rem !important;
}

.row-gap-3 {
  row-gap: 1.5rem !important;
}

.row-gap-4 {
  row-gap: 2.25rem !important;
}

.row-gap-5 {
  row-gap: 4.5rem !important;
}

.column-gap-0 {
  -moz-column-gap: 0 !important;
  column-gap: 0 !important;
}

.column-gap-1 {
  -moz-column-gap: .375rem !important;
  column-gap: .375rem !important;
}

.column-gap-2 {
  -moz-column-gap: .75rem !important;
  column-gap: .75rem !important;
}

.column-gap-3 {
  -moz-column-gap: 1.5rem !important;
  column-gap: 1.5rem !important;
}

.column-gap-4 {
  -moz-column-gap: 2.25rem !important;
  column-gap: 2.25rem !important;
}

.column-gap-5 {
  -moz-column-gap: 4.5rem !important;
  column-gap: 4.5rem !important;
}

.leaflet-container {
  z-index: 0;
}

.text-with-bg-primary, .text-with-bg-secondary, .text-with-bg-success, .text-with-bg-info {
  color: #fff !important;
}

.text-with-bg-warning {
  color: #313a46 !important;
}

.text-with-bg-danger {
  color: #fff !important;
}

.text-with-bg-light {
  color: #313a46 !important;
}

.text-with-bg-dark, body[data-layout-color="dark"] .text-with-bg-primary {
  color: #fff !important;
}

body[data-layout-color="dark"] .text-with-bg-secondary {
  color: #313a46 !important;
}

body[data-layout-color="dark"] .text-with-bg-success, body[data-layout-color="dark"] .text-with-bg-info {
  color: #fff !important;
}

body[data-layout-color="dark"] .text-with-bg-warning {
  color: #313a46 !important;
}

body[data-layout-color="dark"] .text-with-bg-danger, body[data-layout-color="dark"] .text-with-bg-light {
  color: #fff !important;
}

body[data-layout-color="dark"] .text-with-bg-dark {
  color: #313a46 !important;
}

.tree-view, .tree-view ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tree-view li > ul {
  padding-left: 20px;
}

.scroll-vertical {
  overflow: hidden auto;
}

.custom-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: none;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #0003;
  border-radius: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #0006;
}

.page-h-100 {
  --page-cols-cnt: 1;
  --page-col-height: auto;
  grid-template-columns: repeat(var(--page-cols-cnt), 1fr);
  grid-auto-rows: var(--page-col-height);
  grid-gap: 1rem;
  display: grid;
}

@media (width >= 768px) {
  .page-h-100 {
    --page-cols-cnt: 1;
    --page-col-height: calc(100vh - 160px);
  }
}

@media (width >= 992px) {
  .page-h-100 {
    --page-cols-cnt: 2;
  }
}

@media (width >= 1400px) {
  .page-h-100 {
    --page-cols-cnt: 3;
    --page-col-height: calc(100vh - 210px);
  }
}

.page-h-100 .card {
  margin-bottom: 0;
}

.page-h-100 .card .card-body {
  height: 100%;
  overflow: hidden auto;
}

.page-h-100 .card .card-body::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}

.page-h-100 .card .card-body::-webkit-scrollbar-track {
  background: none;
}

.page-h-100 .card .card-body::-webkit-scrollbar-thumb {
  background-color: #0003;
  border-radius: 8px;
}

.page-h-100 .card .card-body::-webkit-scrollbar-thumb:hover {
  background-color: #0006;
}

.tabs-h-100 .tab-content {
  height: calc(100% - 40px);
}

.tabs-h-100 .tab-content .tab-pane {
  height: 100%;
}

.rbt .rbt-input-main::-ms-clear {
  display: none;
}

.rbt-menu {
  margin-bottom: 2px;
}

.rbt-menu > .dropdown-item {
  text-overflow: ellipsis;
  overflow: hidden;
}

.rbt-menu > .dropdown-item:focus {
  outline: none;
}

.rbt-menu-pagination-option {
  text-align: center;
}

.rbt-input-multi {
  cursor: text;
  position: relative;
  overflow: hidden;
}

.rbt-input-multi.focus {
  color: #495057;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.rbt-input-multi.form-control {
  height: auto;
}

.rbt-input-multi.disabled {
  opacity: 1;
  background-color: #e9ecef;
}

.rbt-input-multi.is-invalid.focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 .2rem #dc354540;
}

.rbt-input-multi.is-valid.focus {
  border-color: #28a745;
  box-shadow: 0 0 0 .2rem #28a74540;
}

.rbt-input-multi input::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}

.rbt-input-multi input:-ms-input-placeholder {
  color: #6c757d;
}

.rbt-input-multi input::-webkit-input-placeholder {
  color: #6c757d;
}

.rbt-input-multi .rbt-input-wrapper {
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: -1px;
  margin-bottom: -4px;
  display: flex;
  overflow: hidden;
}

.rbt-input-multi .rbt-input-main {
  margin: 1px 0 4px;
}

.rbt-close {
  z-index: 1;
}

.rbt-close-lg {
  font-size: 1.5rem;
}

.rbt-token {
  color: #007bff;
  background-color: #e7f4ff;
  border: 0;
  border-radius: .25rem;
  margin: 1px 3px 2px 0;
  line-height: 1rem;
  display: inline-flex;
}

.rbt-token .rbt-token-label {
  padding: .25rem .5rem;
}

.rbt-token .rbt-token-label:not(:last-child) {
  padding-right: .25rem;
}

.rbt-token-disabled {
  color: #495057;
  pointer-events: none;
  background-color: #0000001a;
}

.rbt-token-removeable {
  cursor: pointer;
}

.rbt-token-active {
  color: #fff;
  background-color: #007bff;
  outline: none;
  text-decoration: none;
}

.rbt-token .rbt-token-remove-button {
  box-shadow: none;
  color: inherit;
  font-size: inherit;
  opacity: 1;
  text-shadow: none;
  background-image: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  outline: none;
  justify-content: center;
  padding: .25rem .5rem .25rem 0;
  font-weight: 400;
  display: flex;
}

.rbt-token .rbt-token-remove-button .rbt-close-content {
  display: block;
}

.rbt-aux {
  pointer-events: none;
  justify-content: center;
  align-items: center;
  width: 2rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.rbt-aux-lg {
  width: 3rem;
}

.rbt-aux .rbt-close {
  pointer-events: auto;
  margin-top: -.25rem;
}

.has-aux .form-control {
  padding-right: 2rem;
}

.has-aux .form-control.is-valid, .has-aux .form-control.is-invalid {
  background-position: right 2rem center;
  padding-right: 4rem;
}

.rbt-highlight-text {
  background-color: inherit;
  color: inherit;
  padding: 0;
  font-weight: 700;
}

.input-group > .rbt {
  flex: 1;
}

.input-group > .rbt .rbt-input-hint, .input-group > .rbt .rbt-aux {
  z-index: 5;
}

.input-group > .rbt:not(:first-child) .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .rbt:not(:last-child) .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbt .rbt-input-main {
  color: var(--ct-input-color);
}

.rbt .rbt-input-multi.disabled {
  background-color: var(--ct-form-select-disabled-bg);
}

.rbt .rbt-input-multi.focus {
  color: var(--ct-input-color);
}

.rbt .rbt-input-multi .rbt-input-wrapper {
  align-items: center;
  column-gap: .75rem;
}

.rbt .rbt-aux .rbt-close {
  margin-top: 0;
}

.rbt .rbt-aux .rbt-close .rbt-close-content {
  display: none;
}

.top-overlay {
  z-index: 9999;
  background-color: #0000004d;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.rtc-data {
  background-color: #0006;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  max-width: 50%;
  padding: 3px;
  transition: transform .3s ease-in-out;
  display: flex;
  position: fixed;
  top: 150px;
  right: 0;
  transform: translate(calc(100% - 25px));
}

.rtc-data.open {
  transform: translate(0);
}

.rtc-data .rtc-stat {
  color: var(--bs-danger);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20px;
  display: flex;
}

.rtc-data .rtc-stat.rtc-stat-is-online {
  color: var(--bs-success);
}

.rtc-data .rtc-users {
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  padding: 5px;
  display: flex;
}

.rtc-data .rtc-users .rtc-user {
  border: 2px solid;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  overflow: clip;
}

.rtc-data .rtc-users .rtc-user .rtc-user-avatar {
  width: 100%;
  height: 100%;
}

.react-contextmenu {
  box-shadow: var(--ct-box-shadow);
  background-color: var(--ct-dropdown-bg);
  z-index: 10;
  border-radius: .25rem;
  padding: 0;
}

.react-contextmenu .react-contextmenu-item {
  cursor: pointer;
  padding: .5rem 1rem;
}

.react-contextmenu .react-contextmenu-item:hover {
  background-color: var(--ct-dropdown-link-hover-bg);
  color: var(--ct-dropdown-link-hover-color);
}

.react-contextmenu .react-contextmenu-item.disabled {
  opacity: .5;
  cursor: not-allowed;
}

.toast-container {
  max-width: calc(100% - 20px);
  position: fixed;
  bottom: 80px;
  right: 10px;
}

@media (width <= 767.98px) {
  .toast-container {
    bottom: 85px;
  }
}

.invalid-tooltip.force-display, .invalid-feedback.force-display {
  display: block;
}

.backdrop {
  background-color: #0000004d;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.search-app {
  width: 100%;
  position: relative;
}

.search-app .backdrop {
  z-index: 1;
  opacity: 0;
  transition: all .3s ease-in-out;
  display: none;
}

.search-app .search-app-suggest {
  z-index: 3;
  color: var(--ct-input-placeholder-color);
  opacity: 0;
  text-align: right;
  max-width: 400px;
  font-size: .75rem;
  transition: opacity .3s ease-in-out;
  position: absolute;
  top: 0;
  right: .75rem;
}

.search-app .search-app-suggest span {
  white-space: nowrap;
}

@media (hover: none) {
  .search-app .search-app-suggest {
    display: none;
  }
}

.search-app input, .search-app .search-dropdown {
  z-index: 2;
  position: relative;
}

.search-app .search-dropdown {
  grid-row-gap: 1rem;
  grid-template-rows: 35px 1fr;
  max-height: 90vh;
  padding: .75rem;
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
}

@media (width <= 767.98px) {
  .search-app .search-dropdown {
    grid-template-rows: 40px 1fr;
    max-height: calc(100dvh - 150px);
  }
}

.search-app .search-dropdown .search-results {
  flex-grow: 1;
  overflow-y: auto;
}

body:has(.search-app.active) {
  overflow: hidden;
}

@media (width <= 767.98px) {
  .navbar-custom:has(.search-app.active) .topbar-breadcrumbs {
    display: none;
  }
}

.search-app.active .backdrop {
  opacity: 1;
  display: block;
}

.search-app.active .search-dropdown {
  display: grid;
}

.search-app.active .search-app-suggest {
  opacity: 1;
}

.search-app .search-app-result {
  cursor: pointer;
  border-top: 1px solid var(--ct-table-striped-bg);
  align-items: center;
  padding: .5rem;
  display: flex;
}

.search-app .search-app-result:hover, .search-app .search-app-result.selected {
  background-color: var(--ct-table-hover-bg);
}

.search-app .search-app-result .search-app-icon {
  margin-right: .5rem;
}

.search-app .search-app-result .search-app-text {
  flex-grow: 1;
}

.search-app .search-type-selector {
  scrollbar-color: transparent transparent;
  scrollbar-width: none;
  flex-wrap: nowrap;
  overflow: scroll clip;
}

.search-app .search-type-selector::-webkit-scrollbar {
  display: none;
}

.search-app .search-type-selector::-webkit-scrollbar-track {
  display: none;
}

.search-app .search-type-selector::-webkit-scrollbar-thumb {
  display: none;
}

.search-app .search-type-selector .nav-link {
  white-space: nowrap;
}

.nav-counter {
  vertical-align: middle;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  font-size: 10px;
  line-height: 10px;
  display: inline-flex;
}

.nav-counter span {
  font-size: 10px;
  line-height: 10px;
}

.bottom-menu-item .nav-counter {
  margin-left: 5px;
}

.ova-editor {
  border: 1px solid var(--bs-border-color);
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "topbar"
                       "body"
                       "statusbar";
  min-height: 700px;
  display: grid;
}

.ova-editor.ova-editor-fullscreen {
  z-index: 1050;
  box-shadow: none;
  border: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.ova-editor .ova-editor-topbar {
  background-color: var(--bs-border-color);
  flex-wrap: wrap;
  grid-area: topbar;
  align-items: stretch;
  display: flex;
  position: sticky;
  top: 0;
}

.ova-editor .ova-editor-topbar .btn svg {
  --ova-editor-btn-size: 1.2rem;
  width: var(--ova-editor-btn-size);
  height: var(--ova-editor-btn-size);
}

.ova-editor .ova-editor-topbar .ova-editor-topbar-group {
  border-right: 1px solid rgba(var(--bs-secondary-rgb), .25);
  align-items: center;
  padding: .3rem;
  display: flex;
}

.ova-editor .ova-editor-topbar .ova-editor-topbar-group:empty {
  display: none;
}

.ova-editor .ova-editor-topbar .ova-editor-topbar-group[data-name="block-type"] {
  order: 1;
}

.ova-editor .ova-editor-topbar .ova-editor-topbar-group[data-name="font"] {
  order: 2;
}

.ova-editor .ova-editor-topbar .ova-editor-topbar-group[data-name="text-format"] {
  order: 3;
}

.ova-editor .ova-editor-topbar .ova-editor-topbar-group[data-name="align"] {
  order: 4;
}

.ova-editor .ova-editor-topbar .ova-editor-topbar-group[data-name="format"] {
  order: 5;
}

.ova-editor .ova-editor-topbar .ova-editor-topbar-group[data-name="insert"] {
  order: 6;
}

.ova-editor .ova-editor-topbar .ova-editor-topbar-group[data-name="active-selection"] {
  order: 888;
}

.ova-editor .ova-editor-topbar .ova-editor-topbar-group[data-name="editor-fullscreen"] {
  border-left: 1px solid rgba(var(--bs-secondary-rgb), .25);
  border-right: none;
  order: 999;
  margin-left: auto;
}

.ova-editor .ova-editor-body {
  background-color: #fff;
  grid-area: body;
  width: 100%;
  height: 100%;
  padding: 5px 10px;
}

.ova-editor .ova-editor-status-bar {
  background-color: var(--bs-border-color);
  flex-wrap: wrap;
  grid-area: statusbar;
  align-items: center;
  padding: .3rem .5rem .1rem;
  font-size: .7rem;
  display: flex;
}

.react-contextmenu {
  z-index: 1000;
}

.media-card {
  --border-color: rgba(var(--bs-light-rgb), .25);
  aspect-ratio: 1;
  -webkit-user-select: none;
  user-select: none;
  box-shadow: 0 0 2px 1px var(--border-color);
  position: relative;
  overflow: hidden;
}

.media-card .media-card-preview {
  background-size: cover;
  display: block;
  position: absolute;
  inset: 0;
}

.media-card .media-card-preview:before {
  content: "";
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 1;
  display: block;
  position: absolute;
  inset: 0;
}

.media-card .media-card-info {
  z-index: 30;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  color: #fff;
  background-color: #00000080;
  height: 30px;
  padding: .25rem 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.media-card.media-card-selected {
  --border-color: rgba(var(--bs-primary-rgb), .75);
}

.media-card.media-card-selected .media-card-info {
  background-color: rgba(var(--bs-primary-rgb), .75);
}

.media-library-drop-popup {
  z-index: 9999;
  background-color: #00000080;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.media-library-drop-popup:before {
  content: "";
  opacity: .5;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23fff'%3E%3Cpath d='M19 8H15V14H9V8H5L12 1L19 8M14.17 6L12 3.83L9.83 6H11V12H13V6H14.17M5 16V18H19V16H5M19 20V22H5V20H19Z' /%3E%3C/svg%3E");
  width: 80px;
  height: 80px;
  display: block;
}

.media-library-drop-popup:after {
  content: "Перетяніть медіа сюди для завантаження";
  color: #fff;
  text-align: center;
  opacity: .7;
  font-size: 1.5rem;
  font-weight: 700;
}

.media-library-drop-popup[data-message]:after {
  content: attr(data-message);
}

.FIE_root .FIE_tab label {
  text-align: center;
}

.google-preview, .facebook-preview {
  background-color: #fff;
}

.google-preview {
  border-radius: 8px;
  max-width: 400px;
  margin-bottom: 20px;
  padding: 12px 16px;
  display: block;
  box-shadow: 0 1px 6px #20212447;
}

.google-preview .google-url {
  color: #3c4043;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABs0lEQVR4AWL4//8/RRjO8Iucx+noO0MWUDo16FYABMGP6ZfUcRnWtm27jVPbtm3bttuH2t3eFPcY9pLz7NxiLjCyVd87pKnHyqXyxtCs8APd0rnyxiu4qSeA3QEDrAwBDrT1s1Rc/OrjLZwqVmOSu6+Lamcpp2KKMA9PH1BYXMe1mUP5qotvXTywsOEEYHXxrY+3cqk6TMkYpNr2FeoY3KIr0RPtn9wQ2unlA+GMkRw6+9TFw4YTwDUzx/JVvARj9KaedXRO8P5B1Du2S32smzqUrcKGEyA+uAgQjKX7zf0boWHGfn71jIKj2689gxp7OAGShNcBUmLMPVjZuiKcA2vuWHHDCQxMCz629kXAIU4ApY15QwggAFbfOP9DhgBJ+nWVJ1AZAfICAj1pAlY6hCADZnveQf7bQIwzVONGJonhLIlS9gr5mFg44Xd+4S3XHoGNPdJl1INIwKyEgHckEhgTe1bGiFY9GSFBYUwLh1IkiJUbY407E7syBSFxKTszEoiE/YdrgCEayDmtaJwCI9uu8TKMuZSVfSa4BpGgzvomBR/INhLGzrqDotp01ZR8pn/1L0JN9d9XNyx0AAAAAElFTkSuQmCC");
  background-position: 2px 1px;
  background-repeat: no-repeat;
  width: 100%;
  margin-bottom: 12px;
  padding-left: 24px;
  font-size: 12px;
  overflow: hidden;
}

.google-preview .google-title {
  color: #1967d2;
  text-overflow: ellipsis;
  max-width: 600px;
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 20px;
  overflow: hidden;
}

.google-preview .google-title:after {
  content: attr(data-sitename);
  margin-left: 5px;
  display: inline-block;
}

.google-preview .google-descr {
  color: #3c4043;
  max-width: 600px;
  font-size: 14px;
  line-height: 20px;
  position: relative;
}

.facebook-preview {
  max-width: 470px;
  font-family: Georgia, lucida grande, tahoma, verdana, arial, sans-serif;
  box-shadow: inset 0 0 0 1.5px #0000001a, 0 1px 1px #0000000d;
}

.facebook-preview .facebook-image {
  background-color: #ccc;
  background-position: center;
  background-size: cover;
  height: 214px;
}

.facebook-preview .facebook-image.facebook-image-empty:after {
  content: "Зображення не вибрано";
  color: #777;
  text-align: center;
  padding: 98px 0;
  display: block;
}

.facebook-preview .facebook-title {
  padding: 10px 12px 7px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.facebook-preview .facebook-descr {
  color: #777;
  max-height: 80px;
  margin-bottom: 7px;
  padding-left: 12px;
  padding-right: 12px;
}

.facebook-preview .facebook-url {
  text-transform: uppercase;
  color: #9197a3;
  margin-top: 12px;
  padding-bottom: 10px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 11px;
  line-height: 11px;
}

.rbt-token-label {
  color: var(--ct-input-color);
}

/*# sourceMappingURL=index.26e301b7.css.map */
